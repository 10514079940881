import React, { useContext } from 'react';
import { useAnfragen } from '../hooks/useAnfragen';
import { useEvents } from '../hooks/useEvents';
import { useBenutzerContext } from './BenutzerContext';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';

const AnfrageUndEventContext = React.createContext({});

export function useAnfrageUndEventContext() {
  return useContext(AnfrageUndEventContext);
}

export function AnfrageUndEventContextProvider({ children }) {
  const { cognitoUser, benutzer, istHandwerker } = useBenutzerContext();

  const { anfragen, isLoading: isLoadingAnfragen } = useAnfragen(
    cognitoUser && benutzer && benutzer.id,
    istHandwerker
  );
  const { events, isLoading: isLoadingEvents } = useEvents(
    cognitoUser && benutzer && benutzer.id,
    istHandwerker
  );

  if (isLoadingAnfragen || isLoadingEvents) {
    return <LoadingIndicator />;
  }

  return (
    <AnfrageUndEventContext.Provider value={{ anfragen, events }}>
      {children}
    </AnfrageUndEventContext.Provider>
  );
}
