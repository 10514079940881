import React from 'react';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';

import './BewertungErfolgreich.scss';

export default function BewertungErfolgreich({ text }) {
  return (
    <div className="bewertungErfolgreich">
      <div className="bewertungErfolgreich__danke">
        <SuccessIcon className="bewertungErfolgreich__icon" />
        <h1 className="bewertungErfolgreich__ueberschrift">
          Vielen Dank für Ihre Bewertung
        </h1>
        <p className="bewertungErfolgreich__text">{text}</p>
      </div>
    </div>
  );
}
