import React, { useState } from 'react';
import classNames from 'classnames';
import cloneDeep from 'lodash.clonedeep';
import {
  KURZLEISTUNGEN_ZU_LANGLEISTUNGEN,
  leistungenFuerGewerk,
} from '../../../domain/leistung';
import { GEWERKE, gewerkZuLangform } from '../../../domain/gewerk';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron_right.svg';

import './TaetigkeitenAuswahl.scss';

export const deselectLeistung = ({ gewaehlteLeistungen, gewerk, leistung }) => {
  const gewaehlteLeistungenClone = cloneDeep(gewaehlteLeistungen);

  for (const gewerkMitLeistungen of gewaehlteLeistungenClone) {
    if (gewerkMitLeistungen.gewerk === gewerk) {
      gewerkMitLeistungen.leistungen = gewerkMitLeistungen.leistungen.filter(
        (it) => it !== leistung
      );
    }
  }

  return gewaehlteLeistungenClone.filter(
    (gewerkMitLeistungen) => gewerkMitLeistungen.leistungen.length > 0
  );
};

export const selectLeistung = ({ gewaehlteLeistungen, gewerk, leistung }) => {
  const gewaehlteLeistungenClone = cloneDeep(gewaehlteLeistungen);
  const index = gewaehlteLeistungenClone.findIndex(
    (gewerkMitLeistung) => gewerkMitLeistung.gewerk === gewerk
  );
  const gewerkMitLeistungen =
    index !== -1
      ? gewaehlteLeistungenClone.splice(index, 1)[0]
      : { gewerk, leistungen: [] };

  return [
    ...gewaehlteLeistungenClone,
    {
      ...gewerkMitLeistungen,
      leistungen: Array.from(
        new Set([...gewerkMitLeistungen.leistungen, leistung])
      ),
    },
  ];
};

export function uiModel(gewaehlteLeistungen) {
  return GEWERKE.map((gewerk) => {
    const leistungen = leistungenFuerGewerk(gewerk).map((leistung) => ({
      langname: KURZLEISTUNGEN_ZU_LANGLEISTUNGEN[leistung],
      kurzname: leistung,
      ausgewaehlt: gewaehlteLeistungen
        .filter((gewerkMitLeistung) => gewerkMitLeistung.gewerk === gewerk)
        .map((gewerkMitLeistung) => gewerkMitLeistung.leistungen)
        .some((gewerksLeistungen) => gewerksLeistungen.includes(leistung)),
    }));

    return {
      kurzname: gewerk,
      langname: gewerkZuLangform(gewerk),
      leistungen,
      anzahlLeistungen: leistungen.filter((leistung) => leistung.ausgewaehlt)
        .length,
    };
  });
}

export function TaetigkeitenAuswahl({ gewaehlteLeistungen, onChange }) {
  const [offeneGewerke, setOffeneGewerke] = useState([]);

  const zeigeLeistungen = (gewerk, istGewerkOffen) => {
    setOffeneGewerke(istGewerkOffen ? [] : [gewerk]);
  };

  return (
    <div>
      {uiModel(gewaehlteLeistungen).map((gewerkMitLeistungen) => {
        const istGewerkOffen = offeneGewerke.includes(
          gewerkMitLeistungen.kurzname
        );

        return (
          <div
            title={gewerkMitLeistungen.langname}
            key={gewerkMitLeistungen.kurzname}
            className={classNames('taetigkeitenAuswahl__gewerk', {
              'taetigkeitenAuswahl__gewerk--selected': istGewerkOffen,
            })}
          >
            <div
              className="taetigkeitenAuswahl__header"
              onClick={() =>
                zeigeLeistungen(gewerkMitLeistungen.kurzname, istGewerkOffen)
              }
            >
              <ChevronIcon />
              <h3 className="taetigkeitenAuswahl__ueberschrift">
                {gewerkMitLeistungen.langname}
                {gewerkMitLeistungen.anzahlLeistungen > 1 && (
                  <small>
                    {' '}
                    ({gewerkMitLeistungen.anzahlLeistungen} Leistungen gewählt)
                  </small>
                )}
                {gewerkMitLeistungen.anzahlLeistungen === 1 && (
                  <small> (eine Leistung gewählt)</small>
                )}
              </h3>
            </div>
            {istGewerkOffen && (
              <div className="taetigkeitenAuswahl__leistungen">
                {gewerkMitLeistungen.leistungen.map((leistung) => {
                  return (
                    <div
                      key={`${gewerkMitLeistungen.kurzname}_${leistung.kurzname}`}
                      className="taetigkeitenAuswahl__leistung"
                    >
                      <label
                        htmlFor={`${gewerkMitLeistungen.kurzname}_${leistung.kurzname}`}
                        className="auswahlCheckbox__label margin:top:.5rem"
                      >
                        <input
                          className="auswahlCheckbox"
                          type="checkbox"
                          id={`${gewerkMitLeistungen.kurzname}_${leistung.kurzname}`}
                          data-cy-id={leistung.langname}
                          data-cy-bereich="leistung"
                          value={leistung.kurzname}
                          checked={leistung.ausgewaehlt}
                          onChange={(event) => {
                            const callbackFn = event.target.checked
                              ? selectLeistung
                              : deselectLeistung;

                            onChange(
                              callbackFn({
                                gewaehlteLeistungen,
                                gewerk: gewerkMitLeistungen.kurzname,
                                leistung: event.target.value,
                              })
                            );
                          }}
                        />
                        {leistung.langname}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
