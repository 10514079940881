import {
  ACCEPTED_TYPES_PDF_ONLY,
  ACCEPTED_TYPES_IMAGES_ONLY,
  ACCEPTED_TYPES_PDF_AND_IMAGES,
} from './constants';

const TYPE_VALIDATION_MESSAGES = {
  [ACCEPTED_TYPES_PDF_ONLY]:
    'Dateien können nur im PDF-Format (max. 9 MB) hochgeladen werden.',
  [ACCEPTED_TYPES_IMAGES_ONLY]:
    'Bilder können nur im JPG-, PNG- oder GIF-Format (max. 9 MB) hochgeladen werden.',
  [ACCEPTED_TYPES_PDF_AND_IMAGES]:
    'Dateien können nur im PDF-, JPG-, PNG- oder GIF-Format (max. 9 MB) hochgeladen werden.',
};
const MAX_FILE_SIZE = 1024 * 1024 * 9;

export function getValidMimeTypes(acceptedTypes) {
  const isImageAccepted =
    acceptedTypes === ACCEPTED_TYPES_IMAGES_ONLY ||
    acceptedTypes === ACCEPTED_TYPES_PDF_AND_IMAGES;
  const isPdfAccepted =
    acceptedTypes === ACCEPTED_TYPES_PDF_ONLY ||
    acceptedTypes === ACCEPTED_TYPES_PDF_AND_IMAGES;
  const types = {
    'image/png': isImageAccepted,
    'image/gif': isImageAccepted,
    'image/jpeg': isImageAccepted,
    'application/pdf': isPdfAccepted,
  };
  return Object.keys(types).filter((key) => types[key]);
}

export function validateFiles(
  event,
  isMultiple,
  acceptedTypes,
  fileContainers,
  maxFileSizeInMb = 25
) {
  const validMimeTypes = getValidMimeTypes(acceptedTypes);
  const isValidFile = (file) =>
    validMimeTypes.includes(file.type) && file.size <= MAX_FILE_SIZE;
  const files = getFilesFromEvent(event);

  const maxFileSizeInBytes = 1024 * 1024 * maxFileSizeInMb;

  const sum = files
    .map((file) => file.size)
    .reduce((sum, size) => sum + size, 0);

  if (sum > maxFileSizeInBytes) {
    return {
      isValid: false,
      validationMessage: isMultiple
        ? `Die Dateien dürfen maximal ${maxFileSizeInMb}MB groß sein.`
        : `Die Datei darf maximal ${maxFileSizeInMb}MB groß sein.`,
    };
  }

  if (isMultiple) {
    const isValid = files.every(isValidFile);
    return {
      isValid,
      validationMessage: isValid ? '' : TYPE_VALIDATION_MESSAGES[acceptedTypes],
    };
  } else {
    const moreThanOneFileInTotal = files.length + fileContainers.length > 1;
    const noExistingFileAndOneNewFile =
      fileContainers.length === 0 && files.length === 1;
    if (moreThanOneFileInTotal) {
      return {
        isValid: false,
        validationMessage:
          'Es kann nur eine einzelne Datei hochgeladen werden.',
      };
    } else if (noExistingFileAndOneNewFile) {
      const isValid = isValidFile(files[0]);
      return {
        isValid,
        validationMessage: isValid
          ? ''
          : TYPE_VALIDATION_MESSAGES[acceptedTypes],
      };
    }
  }
  return { isValid: false, validationMessage: '' };
}

export function getFilesFromEvent(event) {
  return (
    (Boolean(event.dataTransfer) &&
      Boolean(event.dataTransfer.files) &&
      event.dataTransfer.files.length > 0 && [...event.dataTransfer.files]) ||
    (Boolean(event.target) &&
      Boolean(event.target.files) &&
      event.target.files.length > 0 && [...event.target.files]) ||
    []
  );
}
