import './KommunikationskanalHeader.scss';

import { HideAt, ShowAt } from 'react-with-breakpoints';
import React, { useEffect, useState } from 'react';
import { useDialogState, useModalState } from '../../../hooks';

import AnfrageZurueckziehenOderAblehnenDialog from '../../AnfrageZurueckziehenOderAblehnenDialog/AnfrageZurueckziehenOderAblehnenDialog';
import AnfragedetailsModal from '../../AnfragedetailsModal/AnfragedetailsModal';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import Button from '../../Button/Button';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburger.svg';
import { ReactComponent as HelpCenterIcon } from '../../../assets/icons/help_center.svg';
import Link from '../../Link/Link';
import MobileMenu from '../../MobileMenu/MobileMenu';
import { MobileMenuItem } from '../../MobileMenu/MobileMenu';
import Modal from '../../Modal/Modal';
import ProfilModal from '../../ProfilModal/ProfilModal';
import { gtmEvent } from '../../../googleAnalytics';
import { useAuftraege } from '../../../DomainContext';
import { useHistory } from 'react-router-dom';

const AUTOR = 'HANDWERKER';

const ProblemMeldenDialog = ({ hide, anfrage }) => {
  const auftraege = useAuftraege();

  const [wurdeGesendet, setWurdeGesendet] = useState(false);

  const handleProblemMeldenClick = () => {
    auftraege.meldeProblem(anfrage);
    gtmEvent({
      category: 'request',
      action: 'infoSent',
      label: anfrage.statusAlsText(),
    });
    setWurdeGesendet(true);
  };
  useEffect(() => {
    gtmEvent({
      category: 'request',
      action: 'mediationRequested',
      label: anfrage.statusAlsText(),
    });
  }, [anfrage]);

  const actions = (
    <>
      <Button onClick={hide}>Schließen</Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title="Sie sind in der Kommunikation mit dem Kunden in eine Sackgasse geraten? Wir helfen Ihnen."
      hide={hide}
      actions={actions}
      gaTrackingName="Problem melden"
    >
      <div className="problemMelden">
        <div className="problemMelden__text">
          Bei Problemen mit dem Kunden während der Auftragsausführung, -abnahme
          oder Bezahlung helfen wir Ihnen. Senden Sie uns eine E-Mail an
          <a href="mailto:support@provinzial-meinzuhause.de">
            support@provinzial-meinzuhause.de
          </a>
        </div>
        {wurdeGesendet ? (
          <div className="problemMelden__bestaetigung">
            Ihr Ansprechpartner wurde informiert.
          </div>
        ) : (
          <Link size="small" onClick={handleProblemMeldenClick}>
            Alle Informationen zum Auftrag an PROVINZIAL senden
          </Link>
        )}
      </div>
    </Modal>
  );
};

const KommunikationskanalHeader = function KommunikationskanalHeader({
  anfrage,
}) {
  const history = useHistory();
  const [
    isAnfrageZurueckziehenOderAblehnenDialogShown,
    showAnfrageZurueckziehenOderAblehnenDialog,
    hideAnfrageZurueckziehenOderAblehnenDialog,
  ] = useDialogState();

  const [
    isProblemMeldenDialogShown,
    showProblemMeldenDialog,
    hideProblemMeldenDialog,
  ] = useDialogState();

  const [
    isProfilModalShown,
    showProfilModal,
    hideProfilModal,
  ] = useDialogState();

  const [
    isAnfragedetailsModalShown,
    showAnfragedetailsModal,
    hideAnfragedetailsModal,
  ] = useDialogState();

  const oeffneOffeneOderAbgeschlosseneAuftraege = () => {
    if (anfrage.wurdeBeendet()) {
      history.push('/abgeschlosseneAuftraege');
    } else {
      history.push('/offeneAuftraege');
    }
  };

  const [
    isAnfrageMenuVisible,
    showAnfrageMenu,
    hideAnfrageMenu,
  ] = useModalState();

  return (
    <section className="kommunikationskanalHeader kommunikationskanalHeader--handwerker">
      <HideAt breakpoint="xlarge">
        <Button
          onClick={oeffneOffeneOderAbgeschlosseneAuftraege}
          color="yellow"
          size="large"
          outlined
          icon={<BackIcon />}
        />
        <div className="kommunikationskanalHeader__intro">
          <span className="kommunikationskanalHeader__auftrag">
            {anfrage.auftrag.beschreibung}
          </span>
          <span className="kommunikationskanalHeader__handwerker">
            Sie und &nbsp;
            <Link
              className="kommunikationskanalHeader__introLink"
              onClick={anfrage.angebote && showProfilModal}
            >{`${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`}</Link>
          </span>
        </div>
        <Button
          color="yellow"
          size="large"
          outlined
          icon={<HamburgerIcon />}
          onClick={showAnfrageMenu}
        />
        <MobileMenu isVisible={isAnfrageMenuVisible} hide={hideAnfrageMenu}>
          <MobileMenuItem
            onClick={() => {
              hideAnfrageMenu();
              showProfilModal();
            }}
            className="mobileMenu__option--user"
            label="Kontaktdaten anzeigen"
          />
          <MobileMenuItem
            onClick={() => {
              hideAnfrageMenu();
              showAnfragedetailsModal();
            }}
            className="mobileMenu__option--checklist"
            label="Auftragsdetails anzeigen"
          />
          {anfrage.kannAnfrageZurueckgezogenOderAbgelehntWerden() && (
            <MobileMenuItem
              onClick={() => {
                hideAnfrageMenu();
                showAnfrageZurueckziehenOderAblehnenDialog();
              }}
              className="mobileMenu__option--cancelChat"
              label="Anfrage ablehnen"
            />
          )}
          <MobileMenuItem
            onClick={() => {
              hideAnfrageMenu();
              showProblemMeldenDialog();
            }}
            className="mobileMenu__option--helpcenter"
            label="Problem melden"
          />
        </MobileMenu>
      </HideAt>

      <ShowAt breakpoint="xlarge">
        <div className="kommunikationskanalHeader__actions">
          <Link
            className="kommunikationskanalHeader__backLink"
            onClick={oeffneOffeneOderAbgeschlosseneAuftraege}
            icon={<BackIcon />}
          >
            Zurück zur Übersicht
          </Link>
          <Button
            color="yellow"
            size="small"
            icon={<HelpCenterIcon />}
            onClick={showProblemMeldenDialog}
          >
            Problem melden
          </Button>
        </div>
        <div className="kommunikationskanalHeader__auftrag">
          Kundenanfrage: {anfrage.auftrag.beschreibung}
        </div>
      </ShowAt>

      {isAnfrageZurueckziehenOderAblehnenDialogShown && (
        <AnfrageZurueckziehenOderAblehnenDialog
          hide={hideAnfrageZurueckziehenOderAblehnenDialog}
          anfrage={anfrage}
          autor={AUTOR}
        />
      )}
      {isProblemMeldenDialogShown && (
        <ProblemMeldenDialog hide={hideProblemMeldenDialog} anfrage={anfrage} />
      )}
      {isProfilModalShown && (
        <ProfilModal hide={hideProfilModal} kunde={anfrage.auftrag.kunde} />
      )}
      {isAnfragedetailsModalShown && (
        <AnfragedetailsModal
          hide={hideAnfragedetailsModal}
          anfrage={anfrage}
          auftrag={anfrage.auftrag}
        />
      )}
    </section>
  );
};

export default KommunikationskanalHeader;
