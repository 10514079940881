import React from 'react';
import { Accordion, Entry } from '../Accordion/Accordion';
import './Leistungsuebersicht.scss';

export default function Leistungsuebersicht() {
  return (
    <Accordion gtmCategory="leistungsuebersicht" gtmAction="ausgewählt">
      <Entry title="Dachdecker">
        <ul
          className="leistungsuebersicht__liste"
          data-cy-id="leistungsuebersichtDachdecker"
        >
          <li className="leistungsuebersicht__liste--included">
            Decken und Bekleiden von Dach- und Wandflächen mit Dachziegeln,
            -steinen, Schindeln, Schiefern oder anderen Werkstoffen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Abdichten von Dach- und Wandflächen, Balkone und Terrassen und
            Durchführung zusätzlicher regensichernder Maßnahmen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Erneuern und Bearbeiten von Balkonabdeckungen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Herstellung und Montage von Unterkonstruktionen für
            Außenwandbekleidungen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Herstellung von Dachbegrünungen und Vorbereitung der Dachflächen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Montage von Blitzschutzanlagen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Durchführung energetischer Maßnahmen an Dächern und Wänden (z.B.
            Einbau von Wärmedämmungen)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation von Solarthermie- und Photovoltaikanlagen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Herstellung von Dachöffnungen sowie Einbau von Dachflächenfenster,
            Lichtkuppeln, Dachausstiegsfenster und andere Belichtungs- sowie
            Belüftungselementen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Herstellung und Einbau von Anlagen zur Ableitung von
            Niederschlagswasser (z.B. Dachrinnen, Regenfallrohre)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Verkleidung und Abdeckungen von Kaminen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Wartung und Instandhaltung von Dach- und Wandflächen
          </li>
        </ul>
      </Entry>
      <Entry title="Maler">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Beratung des Kunden über passende Farben und Maltechniken, geeignete
            Tapeten oder Bodenbeläge
          </li>
          <li className="leistungsuebersicht__liste--included">
            Bekleidung und Beschichtung von Innenwände, Decken, Böden und
            Fassaden von Gebäuden
          </li>
          <li className="leistungsuebersicht__liste--included">
            Gestaltung von Innenräumen mit Farbe, Dekorputz oder Stuckelementen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Prüfen und Vorbereiten der Untergründe die weiteren Arbeiten
            vorbereiten (z.B. Untergründe ausgleichen, Grundierungen aufbringen)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Verlegen von Decken-, Wand- und Bodenbeläge (z.B. Tapezierarbeiten)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Durchführung von Putz-, Dämm-, Trockenbauarbeiten (z.B.
            Wärmedämmverbundsystem, Lärm- und Schalldämpfung)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Bearbeitung von Holz und Holzwerkstoffe, z.B. Fenster, Türen und
            Zäune, sowie anderer Objekte aus verschiedenen Materialien mit
            Lasuren, Farben oder Lacken
          </li>
        </ul>
      </Entry>
      <Entry title="Elektriker">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Planung von elektrotechnischen Systemen für die Energieversorgung
            und Gebäudetechnik
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation und Inbetriebnahme elektronischer Anlagen und
            Komponenten (z.B. Anlagen zur elektrischen Energieversorgung in
            Gebäuden oder Beleuchtungsanlagen)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Verlegen von elektrischen Leitungen sowie Installation von
            Steckdosen, Lichtschaltern, Sicherungen Telefonanschlüssen,
            Antennen- und Datenkabeln
          </li>
          <li className="leistungsuebersicht__liste--included">
            Montage von Sicherungen und Anschlüssen für Waschmaschinen, Herde,
            Gebäudeleiteinrichtungen und Datennetze
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation von Empfangs- und Kommunikationsanlagen (z.B.
            Türkontrollsysteme)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Inbetriebnahme von Steuerungs- und Regeleinrichtungen für Heizungs-,
            Lüftungs- und Klimaanlagen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Montage und Inbetriebnahme von Anlagen zur Ersatzstromversorgung und
            zur dezentralen Energiegewinnung (z.B. Photovoltaikanlagen )
          </li>
          <li className="leistungsuebersicht__liste--included">
            Prüfungen und Wartungsarbeiten für elektrische Anlagen, Geräte und
            Sicherheitseinrichtungen durchführen
          </li>
          <li className="leistungsuebersicht__liste--excluded">
            Durchführung von Reparaturarbeiten an Haushaltsgeräten
          </li>
        </ul>
      </Entry>
      <Entry title="Fliesenleger">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Gestaltung von Wand- und Bodenfläche sowie Fassaden innerhalb und
            außerhalb des Gebäudes mit Fliesen, Platten und Mosaiken
          </li>
          <li className="leistungsuebersicht__liste--included">
            Bearbeitung des Untergrunds und Dämm- und Sperrschichten verbauen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Herstellung von Randfugen oder bei großen Flächen auch
            Bewegungsfugen sowie Verfugung von fertigen Belägen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Gestalterische Bekleidung von Gebäudeteile wie Säulen, Treppen oder
            Bögen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Bekleidung von Bauteilen mit Natur- und Werksteinen (z.B.
            Betonwerksteinplatten)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Einbau von Dämmstoffen für den Wärme-, Kälte-, Schall- und
            Brandschutz
          </li>
          <li className="leistungsuebersicht__liste--included">
            Sanierung und Instandhaltung von Fliesen-, Platten- und
            Mosaikbelägen
          </li>
        </ul>
      </Entry>
      <Entry title="Bodenleger">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Beratung von Kunden bei der Gestaltung von Bodenbelägen und bei der
            Materialauswahl
          </li>
          <li className="leistungsuebersicht__liste--included">
            Vorbereitung von Untergründen (z.B. Estrich ausgleichen oder
            Unterbodenkonstruktionen herstellen)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Verlegen von Bodenbelägen, wie z.B. Teppich-, Linoleum-, PVC- und
            Korkböden oder Beläge aus anderem Material wie Laminat oder
            Fertigparkett
          </li>
          <li className="leistungsuebersicht__liste--included">
            Anbringen von Sockelleisten und Wandabschlüsse oder Schienen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Reinigung verlegter Böden
          </li>
          <li className="leistungsuebersicht__liste--included">
            Instandsetzung älterer Böden, dabei z.B. Bodenplatten auswechseln,
            ergänzen, nachbearbeiten, Oberflächen behandeln
          </li>
          <li className="leistungsuebersicht__liste--excluded">
            Verlegen von Schiffsboden- oder Stäbchenparkett (bitte wählen Sie
            Handwerk „Schreiner“ auswählen)
          </li>
        </ul>
      </Entry>
      <Entry title="Sanitär-, Heizung-, Klimainstallateur">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Installation von Rohrleitungen und Bauteilen von Wasser- oder
            Luftversorgungssystem oder -entsorgungssystemen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Einbau von Heizungsanlagen und Modernisierung veralteter Heizungen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Aufstellen, Anschließen und Einstellen von Warmwasser- und
            Wasseraufbereitungs-, Brennstoffversorgungs- und anderer
            Energiegewinnungsanlagen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation von Klima- und Lüftungsanlagen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation von Heizkörpern, Verlegen von Fußbodenheizungen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Einbau von sanitären Einrichtungen wie Waschbecken, Duschkabinen
            oder Toiletten, Anbringen von Armaturen und Abflüssen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Dämmung von Warmwasserleitungen und Heizungsrohren, Abdichten von
            Gas- und Wasserleitungen, Durchführen von Dichtheitsprüfungen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Einbau energieeffizienter und nachhaltiger Anlagen wie
            Pelletheizungen, Wärmepumpen oder Solaranlagen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation von Anlagen zur Regen- und Brauchwassernutzung
          </li>
          <li className="leistungsuebersicht__liste--included">
            Inbetriebnahme von Versorgungsanlagen und Einweisung von Kunden in
            die Bedienung
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation eines GROHE Guards
          </li>
          <li className="leistungsuebersicht__liste--included">
            Installation von Rückstauventilen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Wartung, Reinigung und Instandhaltung von Versorgungsanlagen
          </li>
        </ul>
      </Entry>
      <Entry title="Schreiner">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Herstellung von Holzmöbeln (z.B. Schränke, Sitzmöbel, Küchen-,
            Esszimmer-, Schlaf- und Wohnzimmermöbel)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Behandlung von Holzoberflächen (Schleifen, Färben, Beizen, Lasieren,
            Patinieren Veredeln, Polieren und mit Holzschutzmittel behandeln)
          </li>
          <li className="leistungsuebersicht__liste--included">
            Furnierung von Holzwerkstücke 
          </li>
          <li className="leistungsuebersicht__liste--included">
            Herstellung und Montage von Holzverbindungen und Holzbauteilen für
            Ausbau und Innenausbau - z.B. Fenster, Türen, Fußböden und Paneele
          </li>
          <li className="leistungsuebersicht__liste--included">
            Bearbeitung von Kunststoffen, Glas und Metallen für den Ausbau,
            Innenausbau oder Möbelbau
          </li>
          <li className="leistungsuebersicht__liste--included">
            Durchführung von Montagearbeiten für Einbaumöbel, Fenster, Treppen
            und Türen
          </li>
          <li className="leistungsuebersicht__liste--included">
            Reparieren älterer oder beschädigter Möbel
          </li>
          <li className="leistungsuebersicht__liste--included">
            Gestaltung und Herstellung von Werkstücken aus Holz nach
            Kundenwunsch
          </li>
          <li className="leistungsuebersicht__liste--included">
            Verlegen von Schiffsboden- oder Stäbchenparkett
          </li>
          <li className="leistungsuebersicht__liste--included">
            Verkleidung von Balkonen mit Holz
          </li>
        </ul>
      </Entry>

      <Entry title="Hausmeister und weitere Servicepartner">
        <ul className="leistungsuebersicht__liste">
          <li className="leistungsuebersicht__liste--included">
            Kleinreperaturen, die keine Eintragung in das Betriebsverzeichnis
            der Handwerkskammer erfordern, z.B. Reparatur eines Fenstergriffes,
            eines defekten Duschkopfes oder einfache Anprallschäden.
          </li>
          <li className="leistungsuebersicht__liste--included">
            Kleinmontagen, z.B. von Möbeln, Gardinenstangen, Handtuchhaltern
            oder kleinen Gartenhäusern.
          </li>
          <li className="leistungsuebersicht__liste--included">
            Elektroarbeiten mit Nennspannung ≤ 250 V und Leistung ≤ 200 W, z.B.
            das Wechseln einer Glühbirne oder die Montage von Beleuchtungen.
          </li>
          <li className="leistungsuebersicht__liste--included">
            Gartenarbeiten, z.B. Rasenmähen, Hecken und Büsche schneiden,
            Gartenabfälle beseitigen oder Unkraut jäten.
          </li>
          <li className="leistungsuebersicht__liste--included">
            Hofarbeiten, z.B. Reinigung von Hofflächen oder Fußrosten.
          </li>
        </ul>
      </Entry>
    </Accordion>
  );
}
