import { useEffect, useState } from 'react';
import { recursiveQuery } from '../persistence/query';
import { graphqlOperation, API } from 'aws-amplify';
import {
  eventsByBerechtigterKunde,
  eventsByBerechtigterHandwerker,
} from '../graphql/queries';
import { unmarshallEvents, unmarshallEvent } from '../persistence/marshall';
import {
  onCreateEventByHandwerker,
  onCreateEventByKunde,
} from '../graphql/subscriptions';

export function useEvents(benutzerId, istHandwerker) {
  const [events, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    let didCancel = false;
    let onCreateObservable = null;
    async function loadEventsHandwerker() {
      try {
        if (!didCancel) {
          setLoading(true);
        }
        const { eventsByBerechtigterHandwerker: items } = await recursiveQuery(
          graphqlOperation(eventsByBerechtigterHandwerker, {
            berechtigterHandwerker: benutzerId,
          })
        );
        if (!didCancel) {
          setEvents(unmarshallEvents(items));
        }
      } catch (errors) {
        console.log(errors);
      } finally {
        setLoading(false);
      }
    }
    async function loadEventsKunde() {
      try {
        if (!didCancel) {
          setLoading(true);
        }
        const { eventsByBerechtigterKunde: items } = await recursiveQuery(
          graphqlOperation(eventsByBerechtigterKunde, {
            berechtigterKunde: benutzerId,
          })
        );
        if (!didCancel) {
          setEvents(unmarshallEvents(items));
        }
      } catch (errors) {
        console.log(errors);
      } finally {
        setLoading(false);
      }
    }

    if (benutzerId) {
      istHandwerker ? loadEventsHandwerker() : loadEventsKunde();
      onCreateObservable = onCreateEvent(benutzerId, istHandwerker, (event) =>
        setEvents((currentEvents) => [...currentEvents, event])
      );
    }
    return () => {
      didCancel = true;
      if (onCreateObservable !== null) {
        onCreateObservable.unsubscribe();
      }
    };
  }, [benutzerId, istHandwerker]);
  return { events, isLoading };
}

const onCreateEvent = (benutzerId, istHandwerker, callback) => {
  return API.graphql(
    graphqlOperation(
      istHandwerker ? onCreateEventByHandwerker : onCreateEventByKunde,
      istHandwerker
        ? { berechtigterHandwerker: benutzerId }
        : { berechtigterKunde: benutzerId }
    )
  ).subscribe({
    next: (onSubscriptionMessage) => {
      const newEvent =
        onSubscriptionMessage.value.data.onCreateEventByHandwerker ||
        onSubscriptionMessage.value.data.onCreateEventByKunde;
      callback(unmarshallEvent(newEvent));
    },
  });
};
