import React, { useState } from 'react';
import { useAuftraege } from '../../DomainContext';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { gtmEvent } from '../../googleAnalytics';
import './AnfrageZurueckziehenOderAblehnenDialog.scss';

export default ({ hide, anfrage, autor }) => {
  const istHandwerker = autor === 'HANDWERKER';

  const auftraege = useAuftraege();
  const [ablehnungsgruende, setAblehnungsgruende] = useState(new Set());
  const [sonstigerAblehnungsgrund, setSonstigerAblehnungsgrund] = useState('');

  const isAnfrageZurueckziehenOderAblehnenDisabled =
    ablehnungsgruende.size === 0 ||
    (ablehnungsgruende.has('Sonstiges') && sonstigerAblehnungsgrund === '');

  const updateAblehnungsgruende = (event) => {
    const text = event.target.value;
    if (text !== 'Sonstiger Ablehnungsgrund') {
      setSonstigerAblehnungsgrund('');
    }
    setAblehnungsgruende(new Set([text]));
  };

  const createZieheAnfrageZurueckOderLehneAnfrageAb = (
    setLoading
  ) => async () => {
    try {
      setLoading(true);
      const ablehnungsgrund = Array.from(ablehnungsgruende).join(' ');
      if (istHandwerker) {
        await auftraege.lehneAnfrageAb(anfrage, ablehnungsgrund);
      } else {
        await auftraege.zieheAnfrageZurueck(anfrage, ablehnungsgrund);
      }

      gtmEvent({
        category: 'request',
        action: 'conversationEnded',
        label: ablehnungsgrund,
      });
      hide();
    } catch (e) {
      setLoading(false);
    }
  };

  const actions = (setLoading) => (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        color="red"
        data-cy-id="anfrageZurueckziehenOderAblehnenButton"
        onClick={createZieheAnfrageZurueckOderLehneAnfrageAb(setLoading)}
        disabled={isAnfrageZurueckziehenOderAblehnenDisabled}
      >
        {istHandwerker ? 'Jetzt ablehnen' : 'Jetzt zurückziehen'}
      </Button>
    </>
  );

  const ansprechpartner = istHandwerker
    ? `${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`
    : anfrage.handwerker.firmenname;

  return (
    <Modal
      className="modal--dialog"
      title={
        istHandwerker
          ? `Möchten Sie die Anfrage von ${ansprechpartner} wirklich ablehnen?`
          : `Möchten Sie die Anfrage an ${ansprechpartner} wirklich zurückziehen?`
      }
      hide={hide}
      actions={actions}
      gaTrackingName={
        istHandwerker ? 'Anfrage ablehnen' : 'Anfrage zurückziehen'
      }
    >
      <div className="anfrageZurueckziehenOderAblehnen">
        <span>
          {istHandwerker
            ? `Mit dem Ablehnen der Anfrage von ${ansprechpartner} beenden Sie automatisch die Unterhaltung. Der Kunde ${ansprechpartner} wird darüber informiert.`
            : `Mit dem Zurückziehen der Anfrage an ${ansprechpartner} beenden Sie automatisch die Unterhaltung. Die Firma ${ansprechpartner} wird darüber informiert.`}
        </span>
        <p className="anfrageZurueckziehenOderAblehnen__grundAngabe">
          Bitte geben Sie einen Grund an:
        </p>
        <ul className="anfrageZurueckziehenOderAblehnen__grundAuswahl">
          <li className="anfrageZurueckziehenOderAblehnen__grund">
            <label
              htmlFor="ausfuehrungstermin"
              className="anfrageZurueckziehenOderAblehnen__grundLabel"
            >
              <input
                className="anfrageZurueckziehenOderAblehnen__checkbox"
                id="ausfuehrungstermin"
                type="radio"
                name="group"
                value="Wir kommen bezüglich des Ausführungstermins nicht überein."
                onChange={updateAblehnungsgruende}
              />
              Wir kommen bezüglich des Ausführungstermins nicht überein.
            </label>
          </li>
          <li className="anfrageZurueckziehenOderAblehnen__grund">
            <label
              htmlFor="auftragkosten"
              className="anfrageZurueckziehenOderAblehnen__grundLabel"
            >
              <input
                className="anfrageZurueckziehenOderAblehnen__checkbox"
                id="auftragkosten"
                type="radio"
                name="group"
                value="Wir kommen bezüglich der Auftragskosten nicht überein."
                onChange={updateAblehnungsgruende}
              />
              Wir kommen bezüglich der Auftragskosten nicht überein.
            </label>
          </li>
          <li className="anfrageZurueckziehenOderAblehnen__grund">
            <label
              htmlFor="ausfuehrungsdetails"
              className="anfrageZurueckziehenOderAblehnen__grundLabel"
            >
              <input
                className="anfrageZurueckziehenOderAblehnen__checkbox"
                id="ausfuehrungsdetails"
                type="radio"
                name="group"
                value="Wir kommen bezüglich der Auftragsausführungsdetails nicht überein."
                onChange={updateAblehnungsgruende}
              />
              Wir kommen bezüglich der Auftragsausführungsdetails nicht überein.
            </label>
          </li>
          <li className="anfrageZurueckziehenOderAblehnen__grund">
            <label
              htmlFor="sonstiges"
              className="anfrageZurueckziehenOderAblehnen__grundLabel"
            >
              <input
                className="anfrageZurueckziehenOderAblehnen__checkbox"
                id="sonstiges"
                type="radio"
                name="group"
                value="Sonstiger Ablehnungsgrund"
                onChange={updateAblehnungsgruende}
              />
              Sonstiger Ablehnungsgrund
            </label>
            {ablehnungsgruende.has('Sonstiger Ablehnungsgrund') && (
              <textarea
                value={sonstigerAblehnungsgrund}
                onChange={(event) =>
                  setSonstigerAblehnungsgrund(event.target.value)
                }
                className="anfrageZurueckziehenOderAblehnen__sonstigesText"
              />
            )}
          </li>
        </ul>
      </div>
    </Modal>
  );
};
