import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

import Button from '../../../components/Button/Button';
import {
  useBodyScrollPrevention,
  useResetScrollPosition,
} from '../../../hooks';
import { gtmEvent } from '../../../googleAnalytics';
import {
  useAusgewaehlteHandwerker,
  useSendeAnfrage,
  useSpezifikation,
} from './hooks';
import { useBenutzerContext } from '../../../contexts/BenutzerContext';
import bereinigeSpezifikation from './bereinigeSpezifikation';
import { KontaktHinweis } from '../../../components/Spezifikationsassistent/KontaktHinweis';
import { Handwerkerauswahl } from '../../../components/Spezifikationsassistent/Handwerkerauswahl';
import { Schritt } from '../../../components/Spezifikationsassistent/Schritt';
import { SpezifikationsassistentHeader } from '../../../components/Spezifikationsassistent/SpezifikationsassistentHeader';
import {
  Einfachauswahl,
  GemischteAuswahl,
  Mehrfachauswahl,
  Sofortauswahl,
  Textauswahl,
} from '../../../components/Spezifikationsassistent/Auswahl';
import { Standortauswahl } from '../../../components/Spezifikationsassistent/Standortauswahl';
import { Kontaktauswahl } from '../../../components/Spezifikationsassistent/Kontaktauswahl';

import { ReactComponent as QuestionMarkIcon } from '../../../assets/icons/question_mark.svg';

import './Spezifikationsassistent.scss';
import './SpezifikationsassistentInternetExplorer.scss';

export default function Spezifikationsassistent() {
  useBodyScrollPrevention();
  const history = useHistory();
  const { benutzer } = useBenutzerContext();
  const [
    spezifikation,
    waehleWert,
    waehleSofortauswahlwert,
    waehleMehrfachauswahlwert,
    kategorieIndex,
    vorherigerKategorieIndex,
    naechsterKategorieIndex,
    springeZuKategorieIndex,
    schrittIndex,
    schritt,
    persistState,
    clearState,
    isLoading,
  ] = useSpezifikation();

  const spezifikationsassistentRef = useResetScrollPosition(schrittIndex);
  const [
    ausgewaehlteHandwerker,
    waehleHandwerkerAus,
  ] = useAusgewaehlteHandwerker();
  const [gefundeneHandwerker, setGefundeneHandwerker] = useState([]);
  const handleAnfrageSendenClicked = useSendeAnfrage(
    spezifikation,
    ausgewaehlteHandwerker,
    gefundeneHandwerker,
    schrittIndex,
    history,
    clearState
  );
  if (isLoading) {
    return null;
  }
  const schritteAnzeigen =
    schritt &&
    ((schritt.mussAngemeldetSein && benutzer) || !schritt.mussAngemeldetSein);
  const weiterleitungAufAnmeldungAnzeigen =
    schritt && schritt.mussAngemeldetSein && !benutzer;

  return (
    <Div100vh>
      <SpezifikationsassistentHeader
        kategorieIndex={kategorieIndex}
        springeZuKategorieIndex={springeZuKategorieIndex}
        onClose={history.goBack}
      />
      <div className="spezifikationsassistent" ref={spezifikationsassistentRef}>
        {weiterleitungAufAnmeldungAnzeigen && (
          <div className="spezifikationsassistent__schritt">
            <div className="spezifikationsassistent__frage">
              Einen kleinen Moment noch...
            </div>
            <p className="spezifikationsassistent__schritthinweis">
              Bevor wir Ihnen die Handwerkerauswahl zu Ihrer Anfrage anzeigen
              können, müssen Sie sich bitte zunächst registrieren oder anmelden.
            </p>

            <div className="spezifikationsassistent__auswahl spezifikationsassistent__auswahl--textauswahl margin:top:2rem">
              <div className="input__textauswahlbox">
                <h2 className="margin:bottom:1rem">Registrierung</h2>
                <p className="margin:bottom:2rem">
                  Sie sind das erste Mal hier und haben noch kein Konto
                  angelegt? Dann hier entlang.
                </p>
                <Button
                  onClick={() => {
                    gtmEvent({
                      category: 'assistant',
                      action: 'registerClicked',
                    });
                    history.push(
                      `/registrieren?referrer=${encodeURIComponent(
                        '/'
                      )}spezifikationsassistent&hideRoleSwitchTabs=true`
                    );
                  }}
                  color="yellow"
                >
                  Weiter zur Registrierung
                </Button>
              </div>
              <div className="input__textauswahlbox">
                <h2 className="margin:bottom:1rem">Anmeldung</h2>
                <p className="margin:bottom:2rem">
                  Sie sind bereits registriert? Dann melden Sie sich mit Ihrem
                  Konto an.
                </p>
                <Button
                  onClick={() => {
                    gtmEvent({
                      category: 'assistant',
                      action: 'loginClicked',
                    });
                    history.push(
                      `/anmelden?referrer=${encodeURIComponent(
                        '/'
                      )}spezifikationsassistent&hideRoleSwitchTabs=true`
                    );
                  }}
                  color="yellow"
                >
                  Weiter zur Anmeldung
                </Button>
              </div>
            </div>
          </div>
        )}
        {schritteAnzeigen && (
          <Schritt
            schritt={schritt}
            spezifikation={spezifikation}
            key={`schritt-${schrittIndex}`}
            vorherigerKategorieIndex={vorherigerKategorieIndex}
            naechsterKategorieIndex={naechsterKategorieIndex}
            schrittIndex={schrittIndex}
            ausgewaehlteHandwerker={ausgewaehlteHandwerker}
            gefundeneHandwerker={gefundeneHandwerker}
            handleAnfrageSendenClicked={handleAnfrageSendenClicked}
            onSchrittChange={(schrittIndex, kategorieIndex) =>
              persistState({
                spezifikation: bereinigeSpezifikation(spezifikation),
                schrittIndex,
                kategorieIndex,
              })
            }
          >
            {(setLadetext, ladetext) => (
              <>
                {schritt.typ === 'sofortauswahl' && (
                  <Sofortauswahl
                    schritt={schritt}
                    spezifikation={spezifikation}
                    waehleSofortauswahlwert={waehleSofortauswahlwert}
                  />
                )}
                {schritt.typ === 'textauswahl' && (
                  <Textauswahl
                    schritt={schritt}
                    spezifikation={spezifikation}
                    waehleSofortauswahlwert={waehleSofortauswahlwert}
                  />
                )}
                {schritt.typ === 'einfachauswahl' && (
                  <Einfachauswahl
                    schritt={schritt}
                    spezifikation={spezifikation}
                    waehleWert={waehleWert}
                  />
                )}
                {schritt.typ === 'mehrfachauswahl' && (
                  <Mehrfachauswahl
                    schritt={schritt}
                    spezifikation={spezifikation}
                    waehleMehrfachauswahlwert={waehleMehrfachauswahlwert}
                  />
                )}
                {schritt.typ === 'gemischteAuswahl' && (
                  <GemischteAuswahl
                    schritt={schritt}
                    schrittIndex={schrittIndex}
                    spezifikation={spezifikation}
                    waehleWert={waehleWert}
                    waehleMehrfachauswahlwert={waehleMehrfachauswahlwert}
                  />
                )}
                {schritt.typ === 'standortauswahl' && (
                  <Standortauswahl
                    spezifikation={spezifikation}
                    waehleWert={waehleWert}
                  />
                )}
                {schritt.typ === 'kontaktauswahl' && (
                  <Kontaktauswahl
                    eingabe={schritt}
                    spezifikation={spezifikation}
                    waehleWert={waehleWert}
                  />
                )}
                {schritt.typ === 'handwerkerauswahl' && (
                  <Handwerkerauswahl
                    spezifikation={spezifikation}
                    ausgewaehlteHandwerker={ausgewaehlteHandwerker}
                    waehleHandwerkerAus={waehleHandwerkerAus}
                    setGefundeneHandwerker={setGefundeneHandwerker}
                    setLadetext={setLadetext}
                    ladetext={ladetext}
                  />
                )}
              </>
            )}
          </Schritt>
        )}
        {!schritt && <p>Schritt nicht gefunden</p>}
      </div>
      <KontaktHinweis icon={<QuestionMarkIcon />}>Hilfe</KontaktHinweis>
    </Div100vh>
  );
}
