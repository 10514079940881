import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import Amplify, { Analytics } from 'aws-amplify';
import awsmobile from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import Handwerkerkommunikationskanal from './screens/Handwerker/Kommunikationskanal/Kommunikationskanal';
import Auftragsdruck from './screens/Handwerker/Auftragsdruck/Auftragsdruck';
import Download from './screens/Download/Download';
import Kundenkommunikationskanal from './screens/Kunde/Kommunikationskanal/Kommunikationskanal';
import DomainContext, {
  domain,
  useEventDispatcher,
  useHandwerkerverzeichnis,
} from './DomainContext';
import Anfragen from './screens/Handwerker/Anfragen/Anfragen';
import HandwerkerOffeneAuftraege from './screens/Handwerker/OffeneAuftraege/OffeneAuftraege';
import HandwerkerAbgeschlosseneAuftraege from './screens/Handwerker/AbgeschlosseneAuftraege/AbgeschlosseneAuftraege';
import HandwerkerProfilVervollstaendigen from './screens/Handwerker/ProfilVervollstaendigen/ProfilVervollstaendigen';
import WerbeeinwilligungVervollstaendigen from './screens/Kunde/Werbeeinwilligung/WerbeeinwilligungVervollstaendigen';
import { useModalState } from './hooks';
import { BreakpointsProvider } from 'react-with-breakpoints';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { AnfrageUndEventContextProvider } from './contexts/AnfrageUndEventContext';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import Spezifikationsassistent from './screens/Kunde/Spezifikationsassistent/Spezifikationsassistent';
import Widerrufsbelehrung from './screens/Kunde/Widerrufsbelehrung/Widerrufsbelehrung';
import Startseite from './screens/Startseite/Startseite';
import Dachcheck from './screens/Startseite/Dachcheck';
import WillkommenHandwerker from './screens/Startseite/WillkommenHandwerker';
import Werbeeinwilligung from './screens/Kunde/Werbeeinwilligung/Werbeeinwilligung';
import Datenschutz from './screens/Datenschutz/Datenschutz';
import Rechtliches from './screens/Rechtliches/Rechtliches';
import Impressum from './screens/Impressum/Impressum';
import ReactTooltip from 'react-tooltip';
import IEHinweisStoerer from './components/IEHinweisStoerer/IEHinweisStoerer';
import Umfrage from './components/Bewertungen/Portalbewertung/Umfrage';
import {
  EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT,
  EVENT_TYPE_ANGEBOT_GESENDET,
  EVENT_TYPE_ANGEBOT_ANGENOMMEN,
  EVENT_TYPE_RECHNUNG_GESENDET,
} from './persistence/eventDispatcherImpl';
import MeineAuftraege from './screens/Kunde/MeineAuftraege/MeineAuftraege';
import Anmelden from './screens/Anmelden/Anmelden';
import EmailBestaetigen from './screens/Anmelden/EmailBestaetigen';
import { gtmEvent, initializeGoogleTagManager } from './googleAnalytics';
import { initializeMoment } from './dateTime';
import { istProfilVollstaendig } from './components/ProfilModal/validierung';
import {
  BenutzerContextProvider,
  useBenutzerContext,
} from './contexts/BenutzerContext';
import FragmentSupportingSwitch from './components/FragmentSupportingSwitch/FragmentSupportingSwitch';
import { SindSieProvinzialKundeStoerer } from './components/SindSieProvinzialKundeStoerer/SindSieProvinzialKundeStoerer';
import HinweisZurEmailVerwendung from './screens/Anmelden/HinweisZurEmailVerwendung';
import RedirectSolar from './screens/RedirectSolar/RedirectSolar';

Amplify.configure(awsmobile);

initializeGoogleTagManager();
initializeMoment();

Analytics.configure({
  autoSessionRecord: false,
});

const marketingWeiterleitungen = {
  '/info':
    '/?utm_source=mailing&utm_medium=direct-mail&utm_campaign=pr_2020_mzui_beileger-0620&utm_content=link',
  '/kfz':
    '/?utm_source=mailing&utm_medium=direct-mail&utm_campaign=pr_2020_mzui_beileger-kfz-1120&utm_content=link',
  '/leben':
    '/?utm_source=mailing&utm_medium=direct-mail&utm_campaign=pr_2020_mzui_beileger-leben-0021&utm_content=link',
};

const AuthenticatedRoutes = withAuthenticator(
  function AuthenticatedRoutes() {
    const { benutzer, istHandwerker } = useBenutzerContext();

    useEffect(() => {
      if (benutzer && benutzer.id) {
        gtmEvent({
          category: 'account',
          action: 'userType',
          label: istHandwerker ? 'craftsman' : 'customer',
        });
      }
    }, [benutzer, istHandwerker]);

    const eventDispatcher = useEventDispatcher();
    const umfrageEventTypes = [
      EVENT_TYPE_ANGEBOT_GESENDET,
      EVENT_TYPE_ANGEBOT_ANGENOMMEN,
      EVENT_TYPE_RECHNUNG_GESENDET,
      EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT,
    ];
    const [isUmfrageVisible, showUmfrage] = useModalState();
    useEffect(() => {
      let didCancel = false;
      const zeigeUmfrage = () => {
        if (!didCancel) {
          showUmfrage();
        }
      };

      umfrageEventTypes.forEach((eventType) =>
        eventDispatcher.subscribe(eventType, zeigeUmfrage)
      );

      return () => {
        didCancel = true;
        umfrageEventTypes.forEach((eventType) =>
          eventDispatcher.unsubscribe(eventType, zeigeUmfrage)
        );
      };
    }, [eventDispatcher, showUmfrage, umfrageEventTypes]);

    return istHandwerker ? (
      <HandwerkerRoutes isUmfrageVisible={isUmfrageVisible} />
    ) : (
      <KundeRoutes isUmfrageVisible={isUmfrageVisible} />
    );
  },
  false,
  [<AnmeldenAmplifyWrapper overrides={['signIn']} />]
);

function KundeRoutes({ isUmfrageVisible }) {
  const { benutzer: kunde } = useBenutzerContext();

  if (!kunde) {
    return <LoadingIndicator />;
  }
  return (
    <>
      {isUmfrageVisible && <Umfrage />}
      <Switch>
        {kunde.werbeeinwilligung === null && (
          <Route
            path="/kunde/werbeeinwilligungVervollstaendigen"
            exact
            component={WerbeeinwilligungVervollstaendigen}
          />
        )}
        {kunde.werbeeinwilligung === null && (
          <Redirect
            exact
            from="/"
            to="/kunde/werbeeinwilligungVervollstaendigen"
          />
        )}
        <Route path="/meineAuftraege" exact component={MeineAuftraege} />
        <Route
          path="/spezifikationsassistent"
          exact
          component={Spezifikationsassistent}
        />
        <Route
          path="/kommunikationskanal/:anfrageId"
          exact
          component={Kundenkommunikationskanal}
        />
        <Route
          path="/anfragen/:anfrageId"
          exact
          render={(routeProps) => (
            <Kundenkommunikationskanal {...routeProps} view="anfragen" />
          )}
        />
        <Route
          path="/nachrichten/:anfrageId"
          exact
          render={(routeProps) => (
            <Kundenkommunikationskanal {...routeProps} view="nachrichten" />
          )}
        />
        <Route
          path="/widerrufsbelehrung/:handwerkerId"
          exact
          component={Widerrufsbelehrung}
        />
        <Route path="/dachcheck" exact component={Dachcheck} />
        <Route path="/download/:fileKey" exact component={Download} />
        <Route path="/" exact component={Startseite} />
        {marketingUrlWeiterleitungen()}
        <Redirect to="/" />
      </Switch>
    </>
  );
}

function HandwerkerRoutes({ isUmfrageVisible }) {
  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const { benutzer: handwerker } = useBenutzerContext();

  useEffect(() => {
    if (handwerker) {
      handwerkerverzeichnis.meldeHandwerkerAngemeldet(handwerker);
    }
  }, [handwerkerverzeichnis, handwerker]);

  if (!handwerker) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {isUmfrageVisible && <Umfrage />}
      <Switch>
        {!istProfilVollstaendig(handwerker) && (
          <Redirect exact from="/" to="/handwerker/profilVervollstaendigen" />
        )}
        {!istProfilVollstaendig(handwerker) && (
          <Redirect
            exact
            from="/anfragen"
            to="/handwerker/profilVervollstaendigen"
          />
        )}
        <Route
          path="/handwerker/emailBestaetigen"
          exact
          component={EmailBestaetigen}
        />
        <Route path="/anfragen" exact component={Anfragen} />
        <Route
          path="/offeneAuftraege"
          exact
          component={HandwerkerOffeneAuftraege}
        />
        <Route
          path="/handwerker/profilVervollstaendigen"
          exact
          component={HandwerkerProfilVervollstaendigen}
        />
        <Route
          path="/abgeschlosseneAuftraege"
          exact
          component={HandwerkerAbgeschlosseneAuftraege}
        />
        <Route
          path="/kommunikationskanal/:anfrageId"
          exact
          component={Handwerkerkommunikationskanal}
        />
        <Route
          path="/auftragsdruck/:anfrageId"
          exact
          component={Auftragsdruck}
        />
        <Route path="/dachcheck" exact component={Dachcheck} />
        <Route path="/download/:fileKey" exact component={Download} />
        <Route path="/" exact component={WillkommenHandwerker} />
        {marketingUrlWeiterleitungen()}
        <Redirect to="/anfragen" />
      </Switch>
    </>
  );
}

function AnmeldenAmplifyWrapper(props) {
  const location = useLocation();
  if (props.authState === 'signIn') {
    const istHandwerkerUrl = location.pathname?.includes('/handwerker');
    return (
      <Anmelden
        authState="SIGN_IN"
        rolle={istHandwerkerUrl ? 'HANDWERKER' : 'KUNDE'}
      />
    );
  }
  return null;
}

function Routes() {
  const { cognitoUser } = useBenutzerContext();

  return (
    <Router>
      <Header />
      <IEHinweisStoerer />
      <FragmentSupportingSwitch>
        <Route path="/impressum" exact component={Impressum} />
        <Route path="/einwilligung" exact component={Werbeeinwilligung} />
        <Route
          path="/hinweisemailverwendung"
          exact
          component={HinweisZurEmailVerwendung}
        />
        <Route path="/datenschutz" exact component={Datenschutz} />
        <Route
          path="/handwerker/datenschutz"
          exact
          children={(routeProps) => (
            <Datenschutz rolle="HANDWERKER" {...routeProps} />
          )}
        />
        <Route path="/rechtliches" exact component={Rechtliches} />
        <Route
          path="/handwerker/rechtliches"
          exact
          children={(routeProps) => (
            <Rechtliches rolle="HANDWERKER" {...routeProps} />
          )}
        />
        <Redirect
          exact
          from="/service"
          to="/?utm_source=mailing&utm_medium=direct-mail&utm_campaign=pa_2020_mzui_zg3&utm_content=link"
        />
        {!cognitoUser && (
          <>
            <Route
              path="/spezifikationsassistent"
              exact
              component={Spezifikationsassistent}
            />
            <Route
              path="/handwerker/willkommen/:handwerkerdaten"
              exact
              component={WillkommenHandwerker}
            />
            <Route
              path="/handwerker/registrieren/:handwerkerdaten"
              exact
              children={(routeProps) => (
                <Anmelden
                  authState="SIGN_UP"
                  rolle="HANDWERKER"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/registrieren"
              exact
              children={() => <Anmelden authState="SIGN_UP" rolle="KUNDE" />}
            />
            <Route
              path="/handwerker/bestaetigen"
              exact
              children={(routeProps) => (
                <Anmelden
                  authState="CONFIRM_SIGN_UP"
                  rolle="HANDWERKER"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/bestaetigen"
              exact
              children={(routeProps) => (
                <Anmelden
                  authState="CONFIRM_SIGN_UP"
                  rolle="KUNDE"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/handwerker/anmelden"
              exact
              children={(routeProps) => (
                <Anmelden
                  key={String(Math.random())}
                  authState="SIGN_IN"
                  rolle="HANDWERKER"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/handwerker/passwortVergessen"
              exact
              children={(routeProps) => (
                <Anmelden
                  authState="FORGOT_PASSWORD"
                  rolle="HANDWERKER"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/anmelden"
              exact
              children={(routeProps) => (
                <Anmelden
                  key={String(Math.random())}
                  authState="SIGN_IN"
                  rolle="KUNDE"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/passwortVergessen"
              exact
              children={(routeProps) => (
                <Anmelden
                  authState="FORGOT_PASSWORD"
                  rolle="KUNDE"
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/passwortZuruecksetzen"
              exact
              children={(routeProps) => (
                <Anmelden authState="RESET_PASSWORD" {...routeProps} />
              )}
            />
            <Route path="/dachcheck" exact component={Dachcheck} />
            <Route path="/" exact component={Startseite} />
            <Route path="/solar" exact component={RedirectSolar} />
            <Route path="/solar/" exact component={RedirectSolar} />
            <Route
              path="/online"
              exact
              children={() => (
                <>
                  <SindSieProvinzialKundeStoerer />
                  <Startseite />
                </>
              )}
            />
            {marketingUrlWeiterleitungen()}
          </>
        )}
        <AuthenticatedRoutes />
      </FragmentSupportingSwitch>
      <Footer />
    </Router>
  );
}

function marketingUrlWeiterleitungen() {
  return Object.entries(marketingWeiterleitungen).map(([from, to], index) => (
    <Redirect key={index} exact from={from} to={to} />
  ));
}

function App() {
  const breakpoints = {
    small: undefined,
    medium: undefined,
    large: 1024,
    xlarge: Infinity,
  };

  return (
    <BenutzerContextProvider>
      <AnfrageUndEventContextProvider>
        <DomainContext.Provider value={domain}>
          <BreakpointsProvider breakpoints={breakpoints}>
            <ReactTooltip />
            <Routes />
          </BreakpointsProvider>
        </DomainContext.Provider>
      </AnfrageUndEventContextProvider>
    </BenutzerContextProvider>
  );
}

export default App;
