import { useEffect, useState } from 'react';
import { ladeBewertungenZuHandwerker } from '../domain/bewertung';

export function useBewertungen(hwkId) {
  const [bewertungen, setBewertungen] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;

    async function loadBewertungen() {
      try {
        if (!didCancel) {
          setLoading(true);
        }
        // const bewertungen = await ladeBewertungenZuHandwerkerliste(hwkIds);
        const bewertungen = await ladeBewertungenZuHandwerker(hwkId);
        if (!didCancel) {
          setBewertungen(bewertungen);
        }
      } catch (errors) {
        console.log(errors);
      } finally {
        if (!didCancel) {
          setLoading(false);
        }
      }
    }

    // if (hwkIds && hwkIds.length > 0) {
    if (hwkId) {
      loadBewertungen();
    }

    return () => {
      didCancel = true;
    };
  }, [hwkId]);

  return { bewertungen, isLoading };
}
