import React from 'react';
import { useModalState } from '../../hooks';
import AngebotsuebersichtModal from '../AngebotsuebersichtModal/AngebotsuebersichtModal';
import './AngeboteStoerer.scss';

export default function AngeboteStoerer({ anfrage, autor }) {
  const [
    isAngebotsuebersichtModalShown,
    showAngebotsuebersichtModal,
    hideAngebotsuebersichtModal
  ] = useModalState();

  const angebote = anfrage.angebote;
  const angeboteAbgegeben = angebote && angebote.length > 0;
  return angeboteAbgegeben ? (
    <>
      <div className="angebotestoerer" onClick={showAngebotsuebersichtModal}>
        {angebote.length > 1 ? `${angebote.length} Angebote` : `1 Angebot`}
        {autor === 'HANDWERKER' ? ' abgegeben' : ' erhalten'}
      </div>
      {isAngebotsuebersichtModalShown && (
        <AngebotsuebersichtModal
          anfrage={anfrage}
          angebote={angebote}
          autor={autor}
          hide={hideAngebotsuebersichtModal}
        />
      )}
    </>
  ) : null;
}
