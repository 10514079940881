import React from 'react';

export default function KundenText() {
  return (
    <>
      <p className="legalContent__paragraph">
        Wir freuen uns über Ihren Besuch auf unserer Internetseite und Ihrem
        Interesse an unserem Unternehmen. Der Schutz Ihrer personenbezogenen
        Daten ist uns ein wichtiges Anliegen. Nachstehend informieren wir Sie
        gemäß Art. 12, 13 und 21 der Datenschutzgrundverordnung (DSGVO) über den
        Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer
        Website.
      </p>
      <p className="legalContent__paragraph">
        Personenbezogene Daten sind Einzelangaben über persönliche oder
        sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen
        Person. Darunter fallen Informationen wie der bürgerliche Name, die
        Anschrift, die Telefonnummer und das Geburtsdatum.
      </p>
      <h2 className="legalContent__headline2">
        1. Verantwortlicher für die Datenverarbeitung
      </h2>
      <p className="legalContent__paragraph">
        Provinzial Dienstleistungsgesellschaft mbH, Dorotheenstraße 3, 45130
        Essen,
      </p>
      <p className="legalContent__paragraph">Telefon: 0201 8125 200</p>
      <p className="legalContent__paragraph">
        Die <strong>Datenschutzbeauftragte</strong> des Verantwortlichen ist:
        Linda Berndt, ALBAG GmbH, Dorotheenstr. 3, 45130 Essen, Deutschland,
        E-Mail:&nbsp;
        <u>
          <a href="mailto:datenschutz@albag.de">datenschutz@albag.de</a>
        </u>
      </p>
      <h2 className="legalContent__headline2">
        2. Quellen und Arten der genutzten Daten
      </h2>
      <p className="legalContent__paragraph">
        Wir verarbeiten Ihre personenbezogenen Kundendaten, die wir im Rahmen
        Ihrer Registrierung für die Plattform zur Vermittlung von
        Dienstleistungen („MeinZuhauseUndIch“) von Ihnen erhalten haben. Diese
        Daten speichern wir in einem Kundenprofil. Hierzu gehören vor allem Ihre
        Stamm-, Adress- und Kontaktdaten. Weiterhin verarbeiten wir weitere
        personenbezogene Daten in Ihrem Kundenprofil, die Sie individuell
        ergänzen.
      </p>
      <p className="legalContent__paragraph">
        Darüber hinaus prüfen wir im Rahmen Ihrer Registrierung anhand Ihres
        Namens, Ihrer Adresse und Ihres Geburtsdatums, ob Sie zu diesem
        Zeitpunkt über eine Hausrat- oder Wohngebäudeversicherung bei der
        Provinzial Rheinland Versicherung AG verfügen. Bedingungsgemäß stehen
        die Vermittlungsdienste für Handwerksdienstleistungen ausschließlich
        Hausrat- oder Gebäudeversicherungskunden der Provinzial Rheinland zur
        Verfügung. Die Prüfung erfolgt über einen Datenabgleich mit den
        Bestandsdaten der Provinzial Rheinland. Kann anhand Ihrer
        Personenstammdaten keine Zuordnung zu einer Hausrat- oder
        Wohngebäudeversicherung erfolgen, geben wir Ihnen die Möglichkeit, sich
        mit Hilfe Ihrer Versicherungsnummer als Hausrat- oder Wohngebäudekunde
        der Provinzial Rheinland zu verifizieren.
      </p>
      <p className="legalContent__paragraph">
        Schließlich werden Angaben über Art und Inhalt Ihrer Anfragen oder
        Aufträge an den Dienstleister (z.B. Fachpartner) gespeichert, an denen
        Sie als Kunde teilgenommen haben. Hier verarbeiten wir die mit der
        Anfrage oder dem Auftrag verbundene Kommunikation sowie ausgetauschte
        Dokumente (z.B. Angebote, Rechnung), möglicherweise geltend gemachte
        Mängel, die durch Sie erfolgte Bewertung des Auftrages und
        Zahlungsverkehrsdaten.
      </p>
      <h2 className="legalContent__headline2">
        3. Zwecke und Rechtsgrundlagen der Datenverarbeitung
      </h2>
      <p className="legalContent__paragraph">
        Wir verarbeiten Ihre Daten unter Beachtung der
        EU-Datenschutz-Grundverordnung (DS-GVO) und des
        Bundesdatenschutzgesetzes (BDSG).
      </p>
      <h3 className="legalContent__headline3">
        a) Informatorische Nutzung der Website
      </h3>
      <p className="legalContent__paragraph">
        Sie können unsere Website besuchen, ohne Angaben zu Ihrer Person zu
        machen. Wenn Sie unsere Website lediglich informatorisch nutzen (also
        sich nicht anmelden, registrieren oder einen Vertrag abschließen) oder
        uns sonst keine Informationen zu Ihrer Person übermitteln, verarbeiten
        wir keine personenbezogenen Daten, mit Ausnahme der Daten, die Ihr
        Browser übermittelt, um Ihnen den Besuch der Webseite zu ermöglichen
        sowie Informationen, die uns im Rahmen von eingesetzten Cookies zur
        statistischen Analyse der Nutzung unser Website übermittelt werden.
      </p>
      <h4 className="legalContent__headline4">
        i. Technische Bereitstellung der Website
      </h4>
      <p className="legalContent__paragraph">
        Zum Zweck der technischen Bereitstellung der Website ist es
        erforderlich, dass wir bestimmte, automatisch übermittelte Informationen
        von Ihnen verarbeiten, damit Ihr Browser unsere Website anzeigen kann
        und Sie die Website nutzen können. Diese Informationen werden bei jedem
        Aufruf unserer Internetseite automatisch erfasst und in unseren
        Serverlogfiles gespeichert.
      </p>
      <p className="legalContent__paragraph">
        Ferner setzen wir Cookies ein, um Ihnen unsere Webseite zur Nutzung zur
        Verfügung zu stellen. Bei Cookies handelt es sich um Textdateien, die im
        Internetbrowser bzw. vom Internetbrowser bei dem Aufruf einer Website
        auf Ihrem Computersystem gespeichert werden. Ein Cookie enthält eine
        charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
        Browsers beim erneuten Aufrufen der Website ermöglicht.
      </p>
      <p className="legalContent__paragraph">
        Wir speichern Ihre personenbezogenen Daten auch im sogenannten Local
        Storage (auch „Lokale Daten“ und „Lokaler Speicher“ genannt). Dabei
        werden Daten lokal im Cache Ihres Browsers gespeichert, die auch nach
        dem Schließen des Browser-Fensters oder dem Beenden des Programms –
        soweit Sie den Cache nicht löschen - weiterhin bestehen und ausgelesen
        werden können. Dort werden die Benutzerdaten aller jemals in diesem
        Browser angemeldeten Benutzer, sowie die Sitzung des gerade angemeldeten
        Benutzers gespeichert. Die Benutzerdaten werden dabei nicht an unsere
        Server übertragen, lediglich die Sitzung wird an den Server übertragen.
      </p>
      <h5 className="legalContent__headline5">Usercentrics Consent Manager</h5>
      <p className="legalContent__paragraph">
        Auf unserer Website benutzen wir den Consent Manager von Usercentrics
        GmbH, Rosental 4, 80331 München, Deutschland, Email:
        contact@usercentrics.com. Der Consent Manager von Usercentrics ist eine
        Lösung, mit der wir Ihre Einwilligung zu bestimmten,
        einwilligungsbedürftigen Datenverarbeitungen (z. B. Tracking o. Ä.)
        einholen. Es informiert Sie über die einzelnen von uns eingesetzten
        Cookies und Tools und lässt Ihnen die Wahl, welche davon Sie individuell
        oder kategorisch zulassen oder ablehnen. Ebenfalls werden Sie darüber
        informiert, wann Sie welche Cookies/Tools zugelassen oder abgelehnt
        haben. Dies ermöglicht Ihnen eine informierte Entscheidung über die
        Weitergabe Ihrer Daten und uns einen datenschutzkonformen, transparenten
        und dokumentierten Einsatz von Cookies und Tools.
      </p>
      <p className="legalContent__paragraph">
        Der Consent Manager speichert selbst keine personenbezogenen Daten von
        Ihnen. Es werden lediglich anonymisierte Daten generiert, die in einem
        Cookie gespeichert werden. Diese Daten werden ausschließlich in der
        europäischen Union verarbeitet. Bei diesen Daten handelt es sich um
        folgende Informationen:
      </p>
      <ul className="legalContent__list">
        <li>Datum und Uhrzeit Ihres Besuchs</li>
        <li>Informationen über das genutzte Gerät</li>
        <li>Informationen über den Browser, z. B. Browsertyp/ -version</li>
        <li>Ihre anonymisierte IP-Adresse</li>
        <li>Ihre Einwilligung oder Ablehnung von Cookies</li>
      </ul>
      <p className="legalContent__paragraph">
        Weiterführende Informationen sowie die Datenschutzerklärung von
        Usercentrics finden Sie unter:
        <a
          href="https://usercentrics.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://usercentrics.com/privacy-policy/
        </a>
      </p>
      <p className="legalContent__paragraph">
        Ihre Informationen, die wir durch vorstehende Cookies gesammelt haben,
        werden von uns nicht verwendet, um Nutzerprofile zu erstellen oder Ihr
        Surfverhalten auszuwerten.{' '}
      </p>
      <p className="legalContent__paragraph">
        Wir verarbeiten Ihre personenbezogenen Daten zur technischen
        Bereitstellung unserer Website auf Grundlage der folgenden
        Rechtsgrundlagen:
      </p>
      <ul className="legalContent__list">
        <li>
          zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher
          Maßnahmen gemäß Art. 6 Abs. 1 lit. b) DSGVO, soweit Sie unsere Website
          besuchen, um sich über unsere Produkte und unsere Veranstaltungen zu
          informieren;
        </li>
        <li>
          zur Erfüllung einer rechtlichen Verpflichtung, der wir als
          Verantwortlicher unterliegen gem. Art. 6 Abs. 1 lit. c) DSGVO. Die
          rechtliche Verpflichtung liegt in der Einholung und Dokumentation
          Ihrer Einwilligung zur Datenverarbeitung.
        </li>
        <li>
          zur Wahrung unserer berechtigten Interessen gemäß
          Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht
          dabei darin, Ihnen eine ansprechende, technisch funktionierende und
          benutzerfreundliche Website zur Verfügung stellen zu können sowie um
          Maßnahmen zum Schutz unserer Website vor Cyberrisiken zu ergreifen und
          zu verhindern, dass von unserer Website Cyberrisiken für Dritte
          ausgehen.
        </li>
      </ul>
      <h4 className="legalContent__headline4">
        ii. Statistische Analyse der Nutzung der Website und Reichweitenerhöhung
      </h4>
      <p className="legalContent__paragraph">
        Zum Zweck der statistischen Analyse der Nutzung unserer Website setzen
        wir Google Analytics und damit Cookies ein, die eine Analyse Ihres
        Surfverhaltens ermöglichen. Dadurch können wir die Qualität unserer
        Website und ihrer Inhalte verbessern. Wir erfahren, wie die Website
        genutzt wird und können so unser Angebot stetig optimieren.
      </p>
      <p className="legalContent__paragraph">
        Die im Rahmen der statistischen Analyse unserer Website erlangten
        Informationen werden nicht mit Ihren sonstigen, im Rahmen der Website
        erfassten Daten zusammengeführt.
      </p>
      <h5 className="legalContent__headline5">
        Weitere Informationen zu Datentransfers in die USA
      </h5>
      <p className="legalContent__paragraph">
        Mit Urteil vom 16.07.2020 (Az: C‑311/18) hat der Europäische Gerichtshof
        (EuGH) das sog. EU-US Privacy Shield Abkommen, das bislang
        Rechtsgrundlage für eine Datenübermittlung in die Vereinigten Staaten
        (USA) bildete, für ungültig erklärt.
      </p>
      <p className="legalContent__paragraph">
        Nach der Datenschutzgrundverordnung (DSGVO) dürfen personenbezogene
        Daten nur dann in ein Drittland übermittelt werden, wenn in dem
        Empfängerland oder bei dem Empfänger ein ausreichendes Datenschutzniveau
        gewährleistet wird. Das ist nach Auffassung des EuGH in den USA aufgrund
        der weitreichenden Zugriffsbefugnisse der US-Sicherheitsbehörden auf
        elektronisch gespeicherte Daten und fehlenden Rechtsschutzmöglichkeiten
        nicht der Fall. Insbesondere haben Betroffene keine Möglichkeit
        gegenüber amerikanischen Behörden aufgrund nachrichtendienstlicher
        Überwachungsmaßnahmen Rechte gerichtlich geltend zu machen oder
        durchzusetzen. Die Rechtsschutzmöglichkeiten der Betroffenen sind auf
        ein Ombudsmann-Verfahren beschränkt, welches den Ombudsmann soweit
        ersichtlich allerdings nicht ermächtigt, verbindliche Entscheidungen
        gegenüber Nachrichtendiensten zu treffen und das dem europäischen
        Verständnis eines unabhängigen Richters nicht entspricht. Zudem ist nach
        den geltenden amerikanischen Vorschriften die Datenverarbeitung
        derartiger Überwachungsmaßnahmen nicht auf das zwingend erforderliche
        Maß beschränkt und genügt daher nicht den nach dem Grundsatz der
        Verhältnismäßigkeit bestehenden Mindestanforderungen.
      </p>
      <p className="legalContent__paragraph">
        Die anderslautende „Angemessenheitsentscheidung“ der EU-Kommission,
        wonach bei Datentransfers unter dem sog. „Privacy Shield“ ein
        hinreichendes Datenschutzniveau sichergestellt ist, hat der EuGH daher
        mit sofortiger Wirkung für ungültig erklärt.
      </p>
      <p className="legalContent__paragraph">
        Eine Übermittlung personenbezogener Daten in ein Drittland ist trotz
        fehlenden Angemessenheitsbeschlusses oder anderer geeigneter Garantien
        möglich, wenn die betroffene Person gemäß Art. 49 Abs. 1 S. 1 lit. a
        DSGVO in die Datenübermittlung ausdrücklich eingewilligt hat, nachdem
        sie über die für sie bestehenden möglichen Risiken derartiger
        Datenübermittlungen unterrichtet wurde. Wir verweisen nochmals
        ausdrücklich auf die zuvor beschriebenen Rechtsrisiken, insbesondere die
        Zugriffsmöglichkeiten von US-Behörden auf Ihre Daten sowie fehlende
        Rechtsschutzmöglichkeiten hiergegen vorzugehen.
      </p>
      <p className="legalContent__paragraph">
        Mit Ihrem Einverständnis übermitteln wir personenbezogene Daten an
        folgende Unternehmen mit Sitz außerhalb der EU/EWR:
      </p>
      <h5 className="legalContent__headline5">Name: Google Tag Manager</h5>
      <p className="legalContent__paragraph">
        Vertragspartner: Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland
      </p>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Durch den Google Tag Manager Dienst können
        Webseiten-Tags über eine Oberfläche verwaltet werden. Das Tool „Google
        Tag Manager“ implementiert lediglich Tags. Hierbei werden keine Cookies
        eingesetzt und keine personenbezogenen Daten erfasst. Das Tool sorgt für
        die Auslösung anderer Tags, die ihrerseits unter Umständen Daten
        erfassen. „Google Tag Manager“ greift nicht auf diese Daten zu.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Funktional
      </p>
      <h5 className="legalContent__headline5">Name: Google Analytics</h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Google Analytics verwendet Cookies, die auf Ihrem
        Computer gespeichert werden und die eine Analyse darüber ermöglichen,
        wie Sie die Webseite nutzen. Die durch das Cookie erzeugten
        Informationen über Ihre Benutzung unserer Webseite werden in der Regel
        an einen Server von Google in den USA übertragen und dort gespeichert.
        Sowohl Google als auch staatliche Behörden haben gegebenenfalls Zugriff
        auf diese Daten.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Statistik
      </p>
      <h5 className="legalContent__headline5">
        Name: Google Ads Remarketing oder "Ähnliche Zielgruppen"-Funktionen
      </h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Durch diese Technologie werden Sie, wenn Sie
        unsere Webseite und Onlinedienste bereits besucht und sich für das
        Angebot interessiert haben, durch zielgerichtete Werbung auf den Seiten
        des Google Partner Netzwerks erneut angesprochen.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Marketing
      </p>
      <h5 className="legalContent__headline5">Name: Google Signals</h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Google stellt mit Signals Berichte über die
        geräteübergreifenden Nutzeranzahlen, sowie zu verschiedenen Gruppen von
        Nutzern zur Verfügung, welche auf verwendeten unterschiedlichen
        Gerätekombinationen basieren. Um dies zu ermöglichen, nutzt Google die
        standardmäßig aktivierte Option „personalisierte Werbung“ in Ihren
        Google-Konto-Einstellungen. Diese Funktion können Sie in Ihrem Google
        Konto deaktivieren. Google Signals wird nur mit aktivierter
        IP-Anonymisierung eingesetzt. Das bedeutet, dass die IP-Adresse der
        Nutzer innerhalb der Mitgliedsstaaten der EU und des Europäischen
        Wirtschaftsraum gekürzt wird. Durch diese Kürzung entfällt der
        Personenbezug deiner IP-Adresse. Somit können keine Rückschlüsse auf die
        Identität eines einzelnen Nutzers gezogen werden.
      </p>
      <h5 className="legalContent__headline5">Name: DoubeClickFloodlight</h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Durch diese Technologie werden für Sie, wenn Sie
        unsere Webseite und Onlinedienste bereits besucht und sich für das
        Angebot interessiert haben, durch zielgerichtete und relevante Werbung
        auf den Seiten des Google Partner Netzwerks erneut angesprochen.
        Floodlight erkennt über eine Cookie-ID, welche Anzeigen in welchem
        Browser geschaltet werden und kann so verhindern, dass diese mehrfach
        angezeigt werden. Laut Google enthalten Floodlight-Cookies keine
        personenbezogenen Informationen.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Marketing
      </p>
      <h5 className="legalContent__headline5">Name: Google Optimize</h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Wir setzen diesen Dienst ein, um das individuelle
        Nutzererlebnis auf unserer Website zu verbessern. Dazu nutzen wir die
        durch Google Analytics gesetzten Cookies und erhobenen Informationen
        (s.o.), um verschiedenen Nutzergruppen individuelle zum Nutzerverhalten
        passende Funktionen und Ansichten der Seite zur Verfügung zu stellen .
        Darüber hinaus stellen wir im Zuge von A/B-Testings unterschiedliche
        Ausprägungen der Website nicht vorher definierten Nutzergruppen bereit,
        um damit das Onlineangebot stetig zu verbessern.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Statistik &amp; Personalisierung
      </p>
      <h5 className="legalContent__headline5">
        Name: Google Ads Conversion-Tracking
      </h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Von Google Ads wird ein Cookie auf Ihrem Rechner
        gesetzt („Conversion Cookie“), sofern Sie über eine Google-Anzeige auf
        unsere Webseite gelangt sind. Diese Cookies verlieren nach 30 Tagen ihre
        Gültigkeit und werden nicht zur persönlichen Identifizierung verwendet.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Marketing
      </p>
      <h5 className="legalContent__headline5">
        Name: Facebook: Conversion Tracking
      </h5>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Facebook, Inc., 1601 S. California Ave, Palo Alto,
        CA 94304
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Mit Hilfe des Besucheraktions-Pixels können wir
        Ihre Aktionen nachverfolgen, nachdem Sie eine Facebook-Werbeanzeige
        gesehen oder angeklickt haben. So können wir die Wirksamkeit der
        Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
        erfassen. Die so erfassten Daten sind für uns anonym, das heißt wir
        sehen keine personenbezogenen Daten einzelner Nutzer. Diese Daten werden
        jedoch von Facebook gespeichert und verarbeitet, worüber wir Sie
        entsprechend unserem Kenntnisstandunterrichten.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Soziale Medien
      </p>
      <h5 className="legalContent__headline5">Name: Facebook Remarketing</h5>
      <p className="legalContent__paragraph">
        Vertragspartner: Facebook Ireland Limited, 4 Grand Canal Square, Dublin
        2, Irland
      </p>
      <p className="legalContent__paragraph">
        Hauptsitz in den USA: Facebook, Inc., 1601 S. California Ave, Palo Alto,
        CA 94304
      </p>
      <p className="legalContent__paragraph">
        Art der Datenerhebung: Mittels der Remarketing oder Custom-Audience
        Funktion können wir die Nutzer unserer Webseite zielgerichtet mit
        Werbung ansprechen, indem für Nutzer unserer Webseite personalisierte,
        interessenbezogene Werbung-Anzeigen geschaltet werden, wenn sie Facebook
        besuchen. Zur Durchführung der Analyse der Webseiten-Nutzung, welche die
        Grundlage für die Erstellung der interessenbezogenen Werbeanzeigen
        bildet, setzt Facebook sog. Cookies ein. Hierzu speichert Facebook eine
        kleine Datei mit einer Zahlenfolge in den Browsern der Nutzer unserer
        Webseite. Über diese Zahl werden die Besuche der Webseite sowie
        anonymisierte Daten über die Nutzung der Webseite erfasst. Es erfolgt
        keine Speicherung von personenbezogenen Daten der Nutzer unserer
        Webseite. Sie werden lediglich als Nutzer unserer Webseite markiert.
        Sind Sie später bei Facebook eingeloggt, wird eine nicht-reversible und
        damit nicht-personenbezogene Prüfsumme (Profil) aus Ihren Nutzungsdaten
        an Facebook zu Marketing- und Analysezwecken übertragen. Dies ermöglicht
        es, Ihnen Werbeeinblendungen anzuzeigen, die mit hoher
        Wahrscheinlichkeit zuvor aufgerufener Produkt- und Informationsbereiche
        berücksichtigen.
      </p>
      <p className="legalContent__paragraph">
        Zweck der Datenverarbeitung: Soziale Medien
      </p>
      <h5 className="legalContent__headline5">Google Tag Manager</h5>
      <p className="legalContent__paragraph">
        Auf unserer Webseite benutzen wir den Google Tag Manager. Der Google Tag
        Manager ist eine Lösung, mit der Vermarkter Webseiten-Tags über eine
        Oberfläche verwalten können. Der Dienst Google Tag Manager selbst (der
        die Tags implementiert) ist eine Cookie-lose Domain und erfasst keine
        personenbezogenen Daten. Der Dienst Google Tag Manager sorgt für die
        Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen.
        Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain-
        oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
        alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert
        werden.
      </p>
      <h5 className="legalContent__headline5">Google Analytics</h5>
      <p className="legalContent__paragraph">
        Auf unserer Website benutzen wir Google Analytics, einen
        Webanalysedienst der Google Inc. (Google Ireland Limited, Gordon House,
        Barrow Street, Dublin 4, Ireland; „Google“). Google Analytics verwendet
        sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
        und die eine Analyse darüber ermöglichen, wie Sie die Website nutzen.
        Die durch das Cookie erzeugten Informationen über Ihre Benutzung unserer
        Website werden in der Regel an einen Server von Google in den USA
        übertragen und dort gespeichert. Auf unserer Website verwenden wir
        Google Analytics mit der Erweiterung „_anonymizeIP()“. Dadurch wird Ihre
        IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen
        Union oder in anderen Vertragsstaaten des Abkommens über den
        Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
        die volle IP-Adresse an einen Server von Google in den USA übertragen
        und dort gekürzt. Eine direkte Personenbeziehbarkeit kann damit
        ausgeschlossen werden. In unserem Auf-trag wird Google diese
        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
        Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. 
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Google Analytics ausschließlich mit Ihrer Einwilligung ein.
        Sie können eine einmal erteilte Einwilligung jederzeit für die Zukunft
        widerrufen, indem Sie
      </p>
      <ul className="legalContent__list">
        <li>
          die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unser
          Website vollumfänglich werden nutzen können;
        </li>
        <li>
          das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
          und installieren:{' '}
          <a
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>{' '}
          oder über diesen <a title="Cookie-Einstellungen">Link</a> den Consent
          Manager erneut aufrufen und unter der jeweiligen Kategorie das von
          Ihnen zur Einwilligung gesetzte Häkchen wieder entfernen und
          anschließend die Einstellung bestätigen.
        </li>
      </ul>
      <p className="legalContent__paragraph">
        Die in Google Analytics erhobenen Daten werden 26 Monate nach Ihrem
        letzten Besuch auf der Webseite gelöscht.
      </p>
      <p className="legalContent__paragraph">
        Nähere Informationen zu Nutzungsbedingungen und Datenschutz von bzw. bei
        Google Analytics finden Sie unter{' '}
        <a
          href="http://www.google.com/analytics/terms/de.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.com/analytics/terms/de.html
        </a>{' '}
        bzw. unter{' '}
        <a
          href="https://www.google.de/intl/de/policies/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.de/intl/de/policies/
        </a>
      </p>
      <h5 className="legalContent__headline5">
        Demografische Merkmale bei Google Analytics
      </h5>
      <p className="legalContent__paragraph">
        Diese Website nutzt die Funktion “demografische Merkmale” von Google
        Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
        Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
        Daten stammen aus interessenbezogener Werbung von Google sowie aus
        Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
        Person zugeordnet werden. Sie können diese Funktion jederzeit über die
        Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
        Erfassung Ihrer Daten durch folgenden{' '}
        <a title="Cookie-Einstellungen">Link</a>
        unter der Option "Google Ads Remarketing" untersagen.
      </p>
      <h5 className="legalContent__headline5">Google Ads Remarketing</h5>
      <p className="legalContent__paragraph">
        Wir nutzen die Remarketing Technologie von Google. Durch diese
        Technologie werden Nutzer, die die unsere Internetseiten und
        Onlinedienste bereits besucht und sich für das Angebot interessiert
        haben, durch zielgerichtete Werbung auf den Seiten des Google Partner
        Netzwerks erneut angesprochen. Die Einblendung der Werbung erfolgt durch
        den Einsatz von Cookies. Mit Hilfe dieser Cookies kann das
        Nutzerverhalten beim Besuch der Website analysiert und anschließend für
        gezielte Produktempfehlungen und interessenbasierte Werbung genutzt
        werden. Wenn Sie keine interessenbasierte Werbung erhalten möchten,
        können Sie die Verwendung von Cookies durch Google für diese Zwecke
        deaktivieren, indem sie diesen <a title="Cookie-Einstellungen">Link</a>{' '}
        aufrufen.
      </p>
      <h5 className="legalContent__headline5">Google Optimize</h5>
      <p className="legalContent__paragraph">
        Als Bestandteil von Google Analytics nutzen wir zur Optimierung unserer
        Website „Google Optimize“, ebenfalls einen Dienst der Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Ireland. Wir setzen
        diesen Dienst ein, um das individuelle Nutzererlebnis auf unserer
        Website zu verbessern. Dazu nutzen wir die durch Google Analytics
        gesetzten Cookies und erhobenen Informationen (s.o.), um verschiedenen
        Nutzergruppen individuelle zum Nutzerverhalten passende Funktionen und
        Ansichten der Seite zur Verfügung zu stellen . Darüber hinaus stellen
        wir im Zuge von A/B-Testings unterschiedliche Ausprägungen der Website
        nicht vorher definierten Nutzergruppen bereit, um damit das
        Onlineangebot stetig zu verbessern.
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Google Optimize ausschließlich ein, wenn Sie Ihre
        Einwilligung zu Google Analytics (s.o.) erteilt haben. Dementsprechend
        können Sie durch einen Widerruf der Einwilligung in Google Analytics
        auch die Einwilligung in Google Optimize widerrufen.
      </p>
      <h5 className="legalContent__headline5">Google Adwords</h5>
      <p className="legalContent__paragraph">
        Wir nutzen das Online-Werbeprogramm „Google Ads“ und im Rahmen von
        Google Ads das Conversion-Tracking. Das Google Conversion Tracking ist
        ein Analysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain
        View, CA 94043, USA; „Google“). Wenn Sie auf eine von Google geschaltete
        Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem
        Rechner abgelegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit,
        enthalten keine personenbezogenen Daten und dienen somit nicht der
        persönlichen Identifizierung.
      </p>
      <p className="legalContent__paragraph">
        Wenn Sie bestimmte Internetseiten vor unserer Website besuchen und das
        Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass
        Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet
        wurden. Jeder Google Ads-Kunde erhält ein anderes Cookie. Somit besteht
        keine Möglichkeit, dass Cookies über die Websites von Google Ads-Kunden
        nachverfolgt werden können.
      </p>
      <p className="legalContent__paragraph">
        Die Informationen, die mithilfe des Conversion-Cookie eingeholten
        werden, dienen dazu, Conversion-Statistiken für Google Ads-Kunden zu
        erstellen, die sich für Conversion-Tracking entschieden haben. Hierbei
        erfahren die Kunden die Gesamtanzahl der Nutzer, die auf ihre Anzeige
        geklickt haben, zu einer mit einem Conversion-Tracking-Tag versehenen
        Seite weitergeleitet wurden und dort [… z.B. ein Produkt gekauft oder
        sich für einen Newsletter angemeldet haben]. Sie erhalten jedoch keine
        Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
      </p>
      <p className="legalContent__paragraph">
        Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung
        widersprechen, indem Sie die Installation der Cookies durch eine
        entsprechende Einstellung Ihrer Browser Software verhindern
        (Deaktivierungsmöglichkeit). Sie werden dann nicht in die
        Conversion-Tracking Statistiken aufgenommen. Weiterführende
        Informationen sowie die Datenschutzerklärung von Google finden Sie
        unter:{' '}
        <a
          href="http://www.google.com/policies/technologies/ads/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.com/policies/technologies/ads/
        </a>
      </p>
      <p className="legalContent__paragraph">
        <a
          href="http://www.google.de/policies/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.de/policies/privacy/
        </a>
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Google Ads Conversion nur mit Ihrer Einwilligung ein. Eine
        einmal erteilte Einwilligung können Sie jederzeit für die Zukunft
        widerrufen, über diesen <a title="Cookie-Einstellungen">Link</a> den
        Consent Manager erneut aufrufen und unter der jeweiligen Kategorie das
        von Ihnen zur Einwilligung gesetzte Häkchen wieder entfernen und
        anschließend die Einstellung bestätigen.
      </p>
      <h5 className="legalContent__headline5">Google Signals</h5>
      <p className="legalContent__paragraph">
        Neben Google Analytics nutzen wir Google Signals, ebenfalls einen Dienst
        von Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
        Ireland. („Google“). Google stellt mit Signals Berichte über die
        geräteübergreifenden Nutzeranzahlen, sowie zu verschiedenen Gruppen von
        Nutzern zur Verfügung, welche auf verwendeten unterschiedlichen
        Gerätekombinationen basieren. Um dies zu ermöglichen, nutzt Google die
        standardmäßig aktivierte Option „personalisierte Werbung“ in Ihren
        Google-Konto-Einstellungen. Diese Funktion können Sie in Ihrem Google
        Konto deaktivieren. Google Signals wird nur mit aktivierter
        IP-Anonymisierung eingesetzt. Das bedeutet, dass die IP-Adresse der
        Nutzer innerhalb der Mitgliedsstaaten der EU und des Europäischen
        Wirtschaftsraum gekürzt wird. Durch diese Kürzung entfällt der
        Personenbezug deiner IP-Adresse. Somit können keine Rückschlüsse auf die
        Identität eines einzelnen Nutzers gezogen werden. Der Erfassung durch
        Google Signals kann jederzeit widersprochen werden, indem der
        Seitenbesucher "personalisierte Werbung" in seinem Google Konto
        deaktiviert:{' '}
        <a
          href="https://support.google.com/ads/answer/2662922?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/ads/answer/2662922?hl=de
        </a>{' '}
        Um Ihre Privatsphäre auf unserer Seite zusätzlich zu schützen, werden
        Ihre Aktivitäten im Bezug zum Werbenetzwerk auf unserer Seite durch die
        Funktion “Marketing” gesteuert. Möchten Sie keine Verarbeitung Ihrer
        Daten im Werbenetzwerk mit dem Bezug zu unserer Seite, nutzen Sie den
        Consent Manager. Zusätzliche Informationen darüber, wie Google mit Ihren
        personenbezogenen Daten in seinem Werbenetzwerk umgeht, finden Sie
        unter:{' '}
        <a
          href="https://policies.google.com/technologies/ads?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/technologies/ads?hl=de
        </a>
      </p>
      <h5 className="legalContent__headline5">Social Plug-Ins</h5>
      <p className="legalContent__paragraph">
        Wir setzen auf unserer Webseite Social Plugins ("Plugins") mehrerer
        sozialer Netzwerke, Facebook, Instagram, Twitter, Youtube und Xing ein,
        um unsere Website bekannter zu machen. In diesem werblichen Zweck liegt
        unser berechtigtes Interesse gemäß Art. 6 Abs. 1 lit. f DSGVO. Die
        Plugins sind mit einem Logo gekennzeichnet, z. B. ist das Facebook
        Plugin mit einem Facebook-Logo gekennzeichnet. Die Provinzial erfasst
        selbst keine personenbezogenen Daten mittels der Social Plugins oder
        über deren Nutzung. Um zu verhindern, dass Daten ohne Ihr Wissen an die
        sozialen Netzwerke übertragen werden, setzt die Provinzial die
        sogenannte Shariff-Lösung ein. Diese Lösung sorgt dafür, dass zunächst
        keine personenbezogenen Daten an die Anbieter der einzelnen Social
        Plugins weitergegeben werden, wenn Sie die Provinzial-Webseite besuchen.
        Erst wenn Sie eines der Social Plugins anklicken, können Daten an das
        soziale Netzwerk übertragen und dort gespeichert werden. Sind Sie
        bereits in einem oder mehreren sozialen Netzwerken eingeloggt, und
        klicken Sie dann einen Plugin an, erhalten die sozialen Netzwerke die
        Information, dass Sie die entsprechende Seite unserer Webseite
        aufgerufen haben. Somit kann das jeweilige soziale Netzwerk den Besuch
        Ihrem Konto zuordnen. Eine derartige Datenübermittlung können Sie
        vermeiden, indem Sie das Plugin nicht anklicken. Wenn Sie mit den
        Plugins interagieren, zum Beispiel einen Artikel der Webseite teilen,
        wird die entsprechende Information direkt an das soziale Netzwerk
        übermittelt und dort nach den Richtlinien des jeweiligen sozialen
        Netzwerkes gespeichert. Zweck und Umfang der Datenerhebung und die
        weitere Verarbeitung und Nutzung der Daten durch das jeweilige soziale
        Netzwerk sowie Ihre diesbezüglichen Einstellungsmöglichkeiten und Rechte
        zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
        Datenschutzhinweisen des jeweiligen sozialen Netzwerkes.
      </p>
      <h5 className="legalContent__headline5">Facebook</h5>
      <p className="legalContent__paragraph">
        Anbieter: Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
        Ireland Datenschutzhinweise Instagram:{' '}
        <a
          href=" https://help.instagram.com/519522125107875"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://help.instagram.com/519522125107875
        </a>
      </p>
      <h5 className="legalContent__headline5">Instagram</h5>
      <p className="legalContent__paragraph">
        Anbieter: Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
        Ireland Datenschutzhinweise Instagram:{' '}
        <a
          href=" https://help.instagram.com/519522125107875"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://help.instagram.com/519522125107875
        </a>
      </p>
      <h5 className="legalContent__headline5">Twitter</h5>
      <p className="legalContent__paragraph">
        Anbieter: Twitter International Company, One Cumberland Place, Fenian
        Street, Dublin 2, D02 AX07, Ireland Datenschutzhinweise{' '}
        <a
          href="https://www.facebook.com/policy.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/policy.php
        </a>
      </p>
      <h5 className="legalContent__headline5">XING</h5>
      <p className="legalContent__paragraph">
        Anbieter: XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland
        Datenschutzhinweise Xing:{' '}
        <a
          href="https://www.xing.com/app/share?op=data_protection[MJ1]"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.xing.com/app/share?op=data_protection[MJ1]
        </a>
      </p>
      <h5 className="legalContent__headline5">YouTube</h5>
      <p className="legalContent__paragraph">
        Anbieter: YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, USA
        Datenschutzhinweise YouTube:{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy
        </a>
      </p>
      <h5 className="legalContent__headline5">Facebook Conversion Tracking</h5>
      <p className="legalContent__paragraph">
        Darüber hinaus verwenden wir zur Auswertung und zur Unterstützung von
        Online-Marketing-Maßnahmen den „Besucheraktions-Pixel“ der Facebook,
        Inc., 1601 S. California Ave, Palo Alto, CA 94304 , USA (“Facebook”)
        ein. Mit dessen Hilfe können wir die Aktionen von Nutzern nachverfolgen,
        nachdem diese eine Facebook-Werbeanzeige gesehen oder angeklickt haben.
        So können wir die Wirksamkeit der Facebook-Werbeanzeigen für
        statistische und Marktforschungszwecke erfassen. Die so erfassten Daten
        sind für uns anonym, das heißt wir sehen nicht die personenbezogenen
        Daten einzelner Nutzer. Die dadurch generierten Informationen lassen
        sich somit nicht mit einzelnen Nutzern in Verbindung bringen. Diese
        Daten werden jedoch von Facebook gespeichert und verarbeitet, worüber
        wir Sie entsprechend unserem Kenntnisstand unterrichten. Facebook kann
        diese Daten mit Ihrem Facebookkonto verbinden und auch für eigene
        Werbezwecke, entsprechend Facebooks Datenverwendungsrichtlinie verwenden
        (
        <a
          href="https://www.facebook.com/business/gdpr"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/business/gdpr
        </a>
        ). Sie können Facebook sowie dessen Partnern das Schalten von
        Werbeanzeigen auf und außerhalb von Facebook ermöglichen. Es kann ferner
        zu diesen Zwecken ein Cookie auf Ihrem Rechner gespeichert werden.
        Weitere Informationen dazu können Sie in den Datenschutzrichtlinien von
        Facebook abrufen:{' '}
        <a
          href="https://www.facebook.com/policy.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/policy.php
        </a>
      </p>
      <p className="legalContent__paragraph">
        Die Analyse des Nutzerverhaltens mittels Tracking hilft uns, die
        Effektivität unserer Services zu überprüfen, sie zu optimieren und an
        die Bedürfnisse der Nutzer anzupassen sowie Fehler zu beheben. Die
        Rechtsgrundlage für diese Verarbeitung personenbezogener Daten ist Art.
        6 Abs. 1 lit. a (Einwilligung) der EU-Datenschutz-Grundverordnung
        (EU-DSGVO).
      </p>
      <p className="legalContent__paragraph">
        Bitte klicken Sie hier, wenn Sie Ihre Einwilligung zu Conversion Pixel
        widerrufen möchten:{' '}
        <a
          href="https://www.facebook.com/settings?tab=ads"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/settings?tab=ads
        </a>
      </p>
      <h5 className="legalContent__headline5">Facebook Remarketing</h5>
      <p className="legalContent__paragraph">
        Auf unserer Webseite werden die Remarketing- oder „Custom Audience“-
        Funktion von Facebook verwendet. Mittels dieser Funktion können wir die
        Besucher unserer Webseite zielgerichtet mit Werbung ansprechen, indem
        für Besucher unserer Webseite personalisierte, interessenbezogene
        Werbung-Anzeigen geschaltet werden, wenn sie Facebook besuchen. Zur
        Durchführung der Analyse der Webseiten-Nutzung, welche die Grundlage für
        die Erstellung der interessenbezogenen Werbeanzeigen bildet, setzt
        Facebook sog. Cookies ein. Hierzu speichert Facebook eine kleine Datei
        mit einer Zahlenfolge in den Browsern der Besucher unserer Webseite.
        Über diese Zahl werden die Besuche der Webseite sowie anonymisierte
        Daten über die Nutzung der Webseite erfasst. Es erfolgt keine
        Speicherung von personenbezogenen Daten der Besucher der Webseite. Sie
        werden lediglich als Besucher unserer Website markiert. Sind Sie später
        bei Facebook eingeloggt, wird eine nicht-reversible und damit
        nicht-personenbezogene Prüfsumme (Profil) aus Ihren Nutzungsdaten an
        Facebook zu Marketing- und Analysezwecken übertragen. Dies ermöglicht
        es, Ihnen Werbeeinblendungen anzuzeigen, die mit hoher
        Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche
        berücksichtigen.
      </p>
      <p className="legalContent__paragraph">
        Remarketing hilft uns, unsere Werbeanzeigen zu optimieren und den
        Nutzern auf ihre Interessen zugeschnittene Werbung anzuzeigen. Die
        Rechtsgrundlage für diese Verarbeitung personenbezogener Daten ist Art.
        6 Abs. 1 lit. a (Einwilligung) der EU-Datenschutz-Grundverordnung
        (EU-DSGVO).
      </p>
      <p className="legalContent__paragraph">
        Detailliertere Informationen über den Umfang und Zweck der Datenerhebung
        und die weitere Verarbeitung, die Nutzung der Daten durch Facebook sowie
        Ihre Einstellungsmöglichkeiten zur Wahrung Ihrer Privatsphäre entnehmen
        Sie bitte den Datenschutzrichtlinien von Facebook, welche unter{' '}
        <a
          href="https://www.facebook.com/ads/website_custom_audiences/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/ads/website_custom_audiences/
        </a>{' '}
        und{' '}
        <a
          href="https://www.facebook.com/privacy/explanation"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/privacy/explanation
        </a>{' '}
        nachzulesen sind. Möchten Sie der Nutzung von Facebook Website Custom
        Audiences widersprechen, dann können Sie dies unter{' '}
        <a
          href="https://www.facebook.com/ads/website_custom_audiences/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/ads/website_custom_audiences/
        </a>{' '}
        tun.
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Facebook Pixel nur mit Ihrer Einwilligung ein. Eine einmal
        erteilte Einwilligung können Sie jederzeit für die Zukunft widerrufen,
        über diesen diesen <a title="Cookie-Einstellungen"></a> den Consent
        Manager erneut aufrufen und unter der jeweiligen Kategorie das von Ihnen
        zur Einwilligung gesetzte Häkchen wieder entfernen und anschließend die
        Einstellung bestätigen.
      </p>

      <h5 className="legalContent__headline5">Youtube</h5>
      <p className="legalContent__paragraph">
        YouTube mit erweitertem Datenschutz
      </p>
      <p className="legalContent__paragraph">
        Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
        die Google Ireland Limited („Google“), Gordon House, Barrow Street,
        Dublin 4, Irland. Wir nutzen YouTube im erweiterten Datenschutzmodus.
        Dieser Modus bewirkt laut YouTube, dass YouTube keine Informationen über
        die Besucher auf dieser Website speichert, bevor diese sich das Video
        ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
        erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen. So
        stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine
        Verbindung zum Google DoubleClick-Netzwerk her. 9 / 12 Sobald Sie ein
        YouTube-Video auf dieser Website starten, wird eine Verbindung zu den
        Servern von YouTube hergestellt. Dabei wird dem YouTube-Server
        mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie in Ihrem
        YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr
        Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können
        Sie verhindern, indem Sie sich aus Ihrem YouTubeAccount ausloggen. Des
        Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf
        Ihrem Endgerät speichern oder vergleichbare Wiedererkennungstechnologien
        (z.B. Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube
        Informationen über Besucher dieser Website erhalten. Diese Informationen
        werden u. a. verwendet, um Videostatistiken zu erfassen, die
        Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
        Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
        Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss
        haben. Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
        entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
        ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
        Einwilligung ist jederzeit widerrufbar.
      </p>
      <p className="legalContent__paragraph">
        Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
        Datenschutzerklärung unter:{' '}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
      </p>
      <h5 className="legalContent__headline5">Floodlight</h5>
      <p className="legalContent__paragraph">
        Diese Webseite nutzt das Online-Marketing-Tool Floodlight by Google.
        Floodlight setzt Cookies ein, um für die Nutzer relevante Anzeigen zu
        schalten, die Berichte zur Kampagnenleistung zu verbessern oder um zu
        vermeiden, dass ein Nutzer die gleichen Anzeigen mehrmals sieht. Über
        eine Cookie-ID erfasst Google, welche Anzeigen in welchem Browser
        geschaltet werden und kann so verhindern, dass diese mehrfach angezeigt
        werden. Darüber hinaus kann Floodlight mithilfe von Cookie-IDs sog.
        Conversions erfassen, die Bezug zu Anzeigenanfragen haben. Das ist etwa
        der Fall, wenn ein Nutzer eine Floodlight-Anzeige sieht und später mit
        demselben Browser die Webseite des Werbetreibenden aufruft und dort
        etwas kauft. Laut Google enthalten Floodlight-Cookies keine
        personenbezogenen Informationen.
      </p>
      <p className="legalContent__paragraph">
        Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch
        eine direkte Verbindung mit dem Server von Google auf. Durch die
        Einbindung von Floodlight erhält Google die Information, dass Sie den
        entsprechenden Teil unseres Internetauftritts aufgerufen oder eine
        Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Google
        registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst
        wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt
        haben, besteht die Möglichkeit, dass Google Ihre IP-Adresse in Erfahrung
        bringt und speichert.
      </p>
      <p className="legalContent__paragraph">
        Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene
        Weise verhindern:
      </p>
      <p className="legalContent__paragraph">
        a) durch eine entsprechende Einstellung Ihrer Browser-Software,
        insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie
        keine Anzeigen von Drittanbietern erhalten;
      </p>
      <p className="legalContent__paragraph">
        b) durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie
        Ihren Browser so einstellen, dass Cookies von der Domain
        „www.googleadservices.com“ blockiert werden,{' '}
        <a
          href="https://www.google.de/settings/ads"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.de/settings/ads
        </a>
        , wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen;
      </p>
      <p className="legalContent__paragraph">
        c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter,
        die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link{' '}
        <a
          href="http://www.aboutads.info/choices"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.aboutads.info/choices
        </a>
        , wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen;
      </p>
      <p className="legalContent__paragraph">
        d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox,
        Internetexplorer oder Google Chrome unter dem Link{' '}
        <a
          href="http://www.google.com/settings/ads/plugin"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.com/settings/ads/plugin
        </a>
        . Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls
        nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.
        Rechtsgrundlage für die Verarbeitung Ihrer Daten ist eine
        Interessenabwägung, wonach der vorstehend geschilderten Verarbeitung
        Ihrer personenbezogenen Daten keine überwiegenden gegenteiligen
        Interessen Ihrerseits entgegenstehen (Art. 6 Abs. 1 S. 1 lit. f DSGVO).
      </p>
      <p className="legalContent__paragraph">
        Weitere Informationen zu Floodlight by Google erhalten Sie unter{' '}
        <a
          href="https://www.google.de/Floodlightclick"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.de/Floodlightclick
        </a>
        , sowie zum Datenschutz bei Google allgemein:{' '}
        <a
          href="https://www.google.de/intl/de/policies/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.de/intl/de/policies/privacy
        </a>
        . Alternativ können Sie die Webseite der Network Advertising Initiative
        (NAI) unter{' '}
        <a
          href="http://www.networkadvertising.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.networkadvertising.org
        </a>
        besuchen.
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Floodlight nur mit Ihrer Einwilligung ein. Eine einmal
        erteilte Einwilligung können Sie jederzeit für die Zukunft widerrufen,
        über diesen <a title="Cookie-Einstellungen">Link</a> den Consent Manager
        erneut aufrufen und unter der jeweiligen Kategorie das von Ihnen zur
        Einwilligung gesetzte Häkchen wieder entfernen und anschließend die
        Einstellung bestätigen.
      </p>

      <h3 className="legalContent__headline3">b) Aktive Nutzung der Website</h3>
      <p className="legalContent__paragraph">
        Neben der rein informatorischen Nutzung unserer Website können Sie
        unsere Website auch aktiv nutzen. Auch bei der aktiven Nutzung kommen
        die oben in Ziffer a) i) und ii) dargestellten
        Datenverarbeitungsprozesse und Analysetools zum Einsatz.
      </p>
      <p className="legalContent__paragraph">
        Der wesentliche Teil unserer Angebote ist für Sie nur nutzbar, wenn Sie
        sich bei uns registrieren. Dann können Sie Dienstleistungen bzw. die
        Vermittlung von Dienstleistern (z.B. Fachpartner) in Anspruch nehmen.
        Wenn Sie die Vermittlung eines Dienstleisters wünschen, geben wir Ihre
        Kontaktdaten und den Inhalt Ihrer Anfrage an passende Dienstleister
        weiter, damit diese Rückfragen zu Ihrer Anfrage stellen können oder
        Ihnen ein Angebot unterbreiten können. Wir verwenden die von ihnen
        mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur
        Vertragsabwicklung. Ohne die Verarbeitung Ihrer personenbezogenen Daten
        ist der Abschluss bzw. die Durchführung des Vertrages nicht möglich.
      </p>
      <p className="legalContent__paragraph">
        Auf der Grundlage des Nutzungsvertrages nach Art. 6 Abs. 1 b) EU-DSGVO
        leiten wir Ihre im Rahmen Ihrer Plattform-Registrierung erhobenen
        Personenstammdaten sowie Ihre im Rahmen der Plattformnutzung erhobenen
        Anfrage-, Auftrags- und Abwicklungsdaten (z.B. angefragte oder
        beauftragte Handwerks-Leistung, vermittelte Dienstleister,
        Ausführungstermin, Angebots-/Rechnungsbetrag, Zahlungsflussdaten,
        Bewertungen von Dienstleistern etc.) an die Provinzial Rheinland
        Versicherung AG und deren Vermittler, die Sie hinsichtlich Ihrer
        Versicherungsverträge betreuen, zum Zwecke
      </p>
      <ul className="legalContent__list">
        <li>der Bewertung der bestehenden Versicherungsvertragsbeziehungen,</li>
        <li>
          der umfassenden Betreuung und Beratung innerhalb der bestehenden
          Versicherungskundenbeziehung,
        </li>
        <li>
          der Produktentwicklung und der Entwicklung weitergehender
          Serviceleistungen (wie etwa Schadenverhütungsmaßnahmen),
        </li>
        <li>statistischer Auswertungen</li>
        <li>
          und der personalisierten Werbung zu Versicherungsprodukten und
          Servicedienstleistungen
        </li>
      </ul>
      <p className="legalContent__paragraph">
        weiter. Ohne die Verarbeitung Ihrer personenbezogenen Daten ist der
        Abschluss bzw. die Durchführung des Plattformnutzungs-Vertrages nicht
        möglich.
      </p>
      <p className="legalContent__paragraph">
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten Ihre
        Einwilligung einholen, dient uns Art. 6 Abs. 1 lit. a
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Ihre
        Einwilligung holen wir ein, um Sie im Rahmen der Analyse der
        Kundenfreundlichkeit und Effektivität unserer Vermittlungsprozesse gemäß
        Ziffer 3.7 unserer allgemeinen Geschäftsbedingungen zur Nutzung der
        Plattform „MeinZuhauseUndIch“ telefonisch kontaktieren zu können.
      </p>
      <p className="legalContent__paragraph">
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten Ihre
        Einwilligung einholen, dient uns Art. 6 Abs. 1 lit. a
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Dies betrifft
        insbesondere die Nutzung Ihrer Kontaktdaten sowie Auftragsdaten und
        Abwicklungsdaten zu Dienstleistungen (z.B. Handwerksleistungen) durch
        die Provinzial Rheinland und deren Vermittler für personalisierte
        Werbezwecke per Brief, Telefon, E-Mail oder sozialen Medien sowie deren
        Nutzung zum Zwecke der umfassenden Betreuung und Beratung innerhalb der
        Kundenbeziehung, der Bewertung der Versicherungsvertragsbeziehung sowie
        zum Zwecke der Produktentwicklung und statistischer Erhebungen.
      </p>
      <p className="legalContent__paragraph">
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
        rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
        unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage. Eine
        solche rechtliche Verpflichtung kann sich bspw. aus handels- und
        steuerrechtlichen Vorgaben ergeben.
      </p>
      <p className="legalContent__paragraph">
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
        Unternehmens oder eines Dritten erforderlich und überwiegen die
        Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
        erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
        Rechtsgrundlage für die Verarbeitung. Dies kann insbesondere
        erforderlich sein:{' '}
      </p>
      <ul className="legalContent__list">
        <li>zur Geltendmachung rechtlicher Ansprüche</li>
        <li>zur Gewährleistung der IT-Sicherheit und des IT-Betriebs</li>
        <li>für statistische Zwecke</li>
        <li>
          für Zwecke der Direktwerbung sowie Markt- und Meinungsforschung
          (einschließlich Werbeprofiling zum Zwecke einer bedarfsgerechten
          Kundenansprache)
        </li>
      </ul>
      <p className="legalContent__paragraph">
        <strong>
          Sollten wir Ihre personenbezogenen Daten für einen oben nicht
          genannten Zweck verarbeiten wollen, werden wir Sie im Rahmen der
          gesetzlichen Bestimmungen hierüber zuvor informieren.
        </strong>
      </p>
      <h2 className="legalContent__headline2">4. Links</h2>
      <p className="legalContent__paragraph">
        Einige Abschnitte unserer Webseiten beinhalten Links zu den Webseiten
        von Drittanbietern. Diese Webseiten unterliegen eigenen
        Datenschutzgrundsätzen. Wir sind für deren Betrieb einschließlich der
        Datenhandhabung nicht verantwortlich. Wenn Sie Informationen an oder
        über solche Seiten von Drittanbietern senden, sollten Sie die
        Datenschutzerklärungen dieser Seiten prüfen, bevor Sie Ihnen
        Informationen zukommen lassen, die Ihrer Person zugeordnet werden
        können.
      </p>
      <h2 className="legalContent__headline2">Verwendung von Kartendiensten</h2>
      <h2 className="legalContent__headline2">HERE Maps</h2>
      <p className="legalContent__paragraph">
        Wir nutzen auf unserer Website den Kartendienst HERE. Anbieter ist HERE
        Global B.V. , Kennedyplein 222-226, 5611 ZT Eindhoven, Niederlande.
      </p>
      <p className="legalContent__paragraph">
        Bei der Anzeige von Kartenmaterial des Kartendienstes HERE auf der
        Webseite wird eine Verbindung mit dem Server von HERE hergestellt, um
        Ihnen Dienstleister (z.B. Fachpartner) in Ihrer Nähe anzeigen zu können.
        Dabei wird Ihre IP-Adresse zwecks Anzeige des Kartenmaterials an HERE
        übertragen und dort gespeichert. Wir als Webseitenbetreiber haben darauf
        keinen Einfluss.
      </p>
      <p className="legalContent__paragraph">
        Die Datenverarbeitung erfolgt auf der Rechtsgrundlage des Art. 6 Abs .1
        lit b) DSGVO. Sie dient der Durchführung vorvertraglicher Maßnahmen, die
        auf Ihre Suchanfrage hin durch die Nutzung der Plattform erfolgen.
      </p>
      <p className="legalContent__paragraph">
        Mehr Informationen zum Umgang mit Ihren Nutzerdaten finden Sie in der
        Datenschutzerklärung von HERE:{' '}
        <a
          href="https://legal.here.com/de-de/privacy/policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.here.com/de-de/privacy/policy
        </a>
        .
      </p>
      <h2 className="legalContent__headline2">
        5. Kategorien von Empfängern personenbezogener Daten
      </h2>
      <p className="legalContent__paragraph">
        Wir leiten Ihre Daten an Dritte nur weiter, wenn dies zur Erfüllung
        unserer vertraglichen Pflichten erforderlich ist, Sie in die
        Datenweitergabe eingewilligt haben oder wenn gesetzliche Bestimmungen
        dies erlauben.
      </p>
      <p className="legalContent__paragraph">
        Die Unternehmen unserer Unternehmensgruppe ALBAG GmbH, BAGUS GmbH,
        E-REPA GmbH und I-NADIS GmbH, codecentric AG verarbeiten Ihre
        personenbezogenen Daten zur Erfüllung unserer vertraglichen oder
        gesetzlichen Pflichten im Rahmen bestehender
        Auftragsverarbeitungsverträge. Auch andere von uns eingesetzte
        Auftragsverarbeiter (Art. 28 DS-GVO) können Ihre personenbezogenen Daten
        zur Erfüllung unserer vertraglichen oder gesetzlichen Pflichten
        erhalten.
      </p>
      <h5 className="legalContent__headline5">Vermittler:</h5>
      <p className="legalContent__paragraph">
        Soweit Sie hinsichtlich Ihrer Versicherungsverträge von einem Vermittler
        betreut werden und Sie in eine Datenweitergabe eingewilligt haben,
        übermitteln wir Ihre Daten auch an diesen zwecks einer umfassenden
        Betreuung und Beratung innerhalb der Kundenbeziehung.
      </p>
      <h5 className="legalContent__headline5">CHECK24.net Partnerprogramm</h5>
      <p className="legalContent__paragraph">
        Wir nehmen am CHECK24.net Partnerprogramm teil. Auf unseren Seiten
        werden iFrame-Buchungsmasken und andere Werbemittel eingebunden, an
        denen wir über Transaktionen, zum Beispiel durch Leads und Sales, eine
        Werbekostenerstattung erhalten können.
      </p>
      <p>
        Weitere Informationen zur Datennutzung durch CHECK24.net erhalten Sie in
        der Datenschutzerklärung von{' '}
        <a
          href="https://www.check24.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          CHECK24.net
        </a>
        .
      </p>
      <h5 className="legalContent__headline5">Weitere Empfänger:</h5>
      <p className="legalContent__paragraph">
        Darüber hinaus können wir Ihre personenbezogenen Daten an weitere
        Empfänger übermitteln, wie etwa an Behörden zur Erfüllung gesetzlicher
        Mitteilungspﬂichten (z. B. Sozialversicherungsträger, Finanzbehörden
        oder Strafverfolgungsbehörden).
      </p>
      <p className="legalContent__paragraph">
        Eine Datenübermittlung in Drittstaaten (Staaten außerhalb des
        Europäischen Wirtschaftsraums) findet nicht statt.
      </p>
      <h5 className="legalContent__headline5">
        Datenverarbeitung in der Unternehmensgruppe:
      </h5>
      <p className="legalContent__paragraph">
        Im Falle Ihrer Einwilligung übermitteln wir Ihre Daten innerhalb unserer
        Unternehmensgruppe an die Provinzial Rheinland Versicherung AG als
        unseren Kooperationspartner zum Zwecke der umfassenden Betreuung und
        Beratung innerhalb der Kundenbeziehung, der Bewertung der bestehenden
        Versicherungsvertragsbeziehung, der Entwicklung neuer Produkte und
        Servicedienstleitungen und zu personalisierten Werbezwecken.
      </p>
      <h2 className="legalContent__headline2">
        6. Automatisierte Einzelfallentscheidungen
      </h2>
      <p className="legalContent__paragraph">
        Zur Begründung und Durchführung der Geschäftsbeziehung nutzen wir
        grundsätzlich keine automatisierte Entscheidungsfindung gemäß Art. 22
        DS-GVO.
      </p>
      <h2 className="legalContent__headline2">7. Dauer der Datenspeicherung</h2>
      <h3 className="legalContent__headline3">
        a) Informatorische Nutzung der Website
      </h3>
      <p className="legalContent__paragraph">
        Bei der rein informatorischen Nutzung unserer Website speichern wir Ihre
        personenbezogenen Daten auf unseren Servern ausschließlich für die Dauer
        des Besuchs unserer Website. Nachdem Sie unsere Website verlassen haben,
        werden Ihre personenbezogenen Daten unverzüglich gelöscht.
      </p>
      <p className="legalContent__paragraph">
        Von uns installierte Cookies werden in der Regel ebenfalls nach dem
        Verlassen unserer Website gelöscht. Zudem haben Sie jederzeit die
        Möglichkeit, installierte Cookies selbst zu löschen.
      </p>
      <p className="legalContent__paragraph">
        Der Consent-Manager von Usercentrics bewahrt einen Nachweis über den
        Widerruf einer zuvor erteilten Einwilligung für einen Zeitraum von drei
        Jahren auf.{' '}
      </p>
      <h3 className="legalContent__headline3">b) Aktive Nutzung der Website</h3>
      <p className="legalContent__paragraph">
        Wir löschen Ihre personenbezogenen Daten sobald sie für die oben
        genannten Zwecke nicht mehr erforderlich sind. Dabei kann es vorkommen,
        dass personenbezogene Daten für die Zeit aufbewahrt werden, in der
        Ansprüche gegen unser Unternehmen geltend gemacht werden können
        (gesetzliche Verjährungsfrist von drei oder bis zu dreißig Jahren).
        Zudem speichern wir Ihre personenbezogenen Daten, soweit wir dazu
        gesetzlich verpﬂichtet sind. Entsprechende Nachweis- und
        Aufbewahrungspﬂichten ergeben sich unter anderem aus dem
        Handelsgesetzbuch, der Abgabenordnung und dem Geldwäschegesetz. Die
        Speicherfristen betragen danach bis zu zehn Jahren.
      </p>
      <h2 className="legalContent__headline2">8. Betroffenenrechte </h2>
      <p className="legalContent__paragraph">
        Sie können unter der o. g. Adresse Auskunft über die zu Ihrer Person
        gespeicherten Daten verlangen. Darüber hinaus können Sie unter
        bestimmten Voraussetzungen die Berichtigung oder die Löschung Ihrer
        Daten verlangen. Ihnen kann weiterhin ein Recht auf Einschränkung der
        Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von Ihnen
        bereitgestellten Daten in einem strukturierten, gängigen und
        maschinenlesbaren Format zustehen.
      </p>
      <div className="legalContent__box">
        <h2 className="legalContent__headline2">Widerspruchsrecht </h2>
        <p className="legalContent__paragraph">
          <strong>
            Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen
            Daten zu Zwecken der Direktwerbung zu widersprechen.
          </strong>
        </p>
        <p className="legalContent__paragraph">
          <strong>
            Wenn wir Ihre Daten zur Wahrung berechtigter Interessen verarbeiten,
            haben Sie das Recht, dieser Verarbeitung zu widersprechen, wenn sich
            aus Ihrer besonderen Situation Gründe ergeben, die gegen die
            Datenverarbeitung sprechen.
          </strong>
        </p>
      </div>
      <p className="legalContent__paragraph">
        Eine von Ihnen erteilte Einwilligung gemäß Art. 6 Abs. 1 lit. a oder
        Art. 9 Abs. 2 lit. a können Sie jederzeit widerrufen, ohne dass die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung berührt wird.
      </p>
      <h2 className="legalContent__headline2">9. Beschwerderecht </h2>
      <p className="legalContent__paragraph">
        Sie haben die Möglichkeit, sich mit einer Beschwerde an die oben
        genannte Datenschutzbeauftragte oder an eine Datenschutzaufsichtsbehörde
        zu wenden.
      </p>
    </>
  );
}
