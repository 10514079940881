import React, { useState } from 'react';
import classNames from 'classnames';
import { toCustomFormat, toDateString, toTimeString } from '../../dateTime';
import { formatCurrency, formatMwStForAngebot } from '../../money';
import { useAnhangUrl, useDialogState, useModalState } from '../../hooks';
import Button from '../Button/Button';
import AngebotAnnehmenModal from '../Angebot/AngebotAnnehmenModal';
import AngebotAblehnenDialog from '../Angebot/AngebotAblehnenDialog';
import './Event.scss';

import ProfilModal from '../ProfilModal/ProfilModal';
import {
  EVENT_TYPE_ANFRAGE_ABGELEHNT,
  EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN,
  EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN_DURCH_KUNDENBERATER,
  EVENT_TYPE_ANGEBOT_ABGELEHNT,
  EVENT_TYPE_ANGEBOT_ANGENOMMEN,
  EVENT_TYPE_AUFTRAG_WIDERRUFEN,
  EVENT_TYPE_AUFTRAGSABNAHME_ABGELEHNT,
  EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT,
  EVENT_TYPE_AUFTRAGSABNAHME_GESTARTET,
  EVENT_TYPE_HANDWERKER_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT,
  EVENT_TYPE_KUNDE_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT,
  EVENT_TYPE_ZAHLUNGSEINGANG_BESTAETIGT,
  EVENT_TYPE_ANFRAGE_ABGESCHLOSSEN_DURCH_KUNDENBERATER,
} from '../../persistence/eventDispatcherImpl';

import { ReactComponent as KundeIcon } from '../../assets/icons/kunde.svg';
import { ReactComponent as HandwerkerIcon } from '../../assets/icons/handwerker.svg';
import { ReactComponent as PdfFileIcon } from '../../assets/icons/pdf_file.svg';
import { jsonParseWhileString } from '../../collections';
import { useBenutzerContext } from '../../contexts/BenutzerContext';

const HANDWERKER = 'HANDWERKER';
const KUNDE = 'KUNDE';

const SYSTEMNACHRICHTEN_MAP = {
  [EVENT_TYPE_ANFRAGE_ABGELEHNT]: {
    [KUNDE]: (payload) => {
      if (
        payload.ablehnungsgrund === 'Keine Zeit, mein Auftragsbuch ist voll.'
      ) {
        return 'Vielen Dank für Ihre Anfrage. Leider bin ich nicht in der Lage, Ihren Auftrag in dem von Ihnen gewünschten Zeitraum umzusetzen. Unser Ziel ist, dass alle Aufträge zur absoluten Zufriedenheit ausgeführt werden. Dieser Anspruch kann aktuell nicht 100%ig erfüllt werden, daher habe ich Ihre Anfrage abgelehnt.';
      }
      if (payload.ablehnungsgrund === 'Zu weit entfernt.') {
        return 'Vielen Dank für Ihre Anfrage. Leider liegt der Auftragsort, für die von Ihnen anfragte Leistung, außerhalb meines üblichen Geschäftsgebiets. Daher habe ich Ihre Anfrage nicht annehmen können.';
      }
      if (
        payload.ablehnungsgrund === 'Auftrag passt nicht zu meinen Leistungen.'
      ) {
        return 'Vielen Dank für Ihre Anfrage. Leider kann ich die von Ihnen geforderten Arbeiten nicht ausführen, weil die von Ihnen angefragte Leistung nicht meinem Leistungsumfang entspricht. Aus diesem Grund habe ich die Anfrage nicht angenommen.';
      }

      return `Vielen Dank für Ihre Anfrage. Leider kann ich die von Ihnen geforderten Arbeiten aus dem unten genannten Grund nicht annehmen. ${payload.ablehnungsgrund}`;
    },
    [HANDWERKER]: (payload) =>
      `Sie haben die Anfrage soeben abgelehnt und dies folgendermaßen begründet: ${payload.ablehnungsgrund}`,
  },
  [EVENT_TYPE_ANGEBOT_ANGENOMMEN]: {
    [KUNDE]: (payload) =>
      `Sie haben das Angebot vom ${toDateString(
        payload.angebot.createdAt
      )} über ${formatCurrency(
        payload.angebot.brutto
      )} (inkl. ${formatMwStForAngebot(payload.angebot)}) der Firma ${
        payload.anfrage.handwerker.firmenname
      } angenommen und damit den Auftrag erfolgreich erteilt.`,
    [HANDWERKER]: (payload) =>
      `Herzlichen Glückwunsch, ${payload.anfrage.auftrag.kunde.vorname} ${
        payload.anfrage.auftrag.kunde.nachname
      } hat Ihr Angebot vom ${toDateString(
        payload.angebot.createdAt
      )} über ${formatCurrency(
        payload.angebot.brutto
      )} (inkl. ${formatMwStForAngebot(
        payload.angebot
      )}) angenommen und Ihnen damit den Auftrag erfolgreich erteilt.`,
  },
  [EVENT_TYPE_ANGEBOT_ABGELEHNT]: {
    [KUNDE]: (payload) =>
      `Sie haben das Angebot vom ${toDateString(
        payload.angebot.createdAt
      )} über ${formatCurrency(
        payload.angebot.brutto
      )} (inkl. ${formatMwStForAngebot(payload.angebot)}) der Firma ${
        payload.anfrage.handwerker.firmenname
      } abgelehnt. Teilen Sie dem Fachpartner bitte auch den Grund dafür mit.`,
    [HANDWERKER]: (payload) =>
      `${payload.anfrage.auftrag.kunde.vorname} ${
        payload.anfrage.auftrag.kunde.nachname
      } hat Ihr Angebot vom ${toDateString(
        payload.angebot.createdAt
      )} über ${formatCurrency(
        payload.angebot.brutto
      )} (inkl. ${formatMwStForAngebot(payload.angebot)}) leider abgelehnt. `,
  },
  [EVENT_TYPE_AUFTRAGSABNAHME_GESTARTET]: {
    [KUNDE]: (payload) =>
      `Die Firma ${payload.anfrage.handwerker.firmenname} hat Sie um die Abnahme des Auftrags gebeten.`,
    [HANDWERKER]: (payload) =>
      `Sie haben ${payload.anfrage.auftrag.kunde.vorname} ${payload.anfrage.auftrag.kunde.nachname} um die Abnahme des Auftrags gebeten.`,
  },
  [EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT]: {
    [KUNDE]: (payload) =>
      `Sie haben den Auftrag der Firma ${payload.anfrage.handwerker.firmenname} abgenommen.`,
    [HANDWERKER]: (payload) =>
      `${payload.anfrage.auftrag.kunde.vorname} ${payload.anfrage.auftrag.kunde.nachname} hat den Auftrag abgenommen.`,
  },
  [EVENT_TYPE_AUFTRAGSABNAHME_ABGELEHNT]: {
    [KUNDE]: (payload) =>
      `Sie haben die Abnahme des Auftrags der Firma ${payload.anfrage.handwerker.firmenname} abgelehnt.`,
    [HANDWERKER]: (payload) =>
      `${payload.anfrage.auftrag.kunde.vorname} ${payload.anfrage.auftrag.kunde.nachname} hat die Abnahme des Auftrags abgelehnt.`,
  },
  [EVENT_TYPE_ZAHLUNGSEINGANG_BESTAETIGT]: {
    [KUNDE]: (payload) =>
      `Die Firma ${payload.anfrage.handwerker.firmenname} hat den Zahlungseingang bestätigt. Der Auftrag ist damit erfolgreich abgeschlossen.`,
    [HANDWERKER]: () =>
      `Sie haben den Zahlungseingang bestätigt. Der Auftrag ist damit erfolgreich abgeschlossen.`,
  },
  [EVENT_TYPE_ANFRAGE_ABGESCHLOSSEN_DURCH_KUNDENBERATER]: {
    [KUNDE]: (payload) =>
      `Die Anfrage wurde von einem Servicemitarbeiter nach Rücksprache mit der Firma ${payload.anfrage.handwerker.firmenname} abgeschlossen.`,
    [HANDWERKER]: (payload) =>
      `Die Anfrage wurde von einem Servicemitarbeiter abgeschlossen.`,
  },
  [EVENT_TYPE_AUFTRAG_WIDERRUFEN]: {
    [KUNDE]: (payload) =>
      `Die Firma ${payload.anfrage.handwerker.firmenname} hat den Widerruf Ihres Auftrags bestätigt und wird die bis dahin eventuell angefallenen Kosten in Rechnung stellen.`,
    [HANDWERKER]: (payload) =>
      `Sie haben den Widerruf des Auftrags auf Wunsch von ${payload.anfrage.auftrag.kunde.vorname} ${payload.anfrage.auftrag.kunde.nachname} bestätigt. Sie können die bis dahin eventuell angefallenen Kosten in Rechnung stellen.`,
  },
  [EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN_DURCH_KUNDENBERATER]: {
    [KUNDE]: (payload) =>
      `Die Anfrage wurde von einem Servicemitarbeiter nach Rücksprache mit der Firma ${payload.anfrage.handwerker.firmenname} zurückgezogen.`,
    [HANDWERKER]: (payload) =>
      `Die Anfrage wurde von einem Servicemitarbeiter zurückgezogen.`,
  },
  [EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN]: {
    [KUNDE]: (payload) =>
      `Sie haben die Anfrage soeben zurückgezogen und dies folgendermaßen begründet: ${payload.ablehnungsgrund}`,
    [HANDWERKER]: (payload) =>
      `${payload.anfrage.auftrag.kunde.vorname} ${payload.anfrage.auftrag.kunde.nachname} hat die Anfrage soeben zurückgezogen und dies folgendermaßen begründet: ${payload.ablehnungsgrund}`,
  },
  [EVENT_TYPE_KUNDE_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT]: {
    [KUNDE]: () => '', // Falls der Kunde sich löscht, während er den Kommunikationskanal geöffnet hat
    [HANDWERKER]: (payload) =>
      `${payload.anfrage.auftrag.kunde.vorname} ${payload.anfrage.auftrag.kunde.nachname} hat sein Konto auf der Plattform gelöscht und ist deswegen nicht mehr erreichbar.`,
  },
  [EVENT_TYPE_HANDWERKER_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT]: {
    [KUNDE]: (payload) =>
      `${payload.anfrage.handwerker.firmenname} hat sein Konto auf der Plattform gelöscht und ist deswegen nicht mehr erreichbar.`,
    [HANDWERKER]: () => '', // Falls der Handwerker sich löscht, während er den Kommunikationskanal geöffnet hat
  },
};

const AngebotNachricht = ({ url, side, anfrage, angebotId }) => {
  const [
    isAngebotAnnehmenModalShown,
    showAngebotAnnehmenModal,
    hideAngebotAnnehmenModal,
  ] = useModalState();
  const angebot = anfrage?.angebote?.find(
    (angebot) => angebot.id === angebotId
  );

  const [anzunehmendesAngebot, setAnzunehmendesAngebot] = useState(null);
  const [
    anzunehmendesAngebotAnhangUrl,
    setAnzunehmendesAngebotAnhangUrl,
  ] = useState(null);

  const createShowAngebotAnnehmenModal = () => (angebot, angebotAnhangUrl) => {
    setAnzunehmendesAngebot(angebot);
    setAnzunehmendesAngebotAnhangUrl(angebotAnhangUrl);
    showAngebotAnnehmenModal();
  };

  return (
    <>
      {angebot && (
        <>
          <div className="event__angebotOrRechnungContainer">
            <a href={url} target="_blank" rel="noopener noreferrer">
              <PdfFileIcon className="event__angebotOrRechnung" />
            </a>
            {side === 'left' ? (
              <Preise
                angebot={angebot}
                side={side}
                anfrage={anfrage}
                url={url}
                showAngebotAnnehmenModal={createShowAngebotAnnehmenModal()}
              />
            ) : undefined}
          </div>
          <div
            className="event__angebotOrRechnungDateiname"
            data-cy-angebot={angebot.anhang.datei.fileName}
          >
            {angebot.anhang.datei.fileName}
          </div>
          {side === 'right' ? (
            <Preise angebot={angebot} anfrage={anfrage} url={url} />
          ) : undefined}
        </>
      )}
      {isAngebotAnnehmenModalShown && (
        <AngebotAnnehmenModal
          anfrage={anfrage}
          angebot={anzunehmendesAngebot}
          url={anzunehmendesAngebotAnhangUrl}
          hide={hideAngebotAnnehmenModal}
        />
      )}
    </>
  );
};

const RechnungNachricht = ({ url, side, anfrage, rechnungId }) => {
  const rechnung = anfrage.rechnungen.find(
    (rechnung) => rechnung.id === rechnungId
  );
  return (
    <>
      {rechnung && (
        <>
          <div className="event__angebotOrRechnungContainer">
            <a href={url} target="_blank" rel="noopener noreferrer">
              <PdfFileIcon className="event__angebotOrRechnung" />
            </a>
            {side === 'left' ? (
              <Preise
                rechnung={rechnung}
                side={side}
                anfrage={anfrage}
                url={url}
              />
            ) : undefined}
          </div>
          <div
            className="event__angebotOrRechnungDateiname"
            data-cy-rechnung={rechnung.anhang.datei.fileName}
          >
            {rechnung.anhang.datei.fileName}
          </div>
          {side === 'right' ? (
            <Preise rechnung={rechnung} anfrage={anfrage} url={url} />
          ) : undefined}
        </>
      )}
    </>
  );
};

const Preise = ({
  angebot,
  rechnung,
  side,
  anfrage,
  showAngebotAnnehmenModal,
}) => {
  const url = useAnhangUrl(angebot || rechnung);
  const [
    isAngebotAblehnenDialogShown,
    showAngebotAblehnenDialog,
    hideAngebotAblehnenDialog,
  ] = useDialogState();

  const showAngebotActions =
    angebot && anfrage.kannAngebotAngenommenWerden(angebot) && side === 'left';
  const angebotWurdeAngenommenVisible = angebot && angebot.wurdeAngenommen();
  const angebotWurdeAbgelehntVisible = angebot && angebot.wurdeAbgelehnt();
  const werte = angebot ? angebot : rechnung;

  return (
    <div className="event__angebotOrRechnungPreise">
      <div className="event__angebotOrRechnungNetto">
        Nettobetrag:
        <div className="event__angebotOrRechnungNettoBetrag">
          {formatCurrency(werte.netto)}
        </div>
      </div>
      <div className="event__angebotOrRechnungMwst">
        zzgl. {formatMwStForAngebot(werte)}:
        <div className="event__angebotOrRechnungMwstBetrag">
          {formatCurrency(Math.round((werte.brutto - werte.netto) * 100) / 100)}
        </div>
      </div>
      <div className="event__angebotOrRechnungBrutto">
        Endbetrag:
        <div className="event__angebotOrRechnungBruttoBetrag">
          {formatCurrency(werte.brutto)}
        </div>
      </div>
      {showAngebotActions && (
        <div className="event__actions">
          <div>
            <Button
              color="green"
              size="small"
              onClick={() => showAngebotAnnehmenModal(angebot, url)}
              data-cy-id="eventActionAngebotAnnehmen"
              className="event__actions--actionButton"
            >
              Angebot annehmen
            </Button>
          </div>
          <div>
            <Button
              size="small"
              onClick={showAngebotAblehnenDialog}
              data-cy-id="eventActionAngebotAblehnen"
              className="event__actions--actionButton"
            >
              Ablehnen
            </Button>
          </div>
          {isAngebotAblehnenDialogShown && (
            <AngebotAblehnenDialog
              anfrage={anfrage}
              angebot={angebot}
              hide={hideAngebotAblehnenDialog}
            />
          )}
        </div>
      )}
      {angebotWurdeAngenommenVisible && (
        <div className="event__annahmehinweis">
          {toCustomFormat(
            angebot.annahmedatum,
            '[Angenommen am] DD.MM.YYYY [um] HH:mm'
          )}
        </div>
      )}
      {angebotWurdeAbgelehntVisible && (
        <div className="event__ablehnungshinweis">Abgelehnt</div>
      )}
    </div>
  );
};

function Systemnachricht({
  anfrage,
  event,
  payload,
  side,
  systemnachrichtentext,
}) {
  const { angebot, rechnung } = payload;
  const { istHandwerker } = useBenutzerContext();
  const url = useAnhangUrl(angebot || rechnung);
  const date = toTimeString(event.createdAt);

  const [
    isProfilModalShown,
    showProfilModal,
    hideProfilModal,
  ] = useModalState();

  const profilProps = !istHandwerker
    ? { handwerker: anfrage.handwerker }
    : { kunde: anfrage.auftrag.kunde };

  const Icon = istHandwerker ? KundeIcon : HandwerkerIcon;

  const anfrageAngebot = anfrage?.angebote?.find((a) => a.id === angebot?.id);
  const anfrageRechnung = anfrage?.rechnungen?.find(
    (r) => r.id === rechnung?.id
  );

  return systemnachrichtentext || anfrageAngebot || anfrageRechnung ? (
    <div
      className={classNames('event', {
        'event--left': side === 'left',
        'event--right': side === 'right',
        'event--middle': side !== 'left' && side !== 'right',
      })}
    >
      <div className="event__avatar">
        <Button icon={<Icon />} size="extraLarge" onClick={showProfilModal} />
        {isProfilModalShown && (
          <ProfilModal hide={hideProfilModal} {...profilProps} />
        )}
      </div>
      <div className="event__contentWrapper">
        <div className="event__content">
          {systemnachrichtentext ? (
            <>
              {systemnachrichtentext}
              <br />
            </>
          ) : angebot ? (
            <AngebotNachricht
              url={url}
              side={side}
              anfrage={anfrage}
              angebotId={angebot.id}
            />
          ) : rechnung ? (
            <RechnungNachricht
              url={url}
              side={side}
              anfrage={anfrage}
              rechnungId={rechnung.id}
            />
          ) : null}
        </div>
      </div>
      <div className="event__date">{date}</div>
    </div>
  ) : null;
}

export default function Event({ event, anfrage }) {
  const { istHandwerker } = useBenutzerContext();
  const payload = jsonParseWhileString(event.payload);

  const systemnachrichtentext = toSystemnachrichtentext(
    event,
    istHandwerker,
    payload
  );
  const side = sideOf(systemnachrichtentext, istHandwerker);

  return (
    <Systemnachricht
      anfrage={anfrage}
      event={event}
      payload={payload}
      systemnachrichtentext={systemnachrichtentext}
      side={side}
    />
  );
}

function sideOf(systemnachrichtentext, istHandwerker) {
  return systemnachrichtentext ? 'middle' : istHandwerker ? 'right' : 'left';
}

function toSystemnachrichtentext(event, istHandwerker, payload) {
  return (
    SYSTEMNACHRICHTEN_MAP[event.type] &&
    SYSTEMNACHRICHTEN_MAP[event.type][istHandwerker ? HANDWERKER : KUNDE](
      payload
    )
  );
}
