import { EVENT_TYPE_KUNDE_GELOESCHT } from '../persistence/eventDispatcherImpl';

export default class Kundenverzeichnis {
  constructor(auftraege, kundeRepository, eventDispatcher) {
    this.auftraege = auftraege;
    this.kundeRepository = kundeRepository;
    this.eventDispatcher = eventDispatcher;
  }

  async existiertKundeMitId(kundeId) {
    return Boolean(await this.kundeRepository.holeKundeZuId(kundeId));
  }

  async holeKundeZuId(kundeId) {
    return await this.kundeRepository.holeKundeZuId(kundeId);
  }

  async legeKundenAn(kunde) {
    return this.kundeRepository.erzeugeKunde(kunde);
  }

  async istKundeLoeschbar(kundeId) {
    return this.auftraege.existierenAnfragenInAusfuehrungZuKundeId(kundeId);
  }

  async loescheKundendaten(kunde, grund) {
    await this.kundeRepository.speichereKontoloeschgrund(grund);

    await this.aktualisiereKunde(ueberschreibeLoeschbareKundenFelder(kunde));

    await this.meldeKundeGeloescht();
  }

  async aktualisiereKunde(kunde) {
    return this.kundeRepository.aktualisiereKunde(kunde);
  }

  async meldeKundeGeloescht() {
    return this.eventDispatcher.fireEvents([
      {
        type: EVENT_TYPE_KUNDE_GELOESCHT
      }
    ]);
  }
}
export function ueberschreibeLoeschbareKundenFelder(kunde) {
  return {
    ...Object.keys(kunde).reduce((felder, feld) => {
      return { ...felder, [feld]: null };
    }, {}),
    id: kunde.id,
    geloescht: true,
    vorname: kunde.vorname,
    nachname: kunde.nachname,
    createdAt: '1970-01-01T00:00:00.000Z',
    updatedAt: '1970-01-01T00:00:00.000Z'
  };
}
