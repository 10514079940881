import React from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import { HeaderCell, Row, Table } from '../../../components/Table/Table';
import Anfrage, {
  AnfragenActionWrapper,
} from '../../../components/Anfrage/Anfrage';
import KeineAnfragenGefundenPlatzhalter from '../../../components/KeineAnfragenGefundenPlatzhalter/KeineAnfragenGefundenPlatzhalter';
import Benachrichtigung from '../../../components/Benachrichtigung/Benachrichtigung';
import './Anfragen.scss';
import { useGesendeteAnfragen } from '../../../hooks/useAnfragen';

export default function Anfragen() {
  const history = useHistory();
  const passwortZurueckgesetztBenachrichtigungVisible =
    history.location.search &&
    history.location.search.includes('?passwortZurueckgesetzt');
  const emailBestaetigtVisible =
    history.location.search &&
    history.location.search.includes('?email-bestaetigt');

  const anfragen = useGesendeteAnfragen();
  return (
    <>
      <Navbar />
      <div className="anfragen">
        {passwortZurueckgesetztBenachrichtigungVisible && (
          <Benachrichtigung titel="Passwort erfolgreich geändert">
            Sie haben Ihr Passwort erfolgreich geändert. Bitte nutzen Sie ab
            sofort das neue Passwort, um sich anzumelden.
          </Benachrichtigung>
        )}
        {emailBestaetigtVisible && (
          <Benachrichtigung titel="E-Mail-Adresse erfolgreich geändert">
            Sie haben Ihre E-Mail-Adresse erfolgreich geändert und bestätigt.
          </Benachrichtigung>
        )}

        <AnfragenActionWrapper autor="HANDWERKER">
          {(handleAnfrageClick) =>
            anfragen && anfragen.length === 0 ? (
              <KeineAnfragenGefundenPlatzhalter
                title="Sie haben aktuell keine Anfragen"
                subtext="In dieser Übersicht werden alle an Sie gerichteten Anfragen angezeigt. Sie können diese einsehen und entscheiden, ob Sie darauf eingehen oder Anfragen direkt ablehnen."
              />
            ) : (
              <Table>
                <Row>
                  <HeaderCell>Anfrage</HeaderCell>
                  <HeaderCell>Erhalten</HeaderCell>
                  <HeaderCell>Ausführungszeitraum</HeaderCell>
                  <HeaderCell>Auftraggeber</HeaderCell>
                  <HeaderCell>Ort</HeaderCell>
                </Row>
                {anfragen?.map((anfrage) => (
                  <Anfrage
                    key={anfrage.id}
                    autor="HANDWERKER"
                    anfrage={anfrage}
                    spalten={[
                      'anfrage',
                      'erhalten',
                      'ausfuehrungszeitraum',
                      'auftraggeber',
                      'ort',
                    ]}
                    onAnfrageClick={handleAnfrageClick}
                  />
                ))}
              </Table>
            )
          }
        </AnfragenActionWrapper>
      </div>
    </>
  );
}
