import './Anmelden.scss';

import * as mutations from '../../graphql/mutations';

import { API, Auth } from 'aws-amplify';
import { ErrorMessage, SuccessMessage } from './Message';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Base64 } from 'js-base64';
import Benachrichtigung from '../../components/Benachrichtigung/Benachrichtigung';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { TextInput } from '../../components/TextInput/TextInput';
import classNames from 'classnames';
import { gtmEvent } from '../../googleAnalytics';
import newEmailIcon from '../../assets/icons/new-email.svg';
import { pruefeIstHandwerker } from '../../user';
import successIcon from '../../assets/icons/success.svg';

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  emailSentSuccessfully: false,
  resendPossible: true,
  sendDestination: null,
  confirmationSucceeded: false,
  username: null,
};

export const anmeldenReducer = (state, action) => {
  if (action.type === 'RESET_ERROR') {
    return {
      ...state,
      error: null,
      resendPossible: action.resendPossible,
    };
  } else if (action.type === 'ERROR_RECEIVED') {
    return {
      ...state,
      error: action.error,
      isLoading: false,
      resendPossible: true,
    };
  } else if (action.type === 'EMAIL_SENT_SUCCESSFULLY') {
    return {
      ...state,
      emailSentSuccessfully: true,
      isLoading: false,
      sendDestination: action.sendDestination,
    };
  } else if (action.type === 'CONFIRMATION_SUCCEEDED') {
    return {
      ...state,
      confirmationSucceeded: true,
      username: action.username,
      isLoading: false,
    };
  } else if (action.type === 'START_ACTION') {
    return {
      ...state,
      isLoading: true,
    };
  }
  return state;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Anmelden({ authState, rolle }) {
  const anmeldenState = useAnmeldenState();
  const history = useHistory();
  const query = useQuery();
  const { handwerkerdaten } = useParams();

  const username = query.get('username');
  const bestaetigungscode = query.get('bestaetigungscode');
  const referrer = query.get('referrer');
  const hideRoleSwitchTabs = Boolean(query.get('hideRoleSwitchTabs'));

  const erfolgreichAbgemeldetBenachrichtigungVisible =
    history.location.search &&
    history.location.search.includes('?erfolgreichAbgemeldet');

  return (
    <div className="anmelden">
      {erfolgreichAbgemeldetBenachrichtigungVisible && (
        <Benachrichtigung titel="Erfolgreich abgemeldet">
          Sie wurden erfolgreich abgemeldet.
        </Benachrichtigung>
      )}
      {authState === 'SIGN_UP' && rolle === 'HANDWERKER' && (
        <SignUpHandwerker
          {...anmeldenState}
          handwerkerdaten={handwerkerdaten}
        />
      )}
      {authState === 'SIGN_UP' && rolle === 'KUNDE' && (
        <SignUpKunde {...anmeldenState} referrer={referrer} />
      )}
      {authState === 'CONFIRM_SIGN_UP' && (
        <ConfirmSignUp
          {...anmeldenState}
          username={username}
          bestaetigungscode={bestaetigungscode}
          rolle={rolle}
          referrer={referrer}
        />
      )}
      {authState === 'SIGN_IN' && (
        <SignIn
          {...anmeldenState}
          rolle={rolle}
          username={username}
          hideRoleSwitchTabs={hideRoleSwitchTabs}
        />
      )}
      {authState === 'FORGOT_PASSWORD' && (
        <ForgotPassword {...anmeldenState} rolle={rolle} username={username} />
      )}
      {authState === 'RESET_PASSWORD' && (
        <ResetPassword
          {...anmeldenState}
          username={username}
          bestaetigungscode={bestaetigungscode}
        />
      )}
    </div>
  );
}

function useAnmeldenState() {
  const [state, dispatch] = useReducer(anmeldenReducer, INITIAL_STATE);

  const resetError = useCallback((resendPossible = true) => {
    dispatch({
      type: 'RESET_ERROR',
      resendPossible,
    });
  }, []);

  const errorReceived = useCallback((error) => {
    dispatch({
      type: 'ERROR_RECEIVED',
      error,
    });
  }, []);

  const emailSentSuccessfully = useCallback((sendDestination) => {
    dispatch({
      type: 'EMAIL_SENT_SUCCESSFULLY',
      sendDestination,
    });
  }, []);

  const confirmationSucceeded = useCallback((username) => {
    dispatch({
      type: 'CONFIRMATION_SUCCEEDED',
      username,
    });
  }, []);

  const startAction = useCallback(() => {
    dispatch({
      type: 'START_ACTION',
    });
  }, []);

  return {
    state,
    resetError,
    errorReceived,
    emailSentSuccessfully,
    confirmationSucceeded,
    startAction,
  };
}

function Hinweis({ rolle, referrer }) {
  return (
    <div className="anmelden__container anmelden__container--smallPadding anmelden__container--alignCenter">
      {rolle === 'HANDWERKER' ? (
        <span className="anmelden__hinweis anmelden__hinweis--small">
          Sie sind Fachpartner und möchten Teil des PROVINZIAL Handwerkernetzes
          werden? Dann registrieren Sie sich bitte&nbsp;
          <a
            href="https://www.albag.de/handwerkernetz/"
            alt="Link zur ALBAG GmbH"
            target="_blank"
            rel="noopener noreferrer"
            className="anmelden__link--albag"
          >
            hier bei unserem Partner, der ALBAG GmbH
          </a>
          .
        </span>
      ) : (
        <span className="anmelden__hinweis anmelden__hinweis--large">
          Neu hier?{' '}
          <Link
            href={`/registrieren${
              referrer ? `?referrer=${encodeURIComponent(referrer)}` : ''
            }`}
            data-cy-id="registierenLink"
          >
            Jetzt registrieren
          </Link>
        </span>
      )}
    </div>
  );
}

function SignUp({
  handwerkerdaten: blob,
  state,
  resetError,
  errorReceived,
  emailSentSuccessfully,
  startAction,
  rolle,
  headline,
  children,
  referrer,
}) {
  const [firmenname, setFirmenname] = useState('');
  const [hwkId, setHwkId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [vorname, setVorname] = useState('');
  const [nachname, setNachname] = useState('');
  const [isWerbeeinwilligungChecked, setWerbeeinwilligungChecked] = useState(
    false
  );

  useBlob(blob, setFirmenname, setHwkId, setEmail, errorReceived);

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      resetError();
      startAction();
      await Auth.signUp(
        signUpParameters({
          rolle,
          hwkId,
          password,
          firmenname,
          email,
          vorname,
          nachname,
          isWerbeeinwilligungChecked,
          referrer,
        })
      );
      emailSentSuccessfully(email);
    } catch (error) {
      errorReceived(error);
      console.log({ error });
    }
  };

  const handleResendVerificationLink = async (resendPossible = false) => {
    try {
      resetError(resendPossible);
      startAction();
      await Auth.resendSignUp(rolle === 'HANDWERKER' ? hwkId : email);
      emailSentSuccessfully(email);
      gtmEvent({
        category: 'account',
        action: 'registrationLinkResent',
        label: 'afterSignup',
      });
    } catch (error) {
      errorReceived(error);
    }
  };

  return (
    <div className="anmelden__box">
      {state.emailSentSuccessfully ? (
        <Versandbestaetigung
          resendPossible={state.resendPossible}
          error={state.error}
          handleRetry={handleResendVerificationLink}
          headline="Registrierung erfolgreich"
        >
          Vielen Dank. Wir haben Ihnen eine E-Mail an{' '}
          <strong>{state.sendDestination}</strong> gesendet. Bitte klicken Sie
          auf den Link in der E-Mail um Ihre Registrierung abzuschließen.
        </Versandbestaetigung>
      ) : (
        <form data-cy-id="registrierungsformular" onSubmit={handleSignUp}>
          <div className="anmelden__container anmelden__container--vertical anmelden__container--borderBottom anmelden__container--largePadding">
            <h1 className="anmelden__headline1" data-cy-id="anmeldenHeadline">
              {headline}
            </h1>
            {state.error && (
              <ErrorMessage
                error={state.error}
                username={rolle === 'HANDWERKER' ? hwkId : email}
                istHandwerker={rolle === 'HANDWERKER'}
              />
            )}
            {children({
              firmenname,
              setFirmenname,
              hwkId,
              email,
              setEmail,
              password,
              setPassword,
              vorname,
              setVorname,
              nachname,
              setNachname,
              isWerbeeinwilligungChecked,
              setWerbeeinwilligungChecked,
            })}
            <Button
              className="margin:top:1rem"
              color="yellow"
              data-cy-id="signUpButton"
              loading={state.isLoading}
              disabled={state.isLoading}
            >
              Registrieren
            </Button>
            <p className="anmelden__hinweis anmelden__hinweis--small margin:top:2rem">
              Es gelten die{' '}
              <a
                className="anmelden__link"
                href={
                  rolle === 'HANDWEKER'
                    ? '/handwerker/rechtliches'
                    : 'https://meinzuhauseundich.de/allgemeine-geschaeftsbedingungen'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Allgemeinen Geschäftsbedingungen
              </a>
              . Bitte beachten Sie unsere{' '}
              <a
                className="anmelden__link"
                href={
                  rolle === 'HANDWEKER'
                    ? '/handwerker/datenschutz'
                    : 'https://meinzuhauseundich.de/datenschutzerklaerung'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Datenschutzbestimmungen
              </a>{' '}
              und die{' '}
              <a
                className="anmelden__link"
                href={
                  rolle === 'HANDWEKER'
                    ? '/handwerker/rechtliches/#widerrufsbelehrung'
                    : 'https://meinzuhauseundich.de/allgemeine-geschaeftsbedingungen/#widerrufsbelehrung'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Widerrufsbelehrung
              </a>
              .
            </p>
          </div>
        </form>
      )}
    </div>
  );
}

function SignUpHandwerker(props) {
  const { state } = props;

  return (
    <SignUp
      rolle="HANDWERKER"
      headline="Registrierung für Fachpartner"
      {...props}
    >
      {({
        firmenname,
        setFirmenname,
        hwkId,
        email,
        setEmail,
        password,
        setPassword,
      }) => (
        <>
          <p className="anmelden__paragraph margin:top:1rem">
            Bitte überprüfen Sie Ihre Daten auf Richtigkeit.
          </p>
          <TextInput
            data-cy-id="firmenname"
            type="text"
            label="Firmenname"
            labelClassName="anmelden__label"
            value={firmenname}
            onChange={(event) => setFirmenname(event.target.value)}
            required
            disabled={state.isLoading}
          />
          <TextInput
            data-cy-id="hwkNummer"
            type="text"
            label="HWK-Nummer"
            labelClassName="anmelden__label"
            value={hwkId}
            readOnly
            disabled
          />
          <TextInput
            data-cy-id="email"
            type="text"
            label="E-Mail-Adresse"
            labelClassName="anmelden__label"
            autoCorrect="off"
            autoCapitalize="none"
            value={email}
            onChange={(event) => setEmail(event.target.value.trim())}
            required
            disabled={state.isLoading}
          />
          <TextInput
            data-cy-id="password"
            type="password"
            autoComplete="new-password"
            name="password"
            label="Vergeben Sie ein neues Passwort"
            labelClassName="anmelden__label"
            value={password}
            onChange={(event) => setPassword(event.target.value.trim())}
            disabled={state.isLoading}
          />
          <Passworthinweise password={password} />
        </>
      )}
    </SignUp>
  );
}

function signUpParameters({
  rolle,
  hwkId,
  password,
  firmenname,
  email,
  vorname,
  nachname,
  isWerbeeinwilligungChecked,
  referrer = '/',
}) {
  if (rolle === 'HANDWERKER') {
    return {
      username: hwkId,
      password,
      attributes: {
        'custom:firmenname': firmenname,
        'custom:hwk_id': hwkId,
        email: email,
      },
    };
  } else {
    return {
      username: email,
      password,
      attributes: {
        given_name: vorname,
        family_name: nachname,
        'custom:werbeeinwilligung': isWerbeeinwilligungChecked.toString(),
        email,
      },
      clientMetadata: { referrer },
    };
  }
}

function useBlob(blob, setFirmenname, setHwkId, setEmail, errorReceived) {
  useEffect(() => {
    let didCancel;
    if (blob) {
      try {
        if (!didCancel) {
          const handwerkerDaten = JSON.parse(Base64.decode(blob));
          setFirmenname(handwerkerDaten.firmenname);
          setHwkId(handwerkerDaten.hwkId);
          setEmail(handwerkerDaten.email);
        }
      } catch (error) {
        errorReceived(error);
        console.log(error);
      }
    }

    return () => {
      didCancel = true;
    };
  }, [blob, errorReceived, setEmail, setFirmenname, setHwkId]);
}

function SignUpKunde(props) {
  const { state } = props;
  return (
    <SignUp headline="Registrierung" {...props}>
      {({
        email,
        setEmail,
        password,
        setPassword,
        vorname,
        setVorname,
        nachname,
        setNachname,
        isWerbeeinwilligungChecked,
        setWerbeeinwilligungChecked,
      }) => (
        <>
          <TextInput
            data-cy-id="email"
            autoCorrect="off"
            autoCapitalize="none"
            type="text"
            label="E-Mail-Adresse"
            labelClassName="anmelden__label"
            value={email}
            onChange={(event) => setEmail(event.target.value.trim())}
            required
            disabled={state.isLoading}
          />
          <Link
            className="margin:bottom:1.5rem"
            size="small"
            href="/hinweisemailverwendung"
            target="_blank"
            data-cy-id="hinweisZurEMailVerwendung"
          >
            Hinweis zur Verwendung Ihrer E-Mail Adresse
          </Link>
          <TextInput
            data-cy-id="password"
            type="password"
            autoComplete="new-password"
            name="password"
            label="Passwort"
            labelClassName="anmelden__label"
            value={password}
            onChange={(event) => setPassword(event.target.value.trim())}
            required
            disabled={state.isLoading}
          />
          <Passworthinweise password={password} />
          <TextInput
            data-cy-id="vorname"
            type="text"
            label="Vorname"
            labelClassName="anmelden__label"
            value={vorname}
            onChange={(event) => setVorname(event.target.value.trim())}
            required
            disabled={state.isLoading}
          />
          <TextInput
            data-cy-id="nachname"
            type="text"
            label="Nachname"
            labelClassName="anmelden__label"
            value={nachname}
            onChange={(event) => setNachname(event.target.value.trim())}
            required
            disabled={state.isLoading}
          />

          <label
            className="anmelden__werbeeinwilligungLabel"
            htmlFor="werbeeinwilligung"
          >
            <input
              id="werbeeinwilligung"
              className="anmelden__werbeeinwilligungCheckbox"
              type="checkbox"
              checked={isWerbeeinwilligungChecked}
              onChange={(event) => {
                setWerbeeinwilligungChecked(event.target.checked);
              }}
            />
            <span>
              <a href="/einwilligung" target="_blank">
                Einwilligung
              </a>
              &nbsp;in die telefonische Kontaktaufnahme durch die Provinzial zum
              Zwecke der Unterstützung und Optimierung der Vermittlungsprozesse.
            </span>
          </label>
        </>
      )}
    </SignUp>
  );
}

function ConfirmSignUp(props) {
  const {
    state,
    errorReceived,
    confirmationSucceeded,
    startAction,
    username,
    bestaetigungscode,
    resetError,
    rolle,
    emailSentSuccessfully,
    referrer,
  } = props;

  const istHandwerker = rolle === 'HANDWERKER';

  const confirmSignUp = useCallback(
    async (username, code) => {
      try {
        startAction();
        await Auth.confirmSignUp(username, code);
        confirmationSucceeded(username);
        gtmEvent({
          category: 'account',
          action: istHandwerker ? 'craftsmanRegistered' : 'customerRegistered',
        });
      } catch (error) {
        errorReceived(error);
      }
    },
    [startAction, confirmationSucceeded, istHandwerker, errorReceived]
  );

  useEffect(() => {
    confirmSignUp(username, bestaetigungscode);
  }, [username, bestaetigungscode, confirmSignUp]);

  const handleResendVerificationLink = async (resendPossible = true) => {
    try {
      resetError(resendPossible);
      startAction();
      await Auth.resendSignUp(username);
      emailSentSuccessfully(username);
      gtmEvent({
        category: 'account',
        action: 'registrationLinkResent',
        label: 'afterConfirmation',
      });
    } catch (error) {
      errorReceived(error);
    }
  };

  return (
    <div className="anmelden__box">
      {state.isLoading && (
        <div className="anmelden__container anmelden__container--largePadding">
          <LoadingIndicator />
        </div>
      )}
      {state.emailSentSuccessfully ? (
        <Versandbestaetigung
          resendPossible={state.resendPossible}
          error={state.error}
          handleRetry={handleResendVerificationLink}
          headline="Aktivierungslink erneut gesendet"
        >
          Wir haben Ihnen eine E-Mail gesendet. Bitte klicken Sie auf den Link
          in der E-Mail um Ihre Anmeldung zu bestätigen.
        </Versandbestaetigung>
      ) : state.confirmationSucceeded ? (
        <Registrierungsbestaetigung
          username={state.username}
          istHandwerker={istHandwerker}
          referrer={referrer}
        />
      ) : (
        <ErrorMessage
          error={state.error}
          handleResendVerificationLink={handleResendVerificationLink}
        />
      )}
    </div>
  );
}

function SignIn({
  state,
  resetError,
  errorReceived,
  emailSentSuccessfully,
  startAction,
  rolle,
  username: typedUsername,
  hideRoleSwitchTabs = false,
}) {
  const history = useHistory();
  const query = useQuery();
  const [username, setUsername] = useState(typedUsername || '');
  const [password, setPassword] = useState('');

  const istHandwerker = rolle === 'HANDWERKER';

  const createHandleTabChange = (url) => (event) => {
    event.preventDefault();
    if (
      (istHandwerker && url === '/anmelden') ||
      (!istHandwerker && url === '/handwerker/anmelden')
    ) {
      resetError();
      history.push(url);
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      resetError();
      startAction();
      await Auth.signIn(username, password);
      const referrer = query.get('referrer');
      const uri = referrer ? referrer : istHandwerker ? '/anfragen' : '/';
      history.push(uri);
    } catch (error) {
      errorReceived(error);
    }
  };

  const handleResendSignUp = async (resendPossible = true) => {
    try {
      resetError(resendPossible);
      const {
        CodeDeliveryDetails: { Destination: sendDestination },
      } = await Auth.resendSignUp(username);
      emailSentSuccessfully(sendDestination);
      gtmEvent({
        category: 'account',
        action: 'registrationLinkResent',
        label: 'afterLogin',
      });
    } catch (error) {
      errorReceived(error);
    }
  };

  const handleUsernameChange = (event) => {
    resetError();
    setUsername(event.target.value.trim());
  };

  const handlePasswordChange = (event) => {
    resetError();
    setPassword(event.target.value.trim());
  };

  return (
    <>
      <div className="anmelden__box">
        {state.emailSentSuccessfully ? (
          <Versandbestaetigung
            resendPossible={state.resendPossible}
            error={state.error}
            handleRetry={handleResendSignUp}
            headline="Bestätigung erfolgreich geschickt"
          >
            Vielen Dank. Wir haben Ihnen eine E-Mail{' '}
            <strong>{state.sendDestination}</strong> (E-Mail-Adresse aus
            Sicherheitsgründen zum Teil maskiert) gesendet. Bitte klicken Sie
            auf den Link in der E-Mail um Ihre Anmeldung zu bestätigen.
          </Versandbestaetigung>
        ) : (
          <>
            {!hideRoleSwitchTabs && (
              <div className="anmelden__tabs">
                <a
                  className={classNames('anmelden__tab', {
                    'anmelden__tab--active': !istHandwerker,
                  })}
                  href="/anmelden"
                  onClick={createHandleTabChange('/anmelden')}
                >
                  Auftraggeber
                </a>
                <a
                  className={classNames('anmelden__tab', {
                    'anmelden__tab--active': istHandwerker,
                  })}
                  href="/handwerker/anmelden"
                  onClick={createHandleTabChange('/handwerker/anmelden')}
                  data-cy-id="handwerkerloginTab"
                >
                  Fachpartner
                </a>
              </div>
            )}
            <form onSubmit={handleSignIn}>
              <div className="anmelden__container anmelden__container--vertical anmelden__container--borderBottom anmelden__container--largePadding">
                <p className="anmelden__hinweis anmelden__hinweis--large">
                  {istHandwerker
                    ? 'Melden Sie sich an, um mit Kunden in Kontakt zu treten.'
                    : 'Melden Sie sich an, um mit Handwerkern in Kontakt zu treten.'}
                </p>
                <ErrorMessage
                  error={state.error}
                  handleResendSignUp={() => handleResendSignUp(true)}
                  istHandwerker={istHandwerker}
                  username={username}
                />
                <TextInput
                  data-cy-id="username"
                  type={istHandwerker ? 'text' : 'email'}
                  autoComplete="username"
                  autoCorrect="off"
                  autoCapitalize="none"
                  name="username"
                  label={istHandwerker ? 'HWK-Nummer' : 'E-Mail-Adresse'}
                  labelClassName="anmelden__label"
                  onChange={handleUsernameChange}
                  value={username}
                  disabled={state.isLoading}
                />
                <TextInput
                  data-cy-id="password"
                  type="password"
                  autoComplete="current-password"
                  name="password"
                  label="Passwort"
                  labelClassName="anmelden__label"
                  onChange={handlePasswordChange}
                  value={password}
                  disabled={state.isLoading}
                />
                <Link
                  className="margin:bottom:1.5rem"
                  size="small"
                  href={
                    istHandwerker
                      ? `/handwerker/passwortVergessen?username=${encodeURIComponent(
                          username
                        )}`
                      : `/passwortVergessen?username=${encodeURIComponent(
                          username
                        )}`
                  }
                  data-cy-id="passwortVergessen"
                >
                  Passwort vergessen?
                </Link>
                <Button
                  data-cy-id="signInButton"
                  color="yellow"
                  loading={state.isLoading}
                  disabled={state.isLoading}
                >
                  Anmelden
                </Button>
              </div>
            </form>
            <Hinweis rolle={rolle} referrer={query.get('referrer')} />
          </>
        )}
      </div>
    </>
  );
}

function ForgotPassword({
  state,
  resetError,
  errorReceived,
  emailSentSuccessfully,
  startAction,
  rolle,
  username: typedUsername,
}) {
  const [username, setUsername] = useState(typedUsername || '');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value.trim());
  };

  const handleRetryPasswordReset = async (resendPossible = true) => {
    try {
      resetError(resendPossible);
      await forgotPassword(username);
      emailSentSuccessfully(username);
      gtmEvent({
        category: 'account',
        action: 'passwordLinkResent',
        label: 'afterForgotPassword',
      });
    } catch (error) {
      errorReceived(error);
    }
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    try {
      resetError();
      startAction();
      await forgotPassword(username);
      emailSentSuccessfully(username);
      gtmEvent({
        category: 'account',
        action: 'passwordLinkSent',
        label: 'afterForgotPassword',
      });
    } catch (error) {
      errorReceived(error);
    }
  };
  const istHandwerker = rolle === 'HANDWERKER';

  return (
    <div className="anmelden__box">
      {state.emailSentSuccessfully ? (
        <Versandbestaetigung
          resendPossible={state.resendPossible}
          error={state.error}
          handleRetry={handleRetryPasswordReset}
          headline="E-Mail zum Zurücksetzen des Passworts gesendet"
        >
          Wenn es einen Benutzer für die von Ihnen eingegebene{' '}
          {istHandwerker ? 'HWK-Nummer' : 'E-Mail-Adresse'}{' '}
          <strong>{state.sendDestination}</strong> gibt, haben wir Ihnen eine
          E-Mail zum Zurücksetzen Ihres Passworts gesendet. Bitte folgen Sie den
          Anweisungen in der E-Mail.
        </Versandbestaetigung>
      ) : (
        <form onSubmit={handlePasswordReset}>
          <div className="anmelden__container anmelden__container--vertical anmelden__container--largePadding">
            <h1
              className="anmelden__headline1"
              data-cy-id="passwortVergessenHeadline"
            >
              Haben Sie Ihr Passwort vergessen?
            </h1>
            <ErrorMessage error={state.error} istHandwerker={istHandwerker} />
            <p className="anmelden__paragraph margin:top:1rem">
              Bitte geben Sie hier die{' '}
              {istHandwerker ? 'HWK-Nummer' : 'E-Mail-Adresse'} Ihres
              „MeinZuhauseUndIch“-Benutzerkontos ein. Wir senden Ihnen
              anschließend eine E-Mail zum Zurücksetzen Ihres Passworts zu.
              Bitte folgen Sie den Anweisungen in der E-Mail.
            </p>
            <TextInput
              type="text"
              label={istHandwerker ? 'HWK-Nummer' : 'E-Mail-Adresse'}
              autoCorrect="off"
              autoCapitalize="none"
              labelClassName="anmelden__label margin:bottom:2rem"
              onChange={handleUsernameChange}
              value={username}
              disabled={state.isLoading}
            />
            <Button
              color="yellow"
              loading={state.isLoading}
              onClick={handlePasswordReset}
              data-cy-id="passwortZuruecksetzenButton"
              disabled={state.isLoading}
            >
              Passwort zurücksetzen
            </Button>
            <div className="anmelden__backLinkContainer">
              <Link
                href={
                  istHandwerker
                    ? `/handwerker/anmelden?username=${encodeURIComponent(
                        username
                      )}`
                    : `/anmelden?username=${encodeURIComponent(username)}`
                }
              >
                Zurück zur Anmeldung
              </Link>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

async function forgotPassword(username) {
  const response = await API.graphql({
    query: mutations.forgotPassword,
    variables: {
      email: username,
    },
    authMode: 'API_KEY',
  });
  if (response.error) {
    throw new Error(response.error);
  }
  return response;
}

function ResetPassword({
  username,
  bestaetigungscode,
  state,
  resetError,
  errorReceived,
  emailSentSuccessfully,
  startAction,
}) {
  const history = useHistory();

  const [password, setPassword] = useState('');

  const handleResendVerificationLink = async (resendPossible = true) => {
    try {
      resetError(resendPossible);
      const {
        CodeDeliveryDetails: { Destination: sendDestination },
      } = await forgotPassword(username);
      emailSentSuccessfully(sendDestination);
    } catch (error) {
      errorReceived(error);
    }
  };

  const handlePasswordChanged = async (event) => {
    event.preventDefault();

    try {
      startAction();
      await Auth.forgotPasswordSubmit(username, bestaetigungscode, password);
      resetError();
      const user = await Auth.signIn(username, password);
      const istHandwerker = pruefeIstHandwerker(user);
      history.push(
        istHandwerker
          ? '/?passwortZurueckgesetzt'
          : '/meineAuftraege?passwortZurueckgesetzt'
      );
    } catch (error) {
      errorReceived(error);
    }
  };

  return (
    <div className="anmelden__box">
      {state.emailSentSuccessfully ? (
        <Versandbestaetigung
          resendPossible={state.resendPossible}
          error={state.error}
          handleRetry={() => handleResendVerificationLink(false)}
          headline="Neuer Passwort Zurücksetzen Link erfolgreich geschickt"
        >
          Vielen Dank. Wir haben Ihnen eine E-Mail{' '}
          <strong>{state.sendDestination}</strong> (E-Mail-Adresse aus
          Sicherheitsgründen zum Teil maskiert) gesendet. Bitte klicken Sie auf
          den Link in der E-Mail, um Ihr neues Passwort zu setzen.
        </Versandbestaetigung>
      ) : (
        <form onSubmit={handlePasswordChanged}>
          <div className="anmelden__container anmelden__container--vertical anmelden__container--borderBottom anmelden__container--largePadding">
            <h1 className="anmelden__headline1">
              Bitte geben Sie ein neues Passwort ein
            </h1>
            <ErrorMessage
              error={state.error}
              handleResendVerificationLink={handleResendVerificationLink}
            />
            <TextInput
              type="password"
              autoComplete="new-password"
              name="password"
              label="Passwort"
              labelClassName="anmelden__label"
              onChange={(event) => setPassword(event.target.value.trim())}
              value={password}
              disabled={state.isLoading}
            />
            <Passworthinweise password={password} />
            <Button
              color="yellow"
              loading={state.isLoading}
              onClick={handlePasswordChanged}
              disabled={state.isLoading}
            >
              Passwort ändern
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

function Versandbestaetigung({
  handleRetry,
  resendPossible,
  error,
  headline,
  children,
}) {
  return (
    <>
      <div className="anmelden__container anmelden__container--borderBottom anmelden__container--largePadding anmelden__container--alignCenter">
        <img src={newEmailIcon} alt="" className="anmelden__icon" />
        <h1
          className="anmelden__headline1 margin:bottom:.75rem"
          data-cy-id="versandbestaetigungHeadline"
        >
          {headline}
        </h1>
        <p className="anmelden__paragraph">{children}</p>
      </div>

      <div className="anmelden__container anmelden__container--smallPadding anmelden__container--alignCenter">
        {!resendPossible && (
          <SuccessMessage message="Die E-Mail wurde erfolgreich versendet." />
        )}
        <ErrorMessage error={error} />

        <h2 className="anmelden__headline2">Keine E-Mail erhalten?</h2>
        <p className="anmelden__paragraph">
          Prüfen Sie bitte den Spam-Ordner Ihres E-Mail-Kontos. Ist sie dort
          nicht, können Sie die E-Mail{' '}
          <Link
            onClick={() => handleRetry(false)}
            disabled={!resendPossible}
            data-cy-id="bestaetigungErneutAnfordernButton"
          >
            erneut anfordern
          </Link>
          .
        </p>
      </div>
    </>
  );
}

function Registrierungsbestaetigung({ username, istHandwerker, referrer }) {
  const history = useHistory();
  return (
    <>
      <div className="anmelden__container anmelden__container--borderBottom anmelden__container--largePadding anmelden__container--alignCenter">
        <img src={successIcon} alt="" className="anmelden__icon" />
        <h1
          className="anmelden__headline1 margin:bottom:.75em"
          data-cy-id="registrierungsbestaetigungHeadline"
        >
          Registrierung abgeschlossen
        </h1>
        <p className="anmelden__paragraph">
          Vielen Dank, Sie haben sich erfolgreich auf der
          „MeinZuhauseUndIch“-Plattform registriert.
          {istHandwerker && (
            <>
              <br />
              Bitte melden Sie sich an, um Ihr Profil zu vervollständigen.
            </>
          )}
        </p>
        <Button
          className="margin:top:1rem"
          color="yellow"
          onClick={() =>
            history.push(
              istHandwerker
                ? `/handwerker/anmelden?username=${encodeURIComponent(
                    username
                  )}`
                : `${referrer}?username=${encodeURIComponent(username)}`
            )
          }
        >
          Jetzt anmelden
        </Button>
      </div>
    </>
  );
}

export function Passworthinweise({ password }) {
  const cleanedPassword = password || '';
  const containsNumber = /[0-9]+/.test(cleanedPassword);
  const containsUpperCase = /[A-Z]+/.test(cleanedPassword);
  const containsLowerCase = /[a-z]+/.test(cleanedPassword);
  const containsSpecialCharacter = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]+/.test(
    cleanedPassword
  );
  const atLeastEightCharacter = cleanedPassword.length >= 8;

  const ruleMap = {
    'enthält min. eine Ziffer': containsNumber,
    'enthält min. einen Großbuchstaben': containsUpperCase,
    'enthält min. einen Kleinbuchstaben': containsLowerCase,
    'enthält min. ein Sonderzeichen (@#$...)': containsSpecialCharacter,
    'enthält min. 8 Zeichen': atLeastEightCharacter,
  };

  return (
    <div className="passworthinweise">
      <ul className="passworthinweise__liste">
        {Object.entries(ruleMap).map(([rule, checked], key) => (
          <li
            key={key}
            className={classNames('passworthinweise__rule', {
              'passworthinweise__rule--checked': checked,
            })}
          >
            {rule}
          </li>
        ))}
      </ul>
    </div>
  );
}
