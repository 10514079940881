import { useStepGtmEvent, useStepValidator } from '../ProfilVervollstaendigen';

import React from 'react';
export function Intro(props) {
  useStepValidator(props);
  useStepGtmEvent('intro');
  return (
    <>
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Willkommen
      </h2>
      <p className="profilVervollstaendigen__absatz">
        Vervollständigen Sie die Angaben zu Ihrem Profil, damit Sie die
        Plattform „MeinZuhauseUndIch“ nutzen können. Ihre Angaben können Sie
        jederzeit in den Einstellungen Ihres Profils ändern.
      </p>
    </>
  );
}
