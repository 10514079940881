import React from 'react';
import { berechneDurchschnitt } from '../../../domain/bewertung';
import Link from '../../Link/Link';
import Rating from './Rating';

import './Bewertungszusammenfassung.scss';
import { numberFormat } from '../../../i18n';

export default function Bewertungszusammenfassung({
  hwkId,
  bewertungen,
  showProfilModal,
  size = 'medium',
  className,
}) {
  const hatBewertungen = bewertungen.length > 0;
  const durchschnitt = berechneDurchschnitt(bewertungen);

  return hatBewertungen ? (
    <div
      className={`bewertungszusammenfassung ${className}`}
      data-cy-id="bewertungszusammenfassung"
    >
      <span>{numberFormat(durchschnitt)}</span>
      <Rating size={size} name={hwkId} value={durchschnitt} />
      {showProfilModal && (
        <Link onClick={showProfilModal}>
          {bewertungen.length === 1
            ? `1 Bewertung`
            : `${bewertungen.length} Bewertungen`}
        </Link>
      )}
    </div>
  ) : null;
}
