import { hasStatus } from '../status';
import { ANFRAGE_HANDWERKER_FOREIGN_KEY } from '../persistence/auftragRepository';
import { isOlderThanYears, isOlderThanBusinessDays } from '../dateTime';

/**
 * PMUI-72:
 * GESENDET ------------ Annahme durch HW -----------> ANFRAGEKLAERUNG
 * GESENDET ----------- Ablehnung durch HW ----------> ANFRAGE_ABGELEHNT (*)
 * GESENDET -------- K zieht Anfrage zurück ---------> ANFRAGE_ZURUECKGEZOGEN (*)
 *
 * ANFRAGEKLAERUNG --------- Angebot von HW ---------> ANGEBOTSKLAERUNG
 * ANFRAGEKLAERUNG ----- K zieht Anfrage zurück -----> ANFRAGE_ZURUECKGEZOGEN (*)
 * ANFRAGEKLAERUNG ------- Ablehnung durch HW -------> ANFRAGE_ABGELEHNT (*)
 *
 * ANGEBOTSKLAERUNG -------- Annahme durch K --------> AUFTRAGSAUSFUEHRUNG
 * ANGEBOTSKLAERUNG ----- K zieht Anfrage zurück ----> ANFRAGE_ZURUECKGEZOGEN (*)
 * ANGEBOTSKLAERUNG ------- Ablehnung durch HW ------> ANFRAGE_ABGELEHNT (*)
 *
 * AUFTRAGSAUSFUEHRUNG -- Abnahme starten durch HW --> AUFTRAGSABNAHME
 * AUFTRAGSAUSFUEHRUNG ----- Widerrufen durch K -----> WIDERRUFEN (*)
 *
 * AUFTRAGSABNAHME ------- K lehnt Abnahme ab -------> AUFTRAGSAUSFUEHRUNG
 * AUFTRAGSABNAHME ------ K bestätigt Abnahme -------> ABRECHNUNG
 *
 * ABRECHNUNG -------- HW lädt Rechnung hoch --------> ABRECHNUNG
 * ABRECHNUNG ---- HW bestätigt Zahlungseingang -----> ABGESCHLOSSEN (*)
 *
 * (*) Endzustände
 */
export const ANFRAGESTATUS_GESENDET = 'GESENDET';
export const ANFRAGESTATUS_ANFRAGEKLAERUNG = 'ANFRAGEKLAERUNG';
export const ANFRAGESTATUS_ANFRAGE_ABGELEHNT = 'ANFRAGE_ABGELEHNT';
export const ANFRAGESTATUS_ANGEBOTSKLAERUNG = 'ANGEBOTSKLAERUNG';
export const ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG = 'AUFTRAGSAUSFUEHRUNG';
export const ANFRAGESTATUS_AUFTRAGSABNAHME = 'AUFTRAGSABNAHME';
export const ANFRAGESTATUS_ABRECHNUNG = 'ABRECHNUNG';
export const ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN = 'ANFRAGE_ZURUECKGEZOGEN';
export const ANFRAGESTATUS_ABGESCHLOSSEN = 'ABGESCHLOSSEN';
export const ANFRAGESTATUS_WIDERRUFEN = 'WIDERRUFEN';

export const OFFENE_STATUS = [
  ANFRAGESTATUS_ANFRAGEKLAERUNG,
  ANFRAGESTATUS_ANGEBOTSKLAERUNG,
  ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG,
  ANFRAGESTATUS_AUFTRAGSABNAHME,
  ANFRAGESTATUS_ABRECHNUNG,
];

const ANFRAGESTATUSTEXTE = {
  [ANFRAGESTATUS_GESENDET]: 'Anfrage gesendet',
  [ANFRAGESTATUS_ANFRAGEKLAERUNG]: 'Anfrageklärung',
  [ANFRAGESTATUS_ANFRAGE_ABGELEHNT]: 'Anfrage abgelehnt',
  [ANFRAGESTATUS_ANGEBOTSKLAERUNG]: 'Angebotsklärung',
  [ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG]: 'Auftragsausführung',
  [ANFRAGESTATUS_AUFTRAGSABNAHME]: 'Auftragsabnahme',
  [ANFRAGESTATUS_ABRECHNUNG]: 'Abrechnung',
  [ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN]: 'Anfrage zurückgezogen',
  [ANFRAGESTATUS_ABGESCHLOSSEN]: 'Auftrag abgeschlossen',
  [ANFRAGESTATUS_WIDERRUFEN]: 'Auftrag widerrufen',
};

const ANFRAGESTATUSSCHRITT = {
  [ANFRAGESTATUS_GESENDET]: 1,
  [ANFRAGESTATUS_ANFRAGEKLAERUNG]: 2,
  [ANFRAGESTATUS_ANGEBOTSKLAERUNG]: 3,
  [ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG]: 4,
  [ANFRAGESTATUS_AUFTRAGSABNAHME]: 5,
  [ANFRAGESTATUS_ABRECHNUNG]: 6,
  [ANFRAGESTATUS_ANFRAGE_ABGELEHNT]: 7,
  [ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN]: 7,
  [ANFRAGESTATUS_ABGESCHLOSSEN]: 7,
  [ANFRAGESTATUS_WIDERRUFEN]: 7,
};

export default class Anfrage {
  constructor(anfragedaten) {
    Object.assign(this, anfragedaten);
  }

  static statusAlsText(status) {
    return ANFRAGESTATUSTEXTE[status];
  }

  statusAlsText() {
    return ANFRAGESTATUSTEXTE[this.status];
  }

  wurdeGesendet() {
    return hasStatus(this, ANFRAGESTATUS_GESENDET);
  }

  istOffen() {
    return hasStatus(this, OFFENE_STATUS);
  }

  wirdAusgefuehrt() {
    return hasStatus(this, [
      ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG,
      ANFRAGESTATUS_AUFTRAGSABNAHME,
      ANFRAGESTATUS_ABRECHNUNG,
    ]);
  }

  wurdeBeendet() {
    return hasStatus(this, [
      ANFRAGESTATUS_ABGESCHLOSSEN,
      ANFRAGESTATUS_ANFRAGE_ABGELEHNT,
      ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN,
      ANFRAGESTATUS_WIDERRUFEN,
    ]);
  }

  wurdeAbgebrochen() {
    return hasStatus(this, [
      ANFRAGESTATUS_ANFRAGE_ABGELEHNT,
      ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN,
      ANFRAGESTATUS_WIDERRUFEN,
    ]);
  }

  wurdeAbgeschlossen() {
    return hasStatus(this, [ANFRAGESTATUS_ABGESCHLOSSEN]);
  }

  kannKommuniziertWerden() {
    return (
      hasStatus(this, [
        ANFRAGESTATUS_GESENDET,
        ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG,
        ANFRAGESTATUS_AUFTRAGSABNAHME,
        ANFRAGESTATUS_ABRECHNUNG,
        ANFRAGESTATUS_ANFRAGEKLAERUNG,
        ANFRAGESTATUS_ANGEBOTSKLAERUNG,
        ANFRAGESTATUS_ABGESCHLOSSEN,
      ]) &&
      !Boolean(this.auftrag.kunde.geloescht) &&
      !Boolean(this.handwerker.geloescht)
    );
  }

  kannAngenommenWerden() {
    return hasStatus(this, ANFRAGESTATUS_GESENDET);
  }

  kannAnfrageZurueckgezogenOderAbgelehntWerden() {
    return hasStatus(this, [
      ANFRAGESTATUS_GESENDET,
      ANFRAGESTATUS_ANFRAGEKLAERUNG,
      ANFRAGESTATUS_ANGEBOTSKLAERUNG,
    ]);
  }

  kannAngebotHinzugefuegtWerden() {
    return hasStatus(this, [
      ANFRAGESTATUS_ANFRAGEKLAERUNG,
      ANFRAGESTATUS_ANGEBOTSKLAERUNG,
    ]);
  }

  kannAngebotAngenommenWerden(angebot) {
    return (
      hasStatus(this, ANFRAGESTATUS_ANGEBOTSKLAERUNG) && angebot.istOffen()
    );
  }

  kannAngebotWiderrufenWerden(angebot) {
    return (
      hasStatus(this, ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG) &&
      angebot.kannWiderrufenWerden()
    );
  }

  hatAngenommenesAngebot() {
    return this.angebote.some((angebot) => angebot.wurdeAngenommen());
  }

  hatHandwerker(handwerker) {
    return this[ANFRAGE_HANDWERKER_FOREIGN_KEY] === handwerker.id;
  }

  kannGeloeschtWerden() {
    return (
      hasStatus(this, [
        ANFRAGESTATUS_GESENDET,
        ANFRAGESTATUS_ANFRAGEKLAERUNG,
        ANFRAGESTATUS_ANGEBOTSKLAERUNG,
        ANFRAGESTATUS_ANFRAGE_ABGELEHNT,
        ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN,
        ANFRAGESTATUS_WIDERRUFEN,
      ]) ||
      (hasStatus(ANFRAGESTATUS_ABGESCHLOSSEN) &&
        isOlderThanYears(this.imStatusSeit, 10)) ||
      Boolean(this.auftrag.kunde.geloescht) ||
      Boolean(this.handwerker.geloescht)
    );
  }

  kannAuftragsabnahmeGestartetWerden() {
    return hasStatus(this, ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG);
  }

  kannAuftragAbgenommenWerden() {
    return hasStatus(this, ANFRAGESTATUS_AUFTRAGSABNAHME);
  }

  kannAuftragAbgerechnetWerden() {
    return hasStatus(this, ANFRAGESTATUS_ABRECHNUNG);
  }

  kannZahlungseingangBestaetigtWerden() {
    return (
      hasStatus(this, ANFRAGESTATUS_ABRECHNUNG) &&
      Boolean(this.rechnungen) &&
      this.rechnungen.length > 0
    );
  }

  handwerkerReagiertNicht() {
    return isOlderThanBusinessDays(this.imStatusSeit, 3);
  }

  kannBewertetWerden() {
    return (
      Boolean(this.rechnungen) &&
      this.rechnungen.length > 0 &&
      !Boolean(this.handwerkerBewertetAm)
    );
  }

  fortschritt() {
    return {
      schritt: ANFRAGESTATUSSCHRITT[this.status],
      status: this.statusAlsText(),
      anzahlSchritte: 7,
    };
  }
}
