import React, { useState } from 'react';
import { useDialogState, useAnhangUrl } from '../../hooks';
import Button from '../Button/Button';
import classNames from 'classnames';
import { toDateTimeString, toCustomFormat } from '../../dateTime';
import AngebotAblehnenDialog from './AngebotAblehnenDialog';
import { formatCurrency, formatMwStForAngebot } from '../../money';
import Modal from '../Modal/Modal';
import Link from '../Link/Link';
import { useAuftraege } from '../../DomainContext';
import { gtmEvent } from '../../googleAnalytics';

import './Angebot.scss';

const AuftragWiderufenDialog = ({ hide, anfrage, angebot }) => {
  const auftraege = useAuftraege();
  const [vorgehen, setVorgehen] = useState(null);
  const isWiderrufDisabled = !vorgehen;
  const auftragWiderrufen = async () => {
    await auftraege.widerrufeAuftrag(anfrage, angebot, vorgehen === 'JA');
    hide();

    gtmEvent({
      category: 'request',
      action: 'offerRevoked',
    });
  };

  const actions = (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        color="red"
        data-cy-id="bestaetigeWiderrufButton"
        onClick={auftragWiderrufen}
        disabled={isWiderrufDisabled}
      >
        Widerruf bestätigen
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title={`Auftragswiderruf bestätigen`}
      hide={hide}
      actions={actions}
      gaTrackingName="Auftragswiderruf bestätigen"
    >
      <span className="modal__contentText">
        <p className="auftragswiderruf__grundAngabe">
          Der Kunde hat seine Angebotsannahme widerrufen? Dann bestätigen Sie
          uns bitte den Eingang des Widerrufs.
        </p>
        <p className="auftragswiderruf__grundAngabe">
          Haben Sie vor dem Zeitpunkt des Widerrufs bereits Arbeiten für den
          Kunden ausgeführt, die Sie dem Kunden in Rechnung stellen wollen?
        </p>
      </span>
      <ul className="auftragswiderruf__grundAuswahl">
        <li className="auftragswiderruf__grund">
          <label htmlFor="ja" className="auftragswiderruf__grundLabel">
            <input
              className="auftragswiderruf__radio"
              id="ja"
              type="radio"
              name="group"
              onChange={() => setVorgehen('JA')}
            />
            <span>
              Ja, ich möchte dem Kunden die bereits ausgeführte Arbeit in
              Rechnung stellen.&nbsp;
              <span className="auftragswiderruf__folge">
                (Unterhaltung bleibt aktiv, Rechnung kann hochgeladen werden)
              </span>
            </span>
          </label>
        </li>
        <li className="auftragswiderruf__grund">
          <label htmlFor="nein" className="auftragswiderruf__grundLabel">
            <input
              className="auftragswiderruf__radio"
              id="nein"
              data-cy-id="keineArbeitenAusgefuehrtOption"
              type="radio"
              name="group"
              onChange={() => setVorgehen('NEIN')}
            />
            <span>
              Nein, ich habe keine Arbeiten ausgeführt und kann somit auch
              nichts in Rechnung stellen.&nbsp;
              <span className="auftragswiderruf__folge">
                (Die Unterhaltung wird beendet, der Auftrag geschlossen)
              </span>
            </span>
          </label>
        </li>
      </ul>
    </Modal>
  );
};

export default ({ anfrage, angebot, autor, showAngebotAnnehmenModal }) => {
  const url = useAnhangUrl(angebot);
  const [
    isAngebotAblehnenDialogShown,
    showAngebotAblehnenDialog,
    hideAngebotAblehnenDialog,
  ] = useDialogState();

  const [
    isAuftragWiderrufenDialogShown,
    showAuftragWiderrufenDialog,
    hideAuftragWiderrufenDialog,
  ] = useDialogState();

  const angebotWurdeAngenommen = angebot.wurdeAngenommen();
  const angebotWurdeAbgelehnt = angebot.wurdeAbgelehnt();

  const angebotActionsVisible =
    anfrage.kannAngebotAngenommenWerden(angebot) && autor === 'KUNDE';

  const widerrufVisible =
    anfrage.kannAngebotWiderrufenWerden(angebot) && autor === 'HANDWERKER';

  const handleAuftragswiderrufBestaetigenClick = () => {
    showAuftragWiderrufenDialog();
  };

  return (
    <div
      className={classNames('angebot', {
        'angebot--abgelehnt': angebotWurdeAbgelehnt,
      })}
    >
      <div className="angebot__header">
        <p className="angebot__zeit">{toDateTimeString(angebot.createdAt)}</p>
        <a
          className={classNames('angebot__fileName', {
            'angebot__fileName--abgelehnt': angebotWurdeAbgelehnt,
          })}
          target="_blank"
          rel="noopener noreferrer"
          href={url}
        >
          {angebot.anhang.datei.fileName}
        </a>
      </div>
      <div className="angebot__preis">
        <p className="angebot__brutto">{formatCurrency(angebot.brutto)}</p>
        <p className="angebot__mwstHinweis">
          (inkl. {formatMwStForAngebot(angebot)})
        </p>
      </div>
      {angebotActionsVisible && (
        <div className="angebot__actions">
          <div>
            <Button
              color="green"
              size="small"
              onClick={() => {
                showAngebotAnnehmenModal(angebot, url);
              }}
              className="angebot__actions--actionButton"
            >
              Angebot&nbsp;annehmen
            </Button>
          </div>
          <div>
            <Button
              size="small"
              onClick={showAngebotAblehnenDialog}
              className="angebot__actions--actionButton"
            >
              Ablehnen
            </Button>
          </div>
          {isAngebotAblehnenDialogShown && (
            <AngebotAblehnenDialog
              anfrage={anfrage}
              angebot={angebot}
              hide={hideAngebotAblehnenDialog}
            />
          )}
        </div>
      )}
      {angebotWurdeAngenommen && (
        <div className="angebot__annahmehinweis">
          {toCustomFormat(
            angebot.annahmedatum,
            '[Angenommen am] DD.MM.YYYY [um] HH:mm'
          )}
        </div>
      )}
      {angebot.wurdeAbgelehnt() && (
        <div className="angebot__ablehnungshinweis">Abgelehnt</div>
      )}
      {widerrufVisible && (
        <>
          <div className="angebot__widerruf">
            <Link
              className="angebot__widerrufButton"
              data-cy-id="auftragWiderrufenButton"
              onClick={handleAuftragswiderrufBestaetigenClick}
            >
              Auftragswiderruf bestätigen
            </Link>
          </div>

          {isAuftragWiderrufenDialogShown && (
            <AuftragWiderufenDialog
              hide={hideAuftragWiderrufenDialog}
              anfrage={anfrage}
              angebot={angebot}
            />
          )}
        </>
      )}
    </div>
  );
};
