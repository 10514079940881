import { isBetweenDays } from './dateTime';

const euroCurrencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export const formatCurrency = (value) => euroCurrencyFormat.format(value);

export function formatMwStForAngebot(angebot) {
  if (
    isBetweenDays(
      new Date('2020-07-01').toISOString(),
      angebot.createdAt,
      new Date('2020-12-31').toISOString()
    )
  ) {
    return '16% MwSt.';
  }
  return '19% MwSt.';
}
