import React from 'react';
import classnames from 'classnames';

import './Rating.scss';

const STAR_VALUES = [5, 4, 3, 2, 1];

export default function Rating({
  name,
  size = 'medium',
  color = 'green',
  value,
  onChange,
}) {
  const canEdit = typeof onChange === 'function';
  const fullStarValue = Math.floor(value);
  const hasHalfStar = fullStarValue !== value;

  return (
    <div
      className={classnames('rating', {
        'rating--editable': canEdit,
        [`rating--${size}`]: true,
      })}
    >
      {STAR_VALUES.map((starValue) => {
        return (
          <label
            key={starValue}
            htmlFor={`${name}-${starValue}`}
            className={classnames('rating__star', {
              [`rating__star--${color}`]: true,
              'rating__star--fill': starValue <= fullStarValue,
              'rating__star--fill-half':
                hasHalfStar && starValue === fullStarValue + 1,
            })}
          >
            <input
              value={starValue}
              checked={starValue === value}
              type="radio"
              name={name}
              onChange={(e) => {
                onChange && onChange(parseInt(e.target.value, 10), name);
              }}
              id={`${name}-${starValue}`}
              hidden
            />
          </label>
        );
      })}
    </div>
  );
}
