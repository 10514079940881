import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { EMailBenachrichtigungenEinstellung } from './Components';
import Link from '../Link/Link';
import { Profilzeile } from './Components';
import { RadioGroup } from '../RadioGroup/RadioGroup';
import React from 'react';

export default function Kundenprofil({
  kunde,
  istEigenesProfil,
  aktualisierterBenutzer,
  setAktualisierterBenutzer,
  showKontoLoeschenDialog,
  setProfilGeaendert,
}) {
  const aktualisiereBenutzer = (benutzer) => {
    setProfilGeaendert(true);
    setAktualisierterBenutzer(benutzer);
  };

  return (
    <>
      <h1 className="profil__sectionUeberschrift">Ihre Daten</h1>
      <Profilzeile feld="Name, Vorname">
        {kunde.nachname}, {kunde.vorname}
      </Profilzeile>
      <Profilzeile feld="E-Mail-Adresse">
        <a href={`mailto:${kunde.email}`}>{kunde.email}</a>
      </Profilzeile>
      {istEigenesProfil && (
        <>
          <h1 className="profil__sectionUeberschrift">
            Persönliche Einstellungen
          </h1>
          <div className="profil__persoenlicheEinstellungen">
            <div
              className="profil__persoenlicheEinstellung"
              data-cy-persoenliche-einstellungen="E-Mail-Benachrichtigungen"
              data-cy-status={
                kunde.emailbenachrichtigungen ? 'aktiviert' : 'deaktiviert'
              }
            >
              <EMailBenachrichtigungenEinstellung
                user={kunde}
                aktualisierterBenutzer={aktualisierterBenutzer}
                aktualisiereBenutzer={aktualisiereBenutzer}
              />
            </div>
            <div
              className="profil__persoenlicheEinstellung"
              data-cy-persoenliche-einstellungen="Einwilligung in Werbung"
              data-cy-status={
                aktualisierterBenutzer.werbeeinwilligung
                  ? 'aktiviert'
                  : 'deaktiviert'
              }
            >
              <div>
                <h2 className="profil__feldueberschrift">
                  Einwilligung in telefonische Kontaktaufnahme
                </h2>
                <div className="profil__feldinhalt">
                  <a
                    href="/einwilligung"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Einwilligung
                  </a>
                  &nbsp;in die telefonische Kontaktaufnahme durch die Provinzial
                  zum Zwecke der Unterstützung und Optimierung der
                  Vermittlungsprozesse.
                </div>
              </div>
              <RadioGroup
                hideLabel
                label="werbeeinwilligung"
                options={['Aktiviert', 'Deaktiviert']}
                value={
                  aktualisierterBenutzer.werbeeinwilligung
                    ? 'Aktiviert'
                    : 'Deaktiviert'
                }
                onChange={(event) => {
                  aktualisiereBenutzer({
                    ...aktualisierterBenutzer,
                    werbeeinwilligung: event.target.value === 'Aktiviert',
                  });
                }}
                vertical
              />
            </div>
          </div>
          <div className="margin:top:2rem">
            <Link
              icon={<DeleteIcon />}
              size="small"
              color="red"
              onClick={showKontoLoeschenDialog}
              data-cy-id="kontoLoeschenButton"
            >
              Konto und Daten löschen
            </Link>
          </div>
        </>
      )}
    </>
  );
}
