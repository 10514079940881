import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import Button from '../Button/Button';
import { ICON_MAP } from '../../domain/schritte/konstanten';

import './Input.scss';
import { gtmEvent } from '../../googleAnalytics';

export function SofortauswahlButton({
  className,
  spezifikation,
  attribut,
  wert,
  waehleSofortauswahlwert,
  ...props
}) {
  return (
    <button
      className={classNames(
        'sofortauswahlButton',
        {
          'sofortauswahlButton--istAusgewaehlt':
            spezifikation[attribut] === wert,
        },
        className
      )}
      onClick={() => waehleSofortauswahlwert(attribut, wert)}
      data-cy-id={wert}
    >
      <img
        className="sofortauswahlButton__image"
        src={ICON_MAP[wert]}
        border={0}
        alt=""
      />
      <label className="sofortauswahlButton__label sofortauswahlButton__label--desktop">
        {wert === 'Wärmedämmverbundsystem installieren'
          ? 'Wärmedämmverbund-system installieren'
          : wert}
      </label>
      <label className="sofortauswahlButton__label sofortauswahlButton__label--mobile">
        {wert}
      </label>
    </button>
  );
}

export function TextauswahlBox({
  className,
  spezifikation,
  attribut,
  wert,
  waehleSofortauswahlwert,
  ...props
}) {
  return (
    <div
      className={classNames(
        'input__textauswahlbox',
        {
          'input__textauswahlbox--istAusgewaehlt': spezifikation[
            attribut
          ]?.includes(wert),
        },
        className
      )}
    >
      <h2 className="margin:bottom:1.375rem">{props.titel}</h2>

      <p className="margin:bottom:1rem">{props.untertitel}</p>

      <ul className="input__textauswahlboxLeistungen margin:bottom:2.5rem">
        {props.leistungen.map((leistung, key) => (
          <li className="input__leistung" key={key}>
            {leistung}
          </li>
        ))}
        {props.nichtEnthalteneLeistungen?.map((leistung, key) => (
          <li
            className="input__leistung input__leistung--nichtEnthalten"
            key={`nichtEnthalten-${key}`}
          >
            {leistung}
          </li>
        ))}
      </ul>

      <Button
        color="yellow"
        size="large"
        onClick={() => {
          gtmEvent({
            category: 'assistant',
            action: 'stepOpened',
            label: wert,
          });
          return waehleSofortauswahlwert(attribut, [wert]);
        }}
        data-cy-id={wert}
      >
        {props.buttonText}
      </Button>
    </div>
  );
}

export function CheckBox({
  className,
  imageSrc,
  spezifikation,
  attribut,
  wert,
  waehleMehrfachauswahlwert,
  ...props
}) {
  const id = uuid();
  return (
    <label htmlFor={id} className="auswahlCheckbox__label">
      <input
        className="auswahlCheckbox"
        type="checkbox"
        id={id}
        checked={
          Boolean(spezifikation[attribut]) &&
          spezifikation[attribut].includes(wert)
        }
        onChange={(event) =>
          waehleMehrfachauswahlwert(attribut, wert, event.target.checked)
        }
        {...props}
      />
      {wert}
    </label>
  );
}

export function Radio({
  className,
  spezifikation,
  attribut,
  label,
  wert,
  waehleWert,
  ...props
}) {
  return (
    <label
      htmlFor={`${attribut}_${wert}`}
      className={classNames('auswahlCheckbox__label', className)}
    >
      <input
        className="auswahlCheckbox"
        type="radio"
        name={attribut}
        value={wert}
        id={`${attribut}_${wert}`}
        checked={
          Boolean(spezifikation[attribut]) &&
          spezifikation[attribut].includes(wert)
        }
        onChange={() => waehleWert(attribut, wert)}
        {...props}
      />
      {label || wert}
    </label>
  );
}
