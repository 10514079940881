import React, { useState } from 'react';
import Angebot from '../Angebot/Angebot';
import { useModalState } from '../../hooks';
import AngebotAnnehmenModal from '../Angebot/AngebotAnnehmenModal';
import Collapsible from '../Collapsible/Collapsible';
import './Angebote.scss';

export default function Angebote({ anfrage, autor }) {
  const [anzunehmendesAngebot, setAnzunehmendesAngebot] = useState(null);
  const [
    anzunehmendesAngebotAnhangUrl,
    setAnzunehmendesAngebotAnhangUrl,
  ] = useState(null);

  const createShowAngebotAnnehmenModal = () => (angebot, angebotAnhangUrl) => {
    setAnzunehmendesAngebot(angebot);
    setAnzunehmendesAngebotAnhangUrl(angebotAnhangUrl);
    showAngebotAnnehmenModal();
  };

  const keineAngeboteHinweis =
    autor === 'KUNDE'
      ? 'Sie haben noch keine Angebote erhalten.'
      : 'Sie haben noch keine Angebote abgegeben.';
  const [
    isAngebotAnnehmenModalShown,
    showAngebotAnnehmenModal,
    hideAngebotAnnehmenModal,
  ] = useModalState();

  const angebote = anfrage?.angebote || [];

  const nichtAbgelehnteAngebote = angebote.filter((a) => !a.wurdeAbgelehnt());
  const abgelehnteAngebote = angebote.filter((a) => a.wurdeAbgelehnt());

  return (
    <section className="angebote">
      <div className="angebote__ueberschrift">
        <span>Angebotsübersicht</span>
      </div>
      {angebote && (
        <>
          {angebote.length > 0 ? (
            <div className="angebote__offen">
              {nichtAbgelehnteAngebote.map((nichtAbgelehntesAngebot) => (
                <Angebot
                  key={nichtAbgelehntesAngebot.id}
                  anfrage={anfrage}
                  angebot={nichtAbgelehntesAngebot}
                  autor={autor}
                  showAngebotAnnehmenModal={createShowAngebotAnnehmenModal()}
                />
              ))}
            </div>
          ) : (
            <div className="angebote__keineAngeboteHinweis">
              {keineAngeboteHinweis}
            </div>
          )}
          {abgelehnteAngebote.length > 0 && (
            <Collapsible header={`Abgelehnt (${abgelehnteAngebote.length})`}>
              {abgelehnteAngebote.map((abgelehntesAngebot) => (
                <Angebot
                  key={abgelehntesAngebot.id}
                  anfrage={anfrage}
                  angebot={abgelehntesAngebot}
                  autor={autor}
                />
              ))}
            </Collapsible>
          )}
        </>
      )}
      {isAngebotAnnehmenModalShown && (
        <AngebotAnnehmenModal
          anfrage={anfrage}
          angebot={anzunehmendesAngebot}
          hide={hideAngebotAnnehmenModal}
          url={anzunehmendesAngebotAnhangUrl}
        />
      )}
    </section>
  );
}
