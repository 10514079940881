import React from 'react';
import './Chip.scss';
import Button from '../Button/Button';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const Chip = ({ onRemove, children }) => (
  <div className="chip">
    {children}
    <Button
      onClick={onRemove}
      icon={<CloseIcon />}
      className="margin:left:.5rem"
      size="small"
    />
  </div>
);

export default Chip;
