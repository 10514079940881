import React from 'react';
import ChatHeader from '../ChatHeader/ChatHeader';
import Nachrichten from '../Nachrichten/Nachrichten';
import ChatInputArea from '../ChatInputArea/ChatInputArea';
import './Chat.scss';

const Chat = ({
  anfrage,
  autor,
  headerIntro,
  willkommenshinweis,
  quickActions,
}) => {
  return (
    <section className="chat">
      <ChatHeader anfrage={anfrage} intro={headerIntro} autor={autor} />
      <Nachrichten
        key={anfrage.id}
        anfrage={anfrage}
        willkommenshinweis={willkommenshinweis}
        autor={autor}
      />
      {quickActions}
      <ChatInputArea anfrage={anfrage} autor={autor} />
    </section>
  );
};

export default Chat;
