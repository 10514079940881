import { API } from 'aws-amplify';
import merge from 'deepmerge';

function getNextToken(data) {
  const keyWithNextToken = Object.keys(data).find((key) => data[key].nextToken);
  return (
    keyWithNextToken &&
    data[keyWithNextToken] &&
    data[keyWithNextToken].nextToken
  );
}

export function recursiveQuery(operation) {
  return new Promise((resolve) => {
    API.graphql(operation).then((response) => {
      const nextToken = getNextToken(response.data);
      if (nextToken) {
        recursiveQuery({
          query: operation.query,
          variables: {
            ...operation.variables,
            nextToken,
          },
        }).then((data) => {
          resolve(merge(response.data, data));
        });
      } else {
        resolve(response.data);
      }
    });
  });
}
