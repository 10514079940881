import TagManager from 'react-gtm-module';

export function initializeGoogleTagManager() {
  if (
    window.location.href.includes('https://handwerkervermittlung.meinzuhauseundich.de') ||
    window.location.href.includes('https://staging.meinzuhauseundich.de')
  ) {
    const trackingId = 'GTM-PRPCKXB';

    const tagManagerArgs = {
      gtmId: trackingId,
      events: {
        'cms.init': {
          pagePool: 'microsite',
          pageContext: 'meinzuhauseundich',
        },
      },
    };
    TagManager.initialize(tagManagerArgs);
  } else {
    window.dataLayer = [];
  }
}

export function gtmEvent({ category, action, label, value }) {
  window.dataLayer.push({
    event: 'appEvent',
    'data-event-category': category,
    'data-event-action': action,
    'data-event-label': label || undefined,
    'data-event-value': value || undefined,
  });
}

export function modalView(modalName) {
  window.dataLayer.push({
    event: 'modalview',
    pageName: `/${modalName}`,
  });
}
