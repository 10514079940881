import React, { useContext } from 'react';
import { TextAreaWrapper } from '../../../../components/ProfilModal/Components';
import { RadioGroup } from '../../../../components/RadioGroup/RadioGroup';
import { TaetigkeitenAuswahl } from '../../../../components/Handwerker/TaetigkeitenAuswahl/TaetigkeitenAuswahl';
import {
  ProfilVervollstaendigenDispatchContext,
  ProfilVervollstaendigenStateContext,
  useStepValidator,
  useStepGtmEvent,
} from '../ProfilVervollstaendigen';

export function ProfilVervollstaendigenTaetigkeiten(props) {
  const { setStepValid, isValid } = props;
  const { setAktualisierterBenutzer } = useContext(
    ProfilVervollstaendigenDispatchContext
  );
  const state = useContext(ProfilVervollstaendigenStateContext);
  const aktualisiereBenutzer = (benutzer) => {
    setStepValid(isValid({ aktualisierterBenutzer: benutzer }));
    setAktualisierterBenutzer(benutzer);
  };
  useStepValidator(props);
  useStepGtmEvent('crafts');
  return (
    <>
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Tätigkeiten &amp; Zusatzqualifikationen
      </h2>
      <div className="profilVervollstaendigen__absatz margin:bottom:1.5rem">
        Bitte wählen Sie alle Handwerksleistungen aus, die Sie Ihren Kunden
        anbieten möchten.
      </div>
      <TaetigkeitenAuswahl
        gewaehlteLeistungen={
          state.aktualisierterBenutzer.gewaehlteLeistungen || []
        }
        onChange={(gewaehlteLeistungen) => {
          aktualisiereBenutzer({
            ...state.aktualisierterBenutzer,
            gewaehlteLeistungen,
          });
        }}
      />
      <div className="profil__sectionContainer">
        <RadioGroup
          label="Haben Sie zusätzliche Qualifikationen wie Zertifikate, Meisterbriefe, Diplome oder Ähnliche?"
          options={['Nein', 'Ja']}
          value={
            state.aktualisierterBenutzer.zusatzqualifikationenVorhanden
              ? 'Ja'
              : 'Nein'
          }
          onChange={(event) => {
            aktualisiereBenutzer({
              ...state.aktualisierterBenutzer,
              zusatzqualifikationenVorhanden: event.target.value === 'Ja',
            });
          }}
        />
        {state.aktualisierterBenutzer.zusatzqualifikationenVorhanden && (
          <TextAreaWrapper
            labelClassName="profil__zusatzqualifikationenLabel"
            label="Folgende Zusatzqualifikationen liegen vor:"
            attribut="zusatzqualifikationen"
            aktualisierterBenutzer={state.aktualisierterBenutzer}
            setAktualisierterBenutzer={aktualisiereBenutzer}
          />
        )}
      </div>
    </>
  );
}
