import React from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import { gtmEvent } from '../../googleAnalytics';
import Link from '../Link/Link';

import './TextInput.scss';

export function TextInput({
  attribut,
  label,
  labelClassName,
  placeholder,
  disabled,
  hilfetext,
  validierungstext,
  type,
  ...props
}) {
  const [isPasswordHidden, setPasswordVisible] = useState(true);
  const inputType = () => {
    if (type !== 'password') {
      return type;
    } else if (isPasswordHidden) {
      return 'password';
    } else {
      return 'text';
    }
  };

  const handleShowPassword = () => {
    gtmEvent({ category: 'account', action: 'passwordShown' });
    setPasswordVisible(!isPasswordHidden);
  };

  return (
    <>
      <div
        className={classNames('textInput__label', labelClassName)}
        data-cy-label={label}
      >
        <div className="textInput__labelLayout">
          <label htmlFor={attribut} className="textInput__labelText">
            {label}
          </label>
          {type === 'password' && (
            <Link
              tabIndex="-1"
              size="small"
              color="grey"
              onClick={handleShowPassword}
              type="button"
            >
              {isPasswordHidden ? 'Anzeigen' : 'Verstecken'}
            </Link>
          )}
        </div>
        <input
          id={attribut}
          name={attribut}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames('textInput__input', {
            'textInput__input--invalid': validierungstext,
          })}
          type={inputType()}
          {...props}
        />
      </div>
      {hilfetext && !validierungstext && (
        <div className="textInput__hilfetext">{hilfetext}</div>
      )}
      {validierungstext && (
        <div
          data-cy-validierungstext={validierungstext}
          className="textInput__validierungstext"
        >
          {validierungstext}
        </div>
      )}
    </>
  );
}
