import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import KundeRepository from '../domain/kundeRepository';
import { unmarshallKunde } from './marshall';

export default class KundeRepositoryImpl extends KundeRepository {
  async holeKundeZuId(kundeId) {
    const {
      data: { getKunde }
    } = await API.graphql(graphqlOperation(queries.getKunde, { id: kundeId }));
    return unmarshallKunde(getKunde);
  }

  async erzeugeKunde(kunde) {
    const input = { ...kunde };

    const {
      data: { createKunde }
    } = await API.graphql(graphqlOperation(mutations.createKunde, { input }));

    return unmarshallKunde(createKunde);
  }

  async loescheKunde(kunde) {
    return API.graphql(
      graphqlOperation(mutations.deleteKunde, { input: { id: kunde.id } })
    );
  }

  async aktualisiereKunde(kunde) {
    const input = { ...kunde };
    const {
      data: { updateKunde }
    } = await API.graphql(graphqlOperation(mutations.updateKunde, { input }));
    return unmarshallKunde(updateKunde);
  }

  async speichereKontoloeschgrund(grund) {
    await API.graphql(
      graphqlOperation(mutations.createKontoloeschung, { input: { grund } })
    );
  }
}
