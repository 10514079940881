import React, { useEffect, useState } from 'react';
import './Auftragsdruck.scss';
import { useAnhangUrl } from '../../../hooks';
import { useAnfrage } from '../../../hooks/useAnfrage';
import Ausfuehrungszeitraum from '../../../components/Ausfuerungszeitraum/Ausfuehrungszeitraum';

export default function Auftragsdruck({
  match: {
    params: { anfrageId },
  },
}) {
  const { anfrage } = useAnfrage(anfrageId);

  return (
    <>
      <div className="auftragsdruck auftragsdruck--printable">
        {anfrage && <DruckbareAnfrage anfrage={anfrage} />}
      </div>
      <div className="auftragsdruck auftragsdruck--notPrintable">
        <div className="auftragsdruck__drucken">
          Der Druck wird vorbereitet...
        </div>
      </div>
    </>
  );
}

function DruckbareAnfrage({ anfrage }) {
  const [loadedImages, setLoadedImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(
    anfrage.auftrag.anhaenge.length === 0
  );
  const spezifikation = anfrage.auftrag.spezifikation;
  const umsetzungsdetails = { ...spezifikation };

  delete umsetzungsdetails['bereich'];
  delete umsetzungsdetails['objektart'];
  delete umsetzungsdetails['taetigkeit'];
  delete umsetzungsdetails['teiltaetigkeit'];
  delete umsetzungsdetails['Auftragsbeschreibung'];
  delete umsetzungsdetails['Sonstige Informationen'];
  delete umsetzungsdetails['ausfuehrungszeitraum'];
  delete umsetzungsdetails['spziellerAusfuehrungszeitraum'];
  delete umsetzungsdetails['Straße und Hausnummer'];
  delete umsetzungsdetails['PLZ'];
  delete umsetzungsdetails['Ort'];
  delete umsetzungsdetails['Telefonnummer'];
  delete umsetzungsdetails['Parkplatzsituation vor Ort'];
  delete umsetzungsdetails['Fotos, Grundriss'];

  useEffect(() => {
    if (imagesLoaded) {
      window.print();
      window.onfocus = function () {
        setTimeout(function () {
          window.close();
        }, 500);
      };
    }
  }, [imagesLoaded]);

  const fotosUndGrundrisse = anfrage.auftrag.anhaenge.filter(
    (anhang) => anhang.typ === 'FOTO'
  );

  const createHandleLoad = (anhang) => () => {
    const updatedLoadedImages = [...loadedImages, anhang];
    setLoadedImages(updatedLoadedImages);
    if (updatedLoadedImages.length === fotosUndGrundrisse.length) {
      setImagesLoaded(true);
    }
  };

  return (
    <div className="auftragsdruck__anfrage">
      <h1 className="auftragsdruck__headline">
        {anfrage.auftrag.beschreibung}
      </h1>
      <div className="auftragsdruck__zeitraum">
        <div className="auftragsdruck__header">Zeitraum</div>
        <div className="auftragsdruck__content">
          <Ausfuehrungszeitraum spezifikation={spezifikation} />
        </div>
      </div>
      <div className="auftragsdruck__auftraggeber">
        <div className="auftragsdruck__header">Auftraggeber</div>
        <div className="auftragsdruck__content">
          {anfrage.auftrag.kunde.vorname} {anfrage.auftrag.kunde.nachname}
          <br />
          {spezifikation['Telefonnummer']}
        </div>
      </div>
      <div className="auftragsdruck__standort">
        <div className="auftragsdruck__header">Standort</div>
        <div className="auftragsdruck__content">
          {spezifikation['Straße und Hausnummer']}
          <br />
          {spezifikation['PLZ']} {spezifikation['Ort']}
          <br />
          {spezifikation['Adresszusatz'] && (
            <>
              {spezifikation['Adresszusatz']}
              <br />
            </>
          )}
          <br />
          {spezifikation['Parkplatzsituation vor Ort']}
        </div>
      </div>
      {spezifikation.teiltaetigkeit && (
        <>
          <div className="auftragsdruck__taetigkeiten">
            <div className="auftragsdruck__header">Gewünschte Tätigkeiten</div>
            <div className="auftragsdruck__content">
              {spezifikation.teiltaetigkeit.map((t) => (
                <div key={t} className="auftragsdruck__taetigkeit">
                  {t}
                </div>
              ))}
            </div>
          </div>
          <div className="auftragsdruck__umsetzungsdetails">
            <div className="auftragsdruck__header">
              Umfang &amp; Umsetzungsdetails
            </div>
            <div className="auftragsdruck__content">
              {Object.keys(umsetzungsdetails).map((umsetzungsdetail) => (
                <React.Fragment key={umsetzungsdetail}>
                  <div className="auftragsdruck__attribut">
                    {umsetzungsdetail}:
                  </div>
                  <div className="auftragsdruck__wert">
                    {Array.isArray(umsetzungsdetails[umsetzungsdetail])
                      ? umsetzungsdetails[umsetzungsdetail].join(', ')
                      : umsetzungsdetails[umsetzungsdetail]}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
      {spezifikation['Sonstige Informationen'] ? (
        <div className="auftragsdruck__sonstiges">
          <div className="auftragsdruck__header">Sonstige Informationen</div>
          <div className="auftragsdruck__content">
            {spezifikation['Sonstige Informationen']}
          </div>
        </div>
      ) : (
        spezifikation['Auftragsbeschreibung'] && (
          <div className="auftragsdruck__sonstiges">
            <div className="auftragsdruck__header">Auftragsbeschreibung</div>
            <div className="auftragsdruck__content">
              {spezifikation['Auftragsbeschreibung']}
            </div>
          </div>
        )
      )}
      {anfrage.auftrag.anhaenge.length > 0 && (
        <div className="auftragsdruck__dateien">
          <div className="auftragsdruck__header">Fotos &amp; Grundrisse</div>
          <div className="auftragsdruck__content">
            {fotosUndGrundrisse.map((anhang) => (
              <FotoOderGrundriss
                key={anhang.datei.key}
                anhang={anhang}
                onLoad={createHandleLoad(anhang)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function FotoOderGrundriss({ anhang, onLoad }) {
  const url = useAnhangUrl({ anhang });
  if (anhang.typ === 'FOTO') {
    return (
      <div className="auftragsdruck__fotoContainer">
        <div className="auftragsdruck__foto">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img
              className="auftragsdruck__fotoImage"
              src={url}
              alt=""
              onLoad={onLoad}
            />
          </a>
        </div>
        <div className="auftragsdruck__dateiname">{anhang.datei.fileName}</div>
      </div>
    );
  }
  return null;
}
