import React from 'react';
import { useDialogState, useModalState } from '../../hooks';
import Button from '../Button/Button';
import AnfrageZurueckziehenOderAblehnenDialog from '../AnfrageZurueckziehenOderAblehnenDialog/AnfrageZurueckziehenOderAblehnenDialog';
import ProfilModal from '../ProfilModal/ProfilModal';
import Link from '../Link/Link';

import { ReactComponent as KundeIcon } from '../../assets/icons/kunde.svg';
import { ReactComponent as HandwerkerIcon } from '../../assets/icons/handwerker.svg';

import './ChatHeader.scss';

const ChatHeader = ({ anfrage, intro, autor }) => {
  const [
    isAnfrageZurueckziehenOderAblehnenDialogShown,
    showAnfrageZurueckziehenOderAblehnenDialog,
    hideAnfrageZurueckziehenOderAblehnenDialog,
  ] = useDialogState();

  const [
    isProfilModalShown,
    showProfilModal,
    hideProfilModal,
  ] = useModalState();

  const istHandwerker = autor === 'HANDWERKER';

  const profilProps = istHandwerker
    ? {
        kunde: anfrage.auftrag.kunde,
      }
    : {
        handwerker: anfrage.handwerker,
      };

  const Icon = istHandwerker ? HandwerkerIcon : KundeIcon;

  return (
    <div className="chatHeader">
      <div className="chatHeader__intro">
        <Link onClick={showProfilModal} icon={<Icon width={24} height={24} />}>
          {intro}
        </Link>
      </div>
      {isProfilModalShown && (
        <ProfilModal hide={hideProfilModal} {...profilProps} />
      )}
      {anfrage.kannAnfrageZurueckgezogenOderAbgelehntWerden() && (
        <Button
          className="margin:right:1rem"
          size="small"
          elevated
          onClick={showAnfrageZurueckziehenOderAblehnenDialog}
          data-cy-id="anfrageZurueckziehenOderAblehnenModalOeffnenButton"
        >
          {istHandwerker ? 'Anfrage ablehnen' : 'Anfrage zurückziehen'}
        </Button>
      )}
      {isAnfrageZurueckziehenOderAblehnenDialogShown && (
        <AnfrageZurueckziehenOderAblehnenDialog
          hide={hideAnfrageZurueckziehenOderAblehnenDialog}
          anfrage={anfrage}
          autor={autor}
        />
      )}
    </div>
  );
};

export default ChatHeader;
