import { useEffect } from 'react';
import { Storage } from 'aws-amplify';

export default function Download({ match }) {
  const fileKey = match.params.fileKey;

  useEffect(() => {
    async function redirectToFile() {
      const fileUrl = await Storage.get(fileKey);
      window.location.href = fileUrl;
    }

    redirectToFile();
  }, [fileKey]);

  return null;
}
