import React from 'react';
import LegalContent from '../../components/LegalContent/LegalContent';

export default function () {
  return (
    <LegalContent headline="Hinweis zur Verwendung Ihrer E-Mail Adresse">
      <p className="legalContent__paragraph">
        Wir verwenden Ihre E-Mail Adresse unter den gesetzlichen Voraussetzungen
        für Zwecke der Direktwerbung sowie für Meinungsumfragen. Sie können
        dieser Verwendung jederzeit durch eine Mitteilung an uns per E-Mail an{' '}
        <a href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>{' '}
        widersprechen. Hierfür entstehen keine anderen als die
        Übermittlungskosten nach den Basistarifen.
      </p>
    </LegalContent>
  );
}
