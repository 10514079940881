import SCHRITTE from '../../../domain/schritte';

export function gtmSchrittLabel(kategorieIndex, schrittIndex, spezifikation) {
  return [
    'taetigkeiten',
    'auftragsdetails',
    'zeitraum',
    'standort',
    'kontakt',
    'handwerkerauswahl',
  ]
    .filter(abKategorie(kategorieIndex))
    .map(fuegeWerteHinzu(schrittIndex, spezifikation))
    .join('/');
}

function fuegeWerteHinzu(schrittIndex, spezifikation) {
  return (kategorie) => {
    if (kategorie === 'auftragsdetails') {
      return fuegeAuftragsdetailswerteHinzu(
        kategorie,
        schrittIndex,
        spezifikation
      );
    } else if (kategorie === 'taetigkeiten') {
      return fuegeTaetigkeitenwerteHinzu(
        kategorie,
        schrittIndex,
        spezifikation
      );
    }

    return kategorie;
  };
}

function fuegeAuftragsdetailswerteHinzu(
  kategorie,
  schrittIndex,
  spezifikation
) {
  const schritt = aktuellerSchritt(schrittIndex, spezifikation);

  if (
    naechsterSchrittIstGleicheKategorie(schritt, schrittIndex, spezifikation)
  ) {
    return kategorie.concat('[1]');
  } else if (
    vorherigerSchrittIstGleicheKategorie(schritt, schrittIndex, spezifikation)
  ) {
    return kategorie.concat('[2]');
  }
  return kategorie;
}

function fuegeTaetigkeitenwerteHinzu(kategorie, schrittIndex, spezifikation) {
  const taetigkeitenwerte = [
    spezifikation.bereich,
    spezifikation.objektart,
    spezifikation.taetigkeit,
    spezifikation.teiltaetigkeit?.length > 0 && 'Teiltaetigkeit',
  ]
    .filter(abSchritt(schrittIndex))
    .filter(Boolean)
    .join(';');

  return kategorie.concat(taetigkeitenwerte ? `[${taetigkeitenwerte}]` : '');
}

function aktuellerSchritt(schrittIndex, spezifikation) {
  return SCHRITTE.find((s) => s.bedingung(schrittIndex, spezifikation));
}

function naechsterSchrittIstGleicheKategorie(
  schritt,
  schrittIndex,
  spezifikation
) {
  return (
    SCHRITTE.find((schritt) =>
      schritt.bedingung(schrittIndex + 1, spezifikation)
    )?.kategorieIndex === schritt.kategorieIndex
  );
}

function vorherigerSchrittIstGleicheKategorie(
  schritt,
  schrittIndex,
  spezifikation
) {
  return (
    SCHRITTE.find((schritt) =>
      schritt.bedingung(schrittIndex - 1, spezifikation)
    )?.kategorieIndex === schritt.kategorieIndex
  );
}

function abKategorie(kategorieIndex) {
  return (_, index) => kategorieIndex >= index;
}

function abSchritt(schrittIndex) {
  return (_, index) => schrittIndex > index;
}
