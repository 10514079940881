import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './LegalContent.scss';

export default ({ headline, tabs, defaultTab, children }) => {
  const [currentContent, setCurrentContent] = useState(defaultTab);

  useEffect(() => {
    setCurrentContent(defaultTab);
  }, [defaultTab]);

  if (tabs) {
    return (
      <div className="legalContent">
        <div className="legalContent__content">
          <h1 className="legalContent__headline1">{headline}</h1>
          <div className="legalContent__tabs">
            {tabs.map(tab => (
              <div
                key={tab}
                className={classNames('legalContent__tab', {
                  'legalContent__tab--active': currentContent === tab,
                  'legalContent__tab--inactive': currentContent !== tab
                })}
                onClick={() => setCurrentContent(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
          {children(currentContent)}
        </div>
      </div>
    );
  }
  return (
    <div className="legalContent">
      <div className="legalContent__content">
        <h1 className="legalContent__headline1">{headline}</h1>
        {children}
      </div>
    </div>
  );
};
