import './Accordion.scss';

import React, { useState } from 'react';

import classNames from 'classnames';
import { gtmEvent } from '../../googleAnalytics';

export function Accordion({ children, gtmCategory, gtmAction }) {
  const [selectedIndex, selectIndex] = useState(null);

  return React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      currentIndex: selectedIndex === index,
      selectIndex: () => {
        if (gtmCategory && gtmAction) {
          gtmEvent({
            category: gtmCategory,
            action: gtmAction,
          });
        }
        if (selectedIndex === index) {
          selectIndex(null);
        } else {
          selectIndex(index);
        }
      },
    });
  });
}

export function Entry({ currentIndex, selectIndex, title, children }) {
  return (
    <div className="accordion-wrapper">
      <div className="accordion">
        <div
          className={classNames('accordion__title', {
            'accordion__title--selected': currentIndex,
          })}
          onClick={selectIndex}
          >
          {title}
        </div>
        <div
          className={classNames('accordion__content', {
            'accordion__content--selected': currentIndex,
          })}
          >
          {children}
        </div>
      </div>
    </div>
  );
}
