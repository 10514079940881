import React, { useState } from 'react';
import Link from '../Link/Link';
import arrowDown from '../../assets/icons/arrow_down.svg';
import arrowUp from '../../assets/icons/arrow_up.svg';
import './Collapsible.scss';

export default function Collapsible({ header, children }) {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <Link
        className="collapsible__header"
        onClick={() => setCollapsed(!collapsed)}
        size="small"
      >
        {header}
        <img
          className="collapsible__arrows"
          src={collapsed ? arrowDown : arrowUp}
          alt=""
        />
      </Link>
      {!collapsed && children}
    </>
  );
}
