import { useEffect, useState } from 'react';
import { ladeBewertungenZuHandwerkerliste } from '../domain/bewertung';
import { groupBy } from '../collections';

export default function useBewertungenZuHanderkerliste(handwerkerliste) {
  const [bewertungenZuHandwerker, setBewertungenZuHandwerker] = useState(
    new Map()
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;

    async function ladeBewertungen() {
      try {
        if (!didCancel) {
          setLoading(true);
        }
        const bewertungen = await ladeBewertungenZuHandwerkerliste(
          handwerkerliste
        );
        if (!didCancel) {
          setBewertungenZuHandwerker(
            groupBy(bewertungen, (bewertung) => bewertung.bewertungHandwerkerId)
          );
        }
      } catch (errors) {
        console.log(errors);
      } finally {
        if (!didCancel) {
          setLoading(false);
        }
      }
    }

    if (handwerkerliste && handwerkerliste.length > 0) {
      ladeBewertungen();
    }

    return () => {
      didCancel = true;
    };
  }, [handwerkerliste]);

  return { bewertungenZuHandwerker, isLoading };
}
