import React, { useState } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Dropzone from '../Dropzone/Dropzone';
import { ACCEPTED_TYPES_PDF_ONLY } from '../Dropzone/constants';
import './DateiMitBetragHochladenModal.scss';

function isValidNumber(value) {
  return Boolean(value.match(/^[\d]+(,[\d]*)?$/g));
}

function convertToNumber(value) {
  const cleanedValue = value.trim().endsWith(',') ? `${value}0` : value;
  return parseFloat(cleanedValue.replace(',', '.'));
}

const DateiMitBetragHochladenModal = ({
  hide,
  ladeDateiHoch,
  title,
  dateiHochladenLabel,
  betragLabel,
  children,
}) => {
  const [betrag, setBetrag] = useState('');
  const [betragValide, setBetragValide] = useState(false);
  const [file, setDatei] = useState(null);
  const handleFilesChange = (fileContainers) =>
    fileContainers.length === 1
      ? setDatei(fileContainers[0].file)
      : setDatei(null);

  const handleBetragChange = (event) => {
    const value = event.target.value;
    setBetragValide(isValidNumber(value.replace(/\s*€+/, '').trim()));
    setBetrag(value);
  };

  const handleBetragBlur = (event) => {
    const value = event.target.value;
    setBetrag(
      `${convertToNumber('0' + value).toFixed(2)}`
        .replace('.', ',')
        .concat(' €')
    );
    setBetragValide(true);
  };

  const isHochladenDisabled = !file || !betrag || !betragValide;
  const createLadeDateiHoch = (setLoading) => async () => {
    setLoading(true);
    try {
      await ladeDateiHoch(file, betrag, convertToNumber(betrag));
      hide();
    } catch (e) {
      setLoading(false);
    }
  };

  const actions = (setLoading) => (
    <>
      <Button data-cy-id="dateiHochladenAbbrechenButton" onClick={hide}>
        Abbrechen
      </Button>
      <Button
        color="green"
        onClick={createLadeDateiHoch(setLoading)}
        disabled={isHochladenDisabled}
        data-cy-id="dateiHochladenButton"
      >
        {dateiHochladenLabel}
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title={title}
      hide={hide}
      actions={actions}
      gaTrackingName="Angebot oder Rechnung hochladen"
    >
      <div className="dateiMitBetragHochladen">
        {children}
        <Dropzone
          className="dateiMitBetragHochladen__dropzone"
          onChange={handleFilesChange}
          fileContainers={file ? [{ file }] : []}
          acceptedTypes={ACCEPTED_TYPES_PDF_ONLY}
          maxFileSizeInMb={6} // Datei wird als Anhang in der Mail verschickt. AWS Limit beträgt 10MB => base64(6MB) ~ 9MB. 1MB als Puffer
        />
        <label
          htmlFor="betrag"
          className="dateiMitBetragHochladen__betragLabel"
        >
          {betragLabel}
        </label>
        <input
          id="betrag"
          name="betrag"
          type="text"
          className="dateiMitBetragHochladen__betragInput"
          placeholder="€"
          value={betrag || ''}
          onChange={handleBetragChange}
          onBlur={handleBetragBlur}
        />

        <div className="dateiMitBetragHochladen__betragfehler">
          {!betragValide &&
            betrag.length > 0 &&
            'Bitte geben Sie einen Betrag in Euro an.'}
        </div>
      </div>
    </Modal>
  );
};

export default DateiMitBetragHochladenModal;
