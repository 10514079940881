import React from 'react';
import Modal from '../Modal/Modal';
import Angebot from '../Angebot/Angebot';
import Collapsible from '../Collapsible/Collapsible';

export default ({ anfrage, angebote, autor, hide }) => {
  const angenommeneAngebote = angebote.filter(angebot =>
    angebot.wurdeAngenommen()
  );
  const abgelehnteAngebote = angebote.filter(angebot =>
    angebot.wurdeAbgelehnt()
  );
  const offeneAngebote = angebote.filter(angebot => angebot.istOffen());

  return (
    <Modal
      title="Angebotsübersicht"
      hide={hide}
      gaTrackingName="Angebotsübersicht"
    >
      {offeneAngebote.length > 0
        ? offeneAngebote.map(angebot => (
            <Angebot
              key={angebot.id}
              anfrage={anfrage}
              angebot={angebot}
              autor={autor}
            />
          ))
        : null}
      <div className="angebote__offen">
        {angenommeneAngebote.length > 0
          ? angenommeneAngebote.map(angebot => (
              <Angebot
                key={angebot.id}
                anfrage={anfrage}
                angebot={angebot}
                autor={autor}
              />
            ))
          : null}
      </div>
      <Collapsible header={`Abgelehnt (${abgelehnteAngebote.length})`}>
        {abgelehnteAngebote.length > 0
          ? abgelehnteAngebote.map(angebot => (
              <Angebot
                key={angebot.id}
                anfrage={anfrage}
                angebot={angebot}
                autor={autor}
              />
            ))
          : null}
      </Collapsible>
    </Modal>
  );
};
