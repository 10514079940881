import React from 'react';

export default function HandwerkerText() {
  return (
    <>
      <p className="legalContent__paragraph">
        Die Plattform „MeinZuhauseUndIch“ ist ein Online-Dienst zur Vermittlung
        und zum Angebot von Dienstleistungen. Die Provinzial
        Dienstleistungsgesellschaft mbh (im Folgenden: Provinzial) als Teil des
        Provinzial Rheinland Konzerns möchte als Betreiberin der Plattform
        exklusiv den Versicherungskunden der Provinzial Rheinland Versicherungen
        eine schnelle Vermittlung geeigneter und kompetenter Handwerker und
        Dienstleister und das Angebot von Dienstleistungen rund um das Zuhause
        des Kunden anbieten. Die Nutzung der Plattform unterliegt den
        nachfolgenden Nutzungsbedingungen.{' '}
        <span className="legalContent__new">
          Mit der Registrierung auf der Plattform „MeinZuhauseUndIch“ erkennen
          Sie die Nutzungsbedingungen an.
        </span>
      </p>
      <h2 className="legalContent__headline2">
        1. Zustandekommen des Nutzungsvertrages
      </h2>
      <p className="legalContent__paragraph">
        1.1 Mit „MeinZuhauseUndIch“ steht Ihnen als Handwerker/Dienstleister (im
        Folgenden: Auftragnehmer) eine Plattform zur Verfügung, über die Sie
        unmittelbar gegenüber Auftraggebern verbindliche Angebote auf
        Ausschreibungen abgeben können.
      </p>
      <p className="legalContent__paragraph">
        1.2 Die Plattform steht auf Auftraggeber-Seite ausschließlich Kunden der
        Provinzial Rheinland Versicherung zur Verfügung.
      </p>
      <p className="legalContent__paragraph">
        1.3 Mit Ihrer Registrierung kommt ein Nutzungsvertrag zwischen Ihnen und
        der Provinzial über die Vermittlung von Handwerker- und Dienstleistungen
        zustande. Die Registrierung ist nur solchen Auftragnehmern möglich, die
        bereits am Provinzial-Handwerkernetz der I-NADIS für die Abwicklung von
        Versicherungsschäden von Kunden der Provinzial Rheinland Versicherung
        teilnehmen.
      </p>
      <p className="legalContent__paragraph legalContent__new">
        1.4 Die I-NADIS wird Ihnen im Auftrag der Provinzial eine Nachricht zur
        Registrierung auf der Plattform mit einem individuellen
        Registrierungslink zusenden. Wenn Sie auf der Plattform die
        Registrierung durchlaufen und die AGB anerkennen, wird ein Nutzerkonto
        für Sie angelegt und der Nutzungsvertrag kommt zustande.
      </p>
      <h2 className="legalContent__headline2">
        2. Gegenstand des Nutzungsvertrages
      </h2>
      <p className="legalContent__paragraph">
        2.1 Die Provinzial vermittelt lediglich zwischen Ihnen als Auftragnehmer
        und dem Auftraggeber, ist also selbst nicht Beteiligte des vermittelten
        Auftragsverhältnisses und nicht für dessen Durchführung verantwortlich.
        Der Inhalt des Auftragsverhältnisses richtet sich allein nach den
        Vereinbarungen, die Sie mit dem Auftraggeber getroffen haben.
      </p>
      <p className="legalContent__paragraph">
        2.2 Die Provinzial schuldet aus diesem Vertrag lediglich das Bemühen um
        eine Vermittlung und keinen Vermittlungserfolg. Ein Anspruch auf
        Vermittlung von Auftragsverhältnissen besteht daher nicht.
      </p>
      <h2 className="legalContent__headline2">3. Nutzerkonto</h2>
      <p className="legalContent__paragraph legalContent__new">
        3.1 Die Zugangsdaten zu Ihrem Nutzerkonto bestehen aus Ihrer
        vierstelligen HWK-Nummer (die Ihnen von der I-NADIS vergeben wurde) und
        einem von Ihnen selbst vergebenen Passwort.
      </p>
      <p className="legalContent__paragraph">
        3.2 Mit Ihrer Registrierung widmen Sie Ihr Nutzerkonto als
        Empfangsvorrichtung, das heißt, Sie erklären Sie sich damit
        einverstanden, dass Ihnen über Ihr Nutzerkonto rechtswirksam Dokumente
        und Willenserklärungen übermittelt werden können. Sie sind daher im
        eigenen Interesse verpflichtet, Ihr Nutzerkonto regelmäßig auf neue
        Nachrichteneingänge zu kontrollieren, sofern mit solchen zu rechnen ist.
        Dies gilt insbesondere bei laufenden Ausschreibungen oder erteilten
        Aufträgen.
      </p>
      <p className="legalContent__paragraph">
        3.3 Sie dürfen Ihre Zugangsdaten nicht an Dritte weitergeben. Bitte
        ergreifen Sie auch die erforderlichen Maßnahmen, um Ihre Daten gegen dem
        unbefugten Gebrauch durch Dritte zu schützen. Bei Meldung einer
        Fremdnutzung durch Sie oder dem begründetem Verdacht einer solchen, wird
        die Provinzial die notwendigen Maßnahmen ergreifen. Sie ist insbesondere
        berechtigt, den Zugang vorübergehend zu sperren oder zu schließen.
      </p>
      <p className="legalContent__paragraph">
        3.4 Sie haben die Möglichkeit, Ihr Unternehmen mithilfe Ihres
        Nutzerprofils darzustellen. Sie verpflichten sich, ausschließlich
        wahrheitsgemäße Angaben zu machen. Für den Inhalt, Bewertung und
        Gestaltung in Text, Bild, Ton etc. sind alleine Sie verantwortlich.
        Bitte prüfen Sie daher vor Veröffentlichung von Inhalten, ob Sie hierfür
        über die notwendigen Rechte verfügen.
      </p>
      <p className="legalContent__paragraph">
        3.5 Da Sie eigene Dienstleitungen über die Plattform anbieten, sind Sie
        verpflichtet, stets ein aktuelles, den Anforderungen des § 5 TMG
        entsprechendes Impressum auf Ihrer Unternehmenswebsite vorzuhalten, auf
        welches Sie aus Ihrem Nutzerkonto heraus verlinken können. Sollten Sie
        über keine eigene Unternehmenswebsite verfügen, sind Sie verpflichtet,
        Ihr Nutzerprofil mit einem eigenen Impressum über die hierfür
        vorgesehene Funktion auszustatten.
      </p>
      <h2 className="legalContent__headline2">4. Auftragsverhältnis</h2>
      <p className="legalContent__paragraph">
        4.1 Die vom Auftraggeber veröffentlichte Ausschreibung ist eine
        unverbindliche Aufforderung zur Abgabe von verbindlichen Angeboten.
      </p>
      <p className="legalContent__paragraph">
        4.2 Die Provinzial wird aufgrund der Angaben des Auftraggebers,
        insbesondere nach Ort und Inhalt der durchzuführenden Arbeiten und
        Abgleich mit den Auftragnehmer-Profilen, eine Vorauswahl an in Betracht
        kommenden Auftragnehmern vornehmen. Der Auftraggeber wählt unter dieser
        Vorauswahl maximal drei Auftragnehmer aus. Sofern Sie vom Auftragnehmer
        ausgewählt wurden, erhalten Sie eine Benachrichtigung der Provinzial
        sowie eine Aufforderung, dem Auftragnehmer innerhalb von drei Werktagen
        ein verbindliches Angebot zukommen zu lassen, eine Rückfrage zur
        Ausschreibung zu stellen oder Ihre Teilnahme an der Ausschreibung
        abzulehnen.
      </p>
      <p className="legalContent__paragraph">
        4.3 Entscheidet sich der Auftraggeber für Ihr Angebot, erfolgt die
        verbindliche Auftragsvergabe direkt auf der Plattform, indem der
        Auftraggeber den Button „Kostenpflichtig beauftragen“ betätigt. Hierzu
        erhalten Sie umgehend von der Provinzial eine Auftragsbestätigung. Bitte
        beachten Sie, dass dem Auftraggeber, der hierbei als Verbraucher
        handelt, bei dieser Form des Vertragsschlusses im Fernabsatz ein
        Widerrufsrecht zusteht.
      </p>
      <p className="legalContent__paragraph">
        4.4 Nach Durchführung des Auftrags verpflichten Sie sich, auf eine
        zeitnahe Abnahme der Arbeiten hinzuwirken. Der Auftraggeber kann die
        Abnahme auch elektronisch über die Plattform erklären.
      </p>
      <h2 className="legalContent__headline2">5. Angebote und Rechnungen</h2>
      <p className="legalContent__paragraph">
        5.1 Sie können Ihre Angebote auf Ausschreibungen mittels der hierfür
        vorgesehenen Upload-Funktion abgeben.
      </p>
      <p className="legalContent__paragraph">
        5.2 Um eine hinreichende Information des Auftraggebers vor
        Auftragserteilung zu gewährleisten muss das Angebot folgende
        Mindestangaben enthalten:
      </p>
      <ul className="legalContent__list">
        <li>vollständige Firmenangabe</li>
        <li>Leistungsbeschreibung</li>
        <li>Ausführungszeitraum</li>
        <li>
          Brutto-Gesamtpreis einschließlich der jeweils geltenden Umsatzsteuer
          sowie Anfahrtskosten und etwaige sonstige Sonder- und Nebenkosten,
          aufgeteilt nach Lohn- und Materialkosten
        </li>
        <li>Zahlungsziel</li>
        <li>Zahlungsmöglichkeiten für den Kunden</li>
        <li>Ggf. vom Kunden zu stellende Sicherheiten</li>
        <li>Gültigkeit bzw. Bindungsfrist des Angebots</li>
        <li>
          Ggf. Verfahren zum Umgang mit Beschwerden und Vorhandensein eines
          Kundendienstes (falls jeweils vorhanden)
        </li>
        <li>Ggf. Verhaltenskodizes (falls vorhanden)</li>
      </ul>
      <p className="legalContent__paragraph">
        5.3 Sie verpflichten sich bei der Abgabe von Angeboten zur Verwendung
        ortsüblicher Preise.
      </p>
      <p className="legalContent__paragraph">
        5.4 Sie verpflichten sich, die Rechnung zu Auftragsverhältnissen, die
        über die Plattform zustande kommen, auf der Plattform dem Auftraggeber
        zu übermitteln. Hierfür steht Ihnen auf der Plattform eine Funktion zum
        Upload Ihrer Rechnung zur Verfügung.
      </p>
      <h2 className="legalContent__headline2">6. Service Level Vereinbarung</h2>
      <p className="legalContent__paragraph">
        Mit der Vermittlung von Handwerks- und Dienstleistungen außerhalb von
        Schadenfällen möchte die Provinzial den Kunden der Provinzial Rheinland
        Versicherung einen Mehrwert mit den gleichen Qualitätsstandards wie in
        Schadenfällen bieten. Zur Sicherstellung dieser Qualitätsstandards und
        einer optimierten Auftragsabwicklung verpflichten Sie sich zu folgenden
        Maßnahmen:
      </p>
      <h3 className="legalContent__headline3">a) Reaktionszeiten:</h3>
      <ul className="legalContent__list">
        <li>
          Eine Antwort auf eine Ausschreibung eines Auftraggebers
          (Angebotserstellung bzw. Rückfragen zur Konkretisierung des Angebotes
          oder Ablehnung Ihrer Teilnahme an der Ausschreibung) erfolgt innerhalb
          von drei Werktagen nach erfolgter Ausschreibung über die Plattform.
        </li>
        <li>
          Nach Beauftragung durch den Auftraggeber und auf Basis des erstellten
          Angebotes, sind dem Auftraggeber innerhalb von drei Werktagen 1-3
          Terminvorschläge für die Umsetzung der Arbeiten zu unterbreiten.
        </li>
        <li>
          Der Samstag wird bei diesen Service Leveln zu Reaktionszeiten nicht
          als Werktag gezählt.
        </li>
        <li>
          Weitere Nachrichten des Auftraggebers über die Plattform sind
          kurzfristig zu beantworten.
        </li>
        <li>
          Nicht einzuhaltende Termine sind dem Auftraggeber unverzüglich
          mitzuteilen.
        </li>
        <li>
          Die Rechnungsstellung an den Auftraggeber muss spätestens drei Wochen
          nach Fertigstellung der erbrachten Leistungen erfolgen.
        </li>
      </ul>
      <h3 className="legalContent__headline3">b) Ihr Nutzerprofil</h3>
      <ul className="legalContent__list">
        <li>
          Sie sind verpflichtet, richtige und vollständige Angaben insbesondere
          über Ihre Identität, Anschrift, Telefonnummer, E-Mail-Adresse,
          Qualifikationen, Zulassungen und Erlaubnisse zu machen.
        </li>
        <li>
          Auch während der Vertragslaufzeit sind diese Angaben von Ihnen stets
          in Ihrem Nutzerprofil auf dem aktuellsten Stand zu halten.
        </li>
      </ul>
      <h3 className="legalContent__headline3">
        c) Leistungsumfang und Qualität der Leistungen:
      </h3>
      <ul className="legalContent__list">
        <li>
          Die Durchführung der Arbeiten erfolgt durch eigene, qualifizierte und
          fachlich versierte Mitarbeiter.
        </li>
        <li>Der Einsatz von Subunternehmern ist untersagt.</li>
        <li>
          Es dürfen nur solche Materialen verwendet werden, über die Sie das
          uneingeschränkte Eigentumsrecht besitzen und die vollkommen frei von
          Rechten Dritter sind oder vom Auftraggeber bereitgestellt werden.
        </li>
        <li>
          Materialien werden von Ihnen fach- und sachgerecht entsorgt sowie nur
          handelsübliche und normgerechte Materialien verwendet.
        </li>
        <li>
          Verunreinigungen werden von Ihnen beseitigt, soweit sie von Ihren
          eigenen Arbeiten herrühren.
        </li>
        <li>Gegenüber dem Auftraggeber erfolgt ein ordentliches Auftreten.</li>
      </ul>
      <h2 className="legalContent__headline2">7. Vergütung </h2>
      <p className="legalContent__paragraph">
        7.1 Die Registrierung und Nutzung der Plattform sind kostenfrei. Das
        bedeutet, dass keine Gebühren erhoben werden, wenn Sie lediglich bei
        einer Ausschreibung eines Auftraggebers als möglicher Auftragnehmer von
        der Provinzial empfohlen werden. Ebenfalls werden keine Gebühren
        erhoben, wenn Sie auf Ausschreibungen reagieren und ein verbindliches
        Angebot erstellen.
      </p>
      <p className="legalContent__paragraph legalContent__new">
        7.2. Für die erfolgreiche Vermittlung durch Zustandekommen eines
        Auftragsverhältnisses über die Plattform wird Ihnen eine Gebühr von 3 %
        des Auftragsvolumens berechnet. Als Bemessungsgrundlage für die
        Berechnung der Gebühr gilt der von Ihnen in Rechnung gestellte
        Nettobetrag zum jeweiligen Auftrag. Für Aufträge mit Auftragsvolumen
        unter 300 Euro wird keine Gebühr erhoben („Kleinauftrags-Bonus“). Die
        Gebühr versteht sich zzgl. der jeweils geltenden gesetzlichen
        Mehrwertsteuer. Die Abrechnung der Gebühren erfolgt monatlich.
      </p>
      <h2 className="legalContent__headline2">
        8. Rechtmäßige Nutzung und Zugang
      </h2>
      <p className="legalContent__paragraph">
        8.1 Die Nutzung der Plattform darf nur in Übereinstimmung mit geltendem
        Recht und diesen AGB erfolgen. Dies gilt insbesondere für Ihre
        Darstellung im Nutzerprofil, für den Inhalt der von Ihnen eingestellten
        Angebote sowie für Abgabe und Inhalt von Bewertungen oder
        Stellungnahmen.
      </p>
      <p className="legalContent__paragraph">
        8.2 Untersagt sind insbesondere:
      </p>
      <ul className="legalContent__list">
        <li>
          die Verbreitung rassistischer, pornografischer, gewaltverherrlichender
          oder –verharmlosender sowie sittenwidriger Inhalte
        </li>
        <li>
          die Verbreitung von Informationen, die Kinder oder Jugendliche in
          ihrer Entwicklung oder Erziehung beeinträchtigen oder gefährden oder
          die die Menschenwürde oder sonstige durch das Strafgesetzbuch
          geschützte Rechtsgüter verletzen
        </li>
        <li>der Aufruf und Verleitung zu Straftaten</li>
        <li>die Beleidigung, Verleumdung oder Bedrohung anderer Nutzer</li>
        <li>
          die Verletzung von Urheber-, Marken- oder sonstiger Schutzrechte
          Dritter
        </li>
        <li>
          die Vorbereitung und Durchführung von Aufträgen entgegen gesetzlicher
          Bestimmungen zur Bekämpfung der Schwarzarbeit
        </li>
      </ul>
      <p className="legalContent__paragraph">
        8.3 Sie haben im Rahmen des Ihnen Zumutbaren und technisch Möglichen
        dafür Sorge zu tragen, dass von Ihnen übermittelte Daten keine Viren
        oder ähnliche Schadsoftware enthalten, andernfalls haften Sie für
        hieraus entstehende Schäden.
      </p>
      <p className="legalContent__paragraph">
        8.4 Wird der Provinzial ein schuldhafter Verstoß Ihrerseits gegen
        geltendes Recht, diese AGB oder sonstige dringende Umstände bekannt, die
        gegen eine weitere Nutzung durch Sie sprechen, ist sie berechtigt,
        erforderliche und angemessene Maßnahmen zu ergreifen. Schwere Verstöße
        können zur vorübergehenden Einschränkung oder Aussetzung Ihres Zugangs
        zur Plattform und deren Nutzung führen. Für diesen Fall behält sich die
        Provinzial auch das Recht zur fristlosen Kündigung aus wichtigem Grund
        vor.
      </p>
      <p className="legalContent__paragraph">
        8.5 Beschließt die Provinzial aus den unter Ziff. 8.4 erwähnten Gründen,
        Ihre Nutzungsrechte vorübergehend einzuschränken oder auszusetzen, wird
        sie Ihnen gleichzeitig mit dem Wirksamwerden der Einschränkung oder
        Aussetzung die Gründe hierfür auf einem dauerhaften Datenträger
        übermitteln. Sie haben die Möglichkeit, die Tatsachen und Umstände, die
        zur Einschränkung oder Aussetzung geführt haben, im Rahmen des internen
        Beschwerdemanagements der Provinzial überprüfen zu lassen. Im Falle der
        Aufhebung der Einschränkung oder Aussetzung wird Ihnen die Provinzial
        umgehend wieder den vollen Zugang zur Plattform und Ihrem Nutzerkonto
        verschaffen.
      </p>
      <h2 className="legalContent__headline2">9. Bewertungen</h2>
      <p className="legalContent__paragraph">
        9.1 Nach Auftragsdurchführung hat der Auftraggeber die Möglichkeit, Sie
        zu bewerten. Hierbei gilt für den Auftraggeber das Gebot, dass
        Bewertungen ein möglichst aussagekräftiges und zutreffendes Bild über
        Qualität, Zuverlässigkeit und Vertrauenswürdigkeit vermitteln sollen.
        Die Bewertungen sind daher nach dem Gebot der Fairness, sachlich und
        wahrheitsgemäß zu verfassen.
      </p>
      <p className="legalContent__paragraph">
        9.2 Die Provinzial behält sich vor, nachweislich unzutreffende,
        unsachliche oder beleidigende Bewertungen ganz oder vollständig zu
        sperren oder zu löschen.
      </p>
      <h2 className="legalContent__headline2">10. Gewährleistung</h2>
      <p className="legalContent__paragraph">
        10.1 Die Provinzial schuldet allein die Vermittlung von Handwerker- und
        Dienstleistungen. Sie haftet jedoch nicht für das erfolgreiche
        Zustandekommen eines entsprechenden Auftragsverhältnisses.
      </p>
      <p className="legalContent__paragraph">
        10.2 Die Provinzial kann ferner nicht gewährleisten, dass die Plattform
        zu jeder Zeit uneingeschränkt verfügbar ist. Soweit dies möglich ist,
        wird sich die Provinzial bemühen, Beschränkungen, Beeinträchtigungen,
        Verbindungsabbrüche oder sonstige Nutzungseinschränkungen zu vermeiden.
        Die Verfügbarkeit kann allerdings beim gegenwärtigen Stand der
        Kommunikationstechnik ganz oder teilweise eingeschränkt sein, ohne dass
        die Provinzial hierauf Einfluss nehmen kann. Eine Haftung ist insofern
        ausgeschlossen.
      </p>
      <p className="legalContent__paragraph">
        10.3 Die Provinzial schuldet gleichermaßen nicht primär die
        ordnungsgemäße und mangelfreie Durchführung des vermittelten
        Auftragsverhältnisses. Sie übernimmt jedoch während des laufenden
        Vertragsverhältnisses mit dem Auftraggeber diesem gegenüber
        gesamtschuldnerisch neben Ihnen als Auftragnehmer unter folgenden
        Voraussetzungen und im nachfolgend beschriebenen Umfang eine 5-jährige
        Gewährleistung für die erbrachten Leistungen:
      </p>
      <ul className="legalContent__list">
        <li>Die Gewährleistungsfrist beginnt mit dem Rechnungsdatum.</li>
        <li>
          Die Gewährleistung bezieht sich nur auf die Werkleistung, nicht auf
          Mängel des genutzten Materials.
        </li>
        <li>
          Die Gewährleistungsansprüche werden vom Auftraggeber während des
          laufenden Vertragsverhältnisse betreffend die Nutzung der Plattform
          gegenüber der Provinzial angemeldet.
        </li>
        <li>
          Mit Beendigung des Vertragsverhältnisses zwischen Auftraggeber und
          Provinzial erlöschen sämtliche Gewährleistungsansprüche gegenüber der
          Provinzial. Dies gilt auch für bereits angemeldete Ansprüche. Ihre
          Gewährleistungspflichten bleiben hiervon unberührt.
        </li>
        <li>
          Der Auftraggeber tritt mit Geltendmachung seiner Ansprüche gegenüber
          der Provinzial seine gesetzlichen und mit Ihnen vertraglich
          vereinbarten Gewährleistungsansprüche an die Provinzial ab. Für den
          Fall der Beendigung des Vertragsverhältnisses zwischen Provinzial und
          Auftraggeber und dem Erlöschen von Gewährleistungsansprüchen gegen die
          Provinzial, tritt diese die Ansprüche gegen Sie wieder zur freien
          Verfügung an den Auftraggeber ab.
        </li>
        <li>
          Der Mangel wird durch die Provinzial geprüft. Bei Vorliegen eines
          berechtigten Mangels räumt die Provinzial Ihnen zunächst unter
          angemessener Fristsetzung die Möglichkeit der Mangelbeseitigung /
          Nachbesserung ein. Wenn die Mangelbeseitigung / Nachbesserung von
          Ihnen nicht fristgerecht wahrgenommen oder verweigert wird, beauftragt
          die Provinzial einen Dritten mit der Mangelbeseitigung /
          Nachbesserung.
        </li>
        <li>
          Für den Fall, dass der Mangel von einem von der Provinzial
          beauftragten Dritten beseitigt wird, behält sich die Provinzial den
          Rückgriff wegen der hierdurch entstandenen Kosten gegen Sie vor.
        </li>
      </ul>
      <p className="legalContent__paragraph">
        10.4 Des Weiteren können sich sowohl Auftraggeber als auch Auftragnehmer
        bei Beanstandungen betreffend das Auftragsverhältnis jederzeit an den
        Kundenservice der Provinzial wenden. Nach Schilderung des konkreten
        Problems wird die Provinzial sich mit den Beteiligten in Verbindung
        setzen und versuchen, das Problem vermittelnd zu lösen.
      </p>
      <h2 className="legalContent__headline2">11. Haftung</h2>
      <p className="legalContent__paragraph">
        11.1 Die Provinzial haftet unbeschränkt für Schäden, die auf Vorsatz
        oder grober Fahrlässigkeit beruhen.
      </p>
      <p className="legalContent__paragraph">
        11.2 Ferner haftet die Provinzial für Schäden, die aufgrund einer
        einfach fahrlässigen Verletzung wesentlicher Vertragspflichten beruhen.
        Wesentliche Vertragspflichten in diesem Sinne sind solche, deren
        Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht
        oder deren Verletzung die Erreichung des Vertragszwecks gefährdet und
        auf deren Einhaltung der Vertragspartner regelmäßig vertraut. In diesen
        Fällen ist die Haftung der Provinzial jedoch auf den vorhersehbaren,
        vertragstypischen Schaden begrenzt.
      </p>
      <p className="legalContent__paragraph">
        Die vorstehenden Haftungsbeschränkungen gelten nicht bei der Verletzung
        von Leben, Körper und Gesundheit, im Falle einer Garantieübernahme und
        eines arglistig verschwiegenen Mangels. Die Haftung nach dem
        Produkthaftungsgesetz bleibt von den hier vorliegenden
        Haftungsbeschränkungen ebenfalls unberührt.
      </p>
      <p className="legalContent__paragraph">
        11.3 Soweit die Haftung der Provinzial ausgeschlossen oder beschränkt
        ist, gilt dies auch für Pflichtverletzungen durch ihre Vertreter oder
        Erfüllungsgehilfen.
      </p>
      <h2 className="legalContent__headline2">12. Laufzeit und Beendigung</h2>
      <p className="legalContent__paragraph">
        12.1 Der Nutzungsvertrag beginnt mit erfolgreich abgeschlossener
        Registrierung und Aktivierung Ihres Nutzerkontos. Er ist auf unbestimmte
        Zeit geschlossen.
      </p>
      <p className="legalContent__paragraph">
        12.2 Der Nutzungsvertrag kann von Ihnen jederzeit ordentlich ohne
        Einhaltung einer Frist gekündigt werden. Die Kündigung kann schriftlich
        an Provinzial Dienstleistungsgesellschaft mbH, Dorotheenstraße 3, 45130
        Essen oder per Mail an{' '}
        <a href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>{' '}
        oder direkt innerhalb Ihres Nutzerkontos erklärt werden.
      </p>
      <p className="legalContent__paragraph">
        12.3 Der Nutzungsvertrag endet des Weiteren automatisch mit Beendigung
        Ihrer Mitgliedschaft im Provinzial-Handwerkernetz der I-NADIS. Sie sind
        daher verpflichtet, uns eine Beendigung Ihrer Mitgliedschaft
        unverzüglich mitzuteilen.
      </p>
      <p className="legalContent__paragraph">
        12.4 Die Provinzial ist berechtigt, den Nutzungsvertrag ordentlich mit
        einer Frist von 30 Tagen zu kündigen.
      </p>
      <div className="legalContent__paragraph">
        12.5 Das Recht für beide Parteien, den Vertrag aus wichtigem Grunde zu
        kündigen, bleibt unberührt. Ein wichtiger Grund liegt insbesondere in
        folgenden Fällen vor:
        <ul className="legalContent__list">
          <li>
            Die von Ihnen erbrachten Leistungen haben in mindestens drei Fällen
            nicht den in diesen AGB vereinbarten Qualitätsstandards entsprochen
            und auch eine vorherige Abmahnung hat keine Abhilfe geschaffen.
          </li>
          <li>
            Sie haben wiederholt gegen die hier vereinbarten AGB verstoßen und
            auch eine vorherige Abmahnung hat keine Abhilfe geschaffen.
          </li>
        </ul>
      </div>
      <p className="legalContent__paragraph">
        12.6 Bitte beachten Sie, dass Sie ab dem Zeitpunkt der Beendigung des
        Nutzungsvertrages keine Dienste mehr über die Plattform in Anspruch
        nehmen können. Bereits beauftragte Handwerker- und Dienstleitungen
        bleiben allerdings unberührt und sind noch zu beenden.
      </p>
      <p className="legalContent__paragraph">
        12.7 Beschließt die Provinzial, den Nutzungsvertrag mit Ihnen zu
        beenden, wird sie Ihnen die diesbezüglichen Gründe 30 Tage vor dem
        Wirksamwerden der Beendigung auf einem dauerhaften Datenträger
        übermitteln. Eine Verpflichtung hierzu besteht jedoch in folgenden
        Fällen nicht:
      </p>
      <ul className="legalContent__list">
        <li>
          Gesetzliche oder behördlich angeordnete Verpflichtungen stehen einer
          Einhaltung der 30-tägigen Frist oder einer Offenlegung der konkreten
          Tatsachen oder Umstände und der Gründe entgegen. In diesem Fall
          erhalten Sie, sobald möglich, unverzüglich eine Begründung.
        </li>
        <li>
          Sie haben nachweislich wiederholt gegen die hier vereinbarten AGB
          verstoßen.
        </li>
      </ul>
      <p className="legalContent__paragraph">
        Sie haben die Möglichkeit, die Tatsachen und Umstände, die zur
        Beendigung geführt haben, im Rahmen des internen Beschwerdemanagements
        der Provinzial überprüfen zu lassen. Im Falle der Aufhebung der
        Beendigung wird Ihnen die Provinzial umgehend wieder den vollen Zugang
        zur Plattform und Ihrem Nutzerkonto verschaffen.
      </p>
      <h2 className="legalContent__headline2">13. Datenschutz</h2>
      <p className="legalContent__paragraph">
        13.1 Wir verarbeiten Ihre personenbezogenen Daten ausschließlich für die
        Bereitstellung und Nutzung unserer Dienste gemäß dem mit Ihnen
        abgeschlossenen Nutzungsvertrag, es sei denn, eine weitergehende
        Datenverarbeitung ist gesetzlich vorgesehen oder Sie haben uns
        ausdrücklich eine weitergehende Einwilligungserklärung erteilt.
      </p>
      <p className="legalContent__paragraph">
        13.2 Die Vermittlung von Handwerker- und Dienstleistungen kann
        insbesondere nur erfolgen, wenn wir Ihre Daten an potenzielle
        entsprechende Auftraggeber weiterleiten.
      </p>
      <p className="legalContent__paragraph">
        13.3 Bei der Verarbeitung Ihrer personenbezogenen Daten beachten wir die
        jeweils geltenden Vorgaben nach der DS-GVO und dem BDSG. Details zur
        Verarbeitung entnehmen Sie bitte der gesonderten Datenschutzhinweise,
        die Sie{' '}
        <a href="https://meinzuhauseundich.de/datenschutzerklaerung">hier</a>
        &nbsp; abrufen können.
      </p>
      <h2 className="legalContent__headline2">
        14. Änderung der Nutzungsbedingungen
      </h2>
      <p className="legalContent__paragraph">
        14.1 Die Provinzial ist berechtigt, diese AGB jederzeit mit Wirkung für
        die Zukunft unter Einhaltung der nachfolgenden Bedingungen zu ändern.
      </p>
      <p className="legalContent__paragraph">
        14.2 Der Änderungsvorbehalt gilt nur, wenn Ihnen die Änderung unter
        Berücksichtigung der Interessen der Provinzial zumutbar ist. Die
        Provinzial wird in jedem Falle sicherstellen, dass bereits laufende
        Ausschreibungen zu den bisherigen Nutzungsbedingungen durchgeführt
        werden.
      </p>
      <p className="legalContent__paragraph">
        14.3 Änderungen wird ihnen die Provinzial spätestens{' '}
        <span className="legalContent__new">2 Monate</span> vor dem Zeitpunkt
        des Eintritts der Änderung per E-Mail mitteilen. Für den Fall, dass
        durch die Änderung technische oder geschäftliche Anpassung bei Ihnen
        erforderlich werden, erfolgt die Mitteilung mit entsprechend längerer
        angemessener und verhältnismäßiger Frist, um Ihnen die Anpassung zu
        ermöglichen.
      </p>
      <p className="legalContent__paragraph">
        14.4 Sie sind berechtigt, der geplanten Änderung bis zum Zeitpunkt des
        Eintritts der Änderung zu widersprechen. Die Provinzial wird Sie in der
        Mitteilung auf dieses Widerspruchsrecht hinweisen.
      </p>
      <p className="legalContent__paragraph">
        Widersprechen Sie nicht oder stimmen Sie der Änderung ausdrücklich zu,
        werden die Änderungen zum mitgeteilten Zeitpunkt wirksam. Das Einstellen
        neuer Angebote über die Plattform ist bei Geltung der regulären{' '}
        <span className="legalContent__new">2-Monats-Frist</span> als Zustimmung
        zu betrachten. Im Falle Ihres fristgemäßen Widerspruchs ist die
        Provinzial berechtigt, den Nutzungsvertrag zu kündigen.
      </p>
      <h2 className="legalContent__headline2">15. Schlussbestimmungen</h2>
      <p className="legalContent__paragraph">
        15.1 Es gilt das Recht der Bundesrepublik Deutschland.
      </p>
      <p className="legalContent__paragraph">
        15.2 Sollte eine Bestimmung dieses Vertrages und/oder dieser Bedingungen
        unwirksam oder unvollständig sein, bleiben die übrigen Bestimmungen
        hiervon unberührt. An Stelle der unwirksamen oder unvollständigen
        Bestimmung werden die Parteieneine solche vereinbaren, die beide Seiten
        bei Kenntnis der Unwirksamkeit oder Unvollständigkeit wirtschaftlich
        vereinbart hätte.
      </p>
      <p className="legalContent__paragraph">
        15.3 Sofern Sie ein in Deutschland ansässiger Kaufmann im Sinne des
        Handelsgesetzbuches sind, wird Essen als Gerichtsstand für alle
        Streitigkeiten im Zusammenhang mit dem Nutzungsvertrag zur Plattform
        „MeinZuhause“ vereinbart.
      </p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a name="widerrufsbelehrung" className="legalContent__anchorLink">
        <h1 className="legalContent__headline1 legalContent__headline1--inline">
          Widerrufsbelehrung
        </h1>
      </a>
      <h2 className="legalContent__headline2">Widerrufsrecht</h2>
      <p className="legalContent__paragraph">
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen.
      </p>
      <p className="legalContent__paragraph">
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
        Vertragsabschlusses.
      </p>
      <p className="legalContent__paragraph">
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, Provinzial
        Dienstleistungsgesellschaft mbH, Dorotheenstraße 3, 45130 Essen,
        Telefon: 0201 – 8125 – 400 Fax: 0201 – 8125 – 210,{' '}
        <a href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>
        , mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
        Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </p>
      <p className="legalContent__paragraph">
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
      </p>
      <br />
      <h2 className="legalContent__headline2">Folgen des Widerrufs</h2>
      <p className="legalContent__paragraph">
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
      </p>
      <p className="legalContent__paragraph">
        Haben Sie verlangt, dass die Dienstleistung während der Widerrufsfrist
        beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der
        dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits
        erbrachten Dienstleistung im Vergleich zum Gesamtumfang der im Vertrag
        vorgesehenen Dienstleistung entspricht.
      </p>
      <br />
      <p className="legalContent__paragraph">
        Hier das Muster zum{' '}
        <a
          href={`/Muster_Widerrufsformular_Registrierung_Plattform.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          schriftlichen Widerruf
        </a>
        .
      </p>
    </>
  );
}
