import React from 'react';
import { toDateString } from '../../dateTime';

const Ausfuehrungszeitraum = ({ spezifikation }) => {
  return (
    <span>
      {spezifikation.ausfuehrungszeitraum !== 'Zeitraum wählen'
        ? spezifikation.ausfuehrungszeitraum
        : `${toDateString(
            spezifikation.speziellerAusfuehrungszeitraum.von
          )} bis ${toDateString(
            spezifikation.speziellerAusfuehrungszeitraum.bis
          )}`}
    </span>
  );
};

export default Ausfuehrungszeitraum;
