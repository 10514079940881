import React, { useEffect, useRef } from 'react';
import Nachricht from '../Nachricht/Nachricht';
import { useMarkiereAnfrageAlsGesehen } from '../../hooks';
import { toStartOfDayString, toTodayOrCustomFormat } from '../../dateTime';
import './Nachrichten.scss';
import Event from '../Event/Event';
import { byCreatedAtAsc } from '../../sort';
import { useAnfrageUndEventContext } from '../../contexts/AnfrageUndEventContext';
import { useBenutzerContext } from '../../contexts/BenutzerContext';

function scrollToBottom(ref, immediately = false) {
  if (ref.current && !ref.current.scrollTo) {
    ref.current.scrollTop = ref.current.scrollHeight;
  } else if (ref.current && ref.current.scrollTo) {
    ref.current.scrollTo({
      top: ref.current.scrollHeight,
      behavior: immediately ? 'auto' : 'smooth',
    });
  }
}

function ScrollWrapper({ children, dependsOn, ...props }) {
  const firstUpdate = useRef(true);
  const ref = useRef(null);

  useEffect(() => {
    if (!('ResizeObserver' in window)) {
      return;
    }

    let didCancel = false;
    setTimeout(() => {
      if (!didCancel) {
        scrollToBottom(ref, firstUpdate.current);

        if (firstUpdate.current) {
          firstUpdate.current = false;
        }
      }
    }, 280);

    const observer = new window.ResizeObserver(() => scrollToBottom(ref, true));
    observer.observe(ref.current);

    return () => {
      didCancel = true;
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...dependsOn]);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
}

function useSideOf() {
  const { istHandwerker } = useBenutzerContext();
  return (nachricht) => {
    return nachricht.autor === 'SYSTEM'
      ? 'middle'
      : istHandwerker
      ? nachricht.autor === 'HANDWERKER'
        ? 'right'
        : 'left'
      : nachricht.autor === 'KUNDE'
      ? 'right'
      : 'left';
  };
}

function groupByStartOfDay(nachrichtenUndEvents) {
  const addToMap = (map, key, value) => {
    if (!map.has(key)) {
      map.set(key, [value]);
    } else {
      map.get(key).push(value);
    }
    return map;
  };
  const groupIntoMap = (groups, nachrichtOderEvent) =>
    addToMap(
      groups,
      toStartOfDayString(nachrichtOderEvent.createdAt),
      nachrichtOderEvent
    );
  const nachrichtenUndEventsByDate = nachrichtenUndEvents.reduce(
    groupIntoMap,
    new Map()
  );
  return {
    nachrichtenUndEventsByDate,
    dates: [...nachrichtenUndEventsByDate.keys()],
  };
}

function Datumszeile({ date }) {
  return (
    <h1 className="datumszeile">
      <span className="datumszeile__datum">
        {toTodayOrCustomFormat(date, 'dddd, DD.MM.YYYY')}
      </span>
    </h1>
  );
}

function NachrichtenUndEvents({
  nachrichtenUndEvents,
  anfrage,
  willkommenshinweis,
  autor,
}) {
  const sideOf = useSideOf();
  const { dates, nachrichtenUndEventsByDate } = groupByStartOfDay(
    nachrichtenUndEvents
  );

  const gesehen = useMarkiereAnfrageAlsGesehen(
    anfrage,
    autor,
    nachrichtenUndEvents
  );

  return (
    <ScrollWrapper
      dependsOn={[anfrage, nachrichtenUndEvents.length]}
      className="nachrichten"
      data-cy-anfrage-gesehen={gesehen}
    >
      <p className="nachrichten__willkommenshinweis">{willkommenshinweis}</p>
      {anfrage.wurdeGesendet() && (
        <p className="nachrichten__chathinweis">
          Sie können erst Nachrichten schreiben, sobald die Anfrage vom
          Fachpartner angenommen wurde.
        </p>
      )}
      {dates.reduce(
        (components, startOfDay) =>
          components.concat(
            <Datumszeile key={startOfDay} date={startOfDay} />,
            nachrichtenUndEventsByDate
              .get(startOfDay)
              .map((nachrichtOderEvent) =>
                nachrichtOderEvent.nachricht ? (
                  <Nachricht
                    key={nachrichtOderEvent.nachricht.id}
                    nachricht={nachrichtOderEvent.nachricht}
                    side={sideOf(nachrichtOderEvent.nachricht)}
                    anfrage={anfrage}
                    autor={autor}
                  />
                ) : (
                  <Event
                    key={nachrichtOderEvent.event.id}
                    event={nachrichtOderEvent.event}
                    anfrage={anfrage}
                  />
                )
              )
          ),
        []
      )}
    </ScrollWrapper>
  );
}

const Nachrichten = ({ anfrage, willkommenshinweis, autor }) => {
  const nachrichten = anfrage.nachrichten;
  const { events } = useAnfrageUndEventContext();
  const eventsZuAnfrage = events.filter(
    (event) => event.anfrageId === anfrage.id
  );

  const nachrichtenMitCreatedAt = [...nachrichten].map((nachricht) => ({
    nachricht,
    createdAt: nachricht.createdAt,
  }));

  const eventsMitCreatedAt = eventsZuAnfrage.map((event) => ({
    event,
    createdAt: event.createdAt,
  }));

  const nachrichtenUndEvents = [
    ...nachrichtenMitCreatedAt,
    ...eventsMitCreatedAt,
  ].sort(byCreatedAtAsc);

  return (
    <NachrichtenUndEvents
      nachrichtenUndEvents={nachrichtenUndEvents}
      anfrage={anfrage}
      willkommenshinweis={willkommenshinweis}
      autor={autor}
    />
  );
};

export default Nachrichten;
