import React from 'react';

export default function HandwerkerText() {
  return (
    <>
      <p className="legalContent__paragraph">
        Wir freuen uns über Ihren Besuch auf unserer Internetseite und Ihrem
        Interesse an unserem Unternehmen. Der Schutz Ihrer personenbezogenen
        Daten ist uns ein wichtiges Anliegen. Nachstehend informieren wir Sie
        gemäß Art. 12, 13 und 21 der Datenschutzgrundverordnung (DSGVO) über den
        Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer
        Website.
      </p>
      <p className="legalContent__paragraph">
        Personenbezogene Daten sind Einzelangaben über persönliche oder
        sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen
        Person. Darunter fallen Informationen wie der bürgerliche Name, die
        Anschrift, die Telefonnummer und das Geburtsdatum.
      </p>
      <h2 className="legalContent__headline2">
        1. Verantwortlicher für die Datenverarbeitung
      </h2>
      <p className="legalContent__paragraph">
        Provinzial Dienstleistungsgesellschaft mbH, Dorotheenstraße 3, 45130
        Essen,
      </p>
      <p className="legalContent__paragraph">Telefon: 0201 8125 200</p>
      <p className="legalContent__paragraph">
        Die <strong>Datenschutzbeauftragte </strong>des Verantwortlichen ist:
        Linda Berndt, ALBAG GmbH, Dorotheenstr. 3, 45130 Essen, Deutschland,
        E-Mail:&nbsp;
        <u>
          <a href="mailto:datenschutz@albag.de">datenschutz@albag.de</a>
        </u>
      </p>
      <h2 className="legalContent__headline2">
        2. Quellen und Arten der genutzten Daten
      </h2>
      <p className="legalContent__paragraph">
        Wir verarbeiten personenbezogene Daten, die wir im Rahmen Ihrer
        Registrierung für die Dienstleistungsplattform von Ihnen oder im Rahmen
        Ihrer Einwilligung von der I-NADIS Gesellschaft für Instandhaltungs- und
        Naturalersatzdisposition mbH (kurz: I-NADIS) erhalten haben. Diese Daten
        speichern wir in einem Anbieterprofil. Hierzu gehören vor allem Ihre
        Stamm-, Adress- und Kontaktdaten, die von Ihnen angebotenen Gewerke bzw.
        Tätigkeiten sowie die Region, in der Sie diese anbieten, die Dauer der
        Partnerschaft zur I-NADIS sowie eine mögliche Beendigung der
        Partnerschaft. Weiterhin verarbeiten wir weitere personenbezogene Daten
        in Ihrem Anbieterprofil, die Sie individuell ergänzen (z.B. Zertifikate,
        Meisterbrief).
      </p>
      <p className="legalContent__paragraph">
        Schließlich werden Angaben über Art und Inhalt der Anfragen oder
        Aufträge gespeichert, an denen Sie als Anbieter teilgenommen haben. Hier
        verarbeiten wir die mit der Anfrage oder dem Auftrag verbundene
        Kommunikation sowie ausgetauschte Dokumente (z.B. Angebote, Rechnung),
        möglicherweise geltend gemachte Mängel sowie die durch den Kunden
        erfolgte Bewertung des Auftrages. Ferner können wir
        Zahlungsinformationen für die Entrichtung möglicherer Nutzungsgebühren
        speichern.
      </p>
      <h2 className="legalContent__headline2">
        3. Zwecke und Rechtsgrundlagen der Datenverarbeitung
      </h2>
      <p className="legalContent__paragraph">
        Wir verarbeiten Ihre Daten unter Beachtung der
        EU-Datenschutz-Grundverordnung (DS-GVO) und des
        Bundesdatenschutzgesetzes (BDSG).
      </p>
      <h3 className="legalContent__headline3">
        a) Informatorische Nutzung der Website
      </h3>
      <p className="legalContent__paragraph">
        Sie können unsere Website besuchen, ohne Angaben zu Ihrer Person zu
        machen. Wenn Sie unsere Website lediglich informatorisch nutzen (also
        sich nicht anmelden, registrieren oder einen Vertrag abschließen) oder
        uns sonst keine Informationen zu Ihrer Person übermitteln, verarbeiten
        wir keine personenbezogenen Daten, mit Ausnahme der Daten, die Ihr
        Browser übermittelt, um Ihnen den Besuch der Webseite zu ermöglichen
        sowie Informationen, die uns im Rahmen von eingesetzten Cookies zur
        statistischen Analyse der Nutzung unser Website übermittelt werden.
      </p>
      <h4 className="legalContent__headline4">
        i. Technische Bereitstellung der Website
      </h4>
      <p className="legalContent__paragraph">
        Zum Zweck der technischen Bereitstellung der Website ist es
        erforderlich, dass wir bestimmte, automatisch übermittelte Informationen
        von Ihnen verarbeiten, damit Ihr Browser unsere Website anzeigen kann
        und Sie die Website nutzen können. Diese Informationen werden bei jedem
        Aufruf unserer Internetseite automatisch erfasst und in unseren
        Serverlogfiles gespeichert.
      </p>
      <p className="legalContent__paragraph">
        Ferner setzen wir Cookies ein, um Ihnen unsere Webseite zur Nutzung zur
        Verfügung zu stellen. Bei Cookies handelt es sich um Textdateien, die im
        Internetbrowser bzw. vom Internetbrowser bei dem Aufruf einer Website
        auf Ihrem Computersystem gespeichert werden. Ein Cookie enthält eine
        charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
        Browsers beim erneuten Aufrufen der Website ermöglicht.
      </p>
      <p className="legalContent__paragraph">
        Wir speichern Ihre personenbezogenen Daten auch im sogenannten Local
        Storage (auch „Lokale Daten“ und „Lokaler Speicher“ genannt). Dabei
        werden Daten lokal im Cache Ihres Browsers gespeichert, die auch nach
        dem Schließen des Browser-Fensters oder dem Beenden des Programms –
        soweit Sie den Cache nicht löschen - weiterhin bestehen und ausgelesen
        werden können. Dort werden die Benutzerdaten aller jemals in diesem
        Browser angemeldeten Benutzer, sowie die Sitzung des gerade angemeldeten
        Benutzers gespeichert. Die Benutzerdaten werden dabei nicht an unsere
        Server übertragen, lediglich die Sitzung wird an den Server übertragen.
      </p>
      <h5 className="legalContent__headline5">Usercentrics Consent Manager</h5>
      <p className="legalContent__paragraph">
        Auf unserer Website benutzen wir den Consent Manager von Usercentrics
        GmbH, Rosental 4, 80331 München, Deutschland, Email:
        contact@usercentrics.com. Der Consent Manager von Usercentrics ist eine
        Lösung, mit der wir Ihre Einwilligung zu bestimmten,
        einwilligungsbedürftigen Datenverarbeitungen (z. B. Tracking o. Ä.)
        einholen. Es informiert Sie über die einzelnen von uns eingesetzten
        Cookies und Tools und lässt Ihnen die Wahl, welche davon Sie individuell
        oder kategorisch zulassen oder ablehnen. Ebenfalls werden Sie darüber
        informiert, wann Sie welche Cookies/Tools zugelassen oder abgelehnt
        haben. Dies ermöglicht Ihnen eine informierte Entscheidung über die
        Weitergabe Ihrer Daten und uns einen datenschutzkonformen, transparenten
        und dokumentierten Einsatz von Cookies und Tools.
      </p>
      <p className="legalContent__paragraph">
        Der Consent Manager speichert selbst keine personenbezogenen Daten von
        Ihnen. Es werden lediglich anonymisierte Daten generiert, die in einem
        Cookie gespeichert werden. Diese Daten werden ausschließlich in der
        europäischen Union verarbeitet. Bei diesen Daten handelt es sich um
        folgende Informationen:
      </p>
      <ul className="legalContent__list">
        <li>Datum und Uhrzeit Ihres Besuchs</li>
        <li>Informationen über das genutzte Gerät</li>
        <li>Informationen über den Browser, z. B. Browsertyp/ -version</li>
        <li>Ihre anonymisierte IP-Adresse</li>
        <li>Ihre Einwilligung oder Ablehnung von Cookies</li>
      </ul>
      <p className="legalContent__paragraph">
        Weiterführende Informationen sowie die Datenschutzerklärung von
        Usercentrics finden Sie unter:{' '}
        <a
          href="https://usercentrics.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://usercentrics.com/privacy-policy/
        </a>
      </p>
      <p className="legalContent__paragraph">
        Ihre Informationen, die wir durch vorstehende Cookies gesammelt haben,
        werden von uns nicht verwendet, um Nutzerprofile zu erstellen oder Ihr
        Surfverhalten auszuwerten.{' '}
      </p>
      <p className="legalContent__paragraph">
        Wir verarbeiten Ihre personenbezogenen Daten zur technischen
        Bereitstellung unserer Website auf Grundlage der folgenden
        Rechtsgrundlagen:
      </p>
      <ul className="legalContent__list">
        <li>
          zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher
          Maßnahmen gemäß Art. 6 Abs. 1 lit. b) DSGVO, soweit Sie unsere Website
          besuchen, um sich über unsere Produkte und unsere Veranstaltungen zu
          informieren;
        </li>
        <li>
          zur Erfüllung einer rechtlichen Verpflichtung, der wir als
          Verantwortlicher unterliegen gem. Art. 6 Abs. 1 lit. c) DSGVO. Die
          rechtliche Verpflichtung liegt in der Einholung und Dokumentation
          Ihrer Einwilligung zur Datenverarbeitung.
        </li>
        <li>
          zur Wahrung unserer berechtigten Interessen gemäß
          Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse besteht
          dabei darin, Ihnen eine ansprechende, technisch funktionierende und
          benutzerfreundliche Website zur Verfügung stellen zu können sowie um
          Maßnahmen zum Schutz unserer Website vor Cyberrisiken zu ergreifen und
          zu verhindern, dass von unserer Website Cyberrisiken für Dritte
          ausgehen.
        </li>
      </ul>
      <h4 className="legalContent__headline4">
        ii. Statistische Analyse der Nutzung der Website und Reichweitenerhöhung
      </h4>
      <p className="legalContent__paragraph">
        Zum Zweck der statistischen Analyse der Nutzung unserer Website setzen
        wir Google Analytics und damit Cookies ein, die eine Analyse Ihres
        Surfverhaltens ermöglichen. Dadurch können wir die Qualität unserer
        Website und ihrer Inhalte verbessern. Wir erfahren, wie die Website
        genutzt wird und können so unser Angebot stetig optimieren.
      </p>
      <p className="legalContent__paragraph">
        Die im Rahmen der statistischen Analyse unserer Website erlangten
        Informationen werden nicht mit Ihren sonstigen, im Rahmen der Website
        erfassten Daten zusammengeführt.
      </p>
      <h5 className="legalContent__headline5">Google Tag Manager</h5>
      <p className="legalContent__paragraph">
        Auf unserer Webseite benutzen wir den Google Tag Manager. Der Google Tag
        Manager ist eine Lösung, mit der Vermarkter Webseiten-Tags über eine
        Oberfläche verwalten können. Der Dienst Google Tag Manager selbst (der
        die Tags implementiert) ist eine Cookie-lose Domain und erfasst keine
        personenbezogenen Daten. Der Dienst Google Tag Manager sorgt für die
        Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen.
        Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain-
        oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
        alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert
        werden.
      </p>
      <h5 className="legalContent__headline5">Google Analytics</h5>
      <p className="legalContent__paragraph">
        Auf unserer Website benutzen wir Google Analytics, einen
        Webanalysedienst der Google Inc. (Google Ireland Limited, Gordon House,
        Barrow Street, Dublin 4, Ireland; „Google“). Google Analytics verwendet
        sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
        und die eine Analyse darüber ermöglichen, wie Sie die Website nutzen.
        Die durch das Cookie erzeugten Informationen über Ihre Benutzung unserer
        Website werden in der Regel an einen Server von Google in den USA
        übertragen und dort gespeichert. Auf unserer Website verwenden wir
        Google Analytics mit der Erweiterung „_anonymizeIP()“. Dadurch wird Ihre
        IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen
        Union oder in anderen Vertragsstaaten des Abkommens über den
        Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
        die volle IP-Adresse an einen Server von Google in den USA übertragen
        und dort gekürzt. Eine direkte Personenbeziehbarkeit kann damit
        ausgeschlossen werden. In unserem Auf-trag wird Google diese
        Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
        Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. 
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Google Analytics ausschließlich mit Ihrer Einwilligung ein.
        Sie können eine einmal erteilte Einwilligung jederzeit für die Zukunft
        widerrufen, indem Sie
      </p>
      <ul className="legalContent__list">
        <li>
          die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unser
          Website vollumfänglich werden nutzen können;
        </li>
        <li>
          das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
          und installieren:
          <a
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>{' '}
          oder über diesen{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="legalContent__cookieEinstellungen"
            target="_self"
            title="Cookie-Einstellungen"
          >
            Cookie-Einstellungen
          </a>{' '}
          den Consent Manager erneut aufrufen und unter der jeweiligen Kategorie
          das von Ihnen zur Einwilligung gesetzte Häkchen wieder entfernen und
          anschließend die Einstellung bestätigen.
        </li>
      </ul>

      <p className="legalContent__paragraph">
        Die in Google Analytics erhobenen Daten werden 14 Monate gespeichert und
        nach diesem Zeitraum gelöscht, sofern Sie in diesem Zeitraum die
        Webseite nicht erneut besucht haben. Sollten Sie die Webseite innerhalb
        von 14 Monaten erneut besuchen, beginnt die Löschfrist ab dem Zeitpunkt
        Ihres letzten Besuches.
      </p>
      <p className="legalContent__paragraph">
        Nähere Informationen zu Nutzungsbedingungen und Datenschutz von bzw. bei
        Google Analytics finden Sie unter{' '}
        <a
          href="http://www.google.com/analytics/terms/de.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.com/analytics/terms/de.html
        </a>{' '}
        bzw. unter{' '}
        <a
          href="https://www.google.de/intl/de/policies/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.de/intl/de/policies/
        </a>
      </p>
      <h5 className="legalContent__headline5">Google Ads Remarketing</h5>
      <p className="legalContent__paragraph">
        Wir nutzen die Remarketing Technologie von Google. Durch diese
        Technologie werden Nutzer, die die unsere Internetseiten und
        Onlinedienste bereits besucht und sich für das Angebot interessiert
        haben, durch zielgerichtete Werbung auf den Seiten des Google Partner
        Netzwerks erneut angesprochen. Die Einblendung der Werbung erfolgt durch
        den Einsatz von Cookies. Mit Hilfe dieser Cookies kann das
        Nutzerverhalten beim Besuch der Website analysiert und anschließend für
        gezielte Produktempfehlungen und interessenbasierte Werbung genutzt
        werden. Wenn Sie keine interessenbasierte Werbung erhalten möchten,
        können Sie die Verwendung von Cookies durch Google für diese Zwecke
        deaktivieren, indem sie diesen{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="legalContent__cookieEinstellungen"
          target="_self"
          title="Cookie-Einstellungen"
        >
          Link
        </a>{' '}
        aufrufen.
      </p>
      <h5 className="legalContent__headline5">Google Optimize</h5>
      <p className="legalContent__paragraph">
        Als Bestandteil von Google Analytics nutzen wir zur Optimierung unserer
        Website „Google Optimize“, ebenfalls einen Dienst der Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Ireland. Wir setzen
        diesen Dienst ein, um das individuelle Nutzererlebnis auf unserer
        Website zu verbessern. Dazu nutzen wir die durch Google Analytics
        gesetzten Cookies und erhobenen Informationen (s.o.), um verschiedenen
        Nutzergruppen individuelle zum Nutzerverhalten passende Funktionen und
        Ansichten der Seite zur Verfügung zu stellen . Darüber hinaus stellen
        wir im Zuge von A/B-Testings unterschiedliche Ausprägungen der Website
        nicht vorher definierten Nutzergruppen bereit, um damit das
        Onlineangebot stetig zu verbessern.
      </p>
      <p className="legalContent__paragraph">
        Wir setzen Google Optimize ausschließlich ein, wenn Sie Ihre
        Einwilligung zu Google Analytics (s.o.) erteilt haben. Dementsprechend
        können Sie durch einen Widerruf der Einwilligung in Google Analytics
        auch die Einwilligung in Google Optimize widerrufen.
      </p>
      <h3 className="legalContent__headline3">b) Aktive Nutzung der Website</h3>
      <p className="legalContent__paragraph">
        Neben der rein informatorischen Nutzung unserer Website können Sie
        unsere Website auch aktiv nutzen. Auch bei der aktiven Nutzung kommen
        die oben in Ziffer a) i) und ii) dargestellten
        Datenverarbeitungsprozesse und Analysetools zum Einsatz.{' '}
      </p>
      <p className="legalContent__paragraph">
        Der wesentliche Teil unserer Angebote ist für Sie nur nutzbar, wenn Sie
        sich bei uns registrieren. Dann können Sie Dienstleistungen bzw. die
        Vermittlung von Dienstleistern (z.B. Fachpartner) in Anspruch nehmen.
        Wenn Sie die Vermittlung eines Dienstleisters wünschen, geben wir Ihre
        Kontaktdaten und den Inhalt Ihrer Anfrage an passende Dienstleister
        weiter, damit diese Rückfragen zu Ihrer Anfrage stellen können oder
        Ihnen ein Angebot unterbreiten können. Wir verwenden die von ihnen
        mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur
        Vertragsabwicklung. Ohne die Verarbeitung Ihrer personenbezogenen Daten
        ist der Abschluss bzw. die Durchführung des Vertrages nicht möglich.
      </p>
      <p className="legalContent__paragraph">
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten Ihre
        Einwilligung einholen, dient uns Art. 6 Abs. 1 lit. a
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Dies betrifft
        insbesondere die Nutzung Ihrer Kontaktdaten sowie Auftragsdaten und
        Abwicklungsdaten zu Dienstleistungen (z.B. Handwerksleistungen) durch
        die Provinzial Rheinland und deren Vermittler für personalisierte
        Werbezwecke per Brief, Telefon, E-Mail oder sozialen Medien sowie deren
        Nutzung zum Zwecke der umfassenden Betreuung und Beratung innerhalb der
        Kundenbeziehung, der Bewertung der Versicherungsvertragsbeziehung sowie
        zum Zwecke der Produktentwicklung und statistischer Erhebungen.{' '}
      </p>
      <p className="legalContent__paragraph">
        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer
        rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
        unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage. Eine
        solche rechtliche Verpflichtung kann sich bspw. aus handels- und
        steuerrechtlichen Vorgaben ergeben.{' '}
      </p>
      <p className="legalContent__paragraph">
        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
        Unternehmens oder eines Dritten erforderlich und überwiegen die
        Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
        erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als
        Rechtsgrundlage für die Verarbeitung. Dies kann insbesondere
        erforderlich sein:{' '}
      </p>
      <ul className="legalContent__list">
        <li>zur Geltendmachung rechtlicher Ansprüche</li>
        <li>zur Gewährleistung der IT-Sicherheit und des IT-Betriebs</li>
        <li>für statistische Zwecke</li>
      </ul>
      <p className="legalContent__paragraph">
        <strong>
          Sollten wir Ihre personenbezogenen Daten für einen oben nicht
          genannten Zweck verarbeiten wollen, werden wir Sie im Rahmen der
          gesetzlichen Bestimmungen hierüber zuvor informieren.
        </strong>
      </p>
      <h2 className="legalContent__headline2">4. Links</h2>
      <p className="legalContent__paragraph">
        Einige Abschnitte unserer Webseiten beinhalten Links zu den Webseiten
        von Drittanbietern. Diese Webseiten unterliegen eigenen
        Datenschutzgrundsätzen. Wir sind für deren Betrieb einschließlich der
        Datenhandhabung nicht verantwortlich. Wenn Sie Informationen an oder
        über solche Seiten von Drittanbietern senden, sollten Sie die
        Datenschutzerklärungen dieser Seiten prüfen, bevor Sie Ihnen
        Informationen zukommen lassen, die Ihrer Person zugeordnet werden
        können.
      </p>
      <h2 className="legalContent__headline2">Verwendung von Kartendiensten</h2>
      <h2 className="legalContent__headline2">HERE Maps</h2>
      <p className="legalContent__paragraph">
        Wir nutzen auf unserer Website den Kartendienst HERE. Anbieter ist HERE
        Global B.V. , Kennedyplein 222-226, 5611 ZT Eindhoven, Niederlande.
      </p>
      <p className="legalContent__paragraph">
        Bei der Anzeige von Kartenmaterial des Kartendienstes HERE auf der
        Webseite wird eine Verbindung mit dem Server von HERE hergestellt, um
        Sie als Fachpartner für Kunden anzeigen zu können. Dabei werden Ihre
        Adresse zwecks Anzeige des Kartenmaterials an HERE übertragen und dort
        gespeichert. Wir als Webseitenbetreiber haben darauf keinen Einfluss.
      </p>
      <p className="legalContent__paragraph">
        Die Datenverarbeitung erfolgt auf der Rechtsgrundlage des Art. 6 Abs .1
        lit b) DSGVO. Sie dient der Durchführung vorvertraglicher Maßnahmen, die
        auf Suchanfragen des Kunden hin durch die Nutzung der Plattform
        erfolgen.
      </p>
      <p className="legalContent__paragraph">
        Mehr Informationen zum Umgang mit Ihren Nutzerdaten finden Sie in der
        Datenschutzerklärung von HERE:{' '}
        <a
          href="https://legal.here.com/de-de/privacy/policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://legal.here.com/de-de/privacy/policy
        </a>
        .
      </p>
      <h2 className="legalContent__headline2">
        5. Kategorien von Empfängern personenbezogener Daten
      </h2>
      <p className="legalContent__paragraph">
        Wir leiten Ihre Daten an Dritte nur weiter, wenn dies zur Erfüllung
        unserer vertraglichen Pflichten erforderlich ist, Sie in die
        Datenweitergabe eingewilligt haben oder wenn gesetzliche Bestimmungen
        dies gebieten.
      </p>
      <p className="legalContent__paragraph">
        Wenn Sie mit uns einen Vertrag schließen, bedienen wir uns zur Erfüllung
        unserer vertraglichen und gesetzlichen Pflichten ggs. externer
        Dienstleister, an die wir Ihre personenbezogenen Daten in diesem Falle
        zur Auftragserfüllung weiterleiten.
      </p>
      <h5 className="legalContent__headline5">
        Datenverarbeitung durch externe Dienstleister:
      </h5>
      <p className="legalContent__paragraph">
        Die Unternehmen unserer Unternehmensgruppe ALBAG GmbH, BAGUS GmbH,
        E-REPA GmbH und I-NADIS GmbH verarbeiten Ihre personenbezogenen Daten
        zur Erfüllung unserer vertraglichen oder gesetzlichen Pflichten im
        Rahmen bestehender Auftragsverarbeitungsverträge. Auch andere von uns
        eingesetzte Auftragsverarbeiter (Art. 28 DS-GVO) können Ihre
        personenbezogenen Daten zur Erfüllung unserer vertraglichen oder
        gesetzlichen Pflichten erhalten.
      </p>
      <p className="legalContent__paragraph">
        Sofern Sie hierin eingewilligt haben, werden durch Sie veranlasste
        Änderungen Ihrer Daten über eine Schnittstelle auf elektronischem Weg an
        die I-NADIS GmbH zum Zwecke der Gewährleistung der Aktualität der Daten
        in der Unternehmensgruppe übermittelt.
      </p>
      <h5 className="legalContent__headline5">Weitere Empfänger:</h5>
      <p className="legalContent__paragraph">
        Darüber hinaus können wir Ihre personenbezogenen Daten an weitere
        Empfänger übermitteln, wie etwa an Behörden zur Erfüllung gesetzlicher
        Mitteilungspﬂichten (z. B. Sozialversicherungsträger, Finanzbehörden
        oder Strafverfolgungsbehörden).
      </p>
      <p className="legalContent__paragraph">
        Ferner behalten wir uns vor, Ihre Firmen- und Adressdaten in Form einer
        Liste der an der Dienstleistungsplattform teilnehmenden Fachpartner und
        Dienstleister der Provinzial Rheinland sowie deren Geschäftsstellen zur
        Verfügung zu stellen.
      </p>
      <p className="legalContent__paragraph">
        Eine Datenübermittlung in Drittstaaten (Staaten außerhalb des
        Europäischen Wirtschaftsraums) findet nicht statt.
      </p>
      <h2 className="legalContent__headline2">
        6. Automatisierte Einzelfallentscheidungen
      </h2>
      <p className="legalContent__paragraph">
        Zur Begründung und Durchführung der Geschäftsbeziehung nutzen wir
        grundsätzlich keine automatisierte Entscheidungsfindung gemäß Art. 22
        DS-GVO.
      </p>
      <h2 className="legalContent__headline2">7. Dauer der Datenspeicherung</h2>
      <h3 className="legalContent__headline3">
        a) Informatorische Nutzung der Website
      </h3>
      <p className="legalContent__paragraph">
        Bei der rein informatorischen Nutzung unserer Website speichern wir Ihre
        personenbezogenen Daten auf unseren Servern ausschließlich für die Dauer
        des Besuchs unserer Website. Nachdem Sie unsere Website verlassen haben,
        werden Ihre personenbezogenen Daten unverzüglich gelöscht.
      </p>
      <p className="legalContent__paragraph">
        Von uns installierte Cookies werden in der Regel ebenfalls nach dem
        Verlassen unserer Website gelöscht. Zudem haben Sie jederzeit die
        Möglichkeit, installierte Cookies selbst zu löschen.
      </p>
      <p className="legalContent__paragraph">
        Der Consent-Manager von Usercentrics bewahrt einen Nachweis über den
        Widerruf einer zuvor erteilten Einwilligung für einen Zeitraum von drei
        Jahren auf.
      </p>
      <h3 className="legalContent__headline3">b) Aktive Nutzung der Website</h3>
      <p className="legalContent__paragraph">
        Wir löschen Ihre personenbezogenen Daten sobald sie für die oben
        genannten Zwecke nicht mehr erforderlich sind. Dabei kann es vorkommen,
        dass personenbezogene Daten für die Zeit aufbewahrt werden, in der
        Ansprüche gegen unser Unternehmen geltend gemacht werden können
        (gesetzliche Verjährungsfrist von drei oder bis zu dreißig Jahren).
        Zudem speichern wir Ihre personenbezogenen Daten, soweit wir dazu
        gesetzlich verpﬂichtet sind. Entsprechende Nachweis- und
        Aufbewahrungspﬂichten ergeben sich unter anderem aus dem
        Handelsgesetzbuch, der Abgabenordnung und dem Geldwäschegesetz. Die
        Speicherfristen betragen danach bis zu zehn Jahren.
      </p>
      <h2 className="legalContent__headline2">8. Betroffenenrechte </h2>
      <p className="legalContent__paragraph">
        Sie können unter der o. g. Adresse Auskunft über die zu Ihrer Person
        gespeicherten Daten verlangen. Darüber hinaus können Sie unter
        bestimmten Voraussetzungen die Berichtigung oder die Löschung Ihrer
        Daten verlangen. Ihnen kann weiterhin ein Recht auf Einschränkung der
        Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von Ihnen
        bereitgestellten Daten in einem strukturierten, gängigen und
        maschinenlesbaren Format zustehen.
      </p>
      <div className="legalContent__box">
        <h2 className="legalContent__headline2">Widerspruchsrecht </h2>
        <p className="legalContent__paragraph">
          <strong>
            Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen
            Daten zu Zwecken der Direktwerbung zu widersprechen.
          </strong>
        </p>
        <p className="legalContent__paragraph">
          <strong>
            Wenn wir Ihre Daten zur Wahrung berechtigter Interessen verarbeiten,
            haben Sie das Recht, dieser Verarbeitung zu widersprechen, wenn sich
            aus Ihrer besonderen Situation Gründe ergeben, die gegen die
            Datenverarbeitung sprechen.
          </strong>
        </p>
      </div>
      <p className="legalContent__paragraph">
        Eine von Ihnen erteilte Einwilligung gemäß Art. 6 Abs. 1 lit. a oder
        Art. 9 Abs. 2 lit. a können Sie jederzeit widerrufen, ohne dass die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung berührt wird.
      </p>
      <h2 className="legalContent__headline2">9. Beschwerderecht</h2>
      <p className="legalContent__paragraph">
        Sie haben die Möglichkeit, sich mit einer Beschwerde an die oben
        genannte Datenschutzbeauftragte oder an eine Datenschutzaufsichtsbehörde
        zu wenden.
      </p>
    </>
  );
}
