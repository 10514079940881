import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import './MaximaleDateigroesseUeberschrittenDialog.scss';

export default ({ hide }) => {
  const actions = <Button onClick={hide}>Schließen</Button>;
  return (
    <Modal
      className="modal--dialog maximaleDateigroesseUeberschritten"
      hide={hide}
      title="Datei zu groß"
      actions={actions}
      gaTrackingName="Dateigröße überschritten"
    >
      <p className="maximaleDateigroesseUeberschritten__info">
        Die Datei kann nicht hochgeladen werden, da sie die maximale Größe von 9
        MB überschreitet.
      </p>
    </Modal>
  );
};
