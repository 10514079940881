import './DatenschutzVervollstaendigenStoerer.scss';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import React from 'react';
import moment from 'moment';
import { useBenutzerContext } from '../../contexts/BenutzerContext';
import { useCookies } from 'react-cookie';
import { useModalState } from '../../hooks';

export default function DatenschutzVervollstaendigStoerer({ showProfilModal }) {
  const { benutzer, istHandwerker } = useBenutzerContext();

  const [cookies, setCookie] = useCookies(['datenschutzVervollstaendigen']);

  const mussDatenschutzNeuAusgefuelltWerden =
    istHandwerker &&
    benutzer.datenschutzbestimmungenTyp === 'Datenschutzbestimmungen als PDF' &&
    !benutzer.datenschutzbestimmungen;

  const [
    isDatenschutzVervollstaendigStoererShown,
    ,
    hideDatenschutzVervollstaendigStoererDialog,
  ] = useModalState(mussDatenschutzNeuAusgefuelltWerden);

  const hideStoererAndSetCookie = () => {
    setCookie('datenschutzVervollstaendigen', 'true', {
      expires: moment.utc(moment()).add(1, 'years').toDate(),
    });
    hideDatenschutzVervollstaendigStoererDialog();
  };

  const actions = (
    <>
      <Button onClick={hideStoererAndSetCookie}>Jetzt nicht</Button>
      <Button
        color="green"
        onClick={() => {
          hideStoererAndSetCookie();
          showProfilModal();
        }}
      >
        Datenschutz aktualisieren
      </Button>
    </>
  );

  const sollStoererAngezeigtWerden =
    mussDatenschutzNeuAusgefuelltWerden &&
    !cookies.datenschutzVervollstaendigen;

  return sollStoererAngezeigtWerden ? (
    <>
      {isDatenschutzVervollstaendigStoererShown && (
        <Modal
          title="Es tut uns leid!"
          actions={actions}
          gaTrackingName="Datenschutzbestimmungen vervollständigen"
          className="modal--dialog"
          hide={hideDatenschutzVervollstaendigStoererDialog}
        >
          <div>
            <p className="datenschutzVervollstaendigenStoerer__text">
              Wir sind bemüht <strong>MeinZuhauseUndIch</strong> stetig zu
              verbessern. Dafür arbeiten wir im Hintergrund an neuen Funktionen
              und daran, bestehende zu optimieren. Dabei geht leider auch mal
              etwas schief.
            </p>
            <p className="datenschutzVervollstaendigenStoerer__text">
              Heute müssen wir Sie darüber informieren, dass Ihre
              Datenschutzbestimmungen nicht mehr gespeichert sind. Sie haben
              hier die Möglichkeit, diese erneut in Ihrem Profil hochzuladen.
            </p>
            <p className="datenschutzVervollstaendigenStoerer__text">
              Wir bitten um Entschuldigung für den entstandenen Aufwand und
              wünschen Ihnen weiterhin viel Erfolg auf{' '}
              <strong>MeinZuhauseUndIch</strong>.
            </p>
          </div>
        </Modal>
      )}
    </>
  ) : null;
}
