import {
  bereich,
  erforderlich,
  hatWerte,
  istSchritt,
  leerraum,
  objektart,
  oder,
  optional,
  taetigkeit,
  teiltaetigkeit,
  und,
} from './helfer';
import {
  BEREICH_AUSSEN,
  BEREICH_INNEN,
  DACHART_FLACHDACH,
  DACHART_MANSARDENDACH,
  DACHART_PULTDACH,
  DACHART_SATTELDACH,
  DACHART_SONSTIGES,
  DACHART_WALMDACH,
  KATEGORIE_INDEX_AUFTRAGSDETAILS,
  OBJEKTART_BODENARBEITEN,
  OBJEKTART_DACHARBEITEN,
  OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN,
  OBJEKTART_FASSADENARBEITEN,
  OBJEKTART_FENSTER_TUERARBEITEN,
  OBJEKTART_HEIZUNG_SANITAER_KLIMA,
  OBJEKTART_HOLZARBEITEN,
  OBJEKTART_SONSTIGE_AUSSENARBEITEN,
  OBJEKTART_SONSTIGE_INNENARBEITEN,
  OBJEKTART_TREPPEN_GELAENDERARBEITEN,
  OBJEKTART_WAND_DECKENARBEITEN,
  TAETIGKEIT_DACHCHECK,
  TEILTAETIGKEIT_DACHCHECK_BASIS,
  TEILTAETIGKEIT_DACHCHECK_PREMIUM,
  TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN,
  TAETIGKEIT_FASSADE_STREICHEN,
  TAETIGKEIT_FASSADE_VERPUTZEN,
  TAETIGKEIT_FENSTER_BEHANDELN,
  TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN,
  TAETIGKEIT_TUEREN_BEHANDELN,
  TAETIGKEIT_WAENDE_DECKEN_STREICHEN,
  TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN,
  TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN,
  OBJEKTART_BALKON_TERRASSENARBEITEN,
  TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN,
  OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE,
  OBJEKTART_KLEINMONTAGE,
  OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST,
} from './konstanten';

const SCHRITTE_AUFTRAGSDETAILS = [
  {
    typ: 'gemischteAuswahl',
    frage: 'Was genau muss gemacht werden?',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    eingaben: [
      erforderlich({
        typ: 'text',
        attribut: 'Auftragstitel',
        gruppenIndex: 0,
        maximaleLaenge: 50,
        hilfetext: "Tätigkeit und Umfang, z.B. 'Laminat verlegen, 100qm'",
      }),
      erforderlich({
        typ: 'freitext',
        attribut: 'Auftragsbeschreibung',
        gruppenIndex: 1,
        hilfetext:
          'Welche Tätigkeiten sollen ausgeführt werden? Wie groß ist der Umfang des Auftrags? Welchen Zustand hat das Objekt? Welche Wünsche haben Sie?',
      }),
      optional({
        typ: 'dateiupload',
        attribut: 'Fotos, Grundriss',
        gruppenIndex: 2,
      }),
    ],
    bedingung: oder(
      und(
        istSchritt(3),
        oder(
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_WAND_DECKENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_FENSTER_TUERARBEITEN)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_BODENARBEITEN)),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_HEIZUNG_SANITAER_KLIMA)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_HOLZARBEITEN)),

          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_DACHARBEITEN)),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_BALKON_TERRASSENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FASSADENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FENSTER_TUERARBEITEN)
          ),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_BODENARBEITEN)),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST)
          )
        )
      ),
      und(
        istSchritt(2),
        oder(
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_HEIZUNG_SANITAER_KLIMA)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_HOLZARBEITEN)),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_HOLZARBEITEN)),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_KLEINMONTAGE)),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_KLEINMONTAGE))
        )
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    frage: 'Was genau muss gemacht werden?',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    eingaben: [
      erforderlich({
        attribut: 'Benötigtes Handwerk',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Bodenleger' },
          { wert: 'Elektroinstallateur' },
          { wert: 'Fliesenleger' },
          { wert: 'Maler & Lackierer' },
          { wert: 'Sanitär-, Heizungs-, Klimainstallateur' },
          { wert: 'Schreiner' },
          { wert: 'Hausmeister' },
          { wert: 'mehrere Gewerke für einen Auftrag benötigt' },
        ],
      }),
      erforderlich({
        typ: 'text',
        attribut: 'Auftragstitel',
        gruppenIndex: 1,
        maximaleLaenge: 50,
        hilfetext: "Tätigkeit und Umfang, z.B. 'Laminat verlegen, 100qm'",
      }),
      erforderlich({
        typ: 'freitext',
        attribut: 'Auftragsbeschreibung',
        gruppenIndex: 2,
        hilfetext:
          'Welche Tätigkeiten sollen ausgeführt werden? Wie groß ist der Umfang des Auftrags? Welchen Zustand hat das Objekt? Welche Wünsche haben Sie?',
      }),
      optional({
        typ: 'dateiupload',
        attribut: 'Fotos, Grundriss',
        gruppenIndex: 3,
      }),
    ],
    bedingung: und(
      istSchritt(2),
      oder(
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_SONSTIGE_INNENARBEITEN)
        )
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    frage: 'Was genau muss gemacht werden?',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    eingaben: [
      erforderlich({
        attribut: 'Benötigtes Handwerk',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Dachdecker' },
          { wert: 'Elektroinstallateur' },
          { wert: 'Fliesenleger' },
          { wert: 'Maler & Lackierer' },
          { wert: 'Sanitär-, Heizungs-, Klimainstallateur' },
          { wert: 'Schreiner' },
          { wert: 'Hausmeister' },
          { wert: 'mehrere Gewerke für einen Auftrag benötigt' },
        ],
      }),
      erforderlich({
        typ: 'text',
        attribut: 'Auftragstitel',
        gruppenIndex: 1,
        maximaleLaenge: 50,
        hilfetext: "Tätigkeit und Umfang, z.B. 'Laminat verlegen, 100qm'",
      }),
      erforderlich({
        typ: 'freitext',
        attribut: 'Auftragsbeschreibung',
        gruppenIndex: 2,
        hilfetext:
          'Welche Tätigkeiten sollen ausgeführt werden? Wie groß ist der Umfang des Auftrags? Welchen Zustand hat das Objekt? Welche Wünsche haben Sie?',
      }),
      optional({
        typ: 'dateiupload',
        attribut: 'Fotos, Grundriss',
        gruppenIndex: 3,
      }),
    ],
    bedingung: und(
      istSchritt(2),
      oder(
        hatWerte(
          bereich(BEREICH_AUSSEN),
          objektart(OBJEKTART_SONSTIGE_AUSSENARBEITEN)
        )
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Treppenbauart',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Gerade Treppe' },
          { wert: 'Wendeltreppe' },
          { wert: 'Podesttreppe' },
          { wert: 'Bogentreppe' },
          { wert: 'Sonstige Treppenart' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Handlauf/Geländer',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Einseitig' },
          { wert: 'Beidseitig' },
          { wert: 'Kein Handlauf/Geländer' },
        ],
      }),
      erforderlich({
        attribut: 'Stufenanzahl',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Bis 10 Stufen' },
          { wert: 'Bis 20 Stufen' },
          { wert: 'Mehr als 20 Stufen' },
        ],
      }),
      erforderlich({
        attribut: 'Treppenbreite',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Bis 1m' },
          { wert: 'Zwischen 1m und 2m' },
          { wert: 'Mehr als 2m' },
        ],
      }),
      erforderlich({
        attribut: 'Treppenmaterial',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Holz' },
          { wert: 'Metall' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Handlauf-/Geländermaterial',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Holz' },
          { wert: 'Metall' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Zustand',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Keine sichtbaren Beschädigungen' },
          { wert: 'Kleine Beschädigungen' },
          { wert: 'Große Beschädigungen' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN),
        taetigkeit(TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Anzahl der zu tapezierenden Räume',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Höhe der Räume in Meter',
        typ: 'text',
        gruppenIndex: 0,
        istVollstaendig: (spezifikation) =>
          Boolean(spezifikation['Höhe der Räume in Meter']),
      }),
      erforderlich({
        attribut: 'Quadratmeterzahl der zu tapezierenden Räume',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Anzahl Türen in den zu tapezierenden Räumen',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Anzahl Fenster in den zu tapezierenden Räumen',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Welche Elemente sollen tapeziert werden?',
        typ: 'mehrfachauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Wände' },
          { wert: 'Decken' },
          { wert: 'Sonstige' },
        ],
        bedingung: hatWerte(teiltaetigkeit('Wände/Decken tapezieren')),
      }),
      erforderlich({
        attribut: 'Welche Art von Tapete soll angebracht werden?',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Raufasertapete' },
          { wert: 'Vliestapete' },
          { wert: 'Papiertapete' },
          { wert: 'Kunststofftapete' },
          { wert: 'Textiltapete' },
          { wert: 'Ich bin mir unsicher' },
        ],
        bedingung: hatWerte(teiltaetigkeit('Wände/Decken tapezieren')),
      }),
      leerraum(2),
      erforderlich({
        attribut: 'Welche Elemente sollen gestrichen werden?',
        typ: 'mehrfachauswahl',
        gruppenIndex: 3,
        auswahlmoeglichkeiten: [
          { wert: 'Wände' },
          { wert: 'Decken' },
          { wert: 'Türen' },
          { wert: 'Fenster' },
          { wert: 'Sonstige' },
        ],
        bedingung: hatWerte(teiltaetigkeit('Wände/Decken streichen')),
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Tapete' },
          { wert: 'Putz' },
          { wert: 'Beton' },
          { wert: 'Trockenbau' },
          { wert: 'Rohbau' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Möblierung der Räume',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Räume sind leerstehend' },
          { wert: 'Räume sind möbliert - Wände räume ich frei' },
          { wert: 'Räume sind möbliert - Wände räumt der Fachpartner frei' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was sollte der Fachpartner sonst noch wissen?',
    eingaben: [
      optional({
        attribut: 'Sonstige Informationen',
        hilfetext:
          'Weitere Informationen zum Ist-Zustand, Wunschvorstellungen zum Soll-Zustand',
        typ: 'freitext',
        gruppenIndex: 0,
      }),
      optional({
        typ: 'dateiupload',
        attribut: 'Fotos, Grundriss',
        gruppenIndex: 1,
      }),
    ],
    bedingung: und(
      istSchritt(5),
      oder(
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_WAND_DECKENARBEITEN),
          taetigkeit(TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN)
        ),
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN),
          taetigkeit(TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN)
        ),
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_WAND_DECKENARBEITEN),
          taetigkeit(TAETIGKEIT_WAENDE_DECKEN_STREICHEN)
        ),
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_WAND_DECKENARBEITEN),
          taetigkeit(TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN)
        ),
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_WAND_DECKENARBEITEN),
          taetigkeit(TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN)
        ),
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_FENSTER_TUERARBEITEN),
          taetigkeit(TAETIGKEIT_FENSTER_BEHANDELN)
        ),
        hatWerte(
          bereich(BEREICH_INNEN),
          objektart(OBJEKTART_FENSTER_TUERARBEITEN),
          taetigkeit(TAETIGKEIT_TUEREN_BEHANDELN)
        ),
        hatWerte(
          bereich(BEREICH_AUSSEN),
          objektart(OBJEKTART_FASSADENARBEITEN),
          taetigkeit(TAETIGKEIT_FASSADE_STREICHEN)
        ),
        hatWerte(
          bereich(BEREICH_AUSSEN),
          objektart(OBJEKTART_FASSADENARBEITEN),
          taetigkeit(TAETIGKEIT_FASSADE_VERPUTZEN)
        ),
        hatWerte(
          bereich(BEREICH_AUSSEN),
          objektart(OBJEKTART_FASSADENARBEITEN),
          taetigkeit(TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN)
        )
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Anzahl der zu streichenden Räume',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Höhe der Räume in Meter',
        typ: 'text',
        gruppenIndex: 0,
        istVollstaendig: (spezifikation) =>
          Boolean(spezifikation['Höhe der Räume in Meter']),
      }),
      erforderlich({
        attribut: 'Quadratmeterzahl der zu streichenden Räume',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Welche Elemente sollen gestrichen werden?',
        typ: 'mehrfachauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Wände' },
          { wert: 'Decken' },
          { wert: 'Türen' },
          { wert: 'Fenster' },
          { wert: 'Sonstige' },
        ],
      }),
      erforderlich({
        attribut: 'Anzahl Türen in den zu streichenden Räumen',
        typ: 'text',
        gruppenIndex: 2,
      }),
      erforderlich({
        attribut: 'Anzahl Fenster in den zu streichenden Räumen',
        typ: 'text',
        gruppenIndex: 2,
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 3,
        auswahlmoeglichkeiten: [
          { wert: 'Tapete' },
          { wert: 'Putz' },
          { wert: 'Beton' },
          { wert: 'Trockenbau' },
          { wert: 'Rohbau' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktuelle Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 3,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
      leerraum(4),
      erforderlich({
        attribut: 'Möblierung der Räume',
        typ: 'listenauswahl',
        gruppenIndex: 5,
        auswahlmoeglichkeiten: [
          { wert: 'Räume sind leerstehend' },
          { wert: 'Räume sind möbliert - Wände räume ich frei' },
          { wert: 'Räume sind möbliert - Wände räumt der Fachpartner frei' },
        ],
      }),
      leerraum(5),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_WAENDE_DECKEN_STREICHEN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Anzahl der zu verputzenden Räume',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Höhe der Räume in Meter',
        typ: 'text',
        gruppenIndex: 0,
        istVollstaendig: (spezifikation) =>
          Boolean(spezifikation['Höhe der Räume in Meter']),
      }),
      erforderlich({
        attribut: 'Quadratmeterzahl der zu verputzenden Räume',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Welche Elemente sollen mit Dekorputz versehen werden?',
        typ: 'mehrfachauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [{ wert: 'Wände' }, { wert: 'Decken' }],
      }),
      erforderlich({
        attribut: 'Anzahl Türen in den zu verputzenden Räumen',
        typ: 'text',
        gruppenIndex: 2,
      }),
      erforderlich({
        attribut: 'Anzahl Fenster in den zu verputzenden Räumen',
        typ: 'text',
        gruppenIndex: 2,
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 3,
        auswahlmoeglichkeiten: [
          { wert: 'Tapete' },
          { wert: 'Putz' },
          { wert: 'Beton' },
          { wert: 'Trockenbau' },
          { wert: 'Rohbau' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      leerraum(3),
      erforderlich({
        attribut: 'Gewünschte Putzstruktur',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Modellierputz' },
          { wert: 'Spachtelputz' },
          { wert: 'Kratzputz-Strukturen' },
          { wert: 'Reibeputz' },
          { wert: 'Glattputz' },
          { wert: 'Feinputz' },
          { wert: 'Buntsteinputz' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),

      erforderlich({
        attribut: 'Möblierung der Räume',
        typ: 'listenauswahl',
        gruppenIndex: 5,
        auswahlmoeglichkeiten: [
          { wert: 'Räume sind leerstehend' },
          { wert: 'Räume sind möbliert - Wände räume ich frei' },
          { wert: 'Räume sind möbliert - Wände räumt der Fachpartner frei' },
        ],
      }),
      leerraum(5),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Gebäudeart',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Einfamilienhaus' },
          { wert: 'Mehrfamilienhaus' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Jahr der Gebäudeerrichtung',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Vor 1960' },
          { wert: 'Zwischen 1960 und 1990' },
          { wert: 'Nach 1990' },
        ],
      }),
      erforderlich({
        attribut: 'Steht das Gebäude unter Denkmalschutz',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Ja' },
          { wert: 'Nein' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Anzahl der zu dämmenden Wände',
        typ: 'text',
        gruppenIndex: 1,
        bedingung: hatWerte(teiltaetigkeit('Wände dämmen')),
      }),
      erforderlich({
        attribut: 'Anzahl der zu dämmenden Decken',
        typ: 'text',
        gruppenIndex: 1,
        bedingung: hatWerte(teiltaetigkeit('Decken dämmen')),
      }),
      erforderlich({
        attribut: 'Quadratmeterzahl der zu dämmenden Fläche',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Bis 50qm' },
          { wert: '51 bis 150qm' },
          { wert: '151 bis 300qm' },
          { wert: 'Mehr als 300qm' },
        ],
      }),
      erforderlich({
        attribut: 'Ziel der Dämmarbeiten',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Energieeffizienz steigern' },
          { wert: 'Schutz vor Feuchtigkeit' },
          { wert: 'Mängel beseitigen' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Tapete' },
          { wert: 'Putz' },
          { wert: 'Beton' },
          { wert: 'Trockenbau' },
          { wert: 'Rohbau' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN)
      )
    ),
  },
  {
    typ: 'sofortauswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden? (1/2)',
    attribut: 'Art der Dachform',
    auswahlmoeglichkeiten: [
      {
        wert: DACHART_SATTELDACH,
      },
      {
        wert: DACHART_FLACHDACH,
      },
      {
        wert: DACHART_PULTDACH,
      },
      {
        wert: DACHART_WALMDACH,
      },
      {
        wert: DACHART_MANSARDENDACH,
      },
      {
        wert: DACHART_SONSTIGES,
      },
    ],
    bedingung: und(
      istSchritt(4),
      oder(
        hatWerte(
          bereich(BEREICH_AUSSEN),
          objektart(OBJEKTART_DACHARBEITEN),
          taetigkeit(TAETIGKEIT_DACHCHECK),
          teiltaetigkeit(TEILTAETIGKEIT_DACHCHECK_PREMIUM)
        ),
        hatWerte(
          bereich(BEREICH_AUSSEN),
          objektart(OBJEKTART_DACHARBEITEN),
          taetigkeit(TAETIGKEIT_DACHCHECK),
          teiltaetigkeit(TEILTAETIGKEIT_DACHCHECK_BASIS)
        )
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    frage: 'Was genau muss gemacht werden? (2/2)',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    eingaben: [
      erforderlich({
        typ: 'text',
        attribut: 'Grundfläche des Hauses in qm',
        gruppenIndex: 1,
      }),
      erforderlich({
        typ: 'text',
        attribut: 'Anzahl Stockwerke',
        gruppenIndex: 1,
      }),
      erforderlich({
        attribut: 'Bedachungsmaterial',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Ziegel/Pfannen' },
          { wert: 'Schiefer' },
          { wert: 'Dachpappe' },
          { wert: 'Holz' },
          { wert: 'Reet/Schilf/Stroh' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        typ: 'freitext',
        attribut: 'Informationen zu Dachrinnen und Fallrohren',
        gruppenIndex: 3,
        hilfetext:
          'Aus welchem Material sind Dachrinnen und Fallrohre? In welchem Zustand befinden sie sich? Sind diese durch angestellte Leitern belastbar?',
        bedingung: (_, spezifikation) =>
          spezifikation['teiltaetigkeit'].includes('Dachcheck Premium'),
      }),
      erforderlich({
        attribut: 'Sonstige Art der Dachform',
        typ: 'text',
        gruppenIndex: 4,
        maximaleLaenge: 50,
        bedingung: (_, spezifikation) =>
          spezifikation['Art der Dachform'] === 'Sonstiges',
      }),
      optional({
        typ: 'dateiupload',
        attribut: 'Fotos von Dach, Haus und Dachzufahrt',
        gruppenIndex: 5,
      }),
      optional({
        typ: 'freitext',
        attribut: 'Sonstige Informationen',
        gruppenIndex: 6,
      }),
    ],
    bedingung: und(
      istSchritt(5),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_DACHARBEITEN),
        taetigkeit(TAETIGKEIT_DACHCHECK)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Anzahl zu behandelnder Fenster',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Fensterrahmenmaterial',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Holz' },
          { wert: 'Kunststoff' },
          { wert: 'Aluminium' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktuelle Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Transparent' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_FENSTER_TUERARBEITEN),
        taetigkeit(TAETIGKEIT_FENSTER_BEHANDELN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Anzahl zu behandelnder Türen',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Türmaterial',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Holz' },
          { wert: 'Kunststoff' },
          { wert: 'Aluminium' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktuelle Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Transparent' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_FENSTER_TUERARBEITEN),
        taetigkeit(TAETIGKEIT_TUEREN_BEHANDELN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Gebäudeart',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Einfamilienhaus' },
          { wert: 'Mehrfamilienhaus' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Jahr der Gebäudeerrichtung',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Vor 1960' },
          { wert: 'Zwischen 1960 und 1990' },
          { wert: 'Nach 1990' },
        ],
      }),
      erforderlich({
        attribut: 'Steht das Gebäude unter Denkmalschutz',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Ja' },
          { wert: 'Nein' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Anzahl zu streichender Außenwände',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Anzahl Stockwerke',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Gerüst vorhanden',
        typ: 'listenauswahl', // Sollte radio sein.
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [{ wert: 'Ja' }, { wert: 'Nein' }],
      }),
      erforderlich({
        attribut: 'Zu streichende Fläche',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Bis 50qm' },
          { wert: '51 bis 150qm' },
          { wert: '151 bis 300qm' },
          { wert: 'Mehr als 300qm' },
        ],
      }),
      erforderlich({
        attribut: 'Zustand der Fassade',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Keine sichtbaren Beschädigungen' },
          { wert: 'Kleine Beschädigungen, z.B. Risse, Farbabblätterungen' },
          { wert: 'Große Beschädigungen, z.B. Putzabplatzungen' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Verschmutzungsgrad der Fassade',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Keine Verschmutzungen' },
          { wert: 'Leichte Verschmutzung' },
          { wert: 'Starke Verschmutzung' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Putz' },
          { wert: 'Holz' },
          { wert: 'Klinker/Ziegel' },
          { wert: 'Fachwerk' },
          { wert: 'Beton' },
          { wert: 'Rohbau' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktuelle Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FASSADENARBEITEN),
        taetigkeit(TAETIGKEIT_FASSADE_STREICHEN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Gebäudeart',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Einfamilienhaus' },
          { wert: 'Mehrfamilienhaus' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Jahr der Gebäudeerrichtung',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Vor 1960' },
          { wert: 'Zwischen 1960 und 1990' },
          { wert: 'Nach 1990' },
        ],
      }),
      erforderlich({
        attribut: 'Steht das Gebäude unter Denkmalschutz',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Ja' },
          { wert: 'Nein' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Anzahl zu verputzender Außenwände',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Anzahl Stockwerke',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Gerüst vorhanden',
        typ: 'listenauswahl', // Sollte radio sein.
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [{ wert: 'Ja' }, { wert: 'Nein' }],
      }),
      erforderlich({
        attribut: 'Zu verputzende Fläche',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Bis 50qm' },
          { wert: '51 bis 150qm' },
          { wert: '151 bis 300qm' },
          { wert: 'Mehr als 300qm' },
        ],
      }),
      erforderlich({
        attribut: 'Zustand der Fassade',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Keine sichtbaren Beschädigungen' },
          { wert: 'Kleine Beschädigungen, z.B. Risse, Farbabblätterungen' },
          { wert: 'Große Beschädigungen, z.B. Putzabplatzungen' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Verschmutzungsgrad der Fassade',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Keine Verschmutzungen' },
          { wert: 'Leichte Verschmutzung' },
          { wert: 'Starke Verschmutzung' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Putz' },
          { wert: 'Holz' },
          { wert: 'Klinker/Ziegel' },
          { wert: 'Fachwerk' },
          { wert: 'Beton' },
          { wert: 'Rohbau' },
          { wert: 'Wärmedämmverbundsystem' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Putzstruktur',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Kellenputz' },
          { wert: 'Kratzputz' },
          { wert: 'Modellierputz' },
          { wert: 'Reibeputz' },
          { wert: 'Scheibenputz' },
          { wert: 'Rollputz' },
          { wert: 'Spritzputz' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FASSADENARBEITEN),
        taetigkeit(TAETIGKEIT_FASSADE_VERPUTZEN)
      )
    ),
  },
  {
    typ: 'gemischteAuswahl',
    kategorieIndex: KATEGORIE_INDEX_AUFTRAGSDETAILS,
    frage: 'Was wird der Fachpartner vorfinden?',
    eingaben: [
      erforderlich({
        attribut: 'Gebäudeart',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Einfamilienhaus' },
          { wert: 'Mehrfamilienhaus' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Jahr der Gebäudeerrichtung',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Vor 1960' },
          { wert: 'Zwischen 1960 und 1990' },
          { wert: 'Nach 1990' },
        ],
      }),
      erforderlich({
        attribut: 'Steht das Gebäude unter Denkmalschutz',
        typ: 'listenauswahl',
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [
          { wert: 'Ja' },
          { wert: 'Nein' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Anzahl zu verputzender Außenwände',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Anzahl Stockwerke',
        typ: 'text',
        gruppenIndex: 0,
      }),
      erforderlich({
        attribut: 'Gerüst vorhanden',
        typ: 'listenauswahl', // Sollte radio sein.
        gruppenIndex: 0,
        auswahlmoeglichkeiten: [{ wert: 'Ja' }, { wert: 'Nein' }],
      }),
      erforderlich({
        attribut: 'Zu dämmende Fläche',
        typ: 'listenauswahl',
        gruppenIndex: 1,
        auswahlmoeglichkeiten: [
          { wert: 'Bis 50qm' },
          { wert: '51 bis 150qm' },
          { wert: '151 bis 300qm' },
          { wert: 'Mehr als 300qm' },
        ],
      }),
      erforderlich({
        attribut: 'Aktueller Untergrund',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Putz' },
          { wert: 'Holz' },
          { wert: 'Klinker/Ziegel' },
          { wert: 'Fachwerk' },
          { wert: 'Beton' },
          { wert: 'Rohbau' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Farbe',
        typ: 'listenauswahl',
        gruppenIndex: 2,
        auswahlmoeglichkeiten: [
          { wert: 'Weiß' },
          { wert: 'Farbig' },
          { wert: 'Mehrfarbig' },
          { wert: 'Sonstiges' },
        ],
      }),
      erforderlich({
        attribut: 'Gewünschte Putzstruktur',
        typ: 'listenauswahl',
        gruppenIndex: 4,
        auswahlmoeglichkeiten: [
          { wert: 'Kellenputz' },
          { wert: 'Kratzputz' },
          { wert: 'Modellierputz' },
          { wert: 'Reibeputz' },
          { wert: 'Scheibenputz' },
          { wert: 'Rollputz' },
          { wert: 'Spritzputz' },
          { wert: 'Sonstiges' },
          { wert: 'Ich bin mir unsicher' },
        ],
      }),
    ],
    bedingung: und(
      istSchritt(4),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FASSADENARBEITEN),
        taetigkeit(TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN)
      )
    ),
  },
];

export default SCHRITTE_AUFTRAGSDETAILS;
