import React from 'react';
import { useHistory } from 'react-router-dom';
import { ShowAt, withBreakpoints } from 'react-with-breakpoints';

import Chatrooms from '../../../components/Chatrooms/Chatrooms';
import Angebote from '../../../components/Angebote/Angebote';
import Chat from '../../../components/Chat/Chat';
import KommunikationskanalHeader from '../../../components/Kunde/KommunikationskanalHeader/KommunikationskanalHeader';
import QuickActions from '../../../components/QuickActions/QuickActions';
import AngeboteStoerer from '../../../components/AngeboteStoerer/AngeboteStoerer';
import { useAnfrage } from '../../../hooks/useAnfrage';
import ConciergeService, {
  CONCIERGE_SERVICE_CONTEXT,
} from '../../../components/ConciergeService/ConciergeService';

import './Kommunikationskanal.scss';

const AUTOR = 'KUNDE';

const Kommunikationskanal = withBreakpoints(function Kommunikationskanal({
  match,
  currentBreakpoint,
  view,
}) {
  const history = useHistory();
  const anfrageId = match.params.anfrageId;

  const oeffneKommunikationskanal = (a) => {
    if (currentBreakpoint === 'xlarge') {
      history.push(`/kommunikationskanal/${a.id}`);
    } else {
      history.push(`/nachrichten/${a.id}`);
    }
  };

  const isRouteKommunikationskanal = !view;
  const isRouteAnfragen = view === 'anfragen';
  const isRouteNachrichten = view === 'nachrichten';
  const isDesktop = currentBreakpoint === 'xlarge';

  const zeigeAnfragen = isDesktop
    ? isRouteKommunikationskanal || isRouteAnfragen || isRouteNachrichten
    : isRouteAnfragen;
  const zeigeNachrichtenMobile =
    isRouteNachrichten || (!isDesktop && isRouteKommunikationskanal);
  const zeigeNachrichten = isDesktop || isRouteNachrichten;

  const { anfrage } = useAnfrage(anfrageId);

  return (
    <>
      {anfrage ? (
        <section className="kommunikationskanal kommunikationskanal--kunde">
          <KommunikationskanalHeader anfrage={anfrage} view={view} />

          {zeigeNachrichtenMobile && (
            <AngeboteStoerer anfrage={anfrage} autor={AUTOR} />
          )}
          <div className="kommunikationskanal__conciergeService">
            <ConciergeService
              auftrag={anfrage.auftrag}
              zeigeNurModal={!zeigeAnfragen}
              context={CONCIERGE_SERVICE_CONTEXT.KOMMUNIKATIONSKANAL}
            />
          </div>
          {zeigeAnfragen && (
            <Chatrooms
              anfrage={anfrage}
              onClickChatroom={oeffneKommunikationskanal}
            />
          )}

          {(zeigeNachrichten || zeigeNachrichtenMobile) && (
            <Chat
              anfrage={anfrage}
              autor={AUTOR}
              headerIntro={
                anfrage.handwerker
                  ? anfrage.handwerker.firmenname
                  : 'HANDWERKER NAME'
              }
              willkommenshinweis={
                <>
                  Willkommen in der Unterhaltung mit&nbsp;
                  {anfrage.handwerker
                    ? anfrage.handwerker.firmenname
                    : 'HANDWERKER NAME'}
                  . Hier können Sie mit dem Fachpartner offene Fragen zur
                  Ausführung Ihrer Auftragsanfrage klären. Darüber hinaus können
                  Sie hier den Fachpartner verbindlich beauftragen, die
                  Auftragsausführung abnehmen und die finale Rechnung
                  entgegennehmen.
                </>
              }
              quickActions={<QuickActions rolle="KUNDE" anfrage={anfrage} />}
            />
          )}
          <ShowAt breakpoint="xlarge">
            <Angebote anfrage={anfrage} autor={AUTOR} />
          </ShowAt>
        </section>
      ) : (
        <div className="kommunikationskanal__404">
          Entschuldigung, wir können die angeforderte Seite nicht finden.
        </div>
      )}
    </>
  );
});

export default Kommunikationskanal;
