import React from 'react';

import { useModalState } from '../../hooks';
import Button from '../Button/Button';
import { ReactComponent as AlertHelpIcon } from '../../assets/icons/alert_help.svg';

import './Auftragsstatus.scss';
import { AuftragsstatusModal } from './AuftragsstatusModal';

export function Auftragsstatus({ fortschritt, gtmLabel }) {
  const [isModalVisible, showModal, hideModal] = useModalState();

  return (
    <>
      <div className="auftragsstatus" data-cy-id="auftragsstatus">
        <span className="auftragsstatus__status">{fortschritt.status}</span>
        <Button
          icon={<AlertHelpIcon />}
          color="grey"
          outlined
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            showModal();
          }}
        />

        <span className="auftragsstatus__anzahlSchritte">
          {fortschritt.schritt}/{fortschritt.anzahlSchritte}
        </span>
        <progress
          value={fortschritt.schritt}
          max={fortschritt.anzahlSchritte}
        />
      </div>

      {isModalVisible && (
        <AuftragsstatusModal hideModal={hideModal} gtmLabel={gtmLabel} />
      )}
    </>
  );
}
