import React from 'react';
import './LoadingIndicator.scss';

export default function LoadingIndicator({ text }) {
  return (
    <div className="loadingIndicator">
      <div className="loadingIndicator__spinner" />
      {text && <div className="loadingIndicator__text">{text}</div>}
    </div>
  );
}
