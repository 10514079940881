import React from 'react';

export default function DachcheckTeaserBild(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 1376 500"
      height="500"
      width="1376"
      {...props}
    >
      <path
        fill="#b7b7b7"
        d="m 480.91298,351.65371 1.122,-0.485 139.711,-0.129 c 0.072,1.222 -0.651,2.268 -1.633,2.394 l -0.003,0.004 h -0.034 c -0.023,0.003 -0.047,0.004 -0.07,0.006 -0.074,0.004 -0.146,0.003 -0.218,-0.002 l -140.657,1.663 -0.479,-2.795 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        fill="#878787"
        d="m 479.33998,355.09271 c 1.611,-0.096 2.818,-1.853 2.695,-3.924 l -5.836,0.346 c 0.123,2.071 1.529,3.673 3.141,3.578 z"
      />
      <path
        fill="#e0eae6"
        d="m 847.99698,428.30871 -414.504,6.07 209.345,58.395 321.137,-43.326 z"
      />
      <path
        fill="#c4c4c4"
        d="m 507.18198,351.70371 -29.099,-0.209 0.21,-1.675 58.407,-170.581 30.146,6.488 z"
      />
      <path
        fill="#eaeaea"
        d="m 501.32098,343.75071 53.802,-143.582 86.878,112.396 v 151.535 l -140.68,-27.419 z"
      />
      <path
        fill="#ffffff"
        d="m 891.33198,443.16871 -249.331,20.931 v -151.535 l 249.331,25.116 z"
      />
      <path
        fillOpacity="0.08"
        fill="#000000"
        d="m 891.33198,365.51771 v -14.442 l -339.768,-141.488 -50.243,134.163 v 92.93 l 75.364,14.651 65.316,-93.139 z"
      />
      <path
        fill="#ffffff"
        d="m 540.88698,190.75071 -56.314,160.744 1.884,3.558 57.361,-159.488 z"
      />
      <path
        fill="#d7d7d7"
        d="m 488.75998,355.05271 h -2.303 l 57.151,-159.07 1.256,2.931 z"
      />
      <path stroke="#000000" d="m 546.32998,437.09971 v -61.117" />
      <path
        fill="#cfcfcf"
        d="m 558.05398,375.56471 -16.329,0.418 0.418,61.326 4.187,-0.209 11.724,2.093 z"
      />
      <path
        fill="#096547"
        d="m 545.70198,375.98271 0.628,61.117 11.723,2.093 v -63.628 z"
      />
      <path
        fill="#cfcfcf"
        d="m 621.06598,373.68071 -20.934,0.209 v 25.117 l 20.934,0.627 z"
      />
      <path
        fill="#878787"
        d="m 621.06598,373.68071 -15.91,0.209 v 25.117 l 15.91,0.627 z"
      />
      <path
        fill="#cfcfcf"
        d="m 721.55198,373.68071 -30.564,-0.419 v 43.745 l 30.564,-0.419 z"
      />
      <path
        fill="#878787"
        d="m 716.10898,373.68071 -25.121,-0.419 v 42.07 l 25.121,-0.418 z"
      />
      <path
        fill="#cfcfcf"
        d="m 797.75398,374.72671 h -26.377 v 40.396 l 26.377,-0.419 z"
      />
      <path
        fill="#878787"
        d="m 792.31098,374.72671 h -20.934 v 38.721 l 20.934,-0.418 z"
      />
      <path
        fill="#cfcfcf"
        d="m 524.76698,376.82071 h -12.77 v 19.674 l 12.77,0.209 z"
      />
      <path
        fill="#878787"
        d="m 524.76698,377.23871 h -8.583 v 19.256 l 8.583,0.209 z"
      />
      <path
        fill="#cfcfcf"
        d="m 586.94298,277.40171 -17.376,4.605 v 66.976 l 18.004,-1.046 z"
      />
      <path
        fill="#878787"
        d="m 586.94298,279.07571 -12.351,3.349 v 66.14 l 12.979,-0.628 z"
      />
      <path
        fill="#cfcfcf"
        d="m 548.42298,288.07571 -14.445,3.977 v 60.698 l 14.445,-1.047 z"
      />
      <path
        fill="#878787"
        d="m 548.42398,289.75071 -9.84,2.721 v 59.86 l 9.84,-0.628 z"
      />
      <path
        fill="#373737"
        d="m 535.86198,177.77371 -59.244,172.256 1.465,1.465 57.779,-167.651 87.926,149.651 289.525,15.698 3.559,-2.093 -114.094,-117.628 z"
      />
      <path
        fill="#000000"
        d="m 535.86198,177.77371 90.228,151.744 -2.302,3.977 -87.926,-149.651 -57.779,167.651 -1.465,-1.465 z"
      />
      <path
        fill="#000000"
        d="m 916.87198,347.09971 -290.782,-17.582 -2.303,3.977 289.526,15.698 z"
      />
      <path
        fill="#9f9f9f"
        d="m 736.83398,279.07571 -28.889,-3.558 22.19,27.419 28.262,2.093 z"
      />
      <path
        fill="#9f9f9f"
        d="m 765.72398,305.65771 -21.562,-25.744 27.424,3.139 20.516,24.698 z"
      />
      <path
        fill="#eaeaea"
        d="m 755.25698,230.93671 v -20.931 l 13.189,-1.255 v 35.162 z"
      />
      <path
        fill="#ffffff"
        d="m 782.26298,211.47171 -13.817,-2.721 v 35.581 l 13.817,0.628 z"
      />
      <path
        fill="#cfcfcf"
        d="m 768.44598,208.75071 -14.026,1.465 v -3.14 l 14.026,-1.883 z"
      />
      <path
        fill="#b7b7b7"
        d="m 882.74798,444.42471 v -65.151 c 0,-7.481 4.386,-14.266 11.207,-17.338 l 0.401,-0.181 c 4.53,-2.041 7.443,-6.548 7.443,-11.516 h 5.443 c 0,6.428 -3.587,12.319 -9.297,15.27 l -1.35,0.697 c -4.904,2.534 -7.985,7.594 -7.985,13.114 v 65.315 h -0.059 c -0.271,0.477 -1.454,0.837 -2.872,0.837 -1.418,0 -2.6,-0.36 -2.872,-0.837 h -0.059 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        fill="#b7b7b7"
        d="m 636.76698,332.86671 2.303,-0.837 279.686,16.325 c 0,2.455 -1.572,4.461 -3.553,4.598 l -0.006,0.007 -0.068,-0.003 c -0.047,0.002 -0.094,0.003 -0.141,0.003 -0.148,0 -0.294,-0.01 -0.437,-0.031 l -281.761,-13.364 -0.628,-5.651 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        fill="#878787"
        d="m 633.20798,339.56471 c 3.238,0 5.862,-3.374 5.862,-7.535 h -11.723 c 0,4.161 2.624,7.535 5.861,7.535 z"
      />
      <path
        fill="#f7f7f7"
        d="m 546.32998,447.56471 -30.564,-6.07 19.259,-1.465 30.984,5.442 z"
      />
      <path
        fill="#f7f7f7"
        d="m 546.32998,437.09971 -17.167,0.837 15.073,2.093 13.817,-0.837 z"
      />
      <path
        fill="#eaeaea"
        d="m 558.05398,444.08971 v -4.897 l -13.817,0.837 v 5.442 z"
      />
      <path
        fill="#b7b7b7"
        d="m 544.23598,440.02971 -15.073,-2.093 v 4.395 l 15.073,3.14 z"
      />
      <path
        fill="#eaeaea"
        d="m 546.32998,447.56471 -30.564,-6.07 v 5.86 l 30.564,7.117 19.679,-2.931 v -6.069 z"
      />
      <path
        fill="#b7b7b7"
        d="m 546.32998,447.56471 -30.564,-6.07 v 5.86 l 30.564,7.117 z"
      />
      <path
        fill="#eaeaea"
        d="m 783.09998,208.54071 -14.654,-3.348 v 3.558 l 14.654,2.93 z"
      />
      <path
        fill="#373737"
        d="m 554.70398,381.63371 h -2.931 v 27.21 l 2.931,0.209 z"
      />
      <rect
        y="266.14249"
        x="286.23599"
        fillOpacity="0.1"
        fill="#406a5e"
        transform="matrix(0.99717299,-0.07514,0.07517097,0.99717066,0,0)"
        height="188.01807"
        width="144.145"
      />
      <rect
        y="257.78613"
        x="293.77243"
        fill="#ffffff"
        transform="matrix(0.99717299,-0.07514,0.07517097,0.99717066,0,0)"
        height="187.90807"
        width="142.383"
      />
      <rect
        y="287.48059"
        x="349.05289"
        fill="#878787"
        transform="matrix(0.99758724,-0.06942412,0.06945262,0.99758525,0,0)"
        rx="2.7375093"
        height="5.4750185"
        width="60.471485"
        opacity="0.4"
      />
      <path
        fill="#096547"
        d="m 358.34398,259.93871 c 0.997,0.865 1.104,2.375 0.238,3.372 l -10.637,12.256 c -0.866,0.997 -2.376,1.104 -3.373,0.238 l -6.129,-5.317 c -0.998,-0.865 -1.105,-2.375 -0.239,-3.372 0.865,-0.997 2.375,-1.104 3.373,-0.239 l 4.323,3.751 9.071,-10.451 c 0.865,-0.997 2.375,-1.104 3.373,-0.238 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <rect
        y="316.88626"
        x="349.52368"
        fill="#878787"
        transform="matrix(0.99758724,-0.06942402,0.06945272,0.99758524,0,0)"
        rx="2.7374992"
        height="5.4750085"
        width="41.06789"
        opacity="0.4"
      />
      <path
        fill="#096547"
        d="m 360.85598,289.24071 c 0.997,0.865 1.104,2.375 0.239,3.372 l -10.638,12.256 c -0.866,0.997 -2.376,1.104 -3.373,0.239 l -6.129,-5.318 c -0.997,-0.865 -1.104,-2.375 -0.239,-3.372 0.866,-0.997 2.376,-1.104 3.373,-0.239 l 4.323,3.751 9.071,-10.45 c 0.865,-0.998 2.375,-1.104 3.373,-0.239 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <rect
        y="346.26294"
        x="349.57748"
        fill="#878787"
        transform="matrix(0.99758724,-0.06942402,0.06945282,0.99758524,0,0)"
        rx="2.7374992"
        height="5.4750085"
        width="70.662987"
        opacity="0.4"
      />
      <path
        fill="#096547"
        d="m 362.94998,318.54271 c 0.997,0.865 1.104,2.375 0.238,3.372 l -10.637,12.256 c -0.866,0.997 -2.376,1.104 -3.373,0.239 l -6.129,-5.318 c -0.998,-0.865 -1.105,-2.375 -0.239,-3.372 0.865,-0.997 2.375,-1.104 3.373,-0.238 l 4.323,3.75 9.071,-10.45 c 0.865,-0.997 2.375,-1.104 3.373,-0.239 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <rect
        y="375.6987"
        x="350.46628"
        fill="#878787"
        transform="matrix(0.99758724,-0.06942402,0.06945272,0.99758524,0,0)"
        rx="2.7375093"
        height="5.4750085"
        width="26.017294"
        opacity="0.4"
      />
      <path
        fill="#096547"
        d="m 365.87998,347.84571 c 0.998,0.865 1.104,2.375 0.239,3.372 l -10.638,12.256 c -0.865,0.997 -2.375,1.104 -3.373,0.238 l -6.129,-5.317 c -0.997,-0.865 -1.104,-2.375 -0.238,-3.372 0.865,-0.997 2.375,-1.104 3.372,-0.239 l 4.324,3.751 9.07,-10.451 c 0.866,-0.997 2.376,-1.104 3.373,-0.238 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <rect
        y="404.68637"
        x="350.96613"
        fill="#878787"
        transform="matrix(0.99758725,-0.06942392,0.06945292,0.99758523,0,0)"
        rx="2.7374992"
        height="5.4749985"
        width="60.471485"
        opacity="0.4"
      />
      <path
        fill="#096547"
        d="m 368.39198,376.72871 c 0.998,0.865 1.105,2.375 0.239,3.372 l -10.637,12.256 c -0.866,0.997 -2.376,1.104 -3.373,0.239 l -6.129,-5.318 c -0.998,-0.865 -1.105,-2.375 -0.239,-3.372 0.865,-0.997 2.375,-1.104 3.373,-0.238 l 4.323,3.75 9.071,-10.45 c 0.865,-0.997 2.375,-1.104 3.372,-0.239 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <circle
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        r="10"
        cy="193.77371"
        cx="618.97498"
      />
      <circle
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        r="10"
        cy="277.77371"
        cx="713.97498"
      />
      <circle
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        r="10"
        cy="226.77371"
        cx="781.97498"
      />
      <circle
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        r="10"
        cy="384.77371"
        cx="886.97498"
      />
      <circle
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        r="10"
        cy="336.77371"
        cx="665.97498"
      />
      <circle
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        r="10"
        cy="338.77371"
        cx="318.97498"
      />
      <path
        fill="#69a82f"
        d="m 196.97498,43.905709 -10.981,10.868 -7.019,-6.947 2.155,-2.132 4.864,4.814 8.826,-8.735 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        fill="#69a82f"
        d="m 196.97498,93.905709 -10.981,10.868001 -7.019,-6.947001 2.155,-2.132 4.864,4.814001 8.826,-8.735001 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        d="m 178.47498,138.77371 h 397 l 38,47"
      />
      <path
        fill="#69a82f"
        d="m 949.97498,180.90571 -10.981,10.868 -7.019,-6.947 2.155,-2.132 4.864,4.814 8.826,-8.735 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        d="M 1222,226.77371 H 942.95598 791.97498"
      />
      <path
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        d="M 1302,114.27371 H 822.97498 l -103,154.5"
      />
      <path
        fill="#69a82f"
        d="m 1001.975,315.75 -10.98002,10.868 -7.02,-6.947 2.15,-2.132 4.87,4.814 8.83,-8.735 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        fill="#69a82f"
        d="m 109.97498,194.90571 -10.981001,10.868 -7.019,-6.947 2.155,-2.132 4.864,4.814 8.826001,-8.735 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        d="M 93.474979,239.27371 H 429.47498 l 227.5,93.5"
      />
      <path
        fill="#69a82f"
        d="m 59.974979,292.90571 -10.9807,10.868 -7.0193,-6.947 2.1547,-2.132 4.8646,4.814 8.826,-8.735 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <line
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        y2="383.77371"
        x2="896.97498"
        y1="383.77371"
        x1="1312"
      />
      <line
        strokeWidth="2"
        fill="none"
        stroke="#69a82f"
        y2="338.77371"
        x2="309.97498"
        y1="338.77371"
        x1="41.974979"
      />
      <path
        fill="#69a82f"
        d="m 837.97498,44.905709 -10.981,10.868 -7.019,-6.947 2.155,-2.132 4.864,4.814 8.826,-8.735 z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <text
        y="259.99991"
        x="234.73677"
        transform="translate(-31.635635,-197.02647)"
      />
      <text transform="translate(-31.025021,-224.22629)" />
      <text
        transform="translate(-31.025021,-224.22629)"
        x="234.90538"
        y="278.10431"
      >
        <tspan x="234.90538" y="278.10431">
          <tspan>Begehung und Beurteilung des Gesamteindrucks </tspan>
        </tspan>
        <tspan x="234.90538" y="299.10432">
          <tspan>und Abnutzungsgrads des gesamten Daches.</tspan>
        </tspan>
      </text>
      <text x="144.7644" y="79.984161">
        <tspan x="144.7644" y="79.984161" />
      </text>
      <text
        transform="translate(-31.025021,-224.22629)"
        y="329.23145"
        x="235.64035"
      >
        <tspan x="235.64035" y="329.23145">
          <tspan>Inspektion der Verklammerung und Besichtigung </tspan>
        </tspan>
        <tspan x="235.64035" y="350.23146">
          <tspan>der Dachziegel zur Sturmsicherheit.</tspan>
        </tspan>
      </text>
      <text x="313.74844" y="125.85138">
        <tspan x="313.74844" y="125.85138" />
      </text>
      <text
        x="148.51779"
        y="429.19873"
        transform="translate(-31.909056,-224.22629)"
      >
        <tspan x="148.51779" y="429.19873">
          <tspan>Reinigung der Dachrinnen von Laub und </tspan>
        </tspan>
        <tspan x="148.51779" y="450.19874">
          <tspan>
            Dreck (nur <tspan style={{ fontWeight: 'bold' }}>Premium</tspan>).
          </tspan>
        </tspan>
      </text>

      <text
        x="96.359764"
        y="525.55853"
        transform="translate(750.41627,-470.00916)"
      >
        <tspan x="96.359764" y="525.55853">
          <tspan>Überprüfung aller funktionswichtigen Einbauelemente</tspan>
        </tspan>
        <tspan x="96.359764" y="546.55851">
          <tspan>wie Dachfenster, Lüfter oder Satellitenanschlüsse, </tspan>
        </tspan>
        <tspan x="96.359764" y="567.55849">
          <tspan>
            Überprüfung aller funktionswichtigen auch auf Undichtigkeiten.
          </tspan>
        </tspan>
      </text>
      <text
        y="525.55853"
        x="96.359764"
        transform="translate(-29.942937,-222.99693)"
      >
        <tspan x="96.359764" y="525.55853">
          <tspan>Ausfertigung eines umfassenden </tspan>
        </tspan>
        <tspan x="96.359764" y="546.55851">
          <tspan>Protokolls als Inspektionsnachweis.</tspan>
        </tspan>
      </text>
      <text
        x="877.1618"
        y="300.33609"
        transform="translate(80.274529,-108.80453)"
      >
        <tspan x="877.1618" y="300.33609">
          <tspan>Überprüfung auf Lockerung von </tspan>
        </tspan>
        <tspan x="877.1618" y="321.3361">
          <tspan>Kaminsteinen und Kaminabdeckung.</tspan>
        </tspan>
      </text>

      <text
        transform="translate(-31.025021,-190.22629)"
        x="1039.1705"
        y="517.89893"
      >
        <tspan x="1039.1705" y="517.89893">
          <tspan>Überprüfung von Dachrinnen und Abläufen </tspan>
        </tspan>
        <tspan x="1039.1705" y="538.89897">
          <tspan>auf mögliche Verstopfungen, sowie </tspan>
        </tspan>
        <tspan x="1039.1705" y="559.89897">
          <tspan>Sichtkontrolle aller Anschlüsse und Fugen.</tspan>
        </tspan>
      </text>
    </svg>
  );
}
