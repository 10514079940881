import React, { useContext, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { TextInput } from '../../../../components/TextInput/TextInput';
import { unique } from '../../../../collections';
import Chip from '../../../../components/Chip/Chip';
import {
  ProfilVervollstaendigenDispatchContext,
  ProfilVervollstaendigenStateContext,
  useStepValidator,
  useStepGtmEvent,
} from '../ProfilVervollstaendigen';
export function Einsatzgebiete(props) {
  const { setStepValid, isValid } = props;
  const { setAktualisierterBenutzer } = useContext(
    ProfilVervollstaendigenDispatchContext
  );
  const state = useContext(ProfilVervollstaendigenStateContext);
  const [region, setRegion] = useState('');
  const handleRegionHinzufuegen = (event) => {
    event.preventDefault();
    const isValid = /^\d{3,5}$/.test(region);
    if (isValid) {
      aktualisiereBenutzer({
        ...state.aktualisierterBenutzer,
        regionen: unique([
          ...state.aktualisierterBenutzer.regionen,
          region.substring(0, 3),
        ]),
      });
      setRegion('');
    }
  };
  const createHandleRegionLoeschen = (zuLoeschendeRegion) => {
    return () => {
      aktualisiereBenutzer({
        ...state.aktualisierterBenutzer,
        regionen: state.aktualisierterBenutzer.regionen.filter(
          (r) => r !== zuLoeschendeRegion
        ),
      });
    };
  };
  const aktualisiereBenutzer = (benutzer) => {
    setStepValid(isValid({ aktualisierterBenutzer: benutzer }));
    setAktualisierterBenutzer(benutzer);
  };
  useStepValidator(props);
  useStepGtmEvent('regions');
  return (
    <>
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Einsatzgebiete
      </h2>
      <div className="profilVervollstaendigen__absatz margin:bottom:1.5rem">
        Fügen Sie die Postleitzahlengebiete hinzu, in denen Sie Ihre
        Handwerksleistung anbieten möchten. Es reicht aus, wenn Sie die ersten
        drei Ziffern der Postleitzahl eingeben. Wir schneiden die letzten beiden
        Ziffern ab.
      </div>
      <div className="profil__section profil__section--dreispaltig margin:bottom:1.5rem">
        <form
          className="profil__subsection profil__subsection--regionInput"
          onSubmit={handleRegionHinzufuegen}
        >
          <div>
            <TextInput
              value={region}
              onChange={(event) => setRegion(event.target.value.trim())}
              label="PLZ-Gebiet"
              type="number"
              placeholder="000xx"
              validierungstext={
                !/^\d{3,5}$/.test(region) && region.length > 0 && 'PLZ ungültig'
              }
            />
          </div>
          <Button
            data-cy-id="plzGebietHinzufuegenButton"
            className="margin:top:1.375rem"
            color="green"
          >
            Hinzufügen
          </Button>
        </form>
      </div>
      <div className="profil__subsection profil__subsection--regionen">
        {Array.from(state.aktualisierterBenutzer.regionen).map(
          (region, key) => (
            <Chip key={key} onRemove={createHandleRegionLoeschen(region)}>
              {region}xx
            </Chip>
          )
        )}
      </div>
    </>
  );
}
