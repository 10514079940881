import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useModalState } from '../../../hooks';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { TextArea } from '../../TextArea/TextArea';
import Rating from './Rating';
import { gebeBewertungAb, KATEGORIEN } from '../../../domain/bewertung';
import BewertungErfolgreich from '../BewertungErfolgreich';

import { ReactComponent as AlertErrorIcon } from '../../../assets/icons/alert_error_transparent.svg';

import './BewertungAbgeben.scss';

export default function BewertungAbgeben({ anfrage }) {
  const history = useHistory();
  const bewertungModalVisible =
    history.location.search && history.location.search.includes('?bewertung');
  const [
    isBewertungAbgebenModalShown,
    showBewertungAbgebenModal,
    hideBewertungAbgebenModal,
  ] = useModalState(bewertungModalVisible);

  const [bewertungErfolgreich, setBewertungErfolgreich] = useState(false);
  const [kommentar, setKommentar] = useState('');
  const [sterneBewertungen, setSterneBewertungen] = useState({});
  const [errors, setErrors] = useState([]);

  async function handleSendeBewertung(setLoading) {
    setLoading(true);

    const errors = await gebeBewertungAb({
      anfrage,
      sterneBewertungen,
      kommentar,
    });

    if (errors.length > 0) {
      setErrors(errors);
    } else {
      setBewertungErfolgreich(true);
    }

    setLoading(false);
  }

  function showModal() {
    history.push(`${history.location.pathname}?bewertung`);
    showBewertungAbgebenModal();
  }

  function hideModal() {
    history.replace(history.location.pathname.replace('/(.*)\\?/g', '$1'));
    hideBewertungAbgebenModal();
  }

  const actions = (setLoading) =>
    bewertungErfolgreich ? (
      <Button color="green" onClick={hideModal}>
        Schließen
      </Button>
    ) : (
      <>
        <Button onClick={hideModal}>Abbrechen</Button>
        <Button
          color="green"
          data-cy-id="bewertungAbgebenButton"
          onClick={() => handleSendeBewertung(setLoading)}
        >
          Bewertung abgeben
        </Button>
      </>
    );
  return (
    <>
      <Button color="lightGreen" onClick={showModal}>
        Bewertung für Fachpartner abgeben
      </Button>
      {isBewertungAbgebenModalShown && (
        <Modal
          title={`${anfrage.handwerker.firmenname} bewerten`}
          hide={hideBewertungAbgebenModal}
          className="modal--modal bewertungAbgeben"
          actions={actions}
          gaTrackingName="Bewertung abgeben"
        >
          {bewertungErfolgreich ? (
            <BewertungErfolgreich
              hideModal={hideBewertungAbgebenModal}
              text="Ihre Bewertung wird nun von uns überprüft und schon bald veröffentlicht."
            />
          ) : (
            <>
              <BewertungAbgebenFormular
                anfrage={anfrage}
                kommentar={kommentar}
                setKommentar={setKommentar}
                sterneBewertungen={sterneBewertungen}
                setSterneBewertungen={setSterneBewertungen}
              />

              {errors.length > 0 && <Errors errors={errors} />}
            </>
          )}
        </Modal>
      )}
    </>
  );
}

function Errors({ errors }) {
  return (
    <ul className="bewertungAbgeben__errorList">
      {errors.map((error, index) => {
        return (
          <li key={index} className="bewertungAbgeben__error">
            <AlertErrorIcon />
            <span>{error}</span>
          </li>
        );
      })}
    </ul>
  );
}

function BewertungAbgebenFormular({
  anfrage,
  kommentar,
  setKommentar,
  sterneBewertungen,
  setSterneBewertungen,
}) {
  return (
    <>
      <Zeile feld="Betrieb">{anfrage.handwerker.firmenname}</Zeile>
      <Zeile feld="Auftrag">{anfrage.auftrag.beschreibung}</Zeile>

      <div className="bewertungAbgeben__kategorien">
        <h3>Bewertungskategorien</h3>
        {KATEGORIEN.map((kategorie) => {
          return (
            <div key={kategorie.key} className="bewertungAbgeben__kategorie">
              <Zeile feld={kategorie.titel}>
                <Rating
                  value={sterneBewertungen[kategorie.key] || 0}
                  name={kategorie.key}
                  onChange={(anzahlSterne, key) => {
                    setSterneBewertungen((aktuellenSterneBewertungen) => {
                      return {
                        ...aktuellenSterneBewertungen,
                        [key]: anzahlSterne,
                      };
                    });
                  }}
                />
              </Zeile>
            </div>
          );
        })}
      </div>
      <div className="bewertungAbgeben__kommentar">
        <TextArea
          required
          label="Kommentar"
          placeholder="Ein schriftlicher Kommentar hilft anderen Kunden, Ihre Bewertung besser zu verstehen."
          onChange={(event) => setKommentar(event.target.value)}
          value={kommentar}
        />
      </div>
    </>
  );
}

export function Zeile({ feld, children }) {
  return (
    <div className="bewertungAbgeben__zeile">
      <label className="bewertungAbgeben__feld">{feld}:</label>
      <div className="bewertungAbgeben__wert">{children}</div>
    </div>
  );
}
