import React, { useState, useEffect } from 'react';
import LegalContent from '../../../components/LegalContent/LegalContent';
import { useHandwerkerverzeichnis } from '../../../DomainContext';

export default function ({ match }) {
  const handwerkerId = match.params.handwerkerId;

  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const [handwerker, setHandwerker] = useState(null);
  useEffect(() => {
    let didCancel = false;
    async function holeHandwerker() {
      const hw = await handwerkerverzeichnis.holeHandwerkerZuId(handwerkerId);
      if (!didCancel) {
        setHandwerker(hw);
      }
    }

    holeHandwerker();

    return () => {
      didCancel = true;
    };
  }, [handwerkerverzeichnis, handwerkerId]);

  const subdomain = window.location.host.includes('localhost:3000')
    ? 'meinzuhauseundich.de'
    : window.location.host;

  return handwerker ? (
    <LegalContent headline="Widerrufsbelehrung">
      <h2 className="legalContent__headline2">Widerrufsrecht</h2>
      <p className="legalContent__paragraph">
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen.
      </p>
      <p className="legalContent__paragraph">
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
        Vertragsabschlusses.
      </p>
      <p className="legalContent__paragraph">
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, &nbsp;
        {handwerker.firmenname}, {handwerker.strasse}, {handwerker.plz}
        &nbsp;
        {handwerker.ort}, Telefon: {handwerker.telefon}, &nbsp;
        <a href={`mailto:${handwerker.email}`}>{handwerker.email}</a>, mittels
        einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief,
        Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </p>
      <p className="legalContent__paragraph">
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
      </p>
      <br />
      <h2 className="legalContent__headline2">Folgen des Widerrufs</h2>
      <p className="legalContent__paragraph">
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
      </p>
      <p className="legalContent__paragraph">
        Haben Sie verlangt, dass die Dienstleistung während der Widerrufsfrist
        beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der
        dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits
        erbrachten Dienstleistung im Vergleich zum Gesamtumfang der im Vertrag
        vorgesehenen Dienstleistung entspricht.
      </p>
      <br />
      <p className="legalContent__paragraph">
        Hier das Muster zum{' '}
        <a
          href={`https://${subdomain}/Muster_Widerrufsformular_Handwerker.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          schriftlichen Widerruf
        </a>
        .
      </p>
    </LegalContent>
  ) : (
    <div className="kommunikationskanal__404">
      Entschuldigung, wir können die angeforderte Seite nicht finden.
    </div>
  );
}
