import React from 'react';
import { useModalState } from '../../hooks';
import { useAnfrageUndEventContext } from '../../contexts/AnfrageUndEventContext';
import { useHistory } from 'react-router-dom';

import AuftragWeiterleitenModal from '../AuftragWeiterleitenModal/AuftragWeiterleitenModal';
import Button from '../Button/Button';
import { toDateString } from '../../dateTime';

import { ReactComponent as WaitingIcon } from '../../assets/icons/waiting.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron_right.svg';
import { ReactComponent as HelpCenterIcon } from '../../assets/icons/help_center.svg';
import { ReactComponent as AlertSuccessIcon } from '../../assets/icons/alert_success.svg';

import './ConciergeService.scss';
import { CONCIERGE_SERVICE_GRUND } from '../../domain/auftraege';

export const CONCIERGE_SERVICE_CONTEXT = {
  MEINE_AUFTRAEGE: 'MEINE_AUFTRAEGE',
  KOMMUNIKATIONSKANAL: 'KOMMUNIKATIONSKANAL',
};

export default function ConciergeService({ auftrag, zeigeNurModal, context }) {
  const history = useHistory();
  const sollAuftragWeitergeleitetWerden =
    history.location.search &&
    history.location.search.includes('?auftragWeiterleiten');
  const wurdeAuftragBereitsAnConciergeServiceWeitergeleitet = Boolean(
    auftrag.anConciergeServiceWeitergeleitetAm
  );
  const [
    isConciergeServiceModalVisible,
    showConciergeServiceModal,
    hideConciergeServiceModal,
  ] = useModalState(
    sollAuftragWeitergeleitetWerden &&
      !wurdeAuftragBereitsAnConciergeServiceWeitergeleitet
  );

  const { anfragen } = useAnfrageUndEventContext();
  const anfragenZuAuftrag = anfragen.filter(
    (a) => a.anfrageAuftragId === auftrag?.id
  );

  const alleAnfragenAbgebrochen =
    anfragenZuAuftrag.every((anfrage) => anfrage.wurdeAbgebrochen()) ||
    anfragenZuAuftrag.length === 0;

  const handwerkerReagierenNicht = anfragenZuAuftrag.every(
    (anfrage) => anfrage.wurdeGesendet() && anfrage.handwerkerReagiertNicht()
  );

  if (!auftrag || (!alleAnfragenAbgebrochen && !handwerkerReagierenNicht)) {
    return null;
  }

  if (wurdeAuftragBereitsAnConciergeServiceWeitergeleitet) {
    return (
      <div
        className="conciergeService conciergeService--success"
        data-cy-id="conciergeService"
      >
        <AlertSuccessIcon className="conciergeService__icon" />
        <div className="conciergeService__content">
          <p>
            Die Weiterleitung Ihrer Anfrage am{' '}
            {toDateString(auftrag.anConciergeServiceWeitergeleitetAm)} war
            erfolgreich. Sie hören zeitnah von uns!
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {!zeigeNurModal && alleAnfragenAbgebrochen && (
        <ConciergeServiceStoererAnfragenAbgelehnt
          onClick={showConciergeServiceModal}
          auftrag={auftrag}
        />
      )}
      {!zeigeNurModal &&
        handwerkerReagierenNicht &&
        !alleAnfragenAbgebrochen && (
          <ConciergeServiceStoererKeineReaktion
            onClick={showConciergeServiceModal}
            context={context}
          />
        )}
      {isConciergeServiceModalVisible && (
        <AuftragWeiterleitenModal
          auftrag={auftrag}
          hide={hideConciergeServiceModal}
          anfrageIds={anfragenZuAuftrag.map((anfrage) => anfrage.id)}
          grund={
            alleAnfragenAbgebrochen
              ? CONCIERGE_SERVICE_GRUND.ALLE_ANFRAGEN_ZU_AUFTRAG_ABGEBROCHEN
              : CONCIERGE_SERVICE_GRUND.HANDWERKER_REAGIEREN_NICHT
          }
          gtmLabel={
            alleAnfragenAbgebrochen
              ? 'existingContract'
              : 'Meldefrist für Fachpartner abgelaufen'
          }
        />
      )}
    </>
  );
}

function ConciergeServiceStoererKeineReaktion({ onClick, context }) {
  if (context === CONCIERGE_SERVICE_CONTEXT.KOMMUNIKATIONSKANAL) {
    return (
      <div className="conciergeService" data-cy-id="conciergeService">
        <WaitingIcon className="conciergeService__icon" />
        <div className="conciergeService__content">
          <h4>
            Die angefragten Fachpartner haben sich noch nicht bei Ihnen
            gemeldet?
          </h4>
          <p>
            Leiten Sie Ihre Anfrage gern an unsere Servicemitarbeiter weiter.
            Diese werden zeitnah einen passenden Fachpartner finden und sich bei
            Ihnen melden!
          </p>
        </div>
        <div className="conciergeService__actions">
          <Button
            color="green"
            onClick={onClick}
            data-cy-id="auftragWeiterleiten"
          >
            Auftrag weiterleiten
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="conciergeService"
      data-cy-id="conciergeService"
      onClick={onClick}
    >
      <WaitingIcon className="conciergeService__icon" />
      <div className="conciergeService__content">
        <h4>Die Fachpartner reagieren nicht?</h4>
        <p>Unsere Servicemitarbeiter helfen weiter!</p>
      </div>
      <ChevronRightIcon />
    </div>
  );
}

function ConciergeServiceStoererAnfragenAbgelehnt({ onClick }) {
  return (
    <div className="conciergeService" data-cy-id="conciergeService">
      <HelpCenterIcon className="conciergeService__icon" />
      <div className="conciergeService__content">
        <h4>Die angefragten Fachpartner konnten Ihnen nicht weiterhelfen?</h4>
        <p>
          Geben Sie uns noch eine Chance! Wenn Sie Ihre Anfrage an uns
          weiterleiten, suchen unsere Servicemitarbeiter einen für Sie
          geeigneten Fachpartner außerhalb der Plattform.
        </p>
      </div>
      <div className="conciergeService__actions">
        <Button
          color="green"
          onClick={onClick}
          data-cy-id="auftragWeiterleiten"
        >
          Auftrag weiterleiten
        </Button>
      </div>
    </div>
  );
}
