import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../Button/Button';
import ProfilModal from '../../ProfilModal/ProfilModal';
import {
  useDialogState,
  useModalState,
  useKannAuftragGeloeschtWerden,
} from '../../../hooks';
import { HideAt, ShowAt } from 'react-with-breakpoints';
import AnfrageZurueckziehenOderAblehnenDialog from '../../AnfrageZurueckziehenOderAblehnenDialog/AnfrageZurueckziehenOderAblehnenDialog';
import { withBreakpoints } from 'react-with-breakpoints';
import MobileMenu from '../../MobileMenu/MobileMenu';
import { MobileMenuItem } from '../../MobileMenu/MobileMenu';
import AnfragedetailsModal from '../../AnfragedetailsModal/AnfragedetailsModal';
import AuftragLoeschenDialog from '../../AuftragLoeschenDialog/AuftragLoeschenDialog';
import AuftragNichtLoeschbarDialog from '../../AuftragNichtLoeschbarDialog/AuftragNichtLoeschbarDialog';
import Link from '../../Link/Link';

import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburger.svg';
import { ReactComponent as CancelChatIcon } from '../../../assets/icons/cancel_chat.svg';
import { ReactComponent as ChecklistIcon } from '../../../assets/icons/checklist.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { useAnfrageUndEventContext } from '../../../contexts/AnfrageUndEventContext';
import { useBenutzerContext } from '../../../contexts/BenutzerContext';

import './KommunikationskanalHeader.scss';

const AngefragteHandwerkerText = () => {
  return (
    <span className="kommunikationskanalHeader__handwerker">
      Angefragte Fachpartner
    </span>
  );
};

const KommunikationskanalHeader = withBreakpoints(
  function KommunikationskanalHeader({ anfrage, view, currentBreakpoint }) {
    const history = useHistory();
    const isRouteAnfragen = view === 'anfragen';
    const isDesktop = currentBreakpoint === 'xlarge';
    const { istHandwerker } = useBenutzerContext();
    const { anfragen } = useAnfrageUndEventContext();

    const oeffneMeineAuftraege = () => {
      if (isDesktop || isRouteAnfragen) {
        history.push('/meineAuftraege');
      } else {
        history.push(`/anfragen/${anfrage.id}`);
      }
    };

    const [
      isAuftragLoeschenDialogVisible,
      showAuftragLoeschenDialog,
      hideAuftragLoeschenDialog,
    ] = useDialogState();
    const [
      isAuftragsdetailsModalVisible,
      showAuftragsdetailsModal,
      hideAuftragsdetailsModal,
    ] = useModalState();

    const [
      isProfilModalVisible,
      showProfilModal,
      hideProfilModal,
    ] = useDialogState();

    const [
      isAuftragNichtLoeschbarDialogVisible,
      showAuftragNichtLoeschbarDialog,
      hideAuftragNichtLoeschbarDialog,
    ] = useDialogState();

    const auftragKannGeloeschtWerden = useKannAuftragGeloeschtWerden(
      anfrage,
      anfragen
    );
    const showAuftragLoeschenOderNichtLoeschbarDialog = auftragKannGeloeschtWerden
      ? showAuftragLoeschenDialog
      : showAuftragNichtLoeschbarDialog;

    const [
      isAnfrageZurueckziehenOderAblehnenDialogVisible,
      showAnfrageZurueckziehenOderAblehnenDialog,
      hideAnfrageZurueckziehenOderAblehnenDialog,
    ] = useDialogState();

    const [
      isAuftragMenuVisible,
      showAuftragMenu,
      hideAuftragMenu,
    ] = useModalState();

    return (
      <section className="kommunikationskanalHeader kommunikationskanalHeader--kunde">
        <HideAt breakpoint="xlarge">
          <Button
            onClick={oeffneMeineAuftraege}
            color="yellow"
            size="large"
            outlined
            icon={<BackIcon />}
          />
          <div className="kommunikationskanalHeader__intro">
            <span className="kommunikationskanalHeader__auftrag">
              {anfrage.auftrag.beschreibung}
            </span>
            {view === 'anfragen' && <AngefragteHandwerkerText />}
            {(view === 'nachrichten' || !view) && (
              <>
                <span className="kommunikationskanalHeader__handwerker">
                  Sie und &nbsp;
                  <Link
                    className="kommunikationskanalHeader__introLink"
                    onClick={anfrage.angebote && showProfilModal}
                  >
                    {istHandwerker
                      ? `${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`
                      : `${anfrage.handwerker.firmenname}`}
                  </Link>
                </span>
              </>
            )}
          </div>
          {view === 'anfragen' && (
            <>
              <Button
                color="yellow"
                size="large"
                outlined
                icon={<HamburgerIcon />}
                onClick={showAuftragMenu}
              />
              <MobileMenu
                isVisible={isAuftragMenuVisible}
                hide={hideAuftragMenu}
              >
                <MobileMenuItem
                  onClick={() => {
                    hideAuftragMenu();
                    showAuftragsdetailsModal();
                  }}
                  className="mobileMenu__option--checklist"
                  label="Auftragsdetails anzeigen"
                />
                <MobileMenuItem
                  onClick={() => {
                    hideAuftragMenu();
                    showAuftragLoeschenOderNichtLoeschbarDialog();
                  }}
                  className="mobileMenu__option--delete"
                  label="Auftrag löschen"
                />
              </MobileMenu>
            </>
          )}
          {(view === 'nachrichten' || !view) &&
            (anfrage.kannAnfrageZurueckgezogenOderAbgelehntWerden() ? (
              <Button
                color="yellow"
                outlined
                icon={<CancelChatIcon />}
                onClick={showAnfrageZurueckziehenOderAblehnenDialog}
              />
            ) : (
              <div className="kommunikationskanalHeader__spacer" />
            ))}
        </HideAt>

        <ShowAt breakpoint="xlarge">
          <div className="kommunikationskanalHeader__actions">
            <div className="kommunikationskanalHeader__backLinkWrapper">
              <Link
                className="kommunikationskanalHeader__backLink"
                onClick={oeffneMeineAuftraege}
                icon={<BackIcon />}
              >
                Zurück zur Übersicht
              </Link>
            </div>
            <Link
              onClick={showAuftragsdetailsModal}
              icon={<ChecklistIcon />}
              className="margin:right:1rem"
              size="small"
              color="grey"
              data-cy-id="auftragsdetails-oeffnen"
            >
              Auftragsdetails
            </Link>
            <Link
              onClick={showAuftragLoeschenOderNichtLoeschbarDialog}
              icon={<DeleteIcon />}
              size="small"
              color="grey"
              data-cy-id="auftragLoeschenLink"
            >
              Auftrag löschen
            </Link>
          </div>

          <div className="kommunikationskanalHeader__auftrag">
            Mein Auftrag:&nbsp;
            <Link
              onClick={showAuftragsdetailsModal}
              size="large"
              weight="bold"
              data-cy-id="showAutragDetailLink"
            >
              {anfrage.auftrag.beschreibung}
            </Link>
          </div>
        </ShowAt>
        {auftragKannGeloeschtWerden
          ? isAuftragLoeschenDialogVisible && (
              <AuftragLoeschenDialog
                hide={hideAuftragLoeschenDialog}
                anfrage={anfrage}
              />
            )
          : isAuftragNichtLoeschbarDialogVisible && (
              <AuftragNichtLoeschbarDialog
                hide={hideAuftragNichtLoeschbarDialog}
                anfrage={anfrage}
              />
            )}
        {isAuftragsdetailsModalVisible && (
          <AnfragedetailsModal
            hide={hideAuftragsdetailsModal}
            anfrage={anfrage}
            auftrag={anfrage.auftrag}
          />
        )}
        {isAuftragLoeschenDialogVisible && (
          <AuftragLoeschenDialog
            hide={hideAuftragLoeschenDialog}
            anfrage={anfrage}
          />
        )}
        {isProfilModalVisible && (
          <ProfilModal hide={hideProfilModal} handwerker={anfrage.handwerker} />
        )}
        {isAnfrageZurueckziehenOderAblehnenDialogVisible && (
          <AnfrageZurueckziehenOderAblehnenDialog
            hide={hideAnfrageZurueckziehenOderAblehnenDialog}
            anfrage={anfrage}
            autor={'KUNDE'}
          />
        )}
      </section>
    );
  }
);
export default KommunikationskanalHeader;
