import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const AuftragNichtLoeschbarDialog = ({ hide }) => {
  const actions = (
    <Button onClick={hide} data-cy-id="auftragLoeschenAbbrechenModalButton">
      Schließen
    </Button>
  );

  return (
    <Modal
      className="modal--dialog"
      title={'Dieses Auftrag kann nicht gelöscht werden'}
      hide={hide}
      actions={actions}
      gaTrackingName="Auftrag nicht löschbar"
    >
      <span className="modal__contentText">
        Ihr Auftrag kann nicht gelöscht werden, da es sich hierbei um einen
        offenen Auftrag handelt.
      </span>
    </Modal>
  );
};

export default AuftragNichtLoeschbarDialog;
