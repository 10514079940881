import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { currentDateTimeAsISOString, isYoungerThanDate } from '../dateTime';
import {
  unmarshallAnfrage,
  unmarshallAnfragen,
  unmarshallAngebot,
  unmarshallNachricht,
  unmarshallAuftraege,
  unmarshallRechnung,
  unmarshallAuftrag,
  unmarshallEvents,
  unmarshallNachrichten,
  unmarshallObject,
} from './marshall';
import { uploadFile, toS3Object } from './uploadFile';
import { recursiveQuery } from './query';

/**
 * Die Benamung der Fremdschlüssel ist davon abhängig, ob eine One-to-Many- oder eine One-to-One-Relation vorliegt.
 *
 * Bei einer One-To-Many-Relation (z.B. Anfrage -> Nachrichten) hat das Model des Listeneintrags einen Fremdschlüssel
 * auf das Model, das die Liste enthält. Der Name setzt sich wie folgt zusammen: [parent][Children]Id
 *
 * Bei einer One-To-One-Relation (z.B. Nachricht -> Angebot) hat das Model des Kindelements einen Fremdschlüssel auf
 * das Model, das auf das Kindelement verweist. Der Name setzt sich wie folgt zusammen: [child][Parent]Id
 */
export const ANFRAGE_AUFTRAG_FOREIGN_KEY = 'anfrageAuftragId';
export const ANFRAGE_NACHRICHTEN_FOREIGN_KEY = 'anfrageNachrichtenId';
export const ANFRAGE_ANGEBOTE_FOREIGN_KEY = 'anfrageAngeboteId';
export const ANFRAGE_RECHNUNGEN_FOREIGN_KEY = 'anfrageRechnungenId';
export const NACHRICHT_ANGEBOT_FOREIGN_KEY = 'nachrichtAngebotId';
export const NACHRICHT_RECHNUNG_FOREIGN_KEY = 'nachrichtRechnungId';
export const AUFTRAG_KUNDE_FOREIGN_KEY = 'auftragKundeId';
export const ANFRAGE_HANDWERKER_FOREIGN_KEY = 'anfrageHandwerkerId';

export default class AuftragRepository {
  async holeAuftraege() {
    const { listAuftraege } = await recursiveQuery({
      query: queries.listAuftraege,
      variables: {},
    });

    return unmarshallAuftraege(listAuftraege);
  }

  async holeAnfragen() {
    const { listAnfragen } = await recursiveQuery({
      query: queries.listAnfragen,
      variables: {},
    });

    return unmarshallAnfragen(listAnfragen);
  }

  async holeAnfrageZuId(anfrageId) {
    const {
      data: { getAnfrage },
    } = await API.graphql(
      graphqlOperation(queries.getAnfrage, {
        id: anfrageId,
      })
    );

    return unmarshallAnfrage(getAnfrage);
  }

  async holeAnfragenZuAuftragId(auftragId) {
    const { listAnfragen } = await recursiveQuery({
      query: queries.listAnfragen,
      variables: {
        filter: {
          [ANFRAGE_AUFTRAG_FOREIGN_KEY]: {
            eq: auftragId,
          },
        },
      },
    });

    return unmarshallAnfragen(listAnfragen);
  }

  async holeAnfragenZuHandwerkerId(handwerkerId) {
    const { listAnfragen } = await recursiveQuery({
      query: queries.listAnfragen,
      variables: {
        filter: {
          [ANFRAGE_HANDWERKER_FOREIGN_KEY]: {
            eq: handwerkerId,
          },
        },
      },
    });

    return unmarshallAnfragen(listAnfragen);
  }

  async holeAuftraegeZuKundeId(kundeId) {
    const { listAuftraege } = await recursiveQuery({
      query: queries.listAuftraege,
      variables: {
        filter: {
          [AUFTRAG_KUNDE_FOREIGN_KEY]: {
            eq: kundeId,
          },
        },
      },
    });

    return unmarshallAuftraege(listAuftraege);
  }

  async holeNachrichtZuId(nachrichtId) {
    const {
      data: { getNachrichten },
    } = await API.graphql(
      graphqlOperation(queries.getNachrichten, {
        id: nachrichtId,
      })
    );

    return unmarshallNachricht(getNachrichten);
  }

  async holeEventsZuAnfrageId(anfrageId) {
    const { eventsByAnfrage } = await recursiveQuery({
      query: queries.eventsByAnfrage,
      variables: {
        anfrageId,
      },
    });

    return unmarshallEvents(eventsByAnfrage);
  }

  async holeEventsZuAnfrageNach(anfrage, isoDatum) {
    const eventsOlderThan = (isoString) => (event) =>
      isYoungerThanDate(event.createdAt, isoString);

    return (await this.holeEventsZuAnfrageId(anfrage.id)).filter(
      eventsOlderThan(isoDatum)
    );
  }

  async holeNachrichtenZuAnfrageId(anfrageId) {
    const { listNachrichten } = await recursiveQuery({
      query: queries.listNachrichten,
      variables: {
        filter: {
          [ANFRAGE_NACHRICHTEN_FOREIGN_KEY]: {
            eq: anfrageId,
          },
        },
      },
    });
    return unmarshallNachrichten(listNachrichten);
  }

  async erzeugeAuftragUndAnfragen(auftrag, kunde, handwerkerliste) {
    const anhaenge = [];

    const fotosGrundriss = auftrag.spezifikation['Fotos, Grundriss'] || [];
    for (const datei of fotosGrundriss) {
      const { key, fileName, mimeType } = await uploadFile(datei);
      const hochgeladeneDatei = toS3Object(key, fileName, mimeType);
      anhaenge.push({
        datei: hochgeladeneDatei,
        typ: hochgeladeneDatei.mimeType === 'application/pdf' ? 'PDF' : 'FOTO',
      });
    }

    const auftragMitAnhaengen = {
      ...auftrag,
      anhaenge,
      spezifikation: { ...auftrag.spezifikation },
    };
    delete auftragMitAnhaengen.spezifikation['Fotos, Grundriss'];

    const {
      data: { executeCommand },
    } = await API.graphql(
      graphqlOperation(mutations.executeCommand, {
        command: 'erzeugeAuftragUndAnfragen',
        arguments: JSON.stringify({
          auftrag: auftragMitAnhaengen,
          kunde,
          handwerkerliste,
        }),
      })
    );

    const parsedExecuteCommand = unmarshallObject(executeCommand);
    return {
      auftrag: unmarshallAuftrag(parsedExecuteCommand.auftrag),
      anfragen: unmarshallAnfragen(parsedExecuteCommand.anfragen),
    };
  }

  async nimmAngebotAn(anfrage, angebot) {
    const {
      data: { executeCommand },
    } = await API.graphql(
      graphqlOperation(mutations.executeCommand, {
        command: 'nimmAngebotAn',
        arguments: JSON.stringify({ angebot, anfrage }),
      })
    );
    return unmarshallAngebot(unmarshallObject(executeCommand));
  }

  async aktualisiereAnfrage(anfrage) {
    const input = { ...anfrage, updatedAt: currentDateTimeAsISOString() };
    delete input.angebote;
    delete input.rechnungen;
    delete input.nachrichten;
    delete input.auftrag;
    delete input.handwerker;
    const {
      data: { updateAnfrage },
    } = await API.graphql(graphqlOperation(mutations.updateAnfrage, { input }));

    return unmarshallAnfrage(updateAnfrage);
  }

  async aktualisiereAuftrag(auftrag) {
    const input = {
      ...auftrag,
      spezifikation: JSON.stringify(auftrag.spezifikation),
    };
    delete input.kunde;

    const {
      data: { updateAuftrag },
    } = await API.graphql(graphqlOperation(mutations.updateAuftrag, { input }));

    return unmarshallAuftrag(updateAuftrag);
  }

  async fuegeNachrichtHinzu(
    anfrage,
    nachricht,
    datei = undefined,
    angebot = undefined,
    rechnung = undefined
  ) {
    let anhang = null;

    if (datei) {
      const { key, fileName, mimeType } = await uploadFile(datei);
      const typ =
        angebot !== undefined
          ? 'ANGEBOT'
          : rechnung !== undefined
          ? 'RECHNUNG'
          : mimeType === 'application/pdf'
          ? 'PDF'
          : 'FOTO';

      anhang = {
        typ,
        datei: toS3Object(key, fileName, mimeType),
      };
    }

    const berechtigungen =
      nachricht.autor === 'KUNDE'
        ? {
            gesendetVon: anfrage.auftrag.kunde.id,
            gesendetAn: anfrage.handwerker.id,
          }
        : {
            gesendetVon: anfrage.handwerker.id,
            gesendetAn: anfrage.auftrag.kunde.id,
          };

    const input = {
      ...nachricht,
      [ANFRAGE_NACHRICHTEN_FOREIGN_KEY]: anfrage.id,
      ...(angebot ? { [NACHRICHT_ANGEBOT_FOREIGN_KEY]: angebot.id } : {}),
      ...(rechnung ? { [NACHRICHT_RECHNUNG_FOREIGN_KEY]: rechnung.id } : {}),
      anhang,
      ...berechtigungen,
    };
    const {
      data: { createNachricht },
    } = await API.graphql(
      graphqlOperation(mutations.createNachricht, { input })
    );

    return unmarshallNachricht(createNachricht);
  }

  async sendeAngebot(anfrage, datei, netto, brutto, status) {
    const { key, fileName, mimeType } = await uploadFile(datei);

    const angebot = {
      netto,
      brutto,
      anhang: {
        typ: 'ANGEBOT',
        datei: toS3Object(key, fileName, mimeType),
      },
      status,
      [ANFRAGE_ANGEBOTE_FOREIGN_KEY]: anfrage.id,
      gesendetVon: anfrage.handwerker.id,
      gesendetAn: anfrage.auftrag.kunde.id,
    };

    const {
      data: { executeCommand },
    } = await API.graphql(
      graphqlOperation(mutations.executeCommand, {
        command: 'sendeAngebot',
        arguments: JSON.stringify({ angebot, anfrage }),
      })
    );
    return unmarshallAngebot(unmarshallObject(executeCommand));
  }

  async sendeRechnung(anfrage, datei, netto, brutto) {
    const { key, fileName, mimeType } = await uploadFile(datei);

    const anhang = {
      typ: 'RECHNUNG',
      datei: toS3Object(key, fileName, mimeType),
    };

    const {
      data: { createRechnung },
    } = await API.graphql(
      graphqlOperation(mutations.createRechnung, {
        input: {
          netto,
          brutto,
          anhang,
          [ANFRAGE_RECHNUNGEN_FOREIGN_KEY]: anfrage.id,
          gesendetVon: anfrage.handwerker.id,
          gesendetAn: anfrage.auftrag.kunde.id,
        },
      })
    );
    return unmarshallRechnung(createRechnung);
  }

  async aktualisiereAngebot(angebot) {
    const input = { ...angebot };
    delete input.anfrage;
    delete input.anhang;

    const {
      data: { updateAngebot },
    } = await API.graphql(graphqlOperation(mutations.updateAngebot, { input }));

    return unmarshallAngebot(updateAngebot);
  }

  async aktualisiereNachricht(nachricht) {
    const {
      data: { updateNachricht },
    } = await API.graphql(
      graphqlOperation(mutations.updateNachricht, { input: { ...nachricht } })
    );

    return unmarshallNachricht(updateNachricht);
  }

  async loescheAuftrag(auftrag) {
    await API.graphql(
      graphqlOperation(mutations.executeCommand, {
        command: 'loescheAuftrag',
        arguments: JSON.stringify({
          auftrag: auftrag,
        }),
      })
    );
  }
}
