import React, { useContext } from 'react';
import Handwerkerverzeichnis from './domain/handwerkerverzeichnis';
import Auftraege from './domain/auftraege';
import AuftragRepository from './persistence/auftragRepository';
import Kundenverzeichnis from './domain/kundenverzeichnis';
import KundeRepositoryImpl from './persistence/kundeRepositoryImpl';
import HandwerkerRepositoryImpl from './persistence/handwerkerRepositoryImpl';
import EventDispatcherImpl from './persistence/eventDispatcherImpl';

const auftragRepository = new AuftragRepository();

const eventDispatcher = new EventDispatcherImpl();

const auftraege = new Auftraege(auftragRepository, eventDispatcher);

const handwerkerverzeichnis = new Handwerkerverzeichnis(
  auftraege,
  new HandwerkerRepositoryImpl(),
  eventDispatcher
);

const kundenverzeichnis = new Kundenverzeichnis(
  auftraege,
  new KundeRepositoryImpl(),
  eventDispatcher
);

export const domain = {
  handwerkerverzeichnis,
  kundenverzeichnis,
  auftraege,
  eventDispatcher,
};

const DomainContext = React.createContext(domain);

export function useDomainContext() {
  return useContext(DomainContext);
}

export function useHandwerkerverzeichnis() {
  return useDomainContext().handwerkerverzeichnis;
}

export function useKundenverzeichnis() {
  return useDomainContext().kundenverzeichnis;
}

export function useAuftraege() {
  return useDomainContext().auftraege;
}

export function useEventDispatcher() {
  return useDomainContext().eventDispatcher;
}

export default DomainContext;
