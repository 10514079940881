import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  istDatenschutzAlsPdfOderLinkUngespeichertVorhanden,
  istImpressumAlsTextOderLinkVorhanden,
  istProfilVollstaendig,
  sindEinsatzgebieteVorhanden,
  sindFirmendatenGefuellt,
  sindLeistungenVorhanden,
  sindNurValideLinksVorhanden,
} from '../../../components/ProfilModal/validierung';
import { useHandwerkerverzeichnis } from '../../../DomainContext';
import { s3ObjectsToFileContainers } from '../../../components/Dropzone/Dropzone';
import { useHistory } from 'react-router-dom';
import { gtmEvent } from '../../../googleAnalytics';
import { useBenutzerContext } from '../../../contexts/BenutzerContext';

import { Wizard } from './Wizard';
import { Intro } from './Steps/StepIntro';
import { Firmendaten } from './Steps/StepFirmendaten';
import { ProfilVervollstaendigenTaetigkeiten } from './Steps/StepProfilVervollstaendigenTaetigkeiten';
import { Einsatzgebiete } from './Steps/StepEinsatzgebiete';
import { Rechtliches } from './Steps/StepRechtliches';
import { ServiceLevel } from './Steps/StepServiceLevel';
import { Fertig } from './Steps/StepFertig';
import './ProfilVervollstaendigen.scss';

export const ProfilVervollstaendigenStateContext = createContext();
export const ProfilVervollstaendigenDispatchContext = createContext();

export function useStepValidator({
  setStepValid,
  isValid,
  canBeSkipped = false,
}) {
  const state = useContext(ProfilVervollstaendigenStateContext);
  useEffect(() => {
    setStepValid(canBeSkipped ? true : isValid(state));
  }, [canBeSkipped, isValid, setStepValid, state]);
}

export function useStepGtmEvent(label) {
  useEffect(() => {
    gtmEvent({ category: 'craftsmanProfile', action: 'stepOpened', label });
  }, [label]);
}

function profilVervollstaendigenReducer(
  state,
  {
    type,
    aktualisierterBenutzer,
    datenschutzbestimmungenFileContainers,
    firmenlogoFileContainers,
  }
) {
  if (type === 'BENUTZER_AKTUALISIERT') {
    return {
      ...state,
      aktualisierterBenutzer,
    };
  } else if (type === 'DATENSCHUTZBESTIMMUNGEN_AKTUALISIERT') {
    return {
      ...state,
      datenschutzbestimmungenFileContainers,
    };
  } else if (type === 'FIRMENLOGO_AKTUALISIERT') {
    return {
      ...state,
      firmenlogoFileContainers,
    };
  }
  return state;
}

export default function ProfilVervollstaendigen() {
  const history = useHistory();
  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const { benutzer: handwerker } = useBenutzerContext();

  const oeffneMeineAuftraege = () => history.push('/');

  const [state, dispatch] = useReducer(profilVervollstaendigenReducer, {
    aktualisierterBenutzer: handwerker,
    datenschutzbestimmungenFileContainers:
      handwerker && handwerker.datenschutzbestimmungen
        ? s3ObjectsToFileContainers([handwerker.datenschutzbestimmungen])
        : [],
    firmenlogoFileContainers:
      handwerker && handwerker.firmenlogo
        ? s3ObjectsToFileContainers([handwerker.firmenlogo])
        : [],
  });

  useEffect(() => {
    setFirmenlogoFileContainers(
      handwerker && handwerker.firmenlogo
        ? s3ObjectsToFileContainers([handwerker.firmenlogo])
        : []
    );
  }, [handwerker]);

  function setAktualisierterBenutzer(aktualisierterBenutzer) {
    dispatch({
      type: 'BENUTZER_AKTUALISIERT',
      aktualisierterBenutzer,
    });
  }

  function setDatenschutzbestimmungenFileContainers(
    datenschutzbestimmungenFileContainers
  ) {
    dispatch({
      type: 'DATENSCHUTZBESTIMMUNGEN_AKTUALISIERT',
      datenschutzbestimmungenFileContainers,
    });
  }

  function setFirmenlogoFileContainers(firmenlogoFileContainers) {
    dispatch({
      type: 'FIRMENLOGO_AKTUALISIERT',
      firmenlogoFileContainers,
    });
  }

  useEffect(() => {
    if (istProfilVollstaendig(handwerker)) {
      oeffneMeineAuftraege();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handwerker.id]);

  const speichereBenutzer = async () => {
    const aktualisierterHandwerker = await handwerkerverzeichnis.aktualisiereHandwerker(
      {
        urspruenglicherHandwerker: handwerker,
        aktualisierterHandwerker: state.aktualisierterBenutzer,
        datenschutzbestimmungenFileContainers:
          state.datenschutzbestimmungenFileContainers,
        firmenlogoFileContainers: state.firmenlogoFileContainers,
      }
    );

    if (istProfilVollstaendig(aktualisierterHandwerker)) {
      await handwerkerverzeichnis.setzeProfilVollstaendig(handwerker.id);
      gtmEvent({
        category: 'craftsmanProfile',
        action: 'completed',
      });
    }
  };

  const steps = [
    {
      component: Intro,
      //hat condition vom zweiten step
      isValid: ({ aktualisierterBenutzer }) =>
        sindFirmendatenGefuellt(aktualisierterBenutzer),
      canBeSkipped: true,
    },
    {
      component: Firmendaten,
      isValid: ({ aktualisierterBenutzer }) =>
        sindFirmendatenGefuellt(aktualisierterBenutzer),
    },
    {
      component: ProfilVervollstaendigenTaetigkeiten,
      isValid: ({ aktualisierterBenutzer }) =>
        sindLeistungenVorhanden(aktualisierterBenutzer),
    },
    {
      component: Einsatzgebiete,
      isValid: ({ aktualisierterBenutzer }) =>
        sindEinsatzgebieteVorhanden(aktualisierterBenutzer),
    },
    {
      component: Rechtliches,
      isValid: ({
        aktualisierterBenutzer,
        datenschutzbestimmungenFileContainers,
      }) =>
        istDatenschutzAlsPdfOderLinkUngespeichertVorhanden(
          aktualisierterBenutzer,
          datenschutzbestimmungenFileContainers
        ) &&
        istImpressumAlsTextOderLinkVorhanden(aktualisierterBenutzer) &&
        sindNurValideLinksVorhanden(aktualisierterBenutzer, false),
    },
    {
      component: ServiceLevel,
      canBeSkipped: true,
    },
    {
      component: Fertig,
      canBeSkipped: true,
    },
  ];

  const getIntialStep = () => {
    return steps.findIndex((step) => step.isValid && !step.isValid(state)) || 0;
  };

  return (
    <ProfilVervollstaendigenStateContext.Provider value={state}>
      <ProfilVervollstaendigenDispatchContext.Provider
        value={{
          setAktualisierterBenutzer,
          setDatenschutzbestimmungenFileContainers,
          setFirmenlogoFileContainers,
        }}
      >
        <div className="profilVervollstaendigen">
          <Wizard
            initialStep={getIntialStep()}
            onStepChange={speichereBenutzer}
            onFinish={oeffneMeineAuftraege}
          >
            {steps.map((step, key) => (
              <Step key={key} {...step} />
            ))}
          </Wizard>
        </div>
      </ProfilVervollstaendigenDispatchContext.Provider>
    </ProfilVervollstaendigenStateContext.Provider>
  );
}

function Step({ component: Component, setStepValid, ...props }) {
  const setStepValidInternal = useCallback(setStepValid, []);

  return <Component setStepValid={setStepValidInternal} {...props} />;
}

export function istEinOptionalesFeldGefuellt(benutzer) {
  return (
    benutzer.mobil ||
    benutzer.telefonischeErreichbarkeit ||
    benutzer.webseite ||
    benutzer.firmengruendung ||
    benutzer.anzahlMitarbeiter ||
    benutzer.firmenlogo
  );
}
