import { EGAL_WELCHER_SCHRITT } from './konstanten';

export const istWertGesetzt = (spezifikation, attribut) => {
  const istWertImArrayGesetzt = Array.isArray(spezifikation[attribut])
    ? spezifikation[attribut].length > 0
    : Boolean(spezifikation[attribut]);

  return Array.isArray(spezifikation[attribut])
    ? spezifikation[attribut].includes('Sonstige')
      ? istWertImArrayGesetzt && spezifikation[String(attribut).concat('Text')]
      : spezifikation[attribut].length > 0
    : spezifikation[attribut] === 'Sonstiges'
    ? istWertImArrayGesetzt && spezifikation[String(attribut).concat('Text')]
    : istWertImArrayGesetzt;
};

export const istWertDefault = (spezifikation, attribut) => {
  return Array.isArray(spezifikation[attribut])
    ? false
    : spezifikation[attribut] === 'Bitte auswählen';
};

export const sindWerteGesetzt = (spezifikation, attribute) =>
  !attribute.some((a) => !istWertGesetzt(spezifikation, a));

export const erforderlich = (schrittOderEingabe) => ({
  ...schrittOderEingabe,
  istVollstaendig: (spez) =>
    istWertGesetzt(spez, schrittOderEingabe.attribut) &&
    !istWertDefault(spez, schrittOderEingabe.attribut),
  optional: false,
});

export const optional = (schrittOderEingabe) => ({
  ...schrittOderEingabe,
  istVollstaendig: () => true,
  optional: true,
});

export const leerraum = (gruppenIndex) =>
  optional({
    typ: 'leerraum',
    gruppenIndex,
  });

export const istSchritt = (erwarteterSchrittIndex) => (schrittIndex) =>
  schrittIndex === EGAL_WELCHER_SCHRITT ||
  schrittIndex === erwarteterSchrittIndex;

export const hatWerte = (...wertepaare) => (schrittIndex, spezifikation) =>
  wertepaare.every(([attribut, wert]) =>
    Array.isArray(spezifikation[attribut])
      ? spezifikation[attribut].includes(wert)
      : spezifikation[attribut] === wert
  );

export const und = (...bedingungen) => (schrittIndex, spezifikation) =>
  bedingungen.every((bedingung) => bedingung(schrittIndex, spezifikation));

export const oder = (...bedingungen) => (schrittIndex, spezifikation) =>
  bedingungen.some((bedingung) => bedingung(schrittIndex, spezifikation));

export function bereich(value) {
  return ['bereich', value];
}

export function objektart(value) {
  return ['objektart', value];
}

export function taetigkeit(value) {
  return ['taetigkeit', value];
}

export function teiltaetigkeit(value) {
  return ['teiltaetigkeit', value];
}
