import { useEffect, useState } from 'react';
import SCHRITTE from '../../../domain/schritte';
import {
  KATEGORIE_INDEX_TAETIGKEITEN,
  SPEZIFIKATIONS_ZUSTAND_KEY,
} from '../../../domain/schritte/konstanten';
import { useClickOnce } from '../../../hooks';
import xss from 'xss';
import bereinigeSpezifikation from './bereinigeSpezifikation';
import { gtmEvent } from '../../../googleAnalytics';
import { useAuftraege } from '../../../DomainContext';
import { zuBeschreibung } from '../../../domain/gewerk';
import { useBenutzerContext } from '../../../contexts/BenutzerContext';
import localforage from 'localforage';

export function useSpezifikation() {
  const [isLoading, setLoading] = useState(false);
  const [
    spezifikationsassistentState,
    setSpezifikationsassistentState,
  ] = useState({
    spezifikation: {},
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    schrittIndex: 0,
  });

  useEffect(() => {
    let didCancel = false;
    const loadState = async () => {
      if (!didCancel) {
        setLoading(true);
      }
      const data = await getSpezifikationsAssistentStateFromStorage();
      if (!didCancel) {
        if (data) {
          setSpezifikationsassistentState(data);
        }
        setLoading(false);
      }
    };

    loadState();

    return () => {
      didCancel = true;
    };
  }, []);

  const persistState = (data) => {
    nonWaitingPersistState(data);
    setSpezifikationsassistentState(data);
  };

  const waehleWert = (attribut, wert) => {
    const options = {
      stripIgnoreTag: true,
      escapeHtml: (html) => html,
    };

    setSpezifikationsassistentState((currentState) => {
      const neueSpezifikation = {
        ...currentState.spezifikation,
        [attribut]: typeof wert === 'string' ? xss(wert, options) : wert,
      };
      return {
        ...currentState,
        spezifikation: neueSpezifikation,
      };
    });
  };

  let waehleSofortauswahlwert;

  waehleSofortauswahlwert = (attribut, wert) => {
    const neueSpezifikation = {
      ...spezifikationsassistentState.spezifikation,
      [attribut]: wert,
    };

    const naechsterSchritt = SCHRITTE.find((schritt) =>
      schritt.bedingung(
        spezifikationsassistentState.schrittIndex + 1,
        neueSpezifikation
      )
    );
    const naechsterKategorieIndex =
      naechsterSchritt && naechsterSchritt.kategorieIndex;

    persistState({
      ...spezifikationsassistentState,
      spezifikation: neueSpezifikation,
      schrittIndex: spezifikationsassistentState.schrittIndex + 1,
      kategorieIndex: naechsterKategorieIndex,
    });
  };

  const waehleMehrfachauswahlwert = (attribut, wert, istAusgewaehlt) => {
    const neueSpezifikation = { ...spezifikationsassistentState.spezifikation };
    if (!neueSpezifikation[attribut]) {
      neueSpezifikation[attribut] = [];
    }
    if (istAusgewaehlt) {
      neueSpezifikation[attribut].push(wert);
    } else {
      neueSpezifikation[attribut] = neueSpezifikation[attribut].filter(
        (w) => w !== wert
      );
    }

    persistState({
      ...spezifikationsassistentState,
      spezifikation: neueSpezifikation,
    });
  };

  const springeZuKategorieIndex = (anzusteuernderKategorieIndex) => {
    const berechneterSchrittIndex = berechneErstenSchrittIndexZuKategorieIndex(
      anzusteuernderKategorieIndex,
      spezifikationsassistentState.schrittIndex,
      spezifikationsassistentState.spezifikation
    );

    if (berechneterSchrittIndex !== null) {
      persistState({
        ...spezifikationsassistentState,
        schrittIndex: berechneterSchrittIndex,
        kategorieIndex: anzusteuernderKategorieIndex,
      });
    }
  };

  const vorherigerSchritt = SCHRITTE.find((schritt) =>
    schritt.bedingung(
      Math.max(0, spezifikationsassistentState.schrittIndex - 1),
      spezifikationsassistentState.spezifikation
    )
  );
  const schritt = SCHRITTE.find((s) =>
    s.bedingung(
      spezifikationsassistentState.schrittIndex,
      spezifikationsassistentState.spezifikation
    )
  );
  const naechsterSchritt = SCHRITTE.find((schritt) =>
    schritt.bedingung(
      spezifikationsassistentState.schrittIndex + 1,
      spezifikationsassistentState.spezifikation
    )
  );

  const vorherigerKategorieIndex =
    vorherigerSchritt && vorherigerSchritt.kategorieIndex;

  const naechsterKategorieIndex =
    naechsterSchritt && naechsterSchritt.kategorieIndex;

  const clearState = () => {
    nonWaitingClearState();
  };

  return [
    spezifikationsassistentState.spezifikation,
    waehleWert,
    waehleSofortauswahlwert,
    waehleMehrfachauswahlwert,
    spezifikationsassistentState.kategorieIndex,
    vorherigerKategorieIndex,
    naechsterKategorieIndex,
    springeZuKategorieIndex,
    spezifikationsassistentState.schrittIndex,
    schritt,
    persistState,
    clearState,
    isLoading,
  ];
}

function berechneErstenSchrittIndexZuKategorieIndex(
  anzusteuernderKategorieIndex,
  schrittIndex,
  spezifikation
) {
  const moeglicheSchritte = [];
  for (let i = 0; i <= schrittIndex; i++) {
    moeglicheSchritte.push(
      ...SCHRITTE.filter(
        (s) =>
          s.bedingung(i, spezifikation) &&
          anzusteuernderKategorieIndex === s.kategorieIndex
      ).map((_) => i)
    );
  }

  if (moeglicheSchritte.length > 0) {
    return moeglicheSchritte.sort()[0];
  }
  return null;
}

export function useAusgewaehlteHandwerker() {
  const [ausgewaehlteHandwerker, setAusgewaehlteHandwerker] = useState([]);

  const waehleHandwerkerAus = (handwerker, istAusgewaehlt) => {
    if (istAusgewaehlt) {
      setAusgewaehlteHandwerker([...ausgewaehlteHandwerker, handwerker]);
      gtmEvent({
        category: 'assistant',
        action: 'craftsmanSelected',
      });
    } else {
      setAusgewaehlteHandwerker(
        ausgewaehlteHandwerker.filter((hw) => hw.id !== handwerker.id)
      );
    }
  };

  return [ausgewaehlteHandwerker, waehleHandwerkerAus];
}

export function useSendeAnfrage(
  spezifikation,
  ausgewaehlteHandwerker,
  gefundeneHandwerker,
  schrittIndex,
  history,
  clearState
) {
  const auftraege = useAuftraege();
  const { benutzer: kunde } = useBenutzerContext();

  return useClickOnce(async () => {
    const bereinigteSpezifikation = bereinigeSpezifikation(spezifikation);
    const auftrag = {
      beschreibung: zuBeschreibung(spezifikation),
      ausfuehrungszeitraum: bereinigteSpezifikation.ausfuehrungszeitraum,
      strasse: bereinigteSpezifikation['Straße und Hausnummer'],
      plz: bereinigteSpezifikation['PLZ'],
      ort: bereinigteSpezifikation['Ort'],
      spezifikation: bereinigteSpezifikation,
    };

    if (gefundeneHandwerker.length === 0) {
      await auftraege.sendeAuftragOhneGefundeneHandwerkerAnConcierge(
        auftrag,
        kunde
      );

      gtmEvent({
        category: 'request',
        action: 'sentToConciergeService',
        label: 'craftsmenSearch',
      });
      clearState();
      history.replace('/meineAuftraege?auftragAnConciergeGesendet');
    } else {
      await auftraege.legeAuftragUndAnfragenAn(
        auftrag,
        kunde,
        ausgewaehlteHandwerker
      );

      ausgewaehlteHandwerker.forEach(() =>
        gtmEvent({
          category: 'request',
          action: 'amountRequests',
        })
      );

      gtmEvent({
        category: 'request',
        action: 'amountFilesUploaded',
        value: Array.isArray(bereinigteSpezifikation['Fotos, Grundriss'])
          ? bereinigteSpezifikation['Fotos, Grundriss'].length
          : 0,
      });
      clearState();
      history.replace('/meineAuftraege?anfragenGesendet');
    }
  }, [schrittIndex]);
}

function nonWaitingClearState() {
  localforage.removeItem(SPEZIFIKATIONS_ZUSTAND_KEY);
}

function nonWaitingPersistState(data) {
  localforage.setItem(SPEZIFIKATIONS_ZUSTAND_KEY, data);
}

async function getSpezifikationsAssistentStateFromStorage() {
  return localforage.getItem(SPEZIFIKATIONS_ZUSTAND_KEY);
}
