/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fireEvents = /* GraphQL */ `
  mutation FireEvents($events: [AWSJSON]) {
    fireEvents(events: $events)
  }
`;
export const executeCommand = /* GraphQL */ `
  mutation ExecuteCommand($command: String, $arguments: AWSJSON) {
    executeCommand(command: $command, arguments: $arguments)
  }
`;
export const bestimmeFrist = /* GraphQL */ `
  mutation BestimmeFrist($event: AWSJSON) {
    bestimmeFrist(event: $event)
  }
`;
export const clear = /* GraphQL */ `
  mutation Clear {
    clear
  }
`;
export const executeCommandKundenberater = /* GraphQL */ `
  mutation ExecuteCommandKundenberater($command: String, $arguments: AWSJSON) {
    executeCommandKundenberater(command: $command, arguments: $arguments)
  }
`;
export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($email: String) {
    forgotPassword(email: $email)
  }
`;
export const createAuftrag = /* GraphQL */ `
  mutation CreateAuftrag($input: CreateAuftragInput!) {
    createAuftrag(input: $input) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateAuftrag = /* GraphQL */ `
  mutation UpdateAuftrag($input: UpdateAuftragInput!) {
    updateAuftrag(input: $input) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteAuftrag = /* GraphQL */ `
  mutation DeleteAuftrag($input: DeleteAuftragInput!) {
    deleteAuftrag(input: $input) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createAnfrage = /* GraphQL */ `
  mutation CreateAnfrage($input: CreateAnfrageInput!) {
    createAnfrage(input: $input) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const updateAnfrage = /* GraphQL */ `
  mutation UpdateAnfrage($input: UpdateAnfrageInput!) {
    updateAnfrage(input: $input) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const deleteAnfrage = /* GraphQL */ `
  mutation DeleteAnfrage($input: DeleteAnfrageInput!) {
    deleteAnfrage(input: $input) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const createAngebot = /* GraphQL */ `
  mutation CreateAngebot($input: CreateAngebotInput!) {
    createAngebot(input: $input) {
      id
      createdAt
      updatedAt
      netto
      brutto
      annahmedatum
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      status
      anfrageAngeboteId
      gesendetVon
      gesendetAn
    }
  }
`;
export const updateAngebot = /* GraphQL */ `
  mutation UpdateAngebot($input: UpdateAngebotInput!) {
    updateAngebot(input: $input) {
      id
      createdAt
      updatedAt
      netto
      brutto
      annahmedatum
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      status
      anfrageAngeboteId
      gesendetVon
      gesendetAn
    }
  }
`;
export const deleteAngebot = /* GraphQL */ `
  mutation DeleteAngebot($input: DeleteAngebotInput!) {
    deleteAngebot(input: $input) {
      id
      createdAt
      updatedAt
      netto
      brutto
      annahmedatum
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      status
      anfrageAngeboteId
      gesendetVon
      gesendetAn
    }
  }
`;
export const createRechnung = /* GraphQL */ `
  mutation CreateRechnung($input: CreateRechnungInput!) {
    createRechnung(input: $input) {
      id
      createdAt
      netto
      brutto
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageRechnungenId
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const updateRechnung = /* GraphQL */ `
  mutation UpdateRechnung($input: UpdateRechnungInput!) {
    updateRechnung(input: $input) {
      id
      createdAt
      netto
      brutto
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageRechnungenId
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const deleteRechnung = /* GraphQL */ `
  mutation DeleteRechnung($input: DeleteRechnungInput!) {
    deleteRechnung(input: $input) {
      id
      createdAt
      netto
      brutto
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageRechnungenId
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const createNachricht = /* GraphQL */ `
  mutation CreateNachricht($input: CreateNachrichtInput!) {
    createNachricht(input: $input) {
      id
      createdAt
      autor
      text
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageNachrichtenId
      gelesen
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const updateNachricht = /* GraphQL */ `
  mutation UpdateNachricht($input: UpdateNachrichtInput!) {
    updateNachricht(input: $input) {
      id
      createdAt
      autor
      text
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageNachrichtenId
      gelesen
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const deleteNachricht = /* GraphQL */ `
  mutation DeleteNachricht($input: DeleteNachrichtInput!) {
    deleteNachricht(input: $input) {
      id
      createdAt
      autor
      text
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageNachrichtenId
      gelesen
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const createKunde = /* GraphQL */ `
  mutation CreateKunde($input: CreateKundeInput!) {
    createKunde(input: $input) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateKunde = /* GraphQL */ `
  mutation UpdateKunde($input: UpdateKundeInput!) {
    updateKunde(input: $input) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteKunde = /* GraphQL */ `
  mutation DeleteKunde($input: DeleteKundeInput!) {
    deleteKunde(input: $input) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createHandwerker = /* GraphQL */ `
  mutation CreateHandwerker($input: CreateHandwerkerInput!) {
    createHandwerker(input: $input) {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const updateHandwerker = /* GraphQL */ `
  mutation UpdateHandwerker($input: UpdateHandwerkerInput!) {
    updateHandwerker(input: $input) {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const deleteHandwerker = /* GraphQL */ `
  mutation DeleteHandwerker($input: DeleteHandwerkerInput!) {
    deleteHandwerker(input: $input) {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const createKontoloeschung = /* GraphQL */ `
  mutation CreateKontoloeschung($input: CreateKontoloeschungInput!) {
    createKontoloeschung(input: $input) {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const updateKontoloeschung = /* GraphQL */ `
  mutation UpdateKontoloeschung($input: UpdateKontoloeschungInput!) {
    updateKontoloeschung(input: $input) {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const deleteKontoloeschung = /* GraphQL */ `
  mutation DeleteKontoloeschung($input: DeleteKontoloeschungInput!) {
    deleteKontoloeschung(input: $input) {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const createFrist = /* GraphQL */ `
  mutation CreateFrist($input: CreateFristInput!) {
    createFrist(input: $input) {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const updateFrist = /* GraphQL */ `
  mutation UpdateFrist($input: UpdateFristInput!) {
    updateFrist(input: $input) {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const deleteFrist = /* GraphQL */ `
  mutation DeleteFrist($input: DeleteFristInput!) {
    deleteFrist(input: $input) {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const createBewertung = /* GraphQL */ `
  mutation CreateBewertung($input: CreateBewertungInput!) {
    createBewertung(input: $input) {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
export const updateBewertung = /* GraphQL */ `
  mutation UpdateBewertung($input: UpdateBewertungInput!) {
    updateBewertung(input: $input) {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
export const deleteBewertung = /* GraphQL */ `
  mutation DeleteBewertung($input: DeleteBewertungInput!) {
    deleteBewertung(input: $input) {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
