import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuftraege } from '../../DomainContext';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const AuftragLoeschenDialog = ({ hide, anfrage }) => {
  const history = useHistory();
  const auftraege = useAuftraege();

  const loescheAuftrag = async () => {
    await auftraege.loescheAuftrag(anfrage.auftrag);
    hide();
    history.push('/meineAuftraege?auftragGeloescht');
  };
  const actions = (
    <>
      <Button onClick={hide} data-cy-id="auftragLoeschenAbbrechenModalButton">
        Abbrechen
      </Button>
      <Button
        color="red"
        onClick={loescheAuftrag}
        data-cy-id="auftragLoeschenModalButton"
      >
        Auftrag löschen
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title={'Möchten Sie den Auftrag wirklich löschen?'}
      hide={hide}
      actions={actions}
      gaTrackingName="Auftrag löschen"
    >
      <span className="modal__contentText">
        Wenn Sie den Auftrag jetzt löschen, wird es dauerhaft und unwiderruflich
        aus Ihrer Auftragübersicht entfernt.
      </span>
    </Modal>
  );
};

export default AuftragLoeschenDialog;
