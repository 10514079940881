import React from 'react';
import successIcon from '../../../../assets/icons/success.svg';
import { useStepGtmEvent } from '../ProfilVervollstaendigen';
export function Fertig() {
  useStepGtmEvent('done');
  return (
    <div className="centered">
      <img src={successIcon} alt="" className="profilVervollstaendigen__icon" />
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.75rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Fertig
      </h2>
      <p className="profilVervollstaendigen__absatz">
        Vielen Dank. Ihr Profil ist jetzt vollständig
      </p>
    </div>
  );
}
