import { hasStatus } from '../status';
import { isYoungerThanDays } from '../dateTime';

/**
 * PMUI-58:
 * OFFEN ----- Annahme durch K -----> ANGENOMMEN
 * OFFEN ---- Ablehnung durch K ----> ABGELEHNT
 *
 * ANGENOMMEN - Widerrufen durch K -> WIDERRUFEN
 */
export const ANGEBOTSTATUS_OFFEN = 'OFFEN';
export const ANGEBOTSTATUS_ABGELEHNT = 'ABGELEHNT';
export const ANGEBOTSTATUS_ANGENOMMEN = 'ANGENOMMEN';
export const ANGEBOTSTATUS_WIDERRUFEN = 'WIDERRUFEN';

export const ANGEBOTSTATUSTEXTE = {
  [ANGEBOTSTATUS_ABGELEHNT]: 'Abgelehnt',
  [ANGEBOTSTATUS_ANGENOMMEN]: 'Angenommen',
  [ANGEBOTSTATUS_OFFEN]: 'Offen',
  [ANGEBOTSTATUS_WIDERRUFEN]: 'Widerrufen'
};

export default class Angebot {
  constructor(angebotsdaten) {
    Object.assign(this, angebotsdaten);
  }

  statusAlsText() {
    return ANGEBOTSTATUSTEXTE[this.status];
  }

  istOffen() {
    return hasStatus(this, ANGEBOTSTATUS_OFFEN);
  }

  wurdeAngenommen() {
    return hasStatus(this, ANGEBOTSTATUS_ANGENOMMEN);
  }

  wurdeAbgelehnt() {
    return hasStatus(this, ANGEBOTSTATUS_ABGELEHNT);
  }

  kannWiderrufenWerden() {
    return (
      hasStatus(this, ANGEBOTSTATUS_ANGENOMMEN) &&
      this.annahmedatum &&
      isYoungerThanDays(this.annahmedatum, 21)
    );
  }
}
