import React, { useEffect } from 'react';
import { useStepGtmEvent } from '../ProfilVervollstaendigen';
export function ServiceLevel({ setStepValid }) {
  useEffect(() => {
    setStepValid(true);
  }, [setStepValid]);
  useStepGtmEvent('serviceLevel');
  return (
    <>
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Bevor es losgeht...
      </h2>
      <p className="profilVervollstaendigen__absatz margin:bottom:1.5rem">
        Mit der Vermittlung von Handwerks- und Dienstleistungen außerhalb von
        Schadenfällen möchte die Provinzial Dienstleistungs GmbH den Kunden der
        Provinzial einen Mehrwert mit den gleichen Qualitätsstandards wie in
        Schadenfällen bieten. Zur Sicherstellung dieser Qualitätsstandards und
        einer optimalen Auftragsabwicklung verpflichten Sie sich zu folgenden
        Service-Standards:
      </p>
      <div className="profilVervollstaendigen__serviceLevel">
        <h2 className="profilVervollstaendigen__ueberschrift profilVervollstaendigen__ueberschrift--small margin:bottom:.5rem">
          a) Reaktionszeiten:
        </h2>
        <ul className="profilVervollstaendigen__liste margin:bottom:1.5rem">
          <li>
            Eine Antwort auf eine Ausschreibung eines Kunden (Angebotserstellung
            bzw. Rückfragen zur Konkretisierung des Angebotes oder Ablehnung
            Ihrer Teilnahme an der Ausschreibung) erfolgt innerhalb von{' '}
            <strong>3 Werktagen</strong> nach erfolgter Ausschreibung über die
            Plattform.
          </li>
          <li>
            Nach Beauftragung durch den Kunden und auf Basis des erstellten
            Angebotes, sind dem Kunden innerhalb von{' '}
            <strong>3 Werktagen</strong> 1-3 Terminvorschläge für die Umsetzung
            der Arbeiten zu unterbreiten.
          </li>
          <li>
            Der Samstag wird bei diesen Service Leveln zu Reaktionszeiten nicht
            als Werktag gezählt.
          </li>
          <li>
            Weitere Nachrichten des Kunden über die Plattform sind{' '}
            <strong>kurzfristig</strong> zu beantworten.
          </li>
          <li>
            Nicht einzuhaltende Termine sind dem Kunden unverzüglich
            mitzuteilen.
          </li>
          <li>
            Die Rechnungsstellung an den Kunden muss <strong>3 Wochen</strong>{' '}
            nach Fertigstellung der erbrachten Leistungen erfolgen.
          </li>
        </ul>
        <h2 className="profilVervollstaendigen__ueberschrift profilVervollstaendigen__ueberschrift--small margin:bottom:.5rem">
          b) Ihr Nutzerprofil
        </h2>
        <ul className="profilVervollstaendigen__liste margin:bottom:1.5rem">
          <li>
            Sie sind verpflichtet, richtige und vollständige Angaben
            insbesondere über Ihre Identität, Anschrift, Telefonnummer,
            E-Mail-Adresse, Qualifikationen, Zulassungen und Erlaubnisse zu
            machen.
          </li>
          <li>
            Diese Angaben sind von Ihnen stets in Ihrem Nutzerprofil auf dem
            aktuellen Stand zu halten.
          </li>
        </ul>
        <h2 className="profilVervollstaendigen__ueberschrift profilVervollstaendigen__ueberschrift--small margin:bottom:.5rem">
          c) Leistungsumfang und Qualität der Leistungen:
        </h2>
        <ul className="profilVervollstaendigen__liste">
          <li>
            Die Durchführung der Arbeiten erfolgt durch eigene, qualifizierte
            und fachlich versierte Mitarbeiter.
          </li>
          <li>Der Einsatz von Subunternehmern ist untersagt.</li>
          <li>
            Es dürfen nur solche Materialien verwendet werden, über die Sie das
            uneingeschränkte Eigentumsrecht besitzen und die vollkommen frei von
            Rechten Dritter sind oder vom Auftraggeber bereitgestellt werden.
          </li>
          <li>
            Materialien werden von Ihnen fach- und sachgerecht entsorgt sowie
            nur handelsübliche und normgerechte Materialien verwendet.
          </li>
          <li>
            Verunreinigungen werden von Ihnen beseitigt, soweit sie von Ihren
            eigenen Arbeiten herrühren.
          </li>
          <li>Gegenüber dem Kunden erfolgt ein ordentliches Auftreten.</li>
        </ul>
      </div>
    </>
  );
}
