import Auftrag from './auftrag';
import Anfrage from './anfrage';

export default class EventDispatcher {
  subscribe(eventType, subscriber) {}

  unsubscribe(eventType, subscriber) {}

  fireEvent(event) {}

  fireEvents(events) {}
}

export function mitBerechtigung(event, ...berechtigungen) {
  return {
    ...event,
    ...berechtigungen.reduce(
      (alleBerechtigungen, berechtigung) => ({
        ...alleBerechtigungen,
        ...berechtigung,
      }),
      {}
    ),
  };
}

export function fuerKunde(kundeOderAuftragOderAnfrage) {
  if (kundeOderAuftragOderAnfrage instanceof Auftrag) {
    return fuerKunde(kundeOderAuftragOderAnfrage.kunde);
  } else if (kundeOderAuftragOderAnfrage instanceof Anfrage) {
    return fuerKunde(kundeOderAuftragOderAnfrage.auftrag);
  }
  return { berechtigterKunde: kundeOderAuftragOderAnfrage.id };
}

export function fuerHandwerker(handwerkerOderAnfrage) {
  if (handwerkerOderAnfrage instanceof Anfrage) {
    return fuerHandwerker(handwerkerOderAnfrage.handwerker);
  }
  return { berechtigterHandwerker: handwerkerOderAnfrage.id };
}
