import React from 'react';
import { gtmEvent } from '../../googleAnalytics';
import { useAuftraege } from '../../DomainContext';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Gewaehrleistungsteaser from '../Gewaehrleistungsteaser/Gewaehrleistungsteaser';
import { formatCurrency, formatMwStForAngebot } from '../../money';
import { useS3ObjectUrl } from '../../hooks';
import Link from '../Link/Link';

import { ReactComponent as PdfIcon } from '../../assets/icons/pdf_file.svg';

import './Angebot.scss';

export default ({ hide, anfrage, angebot, url }) => {
  const auftraege = useAuftraege();
  const agbUrl =
    anfrage.handwerker.agbTyp === 'Keine eigenen AGB'
      ? null
      : anfrage.handwerker.agbTyp === 'AGB als Link'
      ? anfrage.handwerker.agbLink
      : useS3ObjectUrl(anfrage.handwerker.agb);

  const datenschutzbestimmungenUrl =
    anfrage.handwerker.datenschutzbestimmungenTyp ===
    'Datenschutzbestimmungen als Link'
      ? anfrage.handwerker.datenschutzbestimmungenLink
      : anfrage.handwerker.datenschutzbestimmungenTyp ===
        'Datenschutzbestimmungen als PDF'
      ? useS3ObjectUrl(anfrage.handwerker.datenschutzbestimmungen)
      : '/rechtliches';

  const createHandleAngebotAnnehmen = (setLoading) => async () => {
    setLoading(true);
    try {
      await auftraege.nimmAngebotAn(anfrage, angebot);
      gtmEvent({
        category: 'contract',
        action: 'offerAccepted',
        value: angebot.brutto,
      });
      hide();
    } catch (e) {
      setLoading(false);
    }
  };

  const actions = (setLoading) => (
    <>
      <Button onClick={hide} data-cy-id="abbrechenButton">
        Abbrechen
      </Button>
      <Button
        color="green"
        onClick={createHandleAngebotAnnehmen(setLoading)}
        data-cy-id="kostenpflichtigBeauftragenButton"
      >
        Kostenpflichtig beauftragen
      </Button>
    </>
  );
  return (
    <Modal
      title={`Angebot von ${anfrage.handwerker.firmenname} annehmen`}
      hide={hide}
      actions={actions}
      gaTrackingName="Angebot annehmen"
    >
      <div className="angebotAnnehmen__hinweis">
        Schön, dass Sie das Angebot von {anfrage.handwerker.firmenname}&nbsp;
        anspricht. Hier haben Sie nun die Möglichkeit den Auftrag zu erteilen.
      </div>
      <div className="angebotAnnehmen__angebotDownload">
        <Link
          icon={<PdfIcon />}
          className="margin:left:1rem"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {angebot.anhang.datei.fileName}
        </Link>
      </div>
      <div className="angebotAnnehmen__angebotNetto">
        Nettobetrag:
        <div className="angebotAnnehmen__angebotNettoBetrag">
          {formatCurrency(angebot.netto)}
        </div>
      </div>
      <div className="angebotAnnehmen__angebotMwst">
        zzgl. {formatMwStForAngebot(angebot)}:
        <div className="angebotAnnehmen__angebotMwstBetrag">
          {formatCurrency(
            Math.round((angebot.brutto - angebot.netto) * 100) / 100
          )}
        </div>
      </div>
      <div className="angebotAnnehmen__angebotBrutto">
        Endbetrag:
        <div className="angebotAnnehmen__angebotBruttoBetrag">
          {formatCurrency(angebot.brutto)}
        </div>
      </div>
      <Gewaehrleistungsteaser className="angebotAnnehmen__gewaehrleistungsteaser" />
      <div className="angebotAnnehmen__hinweis">
        {agbUrl && (
          <>
            Es gelten die&nbsp;
            <Link
              size="small"
              href={agbUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              Allgemeinen Geschäftsbedingungen
            </Link>
            &nbsp;des Handwerkers.{' '}
          </>
        )}
        Bitte beachten Sie {agbUrl ? 'dessen' : 'die'}&nbsp;
        <Link
          size="small"
          href={datenschutzbestimmungenUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Datenschutzhinweise
        </Link>
        &nbsp;und die&nbsp;
        <Link
          size="small"
          href={`/widerrufsbelehrung/${anfrage.handwerker.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Widerrufsbelehrung
        </Link>
        {!agbUrl && ' des Handwerkers'}.
      </div>

      <div className="angebotAnnehmen__rechtliches">
        <p>
          <strong>Was geschieht, wenn Sie einen Auftrag erteilen?</strong>
        </p>
        <p>
          Wenn Sie auf den Button "Kostenpflichtig beauftragen" klicken, kommt
          ein verbindlicher Vertrag zustande. Wir senden Ihnen eine E-Mail mit
          der Bestätigung des Auftrags zu.
        </p>
      </div>
    </Modal>
  );
};
