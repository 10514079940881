import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import Amplify, { API, graphqlOperation, Hub } from 'aws-amplify';
import {
  useHandwerkerverzeichnis,
  useKundenverzeichnis,
} from '../DomainContext';
import { pruefeIstHandwerker } from '../user';
import * as subscriptions from '../graphql/subscriptions';
import { unmarshallHandwerker, unmarshallKunde } from '../persistence/marshall';

const BenutzerContext = React.createContext({});

export function useBenutzerContext() {
  return useContext(BenutzerContext);
}

export function BenutzerContextProvider({ children }) {
  const kundenverzeichnis = useKundenverzeichnis();
  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const [state, setState] = useState({
    cognitoUser: null,
    benutzer: null,
    isLoading: true,
  });
  const updateState = useCallback((delta) => {
    setState((currentState) => {
      return { ...currentState, ...delta };
    });
  }, []);

  useEffect(() => {
    let didCancel = false;

    async function initialisiereCognitoBenutzerUndBenutzer() {
      if (!didCancel) {
        updateState({ isLoading: true });

        const cognitoUser = await holeCognitoUser();

        if (!cognitoUser) {
          updateState({ cognitoUser: null, benutzer: null, isLoading: false });
        } else if (pruefeIstHandwerker(cognitoUser)) {
          const handwerker = await holeHandwerker(
            handwerkerverzeichnis,
            cognitoUser
          );

          if (handwerker === null) {
            console.error(
              `orphaned cognito session found for user ${cognitoUser.attributes['custom:hwk_id']} - logging out!`
            );
            Amplify.Auth.signOut();
          } else {
            updateState({
              isLoading: false,
              cognitoUser,
              benutzer: handwerker,
            });
          }
        } else {
          const kunde = await holeKundeOderLegeKundeAn(
            kundenverzeichnis,
            cognitoUser
          );

          updateState({ isLoading: false, cognitoUser, benutzer: kunde });
        }
      }
    }

    const authenticationListener = async ({ payload: { event } }) => {
      if (!didCancel) {
        if (event === 'signIn') {
          initialisiereCognitoBenutzerUndBenutzer();
        } else if (event === 'signOut') {
          updateState({ cognitoUser: null, benutzer: null, isLoading: false });
        }
      }
    };

    Hub.listen('auth', authenticationListener);
    initialisiereCognitoBenutzerUndBenutzer();

    return () => {
      Hub.remove('auth', authenticationListener);
      didCancel = true;
    };
  }, [handwerkerverzeichnis, kundenverzeichnis, updateState]);

  useEffect(() => {
    let didCancel = false;
    let observable = null;

    if (state.cognitoUser && state.benutzer) {
      if (pruefeIstHandwerker(state.cognitoUser)) {
        observable = subscribeToHandwerker(state.benutzer.id, (handwerker) => {
          if (!didCancel) {
            updateState({ benutzer: handwerker });
          }
        });
      } else {
        observable = subscribeToKunde(state.benutzer.id, (kunde) => {
          if (!didCancel) {
            updateState({ benutzer: kunde });
          }
        });
      }
    }

    return () => {
      didCancel = true;
      if (observable !== null) {
        observable.unsubscribe();
      }
    };
  }, [state.benutzer, state.cognitoUser, updateState]);

  const contextValue = useMemo(
    () => ({
      cognitoUser: state.cognitoUser,
      benutzer: state.benutzer,
      istHandwerker: pruefeIstHandwerker(state.cognitoUser),
    }),
    [state]
  );

  if (state.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <BenutzerContext.Provider value={contextValue}>
      {children}
    </BenutzerContext.Provider>
  );
}

async function holeKundeOderLegeKundeAn(kundenverzeichnis, cognitoUser) {
  const existierenderKunde = await holeKunde(kundenverzeichnis, cognitoUser);

  if (existierenderKunde) {
    return existierenderKunde;
  }

  return legeKundeAn(kundenverzeichnis, cognitoUser);
}

async function holeCognitoUser() {
  try {
    return await Amplify.Auth.currentAuthenticatedUser();
  } catch (_) {
    // Not authenticated. That's okay.
    return null;
  }
}

async function holeKunde(kundenverzeichnis, cognitoUser) {
  try {
    const kundeId = cognitoUser.attributes.sub;
    return await kundenverzeichnis.holeKundeZuId(kundeId);
  } catch (e) {
    console.error(e);
    return null;
  }
}

async function legeKundeAn(kundenverzeichnis, cognitoUser) {
  try {
    const kundeId = cognitoUser.attributes.sub;
    return await kundenverzeichnis.legeKundenAn({
      id: kundeId,
      vorname: cognitoUser.attributes.given_name,
      nachname: cognitoUser.attributes.family_name,
      email: cognitoUser.attributes.email,
      werbeeinwilligung: cognitoUser.attributes['custom:werbeeinwilligung'],
      emailbenachrichtigungen: true,
    });
  } catch (e) {
    console.error(e);
    return null;
  }
}

async function holeHandwerker(handwerkerverzeichnis, cognitoUser) {
  try {
    const hwkId = cognitoUser.attributes['custom:hwk_id'];
    return await handwerkerverzeichnis.holeHandwerkerZuId(hwkId);
  } catch (_) {
    return null;
  }
}

function subscribeToKunde(kundeId, callback) {
  return API.graphql(
    graphqlOperation(subscriptions.onUpdateKunde, {
      owner: kundeId,
    })
  ).subscribe({
    next: (response) => {
      callback(unmarshallKunde(response.value.data.onUpdateKunde));
    },
    error: console.warn,
  });
}

function subscribeToHandwerker(hwkId, callback) {
  return API.graphql(
    graphqlOperation(subscriptions.onUpdateHandwerkerById, {
      id: hwkId,
    })
  ).subscribe({
    next: (response) => {
      const handwerker = unmarshallHandwerker(
        response.value.data.onUpdateHandwerkerById
      );
      callback(handwerker);
    },
    error: console.warn,
  });
}
