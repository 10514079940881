export function updateOrPush(list, updateItem, key = 'id') {
  const keyMapper = typeof key !== 'string' ? key : (o) => o[key];
  const identity = keyMapper(updateItem);
  const index = list.findIndex((p) => keyMapper(p) === identity);
  if (index >= 0) {
    list[index] = updateItem;
  } else {
    list.push(updateItem);
  }
  return list;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function unique(list) {
  return list.filter((value, index) => list.indexOf(value) === index);
}

export function difference(minuend, subtrahent) {
  return new Set([...minuend].filter((feld) => !subtrahent.has(feld)));
}

export function intersection(set1, set2) {
  return new Set([...set1].filter((feld) => set2.has(feld)));
}

export function diffByValues(keysToCompare, object1, object2) {
  return new Set(
    [...keysToCompare].filter(removeUnchangedKeys(object1, object2))
  );
}

export function union(...sets) {
  return sets.filter((s) => s).reduce(mergeSets, new Set());
}

export function mapMapEntries(map, mapper) {
  return new Map([...map.entries()].map(mapper));
}

/**
 * @deprecated
 */
export function jsonParseWhileString(objectOrString) {
  let parsed = objectOrString;
  while (typeof parsed === 'string') {
    parsed = JSON.parse(parsed);
  }

  return parsed;
}

function mergeSets(set1, set2) {
  return new Set([...set1, ...set2]);
}

function removeUnchangedKeys(objekt1, objekt2) {
  return (feld) => {
    const objekt1Wert = Array.isArray(objekt1[feld])
      ? objekt1[feld].sort()
      : objekt1[feld];
    const objekt2Wert = Array.isArray(objekt2[feld])
      ? objekt2[feld].sort()
      : objekt2[feld];
    return JSON.stringify(objekt1Wert) !== JSON.stringify(objekt2Wert);
  };
}
