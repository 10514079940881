import {
  ACCEPTED_TYPES_IMAGES_ONLY,
  ACCEPTED_TYPES_PDF_ONLY,
} from '../Dropzone/constants';
import {
  AbwesenheitEinstellung,
  EMailBenachrichtigungenEinstellung,
  Profilzeile,
  RechtlicheAngabe,
  SelectWrapper,
  TextAreaWrapper,
  TextInputWrapper,
} from './Components';
import { Filialen, Filialuebersicht } from './Filialen';
import React, { useEffect, useRef, useState } from 'react';
import {
  URL_REGEX_PATTERN,
  istDatenschutzAlsPdfOderLinkUngespeichertVorhanden,
  istEmailValide,
  istImpressumAlsTextOderLinkVorhanden,
  sindEinsatzgebieteVorhanden,
  sindFirmendatenGefuellt,
  sindNurValideLinksVorhanden,
} from './validierung';
import { useS3ObjectUrl, useScroll } from '../../hooks';

import Bewertungsanzeige from '../Bewertungen/Handwerkerbewertung/Bewertungsanzeige';
import Bewertungszusammenfassung from '../Bewertungen/Handwerkerbewertung/Bewertungszusammenfassung';
import Button from '../Button/Button';
import Chip from '../Chip/Chip';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import Dropzone from '../Dropzone/Dropzone';
import Link from '../Link/Link';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { RadioGroup } from '../RadioGroup/RadioGroup';
import { TaetigkeitenAuswahl } from '../Handwerker/TaetigkeitenAuswahl/TaetigkeitenAuswahl';
import { TextInput } from '../TextInput/TextInput';
import { Tooltip } from '../Tooltip/Tooltip';
import { gewerkZuLangform } from '../../domain/gewerk';
import { unique } from '../../collections';
import { useBewertungen } from '../../hooks/useBewertungen';

export default function Handwerkerprofil({
  handwerker,
  istEigenesProfil,
  aktualisierterBenutzer,
  setAktualisierterBenutzer,
  setPflichtfelderGefuelltUndValide,
  setProfilGeaendert,
  firmenlogoFileContainers,
  setFirmenlogoFileContainers,
  agbFileContainers,
  setAgbFileContainers,
  datenschutzbestimmungenFileContainers,
  setDatenschutzbestimmungenFileContainers,
  showKontoLoeschenDialog,
  scrollToDatenschutz,
  containerRef,
}) {
  const [istAGBLinkValide, setAGBLinkValide] = useState(true);
  const [istDatenschutzLinkValide, setDatenschutzLinkValide] = useState(true);
  const [istImpressumLinkValide, setImpressumLinkValide] = useState(true);
  const [istWebseitenLinkValide, setWebseitenLinkValide] = useState(true);
  const [istDatenschutzVorhanden, setDatenschutzVorhanden] = useState(true);
  const [istImpressumVorhanden, setImpressumVorhanden] = useState(true);
  const [region, setRegion] = useState('');
  const { bewertungen } = useBewertungen(handwerker?.id);
  const bewertungenRef = useRef();

  const validiereLink = (event) => {
    const feldname = event.target.name;
    const feldwert = event.target.value;
    const istFeldGueltig = event.target.validity.valid;
    const istFeldVorhandenUndGueltig = feldwert && istFeldGueltig;
    const istFeldLeerOderGueltig = !feldwert || istFeldGueltig;

    if (feldname === 'agbLink') {
      setAGBLinkValide(istFeldVorhandenUndGueltig);
    } else if (feldname === 'datenschutzbestimmungenLink') {
      setDatenschutzLinkValide(istFeldVorhandenUndGueltig);
    } else if (feldname === 'impressumLink') {
      setImpressumLinkValide(istFeldVorhandenUndGueltig);
    } else if (feldname === 'webseite') {
      setWebseitenLinkValide(istFeldLeerOderGueltig);
    }

    if (
      istFeldVorhandenUndGueltig &&
      !feldwert.startsWith('http://') &&
      !feldwert.startsWith('https://')
    ) {
      aktualisiereBenutzer({
        ...aktualisierterBenutzer,
        [feldname]: 'http://'.concat(feldwert),
      });
    }
  };

  useEffect(() => {
    const hatDatenschutzAlsPdfOderLink = istDatenschutzAlsPdfOderLinkUngespeichertVorhanden(
      aktualisierterBenutzer,
      datenschutzbestimmungenFileContainers
    );

    const hatImpressumAlsTextOderLink = istImpressumAlsTextOderLinkVorhanden(
      aktualisierterBenutzer
    );

    setDatenschutzVorhanden(hatDatenschutzAlsPdfOderLink);
    setImpressumVorhanden(hatImpressumAlsTextOderLink);

    setPflichtfelderGefuelltUndValide(
      hatImpressumAlsTextOderLink &&
        hatDatenschutzAlsPdfOderLink &&
        sindEinsatzgebieteVorhanden(aktualisierterBenutzer) &&
        sindFirmendatenGefuellt(aktualisierterBenutzer) &&
        sindNurValideLinksVorhanden(aktualisierterBenutzer) &&
        istEmailValide(aktualisierterBenutzer.email)
    );
  }, [
    aktualisierterBenutzer,
    datenschutzbestimmungenFileContainers,
    istWebseitenLinkValide,
    setPflichtfelderGefuelltUndValide,
  ]);

  useEffect(() => {
    setAGBLinkValide(true);
  }, [aktualisierterBenutzer.agbLink]);

  useEffect(() => {
    setDatenschutzLinkValide(true);
  }, [aktualisierterBenutzer.datenschutzbestimmungenLink]);

  useEffect(() => {
    setImpressumLinkValide(true);
  }, [aktualisierterBenutzer.impressumLink]);

  const firmenlogo = useS3ObjectUrl(handwerker.firmenlogo);

  const aktualisiereBenutzer = (benutzer) => {
    setProfilGeaendert(true);
    setAktualisierterBenutzer(benutzer);
  };

  const handleRegionHinzufuegen = (event) => {
    event.preventDefault();

    const isValid = /^\d{3,5}$/.test(region);

    if (isValid) {
      aktualisiereBenutzer({
        ...aktualisierterBenutzer,
        regionen: unique([
          ...aktualisierterBenutzer.regionen,
          region.substring(0, 3),
        ]),
      });
      setRegion('');
    }
  };

  const createHandleRegionLoeschen = (zuLoeschendeRegion) => {
    return () => {
      aktualisiereBenutzer({
        ...aktualisierterBenutzer,
        regionen: aktualisierterBenutzer.regionen.filter(
          (r) => r !== zuLoeschendeRegion
        ),
      });
    };
  };

  const [scrollToTarget, targetRef] = useScroll(containerRef);
  useEffect(() => {
    if (scrollToDatenschutz) {
      // 80px, damit die Überschrift nicht am Rand klebt.
      scrollToTarget(-80);
    }
  }, [scrollToDatenschutz, scrollToTarget]);

  // PMUI-319
  const webseite = handwerker.webseite
    ? /^https?:\/\//.test(handwerker.webseite)
      ? handwerker.webseite
      : `http://${handwerker.webseite}`
    : handwerker.webseite;

  return (
    <>
      {istEigenesProfil ? (
        <>
          <div className="profil__handwerker" ref={containerRef}>
            <div>
              <div className="profil__sectionContainer">
                <h1 className="profil__sectionHeader">Firmendaten</h1>
                <div className="profil__firmendaten">
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="firmenname"
                      label="Firmenname"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                      validierungstext={
                        !aktualisierterBenutzer.firmenname && 'Firmenname fehlt'
                      }
                    />
                    <TextInputWrapper
                      attribut="strasse"
                      label="Straße"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                      validierungstext={
                        !aktualisierterBenutzer.strasse && 'Straße fehlt'
                      }
                    />
                    <div className="profil__plzUndOrt">
                      <div className="profil__label--plz">
                        <TextInputWrapper
                          attribut="plz"
                          label="PLZ"
                          labelClassName="textInput__label--plz"
                          aktualisierterBenutzer={aktualisierterBenutzer}
                          setAktualisierterBenutzer={aktualisiereBenutzer}
                          validierungstext={
                            !aktualisierterBenutzer.plz && 'PLZ fehlt'
                          }
                        />
                      </div>
                      <div className="profil__label--ort">
                        <TextInputWrapper
                          attribut="ort"
                          label="Ort"
                          labelClassName="textInput__label--ort"
                          aktualisierterBenutzer={aktualisierterBenutzer}
                          setAktualisierterBenutzer={aktualisiereBenutzer}
                          validierungstext={
                            !aktualisierterBenutzer.ort && 'Ort fehlt'
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="profil__spalte">
                    <label className="profil__label">
                      Firmenlogo / Teambild (optional)
                      <Dropzone
                        fileContainers={firmenlogoFileContainers}
                        dataCyId="firmenlogo-dropzone"
                        className="profil__firmenlogoDropzone"
                        acceptedTypes={ACCEPTED_TYPES_IMAGES_ONLY}
                        onChange={(fileContainers) => {
                          setProfilGeaendert(true);
                          setFirmenlogoFileContainers(fileContainers);
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="profil__firmendaten">
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="email"
                      label="E-Mail-Adresse"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                      validierungstext={emailValidierungstext(
                        aktualisierterBenutzer.email
                      )}
                      hilfetext={emailHilfetext(
                        handwerker.email,
                        aktualisierterBenutzer.email
                      )}
                    />
                  </div>
                </div>
                <div className="profil__firmendaten">
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="telefon"
                      label="Telefon"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                      validierungstext={
                        !aktualisierterBenutzer.telefon && 'Telefonnummer fehlt'
                      }
                    />
                  </div>
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="mobil"
                      label="Mobiltelefon"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                    />
                  </div>
                </div>
                <div className="profil__firmendaten">
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="telefonischeErreichbarkeit"
                      label="Telefonische Erreichbarkeit (optional)"
                      placeholder="z.B. Mo - Fr: 08 - 18 Uhr"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                    />
                  </div>
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="webseite"
                      label="Webseite (optional)"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                      onBlur={validiereLink}
                      pattern={URL_REGEX_PATTERN}
                      validierungstext={
                        !istWebseitenLinkValide &&
                        'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
                      }
                    />
                  </div>
                </div>
                <div className="profil__firmendaten">
                  <div className="profil__spalte">
                    <TextInputWrapper
                      attribut="firmengruendung"
                      label="Firmengründung (optional)"
                      placeholder="JJJJ"
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                    />
                  </div>
                  <div className="profil__spalte">
                    <SelectWrapper
                      attribut="anzahlMitarbeiter"
                      label="Anzahl Mitarbeiter (optional)"
                      options={[
                        '',
                        'bis 4',
                        '5 bis 9',
                        '10 bis 19',
                        '20 bis 49',
                        '50 und mehr',
                      ]}
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      setAktualisierterBenutzer={aktualisiereBenutzer}
                    />
                  </div>
                </div>
              </div>
              <div className="profil__sectionContainer">
                <h1 className="profil__sectionHeader">Filialen</h1>
                <p>
                  Sie sind überregional aktiv und haben mehrere Filialen? Dann
                  können Sie diese hier hinterlegen um besser gefunden zu
                  werden.
                </p>
                <Filialen
                  filialen={aktualisierterBenutzer.filialen || []}
                  onChange={(filialen) => {
                    aktualisiereBenutzer({
                      ...aktualisierterBenutzer,
                      filialen,
                    });
                  }}
                />
              </div>
              <div className="profil__sectionContainer">
                <h1 className="profil__sectionHeader">Zusatzqualifikationen</h1>
                <RadioGroup
                  label="Haben Sie zusätzliche Qualifikationen wie Zertifikate, Meisterbriefe, Diplome oder Ähnliche?"
                  options={['Nein', 'Ja']}
                  value={
                    aktualisierterBenutzer.zusatzqualifikationenVorhanden
                      ? 'Ja'
                      : 'Nein'
                  }
                  onChange={(event) => {
                    aktualisiereBenutzer({
                      ...aktualisierterBenutzer,
                      zusatzqualifikationenVorhanden:
                        event.target.value === 'Ja',
                    });
                  }}
                />
                {aktualisierterBenutzer.zusatzqualifikationenVorhanden && (
                  <TextAreaWrapper
                    labelClassName="profil__zusatzqualifikationenLabel"
                    label="Folgende Zusatzqualifikationen liegen vor:"
                    attribut="zusatzqualifikationen"
                    aktualisierterBenutzer={aktualisierterBenutzer}
                    setProfilGeaendert={setProfilGeaendert}
                    setAktualisierterBenutzer={aktualisiereBenutzer}
                  />
                )}
              </div>
              <div className="profil__sectionContainer">
                <h1 className="profil__sectionHeader">
                  Tätigkeiten &amp; Einsatzgebiete
                </h1>
                <h2
                  data-cy-ueberschrift="Tätigkeiten"
                  className="profil__sectionHeader"
                >
                  Tätigkeiten
                </h2>
                <div className="profil__section">
                  <TaetigkeitenAuswahl
                    gewaehlteLeistungen={
                      aktualisierterBenutzer.gewaehlteLeistungen || []
                    }
                    onChange={(gewaehlteLeistungen) => {
                      aktualisiereBenutzer({
                        ...aktualisierterBenutzer,
                        gewaehlteLeistungen,
                      });
                    }}
                  />
                </div>
                <h2 className="profil__sectionHeader">
                  Einsatzgebiete
                  <Tooltip
                    tooltipId="Einsatzgebiete"
                    text="Wir schneiden bei der Postleitzahl die letzten zwei Ziffern ab."
                  />
                </h2>
                <div className="profil__section margin:bottom:2rem">
                  Fügen Sie Postleitzahlengebiete hinzu, in denen Sie Ihre
                  Handwerksleistungen anbieten möchten.
                </div>
                <div className="profil__section profil__section--dreispaltig margin:bottom:1.5rem">
                  <form
                    className="profil__subsection profil__subsection--regionInput"
                    onSubmit={handleRegionHinzufuegen}
                  >
                    <div>
                      <TextInput
                        value={region}
                        onChange={(event) =>
                          setRegion(event.target.value.trim())
                        }
                        label="PLZ-Gebiet"
                        type="text"
                        inputMode="numeric"
                        validierungstext={
                          !/^\d{3,5}$/.test(region) &&
                          region.length > 0 &&
                          'PLZ ungültig'
                        }
                      />
                    </div>
                    <Button className="margin:top:1.375rem" color="green">
                      Hinzufügen
                    </Button>
                  </form>
                </div>
                <div className="profil__subsection profil__subsection--regionen">
                  {Array.from(aktualisierterBenutzer.regionen).map(
                    (region, key) => (
                      <Chip
                        key={key}
                        onRemove={createHandleRegionLoeschen(region)}
                      >
                        {region}xx
                      </Chip>
                    )
                  )}
                </div>
              </div>
              <div className="profil__sectionContainer">
                <h1 className="profil__sectionHeader">Rechtliches</h1>
                <h2 className="profil__sectionHeader">
                  Allgemeine Geschäftsbedingungen (optional)
                  <Tooltip
                    tooltipId="AGB"
                    text="Sofern Ihr Unternehmen über eigene AGB verfügt, können Sie hier auf die AGB auf Ihrer Unternehmens-Webseite verlinken oder Ihre AGB als Dokument hochladen. Die AGB werden dem Kunden vor Annahme des Angebots zur Verfügung gestellt."
                  />
                </h2>
                <SelectWrapper
                  attribut="agbTyp"
                  id="agbTyp"
                  options={['Keine eigenen AGB', 'AGB als Link', 'AGB als PDF']}
                  aktualisierterBenutzer={aktualisierterBenutzer}
                  setAktualisierterBenutzer={aktualisiereBenutzer}
                />
                {aktualisierterBenutzer.agbTyp === 'AGB als Link' && (
                  <TextInputWrapper
                    attribut="agbLink"
                    aktualisierterBenutzer={aktualisierterBenutzer}
                    setAktualisierterBenutzer={aktualisiereBenutzer}
                    onBlur={validiereLink}
                    pattern={URL_REGEX_PATTERN}
                    validierungstext={
                      !istAGBLinkValide &&
                      'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
                    }
                  />
                )}
                {aktualisierterBenutzer.agbTyp === 'AGB als PDF' && (
                  <Dropzone
                    fileContainers={agbFileContainers}
                    dataCyId="agb-dropzone"
                    acceptedTypes={ACCEPTED_TYPES_PDF_ONLY}
                    onChange={(fileContainers) => {
                      setProfilGeaendert(true);
                      setAgbFileContainers(fileContainers);
                    }}
                  />
                )}
                <h2 className="profil__sectionHeader" ref={targetRef}>
                  Datenschutzbestimmungen
                  <Tooltip
                    tooltipId="Datenschutzbestimmungen"
                    text="Da Sie über die Plattform „MeinZuhauseUndIch“ Daten von Interessenten und Kunden erheben, sind Sie verpflichtet, die Informationspflichten der EU-DSGVO zu erfüllen. Daher können Sie hier auf die Datenschutzhinweise auf Ihrer Unternehmens-Webseite verlinken oder Ihre Datenschutzhinweise als Dokument hochladen."
                  />
                </h2>
                <SelectWrapper
                  id="datenschutzbestimmungenTyp"
                  attribut="datenschutzbestimmungenTyp"
                  options={[
                    'Bitte auswählen',
                    'Datenschutzbestimmungen als Link',
                    'Datenschutzbestimmungen als PDF',
                  ]}
                  aktualisierterBenutzer={aktualisierterBenutzer}
                  setAktualisierterBenutzer={aktualisiereBenutzer}
                  validierungstext={
                    (aktualisierterBenutzer.datenschutzbestimmungenTyp ===
                      'Bitte auswählen' ||
                      !aktualisierterBenutzer.datenschutzbestimmungenTyp) &&
                    'Datenschutzbestimmungen fehlen'
                  }
                />
                {aktualisierterBenutzer.datenschutzbestimmungenTyp ===
                  'Datenschutzbestimmungen als Link' && (
                  <TextInputWrapper
                    data-cy-id="Datenschutzbestimmungen als Link"
                    attribut="datenschutzbestimmungenLink"
                    aktualisierterBenutzer={aktualisierterBenutzer}
                    setAktualisierterBenutzer={aktualisiereBenutzer}
                    onBlur={validiereLink}
                    pattern={URL_REGEX_PATTERN}
                    validierungstext={
                      !istDatenschutzVorhanden
                        ? 'Datenschutzbestimmungen fehlen'
                        : !istDatenschutzLinkValide &&
                          'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
                    }
                  />
                )}
                {aktualisierterBenutzer.datenschutzbestimmungenTyp ===
                  'Datenschutzbestimmungen als PDF' && (
                  <Dropzone
                    fileContainers={datenschutzbestimmungenFileContainers}
                    dataCyId="datenschutzbestimmungen-dropzone"
                    acceptedTypes={ACCEPTED_TYPES_PDF_ONLY}
                    onChange={(fileContainers) => {
                      setProfilGeaendert(true);
                      setDatenschutzbestimmungenFileContainers(fileContainers);
                    }}
                    validierungstext={
                      !istDatenschutzVorhanden &&
                      'Datenschutzbestimmungen fehlen'
                    }
                  />
                )}
                {!istDatenschutzVorhanden && (
                  <div className="profil__datenschutzinfo">
                    <p>
                      Sie haben noch keine Datenschutzbestimmungen für Ihr
                      Unternehmen? Informieren Sie sich bei Ihrer
                      Handwerkskammer, die Ihnen Muster zur Verfügung stellen
                      kann (Beispiel:{' '}
                      <a
                        href="https://www.zdh.de/fileadmin/user_upload/themen/Recht/Datenschutz/Handwerksbetriebe/Anlage_Muster_Informationserteilung_Handwerksbetriebe.docx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Muster des Zentralverbandes des Deutschen Handwerks
                      </a>
                      ).
                    </p>
                    <p>
                      Brauchen Sie Hilfe?{' '}
                      <a href="tel:02119785858">0211 978 58 58</a> (Mo-Fr von
                      8-17 Uhr)
                    </p>
                    <p>
                      Bitte beachten Sie, dass es sich um einen Link zu einer
                      Website eines Dritten handelt, auf deren Inhalte wir
                      keinen Einfluss haben. Deshalb können wir für diese
                      fremden Inhalte auch keine Gewähr übernehmen.
                    </p>
                  </div>
                )}
                <h2 className="profil__sectionHeader">
                  Impressum
                  <Tooltip
                    tooltipId="Impressum"
                    text={
                      <p>
                        Als Teilnehmer an der Plattform „MeinZuhauseUndIch“
                        treten Sie mit einem eigenen Anbieterprofil auf einer
                        Plattform auf. Somit sind Sie verpflichtet, ein
                        Impressum anzugeben. Dies können Sie machen, indem Sie
                        den direkten Link auf das Impressum Ihrer
                        Unternehmens-Webseite angeben (der Link auf die
                        Startseite ist nicht ausreichend!) oder das Impressum
                        ausfüllen. Welche Angaben ein Impressum enthalten muss,
                        sind in{' '}
                        <a
                          href="https://www.gesetze-im-internet.de/tmg/__5.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          §5 Telemediengesetz
                        </a>{' '}
                        geregelt.
                      </p>
                    }
                  />
                </h2>
                <SelectWrapper
                  id="impressumTyp"
                  attribut="impressumTyp"
                  options={[
                    'Bitte auswählen',
                    'Impressum als Link',
                    'Impressum als Text',
                  ]}
                  aktualisierterBenutzer={aktualisierterBenutzer}
                  setAktualisierterBenutzer={aktualisiereBenutzer}
                  validierungstext={
                    (aktualisierterBenutzer.impressumTyp ===
                      'Bitte auswählen' ||
                      !aktualisierterBenutzer.impressumTyp) &&
                    'Impressum fehlt'
                  }
                />
                {aktualisierterBenutzer.impressumTyp ===
                  'Impressum als Link' && (
                  <TextInputWrapper
                    data-cy-id="Impressum als Link"
                    attribut="impressumLink"
                    aktualisierterBenutzer={aktualisierterBenutzer}
                    setAktualisierterBenutzer={aktualisiereBenutzer}
                    onBlur={validiereLink}
                    pattern={URL_REGEX_PATTERN}
                    validierungstext={
                      !istImpressumVorhanden
                        ? 'Impressum fehlt'
                        : !istImpressumLinkValide &&
                          'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
                    }
                  />
                )}
                {aktualisierterBenutzer.impressumTyp ===
                  'Impressum als Text' && (
                  <TextAreaWrapper
                    data-cy-id="Impressum als Text"
                    attribut="impressum"
                    aktualisierterBenutzer={aktualisierterBenutzer}
                    setProfilGeaendert={setProfilGeaendert}
                    setAktualisierterBenutzer={aktualisiereBenutzer}
                    validierungstext={
                      !istImpressumVorhanden && 'Impressum fehlt'
                    }
                  />
                )}
                <h2 className="profil__sectionHeader">
                  Online-Streitbeilegung
                  <Tooltip
                    tooltipId="Streitbeilegung"
                    text="Als Teilnehmer an der Plattform „MeinZuhauseUndIch“ gehen Sie im Internet Werk- oder Dienstleistungsverträge ein. Somit sind Sie verpflichtet, auf die von der Europäischen Kommission geschaffene Online-Plattform zur Streitbeilegung zu verweisen und auf Ihrem Anbieterprofil einen entsprechenden Link zu platzieren. Mit dem bereits auf Ihrem Profil hinterlegten Link haben wir diese Informationspflicht für Sie schon erfüllt."
                  />
                </h2>
                Die EU-Plattform zur Online-Streitbeilegung (OS-Plattform)
                erreichen Sie unter folgendem Link:&nbsp;
                <a
                  href="https://ec.europa.eu/consumers/odr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://ec.europa.eu/consumers/odr
                </a>
              </div>

              <div className="profil__sectionContainer">
                <h1 className="profil__sectionHeader">Einstellungen</h1>

                <div className="profil__einstellungen">
                  <div
                    className="profil__persoenlicheEinstellung"
                    data-cy-persoenliche-einstellungen="E-Mail-Benachrichtigungen"
                    data-cy-status={
                      aktualisierterBenutzer.emailbenachrichtigungen
                        ? 'aktiviert'
                        : 'deaktiviert'
                    }
                  >
                    <EMailBenachrichtigungenEinstellung
                      user={handwerker}
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      aktualisiereBenutzer={aktualisiereBenutzer}
                    />
                  </div>
                  <div
                    className="profil__persoenlicheEinstellungAbwesenheit"
                    data-cy-persoenliche-einstellungen="Abwesenheit-Einstellung"
                    data-cy-status={
                      aktualisierterBenutzer.abwesenheit
                        ? 'aktiviert'
                        : 'deaktiviert'
                    }
                  >
                    <AbwesenheitEinstellung
                      aktualisierterBenutzer={aktualisierterBenutzer}
                      aktualisiereBenutzer={aktualisiereBenutzer}
                    />
                  </div>
                  <div className="margin:top:2rem">
                    <Link
                      icon={<DeleteIcon />}
                      size="small"
                      color="red"
                      data-cy-id="kontoLoeschenButton"
                      onClick={showKontoLoeschenDialog}
                    >
                      Konto und Daten löschen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1 className="profil__name">{handwerker.firmenname}</h1>
          {bewertungen.length > 0 && (
            <div className="profil__bewertungen">
              <Bewertungszusammenfassung
                size="small"
                hwkId={handwerker.id}
                bewertungen={bewertungen}
                showProfilModal={() => {
                  bewertungenRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              />
            </div>
          )}
          <div className="profil__sectionContainer">
            <div className="profil__section profil__section--zweispaltig">
              <div>
                <Profilzeile feld="Adresse">
                  <a
                    href={`https://maps.google.com/?q=${handwerker.strasse},+${handwerker.plz}+${handwerker.ort}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="profil__strasse">{handwerker.strasse}</div>
                    <div className="profil__ort">
                      {handwerker.plz} {handwerker.ort}
                    </div>
                  </a>
                </Profilzeile>
                <Profilzeile feld="E-Mail-Adresse">
                  <a href={`mailto:${handwerker.email}`}>{handwerker.email}</a>
                </Profilzeile>
                {handwerker.telefon && (
                  <Profilzeile feld="Telefon">{handwerker.telefon}</Profilzeile>
                )}
                {handwerker.mobil && (
                  <Profilzeile feld="Mobiltelefon">
                    {handwerker.mobil}
                  </Profilzeile>
                )}
                {handwerker.telefonischeErreichbarkeit && (
                  <Profilzeile feld="Telefonische Erreichbarkeit">
                    {handwerker.telefonischeErreichbarkeit}
                  </Profilzeile>
                )}
                {webseite && (
                  <Profilzeile feld="Webseite">
                    <a
                      href={webseite.replace(/^javascript:/, '')}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {webseite}
                    </a>
                  </Profilzeile>
                )}
                {handwerker.firmengruendung && (
                  <Profilzeile feld="Firmengründung">
                    {handwerker.firmengruendung}
                  </Profilzeile>
                )}
                {handwerker.anzahlMitarbeiter && (
                  <Profilzeile feld="Anzahl Mitarbeiter">
                    {handwerker.anzahlMitarbeiter}
                  </Profilzeile>
                )}
                {handwerker.zusatzqualifikationenVorhanden &&
                  handwerker.zusatzqualifikationen && (
                    <Profilzeile feld="Zusatz-qualifikationen">
                      {handwerker.zusatzqualifikationen
                        .split('\n')
                        .map((zeile, key) => (
                          <React.Fragment key={key}>
                            {zeile}
                            <br />
                          </React.Fragment>
                        ))}
                    </Profilzeile>
                  )}
                <Profilzeile feld="Gewerke">
                  {handwerker.gewerke.map(gewerkZuLangform).join(', ')}
                </Profilzeile>
                <Profilzeile feld="Region (PLZ-Gebiet)">
                  {handwerker.regionen.join(', ')}
                </Profilzeile>
              </div>
              <div className="profil__firmenlogo">
                {handwerker.firmenlogo &&
                  (firmenlogo ? (
                    <img
                      className="profil__firmenlogoImage"
                      src={firmenlogo}
                      alt=""
                    />
                  ) : (
                    <LoadingIndicator />
                  ))}
              </div>
            </div>
          </div>
          {Array.isArray(handwerker.filialen) &&
            handwerker.filialen.length > 0 && (
              <div className="profil__sectionContainer">
                <div className="profil__section">
                  <Filialuebersicht filialen={handwerker.filialen} />
                </div>
              </div>
            )}
          <div className="profil__sectionContainer">
            <h1 className="profil__sectionHeader">Rechtliche Angaben</h1>
            <div className="profil__section">
              <RechtlicheAngabe
                handwerker={handwerker}
                typattribut="agbTyp"
                linkattribut="agbLink"
                dokumentattribut="agb"
                ueberschrift="Allgemeine Geschäftsbedingungen"
                typattributbedingung="Keine eigenen AGB"
                linkattributbedingung="AGB als Link"
                dokumentattributbedingung="AGB als PDF"
              />
              <RechtlicheAngabe
                handwerker={handwerker}
                typattribut="datenschutzbestimmungenTyp"
                linkattribut="datenschutzbestimmungenLink"
                dokumentattribut="datenschutzbestimmungen"
                ueberschrift="Datenschutzbestimmungen"
                typattributbedingung="Bitte auswählen"
                linkattributbedingung="Datenschutzbestimmungen als Link"
                dokumentattributbedingung="Datenschutzbestimmungen als PDF"
              />
              <RechtlicheAngabe
                handwerker={handwerker}
                typattribut="impressumTyp"
                linkattribut="impressumLink"
                textattribut="impressum"
                ueberschrift="Impressum"
                typattributbedingung="Bitte auswählen"
                linkattributbedingung="Impressum als Link"
                textattributbedingung="Impressum als Text"
              />
              <h2 className="profil__sectionUeberschrift">
                Online-Streitbeilegung
              </h2>
              Die EU-Plattform zur Online-Streitbeilegung (OS-Plattform)
              erreichen Sie unter folgendem Link:&nbsp;
              <a
                href="https://ec.europa.eu/consumers/odr"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr
              </a>
            </div>
          </div>
          {bewertungen.length > 0 && (
            <div ref={bewertungenRef} className="profil__sectionContainer">
              <Bewertungsanzeige
                bewertungen={bewertungen}
                handwerker={handwerker}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

function emailValidierungstext(email) {
  if (!email) {
    return 'E-Mail-Adresse fehlt';
  }
  if (!istEmailValide(email)) {
    return 'Ungültige E-Mail-Adresse';
  }
}

function emailHilfetext(email, neueEmail) {
  return email !== neueEmail
    ? 'Nach Speicherung der neuen E-Mail-Adresse werden Sie per E-Mail zur Bestätigung aufgefordert.'
    : '';
}
