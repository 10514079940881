import React, { useEffect, useState, useRef } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import KontoLoeschenDialog from '../KontoLoeschenDialog/KontoLoeschenDialog';
import { useDialogState } from '../../hooks';
import { s3ObjectsToFileContainers } from '../Dropzone/Dropzone';
import { gtmEvent } from '../../googleAnalytics';
import {
  useKundenverzeichnis,
  useHandwerkerverzeichnis,
} from '../../DomainContext';
import Handwerkerprofil from './Handwerkerprofil';
import Kundenprofil from './Kundenprofil';
import { ReactComponent as AlertSuccessIcon } from '../../assets/icons/alert_success.svg';
import './ProfilModal.scss';
import { useBenutzerContext } from '../../contexts/BenutzerContext';

export default ({ hide, kunde, handwerker, scrollToDatenschutz }) => {
  const [aktualisierterBenutzer, setAktualisierterBenutzer] = useState(
    kunde ? kunde : handwerker
  );
  const [istProfilGeaendert, setProfilGeaendert] = useState(false);
  const [
    sindPflichtfelderGefuelltUndValide,
    setPflichtfelderGefuelltUndValide,
  ] = useState(Boolean(kunde));

  const [wurdeGespeichert, setWurdeGespeichert] = useState(false);
  const { istHandwerker } = useBenutzerContext();

  useEffect(() => {
    if (!istProfilGeaendert) {
      setAktualisierterBenutzer(kunde ? kunde : handwerker);
    }
    if (istHandwerker && kunde) {
      gtmEvent({
        category: 'request',
        action: 'viewProfile',
        label: 'craftsman',
      });
    } else if (!istHandwerker && handwerker) {
      gtmEvent({
        category: 'request',
        action: 'viewProfile',
        label: 'customer',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handwerker, kunde]);

  useEffect(() => {
    setFirmenlogoFileContainers(
      handwerker && handwerker.firmenlogo
        ? s3ObjectsToFileContainers([handwerker.firmenlogo])
        : []
    );

    setAgbFileContainers(
      handwerker && handwerker.agb
        ? s3ObjectsToFileContainers([handwerker.agb])
        : []
    );

    setDatenschutzbestimmungenFileContainers(
      handwerker && handwerker.datenschutzbestimmungen
        ? s3ObjectsToFileContainers([handwerker.datenschutzbestimmungen])
        : []
    );
  }, [handwerker]);

  const [firmenlogoFileContainers, setFirmenlogoFileContainers] = useState(
    handwerker && handwerker.firmenlogo
      ? s3ObjectsToFileContainers([handwerker.firmenlogo])
      : []
  );
  const [agbFileContainers, setAgbFileContainers] = useState(
    handwerker && handwerker.agb
      ? s3ObjectsToFileContainers([handwerker.agb])
      : []
  );
  const [
    datenschutzbestimmungenFileContainers,
    setDatenschutzbestimmungenFileContainers,
  ] = useState(
    handwerker && handwerker.datenschutzbestimmungen
      ? s3ObjectsToFileContainers([handwerker.datenschutzbestimmungen])
      : []
  );

  const [
    isKontoLoeschenDialogShown,
    showKontoLoeschenDialog,
    hideKontoLoeschenDialog,
  ] = useDialogState();
  const istEigenesProfil = istHandwerker ? !!handwerker : !!kunde;
  const kundenverzeichnis = useKundenverzeichnis();
  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const [isLoading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const istSpeichernMoeglich =
    istProfilGeaendert && sindPflichtfelderGefuelltUndValide;

  const aktualisiereBenutzer = async () => {
    setLoading(true);
    if (istHandwerker) {
      await handwerkerverzeichnis.aktualisiereHandwerker({
        urspruenglicherHandwerker: { ...handwerker },
        aktualisierterHandwerker: { ...aktualisierterBenutzer },
        firmenlogoFileContainers,
        agbFileContainers,
        datenschutzbestimmungenFileContainers,
      });
    } else {
      await kundenverzeichnis.aktualisiereKunde({
        ...aktualisierterBenutzer,
      });
    }
    setLoading(false);
  };

  const actions = istEigenesProfil ? (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        data-cy-id="profil-speichern"
        color="green"
        disabled={!istSpeichernMoeglich}
        onClick={async () => {
          await aktualisiereBenutzer();
          setProfilGeaendert(false);
          setWurdeGespeichert(true);
        }}
        loading={isLoading}
        icon={wurdeGespeichert && !istProfilGeaendert && <AlertSuccessIcon />}
      >
        {wurdeGespeichert && !istProfilGeaendert ? 'Gespeichert' : 'Speichern'}
      </Button>
    </>
  ) : (
    <Button onClick={hide}>Schließen</Button>
  );

  const title = istHandwerker
    ? handwerker
      ? 'Ihr Profil'
      : `Profil von ${kunde.vorname} ${kunde.nachname}`
    : kunde
    ? 'Ihr Profil'
    : `Profil von ${handwerker.firmenname}`;

  return (
    <Modal
      title={title}
      hide={hide}
      actions={actions}
      gaTrackingName={handwerker ? 'Handwerkerprofil' : 'Kundenprofil'}
      contentRef={containerRef}
    >
      <div className="profil" data-cy-is-loading={isLoading}>
        {kunde && (
          <Kundenprofil
            kunde={kunde}
            istEigenesProfil={istEigenesProfil}
            aktualisierterBenutzer={aktualisierterBenutzer}
            setAktualisierterBenutzer={setAktualisierterBenutzer}
            setProfilGeaendert={setProfilGeaendert}
            showKontoLoeschenDialog={showKontoLoeschenDialog}
          />
        )}
        {handwerker && (
          <Handwerkerprofil
            handwerker={handwerker}
            istEigenesProfil={istEigenesProfil}
            aktualisierterBenutzer={aktualisierterBenutzer}
            setAktualisierterBenutzer={setAktualisierterBenutzer}
            setPflichtfelderGefuelltUndValide={
              setPflichtfelderGefuelltUndValide
            }
            setProfilGeaendert={setProfilGeaendert}
            firmenlogoFileContainers={firmenlogoFileContainers}
            setFirmenlogoFileContainers={setFirmenlogoFileContainers}
            agbFileContainers={agbFileContainers}
            setAgbFileContainers={setAgbFileContainers}
            datenschutzbestimmungenFileContainers={
              datenschutzbestimmungenFileContainers
            }
            setDatenschutzbestimmungenFileContainers={
              setDatenschutzbestimmungenFileContainers
            }
            showKontoLoeschenDialog={showKontoLoeschenDialog}
            scrollToDatenschutz={scrollToDatenschutz}
            containerRef={containerRef}
          />
        )}
      </div>
      {isKontoLoeschenDialogShown && (
        <KontoLoeschenDialog hide={hideKontoLoeschenDialog} />
      )}
    </Modal>
  );
};
