import './Impressum.scss';

import LegalContent from '../../components/LegalContent/LegalContent';
import React from 'react';

export default function () {
  return (
    <LegalContent headline="Impressum Provinzial Dienstleistungsgesellschaft">
      <h2 className="legalContent__headline2">Anbieter der Webseite:</h2>
      <div className="legalContent__layout">
        <div className="legalContent__layoutColumn">
          <strong>Provinzial Dienstleistungsgesellschaft mbH</strong>
          <br />
          Dorotheenstraße 3<br />
          45130 Essen
          <br />
          Fax: 0201 – 8125 – 210
          <br />
          <a href="mailto:support@provinzial-meinzuhause.de">
            support@provinzial-meinzuhause.de
          </a>
        </div>
        <div className="legalContent__layoutColumn">
          <strong>Geschäftsführer:</strong>
          <br />
          Konrad Bartsch
          <br />
          Nina Schmal
          <br />
          Markus Schulten
          <br />
          <br />
          Umsatzsteueridentnummer: DE322778458
          <br />
          <br />
          Amtsgericht Essen
          <br />
          HRB 29788
        </div>
      </div>
      <h2 className="legalContent__headline2">Online-Streitbeilegung.</h2>
      <div className="legalContent__layout">
        <div className="legalContent__layoutFull">
          Die EU-Plattform zur Online-Streitbeilegung (OS-Plattform) erreichen
          Sie unter folgendem Link:&nbsp;
          <a
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
        </div>
      </div>
    </LegalContent>
  );
}
