import React from 'react';

export default function KundenText() {
  return (
    <>
      <p className="legalContent__paragraph">
        Die Plattform „MeinZuhauseUndIch“ ist ein Online-Dienst zur Vermittlung
        und zum Angebot von Dienstleistungen. Die Provinzial
        Dienstleistungsgesellschaft mbh (im Folgenden: Provinzial) als Teil des
        Provinzial Rheinland Konzerns möchte als Betreiberin der Plattform
        exklusiv den Versicherungskunden der Provinzial Rheinland Versicherung
        eine schnelle Vermittlung geeigneter und kompetenter Fachpartner und
        Dienstleister und das Angebot von Dienstleistungen rund um das Zuhause
        des Kunden anbieten. Die Nutzung der Plattform unterliegt den
        nachfolgenden Nutzungsbedingungen. Mit Ihrer Registrierung durch Eingabe
        der abgefragten Daten auf der Seite{' '}
        <a href="https://www.meinzuhauseundich.de">meinzuhauseundich.de</a>{' '}
        erkennen Sie die Nutzungsbedingungen an.
      </p>
      <h2 className="legalContent__headline2">1. Nutzungsvertrag</h2>
      <p className="legalContent__paragraph">
        1.1 Mit „MeinZuhauseUndIch“ steht Ihnen als Kunde der Provinzial
        Rheinland Versicherung eine Plattform für die Suche und Beauftragung von
        Fachpartnern und Dienstleistern (im Folgenden: Auftragnehmer) zur
        Verfügung.
      </p>
      <p className="legalContent__paragraph">
        1.2 Mit Ihrer Registrierung kommt ein Nutzungsvertrag betreffend die
        Plattform zwischen Ihnen und der Provinzial zustande. Die Provinzial
        vermittelt Ihnen über die Plattform Handwerksleistungen oder andere
        Dienstleistungen Dritter oder aber bietet Ihnen Dienstleistungen aus
        eigener Hand an. Die Nutzung ist für Sie kostenfrei und bis zu einer
        Beauftragung im Einzelfall unverbindlich.
      </p>
      <p className="legalContent__paragraph">
        1.3 Schließen Sie den Nutzungsvertrag als Verbraucher ab, steht Ihnen
        ein gesetzliches Widerrufsrecht zu, über das Sie gesondert belehrt
        werden.
      </p>
      <h2 className="legalContent__headline2">2. Anmeldung und Nutzerkonto</h2>
      <p className="legalContent__paragraph">
        2.1 Zur Nutzung der Leistungen über die Plattform ist Ihre vorherige
        Registrierung unter Angabe der notwendigen Stammdaten erforderlich.
        Bitte beachten Sie, dass eine Registrierung ausschließlich
        Provinzial-Kunden möglich ist. Es erfolgt daher ein Abgleich der von
        Ihnen angegeben Daten mit der Kundendatenbank der Provinzial.
      </p>
      <p className="legalContent__paragraph">
        2.2 Bei Ihrer Registrierung und bei der weiteren Nutzung der Plattform
        sind Sie verpflichtet, richtige und vollständige Angaben zu machen und
        diese Informationen stets auf aktuellstem Stand zu halten. Änderungen
        der Stammdaten sind jederzeit in Ihrem Nutzerprofil möglich.
      </p>
      <p className="legalContent__paragraph">
        2.3 Nach Ihrer Registrierung erhalten Sie ein eigenes Nutzerkonto. Für
        den Zugang zum Nutzerkonto sind eine Benutzerkennung sowie ein eigenes
        Kennwort erforderlich.
      </p>
      <p className="legalContent__paragraph">
        2.4 Die Provinzial und die Auftragnehmer kontaktieren Sie in der Regel
        über Ihr Nutzerkonto oder per E-Mail, insbesondere bei wichtigen
        Informationen zu Ihrem Nutzungs- oder Auftragsverhältnis. Es ist daher
        wichtig, dass stets Ihre aktuelle E-Mail-Adresse angegeben ist.
      </p>
      <p className="legalContent__paragraph">
        2.5 Mit Ihrer Registrierung widmen Sie Ihr Nutzerkonto als
        Empfangsvorrichtung, das heißt, Sie erklären sich damit einverstanden,
        dass Ihnen über Ihr Nutzerkonto rechtswirksam Dokumente und
        Willenserklärungen übermittelt werden können. Sie sind daher im eigenen
        Interesse verpflichtet, Ihr Nutzerkonto regelmäßig auf neue
        Nachrichteneingänge zu kontrollieren, sofern mit solchen zu rechnen ist.
        Dies gilt insbesondere bei offenen Ausschreibungen oder erteilten
        Aufträgen.
      </p>
      <p className="legalContent__paragraph">
        2.6 Sie dürfen Ihre Zugangsdaten nicht an Dritte weitergeben. Bitte
        ergreifen Sie auch die erforderlichen Maßnahmen, um Ihre Daten gegen dem
        unbefugten Gebrauch durch Dritte zu schützen. Bei Meldung einer
        Fremdnutzung durch Sie oder dem begründeten Verdacht einer solchen, wird
        die Provinzial die notwendigen Maßnahmen ergreifen. Sie ist insbesondere
        berechtigt, den Zugang vorübergehend zu sperren oder zu schließen.
      </p>
      <h2 className="legalContent__headline2">
        3. Vermittlung von Fachpartner- und Dienstleistungen
      </h2>
      <p className="legalContent__paragraph">
        3.1 Sie haben die Möglichkeit, auf der Plattform Aufträge
        auszuschreiben. Die Ausschreibung beinhaltet Ihre unverbindliche
        Aufforderung zur Abgabe von Angeboten durch Auftragnehmer.
      </p>
      <p className="legalContent__paragraph">
        3.2 Der Inhalt der Ausschreibung sollte von Ihnen möglichst vollständige
        und präzise beschrieben werden, um den Auftragnehmern eine realistische
        Kalkulation zu ermöglichen. Sie haben im Rahmen der Ausschreibung die
        Option, Zusatztexte, Bilder und sonstige Daten einzubinden. Wir weisen
        allerdings darauf hin, dass Sie für alle Inhalte, die Sie einstellen
        oder zugänglich machen, selbst verantwortlich sind und über die
        erforderlichen Rechte verfügen müssen. Bitte prüfen Sie daher vor
        Veröffentlichung von Inhalten, ob Sie hierfür über die notwendigen
        Rechte verfügen.
      </p>
      <p className="legalContent__paragraph">
        3.3 Nach Veröffentlichung Ihrer Ausschreibung erhalten Sie innerhalb von
        spätestens drei Werktagen bis zu drei verbindliche Angebote von
        Auftragnehmern oder Rückfragen der Auftragnehmer zu Ihrem Auftrag.
        Auftragnehmer können Ihre Ausschreibung auch innerhalb von drei
        Werktagen ablehnen. Sie entscheiden selbst, ob und welches der Angebote
        Sie annehmen. Sie sind weder an einen bestimmten Auftragnehmer, noch an
        das günstigste Angebot gebunden.
      </p>
      <p className="legalContent__paragraph">
        3.4 Die verbindliche Auftragsvergabe erfolgt direkt auf der Plattform
        durch Betätigen des Buttons „Kostenpflichtig beauftragen“. Als
        Verbraucher steht Ihnen bei dieser Form der Auftragserteilung ein
        gesetzliches Widerrufsrecht zu, über das Sie vor Auftragserteilung
        gesondert belehrt werden.
      </p>
      <p className="legalContent__paragraph">
        3.5 Von Ihnen erstellte Ausschreibungen können Sie vor einer
        verbindlichen Beauftragung jederzeit ohne Angabe von Gründen löschen. In
        diesem Falle verlieren auch die auf diese Ausschreibung hin abgegebenen
        Angebote ihre Verbindlichkeit.
      </p>
      <p className="legalContent__paragraph">
        3.6 Die Provinzial vermittelt lediglich zwischen Ihnen als Auftraggeber
        und dem Auftragnehmer, ist also selbst nicht Beteiligte des vermittelten
        Auftragsverhältnisses und nicht für dessen Durchführung verantwortlich.
        Der Inhalt des Auftragsverhältnisses richtet sich allein nach den
        Vereinbarungen, die Sie mit dem Auftragnehmer getroffen haben.
      </p>
      <p className="legalContent__paragraph legalContent__new">
        3.7 Die Provinzial analysiert und überwacht die Vermittlungsprozesse und
        deren Ergebnisse mit Blick auf die zustande gekommenen sowie nicht
        zustande gekommenen Aufträge an Fachpartner und sonstige Dienstleister.
        Sie behält sich vor, durch Einzelnachfragen bei Kunden sowie bei
        Fachpartnern Hintergründe und Ursachen nicht erfolgreicher
        Vermittlungsangebote zu ermitteln und allgemeine Marktanalysen zur
        Funktionsfähigkeit und zum Service der Vermittlungsplattform z.B.
        mittels Bewertungsanfragen durchzuführen. Für telefonische
        Kontaktaufnahmen holt sie die Provinzial ihre Einwilligung ein.
      </p>
      <p className="legalContent__paragraph">
        3.8 Vermittelt werden Aufträge durch die Provinzial ausschließlich an
        Fachpartner und Dienstleister, die bestimmte Qualitätsanforderungen
        erfüllen, so dass sichergestellt ist, dass nur Auftragnehmer mit
        entsprechender Qualifikation an Sie vermittelt werden. Sämtliche Partner
        haben sich gegenüber der Provinzial verpflichtet, alle einschlägigen
        gesetzlichen Vorgaben und Richtlinien von Handwerkskammern,
        Standesorganisationen und vergleichbaren Institutionen einzuhalten sowie
        eine Betriebshaftpflichtversicherung vorzuhalten.
      </p>
      <h2 className="legalContent__headline2 legalContent__new">
        4. Nutzung von Daten durch die Provinzial Rheinland Versicherung AG
      </h2>
      <p className="legalContent__paragraph legalContent__new">
        Die von der Provinzial im Rahmen ihrer Plattform-Registrierung erhobenen
        Personenstammdaten sowie ihre im Rahmen der Plattformnutzung erhobenen
        Anfrage- und Auftragsdaten (z.B. angefragte oder beauftragte
        Handwerks-Leistung, vermittelter Dienstleister, Ausführungstermin,
        Angebots- und Rechnungsbetrag, Zahlungsflussdaten, Bewertungen von
        Dienstleistern) werden an die Provinzial Rheinland Versicherung AG und
        deren Vermittler, die Sie hinsichtlich Ihrer Versicherungsverträge
        betreuen, weitergeleitet und dort zum Zwecke
      </p>
      <ul className="legalContent__list legalContent__new">
        <li>der Bewertung der bestehenden Versicherungsvertragsbeziehungen,</li>
        <li>
          der umfassenden Betreuung und Beratung innerhalb der bestehenden
          Versicherungskundenbeziehung,
        </li>
        <li>
          der Produktentwicklung und der Entwicklung weitergehender
          Serviceleistungen (wie etwa Schadenverhütungsmaßnahmen),
        </li>
        <li>statistischer Auswertungen,</li>
        <li>
          und der personalisierten Werbung zu Versicherungsprodukten und
          Servicedienstleistungen
        </li>
      </ul>
      <p className="legalContent__paragraph legalContent__new">verarbeitet.</p>
      <p className="legalContent__paragraph legalContent__new">
        Um die Werbemaßnahmen gezielter auf Ihre Kundenwünsche ausrichten zu
        können, werden die o.g. Daten mit den bei der Provinzial Rheinland
        gespeicherten Kunden- und Vertragsdaten verknüpft und ausgewertet.
      </p>
      <h2 className="legalContent__headline2">
        5. Dienstleistungen der Provinzial
      </h2>
      <p className="legalContent__paragraph">
        Die Provinzial beabsichtigt, das derzeitige Leistungsangebot der
        Plattform zukünftig noch zu erweitern, insbesondere Dienstleistungen
        nicht nur zu vermitteln, sondern auch aus eigener Hand anzubieten. In
        diesem Zuge ist sie berechtigt, die AGB einseitig ohne Ihre Zustimmung
        zu ändern, sofern mit dem zusätzlichen Leistungsangebot nicht zugleich
        zusätzliche Kosten oder sonstige nachteilige Veränderung für Sie
        einhergehen.
      </p>
      <h2 className="legalContent__headline2">
        6. Rechtmäßige Nutzung und Zugang
      </h2>
      <p className="legalContent__paragraph">
        6.1 Die Nutzung der Plattform darf nur in Übereinstimmung mit geltendem
        Recht und diesen AGB erfolgen. Dies gilt insbesondere für den Inhalt und
        die Art der eingestellten Aufträge, Abgabe und Inhalt von Bewertungen
        oder Stellungnahmen.
      </p>
      <p className="legalContent__paragraph">
        6.2 Untersagt sind insbesondere:
      </p>
      <ul className="legalContent__list">
        <li>
          die Verbreitung rassistischer, pornografischer, gewaltverherrlichender
          oder –verharmlosender sowie sittenwidriger Inhalte
        </li>
        <li>
          die Verbreitung von Informationen, die Kinder oder Jugendliche in
          ihrer Entwicklung oder Erziehung beeinträchtigen oder gefährden oder
          die die Menschenwürde oder sonstige durch das Strafgesetzbuch
          geschützte Rechtsgüter verletzen
        </li>
        <li>der Aufruf und die Verleitung zu Straftaten</li>
        <li>die Beleidigung, Verleumdung oder Bedrohung anderer Nutzer</li>
        <li>
          die Verletzung von Urheber-, Marken- oder sonstiger Schutzrechte
          Dritter
        </li>
        <li>
          die Vorbereitung und Durchführung von Aufträgen entgegen gesetzlicher
          Bestimmungen zur Bekämpfung der Schwarzarbeit
        </li>
      </ul>
      <p className="legalContent__paragraph">
        6.3 Wird der Provinzial ein schuldhafter Verstoß Ihrerseits gegen
        geltendes Recht oder diese AGB bekannt, ist sie berechtigt,
        erforderliche und angemessene Maßnahmen zu ergreifen. Schwere Verstöße
        können zur vorübergehenden oder völligen Sperrung Ihres Zugangs führen.
        Für diesen Fall behält sich die Provinzial auch das Recht zur fristlosen
        Kündigung aus wichtigem Grund vor.
      </p>
      <h2 className="legalContent__headline2">7. Bewertungen</h2>
      <p className="legalContent__paragraph">
        7.1 Nach Auftragsdurchführung haben Sie als Auftraggeber die
        Möglichkeit, den Auftragnehmer zu bewerten. Die Bewertungen sollen ein
        möglichst aussagekräftiges und zutreffendes Bild über Qualität,
        Zuverlässigkeit und Vertrauenswürdigkeit vermitteln. Die Bewertungen
        sind daher nach dem Gebot der Fairness, sachlich und wahrheitsgemäß zu
        verfassen.
      </p>
      <p className="legalContent__paragraph">
        7.2 Die Provinzial behält sich vor, nachweislich unzutreffende,
        unsachliche oder beleidigende Bewertungen ganz oder vollständig zu
        sperren oder zu löschen.
      </p>
      <h2 className="legalContent__headline2">8. Gewährleistung</h2>
      <p className="legalContent__paragraph">
        8.1 Die Provinzial schuldet im Falle einer Ausschreibung allein die
        Vermittlung von Fachpartner- und Dienstleistungen. Sie haftet jedoch
        nicht für das erfolgreiche Zustandekommen eines entsprechenden
        Auftragsverhältnisses. Sie schuldet gesetzlich auch nicht die
        ordnungsgemäße und mangelfreie Durchführung des vermittelten
        Auftragsverhältnisses.
      </p>
      <p className="legalContent__paragraph">
        8.2 Die Provinzial übernimmt jedoch während des offenen
        Vertragsverhältnisses gesamtschuldnerisch neben dem Auftragnehmer unter
        folgenden Voraussetzungen und im nachfolgend beschriebenen Umfang eine
        5-jährige Gewährleistung für die erbrachten Leistungen:
      </p>
      <ul className="legalContent__list">
        <li>
          Die Gewährleistungsfrist beginnt mit dem Rechnungsdatum des
          Auftragnehmers.
        </li>
        <li>
          Die Gewährleistung bezieht sich nur auf die erbrachte Leistung, nicht
          auf Mängel des genutzten Materials.
        </li>
        <li>
          Die Gewährleistungsansprüche werden von Ihnen während des laufenden
          Vertragsverhältnisse betreffend die Nutzung der Plattform gegenüber
          der Provinzial angemeldet.
        </li>
        <li>
          Mit Beendigung des Vertragsverhältnisses zwischen Ihnen und der
          Provinzial erlöschen sämtliche Gewährleistungsansprüche gegenüber der
          Provinzial. Dies gilt auch für bereits angemeldete Ansprüche. Die
          Gewährleistung des Auftragnehmers bleibt hiervon unberührt.
        </li>
        <li>
          Sie treten mit Geltendmachung Ihrer Ansprüche gegenüber der Provinzial
          Ihre gesetzlichen oder mit dem Auftragnehmer vertraglich vereinbarten
          Gewährleistungsansprüche an die Provinzial ab. Für den Fall der
          Beendigung des Vertragsverhältnisses und des Erlöschens Ihrer
          Gewährleistungsansprüche gegen die Provinzial, tritt Ihnen die
          Provinzial Ihre Gewährleistungsansprüche gegen den Auftragnehmer
          wieder zu Ihrer freien Verfügung ab.
        </li>
        <li>
          Der Mangel wird durch die Provinzial geprüft. Bei Vorliegen eines
          berechtigten Mangels räumt die Provinzial dem Auftragnehmer zunächst
          unter angemessener Fristsetzung die Möglichkeit der Mangelbeseitigung
          / Nachbesserung ein. Wenn die Mangelbeseitigung / Nachbesserung vom
          Auftragnehmer nicht fristgerecht wahrgenommen oder verweigert wird,
          beauftragt die Provinzial einen Dritten mit der Mangelbeseitigung /
          Nachbesserung.
        </li>
        <li>
          Für den Fall, dass der Mangel durch einen von der Provinzial
          beauftragten Dritten beseitigt wird, behält sich die Provinzial den
          Rückgriff wegen der hierdurch entstandenen Kosten gegen den
          Auftragnehmer vor.
        </li>
      </ul>
      <p className="legalContent__paragraph">
        8.3 Des Weiteren können sich sowohl Auftraggeber wie auch Auftragnehmer
        bei Beanstandungen betreffend das Auftragsverhältnis jederzeit an den
        Kundenservice der Provinzial wenden. Nach Schilderung des konkreten
        Problems wird die Provinzial sich mit den Beteiligten in Verbindung
        setzen und versuchen, das Problem vermittelnd zu lösen.
      </p>
      <p className="legalContent__paragraph">
        8.4 Die Provinzial kann ferner nicht gewährleisten, dass die Plattform
        zu jeder Zeit uneingeschränkt verfügbar ist. Soweit dies möglich ist,
        wird sich die Provinzial bemühen, Beschränkungen, Beeinträchtigungen,
        Verbindungsabbrüche oder sonstige Nutzungseinschränkungen zu vermeiden.
        Die Verfügbarkeit kann allerdings beim gegenwärtigen Stand der
        Kommunikationstechnik ganz oder teilweise eingeschränkt sein, ohne dass
        die Provinzial hierauf Einfluss nehmen kann. Eine Haftung ist insofern
        ausgeschlossen.
      </p>
      <h2 className="legalContent__headline2">9. Haftung</h2>
      <p className="legalContent__paragraph">
        9.1 Die Provinzial haftet unbeschränkt für Schäden, die auf Vorsatz oder
        grober Fahrlässigkeit beruhen.
      </p>
      <p className="legalContent__paragraph">
        9.2 Ferner haftet die Provinzial für Schäden, die aufgrund einer einfach
        fahrlässigen Verletzung wesentlicher Vertragspflichten beruhen.
        Wesentliche Vertragspflichten in diesem Sinne sind solche, deren
        Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht
        oder deren Verletzung die Erreichung des Vertragszwecks gefährdet und
        auf deren Einhaltung der Vertragspartner regelmäßig vertraut. In diesen
        Fällen ist die Haftung der Provinzial jedoch auf den vorhersehbaren,
        vertragstypischen Schaden begrenzt.
      </p>
      <p className="legalContent__paragraph">
        Die vorstehenden Haftungsbeschränkungen gelten nicht bei der Verletzung
        von Leben, Körper und Gesundheit, im Falle einer Garantieübernahme und
        eines arglistig verschwiegenen Mangels. Die Haftung nach dem
        Produkthaftungsgesetz bleibt von den hier vorliegenden
        Haftungsbeschränkungen ebenfalls unberührt.
      </p>
      <p className="legalContent__paragraph">
        9.3 Soweit die Haftung der Provinzial ausgeschlossen oder beschränkt
        ist, gilt dies auch für Pflichtverletzungen durch ihre Vertreter oder
        Erfüllungsgehilfen.
      </p>
      <h2 className="legalContent__headline2">10. Laufzeit und Beendigung</h2>
      <p className="legalContent__paragraph">
        10.1 Der Nutzungsvertrag beginnt mit Ihrer erfolgreich abgeschlossenen
        Registrierung und Aktivierung Ihres Nutzerkontos. Er ist auf unbestimmte
        Zeit geschlossen.
      </p>
      <p className="legalContent__paragraph">
        10.2 Der Nutzungsvertrag kann von Ihnen jederzeit ohne Einhaltung einer
        Frist und ohne Angabe von Gründen gekündigt werden. Die Kündigung kann
        schriftlich an Provinzial Dienstleistungsgesellschaft mbH,
        Dorotheenstraße 3, 45130 Essen) oder per Mail an{' '}
        <a href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>{' '}
        oder direkt innerhalb Ihres Nutzerkontos erklärt werden.
      </p>
      <p className="legalContent__paragraph">
        10.3 Der Nutzungsvertrag endet des Weiteren automatisch mit Beendigung
        Ihrer Geschäftsbeziehung zur Provinzial Rheinland Versicherung.
      </p>
      <p className="legalContent__paragraph">
        10.4 Die Provinzial ist berechtigt, den Nutzungsvertrag mit einer Frist
        von 14 Tagen zu kündigen.
      </p>
      <p className="legalContent__paragraph">
        10.5 Das Recht für beide Parteien, den Vertrag aus wichtigem Grunde zu
        kündigen, bleibt unberührt.
      </p>
      <p className="legalContent__paragraph">
        10.6 Bitte beachten Sie, dass Sie ab dem Zeitpunkt der Beendigung des
        Nutzungsvertrages keine Dienste mehr über die Plattform in Anspruch
        nehmen können. Bereits beauftragte Fachpartner- und Dienstleitungen
        bleiben unberührt und werden noch durchgeführt.
      </p>
      <h2 className="legalContent__headline2">11. Datenschutz</h2>
      <p className="legalContent__paragraph">
        11.1 Wir verarbeiten Ihre personenbezogenen Daten ausschließlich für die
        Bereitstellung und Nutzung unserer Dienste gemäß dem mit Ihnen
        abgeschlossenen Nutzungsvertrag, es sei denn, eine weitergehende
        Datenverarbeitung ist gesetzlich vorgesehen oder Sie haben uns
        ausdrücklich eine weitergehende Einwilligungserklärung erteilt.
      </p>
      <p className="legalContent__paragraph">
        11.2 Die Vermittlung von Handwerks – und Dienstleistungen kann
        insbesondere nur erfolgen, wenn wir Ihre Daten an Auftragnehmer aus dem
        Fachpartnernetz weiterleiten.
      </p>
      <p className="legalContent__paragraph">
        11.3 Bei der Verarbeitung Ihrer personenbezogenen Daten beachten wir die
        jeweils geltenden Vorgaben nach der DS-GVO und dem BDSG. Details zur
        Verarbeitung entnehmen Sie bitte der gesonderten Datenschutzhinweise,
        die Sie{' '}
        <a href="https://meinzuhauseundich.de/datenschutzerklaerung">hier</a>{' '}
        abrufen können.
      </p>
      <h2 className="legalContent__headline2">
        12. Änderung der Nutzungsbedingungen
      </h2>
      <p className="legalContent__paragraph">
        12.1 Die Provinzial ist berechtigt, diese AGB jederzeit mit Wirkung für
        die Zukunft unter Einhaltung der nachfolgenden Bedingungen zu ändern.
      </p>
      <p className="legalContent__paragraph">
        12.2 Der Änderungsvorbehalt gilt nur, wenn Ihnen die Änderung unter
        Berücksichtigung der Interessen der Provinzial zumutbar ist. Die
        Provinzial wird in jedem Falle sicherstellen, dass bereits laufende
        Ausschreibungen zu den bisherigen Nutzungsbedingungen durchgeführt
        werden.
      </p>
      <p className="legalContent__paragraph">
        12.3{' '}
        <strong>
          Änderungen wird Ihnen die Provinzial vor dem Zeitpunkt des Eintritts
          der Änderung per E-Mail mitteilen.{' '}
        </strong>
        Änderungen wird ihnen die Provinzial spätestens einen Monat vor dem
        Zeitpunkt des Eintritts der Änderung per E-Mail mitteilen. Sie sind
        berechtigt, der geplanten Änderung innerhalb von zwei Monaten ab Zugang
        der Mitteilung zu widersprechen. Die Provinzial wird Sie in der
        Mitteilung auf dieses Widerspruchsrecht hinweisen.
      </p>
      <p className="legalContent__paragraph">
        Widersprechen Sie nicht oder stimmen Sie der Änderung ausdrücklich zu,
        werden die Änderungen zum mitgeteilten Zeitpunkt wirksam. Im Falle Ihres
        fristgemäßen Widerspruchs ist die Provinzial berechtigt, den
        Nutzungsvertrag zu kündigen.
      </p>
      <p className="legalContent__paragraph legalContent__new">
        Widersprechen Sie nicht oder stimmen Sie der Änderung ausdrücklich zu,
        werden die Änderungen zum mitgeteilten Zeitpunkt wirksam. Im Falle Ihres
        fristgemäßen Widerspruchs endet ihr Nutzungsvertrag nach Abwicklung von
        bis dahin bestehenden Vermittlungsanfragen und -aufträgen.
      </p>
      <p className="legalContent__paragraph">
        12.4 Reine Erweiterungen des Leistungsangebots über die Plattform ohne
        diesbezügliche zusätzliche Kosten für Sie sind als lediglich rechtlich
        vorteilhaft für Sie jederzeit ohne die hier genannten Voraussetzungen
        zulässig.
      </p>
      <h2 className="legalContent__headline2">13. Schlussbestimmungen</h2>
      <p className="legalContent__paragraph">
        13.1 Es gilt das Recht der Bundesrepublik Deutschland.
      </p>
      <p className="legalContent__paragraph">
        13.2 Sollte eine Bestimmung dieses Vertrages und/oder dieser Bedingungen
        unwirksam oder unvollständig sein, bleiben die übrigen Bestimmungen
        hiervon unberührt. An Stelle der unwirksamen oder unvollständigen
        Bestimmung werden die Parteien eine solche vereinbaren, die beide Seiten
        bei Kenntnis der Unwirksamkeit oder Unvollständigkeit wirtschaftlich
        vereinbart hätten.
      </p>
      <p className="legalContent__paragraph">
        13.3 Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein
        Rechtsgeschäft zu einem Zwecke abschließt, der überwiegend weder ihrer
        gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet
        werden kann (§ 13 BGB).
      </p>
      <p className="legalContent__paragraph">
        13.4 Sofern Sie ein in Deutschland ansässiger Kaufmann im Sinne des
        Handelsgesetzbuches sind, wird Essen als Gerichtsstand für alle
        Streitigkeiten im Zusammenhang mit dem Nutzungsvertrag zur Plattform
        „MeinZuhauseUndIch“ vereinbart.
      </p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a name="widerrufsbelehrung" className="legalContent__anchorLink">
        <h1 className="legalContent__headline1 legalContent__headline1--inline">
          Widerrufsbelehrung
        </h1>
      </a>
      <h2 className="legalContent__headline2">Widerrufsrecht</h2>
      <p className="legalContent__paragraph">
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen.
      </p>
      <p className="legalContent__paragraph">
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
        Vertragsabschlusses.
      </p>
      <p className="legalContent__paragraph">
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, Provinzial
        Dienstleistungsgesellschaft mbH, Dorotheenstraße 3, 45130 Essen,
        Telefon: 0201 – 8125 – 400 Fax: 0201 – 8125 – 210,{' '}
        <a href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>
        , mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
        Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </p>
      <p className="legalContent__paragraph">
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
      </p>
      <br />
      <h2 className="legalContent__headline2">Folgen des Widerrufs</h2>
      <p className="legalContent__paragraph">
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die von uns angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
      </p>
      <p className="legalContent__paragraph">
        Haben Sie verlangt, dass die Dienstleistung während der Widerrufsfrist
        beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der
        dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits
        erbrachten Dienstleistung im Vergleich zum Gesamtumfang der im Vertrag
        vorgesehenen Dienstleistung entspricht.
      </p>
      <br />
      <p className="legalContent__paragraph">
        Hier das Muster zum{' '}
        <a
          href={`/Muster_Widerrufsformular_Registrierung_Plattform.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          schriftlichen Widerruf
        </a>
        .
      </p>
    </>
  );
}
