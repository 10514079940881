import React from 'react';
import classNames from 'classnames';

import './Button.scss';

export default function Button({
  children,
  icon,
  iconPosition = 'left',
  loading = false,
  className,
  color = 'transparent',
  size = 'medium',
  elevated = false,
  outlined = false,
  ...props
}) {
  const hasIcon = Boolean(icon);
  const isElevated = Boolean(elevated);
  const isOutlined = Boolean(outlined);

  const isIconOnly = hasIcon && !children;

  return (
    <button
      className={classNames(
        'button',
        `button--${size}`,
        `button--${color}`,
        className,
        {
          'button--hasIcon': hasIcon,
          'button--isIconOnly': isIconOnly,
          'button--loading': loading,
          'button--elevated': isElevated,
          'button--outline': isOutlined,
        }
      )}
      {...props}
    >
      {icon && iconPosition !== 'right' && (
        <span className="button__icon">{icon}</span>
      )}
      {!isIconOnly && children && (
        <span
          className={classNames('button__text', {
            [`button__text--${iconPosition ?? 'left'}Padding`]: hasIcon,
          })}
        >
          {children}
        </span>
      )}
      {icon && iconPosition === 'right' && (
        <span className="button__icon">{icon}</span>
      )}
    </button>
  );
}
