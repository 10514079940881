export function pruefeIstHandwerker(user) {
  if (typeof user === 'undefined') {
    throw new Error('pruefeIstHandwerker must be called with a CognitoUser.');
  }
  if (user === null) {
    return null;
  }
  return !!(
    user &&
    user.signInUserSession.idToken.payload['cognito:groups'] &&
    user.signInUserSession.idToken.payload['cognito:groups'].includes(
      'Handwerker'
    )
  );
}
