import {
  bereich,
  hatWerte,
  istSchritt,
  objektart,
  oder,
  taetigkeit,
  teiltaetigkeit,
  und,
} from './helfer';
import {
  BEREICH_AUSSEN,
  BEREICH_INNEN,
  KATEGORIE_INDEX_HANDWERKERAUSWAHL,
  OBJEKTART_BODENARBEITEN,
  OBJEKTART_DACHARBEITEN,
  OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN,
  OBJEKTART_FASSADENARBEITEN,
  OBJEKTART_FENSTER_TUERARBEITEN,
  OBJEKTART_HEIZUNG_SANITAER_KLIMA,
  OBJEKTART_HOLZARBEITEN,
  OBJEKTART_SONSTIGE_AUSSENARBEITEN,
  OBJEKTART_SONSTIGE_INNENARBEITEN,
  OBJEKTART_TREPPEN_GELAENDERARBEITEN,
  OBJEKTART_WAND_DECKENARBEITEN,
  TAETIGKEIT_DACHCHECK,
  TEILTAETIGKEIT_DACHCHECK_BASIS,
  TEILTAETIGKEIT_DACHCHECK_PREMIUM,
  TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN,
  TAETIGKEIT_FASSADE_STREICHEN,
  TAETIGKEIT_FASSADE_VERPUTZEN,
  TAETIGKEIT_FENSTER_BEHANDELN,
  TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN,
  TAETIGKEIT_TUEREN_BEHANDELN,
  TAETIGKEIT_WAENDE_DECKEN_STREICHEN,
  TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN,
  TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN,
  OBJEKTART_BALKON_TERRASSENARBEITEN,
  TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN,
  OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST,
  TAETIGKEIT_RASENMAEHEN,
  TAETIGKEIT_REINIGUNG_HOFFLAECHE,
  TAETIGKEIT_BESEITIGUNG_GARTENABFAELLE,
  TAETIGKEIT_SCHNITTARBEITEN_HECKE_BUESCHE,
  TAETIGKEIT_AUFBAU_GARTENHAUS,
  OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE,
  OBJEKTART_KLEINMONTAGE,
  TAETIGKEIT_WINTERDIENST,
} from './konstanten';

const SCHRITTE_HANDWERKERAUSWAHL = [
  {
    mussAngemeldetSein: true,
    typ: 'handwerkerauswahl',
    frage: 'Handwerkerauswahl',
    kategorieIndex: KATEGORIE_INDEX_HANDWERKERAUSWAHL,
    bedingung: oder(
      und(
        istSchritt(9),
        oder(
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_WAND_DECKENARBEITEN),
            taetigkeit(TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_WAND_DECKENARBEITEN),
            taetigkeit(TAETIGKEIT_WAENDE_DECKEN_STREICHEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN),
            taetigkeit(TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_WAND_DECKENARBEITEN),
            taetigkeit(TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_WAND_DECKENARBEITEN),
            taetigkeit(TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_FENSTER_TUERARBEITEN),
            taetigkeit(TAETIGKEIT_FENSTER_BEHANDELN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_FENSTER_TUERARBEITEN),
            taetigkeit(TAETIGKEIT_TUEREN_BEHANDELN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FASSADENARBEITEN),
            taetigkeit(TAETIGKEIT_FASSADE_STREICHEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FASSADENARBEITEN),
            taetigkeit(TAETIGKEIT_FASSADE_VERPUTZEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FASSADENARBEITEN),
            taetigkeit(TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_DACHARBEITEN),
            taetigkeit(TAETIGKEIT_DACHCHECK),
            teiltaetigkeit(TEILTAETIGKEIT_DACHCHECK_PREMIUM)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_DACHARBEITEN),
            taetigkeit(TAETIGKEIT_DACHCHECK),
            teiltaetigkeit(TEILTAETIGKEIT_DACHCHECK_BASIS)
          )
        )
      ),
      und(
        istSchritt(7),
        oder(
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_WAND_DECKENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_FENSTER_TUERARBEITEN)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_BODENARBEITEN)),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_HEIZUNG_SANITAER_KLIMA)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_HOLZARBEITEN)),

          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_DACHARBEITEN)),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_BALKON_TERRASSENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FASSADENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_FENSTER_TUERARBEITEN)
          ),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_BODENARBEITEN)),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_BODENARBEITEN)),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST),
            taetigkeit(TAETIGKEIT_RASENMAEHEN)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST),
            taetigkeit(TAETIGKEIT_REINIGUNG_HOFFLAECHE)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST),
            taetigkeit(TAETIGKEIT_BESEITIGUNG_GARTENABFAELLE)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST),
            taetigkeit(TAETIGKEIT_SCHNITTARBEITEN_HECKE_BUESCHE)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST),
            taetigkeit(TAETIGKEIT_AUFBAU_GARTENHAUS)
          ),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST),
            taetigkeit(TAETIGKEIT_WINTERDIENST)
          )
        )
      ),
      und(
        istSchritt(6),
        oder(
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_SONSTIGE_INNENARBEITEN)
          ),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_HOLZARBEITEN)),
          hatWerte(
            bereich(BEREICH_AUSSEN),
            objektart(OBJEKTART_SONSTIGE_AUSSENARBEITEN)
          ),
          hatWerte(
            bereich(BEREICH_INNEN),
            objektart(OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE)
          ),
          hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_KLEINMONTAGE)),
          hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_KLEINMONTAGE))
        )
      )
    ),
  },
];

export default SCHRITTE_HANDWERKERAUSWAHL;
