import React, { useContext, useState } from 'react';
import { URL_REGEX_PATTERN } from '../../../../components/ProfilModal/validierung';
import {
  SelectWrapper,
  TextInputWrapper,
} from '../../../../components/ProfilModal/Components';
import Dropzone from '../../../../components/Dropzone/Dropzone';
import { ACCEPTED_TYPES_IMAGES_ONLY } from '../../../../components/Dropzone/constants';
import Link from '../../../../components/Link/Link';
import {
  ProfilVervollstaendigenDispatchContext,
  ProfilVervollstaendigenStateContext,
  istEinOptionalesFeldGefuellt,
  useStepValidator,
  useStepGtmEvent,
} from '../ProfilVervollstaendigen';
import { Filialen } from '../../../../components/ProfilModal/Filialen';
export function Firmendaten(props) {
  const { setAktualisierterBenutzer, setFirmenlogoFileContainers } = useContext(
    ProfilVervollstaendigenDispatchContext
  );
  const { setStepValid, isValid } = props;
  const state = useContext(ProfilVervollstaendigenStateContext);
  const [istWebseitenLinkValide, setWebseitenLinkValide] = useState(true);
  const [weitereFelderEingeblendet, setWeitereFelderEingeblendet] = useState(
    istEinOptionalesFeldGefuellt(state.aktualisierterBenutzer)
  );
  const aktualisiereBenutzer = (benutzer) => {
    setStepValid(isValid({ aktualisierterBenutzer: benutzer }));
    setAktualisierterBenutzer(benutzer);
  };
  useStepValidator(props);
  useStepGtmEvent('companyData');
  const validiereLink = (event) => {
    const feldname = event.target.name;
    const feldwert = event.target.value;
    const istFeldGueltig = event.target.validity.valid;
    const istFeldVorhandenUndGueltig = feldwert && istFeldGueltig;
    const istFeldLeerOderGueltig = !feldwert || istFeldGueltig;
    setWebseitenLinkValide(istFeldLeerOderGueltig);
    if (
      istFeldVorhandenUndGueltig &&
      !feldwert.startsWith('http://') &&
      !feldwert.startsWith('https://')
    ) {
      aktualisiereBenutzer({
        ...state.aktualisierterBenutzer,
        [feldname]: 'http://'.concat(feldwert),
      });
    }
  };
  return (
    <>
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Kontaktdaten
      </h2>
      <div className="profil__section profil__section--zweispaltig margin:bottom:2rem">
        <div>
          <TextInputWrapper
            validateOnBlur
            attribut="strasse"
            label="Straße"
            aktualisierterBenutzer={state.aktualisierterBenutzer}
            setAktualisierterBenutzer={aktualisiereBenutzer}
            validierungstext={
              !state.aktualisierterBenutzer.strasse && 'Straße fehlt'
            }
          />
          <div className="profil__plzUndOrt">
            <div className="profil__label--plz">
              <TextInputWrapper
                validateOnBlur
                attribut="plz"
                label="PLZ"
                labelClassName="textInput__label--plz"
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
                validierungstext={
                  !state.aktualisierterBenutzer.plz && 'PLZ fehlt'
                }
              />
            </div>
            <div className="profil__label--ort">
              <TextInputWrapper
                validateOnBlur
                attribut="ort"
                label="Ort"
                labelClassName="textInput__label--ort"
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
                validierungstext={
                  !state.aktualisierterBenutzer.ort && 'Ort fehlt'
                }
              />
            </div>
          </div>
        </div>
        <div>
          <TextInputWrapper
            validateOnBlur
            attribut="telefon"
            label="Telefon"
            aktualisierterBenutzer={state.aktualisierterBenutzer}
            setAktualisierterBenutzer={setAktualisierterBenutzer}
            validierungstext={
              !state.aktualisierterBenutzer.telefon && 'Telefonnummer fehlt'
            }
          />
        </div>
      </div>
      {!weitereFelderEingeblendet && (
        <div className="profil__section">
          <Link
            data-cy-id="optionaleFelderAnzeigenButton"
            onClick={() => setWeitereFelderEingeblendet(true)}
          >
            Weitere Felder einblenden
          </Link>
        </div>
      )}
      {weitereFelderEingeblendet && (
        <>
          <div className="profil__section profil__section--zweispaltig">
            <div>
              <TextInputWrapper
                attribut="mobil"
                label="Mobiltelefon"
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
              />
              <TextInputWrapper
                attribut="telefonischeErreichbarkeit"
                label="Telefonische Erreichbarkeit (optional)"
                placeholder="z.B. Mo - Fr: 08 - 18 Uhr"
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
              />
              <TextInputWrapper
                attribut="webseite"
                label="Webseite (optional)"
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
                onBlur={validiereLink}
                pattern={URL_REGEX_PATTERN}
                validierungstext={
                  !istWebseitenLinkValide &&
                  'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
                }
              />
              <TextInputWrapper
                attribut="firmengruendung"
                label="Firmengründung (optional)"
                placeholder="JJJJ"
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
              />
              <SelectWrapper
                attribut="anzahlMitarbeiter"
                label="Anzahl Mitarbeiter (optional)"
                options={[
                  '',
                  'bis 4',
                  '5 bis 9',
                  '10 bis 19',
                  '20 bis 49',
                  '50 und mehr',
                ]}
                aktualisierterBenutzer={state.aktualisierterBenutzer}
                setAktualisierterBenutzer={aktualisiereBenutzer}
              />
            </div>
            <div>
              <label className="profil__label">
                Firmenlogo / Teambild (optional)
                <Dropzone
                  fileContainers={state.firmenlogoFileContainers}
                  dataCyId="firmenlogo-dropzone"
                  className="profil__firmenlogoDropzone"
                  acceptedTypes={ACCEPTED_TYPES_IMAGES_ONLY}
                  onChange={(fileContainers) =>
                    setFirmenlogoFileContainers(fileContainers)
                  }
                />
              </label>
            </div>
          </div>
          <div className="profil__section">
            <div>
              <h2 className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem margin:top:1rem">
                Filialen
              </h2>
              <p>
                Sie sind überregional aktiv und haben mehrere Filialen? Dann
                können Sie diese hier hinterlegen um besser gefunden zu werden.
              </p>
              <Filialen
                filialen={state.aktualisierterBenutzer.filialen || []}
                onChange={(filialen) => {
                  aktualisiereBenutzer({
                    ...state.aktualisierterBenutzer,
                    filialen,
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
