import './Startseite.scss';

import React, { useEffect, useState } from 'react';
import Timeline, { TimelineItem } from '../../components/Timeline/Timeline';

import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import Modal from '../../components/Modal/Modal';
import StartseiteBuehne from './StartseiteBuehne';
import abwicklungUndDokumentationIcon from '../../assets/icons/vorteile/abwicklung-und-dokumentation.svg';
import individuellesFirmenprofilIcon from '../../assets/icons/vorteile/individuelles-firmenprofil.svg';
import lukrativeAuftraegeIcon from '../../assets/icons/vorteile/lukrative-auftraege.svg';
import unterstuetzungIcon from '../../assets/icons/vorteile/unterstuetzung-im-fall-der-faelle.svg';
import { useBenutzerContext } from '../../contexts/BenutzerContext';
import { useModalState } from '../../hooks';

export default function Welcome({
  history,
  match: {
    params: { handwerkerdaten: blob },
  },
}) {
  const [
    isInformationenModalVisible,
    showInformationenModal,
    hideInformationenModal,
  ] = useModalState();
  const [information, setInformation] = useState(null);
  const isBlobParseable = useBlob(blob);
  const { benutzer: handwerker } = useBenutzerContext();

  const showInformation = (information) => {
    setInformation(information);
    showInformationenModal();
  };

  const informationen = [
    {
      title: 'Verbindliche Service-Levels',
      gaTrackingName: 'serviceLevels',
      component: <ServiceLevels />,
    },
    {
      title: 'Unterschiede zwischen ProGS und MeinZuhauseUndIch',
      gaTrackingName: 'differences',
      component: <Unterschiede />,
    },
    {
      title: 'Fragen und Antworten',
      gaTrackingName: 'craftsmanFaq',
      component: <FragenUndAntworten />,
    },
  ];

  const Information = () => informationen[information]?.component;

  if (isBlobParseable || handwerker) {
    return (
      <div className="startseite">
        <StartseiteBuehne />
        <div className="startseite__inhaltWrapper">
          <div className="startseite__inhalt">
            <div className="startseite__bereich">
              <div className="startseite__teaser">
                <div className="startseite__teaserContent">
                  <h1 className="startseite__ueberschrift">
                    Willkommen bei <strong>“MeinZuhauseUndIch”</strong>
                  </h1>
                  <p className="startseite__beschreibung">
                    Als Profi stehen Sie Provinzial-Kunden in unserem
                    Handwerkernetz bei einem Schaden bereits zur Seite. Über
                    unsere neue „MeinZuhauseUndIch“- Plattform haben Sie nun die
                    Möglichkeit unsere Kunden mit dem Service
                    „MeinZuhauseUndIch“ auch bei privaten Instandhaltungs- oder
                    Modernisierungsvorhaben zu unterstützen.
                  </p>
                  {!handwerker && (
                    <>
                      <p className="startseite__beschreibung">
                        Werden Sie Teil unseres wachsenden Netzwerks!
                      </p>
                      <Button
                        color="yellow"
                        size="large"
                        data-cy-id="registrierungOeffnenButton"
                        onClick={() => {
                          history.push(`/handwerker/registrieren/${blob}`);
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        Jetzt kostenlos registrieren
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="startseite__bereich startseite__bereich--light startseite__bereich--collapsedMargin">
              <div className="startseite__teaser">
                <div className="startseite__teaserContent">
                  <h2 className="startseite__ueberschrift">
                    Ihre Vorteile auf einen Blick:
                  </h2>
                  <div className="startseite__handwerkervorteile">
                    <div className="startseite__handwerkervorteil">
                      <div className="startseite__handwerkervorteiltext">
                        <h2 className="startseite__ueberschrift startseite__ueberschrift--tiny">
                          Lukrative Aufträge mit wenig Aufwand gewinnen
                        </h2>
                        <p className="startseite__beschreibung">
                          Werden Sie von Provinzial-Kunden auch außerhalb des
                          Schadenfalls angesprochen. Ohne Rahmenpreisabkommen.
                          Sie entscheiden, ob Sie einer Anfrage nachgehen oder
                          ob Sie diese ablehnen möchten.
                        </p>
                      </div>
                      <div className="startseite__handwerkervorteilbild">
                        <img
                          src={lukrativeAuftraegeIcon}
                          alt="Lukrative Aufträge mit wenig Aufwand gewinnen"
                        />
                      </div>
                    </div>

                    <div className="startseite__handwerkervorteil">
                      <div className="startseite__handwerkervorteiltext">
                        <h2 className="startseite__ueberschrift startseite__ueberschrift--tiny">
                          Individuelles Firmenprofil
                        </h2>
                        <p className="startseite__beschreibung">
                          Sie entscheiden, welche Leistungen aus Ihrem Portfolio
                          angeboten werden sollen. Überzeugen Sie Kunden durch
                          Angabe Ihrer Zusatzqualifikationen und machen Sie
                          durch ein aussagekräftiges Profil auf sich aufmerksam.
                        </p>
                      </div>
                      <div className="startseite__handwerkervorteilbild">
                        <img
                          src={individuellesFirmenprofilIcon}
                          alt="Individuelles Firmenprofil"
                        />
                      </div>
                    </div>

                    <div className="startseite__handwerkervorteil">
                      <div className="startseite__handwerkervorteiltext">
                        <h2 className="startseite__ueberschrift startseite__ueberschrift--tiny">
                          Abwicklung und Dokumentation an einem Ort
                        </h2>
                        <p className="startseite__beschreibung">
                          Nutzen Sie die Funktionen von “MeinZuhauseUndIch” vom
                          Erstkontakt, über Angebotserstellung, zur
                          Auftragsabnahme bis hin zur Rechnungsübermittlung.
                          Einfach und übersichtlich, dauerhaft dokumentiert.
                        </p>
                      </div>
                      <div className="startseite__handwerkervorteilbild">
                        <img
                          src={abwicklungUndDokumentationIcon}
                          alt="Abwicklung und Dokumentation an einem Ort"
                        />
                      </div>
                    </div>

                    <div className="startseite__handwerkervorteil">
                      <div className="startseite__handwerkervorteiltext">
                        <h2 className="startseite__ueberschrift startseite__ueberschrift--tiny">
                          Unterstützung im Fall der Fälle
                        </h2>
                        <p className="startseite__beschreibung">
                          Sollte es einmal zu Problemen zwischen Ihnen und einem
                          Kunden kommen, unterstützen wir Sie. Unsere geschulten
                          Service-Mitarbeiter helfen gerne bei der Vermittlung
                          und Schlichtung, auch persönlich am Telefon.
                        </p>
                      </div>
                      <div className="startseite__handwerkervorteilbild">
                        <img
                          src={unterstuetzungIcon}
                          alt="Unterstützung im Fall der Fälle"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!handwerker && (
              <div className="startseite__bereich startseite__bereich--dark">
                <div className="startseite__teaser">
                  <div className="startseite__teaserContent">
                    <h2 className="startseite__ueberschrift startseite__ueberschrift--small">
                      Werden Sie Teil unseres wachsenden Netzwerks!
                    </h2>

                    <Button
                      color="yellow"
                      size="large"
                      data-cy-id="registrierungOeffnenButton"
                      onClick={() => {
                        history.push(`/handwerker/registrieren/${blob}`);
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      Jetzt kostenlos registrieren
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <div className="startseite__bereich">
              <div className="startseite__teaser">
                <div className="startseite__teaserContent">
                  <h2 className="startseite__ueberschrift startseite__ueberschrift--medium">
                    So funktioniert <strong>“MeinZuhauseUndIch”</strong>
                  </h2>
                  <p className="startseite__beschreibung">
                    Sie können “MeinZuhauseUndIch” durchweg vom ersten
                    Kundenkontakt bis hin zum erfolgreichen Abschluss des
                    Auftrags durch die Bezahlung der Rechnung verwenden. Hier
                    zeigen wir Ihnen wie:
                  </p>
                </div>
              </div>
              <div className="margin:top:4rem@desktop">
                <Timeline>
                  <TimelineItem headline="Gefunden werden">
                    “MeinZuhauseUndIch” - Kunden haben die Möglichkeit, Ihre
                    Anfrage an bis zu drei Handwerks&shy;betriebe zu schicken.
                    Welche Ihnen angezeigt werden, hängt vom Einsatz&shy;gebiet
                    und den Tätigkeits&shy;schwerpunkten der Betriebe ab.
                  </TimelineItem>
                  <TimelineItem headline="Anfrage annehmen oder ablehnen">
                    Anhand der detaillierten Informationen des Kunden
                    entscheiden Sie, ob eine Anfrage zu Ihnen passt oder nicht.
                    Mit "Annehmen" treten Sie mit dem Kunden in Kontakt - eine
                    Verpflichtung zur Auftrags&shy;ausführung entsteht aber noch
                    nicht.
                  </TimelineItem>
                  <TimelineItem headline="Kommunikation über die Plattform">
                    Nutzen Sie für eine einfache und sichere Kommunikation
                    unseren Chat. Hier können Sie und der Kunde auch Dateien wie
                    Fotos und PDFs versenden. Die gesamte Kommunikation wird für
                    Ihre Sicherheit gespeichert.
                  </TimelineItem>
                  <TimelineItem headline="Angebot online versenden">
                    Nun können Sie sich bequem über die Plattform oder direkt
                    per Telefon mit dem Kunden abstimmen. Sind Sie sich einig,
                    können Sie online ein Angebot hochladen.
                  </TimelineItem>
                  <TimelineItem headline="Abnahme und Rechnungs&shy;stellung">
                    Ist alles erledigt, können Sie selbst den Abnahmeprozess
                    starten. Anschließend können Sie die geleisteten Tätigkeiten
                    in Rechnung stellen, ebenfalls direkt online auf der
                    Plattform. Ist diese vom Kunden bezahlt, müssen Sie dies nur
                    noch bestätigen.
                  </TimelineItem>
                </Timeline>
              </div>
            </div>
            <div className="startseite__bereich startseite__teaser startseite__teaser--large margin:bottom:4rem">
              <div>
                <h2 className="startseite__ueberschrift startseite__ueberschrift--micro">
                  Hier finden Sie alle Richtlinien zur Nutzung der Plattform und
                  zum Umgang mit den Kunden:
                </h2>
                <p className="margin:bottom:1rem">
                  <Link onClick={() => showInformation(0)}>
                    Verbindliche Service-Levels
                  </Link>
                </p>
                <p className="margin:bottom:1rem">
                  <Link onClick={() => showInformation(1)} className="centered">
                    Unterschiede zwischen ProGS und MeinZuhauseUndIch
                  </Link>
                </p>
                <p className="margin:bottom:1rem">
                  <Link onClick={() => showInformation(2)}>
                    Fragen und Antworten
                  </Link>
                </p>
                {!handwerker && (
                  <Button
                    className="margin:top:2rem"
                    color="yellow"
                    size="large"
                    data-cy-id="registrierungOeffnenButton"
                    onClick={() => {
                      history.push(`/handwerker/registrieren/${blob}`);
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    Jetzt kostenlos registrieren
                  </Button>
                )}
              </div>
              {isInformationenModalVisible && (
                <InformationenModal
                  title={informationen[information].title}
                  gaTrackingName={informationen[information].gaTrackingName}
                  hide={hideInformationenModal}
                >
                  <Information />
                </InformationenModal>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="anmelden">
      <div className="anmelden__box">
        <div className="anmelden__container anmelden__container--vertical anmelden__container--borderBottom anmelden__container--largePadding">
          <h1 className="anmelden__headline1">Ihr Link ist ungültig</h1>
          <p className="anmelden__paragraph margin:top:1rem">
            Bitte öffnen Sie Ihre Registrierungsbestätigung und probieren es
            erneut. Sollten Sie dennoch nicht weiterkommen, wenden Sie sich
            bitte an den Support.
          </p>
          <p className="anmelden__paragraph margin:top:1rem">
            Sie erreichen uns unter{' '}
            <a href="mailto:support@provinzial-meinzuhause.de">
              support@provinzial-meinzuhause.de
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

function ServiceLevels() {
  return (
    <div>
      <h1 className="startseite__ueberschrift startseite__ueberschrift--micro">
        Verbindliche Service-Levels
      </h1>
      <p className="startseite__beschreibung">
        Mit der Vermittlung von Handwerks- und Dienstleistungen außerhalb von
        Schadenfällen möchte die Provinzial Dienstleistungs GmbH den Kunden der
        Provinzial einen Mehrwert mit den gleichen Qualitätsstandards wie in
        Schadenfällen bieten. Zur Sicherstellung dieser Qualitätsstandards und
        einer optimalen Auftragsabwicklung verpflichten Sie sich zu folgenden
        Service-Standards:
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        a) Reaktionszeiten:
      </h2>
      <p className="startseite__beschreibung">
        <ul className="startseite__liste">
          <li>
            Eine Antwort auf eine Ausschreibung eines Kunden (Angebotserstellung
            bzw. Rückfragen zur Konkretisierung des Angebotes oder Ablehnung
            Ihrer Teilnahme an der Ausschreibung) erfolgt innerhalb von{' '}
            <strong>3 Werktagen</strong> nach erfolgter Ausschreibung über die
            Plattform.
          </li>
          <li>
            Nach Beauftragung durch den Kunden und auf Basis des erstellten
            Angebotes, sind dem Kunden innerhalb von{' '}
            <strong>3 Werktagen</strong> 1-3 Terminvorschläge für die Umsetzung
            der Arbeiten zu unterbreiten.
          </li>
          <li>
            Der Samstag wird bei diesen Service Leveln zu Reaktionszeiten nicht
            als Werktag gezählt.
          </li>
          <li>
            Weitere Nachrichten des Kunden über die Plattform sind{' '}
            <strong>kurzfristig</strong>
            zu beantworten.
          </li>
          <li>
            Nicht einzuhaltende Termine sind dem Kunden unverzüglich
            mitzuteilen.
          </li>
          <li>
            Die Rechnungsstellung an den Kunden muss <strong>3 Wochen</strong>{' '}
            nach Fertigstellung der erbrachten Leistungen erfolgen.
          </li>
        </ul>
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        b) Ihr Nutzerprofil
      </h2>
      <p className="startseite__beschreibung">
        <ul className="startseite__liste">
          <li>
            Sie sind verpflichtet, richtige und vollständige Angaben
            insbesondere über Ihre Identität, Anschrift, Telefonnummer,
            E-Mail-Adresse, Qualifikationen, Zulassungen und Erlaubnisse zu
            machen.
          </li>

          <li>
            Diese Angaben sind von Ihnen stets in Ihrem Nutzerprofil auf dem
            aktuellen Stand zu halten.
          </li>
        </ul>
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        c) Leistungsumfang und Qualität der Leistungen:
      </h2>
      <p className="startseite__beschreibung">
        <ul className="startseite__liste">
          <li>
            Die Durchführung der Arbeiten erfolgt durch eigene, qualifizierte
            und fachlich versierte Mitarbeiter.
          </li>
          <li>Der Einsatz von Subunternehmern ist untersagt.</li>
          <li>
            Es dürfen nur solche Materialien verwendet werden, über die Sie das
            uneingeschränkte Eigentumsrecht besitzen und die vollkommen frei von
            Rechten Dritter sind oder vom Auftraggeber bereitgestellt werden.
          </li>
          <li>
            Materialien werden von Ihnen fach- und sachgerecht entsorgt sowie
            nur handelsübliche und normgerechte Materialien verwendet.
          </li>
          <li>
            Verunreinigungen werden von Ihnen beseitigt, soweit sie von Ihren
            eigenen Arbeiten herrühren.
          </li>
          <li>Gegenüber dem Kunden erfolgt ein ordentliches Auftreten.</li>
        </ul>
      </p>
    </div>
  );
}

function Unterschiede() {
  return (
    <div>
      <h1 className="startseite__ueberschrift startseite__ueberschrift--micro">
        Unterschiede zwischen dem Provinzial Gebäude-Schadenservice (ProGS) und
        der Plattform „MeinZuhauseUndIch“
      </h1>
      <p className="startseite__beschreibung">
        Falls Sie bereits für den Provinzial Gebäude-Schadenservice (PR
        Gebäude-Schadenservice) tätig sind und sich nun auch auf der Plattform
        „MeinZuhauseUndIch“ registrieren möchten, zeigen wir Ihnen hier die
        wichtigsten Unterschiede.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        1. Preise/Kalkulationen
      </h2>
      <p className="startseite__beschreibung">
        Über den Provinzial Gebäude-Schadenservice sind Sie den Umgang mit
        Rahmenpreisen gewohnt. Für die Plattform „MeinZuhauseUndIch“ gelten
        diese Rahmenpreise nicht. Sie können hier eine individuelle und faire
        Preisgestaltung den Kunden gegenüber vornehmen.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        2. Angebote
      </h2>
      <p className="startseite__beschreibung">
        Durch die Vereinbarung von Rahmenabkommen ist es für den Provinzial
        Gebäude-Schadenservice nicht erforderlich, Angebote abzugeben. Für die
        Plattform „MeinZuhauseUndIch“ ist es zwingend erforderlich, ein
        verbindliches Angebot zu erstellen und auf der Plattform abzugeben.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        3. Auftraggeber
      </h2>
      <p className="startseite__beschreibung">
        Im Provinzial Gebäude-Schadenservice ist die I-NADIS Ihr Auftraggeber.
        Für Aufträge, die Sie über die Plattform „MeinZuhauseUndIch“ generieren,
        ist der jeweilige Kunde Ihr Auftraggeber und Vertragspartner. Ist der
        Kunde mit Ihrem Angebot einverstanden, kann er Sie über die Plattform
        „MeinZuhauseUndIch“ verbindlich beauftragen. Der Versand einer
        Auftragsbestätigung durch Sie ist somit nicht mehr notwendig.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        4. Zahlungen
      </h2>
      <p className="startseite__beschreibung">
        Über den Provinzial Gebäude-Schadenservice werden Sie durch die I-NADIS
        bezahlt. Für Aufträge, die über Plattform „MeinZuhauseUndIch“ zustande
        kommen, zahlen die jeweiligen Kunden die Rechnungen direkt an Sie. Bitte
        beachten Sie, dass es dafür zwingend erforderlich ist, dass Sie den
        Kunden die Rechnungen über die Plattform „MeinZuhauseUndIch“ zur
        Verfügung stellen.
      </p>
    </div>
  );
}

function FragenUndAntworten() {
  return (
    <div>
      <h1 className="startseite__ueberschrift startseite__ueberschrift--micro">
        Fragen und Antworten
      </h1>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        1. Über welche Internetadresse erreiche ich die Plattform?
      </h2>
      <p className="startseite__beschreibung">
        Sie erreichen die Plattform unter{' '}
        <a
          href="https://handwerkervermittlung.meinzuhauseundich.de"
          target="_blank"
          rel="noreferrer noopener"
        >
          handwerkervermittlung.meinzuhauseundich.de
        </a>
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        2. Wer hilft mir bei Problemen mit dem Kunden?
      </h2>
      <p className="startseite__beschreibung">
        Unsere Service-Hotline unter der{' '}
        <a href="tel:02119785858">0211-978 5858</a> hilft Ihnen montags bis
        freitags von 8-17 Uhr bei Problemen mit Kunden gerne weiter.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        3. Muss ich mit dem Kunden ausschließlich über den Chat kommunizieren?
      </h2>
      <p className="startseite__beschreibung">
        Nein, rufen Sie den Kunden gerne an.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        4. Kann ich vor Abgabe meines Angebotes einen Besichtigungstermin beim
        Kunden durchführen?
      </h2>
      <p className="startseite__beschreibung">
        Ja, vereinbaren Sie gerne einen Termin mit dem Kunde. Eine zuverlässige
        und persönliche Beratung ist uns wichtig.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        5. Welche Vorteile bietet mir eine „MeinZuhauseUndIch“-Mitgliedschaft?
      </h2>
      <p className="startseite__beschreibung">
        Die Provinzial empfiehlt Ihren Betrieb an Kunden der Provinzial weiter.
        So können Sie einfach und schnell neue Kunden gewinnen. Über die
        Plattform wird der gesamte Auftrag und Ihre Kommunikation mit dem Kunden
        dokumentiert - denn häufig ist es besser, alles „schwarz auf weiß“ zu
        haben. Und sollte es doch einmal zu Problemen mit dem Kunden kommen,
        hilft Ihnen die Provinzial weiter und vermittelt zwischen Ihnen und dem
        Kunden.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        6. Wie kann ich mein Firmenprofil ändern?
      </h2>
      <p className="startseite__beschreibung">
        Nachdem Sie initial bei der Registrierung Ihr Profil mit Daten gefüllt
        haben, können Sie diese jederzeit ändern und aktualisieren. Melden Sie
        sich dafür am System an und gehen Sie rechts oben über Ihren
        Firmennamen. Nach Klick auf „Mein Profil“ können Sie, in der sich
        öffnenden Maske, Ihre Daten ändern und dauerhaft speichern.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        7. Wie kann ich die Kunden kontaktieren?
      </h2>
      <p className="startseite__beschreibung">
        Sie können die Kunden via Chat, E-Mail oder Telefon kontaktieren.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        8. Wie gebe ich Angebote ab?{' '}
      </h2>
      <p className="startseite__beschreibung">
        Über den Chatverlauf stellen Sie Ihrem Kunden das Angebot als pdf-Anhang
        zur Verfügung.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        9. Wie erhalte ich Bewertungen auf „MeinZuhauseUndIch“?
      </h2>
      <p className="startseite__beschreibung">
        Nach Abschluss eines Auftrages wird der Kunde Sie bewerten und angeben,
        wie zufrieden er mit Ihnen und Ihrer Dienstleistung war.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        10. Wie kann ich mein Passwort ändern?
      </h2>
      <p className="startseite__beschreibung">
        Sie müssen sich dazu vom System abmelden. Gehen Sie dann auf die
        Fachpartner-Anmeldung und klicken Sie unterhalb des Passwortfeldes auf
        “Passwort vergessen”. Folgen Sie danach den Anweisungen, um ein neues
        Passwort anzulegen.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        11. Kann ich „MeinZuhauseUndIch“ auch mobil vom Smartphone oder Tablet
        aus nutzen?
      </h2>
      <p className="startseite__beschreibung">
        Egal mit welchem Gerät - mit Ihren Anmelde-Daten kommen Sie immer auf
        die Plattform.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        12. In welchen Regionen funktioniert „MeinZuhauseUndIch“?
      </h2>
      <p className="startseite__beschreibung">
        Die Vermittlung von Dienstleistungen ist auf das Geschäftsgebiet der
        Provinzial in NRW und RLP begrenzt. Ihre Tätigkeitsgebiete können Sie
        individuell in der Plattform einrichten (analog I-NADIS).
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        13. Wie kann ich mein Dienstleistungsportfolio, z.B. Erweiterung eines
        Gewerkes, ändern?
      </h2>
      <p className="startseite__beschreibung">
        Auf der „MeinZuhauseUndIch“-Plattform haben Sie unter dem Reiter „Profil
        einrichten“ die Möglichkeit dazu.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        14. Was kostet die Teilnahme an der Plattform „MeinZuhauseUndIch“ nach
        dem kostenlosen Zeitraum?
      </h2>{' '}
      <p className="startseite__beschreibung">
        Die Registrierung und Nutzung der Plattform sind kostenfrei. Das
        bedeutet, dass keine Gebühren erhoben werden, wenn Sie lediglich bei
        einer Ausschreibung eines Auftraggebers als möglicher Auftragnehmer von
        der PR-DLG empfohlen werden. Ebenfalls werden keine Gebühren erhoben,
        wenn Sie auf Ausschreibungen reagieren und ein verbindliches Angebot
        erstellen. Die PR-DLG beabsichtigt, nach Abschluss der Startphase,
        frühestens jedoch zum 01.01.2021, für die erfolgreiche Vermittlung eines
        Auftrages eine Gebühr von mindestens 1 % und maximal 3 % des
        Auftragsvolumens festzulegen. Als Bemessungsgrundlage für die Berechnung
        der Gebühr gilt der von Ihnen in Rechnung gestellte Nettobetrag zum
        jeweiligen Auftrag. Die PR-DLG wird Sie über den Zeitpunkt der
        Einführung und die Höhe der Gebühr mit einer Frist von 2 Monaten vor dem
        Einführungszeitpunkt informieren. Ihnen steht in diesem Fall ein
        Sonderkündigungsrecht zu.
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        15. Wie erstelle ich ein Impressum?
      </h2>
      <p className="startseite__beschreibung">
        Als Teilnehmer an der Plattform „MeinZuhauseUndIch“ treten Sie mit einem
        eigenen Anbieterprofil auf einer Plattform auf. Somit sind Sie
        verpflichtet, ein Impressum anzugeben. Dies können Sie machen, indem Sie
        den direkten Link auf das Impressum Ihrer Unternehmens-Webseite angeben
        (der Link auf die Startseite ist nicht ausreichend!) oder das Impressum
        ausfüllen. Welche Angaben ein Impressum enthalten muss, sind in{' '}
        <a
          href="https://www.gesetze-im-internet.de/tmg/__5.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          §5 Telemediengesetz
        </a>{' '}
        geregelt. Muster für das Impressum finden Sie häufig auch bei Ihrer
        Handwerkskammer (Beispiel:{' '}
        <a
          href="https://www.hwk-koeln.de/32,0,940.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          Muster der Handwerkskammer Köln
        </a>
        ).
      </p>
      <h2 className="startseite__ueberschrift startseite__ueberschrift--nano margin:bottom:.5rem">
        16. Wie erstelle ich Datenschutzhinweise?
      </h2>
      <p className="startseite__beschreibung">
        Da Sie über die Plattform „MeinZuhauseUndIch“ Daten von Interessenten
        und Kunden erheben, sind Sie verpflichtet, die Informationspflichten der
        EU-DSGVO zu erfüllen. Daher können Sie hier auf die Datenschutzhinweise
        auf Ihrer Unternehmens-Webseite verlinken oder Ihre Datenschutzhinweise
        als Dokument hochladen. Sie haben noch keine Datenschutzhinweise für Ihr
        Unternehmen? Informieren Sie sich bei Ihrer Handwerkskammer, die Ihnen
        Muster zur Verfügung stellen kann (Beispiel:{' '}
        <a
          href="https://www.zdh.de/fileadmin/user_upload/themen/Recht/Datenschutz/Handwerksbetriebe/Anlage_Muster_Informationserteilung_Handwerksbetriebe.docx"
          target="_blank"
          rel="noreferrer noopener"
        >
          Muster des Zentralverbandes des Deutschen Handwerks
        </a>
        ).
      </p>
      <p className="startseite__beschreibung">
        Bitte beachten Sie, dass es sich um einen Link zu einer Website eines
        Dritten handelt, auf deren Inhalte wir keinen Einfluss haben. Deshalb
        können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
      </p>
    </div>
  );
}

function useBlob(blob) {
  const [isBlobParseable, setBlobParseable] = useState(true);
  useEffect(() => {
    let didCancel;
    try {
      if (!didCancel) {
        const handwerkerdaten = JSON.parse(atob(blob));
        if (
          !handwerkerdaten.hwkId ||
          !handwerkerdaten.email ||
          !handwerkerdaten.firmenname
        ) {
          setBlobParseable(false);
        }
      }
    } catch (error) {
      setBlobParseable(false);
    }
    return () => {
      didCancel = true;
    };
  }, [blob]);

  return isBlobParseable;
}

function InformationenModal({ title, gaTrackingName, hide, children }) {
  const actions = () => <Button onClick={hide}>Schließen</Button>;
  return (
    <Modal
      title={title}
      hide={hide}
      actions={actions}
      gaTrackingName={gaTrackingName}
    >
      {children}
    </Modal>
  );
}
