import {
  bereich,
  erforderlich,
  hatWerte,
  istSchritt,
  objektart,
  taetigkeit,
  und,
} from './helfer';
import {
  KATEGORIE_INDEX_TAETIGKEITEN,
  BEREICH_INNEN,
  BEREICH_AUSSEN,
  OBJEKTART_BODENARBEITEN,
  OBJEKTART_DACHARBEITEN,
  OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN,
  OBJEKTART_FASSADENARBEITEN,
  OBJEKTART_FENSTER_TUERARBEITEN,
  OBJEKTART_HEIZUNG_SANITAER_KLIMA,
  OBJEKTART_HOLZARBEITEN,
  OBJEKTART_SONSTIGE_AUSSENARBEITEN,
  OBJEKTART_SONSTIGE_INNENARBEITEN,
  OBJEKTART_TREPPEN_GELAENDERARBEITEN,
  OBJEKTART_WAND_DECKENARBEITEN,
  TAETIGKEIT_DACHCHECK,
  TEILTAETIGKEIT_DACHCHECK_BASIS,
  TEILTAETIGKEIT_DACHCHECK_PREMIUM,
  TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN,
  TAETIGKEIT_BLITZSCHUTZANLAGE,
  TAETIGKEIT_FASSADE_STREICHEN,
  TAETIGKEIT_FASSADE_VERPUTZEN,
  TAETIGKEIT_FENSTER_BEHANDELN,
  TAETIGKEIT_FLIESEN_NATURSTEIN_LEGEN,
  TAETIGKEIT_HOLZBODENBELAEGE_BEARBEITEN,
  TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN,
  TAETIGKEIT_LAMINAT_VINYL_LEGEN,
  TAETIGKEIT_SONSTIGE_DACHARBEITEN,
  TAETIGKEIT_SONSTIGE_FLIESENARBEITEN,
  TAETIGKEIT_TEPPICH_LINOLEUM_PVC_LEGEN,
  TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN,
  TAETIGKEIT_TUEREN_BEHANDELN,
  TAETIGKEIT_WAENDE_DECKEN_STREICHEN,
  TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN,
  TAETIGKEIT_WAENDE_RAEUME_FLIESEN,
  TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN,
  OBJEKTART_BALKON_TERRASSENARBEITEN,
  TAETIGKEIT_TREPPE_GELAENDER_AUS_HOLZ_EINBAUEN,
  TAETIGKEIT_TREPPE_GELAENDER_OBERBOEDEN,
  TAETIGKEIT_TREPPE_FLIESEN,
  TAETIGKEIT_WAENDE_DECKEN_HOLZVERKLEIDUNGEN,
  TAETIGKEIT_SONSTIGE_DECKENARBEITEN,
  TAETIGKEIT_TUEREN_EINBAUEN,
  TAETIGKEIT_FENSTER_EINBAUEN,
  TAETIGKEIT_ESTRICH_BEARBEITEN,
  TAETIGKEIT_SCHIFFSBODEN_LEGEN,
  TAETIGKEIT_OBERBOEDEN_AUF_TREPPEN,
  TAETIGKEIT_BOILER_WARMWASSER,
  TAETIGKEIT_ANTENNE_SCHUESSEL,
  TAETIGKEIT_STROMLEITUNGEN,
  TAETIGKEIT_BELEUCHTUNG,
  TAETIGKEIT_KLINGEL_SPRECHANLAGE,
  TAETIGKEIT_ANSCHLUSS_ELEKTROHERD,
  TAETIGKEIT_SMART_HOME_EINBINDEN,
  TAETIGKEIT_ALARMANLAGEN,
  TAETIGKEIT_SONSTIGE_ELEKTRIKARBEITEN,
  TAETIGKEIT_BALKONE_ABDICHTEN,
  TAETIGKEIT_BALKONVERKLEIDUNGEN_HOLZ,
  TAETIGKEIT_BALKON_FLIESEN,
  TAETIGKEIT_ABWASSERVORRSICHTUNGEN,
  TAETIGKEIT_BAD_ARMATUREN,
  TAETIGKEIT_FENSTER_STREICHEN,
  TAETIGKEIT_ROLLAEDEN_ANBRINGEN,
  TAETIGKEIT_TREPPENAUFGANG_FLIESEN,
  TAETIGKEIT_EINGANGSPODEST_FLIESEN,
  TAETIGKEIT_BAD_FLIESEN,
  TAETIGKEIT_FUGEN,
  TAETIGKEIT_BODENEINLAEUFE,
  TAETIGKEIT_BOILER_WARMWASSER_DURCHLAUFERHITZER,
  TAETIGKEIT_GASLEISTUNGEN,
  TAETIGKEIT_HAUSWASSERSTATION,
  TAETIGKEIT_HEIZUNGSANLAGE,
  TAETIGKEIT_HEIZKOERPER_STREICHEN,
  TAETIGKEIT_FUSSBODENHEIZUNG,
  TAETIGKEIT_KLIMAANLAGE,
  TAETIGKEIT_LUEFTUNG,
  TAETIGKEIT_WASCHMASCHINENANSCHLUSS,
  TAETIGKEIT_WASSERLEITUNGEN,
  TAETIGKEIT_PHOTOVOLTAIK,
  TAETIGKEIT_WASSERPUMPE,
  TAETIGKEIT_SONSTIGE_HEIZUNG_SANITAER_KLIMA,
  TAETIGKEIT_HOLZMOEBEL,
  TAETIGKEIT_KUECHENAUF_ABBAU,
  TAETIGKEIT_SONSTIGE_HOLZARBEITEN,
  TAETIGKEIT_DACH,
  TAETIGKEIT_DACHFENSTER,
  TAETIGKEIT_SANITAERLUEFTER,
  TAETIGKEIT_VORDAECHER,
  TAETIGKEIT_GARAGENDAECHER,
  TAETIGKEIT_DACH_BLITZSCHUTZANLAGE,
  TAETIGKEIT_SCHORNSTEIN,
  TAETIGKEIT_DACHRINNE,
  TAETIGKEIT_FASSADE_FLIESEN,
  TAETIGKEIT_FASSADE_VERKLEIDEN,
  OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE,
  OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST,
  OBJEKTART_KLEINMONTAGE,
  TAETIGKEIT_KLEINREPARATUR,
  TAETIGKEIT_RASENMAEHEN,
  TAETIGKEIT_REINIGUNG_HOFFLAECHE,
  TAETIGKEIT_BESEITIGUNG_GARTENABFAELLE,
  TAETIGKEIT_SCHNITTARBEITEN_HECKE_BUESCHE,
  TAETIGKEIT_AUFBAU_GARTENHAUS,
  TAETIGKEIT_WINTERDIENST,
} from './konstanten';

const SCHRITTE_TAETIGKEITEN = [
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'bereich',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Wo soll Ihr Auftrag durchgeführt werden?',
    auswahlmoeglichkeiten: [
      {
        wert: BEREICH_INNEN,
      },
      {
        wert: BEREICH_AUSSEN,
      },
    ],
    bedingung: istSchritt(0),
  }),
  /*
   * ---------- Innen
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'objektart',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was soll innen gemacht werden?',
    auswahlmoeglichkeiten: [
      {
        wert: OBJEKTART_TREPPEN_GELAENDERARBEITEN,
      },
      {
        wert: OBJEKTART_WAND_DECKENARBEITEN,
      },
      {
        wert: OBJEKTART_FENSTER_TUERARBEITEN,
      },
      {
        wert: OBJEKTART_BODENARBEITEN,
      },
      {
        wert: OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN,
      },
      {
        wert: OBJEKTART_HEIZUNG_SANITAER_KLIMA,
      },
      {
        wert: OBJEKTART_HOLZARBEITEN,
      },
      {
        wert: OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE,
      },
      {
        wert: OBJEKTART_KLEINMONTAGE,
      },
      {
        wert: OBJEKTART_SONSTIGE_INNENARBEITEN,
      },
    ],
    bedingung: und(istSchritt(1), hatWerte(bereich(BEREICH_INNEN))),
  }),
  /*
   * ---------- Innen / Treppen- & Geländerarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Treppen- & Geländerarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN },
      { wert: TAETIGKEIT_TREPPE_GELAENDER_AUS_HOLZ_EINBAUEN },
      { wert: TAETIGKEIT_TREPPE_GELAENDER_OBERBOEDEN },
      { wert: TAETIGKEIT_TREPPE_FLIESEN },
      { wert: TAETIGKEIT_KLEINREPARATUR },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN)
      )
    ),
  }),
  /*
   * ---------- Innen / Wand- & Deckenarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Wand- & Deckenarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_WAENDE_RAEUME_FLIESEN },
      { wert: TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN },
      { wert: TAETIGKEIT_WAENDE_DECKEN_STREICHEN },
      { wert: TAETIGKEIT_WAENDE_DECKEN_HOLZVERKLEIDUNGEN },
      { wert: TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN },
      { wert: TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN },
      { wert: TAETIGKEIT_SONSTIGE_FLIESENARBEITEN },
      { wert: TAETIGKEIT_KLEINREPARATUR },
      { wert: TAETIGKEIT_SONSTIGE_DECKENARBEITEN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_WAND_DECKENARBEITEN))
    ),
  }),
  /*
   * ---------- Innen / Fenster- & Türarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Fenster- & Türarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_FENSTER_BEHANDELN },
      { wert: TAETIGKEIT_TUEREN_BEHANDELN },
      { wert: TAETIGKEIT_TUEREN_EINBAUEN },
      { wert: TAETIGKEIT_FENSTER_EINBAUEN },
      { wert: TAETIGKEIT_KLEINREPARATUR },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_FENSTER_TUERARBEITEN)
      )
    ),
  }),
  /*
   * ---------- Innen / Bodenarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Bodenarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_FLIESEN_NATURSTEIN_LEGEN },
      { wert: TAETIGKEIT_ESTRICH_BEARBEITEN },
      { wert: TAETIGKEIT_TEPPICH_LINOLEUM_PVC_LEGEN },
      { wert: TAETIGKEIT_LAMINAT_VINYL_LEGEN },
      { wert: TAETIGKEIT_SCHIFFSBODEN_LEGEN },
      { wert: TAETIGKEIT_OBERBOEDEN_AUF_TREPPEN },
      { wert: TAETIGKEIT_HOLZBODENBELAEGE_BEARBEITEN },
      { wert: TAETIGKEIT_KLEINREPARATUR },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_BODENARBEITEN))
    ),
  }),
  /*
   * ---------- Innen / Elektrik- & Anschlussarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Elektrik- & Anschlussarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_BOILER_WARMWASSER },
      { wert: TAETIGKEIT_ANTENNE_SCHUESSEL },
      { wert: TAETIGKEIT_STROMLEITUNGEN },
      { wert: TAETIGKEIT_BELEUCHTUNG },
      { wert: TAETIGKEIT_KLINGEL_SPRECHANLAGE },
      { wert: TAETIGKEIT_ANSCHLUSS_ELEKTROHERD },
      { wert: TAETIGKEIT_SMART_HOME_EINBINDEN },
      { wert: TAETIGKEIT_PHOTOVOLTAIK },
      { wert: TAETIGKEIT_ALARMANLAGEN },
      { wert: TAETIGKEIT_KLEINREPARATUR },
      { wert: TAETIGKEIT_SONSTIGE_ELEKTRIKARBEITEN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
      )
    ),
  }),
  /*
   * ---------- Innen / Heizung, Sanitär & Klima
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Heizung-, Sanitär- & Klimaarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_ABWASSERVORRSICHTUNGEN },
      { wert: TAETIGKEIT_BAD_ARMATUREN },
      { wert: TAETIGKEIT_BAD_FLIESEN },
      { wert: TAETIGKEIT_FUGEN },
      { wert: TAETIGKEIT_BODENEINLAEUFE },
      { wert: TAETIGKEIT_BOILER_WARMWASSER_DURCHLAUFERHITZER },
      { wert: TAETIGKEIT_GASLEISTUNGEN },
      { wert: TAETIGKEIT_HAUSWASSERSTATION },
      { wert: TAETIGKEIT_HEIZUNGSANLAGE },
      { wert: TAETIGKEIT_HEIZKOERPER_STREICHEN },
      { wert: TAETIGKEIT_FUSSBODENHEIZUNG },
      { wert: TAETIGKEIT_KLIMAANLAGE },
      { wert: TAETIGKEIT_LUEFTUNG },
      { wert: TAETIGKEIT_WASCHMASCHINENANSCHLUSS },
      { wert: TAETIGKEIT_WASSERLEITUNGEN },
      { wert: TAETIGKEIT_WASSERPUMPE },
      { wert: TAETIGKEIT_KLEINREPARATUR },
      { wert: TAETIGKEIT_SONSTIGE_HEIZUNG_SANITAER_KLIMA },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_HEIZUNG_SANITAER_KLIMA)
      )
    ),
  }),
  /*
   * ---------- Innen / Holzarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Holzarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_HOLZMOEBEL },
      { wert: TAETIGKEIT_KUECHENAUF_ABBAU },
      { wert: TAETIGKEIT_KLEINREPARATUR },
      { wert: TAETIGKEIT_SONSTIGE_HOLZARBEITEN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(bereich(BEREICH_INNEN), objektart(OBJEKTART_HOLZARBEITEN))
    ),
  }),
  erforderlich({
    typ: 'textauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Variante soll ausgeführt werden?',
    auswahlmoeglichkeiten: [
      {
        wert: TEILTAETIGKEIT_DACHCHECK_BASIS,
        titel: TEILTAETIGKEIT_DACHCHECK_BASIS,
        untertitel: '',
        leistungen: [
          'Begehung und Beurteilung des Gesamteindrucks und Abnutzungsgrads des gesamten Daches.',
          'Inspektion der Verklammerung und Besichtigung der Dachziegel zur Sturmsicherheit.',
          'Überprüfung aller funktionswichtigen Einbauelemente wie Dachfenster, Lüfter oder Satellitenanschlüsse, auch auf Undichtigkeiten.',
          'Überprüfung von Dachrinnen und Abläufen auf mögliche Verstopfungen, sowie Sichtkontrolle aller Anschlüsse und Fugen.',
          'Überprüfung auf Lockerung von Kaminsteinen und Kaminabdeckung.',
          'Ausfertigung eines umfassenden Protokolls als Inspektionsnachweis.',
        ],
        nichtEnthalteneLeistungen: [
          'Reinigung der Dachrinnen von Laub und Dreck',
        ],
        buttonText: 'Weiter mit Basis',
      },
      {
        wert: TEILTAETIGKEIT_DACHCHECK_PREMIUM,
        titel: TEILTAETIGKEIT_DACHCHECK_PREMIUM,
        untertitel: '',
        leistungen: [
          'Begehung und Beurteilung des Gesamteindrucks und Abnutzungsgrads des gesamten Daches.',
          'Inspektion der Verklammerung und Besichtigung der Dachziegel zur Sturmsicherheit.',
          'Überprüfung aller funktionswichtigen Einbauelemente wie Dachfenster, Lüfter oder Satellitenanschlüsse, auch auf Undichtigkeiten.',
          'Überprüfung von Dachrinnen und Abläufen auf mögliche Verstopfungen, sowie Sichtkontrolle aller Anschlüsse und Fugen.',
          'Überprüfung auf Lockerung von Kaminsteinen und Kaminabdeckung.',
          'Ausfertigung eines umfassenden Protokolls als Inspektionsnachweis.',
          'Reinigung der Dachrinnen von Laub und Dreck',
        ],
        buttonText: 'Weiter mit Premium',
      },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_DACHARBEITEN),
        taetigkeit(TAETIGKEIT_DACHCHECK)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Treppe/Geländer lackieren' },
      { wert: 'Treppe/Geländer lasieren' },
      { wert: 'Treppe/Geländer versiegeln' },
      { wert: 'Ausbesserungen von Beschädigungen' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_TREPPEN_GELAENDERARBEITEN),
        taetigkeit(TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Alte Tapete entfernen' },
      { wert: 'Alte Farbe entfernen' },
      { wert: 'Unebenheiten ausgleichen' },
      { wert: 'Risse/Löcher spachteln' },
      { wert: 'Wände/Decken tapezieren' },
      { wert: 'Wände/Decken streichen' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Alte Tapete entfernen' },
      { wert: 'Alte Farbe entfernen' },
      { wert: 'Unebenheiten ausgleichen' },
      { wert: 'Risse/Löcher spachteln' },
      { wert: 'Wände/Decken streichen' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_WAENDE_DECKEN_STREICHEN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Alte Tapete entfernen' },
      { wert: 'Alte Farbe entfernen' },
      { wert: 'Dekorputz auf Wände/Decken auftragen' },
      { wert: 'Unebenheiten ausgleichen' },
      { wert: 'Risse/Löcher spachteln' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Alte Dämmung entfernen' },
      { wert: 'Wände dämmen' },
      { wert: 'Decken dämmen' },
      { wert: 'Beratung zur Materialauswahl' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_WAND_DECKENARBEITEN),
        taetigkeit(TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Fenster lackieren' },
      { wert: 'Fenster lasieren' },
      { wert: 'Fenster versiegeln' },
      { wert: 'Fenster verkitten' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_FENSTER_TUERARBEITEN),
        taetigkeit(TAETIGKEIT_FENSTER_BEHANDELN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Türen lackieren' },
      { wert: 'Türen lasieren' },
      { wert: 'Türen versiegeln' },
      { wert: 'Türen abbeizen' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_INNEN),
        objektart(OBJEKTART_FENSTER_TUERARBEITEN),
        taetigkeit(TAETIGKEIT_TUEREN_BEHANDELN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Fassade streichen' },
      { wert: 'Risse schließen' },
      { wert: 'Abgeplatzten Putz reparieren' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FASSADENARBEITEN),
        taetigkeit(TAETIGKEIT_FASSADE_STREICHEN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Fassade verputzen' },
      { wert: 'Fassadensockel verputzen' },
      { wert: 'Fassade streichen' },
      { wert: 'Vorhandenen Putz sanieren' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FASSADENARBEITEN),
        taetigkeit(TAETIGKEIT_FASSADE_VERPUTZEN)
      )
    ),
  }),
  erforderlich({
    typ: 'mehrfachauswahl',
    attribut: 'teiltaetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was genau muss gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: 'Wärmedämmverbundsystem installieren' },
      { wert: 'Fassade verputzen' },
      { wert: 'Fassade streichen' },
      { wert: 'Beratung zur Materialauswahl & Farbgestaltung' },
      { wert: 'Sonstige Aufgaben' },
    ],
    bedingung: und(
      istSchritt(3),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FASSADENARBEITEN),
        taetigkeit(TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN)
      )
    ),
  }),
  /*
   * ---------- AUSSEN
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'objektart',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Was soll außen gemacht werden?',
    auswahlmoeglichkeiten: [
      {
        wert: OBJEKTART_DACHARBEITEN,
      },
      {
        wert: OBJEKTART_BALKON_TERRASSENARBEITEN,
      },
      {
        wert: OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN,
      },
      {
        wert: OBJEKTART_FASSADENARBEITEN,
      },
      {
        wert: OBJEKTART_FENSTER_TUERARBEITEN,
      },
      {
        wert: OBJEKTART_BODENARBEITEN,
      },
      {
        wert: OBJEKTART_HOLZARBEITEN,
      },
      {
        wert: OBJEKTART_KLEINMONTAGE,
      },
      {
        wert: OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST,
      },
      {
        wert: OBJEKTART_SONSTIGE_AUSSENARBEITEN,
      },
    ],
    bedingung: und(istSchritt(1), hatWerte(bereich(BEREICH_AUSSEN))),
  }),
  /*
   * ---------- Außen / Dacharbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Dacharbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_DACHCHECK },
      { wert: TAETIGKEIT_DACHRINNE },
      { wert: TAETIGKEIT_DACH },
      { wert: TAETIGKEIT_DACHFENSTER },
      { wert: TAETIGKEIT_SANITAERLUEFTER },
      { wert: TAETIGKEIT_VORDAECHER },
      { wert: TAETIGKEIT_GARAGENDAECHER },
      { wert: TAETIGKEIT_DACH_BLITZSCHUTZANLAGE },
      { wert: TAETIGKEIT_SCHORNSTEIN },
      { wert: TAETIGKEIT_PHOTOVOLTAIK },
      { wert: TAETIGKEIT_SONSTIGE_DACHARBEITEN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_DACHARBEITEN))
    ),
  }),
  /*
   * ---------- Außen / Balkon- und Terrassenarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Balkon- und Terrassenarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_BALKONE_ABDICHTEN },
      { wert: TAETIGKEIT_BALKONVERKLEIDUNGEN_HOLZ },
      { wert: TAETIGKEIT_BALKON_FLIESEN },
      { wert: TAETIGKEIT_TREPPENAUFGANG_FLIESEN },
      {
        wert: TAETIGKEIT_KLEINREPARATUR,
      },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_BALKON_TERRASSENARBEITEN)
      )
    ),
  }),
  /*
   * ---------- Außen / Elektrik- und Anschlussarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Elektrik- und Anschlussarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_ANTENNE_SCHUESSEL },
      { wert: TAETIGKEIT_STROMLEITUNGEN },
      { wert: TAETIGKEIT_BELEUCHTUNG },
      { wert: TAETIGKEIT_KLINGEL_SPRECHANLAGE },
      { wert: TAETIGKEIT_SMART_HOME_EINBINDEN },
      { wert: TAETIGKEIT_ALARMANLAGEN },
      { wert: TAETIGKEIT_BLITZSCHUTZANLAGE },
      {
        wert: TAETIGKEIT_KLEINREPARATUR,
      },
      { wert: TAETIGKEIT_SONSTIGE_ELEKTRIKARBEITEN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN)
      )
    ),
  }),
  /*
   * ---------- Außen / Fassadenarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Fassadenarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_FASSADE_STREICHEN },
      { wert: TAETIGKEIT_FASSADE_VERPUTZEN },
      { wert: TAETIGKEIT_FASSADE_FLIESEN },
      { wert: TAETIGKEIT_FASSADE_VERKLEIDEN },
      { wert: TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_FASSADENARBEITEN))
    ),
  }),
  /*
   * ---------- Außen / Fenster- & Türarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Fenster- & Türarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_FENSTER_STREICHEN },
      { wert: TAETIGKEIT_ROLLAEDEN_ANBRINGEN },
      { wert: TAETIGKEIT_TUEREN_EINBAUEN },
      { wert: TAETIGKEIT_FENSTER_EINBAUEN },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_FENSTER_TUERARBEITEN)
      )
    ),
  }),
  /*
   * ---------- Außen / Bodenarbeiten
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Bodenarbeiten sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_FLIESEN_NATURSTEIN_LEGEN },
      { wert: TAETIGKEIT_EINGANGSPODEST_FLIESEN },
      { wert: TAETIGKEIT_TREPPENAUFGANG_FLIESEN },
      {
        wert: TAETIGKEIT_KLEINREPARATUR,
      },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(bereich(BEREICH_AUSSEN), objektart(OBJEKTART_BODENARBEITEN))
    ),
  }),
  /*
   * ---------- Außen / Garten, Hofpflege und Winderdienst
   */
  erforderlich({
    typ: 'sofortauswahl',
    attribut: 'taetigkeit',
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    frage: 'Welche Garten, Hofpflege oder Winderdienst sollen gemacht werden?',
    auswahlmoeglichkeiten: [
      { wert: TAETIGKEIT_RASENMAEHEN },
      { wert: TAETIGKEIT_REINIGUNG_HOFFLAECHE },
      { wert: TAETIGKEIT_BESEITIGUNG_GARTENABFAELLE },
      { wert: TAETIGKEIT_SCHNITTARBEITEN_HECKE_BUESCHE },
      { wert: TAETIGKEIT_AUFBAU_GARTENHAUS },
      { wert: TAETIGKEIT_WINTERDIENST },
    ],
    bedingung: und(
      istSchritt(2),
      hatWerte(
        bereich(BEREICH_AUSSEN),
        objektart(OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST)
      )
    ),
  }),
];

export default SCHRITTE_TAETIGKEITEN;
