import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useAnfrageUndEventContext } from '../../contexts/AnfrageUndEventContext';

import './Navbar.scss';

export const NavbarItem = ({ onClick, isActive, children }) => {
  return (
    <button
      type="button"
      className={classNames('navbar__item', {
        'navbar__item--active': isActive,
      })}
      onClick={onClick}
    >
      <label
        className={classNames('navbar__itemLabel', {
          'navbar__itemLabel--active': isActive,
        })}
      >
        {children}
      </label>
    </button>
  );
};

const NavBar = function NavBar() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const oeffneOffeneAuftraege = () => history.push('/offeneAuftraege');
  const oeffneAbgeschlosseneAuftraege = () =>
    history.push('/abgeschlosseneAuftraege');
  const oeffneAnfragen = () => history.push('/anfragen');

  const isOffeneAuftraegeActive = () => pathname.includes('offeneAuftraege');
  const isAbgeschlosseneAuftraegeActive = () =>
    pathname.includes('abgeschlosseneAuftraege');
  const isAnfragenActive = () =>
    pathname.includes('anfragen') || pathname === '/';

  const oeffneAnsicht = (ansicht) => history.push(`/${ansicht}`);
  const ansicht = isAnfragenActive()
    ? 'anfragen'
    : isOffeneAuftraegeActive()
    ? 'offeneAuftraege'
    : 'abgeschlosseneAuftraege';

  const anzahlAnfragen = (anfragen) =>
    anfragen.filter((anfrage) => anfrage.wurdeGesendet()).length;

  const anzahlOffenerAuftraegeDesHandwerkers = (anfragen) =>
    anfragen.filter((anfrage) => anfrage.istOffen()).length;

  const anzahlAbgeschlossenerAuftraegeDesHandwerkers = (anfragen) =>
    anfragen.filter((anfrage) => anfrage.wurdeBeendet()).length;

  const navbarItemsDesktop = (anfragen) => (
    <>
      <NavbarItem isActive={isAnfragenActive()} onClick={oeffneAnfragen}>
        Anfragen ({anzahlAnfragen(anfragen)})
      </NavbarItem>
      <NavbarItem
        isActive={isOffeneAuftraegeActive()}
        onClick={oeffneOffeneAuftraege}
      >
        Offene Aufträge ({anzahlOffenerAuftraegeDesHandwerkers(anfragen)})
      </NavbarItem>
      <NavbarItem
        isActive={isAbgeschlosseneAuftraegeActive()}
        onClick={oeffneAbgeschlosseneAuftraege}
      >
        Abgeschlossene Aufträge (
        {anzahlAbgeschlossenerAuftraegeDesHandwerkers(anfragen)})
      </NavbarItem>
    </>
  );

  const navbarItemsMobile = (anfragen) => (
    <select
      className="navbar__dropdown"
      onChange={(event) => oeffneAnsicht(event.target.value)}
      value={ansicht}
    >
      <option value="anfragen">Anfragen ({anzahlAnfragen(anfragen)})</option>
      <option value="offeneAuftraege">
        Offene Aufträge ({anzahlOffenerAuftraegeDesHandwerkers(anfragen)})
      </option>
      <option value="abgeschlosseneAuftraege">
        Abgeschlossene Aufträge (
        {anzahlAbgeschlossenerAuftraegeDesHandwerkers(anfragen)})
      </option>
    </select>
  );

  const { anfragen } = useAnfrageUndEventContext();

  return (
    <>
      <nav className="navbar navbar--desktop">
        <div className="navbar__items">{navbarItemsDesktop(anfragen)}</div>
      </nav>

      <nav className="navbar navbar--mobile">{navbarItemsMobile(anfragen)}</nav>
    </>
  );
};

export default NavBar;
