import HandwerkerText from './HandwerkerText';
import KundenText from './KundenText';
import LegalContent from '../../components/LegalContent/LegalContent';
import React from 'react';
import { useBenutzerContext } from '../../contexts/BenutzerContext';

export default function Rechtliches({ rolle }) {
  const { istHandwerker } = useBenutzerContext();
  const defaultTab =
    istHandwerker || rolle === 'HANDWERKER'
      ? 'für Dienstleister'
      : 'für Kunden';

  return (
    <LegalContent
      headline="Allgemeine Geschäftsbedingungen zur Nutzung der Plattform „MeinZuhauseUndIch“"
      tabs={['für Kunden', 'für Dienstleister']}
      defaultTab={defaultTab}
    >
      {(content) =>
        content === 'für Kunden' ? <KundenText /> : <HandwerkerText />
      }
    </LegalContent>
  );
}
