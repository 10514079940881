import './Dachcheck.scss';

import { Accordion, Entry } from '../../components/Accordion/Accordion';

import DachcheckTeaserBild from './DachcheckTeaserBild';
import DachcheckTeaserBildMobile from './DachcheckTeaserBildMobile';
import NeuerAuftragSchaltflaeche from '../../components/NeuerAuftragSchaltflaeche/NeuerAuftragSchaltflaeche';
import React from 'react';
import buehnenbild from '../../assets/images/buehne-dachcheck.jpg';
import schritt1 from '../../assets/icons/schritte/step-1.svg';
import schritt2 from '../../assets/icons/schritte/step-2.svg';
import schritt3 from '../../assets/icons/schritte/step-3.svg';
import schritt4 from '../../assets/icons/schritte/step-4.svg';

export default function Dachcheck() {
  return (
    <div className="startseite">
      <div className="startseite__buehne2">
        <img src={buehnenbild} alt="" className="startseite__buehnenbild" />
        <div className="startseite__buehnenBox">
          <div className="startseite__buehnenBoxLayer1">
            <div className="startseite__buehnenBoxLayer2">
              <div className="startseite__buehnenBoxText">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large margin:bottom:.5rem">
                  Der Dachcheck – Werterhalt leicht gemacht
                </h1>
                <p className="startseite__beschreibung">
                  Lassen Sie die zuverlässige Funktion Ihres Daches einfach
                  überprüfen. Wir finden für Sie den dafür passenden Dachdecker
                  in Ihrer Nähe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="startseite__inhaltWrapper">
        <div className="startseite__inhalt">
          <div className="startseite__bereich">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  Das Dach vom Profi checken lassen – einfach und zuverlässig
                </h1>
                <p className="startseite__beschreibung">
                  Das Dach Ihres Eigenheims ist Sommer wie Winter, Tag und Nacht
                  immer extremer werdenden Witterungsbedingungen ausgesetzt. Für
                  Ihr Wohlgefühl und den Werterhalt Ihrer Immobilie muss der
                  gesamte Aufbau zuverlässig funktionieren. Die klar definierten
                  Leistungen der Dachcheck-Varianten Basis und Premium geben
                  Ihnen einen zuverlässigen Eindruck über den Zustand Ihres
                  Daches. Wir vermitteln Ihnen dafür einen verlässlichen
                  Dachdecker aus Ihrer Region.
                </p>
                <NeuerAuftragSchaltflaeche
                  color="yellow"
                  size="large"
                  gtmEventData={{
                    category: 'landingpageDachcheck',
                    action: 'newRequestClicked',
                    label: 'top',
                  }}
                  data-cy-id="callToActionButton"
                  einstiegspunkt="DACHCHECK"
                >
                  Dachcheckanfrage starten
                </NeuerAuftragSchaltflaeche>
              </div>
            </div>
          </div>
          <div className="startseite__bereich startseite__bereich--light">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  Dachcheck – in zwei Varianten – Basis und Premium
                </h1>
                <p className="startseite__beschreibung">
                  Der <strong>Dachcheck Basis</strong> beinhaltet die
                  Überprüfung Ihres Hausdaches durch einen qualifizierten
                  Dachdeckerbetrieb.
                  <br />
                  Der <strong>Dachcheck Premium</strong> beinhaltet die
                  Leistungen aus Dachcheck Basis und zusätzlich die Reinigung
                  der Dachrinnen von Laub und Dreck.
                </p>
                <DachcheckTeaserBildMobile className="startseite__dachcheckTeaserBild hidden@desktop" />
                <ol className="hidden@desktop startseite__dachcheckLeistungsuebersicht">
                  <li>
                    <ul>
                      <li>
                        Begehung und Beurteilung des Gesamteindrucks und
                        Abnutzungsgrads des gesamten Daches.
                      </li>
                      <li>
                        Inspektion der Verklammerung und Besichtigung der
                        Dachziegel zur Sturmsicherheit.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        Überprüfung auf Lockerung von Kaminsteinen,
                        Kaminabdeckung.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        Überprüfung aller funktionswichtigen Einbauelemente wie
                        Dachfenster, Lüfter oder Satellitenanschlüsse, auch auf
                        Undichtigkeiten.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        Reinigung der Dachrinnen von Laub und Dreck (nur{' '}
                        <strong>Premium</strong>).
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        Ausfertigung eines umfassenden Protokolls als
                        Inspektionsnachweis.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        Überprüfung von Dachrinnen und Abläufen auf mögliche
                        Verstopfungen, sowie Sichtkontrolle aller Anschlüsse und
                        Fugen.
                      </li>
                    </ul>
                  </li>
                </ol>
                <DachcheckTeaserBild className="startseite__dachcheckTeaserBild hidden@mobile" />
              </div>
            </div>
          </div>
          <div className="startseite__bereich">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <div className="startseite__dachcheck">
                  <div>
                    <h1 className="margin:bottom:2.5rem">
                      Gute Gründe für den Dachcheck – ausgeführt vom Profi
                    </h1>
                    <ul className="startseite__dachcheckVorteilliste">
                      <li>
                        Sie erhalten eine professionelle und umfassende
                        Bestandsaufnahme aller relevanten Teile Ihres Daches.
                      </li>
                      <li>
                        Eventuelle Mängel können frühzeitig erkannt und behoben
                        werden.
                      </li>
                      <li>
                        Sie erhalten den Wert Ihrer Immobilie und erhöhen deren
                        Lebensdauer.
                      </li>
                      <li>
                        Sie bleiben auf dem Boden und müssen sich selbst nicht
                        in Gefahr begeben.
                      </li>
                      <li>
                        Sie haben ein gutes Gefühl, dass Sie und mögliche Dritte
                        von Überraschungen verschont bleiben.
                      </li>
                    </ul>
                    <NeuerAuftragSchaltflaeche
                      color="yellow"
                      size="large"
                      gtmEventData={{
                        category: 'landingpageDachcheck',
                        action: 'newRequestClicked',
                        label: 'bottom',
                      }}
                      data-cy-id="callToActionButton"
                      einstiegspunkt="DACHCHECK"
                    >
                      Dachcheckanfrage starten
                    </NeuerAuftragSchaltflaeche>
                  </div>

                  <div className="startseite__preisbeispiel" id="preisBeispiel">
                    <h2 className="margin:bottom:1.375rem">
                      Ein Preisbeispiel für das abgebildete <br />
                      Haus inklusive folgender Kriterien:
                    </h2>

                    <ul className="startseite__liste">
                      <li>Einfamilienhaus</li>
                      <li>6-8 Meter breit</li>
                      <li>Steildach</li>
                      <li>Mit Dachziegeln</li>
                      <li>Mit zwei Dachfenstern</li>
                      <li>Mit normalen Dachrinnen</li>
                      <li>Mit Kamin</li>
                      <li>Es besteht die Möglichkeit eine Leiter anzulegen</li>
                    </ul>
                    <p className="margin:top:1.375rem margin:bottom:1.375rem">
                      Hierfür fallen durchschnittlich diese Kosten an:
                    </p>
                    <div className="startseite__preisbeispielVarianten">
                      <div className="startseite__preisbeispielVariante">
                        <div className="startseite__preisbeispielVarianteHeader">
                          Basis:
                        </div>
                        <div className="startseite__preisbeispielVariantePreis">
                          199 Euro
                        </div>
                        <div className="startseite__preisbeispielVarianteFooter">
                          (inkl. MwSt.)
                        </div>
                      </div>
                      <div className="startseite__preisbeispielVariante">
                        <div className="startseite__preisbeispielVarianteHeader">
                          Premium:
                        </div>
                        <div className="startseite__preisbeispielVariantePreis">
                          299 Euro
                        </div>
                        <div className="startseite__preisbeispielVarianteFooter">
                          (inkl. MwSt.)
                        </div>
                      </div>
                    </div>
                    <p className="startseite__dachcheck--greenPaper--footer">
                      Gegebenenfalls ist für die Durchführung des Dachchecks ein
                      Hubwagen erforderlich. In diesem Fall können weitere
                      Kosten anfallen.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="startseite__teaser margin:top:4rem">
              <div className="startseite__teaserContent">
                <p className="startseite__beschreibung">
                  Sollte der Dachdecker akute Schäden oder Mängel an Ihrem Dach
                  feststellen, informiert dieser Sie über die Auswirkungen und
                  berät Sie zu geeigneten Gegenmaßnahmen. Für die Behebung von
                  Mängeln fallen dann zusätzliche Kosten an.
                </p>
              </div>
            </div>
          </div>

          <div className="startseite__bereich">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  Sie wollen einen <strong>Dachcheck</strong> beauftragen? Das
                  geht so!
                </h1>
                <div className="startseite__schrittliste">
                  <Schritt icon={schritt1}>
                    Wählen Sie Dachcheck Basis oder Premium und geben Sie
                    relevante Informationen zu Ihrem Haus ein.
                  </Schritt>
                  <Schritt icon={schritt2}>
                    Wir empfehlen Ihnen Dachdecker in Ihrer Umgebung. Sie wählen
                    denjenigen aus, den Sie anfragen möchten.
                  </Schritt>
                  <Schritt icon={schritt3}>
                    Der Dachdecker wird sich im Handumdrehen bei Ihnen melden
                    und Sie erhalten ein Angebot über meinzuhauseundich.de.
                  </Schritt>
                  <Schritt icon={schritt4}>
                    Die Abwicklung des gesamten Auftrags können Sie bequem bis
                    zum Ende auf meinzuhauseundich.de durchführen und verfolgen.
                  </Schritt>
                </div>
                <p className="startseite__schrittUnterschrift">
                  Sie wollen mehr erfahren? <a href="/">Schauen Sie hier</a>
                </p>
              </div>
            </div>
          </div>
          <div className="startseite__bereich startseite__faq">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift margin:bottom:1.5rem">
                  Sie haben Fragen zum <strong>Dachcheck</strong>?<br />
                  Hier finden Sie die wichtigsten Antworten.
                </h1>
                <Accordion
                  gtmCategory="landingpageDachcheck"
                  gtmAction="faqClicked"
                >
                  <Entry title="Welche möglichen Mängel können durch einen Dachcheck gefunden werden?">
                    <ul className="startseite__liste">
                      <li>
                        Lockere Dachziegel entstehen häufig durch Stürme. Hier
                        besteht die Gefahr, dass Ziegel herabstürzen und
                        Personen verletzt oder Gebäudeteile beschädigt werden.
                      </li>
                      <li>
                        Risse in der Eindeckung und Bausubstanz können durch
                        Witterungsbedingungen entstehen. Wasser kann eindringen
                        und Schimmel verursachen oder bei Frost können
                        Spannungsrisse entstehen.
                      </li>
                      <li>
                        Die Dachisolierung oder Wärmedämmung kann durch
                        Nagetiere wie Marder beschädigt werden. Feuchtigkeit
                        kann eindringen und Wärme geht verloren.
                      </li>
                      <li>
                        Aufgrund verstopfter Dachrinnen und Abflussrohre kann
                        das Regenwasser nicht mehr ordnungsgemäß ablaufen.
                      </li>
                    </ul>
                  </Entry>
                  <Entry title="Wie läuft die Beauftragung des Dachchecks ab?">
                    Sie geben ein paar Informationen zu Ihrem Haus und Dach an
                    und können dann Ihre Anfrage an bis zu drei Dachdecker aus
                    Ihrer Region abschicken. Diese nehmen dann mit Ihnen Kontakt
                    auf und erstellen Ihnen nach Absprache ein Angebot über
                    Ihren individuellen Dachcheck. Sie können das Angebot, das
                    Ihnen zusagt, über „MeinZuhauseUndIch“ verbindlich
                    annehmen und mit dem Dachdecker einen Termin für den Check
                    ausmachen. Anschließend können Sie die Leistung über
                    „MeinZuhauseUndIch“ abnehmen und der Fachpartner schickt
                    Ihnen digital die Rechnung.
                  </Entry>
                  <Entry title="Was kostet der Dachcheck?">
                    Sowohl der Dachcheck Basis als auch der Dachcheck Premium
                    werden individuell vom Dachdecker für Ihr Haus kalkuliert.
                    Hierfür werden verschiedene Kriterien zugrunde gelegt, z.B.
                    Höhe und Breite des Hauses, Art des Daches, Art der
                    Dachziegel usw. Sie erhalten von den ausgewählten
                    Handwerkern vorab ein auf Sie zugeschnittenes Angebot. Ein{' '}
                    <a href="#preisBeispiel">Preisbeispiel</a> können Sie oben
                    auf der Seite einsehen.
                  </Entry>
                  <Entry title="In welchen Fällen ist ein Hubwagen erforderlich?">
                    Bei der Verwendung einer Leiter als Zugang zu oder Abgang
                    von hoch gelegenen Arbeitsplätzen gilt, dass der zu
                    überwindende Höhenunterschied grundsätzlich nicht mehr als 5
                    Meter betragen darf. Insofern sind Dachdecker verpflichtet,
                    bei einer Höhe ab 5 Meter einen Hubwagen einzusetzen. Einige
                    Dachdecker verwenden für die Durchführung eines Dachchecks
                    mittlerweile Drohnen. Dadurch fällt der Hubwagen weg, womit
                    der Dachcheck günstiger ist.
                  </Entry>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Schritt({ icon, children }) {
  return (
    <div className="startseite__schritt">
      <img
        src={icon}
        className="startseite__icon startseite__icon--small"
        alt=""
      />
      <p className="startseite__beschreibung">{children}</p>
    </div>
  );
}
