import React, { useEffect } from 'react';
import classNames from 'classnames';
import { toTimeString } from '../../dateTime';
import { useModalState, useAnhangUrl } from '../../hooks';
import Button from '../Button/Button';
import { useAuftraege } from '../../DomainContext';
import './Nachricht.scss';
import ProfilModal from '../ProfilModal/ProfilModal';

import { ReactComponent as KundeIcon } from '../../assets/icons/kunde.svg';
import { ReactComponent as HandwerkerIcon } from '../../assets/icons/handwerker.svg';
import { ReactComponent as PdfFileIcon } from '../../assets/icons/pdf_file.svg';

const FotoNachricht = ({ url, nachricht }) => (
  <>
    <div className="nachricht__fotoContainer">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="nachricht__foto" src={url} alt="" />
      </a>
    </div>
    <div className="nachricht__fotoDateiname">
      {nachricht.anhang.datei.fileName}
    </div>
  </>
);

const PdfNachricht = ({ url, nachricht }) => (
  <>
    <div className="nachricht__pdfContainer">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <PdfFileIcon className="nachricht__pdf" />
      </a>
    </div>
    <div className="nachricht__pdfDateiname">
      {nachricht.anhang.datei.fileName}
    </div>
  </>
);

function useMarkiereNachrichtAlsGelesen(nachricht, autor) {
  const auftraege = useAuftraege();
  useEffect(() => {
    let didCancel = false;
    setTimeout(() => {
      if (!didCancel) {
        auftraege.markiereNachrichtAlsGelesen(nachricht, autor);
      }
    }, 1000);
    return () => {
      didCancel = true;
    };
  }, [autor, nachricht, auftraege]);
}

export default ({ nachricht, side, angebot, rechnung, anfrage, autor }) => {
  const url = useAnhangUrl(nachricht);
  const date = toTimeString(nachricht.createdAt);
  const aktuellerBenutzeristHandwerker = autor === 'HANDWERKER';

  const [
    isProfilModalShown,
    showProfilModal,
    hideProfilModal
  ] = useModalState();

  const profilProps = !aktuellerBenutzeristHandwerker
    ? { handwerker: anfrage.handwerker }
    : { kunde: anfrage.auftrag.kunde };

  useMarkiereNachrichtAlsGelesen(nachricht, autor);

  const Icon = aktuellerBenutzeristHandwerker ? KundeIcon : HandwerkerIcon;

  return (
    <div
      className={classNames('nachricht', {
        'nachricht--left': side === 'left',
        'nachricht--right': side === 'right',
        'nachricht--middle': side !== 'left' && side !== 'right'
      })}
    >
      <div className="nachricht__avatar">
        <Button icon={<Icon />} size="extraLarge" onClick={showProfilModal} />
        {isProfilModalShown && (
          <ProfilModal hide={hideProfilModal} {...profilProps} />
        )}
      </div>
      <div className="nachricht__contentWrapper">
        <div className="nachricht__content">
          {nachricht.anhang?.typ === 'FOTO' && (
            <FotoNachricht url={url} nachricht={nachricht} />
          )}
          {nachricht.anhang?.typ === 'PDF' && (
            <PdfNachricht url={url} nachricht={nachricht} />
          )}
          {!nachricht.anhang &&
            nachricht.text.split('\n').map((zeile, key) => (
              <React.Fragment key={key}>
                {zeile}
                <br />
              </React.Fragment>
            ))}
        </div>
      </div>
      <div className="nachricht__date">{date}</div>
    </div>
  );
};
