import React from 'react';
import { useAuftraege } from '../../DomainContext';
import DateiMitBetragHochladenModal from '../DateiMitBetragHochladenModal/DateiMitBetragHochladenModal';
import { gtmEvent } from '../../googleAnalytics';

export default ({ hide, anfrage }) => {
  const auftraege = useAuftraege();
  const sendeAngebot = async (datei, betrag, betragAsNumber) => {
    await auftraege.sendeAngebot(anfrage, datei, betragAsNumber);

    gtmEvent({
      category: 'request',
      action: 'offerUploaded',
      value: betragAsNumber
    });
  };

  return (
    <DateiMitBetragHochladenModal
      hide={hide}
      ladeDateiHoch={sendeAngebot}
      title="Angebot abgeben"
      dateiHochladenLabel="Angebot abgeben"
      betragLabel="Angebotssumme in Euro (netto)"
    >
      <p>
        Bitte legen Sie hier Ihr Angebot im PDF-Format ab und geben Sie die
        Angebotssumme (netto) aus Ihrem Angebot seperat mit an.
      </p>
    </DateiMitBetragHochladenModal>
  );
};
