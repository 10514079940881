import {
  BEREICH_INNEN,
  BEREICH_AUSSEN,
  OBJEKTART_TREPPEN_GELAENDERARBEITEN,
  OBJEKTART_WAND_DECKENARBEITEN,
  OBJEKTART_FENSTER_TUERARBEITEN,
  TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN,
  TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN,
  TAETIGKEIT_WAENDE_DECKEN_STREICHEN,
  TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN,
  TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN,
  TAETIGKEIT_FENSTER_BEHANDELN,
  TAETIGKEIT_TUEREN_BEHANDELN,
  OBJEKTART_HOLZARBEITEN,
  OBJEKTART_DACHARBEITEN,
  OBJEKTART_FASSADENARBEITEN,
  TAETIGKEIT_FASSADE_STREICHEN,
  TAETIGKEIT_FASSADE_VERPUTZEN,
  TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN,
  TAETIGKEIT_DACHCHECK,
  TEILTAETIGKEIT_DACHCHECK_BASIS,
  TEILTAETIGKEIT_DACHCHECK_PREMIUM,
} from './schritte/konstanten';

export const GEWERK_FLIESENARBEITEN = 'FL';
export const GEWERK_MALERARBEITEN = 'ML';
export const GEWERK_OBERBODENBELAGSARBEITEN = 'OB';
export const GEWERK_SANITAERINSTALLATIONEN = 'SA';
export const GEWERK_DACHDECKERARBEITEN = 'DA';
export const GEWERK_ELEKTROARBEITEN = 'EL';
export const GEWERK_SCHREINER = 'TI';
export const GEWERK_HAUSMEISTER = 'HM';

export const GEWERKE = [
  GEWERK_FLIESENARBEITEN,
  GEWERK_MALERARBEITEN,
  GEWERK_OBERBODENBELAGSARBEITEN,
  GEWERK_SANITAERINSTALLATIONEN,
  GEWERK_DACHDECKERARBEITEN,
  GEWERK_ELEKTROARBEITEN,
  GEWERK_SCHREINER,
  GEWERK_HAUSMEISTER,
];

export function gewerkZuLangform(gewerk) {
  return KURZGEWERKE_ZU_LANGGEWERKE[gewerk] || `Unbekanntes Gewerk: ${gewerk}`;
}

const KURZGEWERKE_ZU_LANGGEWERKE = {
  [GEWERK_FLIESENARBEITEN]: 'Fliesenarbeiten',
  [GEWERK_MALERARBEITEN]: 'Malerarbeiten',
  [GEWERK_OBERBODENBELAGSARBEITEN]: 'Oberbodenbelagsarbeiten',
  [GEWERK_SANITAERINSTALLATIONEN]: 'Sanitärinstallationen',
  [GEWERK_DACHDECKERARBEITEN]: 'Dachdeckerarbeiten',
  [GEWERK_ELEKTROARBEITEN]: 'Elektroarbeiten',
  [GEWERK_SCHREINER]: 'Schreiner',
  [GEWERK_HAUSMEISTER]: 'Hausmeister',
};

const GEWERKE_MATCHING = {
  Bodenleger: GEWERK_OBERBODENBELAGSARBEITEN,
  Elektroinstallateur: GEWERK_ELEKTROARBEITEN,
  Fliesenleger: GEWERK_FLIESENARBEITEN,
  'Maler & Lackierer': GEWERK_MALERARBEITEN,
  Dachdecker: GEWERK_DACHDECKERARBEITEN,
  'Sanitär-, Heizungs-, Klimainstallateur': GEWERK_SANITAERINSTALLATIONEN,
  Schreiner: GEWERK_SCHREINER,
  [OBJEKTART_HOLZARBEITEN]: GEWERK_SCHREINER,
  Hausmeister: GEWERK_HAUSMEISTER,
  Kleinmontage: GEWERK_HAUSMEISTER,
  'Entrümpelungen & Umzugshilfe': GEWERK_HAUSMEISTER,
};

export function istMultigewerk(spezifikation) {
  return (
    spezifikation['Benötigtes Handwerk'] ===
    'mehrere Gewerke für einen Auftrag benötigt'
  );
}

export function zuGewerk(spezifikation) {
  if (spezifikation['Benötigtes Handwerk']) {
    return GEWERKE_MATCHING[spezifikation['Benötigtes Handwerk']];
  }
  return GEWERKE_MATCHING[spezifikation['objektart']];
}

const auftragtitelZuBeschreibung = (spezifikation) =>
  spezifikation['Auftragstitel'];

const einheit = (...einheiten) => (wert) =>
  einheiten.length === 1
    ? einheiten[0]
    : wert > 1
    ? einheiten[1]
    : einheiten[0];

const taetigkeitUndAttributZuBeschreibung = (attribut, einheitCallback) => (
  spezifikation
) =>
  `${spezifikation.taetigkeit}, ${spezifikation[attribut]}${
    einheitCallback ? ` ${einheitCallback(spezifikation[attribut])}` : ''
  }`;

const BESCHREIBUNG = {
  [BEREICH_INNEN]: {
    [OBJEKTART_TREPPEN_GELAENDERARBEITEN]: {
      [TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN]: taetigkeitUndAttributZuBeschreibung(
        'Stufenanzahl'
      ),
    },
    [OBJEKTART_WAND_DECKENARBEITEN]: {
      [TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN]: taetigkeitUndAttributZuBeschreibung(
        'Anzahl der zu tapezierenden Räume',
        einheit('Raum', 'Räume')
      ),
      [TAETIGKEIT_WAENDE_DECKEN_STREICHEN]: taetigkeitUndAttributZuBeschreibung(
        'Anzahl der zu streichenden Räume',
        einheit('Raum', 'Räume')
      ),
      [TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN]: taetigkeitUndAttributZuBeschreibung(
        'Anzahl der zu verputzenden Räume',
        einheit('Raum', 'Räume')
      ),
      [TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN]: taetigkeitUndAttributZuBeschreibung(
        'Quadratmeterzahl der zu dämmenden Fläche',
        einheit('qm')
      ),
    },
    [OBJEKTART_FENSTER_TUERARBEITEN]: {
      [TAETIGKEIT_FENSTER_BEHANDELN]: taetigkeitUndAttributZuBeschreibung(
        'Anzahl zu behandelnder Fenster',
        einheit('Fenster')
      ),
      [TAETIGKEIT_TUEREN_BEHANDELN]: taetigkeitUndAttributZuBeschreibung(
        'Anzahl zu behandelnder Türen',
        einheit('Tür', 'Türen')
      ),
    },
  },
  [BEREICH_AUSSEN]: {
    [OBJEKTART_DACHARBEITEN]: {
      [TAETIGKEIT_DACHCHECK]: {
        [TEILTAETIGKEIT_DACHCHECK_BASIS]: () => 'Dachcheck Basis',
        [TEILTAETIGKEIT_DACHCHECK_PREMIUM]: () => 'Dachcheck Premium',
      },
    },
    [OBJEKTART_FASSADENARBEITEN]: {
      [TAETIGKEIT_FASSADE_STREICHEN]: taetigkeitUndAttributZuBeschreibung(
        'Zu streichende Fläche',
        einheit('qm')
      ),
      [TAETIGKEIT_FASSADE_VERPUTZEN]: taetigkeitUndAttributZuBeschreibung(
        'Zu verputzende Fläche',
        einheit('qm')
      ),
      [TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN]: taetigkeitUndAttributZuBeschreibung(
        'Zu dämmende Fläche',
        einheit('qm')
      ),
    },
  },
};

export function zuBeschreibung(spezifikation) {
  try {
    const bereichbeschreibung = BESCHREIBUNG[spezifikation.bereich];
    if (typeof bereichbeschreibung === 'function') {
      return bereichbeschreibung(spezifikation);
    }
    const objektartbeschreibung = bereichbeschreibung[spezifikation.objektart];
    if (typeof objektartbeschreibung === 'function') {
      return objektartbeschreibung(spezifikation);
    }
    if (!objektartbeschreibung) {
      return auftragtitelZuBeschreibung(spezifikation);
    }
    const taetigkeitbeschreibung =
      objektartbeschreibung[spezifikation.taetigkeit];
    if (typeof taetigkeitbeschreibung === 'function') {
      return taetigkeitbeschreibung(spezifikation);
    }
    if (!taetigkeitbeschreibung) {
      return auftragtitelZuBeschreibung(spezifikation);
    }
    const teiltaetigkeitbeschreibung =
      taetigkeitbeschreibung[spezifikation.teiltaetigkeit];
    if (typeof teiltaetigkeitbeschreibung === 'function') {
      return teiltaetigkeitbeschreibung(spezifikation);
    }
    if (!teiltaetigkeitbeschreibung) {
      return auftragtitelZuBeschreibung(spezifikation);
    }
  } catch (e) {
    throw new Error(
      `Fehler "${e.message}" bei der Beschreibungsfindung: ${JSON.stringify(
        spezifikation
      )}`
    );
  }

  throw new Error(
    `Zur Spezifikation konnte keine Beschreibung ermittelt werden: ${JSON.stringify(
      spezifikation
    )}`
  );
}
