import SCHRITTE from '../../../domain/schritte';
import { EGAL_WELCHER_SCHRITT } from '../../../domain/schritte/konstanten';

export default function bereinigeSpezifikation(unbereinigteSpezifikation) {
  const schritteDesPfades = SCHRITTE.filter((s) =>
    s.bedingung(EGAL_WELCHER_SCHRITT, unbereinigteSpezifikation)
  );

  const moeglicherweiseGesetzteAttribute = ermittleMoeglicherweiseGesetzteAttribute(
    schritteDesPfades
  );

  return bereinigeAttribute(
    unbereinigteSpezifikation,
    moeglicherweiseGesetzteAttribute
  );
}

function ermittleMoeglicherweiseGesetzteAttribute(schritteDesPfades) {
  return schritteDesPfades.reduce(
    (attribute, durchlaufenderSchritt) => {
      if (
        durchlaufenderSchritt.typ === 'sofortauswahl' ||
        durchlaufenderSchritt.typ === 'textauswahl' ||
        durchlaufenderSchritt.typ === 'einfachauswahl' ||
        durchlaufenderSchritt.typ === 'zeitraum'
      ) {
        return { ...attribute, [durchlaufenderSchritt.attribut]: true };
      } else if (durchlaufenderSchritt.typ === 'mehrfachauswahl') {
        return {
          ...attribute,
          [durchlaufenderSchritt.attribut]: durchlaufenderSchritt.auswahlmoeglichkeiten.map(
            (a) => a.wert
          ),
        };
      } else if (durchlaufenderSchritt.typ === 'gemischteAuswahl') {
        return {
          ...attribute,
          ...ermittleEingabenattribute(durchlaufenderSchritt),
        };
      } else if (durchlaufenderSchritt.typ === 'standortauswahl') {
        return {
          ...attribute,
          'Straße und Hausnummer': true,
          PLZ: true,
          Ort: true,
          'Parkplatzsituation vor Ort': true,
          Adresszusatz: true,
        };
      } else if (durchlaufenderSchritt.typ === 'kontaktauswahl') {
        return {
          ...attribute,
          Telefonnummer: true,
          Kontaktwunsch: true,
        };
      }
      return attribute;
    },
    { 'Fotos, Grundriss': true, 'Fotos von Dach, Haus und Dachzufahrt': true }
  );
}

function bereinigeAttribute(
  unbereinigteSpezifikation,
  moeglicherweiseGesetzteAttribute
) {
  const bereinigteSpezifikation = { ...unbereinigteSpezifikation };
  Object.keys(bereinigteSpezifikation).forEach((attribut) => {
    if (bereinigteSpezifikation[attribut]) {
      if (attribut.endsWith('Text')) {
        const nameDesDazugehoerigenAttributs = String(attribut).substring(
          0,
          String(attribut).length - 4
        );
        const wertDesDazugehoerigenAttributs =
          bereinigteSpezifikation[nameDesDazugehoerigenAttributs];
        if (
          Array.isArray(wertDesDazugehoerigenAttributs) &&
          wertDesDazugehoerigenAttributs.includes('Sonstige')
        ) {
          bereinigteSpezifikation[
            nameDesDazugehoerigenAttributs
          ] = bereinigteSpezifikation[
            nameDesDazugehoerigenAttributs
          ].map((value) =>
            value === 'Sonstige' ? bereinigteSpezifikation[attribut] : value
          );
        } else if (wertDesDazugehoerigenAttributs === 'Sonstiges') {
          bereinigteSpezifikation[nameDesDazugehoerigenAttributs] =
            bereinigteSpezifikation[attribut];
        }

        delete bereinigteSpezifikation[attribut];
      }

      if (Array.isArray(moeglicherweiseGesetzteAttribute[attribut])) {
        const unbereinigteWerte = unbereinigteSpezifikation[attribut] || [];
        bereinigteSpezifikation[attribut] = unbereinigteWerte.filter((w) =>
          moeglicherweiseGesetzteAttribute[attribut].includes(w)
        );
      } else if (moeglicherweiseGesetzteAttribute[attribut] !== true) {
        delete bereinigteSpezifikation[attribut];
      }

      // Deduplikation der Werte
      if (Array.isArray(bereinigteSpezifikation[attribut])) {
        bereinigteSpezifikation[attribut] = unique(
          bereinigteSpezifikation[attribut]
        );
      }

      if (attribut === 'Fotos von Dach, Haus und Dachzufahrt') {
        bereinigteSpezifikation['Fotos, Grundriss'] =
          bereinigteSpezifikation[attribut];
        delete bereinigteSpezifikation[attribut];
      }
    }
  });

  return bereinigteSpezifikation;
}

function ermittleEingabenattribute(durchlaufenderSchritt) {
  const nichtTypDateiupload = (e) => e.typ !== 'dateiupload';
  const nichtTypLeerrraum = (e) => e.typ !== 'leerraum';

  return durchlaufenderSchritt.eingaben
    .filter(nichtTypDateiupload)
    .filter(nichtTypLeerrraum)
    .reduce((eingabeAttribute, e) => {
      if (e.typ === 'mehrfachauswahl') {
        return {
          ...eingabeAttribute,
          [e.attribut]: e.auswahlmoeglichkeiten.map((a) => a.wert),
        };
      }
      return { ...eingabeAttribute, [e.attribut]: true };
    }, {});
}

function unique(list) {
  return list.filter((value, index) => list.indexOf(value) === index);
}
