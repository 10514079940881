import moment from 'moment-business-days';
import 'moment/locale/de';

export const initializeMoment = () => {
  moment.updateLocale('de', {
    workingWeekdays: [1, 2, 3, 4, 5],
  });
  moment.locale('de');
};

export const currentDateTimeAsISOString = () => moment().toISOString();

export const toDateTimeString = (isoString) =>
  moment(isoString).isSame(Date.now(), 'day')
    ? moment(isoString).format('[Heute], HH:mm [Uhr]')
    : moment(isoString).isSame(moment().subtract(1, 'days'), 'day')
    ? moment(isoString).format('[Gestern], HH:mm [Uhr]')
    : moment(isoString).format('DD.MM.YYYY, HH:mm [Uhr]');

export const toDateString = (isoString) =>
  moment(isoString).format('DD.MM.YYYY');

export const toTimeString = (isoString) => moment(isoString).format('HH:mm');

export const toCustomFormat = (isoString, format) =>
  moment(isoString).format(format);

export const toStartOfDayString = (isoString) =>
  moment(isoString).startOf('day').toISOString();

export const toTodayOrCustomFormat = (isoString, format) => {
  const date = moment(isoString);
  return date.isBefore(moment().startOf('day')) ? date.format(format) : 'Heute';
};

export const toNumberOfYears = (isoString) =>
  moment().diff(moment(isoString), 'years');

export const isYoungerThanDays = (isoString, days) =>
  moment.utc(isoString).isAfter(moment.utc().subtract(days, 'days'), 'days');

export const isOlderThanBusinessDays = (isoString, days) =>
  moment.utc(isoString).isBefore(moment.utc().businessSubtract(days, 'days'));

export const isYoungerThanDate = (isoString1, isoString2) =>
  moment(isoString1).isAfter(isoString2);

export const isBetweenDays = (leftIsoString, isoString, rightIsoString) =>
  moment(isoString).isBetween(leftIsoString, rightIsoString, 'days', '[]');

export const isOlderThanYears = (isoString, years) =>
  moment
    .utc(isoString)
    .endOf('year')
    .add(years, 'years')
    .isBefore(moment.utc());

export const toTime = (dateString) => new Date(dateString).getTime();
