import React from 'react';
import LegalContent from '../../components/LegalContent/LegalContent';
import { useBenutzerContext } from '../../contexts/BenutzerContext';
import KundenText from './KundenText';
import HandwerkerText from './HandwerkerText';

export default function Datenschutz({ rolle }) {
  const { istHandwerker } = useBenutzerContext();

  const defaultTab =
    istHandwerker || rolle === 'HANDWERKER' ? 'für Dienstleister' : 'für Kunde';

  return (
    <LegalContent
      headline="Information zur Verwendung Ihrer Daten nach Art. 13, 14 DS-GVO"
      tabs={['für Kunde', 'für Dienstleister']}
      defaultTab={defaultTab}
    >
      {(content) =>
        content === 'für Kunde' ? <KundenText /> : <HandwerkerText />
      }
    </LegalContent>
  );
}
