import React from 'react';

export default function DachcheckTeaserBildMobile(props) {
  return (
    <svg
      {...props}
      width="672"
      height="316"
      viewBox="0 0 672 316"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.938 174.88L190.06 174.395L329.771 174.266C329.843 175.488 329.12 176.534 328.138 176.66L328.135 176.664L328.101 176.664C328.078 176.667 328.054 176.668 328.031 176.67C327.957 176.674 327.885 176.673 327.813 176.668L187.156 178.331L186.677 175.536L188.938 174.88Z"
        fill="#B7B7B7"
      />
      <path
        d="M187.365 178.319C188.976 178.223 190.183 176.466 190.06 174.395L184.224 174.741C184.347 176.812 185.753 178.414 187.365 178.319Z"
        fill="#878787"
      />
      <path
        d="M556.022 251.535L141.518 257.605L350.863 316L672 272.674L556.022 251.535Z"
        fill="#E0EAE6"
      />
      <path
        d="M215.207 174.93L186.108 174.721L186.318 173.046L244.725 2.46509L274.871 8.95346L215.207 174.93Z"
        fill="#C4C4C4"
      />
      <path
        d="M209.346 166.977L263.148 23.3954L350.026 135.791V287.326L209.346 259.907V166.977Z"
        fill="#EAEAEA"
      />
      <path
        d="M599.357 266.395L350.026 287.326V135.791L599.357 160.907V266.395Z"
        fill="white"
      />
      <path
        d="M599.357 188.744V174.302L259.589 32.814L209.346 166.977V259.907L284.71 274.558L350.026 181.419L599.357 188.744Z"
        fill="black"
        fillOpacity="0.08"
      />
      <path
        d="M248.912 13.9767L192.598 174.721L194.482 178.279L251.843 18.7906L248.912 13.9767Z"
        fill="white"
      />
      <path
        d="M196.785 178.279H194.482L251.633 19.2094L252.889 22.1396L196.785 178.279Z"
        fill="#D7D7D7"
      />
      <path d="M254.355 260.326V199.209" stroke="black" />
      <path
        d="M266.079 198.791L249.75 199.209L250.168 260.535L254.355 260.326L266.079 262.419V198.791Z"
        fill="#CFCFCF"
      />
      <path
        d="M253.727 199.209L254.355 260.326L266.078 262.419V198.791L253.727 199.209Z"
        fill="#096547"
      />
      <path
        d="M329.091 196.907L308.157 197.116V222.233L329.091 222.86V196.907Z"
        fill="#CFCFCF"
      />
      <path
        d="M329.091 196.907L313.181 197.116V222.233L329.091 222.86V196.907Z"
        fill="#878787"
      />
      <path
        d="M429.577 196.907L399.013 196.488V240.233L429.577 239.814V196.907Z"
        fill="#CFCFCF"
      />
      <path
        d="M424.134 196.907L399.013 196.488V238.558L424.134 238.14V196.907Z"
        fill="#878787"
      />
      <path
        d="M505.779 197.953H479.402V238.349L505.779 237.93V197.953Z"
        fill="#CFCFCF"
      />
      <path
        d="M500.336 197.953H479.402V236.674L500.336 236.256V197.953Z"
        fill="#878787"
      />
      <path
        d="M232.792 200.047H220.022V219.721L232.792 219.93V200.047Z"
        fill="#CFCFCF"
      />
      <path
        d="M232.792 200.465H224.209V219.721L232.792 219.93V200.465Z"
        fill="#878787"
      />
      <path
        d="M294.968 100.628L277.592 105.233V172.209L295.596 171.163L294.968 100.628Z"
        fill="#CFCFCF"
      />
      <path
        d="M294.968 102.302L282.617 105.651V171.791L295.596 171.163L294.968 102.302Z"
        fill="#878787"
      />
      <path
        d="M256.448 111.302L242.003 115.279V175.977L256.448 174.93V111.302Z"
        fill="#CFCFCF"
      />
      <path
        d="M256.449 112.977L246.609 115.698V175.558L256.449 174.93V112.977Z"
        fill="#878787"
      />
      <path
        d="M243.887 1L184.643 173.256L186.108 174.721L243.887 7.06977L331.813 156.721L621.338 172.419L624.897 170.326L510.803 52.6977L243.887 1Z"
        fill="#373737"
      />
      <path
        d="M243.887 1L334.115 152.744L331.813 156.721L243.887 7.06977L186.108 174.721L184.643 173.256L243.887 1Z"
        fill="black"
      />
      <path
        d="M624.897 170.326L334.115 152.744L331.812 156.721L621.338 172.419L624.897 170.326Z"
        fill="black"
      />
      <path
        d="M444.859 102.302L415.97 98.7441L438.16 126.163L466.422 128.256L444.859 102.302Z"
        fill="#9F9F9F"
      />
      <path
        d="M473.749 128.884L452.187 103.14L479.611 106.279L500.127 130.977L473.749 128.884Z"
        fill="#9F9F9F"
      />
      <path
        d="M463.282 54.1627V33.2325L476.471 31.9767V67.1395L463.282 54.1627Z"
        fill="#EAEAEA"
      />
      <path
        d="M490.288 34.6976L476.471 31.9767V67.5581L490.288 68.186V34.6976Z"
        fill="white"
      />
      <path
        d="M476.471 31.9767L462.445 33.4418V30.3023L476.471 28.4186V31.9767Z"
        fill="#CFCFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M590.773 267.651V202.5C590.773 195.019 595.159 188.234 601.98 185.162L602.381 184.981C606.911 182.94 609.824 178.433 609.824 173.465H615.267C615.267 179.893 611.68 185.784 605.97 188.735L604.62 189.432C599.716 191.966 596.635 197.026 596.635 202.546V267.861H596.576C596.305 268.338 595.122 268.698 593.704 268.698C592.286 268.698 591.104 268.338 590.832 267.861H590.773V267.651Z"
        fill="#B7B7B7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M344.792 156.093L347.095 155.256L626.781 171.581C626.781 174.036 625.209 176.042 623.228 176.179L623.222 176.186L623.154 176.183C623.107 176.185 623.06 176.186 623.013 176.186C622.865 176.186 622.719 176.176 622.576 176.155L340.815 162.791L340.187 157.14L344.792 156.093Z"
        fill="#B7B7B7"
      />
      <path
        d="M341.233 162.791C344.471 162.791 347.095 159.417 347.095 155.256H335.372C335.372 159.417 337.996 162.791 341.233 162.791Z"
        fill="#878787"
      />
      <path
        d="M254.355 270.791L223.791 264.721L243.05 263.256L274.034 268.698L254.355 270.791Z"
        fill="#F7F7F7"
      />
      <path
        d="M254.355 260.326L237.188 261.163L252.261 263.256L266.078 262.419L254.355 260.326Z"
        fill="#F7F7F7"
      />
      <path
        d="M266.079 267.316V262.419L252.262 263.256V268.698L266.079 267.316Z"
        fill="#EAEAEA"
      />
      <path
        d="M252.261 263.256L237.188 261.163V265.558L252.261 268.698V263.256Z"
        fill="#B7B7B7"
      />
      <path
        d="M254.355 270.791L223.791 264.721V270.581L254.355 277.698L274.034 274.767V268.698L254.355 270.791Z"
        fill="#EAEAEA"
      />
      <path
        d="M254.355 270.791L223.791 264.721V270.581L254.355 277.698V270.791Z"
        fill="#B7B7B7"
      />
      <path
        d="M491.125 31.7674L476.471 28.4186V31.9767L491.125 34.907V31.7674Z"
        fill="#EAEAEA"
      />
      <path
        d="M262.729 204.86H259.798V232.07L262.729 232.279V204.86Z"
        fill="#373737"
      />
      <rect
        width="144.145"
        height="188.018"
        transform="matrix(0.997173 -0.07514 0.075171 0.997171 13.458 67.1075)"
        fill="#406A5E"
        fillOpacity="0.1"
      />
      <rect
        width="142.383"
        height="187.908"
        transform="matrix(0.997173 -0.07514 0.075171 0.997171 20.3447 58.2091)"
        fill="white"
      />
      <rect
        opacity="0.4"
        width="60.4715"
        height="5.47502"
        rx="2.73751"
        transform="matrix(0.997587 -0.0694241 0.0694526 0.997585 76.2021 85.7795)"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.3686 83.1645C67.3659 84.0298 67.4728 85.5396 66.6073 86.5367L55.9697 98.7927C55.1043 99.7898 53.5942 99.8967 52.5968 99.0314L46.4677 93.7138C45.4704 92.8485 45.3635 91.3388 46.2289 90.3417C47.0944 89.3445 48.6044 89.2377 49.6018 90.1029L53.9251 93.8538L62.9957 83.4032C63.8612 82.4061 65.3712 82.2992 66.3686 83.1645Z"
        fill="#096547"
      />
      <rect
        opacity="0.4"
        width="41.0679"
        height="5.47501"
        rx="2.7375"
        transform="matrix(0.997587 -0.069424 0.0694527 0.997585 78.7144 115.082)"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.8808 112.467C69.8781 113.332 69.985 114.842 69.1196 115.839L58.4819 128.095C57.6165 129.092 56.1064 129.199 55.1091 128.334L48.9799 123.016C47.9826 122.151 47.8757 120.641 48.7411 119.644C49.6066 118.647 51.1167 118.54 52.114 119.405L56.4373 123.156L65.5079 112.706C66.3734 111.708 67.8835 111.602 68.8808 112.467Z"
        fill="#096547"
      />
      <rect
        opacity="0.4"
        width="70.663"
        height="5.47501"
        rx="2.7375"
        transform="matrix(0.997587 -0.069424 0.0694528 0.997585 80.8081 144.384)"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.9745 141.769C71.9719 142.634 72.0788 144.144 71.2133 145.141L60.5757 157.397C59.7102 158.394 58.2001 158.501 57.2028 157.636L51.0737 152.318C50.0763 151.453 49.9694 149.943 50.8349 148.946C51.7003 147.949 53.2104 147.842 54.2077 148.708L58.5311 152.458L67.6017 142.008C68.4671 141.011 69.9772 140.904 70.9745 141.769Z"
        fill="#096547"
      />
      <rect
        opacity="0.4"
        width="26.0173"
        height="5.47501"
        rx="2.73751"
        transform="matrix(0.997587 -0.069424 0.0694527 0.997585 83.7388 173.687)"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.9052 171.072C74.9025 171.937 75.0094 173.447 74.144 174.444L63.5063 186.7C62.6409 187.697 61.1308 187.804 60.1335 186.938L54.0043 181.621C53.007 180.756 52.9001 179.246 53.7655 178.249C54.631 177.252 56.1411 177.145 57.1384 178.01L61.4617 181.761L70.5323 171.31C71.3978 170.313 72.9079 170.206 73.9052 171.072Z"
        fill="#096547"
      />
      <rect
        opacity="0.4"
        width="60.4715"
        height="5.475"
        rx="2.7375"
        transform="matrix(0.997587 -0.0694239 0.0694529 0.997585 86.251 202.57)"
        fill="#878787"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4174 199.955C77.4147 200.82 77.5216 202.33 76.6562 203.327L66.0185 215.583C65.1531 216.58 63.643 216.687 62.6457 215.822L56.5165 210.504C55.5192 209.639 55.4123 208.129 56.2777 207.132C57.1432 206.135 58.6533 206.028 59.6506 206.894L63.9739 210.644L73.0445 200.194C73.91 199.197 75.4201 199.09 76.4174 199.955Z"
        fill="#096547"
      />
      <circle
        cx="327"
        cy="17"
        r="16"
        fill="#69A82F"
        stroke="#69A82F"
        strokeWidth="2"
      />
      <circle
        cx="422"
        cy="101"
        r="16"
        fill="#69A82F"
        stroke="#69A82F"
        strokeWidth="2"
      />
      <circle
        cx="490"
        cy="50"
        r="16"
        fill="#69A82F"
        stroke="#69A82F"
        strokeWidth="2"
      />
      <circle
        cx="595"
        cy="208"
        r="16"
        fill="#69A82F"
        stroke="#69A82F"
        strokeWidth="2"
      />
      <circle
        cx="374"
        cy="160"
        r="16"
        fill="#69A82F"
        stroke="#69A82F"
        strokeWidth="2"
      />
      <circle
        cx="27"
        cy="162"
        r="16"
        fill="#69A82F"
        stroke="#69A82F"
        strokeWidth="2"
      />
      <path
        d="M328.604 25H325.5V13.0332L321.794 14.1826V11.6582L328.271 9.33789H328.604V25Z"
        fill="white"
      />
      <path
        d="M495.784 58H485.063V55.873L490.123 50.4805C490.818 49.7214 491.33 49.0589 491.659 48.4932C491.996 47.9274 492.164 47.3903 492.164 46.8818C492.164 46.1872 491.989 45.6429 491.638 45.249C491.287 44.848 490.785 44.6475 490.134 44.6475C489.432 44.6475 488.877 44.891 488.469 45.3779C488.068 45.8577 487.867 46.4915 487.867 47.2793H484.752C484.752 46.3268 484.978 45.4567 485.429 44.6689C485.887 43.8812 486.532 43.2653 487.362 42.8213C488.193 42.3701 489.135 42.1445 490.188 42.1445C491.799 42.1445 493.049 42.5312 493.937 43.3047C494.832 44.0781 495.279 45.1702 495.279 46.5811C495.279 47.3545 495.079 48.1423 494.678 48.9443C494.277 49.7464 493.589 50.681 492.615 51.748L489.06 55.4971H495.784V58Z"
        fill="white"
      />
      <path
        d="M420.222 99.8047H421.876C422.664 99.8047 423.247 99.6077 423.627 99.2139C424.007 98.82 424.196 98.2972 424.196 97.6455C424.196 97.0153 424.007 96.5247 423.627 96.1738C423.255 95.8229 422.739 95.6475 422.08 95.6475C421.486 95.6475 420.988 95.8122 420.587 96.1416C420.186 96.4639 419.985 96.8864 419.985 97.4092H416.881C416.881 96.5928 417.099 95.8623 417.536 95.2178C417.98 94.5661 418.596 94.0576 419.384 93.6924C420.179 93.3271 421.052 93.1445 422.005 93.1445C423.659 93.1445 424.955 93.542 425.894 94.3369C426.832 95.1247 427.301 96.2132 427.301 97.6025C427.301 98.3187 427.082 98.9775 426.646 99.5791C426.209 100.181 425.636 100.643 424.927 100.965C425.808 101.28 426.463 101.753 426.893 102.383C427.329 103.013 427.548 103.758 427.548 104.617C427.548 106.007 427.039 107.12 426.022 107.958C425.013 108.796 423.674 109.215 422.005 109.215C420.444 109.215 419.165 108.803 418.17 107.979C417.182 107.156 416.688 106.067 416.688 104.714H419.792C419.792 105.301 420.01 105.781 420.447 106.153C420.891 106.526 421.436 106.712 422.08 106.712C422.818 106.712 423.394 106.519 423.81 106.132C424.232 105.738 424.443 105.219 424.443 104.574C424.443 103.013 423.584 102.232 421.865 102.232H420.222V99.8047Z"
        fill="white"
      />
      <path
        d="M377.248 162.113H379.021V164.616H377.248V168H374.144V164.616H367.73L367.591 162.661L374.111 152.359H377.248V162.113ZM370.685 162.113H374.144V156.592L373.939 156.946L370.685 162.113Z"
        fill="white"
      />
      <path
        d="M21.5254 162.309L22.4277 154.359H31.1934V156.948H24.9736L24.5869 160.311C25.3245 159.917 26.1087 159.72 26.9395 159.72C28.429 159.72 29.5964 160.182 30.4414 161.105C31.2865 162.029 31.709 163.322 31.709 164.983C31.709 165.993 31.4941 166.899 31.0645 167.701C30.6419 168.496 30.0332 169.116 29.2383 169.56C28.4434 169.996 27.5052 170.215 26.4238 170.215C25.4785 170.215 24.6012 170.025 23.792 169.646C22.9827 169.259 22.3418 168.718 21.8691 168.023C21.4036 167.329 21.1566 166.537 21.1279 165.649H24.2002C24.2646 166.301 24.4902 166.81 24.877 167.175C25.2708 167.533 25.7829 167.712 26.4131 167.712C27.1149 167.712 27.6556 167.461 28.0352 166.96C28.4147 166.451 28.6045 165.735 28.6045 164.812C28.6045 163.924 28.3861 163.243 27.9492 162.771C27.5124 162.298 26.8929 162.062 26.0908 162.062C25.3532 162.062 24.7552 162.255 24.2969 162.642L23.9961 162.921L21.5254 162.309Z"
        fill="white"
      />
      <path
        d="M598.485 200.198V202.755H598.185C596.781 202.776 595.649 203.142 594.79 203.851C593.938 204.56 593.426 205.544 593.254 206.805C594.085 205.96 595.134 205.537 596.401 205.537C597.762 205.537 598.843 206.024 599.646 206.998C600.448 207.972 600.849 209.254 600.849 210.844C600.849 211.861 600.627 212.781 600.183 213.604C599.746 214.428 599.123 215.069 598.313 215.527C597.511 215.986 596.602 216.215 595.585 216.215C593.938 216.215 592.606 215.642 591.589 214.496C590.579 213.35 590.074 211.821 590.074 209.909V208.792C590.074 207.095 590.393 205.598 591.03 204.302C591.675 202.998 592.595 201.992 593.791 201.283C594.994 200.567 596.387 200.205 597.97 200.198H598.485ZM595.456 208.029C594.955 208.029 594.5 208.162 594.092 208.427C593.684 208.685 593.383 209.028 593.189 209.458V210.403C593.189 211.442 593.394 212.255 593.802 212.842C594.21 213.422 594.783 213.712 595.521 213.712C596.187 213.712 596.724 213.451 597.132 212.928C597.547 212.398 597.755 211.714 597.755 210.876C597.755 210.024 597.547 209.336 597.132 208.813C596.716 208.291 596.158 208.029 595.456 208.029Z"
        fill="white"
      />
    </svg>
  );
}
