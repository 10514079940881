import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { useAuftraege } from '../../DomainContext';
import { gtmEvent } from '../../googleAnalytics';
import { CONCIERGE_SERVICE_GRUND } from '../../domain/auftraege';

import './AuftragWeiterleitenModal.scss';

export default function AuftragWeiterleitenModal({
  hide,
  auftrag,
  anfrageIds,
  zeigeDetails,
  grund,
  gtmLabel,
}) {
  const history = useHistory();
  const auftraege = useAuftraege();
  const createLeiteAuftragWeiter = (setLoading) => async () => {
    setLoading(true);
    await auftraege.leiteAnConciergeServiceWeiter({
      auftrag,
      anfrageIds,
      grund,
    });

    gtmEvent({
      category: 'request',
      action: 'sentToConciergeService',
      label: gtmLabel,
    });

    setLoading(false);
    hide();
    history.push('/meineAuftraege?auftragAnConciergeGesendet');
  };
  const actions = (setLoading) => (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        color="green"
        onClick={createLeiteAuftragWeiter(setLoading)}
        data-cy-id="auftragWeiterleitenModalAction"
      >
        Auftrag weiterleiten
      </Button>
    </>
  );

  return (
    <Modal
      className="modal--dialog"
      hide={hide}
      title={modalTitle(grund, zeigeDetails)}
      actions={actions}
      gaTrackingName={gtmLabel}
    >
      <ModalContent grund={grund} zeigeDetails={zeigeDetails} />
    </Modal>
  );
}

function modalTitle(grund, zeigeDetails) {
  if (grund === CONCIERGE_SERVICE_GRUND.HANDWERKER_REAGIEREN_NICHT) {
    return 'Die angefragten Fachpartner haben sich bisher nicht gemeldet?';
  }
  if (zeigeDetails) {
    return 'Die angefragten Fachpartner konnten Ihnen nicht weiterhelfen?';
  }

  return 'Auftrag weiterleiten';
}

function ModalContent({ grund, zeigeDetails }) {
  if (grund === CONCIERGE_SERVICE_GRUND.HANDWERKER_REAGIEREN_NICHT) {
    return (
      <p className="auftragWeiterleitenModal__paragraph">
        Das tut uns leid. Die Gründe dafür kennen wir nicht. Wir können Ihnen
        jedoch weiterhelfen. Leiten Sie Ihre Anfrage gern an unsere
        Servicemitarbeiter weiter. Diese werden zeitnah einen passenden
        Fachpartner finden und sich bei Ihnen melden.
      </p>
    );
  }
  return (
    <>
      {zeigeDetails && (
        <p className="auftragWeiterleitenModal__paragraph">
          Geben Sie uns noch eine Chance! Wenn Sie Ihre Anfrage an uns
          weiterleiten, suchen unsere Servicemitarbeiter einen für Sie
          geeigneten Fachpartner außerhalb der Plattform.
        </p>
      )}
      <p className="auftragWeiterleitenModal__paragraph">
        Wenn Sie den Auftrag an uns weiterleiten werden wir persönlich für Sie
        einen Fachpartner suchen. Wir melden uns innerhalb der nächsten drei
        Werktage mit einem Vorschlag bei Ihnen.
      </p>
      <p className="auftragWeiterleitenModal__paragraph">
        Der Nachrichtenverlauf wird nicht an uns übertragen.
      </p>
    </>
  );
}
