import Button from '../Button/Button';
import { useModalState } from '../../hooks';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SPEZIFIKATION_INITIALE_ZUSTAENDE,
  SPEZIFIKATIONS_ZUSTAND_KEY,
} from '../../domain/schritte/konstanten';
import localforage from 'localforage';
import Modal from '../Modal/Modal';
import { gtmEvent } from '../../googleAnalytics';

export default function NeuerAuftragSchaltflaeche({
  children,
  gtmEventData,
  einstiegspunkt,
  Component = Button,
  ...props
}) {
  const [
    isNeueAnfrageShown,
    showNeueAnfrageModal,
    hideNeueAnfrageModal,
  ] = useModalState();

  const onClick = () => {
    if (gtmEventData) {
      gtmEvent(gtmEventData);
    }
    showNeueAnfrageModal();
  };

  return (
    <>
      {isNeueAnfrageShown && (
        <AuftragExistiertModal
          hide={hideNeueAnfrageModal}
          einstiegspunkt={einstiegspunkt}
        />
      )}
      {React.createElement(Component, { ...props, onClick }, children)}
    </>
  );
}

function AuftragExistiertModal({ hide, einstiegspunkt = '' }) {
  const [hasSpezifikation, setHasSpezifikation] = useState(null);
  const history = useHistory();

  const geheZuSpezifikationsassistent = () => {
    history.push('/spezifikationsassistent');
  };

  const starteNeueSpezifikation = () => {
    const spezifikationsInitialerZustand =
      SPEZIFIKATION_INITIALE_ZUSTAENDE[einstiegspunkt.toUpperCase()] ||
      SPEZIFIKATION_INITIALE_ZUSTAENDE.NEU;
    localforage
      .setItem(SPEZIFIKATIONS_ZUSTAND_KEY, spezifikationsInitialerZustand)
      .then(geheZuSpezifikationsassistent);
  };

  useEffect(() => {
    localforage
      .getItem(SPEZIFIKATIONS_ZUSTAND_KEY)
      .then((spezifikationState) => {
        setHasSpezifikation(Boolean(spezifikationState));
      })
      .catch(console.error);
  }, []);

  if (hasSpezifikation === false) {
    starteNeueSpezifikation();
  }

  const actions = (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        color="yellow"
        onClick={() => {
          gtmEvent({
            category: 'assistant',
            action: 'continueRequestClicked',
          });
          geheZuSpezifikationsassistent();
        }}
      >
        Anfrage fortsetzen
      </Button>
      <Button
        color="yellow"
        onClick={() => {
          gtmEvent({
            category: 'assistant',
            action: 'startNewRequestClicked',
          });
          starteNeueSpezifikation();
        }}
      >
        Neue Anfrage starten
      </Button>
    </>
  );

  return hasSpezifikation === true ? (
    <Modal
      className="modal--dialog modal--wide modal--center"
      title="Bestehende Anfrage gefunden"
      closeable={false}
      gaTrackingName="Bestehende Anfrage gefunden"
      actions={actions}
    >
      <p className="modal__contentText">
        Wir haben eine bestehende, nicht versendete Anfrage gefunden. Möchten
        Sie diese fortsetzen oder möchten Sie diese löschen und eine neue
        Anfrage starten?
      </p>
    </Modal>
  ) : null;
}
