import React from 'react';
import { useAuftraege } from '../../DomainContext';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

export default ({ hide, anfrage, angebot }) => {
  const auftraege = useAuftraege();
  const angebotAblehnen = () => {
    auftraege.lehneAngebotAb(anfrage, angebot);
    hide();
  };
  const actions = (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button color="red" onClick={angebotAblehnen}>
        Angebot ablehnen
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title={`Möchten Sie das Angebot von ${anfrage.handwerker.firmenname} ablehnen?`}
      hide={hide}
      actions={actions}
      gaTrackingName="Angebot ablehnen"
    >
      <span className="modal__contentText">
        Das Angebot bleibt weiter für Sie einsehbar, kann jedoch später nicht
        mehr angenommen werden.
      </span>
    </Modal>
  );
};
