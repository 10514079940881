import Anfrage from '../domain/anfrage';
import Angebot from '../domain/angebot';
import Nachricht from '../domain/nachricht';
import Auftrag from '../domain/auftrag';
import Rechnung from '../domain/rechnung';
import { jsonParseWhileString } from '../collections';

export function unmarshallObject(obj) {
  try {
    return JSON.parse(obj);
  } catch (_) {
    return undefined;
  }
}

export function unmarshallArray(array) {
  return Array.isArray(array)
    ? array
    : Array.isArray(array?.items)
    ? array.items
    : [];
}

export function unmarshallAnfragen(anfragen) {
  return unmarshallArray(anfragen).map(unmarshallAnfrage);
}

export function unmarshallAnfrage(anfrage) {
  if (!anfrage || anfrage instanceof Anfrage) {
    return anfrage;
  }
  const anfragedaten = {
    ...anfrage,
    angebote: unmarshallArray(anfrage.angebote).map(unmarshallAngebot),
    rechnungen: unmarshallArray(anfrage.rechnungen).map(unmarshallRechnung),
    nachrichten: unmarshallArray(anfrage.nachrichten).map(unmarshallNachricht),
    auftrag: unmarshallAuftrag(anfrage.auftrag),
  };
  delete anfragedaten.__typename;
  return new Anfrage(anfragedaten);
}

export function unmarshallAngebote(angebote) {
  return unmarshallArray(angebote).map(unmarshallAngebot);
}

export function unmarshallAngebot(angebot) {
  if (!angebot || angebot instanceof Angebot) {
    return angebot;
  }
  const angebotsdaten = {
    ...angebot,
  };
  delete angebotsdaten.__typename;
  return new Angebot(angebotsdaten);
}

export function unmarshallRechnung(rechnung) {
  if (!rechnung || rechnung instanceof Rechnung) {
    return rechnung;
  }
  const rechnungsdaten = {
    ...rechnung,
  };
  delete rechnungsdaten.__typename;
  return new Rechnung(rechnungsdaten);
}

export function unmarshallNachrichten(nachrichten) {
  return unmarshallArray(nachrichten).map(unmarshallNachricht);
}

export function unmarshallNachricht(nachricht) {
  if (!nachricht || nachricht instanceof Nachricht) {
    return nachricht;
  }
  const nachrichtdaten = {
    ...nachricht,
  };
  if (nachricht.angebot) {
    nachrichtdaten.angebot = unmarshallAngebot(nachricht.angebot);
  }
  delete nachrichtdaten.__typename;
  if (nachrichtdaten.anhang) {
    delete nachrichtdaten.anhang.__typename;
    if (nachrichtdaten.anhang.datei) {
      delete nachrichtdaten.anhang.datei.__typename;
    }
  }
  return new Nachricht(nachrichtdaten);
}

export function unmarshallAuftraege(auftraege) {
  return unmarshallArray(auftraege).map(unmarshallAuftrag);
}

export function unmarshallAuftrag(auftrag) {
  if (!auftrag || auftrag instanceof Auftrag) {
    return auftrag;
  }
  const auftragsdaten = { ...auftrag };
  delete auftragsdaten.owner;
  delete auftragsdaten.__typename;

  return new Auftrag({
    ...auftragsdaten,
    anhaenge: unmarshallAnhaenge(auftragsdaten.anhaenge),
    spezifikation: jsonParseWhileString(auftragsdaten.spezifikation),
  });
}

function unmarshallAnhaenge(anhaenge) {
  return unmarshallArray(anhaenge).map(unmarshallAnhang);
}

function unmarshallAnhang(anhang) {
  if (!anhang) {
    return anhang;
  }
  const anhangdaten = { ...anhang, datei: { ...anhang.datei } };
  delete anhangdaten.__typename;
  delete anhangdaten.datei.__typename;
  return anhangdaten;
}

export function unmarshallEvents(events) {
  return unmarshallArray(events).map(unmarshallEvent);
}

export function unmarshallEvent(event) {
  if (!event) {
    return event;
  }
  const eventdaten = { ...event };
  delete eventdaten.__typename;
  return eventdaten;
}

export function unmarshallKunde(kunde) {
  if (!kunde) {
    return kunde;
  }
  const kundendaten = { ...kunde };
  delete kundendaten.__typename;
  delete kundendaten.owner;
  return kundendaten;
}

export function unmarshallHandwerkerliste(handwerkerliste) {
  return unmarshallArray(handwerkerliste).map(unmarshallHandwerker);
}

export function unmarshallHandwerker(handwerker) {
  if (!handwerker) {
    return handwerker;
  }
  const handwerkerdaten = { ...handwerker };
  delete handwerkerdaten.__typename;
  handwerkerdaten.agb && delete handwerkerdaten.agb.__typename;
  handwerkerdaten.datenschutzbestimmungen &&
    delete handwerkerdaten.datenschutzbestimmungen.__typename;
  handwerkerdaten.firmenlogo && delete handwerkerdaten.firmenlogo.__typename;
  return handwerkerdaten;
}
