import React from 'react';
import './KeineAnfragenGefundenPlatzhalter.scss';

export default function KeineAnfragenGefundenPlatzhalter({ title, subtext }) {
  return (
    <div className="keineAnfragenGefundenPlatzhalter">
      <h1 className="keineAnfragenGefundenPlatzhalter__ueberschrift">
        {title}
      </h1>
      <p className="keineAnfragenGefundenPlatzhalter__informationen">
        {subtext}
      </p>
    </div>
  );
}
