import { useHandwerkerverzeichnis } from '../../DomainContext';
import React, { useEffect, useState } from 'react';
import { ermittleGeokoordinaten, hatLatLong } from '../../geocode';
import { gtmEvent } from '../../googleAnalytics';
import { gewerkZuLangform, istMultigewerk } from '../../domain/gewerk';
import useBewertungenZuHandwerkerliste from '../../hooks/useBewertungenZuHandwerkerliste';
import Ladeindikator from './Ladeindikator';
import { useModalState } from '../../hooks';
import { isBetweenDays, toNumberOfYears } from '../../dateTime';
import classNames from 'classnames';
import Bewertungszusammenfassung from '../Bewertungen/Handwerkerbewertung/Bewertungszusammenfassung';
import { numberFormat } from '../../i18n';
import Link from '../Link/Link';
import ProfilModal from '../ProfilModal/ProfilModal';
import moment from 'moment';

const randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const randomMs = () => randomInt(1000, 3000);

export function Handwerkerauswahl({
  spezifikation,
  ausgewaehlteHandwerker,
  waehleHandwerkerAus,
  setGefundeneHandwerker,
  setLadetext,
  ladetext,
}) {
  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const [handwerkerliste, setHandwerkerliste] = useState(null);
  const [
    geokoordinatenAuftragsadresse,
    setGeokoordinatenAuftragsadresse,
  ] = useState({});

  useEffect(() => {
    let fakeTimerElapsed = false;
    let didCancel = false;
    let gefundeneHandwerker;

    async function _ermittleGeokoordinaten() {
      const geokoordinaten = await ermittleGeokoordinaten({
        strasse: spezifikation['Straße und Hausnummer'],
        plz: spezifikation['PLZ'],
        ort: spezifikation['Ort'],
      });

      if (!didCancel && hatLatLong(geokoordinaten)) {
        setGeokoordinatenAuftragsadresse(geokoordinaten);
      }
    }

    async function findeHandwerker() {
      if (!didCancel) {
        setLadetext('Passende Fachpartner werden gesucht. Bitte warten...');
      }
      gefundeneHandwerker = await handwerkerverzeichnis.findeHandwerker(
        spezifikation
      );

      if (!didCancel) {
        const nichtAbwesendeHandwerker = gefundeneHandwerker.filter(abwesend);
        setHandwerkerliste(nichtAbwesendeHandwerker);
        setGefundeneHandwerker(nichtAbwesendeHandwerker);
        if (fakeTimerElapsed) {
          setLadetext(null);
        }
        gtmEvent({
          category: 'assistant',
          action: 'craftsmanSuggestions',
          value: nichtAbwesendeHandwerker.length,
        });
      }
    }

    if (istMultigewerk(spezifikation)) {
      setGefundeneHandwerker([]);
      setHandwerkerliste([]);
    } else {
      findeHandwerker();
      _ermittleGeokoordinaten();
    }

    const handle = setTimeout(() => {
      fakeTimerElapsed = true;
      if (!didCancel && gefundeneHandwerker !== null) {
        setLadetext(null);
      }
    }, randomMs());

    return () => {
      didCancel = true;
      clearTimeout(handle);
    };
  }, [
    handwerkerverzeichnis,
    setGefundeneHandwerker,
    setLadetext,
    spezifikation,
  ]);

  const { bewertungenZuHandwerker } = useBewertungenZuHandwerkerliste(
    handwerkerliste
  );

  return ladetext !== null ? (
    <Ladeindikator text={ladetext} />
  ) : handwerkerliste && handwerkerliste.length > 0 ? (
    <>
      <div className="spezifikationsassistent__schritthinweis">
        Die folgenden, geprüften Fachpartner können wir empfehlen und befinden
        sich in Ihrer Nähe. Wählen Sie aus, an welche Betriebe Sie Ihre
        Auftragsanfrage senden möchten (max. 3).
      </div>
      {ausgewaehlteHandwerker.length === 3 && (
        <div className="spezifikationsassistent__benachrichtigung">
          Sie haben die maximale Anzahl (3) an Betrieben ausgewählt.
        </div>
      )}
      <section className="spezifikationsassistent__handwerkerauswahl">
        {handwerkerliste.map((handwerker) => {
          const istAusgewaehlt = ausgewaehlteHandwerker.some(
            (hw) => hw.id === handwerker.id
          );
          const istAuswaehlbar =
            istAusgewaehlt || ausgewaehlteHandwerker.length < 3;

          return (
            <Handwerker
              key={handwerker.id}
              handwerker={handwerker}
              istAusgewaehlt={istAusgewaehlt}
              waehleHandwerkerAus={waehleHandwerkerAus}
              istAuswaehlbar={istAuswaehlbar}
              naechsterStandort={handwerkerverzeichnis.naechsterStandort(
                geokoordinatenAuftragsadresse,
                handwerker
              )}
              bewertungen={bewertungenZuHandwerker?.get(handwerker.id) || []}
            />
          );
        })}
      </section>
    </>
  ) : (
    <div
      className="spezifikationsassistent__handwerkerauswahl--keineTreffer"
      data-cy-id="keineTrefferHinweis"
    >
      {istMultigewerk(spezifikation) ? (
        <p>
          Leider können wir Ihnen für Aufträge, für die mehrere Gewerke benötigt
          werden, noch keine passenden Fachpartner direkt online anbieten.
        </p>
      ) : (
        <p>
          Leider konnten wir für Ihre Region keinen passenden Fachpartner auf
          unserer Plattform finden.
        </p>
      )}
      <p>
        Gerne nehmen wir Ihre Anfrage aber persönlich entgegen und werden einen
        passenden Fachpartner für Sie suchen. Wir melden uns innerhalb der
        nächsten drei Werktage mit einem Vorschlag für einen Fachpartner bei
        Ihnen.
      </p>
    </div>
  );
}

function Handwerker({
  handwerker,
  istAusgewaehlt,
  istAuswaehlbar,
  waehleHandwerkerAus,
  naechsterStandort,
  bewertungen,
}) {
  const [
    isProfilModalShown,
    showProfilModal,
    hideProfilModal,
  ] = useModalState();

  const handleClick = () => {
    waehleHandwerkerAus(handwerker, !istAusgewaehlt);
  };

  const numberOfYears = toNumberOfYears(handwerker.teilnehmerSeit);
  return (
    <div
      className={classNames(
        'handwerker',
        {
          'handwerker--checked': istAusgewaehlt,
        },
        { 'handwerker--disabled': !istAuswaehlbar }
      )}
      onClick={istAuswaehlbar ? handleClick : undefined}
      data-cy-handwerker={handwerker.firmenname}
    >
      <div className="handwerker__checkbox">
        <input
          id={handwerker.id}
          type="checkbox"
          checked={istAusgewaehlt}
          disabled={!istAuswaehlbar}
          readOnly
        />
      </div>
      <div className="handwerker__identitaet">
        <label className="handwerker__name" htmlFor={handwerker.id}>
          {handwerker.firmenname}
        </label>
        <div className="handwerker__bewertung">
          <Bewertungszusammenfassung
            hwkId={handwerker.id}
            bewertungen={bewertungen}
            showProfilModal={showProfilModal}
            size="small"
          />
        </div>
        <div className="handwerker__ort">
          {naechsterStandort.name}
          {typeof naechsterStandort.entfernung === 'number' && (
            <>
              {' '}
              <span>
                ({numberFormat(naechsterStandort.entfernung)}km entfernt)
              </span>
            </>
          )}
        </div>

        <Link size="small" weight="bold" onClick={showProfilModal}>
          Profil anzeigen
        </Link>
        {isProfilModalShown && (
          <ProfilModal hide={hideProfilModal} handwerker={handwerker} />
        )}
      </div>
      <div className="handwerker__gewerke">
        {handwerker.gewerke.map((gewerk) => (
          <div key={gewerk} className="handwerker__gewerk">
            {gewerkZuLangform(gewerk)}
          </div>
        ))}
      </div>
      <div
        className={classNames('handwerker__laurel', {
          'handwerker__laurel--hidden': numberOfYears === 0,
        })}
      >
        {numberOfYears}
      </div>
      <div
        className={classNames('handwerker__teilnehmerSeit', {
          'handwerker__teilnehmerSeit--hidden': numberOfYears === 0,
        })}
      >
        {numberOfYears === 1
          ? 'Seit einem Jahr Partner der Provinzial'
          : `Seit ${numberOfYears} Jahren Partner der Provinzial`}
      </div>
    </div>
  );
}

export function abwesend(handwerker) {
  return !(
    handwerker.abwesenheit &&
    isBetweenDays(
      handwerker.abwesenheit.von,
      moment().toISOString(),
      handwerker.abwesenheit.bis
    )
  );
}
