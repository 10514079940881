import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { CheckBox } from './Input';
import Dropzone, {
  fileContainersToFiles,
  filesToFileContainers,
} from '../Dropzone/Dropzone';
import { ACCEPTED_TYPES_PDF_AND_IMAGES } from '../Dropzone/constants';
import { TextInput } from '../TextInput/TextInput';
import { Select } from '../Select/Select';
import { TextArea } from '../TextArea/TextArea';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useModalState } from '../../hooks';
import Modal from '../Modal/Modal';
import Link from '../Link/Link';
import Leistungsuebersicht from './Leistungsuebersicht';

function erzeugeEingabeLabel({ attribut, optional, maximaleLaenge }) {
  // Handwerk umbenannt zu Fachpartner
  const umbenanntesAttribut =
    attribut === 'Benötigtes Handwerk' ? 'Benötigter Fachpartner' : attribut;

  const label = maximaleLaenge
    ? `${umbenanntesAttribut} (max. ${maximaleLaenge} Zeichen)`
    : umbenanntesAttribut;
  return optional ? `${label} (optional)` : label;
}

export function Eingabengruppe({ gruppierteEingaben, gruppenIndex, children }) {
  return (
    <div
      key={`gruppe-${gruppenIndex}`}
      className={classNames('spezifikationsassistent__gruppe', {
        'spezifikationsassistent__gruppe--einspaltig':
          gruppierteEingaben.get(gruppenIndex).length === 1,
        'spezifikationsassistent__gruppe--zweispaltig':
          gruppierteEingaben.get(gruppenIndex).length > 1,
      })}
    >
      {children}
    </div>
  );
}

export function Texteingabe({ eingabe, spezifikation, waehleWert }) {
  return (
    <div className="spezifikationsassistent__eingabegruppe">
      <TextInput
        label={erzeugeEingabeLabel(eingabe)}
        value={spezifikation[eingabe.attribut] || ''}
        onChange={(event) => waehleWert(eingabe.attribut, event.target.value)}
        maxLength={eingabe.maximaleLaenge}
        hilfetext={eingabe.hilfetext}
        attribut={eingabe.attribut}
      />
    </div>
  );
}

export function Mehrfachauswahleingabe({
  key,
  eingabe,
  spezifikation,
  waehleMehrfachauswahlwert,
  waehleWert,
}) {
  const showSonstigesTextfeld =
    spezifikation[eingabe.attribut] &&
    spezifikation[eingabe.attribut].includes('Sonstige');
  return (
    <div className="spezifikationsassistent__eingabegruppe">
      <label className="spezifikationsassistent__label">
        {erzeugeEingabeLabel(eingabe)}
      </label>
      <div className="spezifikationsassistent__dynamischeMehrfachauswahl">
        {eingabe.auswahlmoeglichkeiten.map(
          (auswahlmoeglichkeit, auswahlIndex) => (
            <CheckBox
              key={`${key}}-auswahlmoeglichkeit-${auswahlIndex}`}
              spezifikation={spezifikation}
              attribut={eingabe.attribut}
              waehleMehrfachauswahlwert={waehleMehrfachauswahlwert}
              {...auswahlmoeglichkeit}
            />
          )
        )}
      </div>
      {showSonstigesTextfeld && (
        <textarea
          value={
            spezifikation[[String([eingabe.attribut]).concat('Text')]]
              ? spezifikation[[String([eingabe.attribut]).concat('Text')]]
              : ''
          }
          placeholder="Geben Sie dazu bitte Details an."
          onChange={(event) =>
            waehleWert(
              String([eingabe.attribut]).concat('Text'),
              event.target.value
            )
          }
          className="spezifikationsassistent__sonstigesText spezifikationsassistent__sonstigesText--mehrfachauswahl"
        />
      )}
    </div>
  );
}

export function Listenauswahleingabe({ eingabe, spezifikation, waehleWert }) {
  const showSonstigesTextfeld =
    spezifikation[eingabe.attribut] &&
    spezifikation[eingabe.attribut] === 'Sonstiges';
  const istBenoetigtesHandwerk =
    eingabe.attribut && eingabe.attribut === 'Benötigtes Handwerk';

  return (
    <div className="spezifikationsassistent__eingabegruppe">
      <Select
        label={erzeugeEingabeLabel(eingabe)}
        options={['Bitte auswählen'].concat(
          eingabe.auswahlmoeglichkeiten.map(
            (auswahlmoeglichkeit) => auswahlmoeglichkeit.wert
          )
        )}
        value={
          spezifikation[eingabe.attribut]
            ? spezifikation[eingabe.attribut]
            : 'Bitte auswählen'
        }
        onChange={(event) => waehleWert(eingabe.attribut, event.target.value)}
      />
      {showSonstigesTextfeld && (
        <textarea
          value={
            spezifikation[[String([eingabe.attribut]).concat('Text')]]
              ? spezifikation[[String([eingabe.attribut]).concat('Text')]]
              : ''
          }
          placeholder="Geben Sie dazu bitte Details an."
          onChange={(event) =>
            waehleWert(
              String([eingabe.attribut]).concat('Text'),
              event.target.value
            )
          }
          className="spezifikationsassistent__sonstigesText"
        />
      )}
      {istBenoetigtesHandwerk && <LeistungsuebersichtModal />}
    </div>
  );
}

function LeistungsuebersichtModal() {
  const [
    isLeistungsuebersichtModalShown,
    showLeistungsuebersichtModal,
    hideLeistungsuebersichtModal,
  ] = useModalState();

  return (
    <>
      <div className="margin:top:-0.5rem">
        <Link
          size="small"
          onClick={showLeistungsuebersichtModal}
          data-cy-id="leistungsuebersichtOeffnen"
        >
          Hier finden Sie weitere Informationen zu den Leistungen
        </Link>
      </div>
      {isLeistungsuebersichtModalShown && (
        <Modal
          title="Leistungsübersicht"
          hide={hideLeistungsuebersichtModal}
          gaTrackingName="Leistungsübersicht"
          hideActions
        >
          <div data-cy-id="leistungsuebersichtDialog">
            <p className="margin:bottom:1rem">
              Wir können Ihnen Fachpartner mit den folgenden Leistungen
              anbieten:
            </p>
            <Leistungsuebersicht />
          </div>
        </Modal>
      )}
    </>
  );
}

export function Freitexteingabe({ eingabe, spezifikation, waehleWert }) {
  return (
    <div className="spezifikationsassistent__eingabegruppe">
      <TextArea
        label={erzeugeEingabeLabel(eingabe)}
        data-cy-id={eingabe.attribut}
        value={spezifikation[eingabe.attribut] || ''}
        onChange={(event) => waehleWert(eingabe.attribut, event.target.value)}
        hilfetext={eingabe.hilfetext}
        maxLength={eingabe.maximaleLaenge}
        attribut={eingabe.attribut}
      />
    </div>
  );
}

export function Dateiuploadeingabe({ eingabe, spezifikation, waehleWert }) {
  const [fileContainers, setFileContainers] = useState(
    filesToFileContainers(spezifikation[eingabe.attribut] || [])
  );
  useEffect(() => {
    if (spezifikation[eingabe.attribut]) {
      setFileContainers(filesToFileContainers(spezifikation[eingabe.attribut]));
    }
  }, [eingabe.attribut, spezifikation]);

  const handleFileContainersChange = (fcs) => {
    setFileContainers(fcs);
    waehleWert(eingabe.attribut, fileContainersToFiles(fcs));
  };

  return (
    <div className="spezifikationsassistent__eingabegruppe">
      <label className="spezifikationsassistent__label">
        {erzeugeEingabeLabel(eingabe)}
      </label>
      <Dropzone
        fileContainers={fileContainers}
        isMultiple
        acceptedTypes={ACCEPTED_TYPES_PDF_AND_IMAGES}
        onChange={handleFileContainersChange}
      />
    </div>
  );
}

export function Zeitraum({ eingabe, spezifikation, waehleWert }) {
  const gewaehlterZeitraum = spezifikation[eingabe.attribut] || {};

  const [von, setVon] = useState(
    gewaehlterZeitraum.von ? new Date(gewaehlterZeitraum.von) : new Date()
  );
  const [bis, setBis] = useState(
    gewaehlterZeitraum.bis ? new Date(gewaehlterZeitraum.bis) : new Date()
  );

  const handleZeitraumChange = (datumVon, datumBis) => {
    if (datumVon && datumBis) {
      waehleWert(eingabe.attribut, {
        von: datumVon.toISOString(),
        bis: datumBis.toISOString(),
      });
    }
  };

  return (
    <div className="spezifikationsassistent__zeitraum">
      <div className="spezifikationsassistent__zeitraumVon">
        <label>Von:</label>
        <DatePicker
          title="Zeitraum von"
          className="textInput__input"
          selected={von}
          onChange={(value) => {
            handleZeitraumChange(value, bis);
            setVon(value);
            if (moment(bis).isBefore(moment(value))) {
              setBis(value);
            }
          }}
          dateFormat="dd.MM.yyyy"
          minDate={new Date()}
        />
      </div>
      <div className="spezifikationsassistent__zeitraumBis">
        <label>Bis:</label>
        <DatePicker
          title="Zeitraum bis"
          className="textInput__input"
          selected={bis}
          onChange={(value) => {
            handleZeitraumChange(von, value);
            setBis(value);
          }}
          dateFormat="dd.MM.yyyy"
          minDate={von}
        />
      </div>
    </div>
  );
}
