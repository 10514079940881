import React from 'react';
import logo from '../../assets/icons/00_header Logo update.jpg';

export default function StartseiteBuehne() {
  return (
    <div className="startseite__buehne">
      <div className="startseite__buehnenBox">
        <div className="startseite__buehnenBoxLayer1">
          <div className="startseite__buehnenBoxLayer2">
            <img src={logo} alt={logo} />
          </div>
        </div>
      </div>
    </div>
  );
}
