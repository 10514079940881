import React from 'react';
import Angebote from '../../../components/Angebote/Angebote';
import Chat from '../../../components/Chat/Chat';
import './Kommunikationskanal.scss';
import QuickActions from '../../../components/QuickActions/QuickActions';
import Auftragsdetails from '../../../components/Auftragsdetails/Auftragsdetails';
import KommunikationskanalHeader from '../../../components/Handwerker/KommunikationskanalHeader/KommunikationskanalHeader';
import AngeboteStoerer from '../../../components/AngeboteStoerer/AngeboteStoerer';
import { useAnfrage } from '../../../hooks/useAnfrage';

const AUTOR = 'HANDWERKER';

const Kommunikationskanal = ({ match }) => {
  const anfrageId = match.params.anfrageId;

  const { anfrage } = useAnfrage(anfrageId);
  const areQuickActionsHidden =
    !anfrage?.kannAngebotHinzugefuegtWerden() &&
    !anfrage?.kannAuftragsabnahmeGestartetWerden() &&
    !anfrage?.kannAuftragAbgerechnetWerden();

  return (
    <>
      {anfrage ? (
        <section className="kommunikationskanal kommunikationskanal--handwerker">
          <KommunikationskanalHeader anfrage={anfrage} />
          <AngeboteStoerer anfrage={anfrage} autor={AUTOR} />
          <Auftragsdetails anfrage={anfrage} />
          <Chat
            anfrage={anfrage}
            autor={AUTOR}
            headerIntro={`${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`}
            willkommenshinweis={
              <>
                Willkommen in der Unterhaltung mit&nbsp;
                {`${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`}
                . Hier können Sie Details zur Anfrage des Kunden besprechen und
                auch alle weiteren Vorgänge wie Angebotsabgaben,
                Terminabsprachen, Auftragsabnahme und Rechnungsstellung
                durchführen.
              </>
            }
            areQuickActionsHidden={areQuickActionsHidden}
            quickActions={
              <QuickActions
                rolle="HANDWERKER"
                anfrage={anfrage}
                hidden={areQuickActionsHidden}
              />
            }
          />
          <Angebote anfrage={anfrage} autor={AUTOR} />
        </section>
      ) : (
        <div className="kommunikationskanal__404">
          Entschuldigung, wir können die angeforderte Seite nicht finden.
        </div>
      )}
    </>
  );
};

export default Kommunikationskanal;
