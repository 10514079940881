import React from 'react';
import Button from '../Button/Button';
import AngebotAbgebenModal from '../../components/AngebotAbgebenModal/AngebotAbgebenModal';
import { useDialogState } from '../../hooks';
import { useAuftraege } from '../../DomainContext';
import Modal from '../Modal/Modal';
import RechnungHochladenModal from '../RechnungHochladenModal/RechnungHochladenModal';
import { gtmEvent } from '../../googleAnalytics';
import BewertungAbgeben from '../Bewertungen/Handwerkerbewertung/BewertungAbgeben';
import { useAnfrageUndEventContext } from '../../contexts/AnfrageUndEventContext';
import { EVENT_TYPE_ANFRAGE_ABGESCHLOSSEN_DURCH_KUNDENBERATER } from '../../persistence/eventDispatcherImpl';

import './QuickActions.scss';

const AuftragsabnahmeStartenDialog = ({ anfrage, hide }) => {
  const auftraege = useAuftraege();

  const handleAbbrechenClick = () => {
    hide();
  };

  const createHandleAufragsabnahmeStartenClick = (setLoading) => async () => {
    setLoading(true);
    try {
      await auftraege.starteAuftragsabnahme(anfrage);
      hide();

      gtmEvent({
        category: 'contract',
        action: 'approved',
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const actions = (setLoading) => (
    <>
      <Button onClick={handleAbbrechenClick}>Abbrechen</Button>
      <Button
        color="green"
        onClick={createHandleAufragsabnahmeStartenClick(setLoading)}
        data-cy-id="starteAuftragsabnahme"
      >
        Auftragsabnahme starten
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title="Auftragsabnahme starten"
      hide={hide}
      actions={actions}
      gaTrackingName="Auftragsabnahme starten"
    >
      <span>
        Hier geben Sie die Auftragsdurchführung zur Abnahme auf Kundenseite frei
        und bestätigen damit, dass Ihre Arbeiten vollständig erledigt sind. Nach
        erfolgreicher Auftragsabnahme auf Kundenseite können Sie die
        Auftragsrechnung auf der Plattform hochladen.
      </span>
    </Modal>
  );
};

const AuftragAbnehmenDialog = ({ anfrage, hide }) => {
  const auftraege = useAuftraege();
  const handleAbnahmeAblehnenClick = () => {
    auftraege.lehneAuftragsabnahmeAb(anfrage);

    gtmEvent({
      category: 'contract',
      action: 'inspectionDisapproved',
    });
    hide();
  };
  const createHandleAbnahmeBestaetigenClick = (setLoading) => async () => {
    setLoading(true);
    try {
      await auftraege.bestaetigeAuftragsabnahme(anfrage);

      gtmEvent({
        category: 'contract',
        action: 'inspectionApproved',
      });
      hide();
    } catch (e) {
      setLoading(false);
    }
  };

  const actions = (setLoading) => (
    <>
      <Button onClick={handleAbnahmeAblehnenClick}>Abnahme ablehnen</Button>
      <Button
        color="green"
        onClick={createHandleAbnahmeBestaetigenClick(setLoading)}
      >
        Abnahme bestätigen
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title="Auftrag abnehmen"
      hide={hide}
      actions={actions}
      gaTrackingName="Auftrag abnehmen"
    >
      <span>
        {anfrage.handwerker.firmenname} hat die Arbeiten für Ihr Auftrag
        abgeschlossen. Wenn Sie mit den Arbeiten zufrieden sind, bestätigen Sie
        bitte die Abnahme. Sollten Sie mit den Arbeiten nicht zufrieden sein,
        lehnen Sie die Abnahme ab und teilen dem Fachpartner den Grund dafür
        mit.
      </span>
    </Modal>
  );
};

const ZahlungseingangBestaetigenDialog = ({ anfrage, hide }) => {
  const auftraege = useAuftraege();
  const handleAbbrechenClick = () => {
    hide();
  };
  const createHandleZahlungseingangBestaetigenClick = (
    setLoading
  ) => async () => {
    setLoading(true);
    try {
      await auftraege.bestaetigeZahlungseingang(anfrage);
      hide();

      gtmEvent({
        category: 'contract',
        action: 'done',
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const actions = (setLoading) => (
    <>
      <Button onClick={handleAbbrechenClick}>Abbrechen</Button>
      <Button
        color="green"
        onClick={createHandleZahlungseingangBestaetigenClick(setLoading)}
        data-cy-id="zahlungseingangBestaetigenButton"
      >
        Zahlungseingang bestätigen
      </Button>
    </>
  );
  return (
    <Modal
      className="modal--dialog"
      title="Zahlungseingang bestätigen &amp; Auftrag abschließen"
      hide={hide}
      actions={actions}
      gaTrackingName="Zahlungseingang bestätigen"
    >
      <span>
        Sie haben die Rechnungssumme vom Kunden komplett erhalten? Dann
        bestätigen Sie hier bitte den Eingang der Zahlung und der Auftrag wird
        geschlossen.
      </span>
    </Modal>
  );
};

export default function QuickActions({ rolle, anfrage }) {
  const [
    isAuftragsabnahmeStartenDialogShown,
    showAuftragsabnahmeStartenDialog,
    hideAuftragsabnahmeStartenDialog,
  ] = useDialogState();
  const [
    isAngebotAbgebenModalShown,
    showAngebotAbgebenModal,
    hideAngebotAbgebenModal,
  ] = useDialogState();
  const [
    isAuftragAbnehmenModalShown,
    showAuftragAbnehmenModal,
    hideAuftragAbnehmenModal,
  ] = useDialogState();
  const [
    isRechnungHochladenModalShown,
    showRechnungHochladenModal,
    hideRechnungHochladenModal,
  ] = useDialogState();
  const [
    isZahlungseingangBestaetigenModalShown,
    showZahlungseingangBestaetigenModal,
    hideZahlungseingangBestaetigenModal,
  ] = useDialogState();

  const { events } = useAnfrageUndEventContext();
  const abgeschlossenDurchKundenberater = !!events.find(
    (event) =>
      event.anfrageId === anfrage.id &&
      event.type === EVENT_TYPE_ANFRAGE_ABGESCHLOSSEN_DURCH_KUNDENBERATER
  );

  const kannBewertetWerden =
    anfrage?.kannBewertetWerden() ||
    (abgeschlossenDurchKundenberater && anfrage?.handwerkerBewertetAm === null);

  return (
    <div className="quickActions">
      {rolle === 'HANDWERKER' ? (
        <>
          {anfrage.kannAngebotHinzugefuegtWerden() && (
            <Button
              className="margin:right:.5rem"
              color="lightGreen"
              onClick={showAngebotAbgebenModal}
              data-cy-id="quickActionAngebotAbgebenButton"
            >
              Angebot abgeben
            </Button>
          )}
          {anfrage.kannAuftragsabnahmeGestartetWerden() && (
            <Button
              className="margin:right:.5rem"
              color="lightGreen"
              onClick={showAuftragsabnahmeStartenDialog}
              data-cy-id="quickActionAuftragsAbnahmeButton"
            >
              Auftragsabnahme starten
            </Button>
          )}
          {anfrage.kannAuftragAbgerechnetWerden() && (
            <Button
              className="margin:right:.5rem"
              color="lightGreen"
              onClick={showRechnungHochladenModal}
              data-cy-id="quickActionRechnungHochladenButton"
            >
              Rechnung hochladen
            </Button>
          )}
          {anfrage.kannZahlungseingangBestaetigtWerden() && (
            <Button
              color="lightGreen"
              onClick={showZahlungseingangBestaetigenModal}
              data-cy-id="quickActionZahlungBestaetigenButton"
            >
              Zahlungseingang bestätigen
            </Button>
          )}
          {isAngebotAbgebenModalShown && (
            <AngebotAbgebenModal
              hide={hideAngebotAbgebenModal}
              anfrage={anfrage}
            />
          )}
          {isRechnungHochladenModalShown && (
            <RechnungHochladenModal
              hide={hideRechnungHochladenModal}
              anfrage={anfrage}
            />
          )}
          {isAuftragsabnahmeStartenDialogShown && (
            <AuftragsabnahmeStartenDialog
              hide={hideAuftragsabnahmeStartenDialog}
              anfrage={anfrage}
            />
          )}
          {isZahlungseingangBestaetigenModalShown && (
            <ZahlungseingangBestaetigenDialog
              hide={hideZahlungseingangBestaetigenModal}
              anfrage={anfrage}
            />
          )}
        </>
      ) : (
        <>
          {anfrage.kannAuftragAbgenommenWerden() && (
            <Button
              color="lightGreen"
              onClick={showAuftragAbnehmenModal}
              data-cy-id="quickActionAuftragAbnehmenButton"
            >
              Auftrag abnehmen
            </Button>
          )}

          {kannBewertetWerden && <BewertungAbgeben anfrage={anfrage} />}
          {isAuftragAbnehmenModalShown && (
            <AuftragAbnehmenDialog
              hide={hideAuftragAbnehmenModal}
              anfrage={anfrage}
            />
          )}
        </>
      )}
    </div>
  );
}
