import React from 'react';
import { useHistory } from 'react-router-dom';
import './Benachrichtigung.scss';
import Button from '../Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

export default function Benachrichtigung({ titel, children }) {
  const history = useHistory();
  const close = () => {
    history.replace(history.location.pathname.replace('/(.*)\\?/g', '$1'));
  };
  return (
    <section className="benachrichtigung benachrichtigung--success">
      <div className="benachrichtigung__icon benachrichtung__icon--success" />
      <div className="benachrichtigung__text">
        <div className="benachrichtigung__titelzeile">
          <div className="benachrichtigung__titel">{titel}</div>
          <div className="benachrichtigung__actions">
            <Button icon={<CloseIcon />} onClick={close} />
          </div>
        </div>
        <div
          className="benachrichtigung__beschreibung"
          data-cy-id="benachrichtigungBeschreibung"
        >
          {children}
        </div>
      </div>
    </section>
  );
}
