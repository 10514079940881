import React from 'react';
import './Status.scss';
import classNames from 'classnames';

const Status = ({ className, text, color, size, icon, ...props }) => (
  <div
    className={classNames('status', `status--${color}`, className, {
      [`status--${size}`]: size
    })}
    {...props}
  >
    {icon && <img className="status__icon" src={icon} alt="" />}
    {text}
  </div>
);

export default Status;
