import React from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

export default function Ladeindikator({ text }) {
  return (
    <div className="spezifikationsassistent__ladeindikator">
      <LoadingIndicator text={text} />
    </div>
  );
}
