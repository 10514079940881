import React from 'react';
import './RadioGroup.scss';
import classNames from 'classnames';

export function RadioGroup({
  label,
  options,
  validierungstext,
  value,
  onChange,
  vertical,
  hideLabel,
  ...props
}) {
  return (
    <>
      {hideLabel || (
        <label className="radioGroup__label" data-cy-label={label}>
          {label}
        </label>
      )}
      <div
        className={classNames('radioGroup__radioGroup', {
          'radioGroup__radioGroup--vertical': vertical,
        })}
        {...props}
      >
        {options.map((option, optionIndex) => (
          <label
            className="radioGroup__optionLabel"
            htmlFor={`${label}${option}`}
            key={`option-${optionIndex}`}
          >
            <input
              id={`${label}${option}`}
              checked={value === option}
              className="radioGroup__radio"
              type="radio"
              value={option}
              name={label}
              onChange={onChange}
              data-cy-status={option.toLowerCase()}
            />
            {option}
          </label>
        ))}
      </div>
      {validierungstext && (
        <div
          data-cy-validierungstext={validierungstext}
          className="radioGroup__validierungstext"
        >
          {validierungstext}
        </div>
      )}
    </>
  );
}
