import React from 'react';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import Link from '../Link/Link';
import { TextInput } from '../TextInput/TextInput';
import Button from '../Button/Button';
import filialIcon from '../../assets/icons/filiale.svg';
import { ReactComponent as DeleteIcon } from '../../../src/assets/icons/delete.svg';

import './Filialen.scss';

export function Filialen({ filialen = [], onChange }) {
  const deleteFiliale = (index) =>
    onChange(filialen.filter((_, i) => i !== index));

  const updateFiliale = (index, updatedFiliale) => {
    onChange(
      filialen.map((filiale, i) => (index === i ? updatedFiliale : filiale))
    );
  };

  return (
    <>
      {filialen &&
        filialen.map((filiale, index) => (
          <Filiale
            key={index}
            index={index}
            filiale={filiale}
            onChange={(updatedFiliale) => updateFiliale(index, updatedFiliale)}
            onDelete={() => deleteFiliale(index)}
          />
        ))}
      <Link
        className="filialen__neueFiliale"
        icon={<PlusIcon />}
        onClick={() =>
          onChange([
            ...filialen,
            { name: 'Neue Filiale', strasse: '', plz: '', ort: '' },
          ])
        }
      >
        Filiale hinzufügen
      </Link>
    </>
  );
}

function Filiale({ index, filiale, onChange, onDelete }) {
  function updateFiliale(aktualisierteFiliale) {
    onChange({
      ...aktualisierteFiliale,
      name: `Filiale ${aktualisierteFiliale.ort}`,
    });
  }

  return (
    <div
      className="filiale"
      data-cy-id="filiale"
      data-cy-value={<filiale className="name"></filiale>}
    >
      <div className="filiale__name">
        <h2 className="filiale__ueberschrift">{filiale.name}</h2>
        <Button
          title={`Filiale "${filiale.name}" löschen`}
          icon={<DeleteIcon />}
          color="grey"
          outlined
          onClick={onDelete}
        />
      </div>

      <div className="filiale__addresse">
        <TextInput
          required
          maxLength="50"
          size="30"
          onChange={(event) =>
            updateFiliale({ ...filiale, strasse: event.target.value })
          }
          label="Straße, Nr."
          attribut={`strasse_nr_${index}`}
          value={filiale.strasse}
        />
        <TextInput
          required
          maxLength="5"
          onChange={(event) =>
            updateFiliale({ ...filiale, plz: event.target.value })
          }
          pattern="^[0-9]{5}$"
          label="PLZ"
          attribut={`plz_${index}`}
          value={filiale.plz}
        />
        <TextInput
          required
          maxLength="50"
          size="40"
          onChange={(event) =>
            updateFiliale({ ...filiale, ort: event.target.value })
          }
          label="Ort"
          attribut={`ort_${index}`}
          value={filiale.ort}
        />
      </div>
    </div>
  );
}

export function Filialuebersicht({ filialen }) {
  return (
    <>
      <h1 className="profil__sectionHeader">
        Dieser Fachpartner hat{' '}
        {filialen.length === 1 ? 'eine Filiale' : `${filialen.length} Filialen`}
      </h1>
      <div className="filialuebersicht">
        {filialen.map((filiale, index) => {
          const filialeGoogleMapsLink = `https://www.google.de/maps/place/${encodeURIComponent(
            `${filiale.strasse}, ${filiale.plz} ${filiale.ort}`
          )}`;
          return (
            <div
              key={index}
              className="filialuebersicht__filiale"
              data-cy-id="filiale"
            >
              <div className="filialuebersicht__icon">
                <img src={filialIcon} alt="Filiale" />
              </div>

              <div>
                <p className="filialuebersicht__name">{filiale.name}</p>
                <p>
                  <a
                    className="link"
                    href={filialeGoogleMapsLink}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {filiale.strasse}, {filiale.plz} {filiale.ort}
                  </a>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
