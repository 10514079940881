import React from 'react';
import LegalContent from '../../../components/LegalContent/LegalContent';

export default function () {
  return (
    <LegalContent headline="Einwilligungserklärung zur telefonischen Kontaktaufnahme durch die Provinzial Dienstleistungsgesellschaft">
      <p className="legalContent__paragraph">
        Zur Verbesserung der Kundenfreundlichkeit ihres Vermittlungsangebotes
        analysiert und überwacht die Provinzial Dienstleistungsgesellschaft die
        Vermittlungsprozesse und deren Ergebnisse mit Blick auf die zustande
        gekommenen sowie nicht zustande gekommenen Aufträge an Fachpartner und
        sonstigen Dienstleister.
      </p>
      <p className="legalContent__paragraph">
        Ohne Einfluss auf das Auftragsverhältnis und jederzeit widerrufbar,
        erkläre ich mich damit einverstanden, dass die Provinzial
        Dienstleistungsgesellschaft mbH mich telefonisch kontaktiert, um
        initiativ konkrete Hilfestellungen bei Nutzung einzelner
        Plattformfunktionen (Vermittlungsanfragen, Auftragsvergabe etc.) zu
        geben sowie Analysen betreffend der Funktionsfähigkeit und
        Benutzerfreundlichkeit der angebotenen Plattform-Services (z.B. mittels
        Bewertungsanfragen) durchführen zu können.
      </p>
      <p className="legalContent__paragraph">
        Diese Einwilligungserklärung kann ich jederzeit ohne Angabe von Gründen
        ohne Einfluss auf die bis dahin erfolgte Datenweitergabe und -nutzung
        gegenüber der Provinzial Dienstleistungsgesellschaft mbH (per Brief an
        Provinzial Dienstleistungsgesellschaft mbH, Dorotheenstraße 3, 45130
        Essen oder per Mail an:&nbsp;
        <a href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>
        ) widerrufen.
      </p>
    </LegalContent>
  );
}
