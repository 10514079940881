import {
  ProfilVervollstaendigenDispatchContext,
  ProfilVervollstaendigenStateContext,
  useStepGtmEvent,
} from '../ProfilVervollstaendigen';
import React, { useContext, useEffect, useState } from 'react';
import {
  SelectWrapper,
  TextAreaWrapper,
  TextInputWrapper,
} from '../../../../components/ProfilModal/Components';
import {
  URL_REGEX_PATTERN,
  istDatenschutzAlsPdfOderLinkUngespeichertVorhanden,
  istImpressumAlsTextOderLinkVorhanden,
} from '../../../../components/ProfilModal/validierung';

import { ACCEPTED_TYPES_PDF_ONLY } from '../../../../components/Dropzone/constants';
import Dropzone from '../../../../components/Dropzone/Dropzone';

export function Rechtliches({ setStepValid, isValid }) {
  const {
    setAktualisierterBenutzer,
    setDatenschutzbestimmungenFileContainers,
  } = useContext(ProfilVervollstaendigenDispatchContext);
  const state = useContext(ProfilVervollstaendigenStateContext);
  const [istDatenschutzLinkValide, setDatenschutzLinkValide] = useState(true);
  const [istImpressumLinkValide, setImpressumLinkValide] = useState(true);
  const [istDatenschutzVorhanden, setDatenschutzVorhanden] = useState(true);
  const [istImpressumVorhanden, setImpressumVorhanden] = useState(true);
  const aktualisiereBenutzer = (benutzer) => {
    setStepValid(
      isValid({
        aktualisierterBenutzer: benutzer,
        datenschutzbestimmungenFileContainers:
          state.datenschutzbestimmungenFileContainers,
      })
    );
    setAktualisierterBenutzer(benutzer);
  };
  useEffect(() => {
    setDatenschutzVorhanden(
      istDatenschutzAlsPdfOderLinkUngespeichertVorhanden(
        state.aktualisierterBenutzer,
        state.datenschutzbestimmungenFileContainers
      )
    );
    setImpressumVorhanden(
      istImpressumAlsTextOderLinkVorhanden(state.aktualisierterBenutzer)
    );
    setStepValid(isValid(state));
  }, [
    state.aktualisierterBenutzer,
    state.datenschutzbestimmungenFileContainers,
    istDatenschutzVorhanden,
    istImpressumVorhanden,
    setStepValid,
    state,
    isValid,
  ]);
  useStepGtmEvent('legal');
  const validiereLink = (event) => {
    const feldname = event.target.name;
    const feldwert = event.target.value;
    const istFeldGueltig = event.target.validity.valid;
    const istFeldVorhandenUndGueltig = feldwert && istFeldGueltig;
    if (feldname === 'datenschutzbestimmungenLink') {
      setDatenschutzLinkValide(istFeldVorhandenUndGueltig);
    } else if (feldname === 'impressumLink') {
      setImpressumLinkValide(istFeldVorhandenUndGueltig);
    }
    if (
      istFeldVorhandenUndGueltig &&
      !feldwert.startsWith('http://') &&
      !feldwert.startsWith('https://')
    ) {
      aktualisiereBenutzer({
        ...state.aktualisierterBenutzer,
        [feldname]: 'http://'.concat(feldwert),
      });
    }
  };
  return (
    <>
      <h2
        className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem"
        data-cy-id="profilVervollstaendigenUeberschrift"
      >
        Datenschutzbestimmungen
      </h2>
      <p className="profilVervollstaendigen__absatz margin:bottom:1.5rem">
        Da Sie über die Plattform „MeinZuhauseUndIch“ Daten von Interessenten
        und Kunden erheben, sind Sie verpflichtet, die Informationspflichten der
        EU-DSGVO zu erfüllen. Daher können Sie hier auf die Datenschutzhinweise
        auf Ihrer Unternehmens-Webseite verlinken oder Ihre Datenschutzhinweise
        als Dokument hochladen.
      </p>
      <SelectWrapper
        id="datenschutzbestimmungenTyp"
        attribut="datenschutzbestimmungenTyp"
        data-cy-id="dateschutzbestimmungenTyp"
        options={[
          'Bitte auswählen',
          'Datenschutzbestimmungen als Link',
          'Datenschutzbestimmungen als PDF',
        ]}
        aktualisierterBenutzer={state.aktualisierterBenutzer}
        setAktualisierterBenutzer={aktualisiereBenutzer}
        validateOnBlur
        validierungstext={
          (state.aktualisierterBenutzer.datenschutzbestimmungenTyp ===
            'Bitte auswählen' ||
            !state.aktualisierterBenutzer.datenschutzbestimmungenTyp) &&
          'Datenschutzbestimmungen fehlen'
        }
      />
      {state.aktualisierterBenutzer.datenschutzbestimmungenTyp ===
        'Datenschutzbestimmungen als Link' && (
        <TextInputWrapper
          data-cy-id="datenschutzbestimmungenLink"
          attribut="datenschutzbestimmungenLink"
          aktualisierterBenutzer={state.aktualisierterBenutzer}
          setAktualisierterBenutzer={aktualisiereBenutzer}
          onBlur={validiereLink}
          pattern={URL_REGEX_PATTERN}
          validateOnBlur
          validierungstext={
            !istDatenschutzVorhanden
              ? 'Datenschutzbestimmungen fehlen'
              : !istDatenschutzLinkValide &&
                'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
          }
        />
      )}
      {state.aktualisierterBenutzer.datenschutzbestimmungenTyp ===
        'Datenschutzbestimmungen als PDF' && (
        <Dropzone
          fileContainers={state.datenschutzbestimmungenFileContainers}
          acceptedTypes={ACCEPTED_TYPES_PDF_ONLY}
          onChange={(fileContainers) => {
            setDatenschutzbestimmungenFileContainers(fileContainers);
          }}
          validierungstext={
            !istDatenschutzVorhanden && 'Datenschutzbestimmungen fehlen'
          }
        />
      )}
      {!istDatenschutzVorhanden && (
        <div className="profil__datenschutzinfo margin:bottom:1.5rem">
          <p className="profilVervollstaendigen__absatz">
            Sie haben noch keine Datenschutzbestimmungen für Ihr Unternehmen?
            Informieren Sie sich bei Ihrer Handwerkskammer, die Ihnen Muster zur
            Verfügung stellen kann (Beispiel:{' '}
            <a
              href="https://www.zdh.de/fileadmin/user_upload/themen/Recht/Datenschutz/Handwerksbetriebe/Anlage_Muster_Informationserteilung_Handwerksbetriebe.docx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Muster des Zentralverbandes des Deutschen Handwerks
            </a>
            ).
          </p>
          <p className="profilVervollstaendigen__absatz margin:bottom:.5rem">
            Brauchen Sie Hilfe? <a href="tel:02119785858">0211 978 58 58</a>{' '}
            (Mo-Fr von 8-17 Uhr)
          </p>
          <p className="profilVervollstaendigen__absatz">
            Bitte beachten Sie, dass es sich um einen Link zu einer Website
            eines Dritten handelt, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr
            übernehmen.
          </p>
        </div>
      )}
      <h2 className="profilVervollstaendigen__ueberschrift margin:bottom:.5rem margin:top:1.5rem">
        Impressum
      </h2>
      <p className="profilVervollstaendigen__absatz margin:bottom:1.5rem">
        Als Teilnehmer an der Plattform „MeinZuhauseUndIch“ treten Sie mit einem
        eigenen Anbieterprofil auf einer Plattform auf. Somit sind Sie
        verpflichtet, ein Impressum anzugeben. Dies können Sie machen, indem Sie
        den direkten Link auf das Impressum Ihrer Unternehmens-Webseite angeben
        (der Link auf die profilVervollstaendigen ist nicht ausreichend!) oder
        das Impressum ausfüllen. Welche Angaben ein Impressum enthalten muss,
        sind in{' '}
        <a
          href="https://www.gesetze-im-internet.de/tmg/__5.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          §5 Telemediengesetz
        </a>{' '}
        geregelt.
      </p>
      <SelectWrapper
        id="impressumTyp"
        attribut="impressumTyp"
        options={[
          'Bitte auswählen',
          'Impressum als Link',
          'Impressum als Text',
        ]}
        aktualisierterBenutzer={state.aktualisierterBenutzer}
        setAktualisierterBenutzer={aktualisiereBenutzer}
        validateOnBlur
        validierungstext={
          (state.aktualisierterBenutzer.impressumTyp === 'Bitte auswählen' ||
            !state.aktualisierterBenutzer.impressumTyp) &&
          'Impressum fehlt'
        }
      />
      {state.aktualisierterBenutzer.impressumTyp === 'Impressum als Link' && (
        <TextInputWrapper
          data-cy-id="impressumLink"
          attribut="impressumLink"
          aktualisierterBenutzer={state.aktualisierterBenutzer}
          setAktualisierterBenutzer={aktualisiereBenutzer}
          onBlur={validiereLink}
          pattern={URL_REGEX_PATTERN}
          validateOnBlur
          validierungstext={
            !istImpressumVorhanden
              ? 'Impressum fehlt'
              : !istImpressumLinkValide &&
                'Der eingegebene Link ist ungültig. Ein gültiger Link kann z.B. so aussehen:  “http://www.meinehomepage.de” oder “https://meinehomepage.org”.'
          }
        />
      )}
      {state.aktualisierterBenutzer.impressumTyp === 'Impressum als Text' && (
        <TextAreaWrapper
          data-cy-id="impressum"
          attribut="impressum"
          aktualisierterBenutzer={state.aktualisierterBenutzer}
          setAktualisierterBenutzer={aktualisiereBenutzer}
          validateOnBlur
          validierungstext={!istImpressumVorhanden && 'Impressum fehlt'}
        />
      )}
    </>
  );
}
