import './redirectSolar.scss';

import React, { useEffect } from 'react';

import Button from '../../components/Button/Button';
import LegalContent from '../../components/LegalContent/LegalContent';

export default function () {
  const handleRedirect = () => {
    window.location = 'https://meinzuhauseundich.de';
  };

  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <LegalContent>
      <Button
        color="green"
        onClick={() => {
          handleRedirect();
        }}
      >
        Klicken Sie hier, wenn Sie nicht automatisch weitergeleitet werden.
      </Button>
    </LegalContent>
  );
}
