import { useEffect, useRef, useState } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import { useAuftraege } from './DomainContext';
import pdfIcon from './assets/icons/pdf_file.svg';
import { modalView } from './googleAnalytics';

export function useLogOut(history, istHandwerker) {
  return () =>
    Amplify.Auth.signOut()
      .then(() => {
        return history.push(
          istHandwerker
            ? '/handwerker/anmelden?erfolgreichAbgemeldet'
            : '/anmelden?erfolgreichAbgemeldet'
        );
      })
      .catch(console.log);
}

export function useDialogState(afterHideCallback = () => {}) {
  const [isDialogShown, setDialogShown] = useState(false);
  const showDialog = () => setDialogShown(true);
  const hideDialog = () => {
    setDialogShown(false);
    afterHideCallback && afterHideCallback();
  };
  return [isDialogShown, showDialog, hideDialog];
}

export function useModalState(isShown = false) {
  const [isModalShown, setModalShown] = useState(isShown);
  const showModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setModalShown(true);
  };
  const hideModal = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setModalShown(false);
  };
  return [isModalShown, showModal, hideModal];
}

export function usePreview(fileContainer) {
  const [preview, setPreview] = useState({ isLoading: true });
  useEffect(() => {
    let didCancel = false;
    async function loadS3Url(key, fileName) {
      const storageUrl = await Storage.get(key.replace(/^public\//, ''));
      if (!didCancel) {
        setPreview({ previewUrl: storageUrl, fileName, isLoading: false });
      }
    }
    function loadFileUrl(file, fileName) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (!didCancel) {
          setPreview({
            previewUrl: event.target.result,
            fileName,
            isLoading: false,
          });
        }
      };
      reader.readAsDataURL(file);
    }

    if (!didCancel) {
      if (fileContainer.s3Object) {
        if (fileContainer.s3Object.mimeType === 'application/pdf') {
          setPreview({
            previewUrl: pdfIcon,
            fileName: fileContainer.s3Object.fileName,
            isLoading: false,
          });
        } else {
          loadS3Url(
            fileContainer.s3Object.key,
            fileContainer.s3Object.fileName
          );
        }
      } else if (fileContainer.file) {
        if (fileContainer.file.type === 'application/pdf') {
          setPreview({
            previewUrl: pdfIcon,
            fileName: fileContainer.file.name,
            isLoading: false,
          });
        } else {
          loadFileUrl(fileContainer.file, fileContainer.file.name);
        }
      }
    }
    return () => {
      didCancel = true;
    };
  }, [fileContainer.file, fileContainer.s3Object]);
  return preview;
}

export function useAnhangUrl(nachrichtOrAngebotOrRechnung) {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    let didCancel = false;
    async function loadUrl(key) {
      const storageUrl = await Storage.get(key.replace(/^public\//, ''));
      if (!didCancel) {
        setUrl(storageUrl);
      }
    }
    if (
      nachrichtOrAngebotOrRechnung &&
      nachrichtOrAngebotOrRechnung.anhang &&
      !didCancel
    ) {
      loadUrl(nachrichtOrAngebotOrRechnung.anhang.datei.key);
    }
    return () => {
      didCancel = true;
    };
  }, [nachrichtOrAngebotOrRechnung]);
  return url;
}

export function useS3ObjectUrl(s3Object) {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    let didCancel = false;
    async function loadUrl(key) {
      const storageUrl = await Storage.get(key.replace(/^public\//, ''));
      if (!didCancel) {
        setUrl(storageUrl);
      }
    }
    if (s3Object?.key && !didCancel) {
      loadUrl(s3Object.key);
    }
    return () => {
      didCancel = true;
    };
  }, [s3Object]);
  return url;
}

export function useBodyScrollPrevention() {
  // Prevents vertical scrollbars from appearing
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  });
}

export function useResetScrollPosition(...dependencies) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return containerRef;
}

export function useClickOnce(callback, dependencies) {
  const [wasButtonClicked, setButtonWasClicked] = useState(false);

  useEffect(() => {
    setButtonWasClicked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return () => {
    if (!wasButtonClicked) {
      setButtonWasClicked(true);
      callback();
    }
  };
}

export function useModalTracker(modalName) {
  useEffect(() => {
    if (modalName) {
      modalView(modalName);
    } else {
      console.warn(
        'Es wird kein GA Event für das Modal gefeuert. Bitte prüfen!'
      );
    }
  }, [modalName]);
}

export function useMarkiereAnfrageAlsGesehen(
  anfrage,
  autor,
  nachrichtenUndEvents
) {
  const auftraege = useAuftraege();
  const [gesehen, setGesehen] = useState(false);
  useEffect(() => {
    let didCancel = false;
    async function markiereAnfrageAlsGesehen() {
      await auftraege.markiereAnfrageAlsGesehen(anfrage, autor);
      if (!didCancel) {
        setGesehen(true);
      }
    }
    const timeout = setTimeout(markiereAnfrageAlsGesehen, 1000);
    return () => {
      didCancel = true;
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nachrichtenUndEvents.length]);
  return gesehen;
}

export function useKannAuftragGeloeschtWerden(anfrage, anfragen) {
  const auftraege = useAuftraege();
  const [auftragKannGeloeschtWerden, setAuftragKannGeloeschtWerden] = useState(
    false
  );

  useEffect(() => {
    let didCancel = false;
    async function kannAuftragGeloeschtWerden() {
      const kannGeloeschtWerden = await auftraege.kannAuftragGeloeschtWerden(
        anfrage.auftrag,
        anfragen
      );
      if (!didCancel) {
        setAuftragKannGeloeschtWerden(kannGeloeschtWerden);
      }
    }
    kannAuftragGeloeschtWerden();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anfrage.id, anfrage.status, auftraege, anfragen]);
  return auftragKannGeloeschtWerden;
}

export function useScroll(containerRef) {
  const targetRef = useRef(null);
  const scrollToTarget = (offset = 0) => {
    return containerRef.current.scrollTo(
      0,
      targetRef.current.offsetTop + offset
    );
  };
  return [scrollToTarget, targetRef];
}
