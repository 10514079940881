import spezifikationIcons from '../../assets/icons/spezifikation';

export const EGAL_WELCHER_SCHRITT = -1;
export const KATEGORIE_INDEX_TAETIGKEITEN = 0;
export const KATEGORIE_INDEX_AUFTRAGSDETAILS = 1;
export const KATEGORIE_INDEX_ZEITRAUM = 2;
export const KATEGORIE_INDEX_STANDORT = 3;
export const KATEGORIE_INDEX_KONTAKT = 4;
export const KATEGORIE_INDEX_HANDWERKERAUSWAHL = 5;

export const BEREICH_INNEN = 'Innen';
export const BEREICH_AUSSEN = 'Außen';

export const OBJEKTART_TREPPEN_GELAENDERARBEITEN =
  'Treppen- & Geländerarbeiten';
export const OBJEKTART_WAND_DECKENARBEITEN = 'Wand- & Deckenarbeiten';
export const OBJEKTART_FENSTER_TUERARBEITEN = 'Fenster- & Türarbeiten';
export const OBJEKTART_BODENARBEITEN = 'Bodenarbeiten';
export const OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN =
  'Elektrik- & Anschlussarbeiten';
export const OBJEKTART_HEIZUNG_SANITAER_KLIMA = 'Heizung, Sanitär, Klima';
export const OBJEKTART_SONSTIGE_INNENARBEITEN = 'Sonstige Innenarbeiten';
export const OBJEKTART_SONSTIGE_AUSSENARBEITEN = 'Sonstige Außenarbeiten';

export const OBJEKTART_HOLZARBEITEN = 'Holzarbeiten';
export const OBJEKTART_DACHARBEITEN = 'Dacharbeiten';
export const OBJEKTART_BALKON_TERRASSENARBEITEN =
  'Balkon- und Terrassenarbeiten';
export const OBJEKTART_FASSADENARBEITEN = 'Fassadenarbeiten';

export const OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE =
  'Entrümpelungen & Umzugshilfe';
export const OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST =
  'Garten, Hofpflege und Winterdienst';
export const OBJEKTART_KLEINMONTAGE = 'Kleinmontage';

// Treppen- & Geländerarbeiten
export const TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN =
  'Treppe & Geländer behandeln';
export const TAETIGKEIT_TREPPE_GELAENDER_AUS_HOLZ_EINBAUEN =
  'Treppen & Geländer aus Holz einbauen';
export const TAETIGKEIT_TREPPE_GELAENDER_OBERBOEDEN =
  'Oberböden auf Treppen & Podesten verlegen';
export const TAETIGKEIT_TREPPE_FLIESEN = 'Treppen fliesen';

// Wand- & Deckenarbeiten
export const TAETIGKEIT_WAENDE_RAEUME_FLIESEN = 'Wände & Räume fliesen';
export const TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN = 'Wände & Decken tapezieren';
export const TAETIGKEIT_WAENDE_DECKEN_STREICHEN = 'Wände & Decken streichen';
export const TAETIGKEIT_WAENDE_DECKEN_HOLZVERKLEIDUNGEN =
  'Holzverkleidungen / -vertäfelungen für Wände & Decken';
export const TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN =
  'Dekorputz für Wände & Decken';
export const TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN =
  'Innendämmung installieren';
export const TAETIGKEIT_SONSTIGE_FLIESENARBEITEN = 'Sonstige Fliesenarbeiten';
export const TAETIGKEIT_SONSTIGE_DECKENARBEITEN =
  'Sonstiges an Decken & Wänden (z.B. Decke abhängen)';

// Fenster- & Türarbeiten
export const TAETIGKEIT_FENSTER_BEHANDELN = 'Fenster behandeln';
export const TAETIGKEIT_TUEREN_BEHANDELN = 'Türen behandeln';
export const TAETIGKEIT_TUEREN_EINBAUEN = 'Holz- & Kunststofftüren einbauen';
export const TAETIGKEIT_FENSTER_EINBAUEN = 'Holz- & Kunststofffenster einbauen';
export const TAETIGKEIT_FENSTER_STREICHEN =
  'Fenster, Rollläden und Fensterläden streichen';
export const TAETIGKEIT_ROLLAEDEN_ANBRINGEN =
  'Rolläden / Fensterläden aus Holz anbringen';

// Bodenarbeiten
export const TAETIGKEIT_FLIESEN_NATURSTEIN_LEGEN =
  'Fliesen, Mosaik, Terrazzo & Naturstein legen';
export const TAETIGKEIT_ESTRICH_BEARBEITEN =
  'Estrichböden bearbeiten und ausgleichen';
export const TAETIGKEIT_TEPPICH_LINOLEUM_PVC_LEGEN =
  'Teppich, Linoleum & PVC legen';
export const TAETIGKEIT_LAMINAT_VINYL_LEGEN =
  'Laminat, Fertigparkett & Vinyl legen';
export const TAETIGKEIT_SCHIFFSBODEN_LEGEN =
  'Schiffsboden- & Stäbchenparkett verlegen';
export const TAETIGKEIT_OBERBOEDEN_AUF_TREPPEN =
  'Oberböden auf Treppen & Podesten verlegen';
export const TAETIGKEIT_HOLZBODENBELAEGE_BEARBEITEN =
  'Holzbodenbeläge bearbeiten';
export const TAETIGKEIT_EINGANGSPODEST_FLIESEN =
  'Eingangspodest & -stufen fliesen';
export const TAETIGKEIT_TREPPENAUFGANG_FLIESEN =
  'Treppen & Treppenaufgänge fliesen';

// Elektrik- & Anschlussarbeiten
export const TAETIGKEIT_BOILER_WARMWASSER =
  'Boiler, Warmwasserspeicher & Durchlauferhitzer';
export const TAETIGKEIT_ANTENNE_SCHUESSEL = 'Antenne, Schüssel und Datenkabel';
export const TAETIGKEIT_STROMLEITUNGEN =
  'Stromleitungen, Sicherungen, Schaltern und Steckdosen';
export const TAETIGKEIT_BELEUCHTUNG = 'Beleuchtung';
export const TAETIGKEIT_KLINGEL_SPRECHANLAGE =
  'Klingel-, Sprech- und Videoanlage';
export const TAETIGKEIT_ANSCHLUSS_ELEKTROHERD =
  'Anschluss Elektroherd und Ceranfeld';
export const TAETIGKEIT_SMART_HOME_EINBINDEN = 'Smart Home einbinden';
export const TAETIGKEIT_PHOTOVOLTAIK = 'Solar- und Photovoltaikanlagen';
export const TAETIGKEIT_ALARMANLAGEN = 'Alarmanlagen';
export const TAETIGKEIT_BLITZSCHUTZANLAGE = 'Blitzschutzanlage';
export const TAETIGKEIT_SONSTIGE_ELEKTRIKARBEITEN =
  'Sonstige Elektrik- & Anschlussarbeiten';

// Heizung, Sanitär & Klima
export const TAETIGKEIT_ABWASSERVORRSICHTUNGEN =
  'Abwasservorrichtungen (auch Rückstau- und Rattenklappen)';
export const TAETIGKEIT_BAD_ARMATUREN =
  'Badewanne, Dusche, WC, Waschtisch/Spüle oder Armaturen';
export const TAETIGKEIT_BAD_FLIESEN = 'Bad & Dusche fliesen';
export const TAETIGKEIT_FUGEN = 'Fugen erneuern';
export const TAETIGKEIT_BODENEINLAEUFE = 'Bodeneinläufe';
export const TAETIGKEIT_BOILER_WARMWASSER_DURCHLAUFERHITZER =
  'Boiler, Warmwasserspeicher & Durchlauferhitzer';
export const TAETIGKEIT_GASLEISTUNGEN = 'Gasleitungen (auch Gasherdanschluss)';
export const TAETIGKEIT_HAUSWASSERSTATION =
  'Hauswasserstation (Regen- und  Gebrauchwassernutzung)';
export const TAETIGKEIT_HEIZUNGSANLAGE =
  'Heizungsanlage, Heizkörper, Ventile und Zubehör';
export const TAETIGKEIT_HEIZKOERPER_STREICHEN = 'Heizkörper streichen';
export const TAETIGKEIT_FUSSBODENHEIZUNG = 'Fußbodenheizung';
export const TAETIGKEIT_KLIMAANLAGE = 'Klimaanlage';
export const TAETIGKEIT_LUEFTUNG = 'Lüftung';
export const TAETIGKEIT_WASCHMASCHINENANSCHLUSS = 'Waschmaschinenanschluss';
export const TAETIGKEIT_WASSERLEITUNGEN =
  'Wasserleitungen, Eckventile und Dämmungen von Leitungen';
export const TAETIGKEIT_WASSERPUMPE = 'Wasserpumpe';
export const TAETIGKEIT_SONSTIGE_HEIZUNG_SANITAER_KLIMA =
  'Sonstige Sanitär-, Heizungs- und Klimaarbeiten';

// Holzarbeiten
export const TAETIGKEIT_HOLZMOEBEL = 'Holzmöbel herstellen & bearbeiten';
export const TAETIGKEIT_KUECHENAUF_ABBAU = 'Küchenauf- oder -abbau';
export const TAETIGKEIT_SONSTIGE_HOLZARBEITEN = 'sonstige Holzarbeiten';

// Dacharbeiten
export const TAETIGKEIT_DACHCHECK = 'Dachcheck';
export const TEILTAETIGKEIT_DACHCHECK_PREMIUM = 'Dachcheck Premium';
export const TEILTAETIGKEIT_DACHCHECK_BASIS = 'Dachcheck Basis';
export const TAETIGKEIT_DACHRINNE = 'Dachrinne, -abläufe und Regenfallrohre';
export const TAETIGKEIT_DACH = 'Dach, Dachgaube und Dachdämmungen';
export const TAETIGKEIT_DACHFENSTER = 'Dachfenster und Lichtkuppeln';
export const TAETIGKEIT_SANITAERLUEFTER = 'Sanitärlüfter';
export const TAETIGKEIT_VORDAECHER = 'Vordächer';
export const TAETIGKEIT_GARAGENDAECHER = 'Garagendächer';
export const TAETIGKEIT_DACH_BLITZSCHUTZANLAGE = 'Blitzschutzanlage';
export const TAETIGKEIT_SCHORNSTEIN = 'Schornstein';
export const TAETIGKEIT_SONSTIGE_DACHARBEITEN = 'Sonstige Dacharbeiten';

// Balkon- und Terrassenarbeiten
export const TAETIGKEIT_BALKONE_ABDICHTEN = 'Balkone & Terrassen abdichten';
export const TAETIGKEIT_BALKONVERKLEIDUNGEN_HOLZ =
  'Balkonverkleidungen aus Holz';
export const TAETIGKEIT_BALKON_FLIESEN =
  'Fliesen, Mosaik, Terrazzo & Naturstein legen';

// Fassadenarbeiten
export const TAETIGKEIT_FASSADE_STREICHEN = 'Fassade streichen';
export const TAETIGKEIT_FASSADE_VERPUTZEN = 'Fassade verputzen';
export const TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN =
  'Wärmedämmverbundsystem installieren';
export const TAETIGKEIT_FASSADE_FLIESEN = 'Fassade & Sockel fliesen';
export const TAETIGKEIT_FASSADE_VERKLEIDEN =
  'Fassade mit Schindeln & Schiefer verkleiden';

export const TAETIGKEIT_KLEINREPARATUR = 'Kleinreparaturen';
export const TAETIGKEIT_RASENMAEHEN = 'Rasenmähen';
export const TAETIGKEIT_REINIGUNG_HOFFLAECHE = 'Reinigung von Hofflächen';
export const TAETIGKEIT_BESEITIGUNG_GARTENABFAELLE =
  'Beseitigung von Gartenabfällen';
export const TAETIGKEIT_SCHNITTARBEITEN_HECKE_BUESCHE =
  'Schnittarbeiten an Hecken & Büschen';
export const TAETIGKEIT_AUFBAU_GARTENHAUS = 'Aufbau von Gartenhäuser';
export const TAETIGKEIT_WINTERDIENST = 'Winterdienst';

export const DACHART_SATTELDACH = 'Satteldach';
export const DACHART_FLACHDACH = 'Flachdach';
export const DACHART_PULTDACH = 'Pultdach';
export const DACHART_WALMDACH = 'Walmdach';
export const DACHART_MANSARDENDACH = 'Mansardendach';
export const DACHART_SONSTIGES = 'Sonstiges';

export const SPEZIFIKATIONS_ZUSTAND_KEY = 'spezifikationsAssistentState';

export const SPEZIFIKATION_INITIALE_ZUSTAENDE = {
  NEU: {
    spezifikation: {},
    kategorieIndex: 0,
    schrittIndex: 0,
  },
  AUSSEN: {
    spezifikation: {
      bereich: BEREICH_AUSSEN,
    },
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    schrittIndex: 1,
  },
  INNEN: {
    spezifikation: {
      bereich: BEREICH_INNEN,
    },
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    schrittIndex: 1,
  },
  DACHCHECK: {
    spezifikation: {
      bereich: BEREICH_AUSSEN,
      objektart: OBJEKTART_DACHARBEITEN,
      taetigkeit: TAETIGKEIT_DACHCHECK,
    },
    kategorieIndex: KATEGORIE_INDEX_TAETIGKEITEN,
    schrittIndex: 3,
  },
};

export const ICON_MAP = {
  [BEREICH_INNEN]: spezifikationIcons.innen,
  [BEREICH_AUSSEN]: spezifikationIcons.aussen,
  [TAETIGKEIT_TREPPE_GELAENDER_BEHANDELN]:
    spezifikationIcons.treppeGelaenderBehandeln,
  [TAETIGKEIT_WAENDE_RAEUME_FLIESEN]: spezifikationIcons.waendeRaeumeFliesen,
  [TAETIGKEIT_WAENDE_DECKEN_TAPEZIEREN]:
    spezifikationIcons.waendeDeckenTapezieren,
  [TAETIGKEIT_WAENDE_DECKEN_STREICHEN]:
    spezifikationIcons.waendeDeckenStreichen,
  [TAETIGKEIT_DEKORPUTZ_WAENDE_DECKEN]:
    spezifikationIcons.dekorputzWaendeDecken,
  [TAETIGKEIT_INNENDAEMMUNG_INSTALLIEREN]:
    spezifikationIcons.innendaemmungInstallieren,
  [TAETIGKEIT_SONSTIGE_FLIESENARBEITEN]:
    spezifikationIcons.sonstigeFliesenarbeiten,
  [TAETIGKEIT_FENSTER_BEHANDELN]: spezifikationIcons.fensterBehandeln,
  [TAETIGKEIT_TUEREN_BEHANDELN]: spezifikationIcons.tuerenBehandeln,
  [TAETIGKEIT_FLIESEN_NATURSTEIN_LEGEN]:
    spezifikationIcons.fliesenNatursteinLegen,
  [TAETIGKEIT_TEPPICH_LINOLEUM_PVC_LEGEN]:
    spezifikationIcons.teppichLinoleumPvcLegen,
  [TAETIGKEIT_LAMINAT_VINYL_LEGEN]: spezifikationIcons.laminatVinylLegen,
  [TAETIGKEIT_HOLZBODENBELAEGE_BEARBEITEN]:
    spezifikationIcons.holzbodenbelaegeBearbeiten,
  [TAETIGKEIT_FASSADE_STREICHEN]: spezifikationIcons.fassadeStreichen,
  [TAETIGKEIT_FASSADE_VERPUTZEN]: spezifikationIcons.fassadeVerputzen,
  [TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN]:
    spezifikationIcons.waermedaemmverbundsystemInstallieren,
  [TAETIGKEIT_DACHCHECK]: spezifikationIcons.dachcheck,
  [TAETIGKEIT_SONSTIGE_DACHARBEITEN]: spezifikationIcons.dacharbeiten,
  [OBJEKTART_TREPPEN_GELAENDERARBEITEN]:
    spezifikationIcons.treppenGelaenderarbeiten,
  [OBJEKTART_WAND_DECKENARBEITEN]: spezifikationIcons.wandDeckenarbeiten,
  [OBJEKTART_FENSTER_TUERARBEITEN]: spezifikationIcons.fensterTuerarbeiten,
  [OBJEKTART_BODENARBEITEN]: spezifikationIcons.bodenarbeiten,
  [OBJEKTART_ELEKTRIK_ANSCHLUSSARBEITEN]:
    spezifikationIcons.elektrikAnschlussarbeiten,
  [OBJEKTART_HEIZUNG_SANITAER_KLIMA]:
    spezifikationIcons.heizungSanitaerKlimaarbeiten,
  [OBJEKTART_HOLZARBEITEN]: spezifikationIcons.holzarbeiten,
  [OBJEKTART_SONSTIGE_INNENARBEITEN]: spezifikationIcons.sonstigeArbeiten,
  [OBJEKTART_DACHARBEITEN]: spezifikationIcons.dacharbeiten,
  [OBJEKTART_FASSADENARBEITEN]: spezifikationIcons.fassadenarbeiten,
  [OBJEKTART_HOLZARBEITEN]: spezifikationIcons.holzarbeiten,
  [OBJEKTART_SONSTIGE_AUSSENARBEITEN]: spezifikationIcons.sonstigeArbeiten,
  [OBJEKTART_ENTRUEMPELUNGEN_UMZUGSHILFE]:
    spezifikationIcons.entruempelungUmzugshilfe,
  [OBJEKTART_GARTEN_HOFPLFEGE_WINTERDIENST]:
    spezifikationIcons.gartenHofpflegeWinterdienst,
  [OBJEKTART_KLEINMONTAGE]: spezifikationIcons.kleinmontage,
  [DACHART_SATTELDACH]: spezifikationIcons.satteldach,
  [DACHART_FLACHDACH]: spezifikationIcons.flachdach,
  [DACHART_PULTDACH]: spezifikationIcons.pultdach,
  [DACHART_WALMDACH]: spezifikationIcons.walmdach,
  [DACHART_MANSARDENDACH]: spezifikationIcons.mansardendach,
  [DACHART_SONSTIGES]: spezifikationIcons.sonstigeArbeiten,
  [TAETIGKEIT_WAERMEVERBUNDSYSTEM_INSTALLIEREN]:
    spezifikationIcons.waermeverbundsystemInstallieren,
  [TAETIGKEIT_FASSADE_FLIESEN]: spezifikationIcons.fassadeFliesen,
  [TAETIGKEIT_FASSADE_VERKLEIDEN]: spezifikationIcons.fassadeVerkleiden,
  [TAETIGKEIT_BALKONE_ABDICHTEN]: spezifikationIcons.balkoneAbdichten,
  [TAETIGKEIT_BALKONVERKLEIDUNGEN_HOLZ]:
    spezifikationIcons.balkonverkleidungenHolz,
  [TAETIGKEIT_BALKON_FLIESEN]: spezifikationIcons.balkonFliesen,
  [TAETIGKEIT_DACHRINNE]: spezifikationIcons.dachrinne,
  [TAETIGKEIT_DACH]: spezifikationIcons.dach,
  [TAETIGKEIT_DACHFENSTER]: spezifikationIcons.dachfenster,
  [TAETIGKEIT_SANITAERLUEFTER]: spezifikationIcons.sanitaerluefter,
  [TAETIGKEIT_TREPPE_GELAENDER_OBERBOEDEN]:
    spezifikationIcons.oberboedenAufTreppen,
  [TAETIGKEIT_VORDAECHER]: spezifikationIcons.vordaecher,
  [TAETIGKEIT_GARAGENDAECHER]: spezifikationIcons.garagendaecher,
  [TAETIGKEIT_DACH_BLITZSCHUTZANLAGE]: spezifikationIcons.dachBlitzschutzanlage,
  [TAETIGKEIT_SCHORNSTEIN]: spezifikationIcons.schornstein,
  [TAETIGKEIT_PHOTOVOLTAIK]: spezifikationIcons.photovoltaik,
  [TAETIGKEIT_SONSTIGE_DACHARBEITEN]: spezifikationIcons.sonstigeDacharbeiten,
  [TAETIGKEIT_HOLZMOEBEL]: spezifikationIcons.holzmoebel,
  [TAETIGKEIT_KUECHENAUF_ABBAU]: spezifikationIcons.kuechenaufAbbau,
  [TAETIGKEIT_SONSTIGE_HOLZARBEITEN]: spezifikationIcons.sonstigeHolzarbeiten,
  [TAETIGKEIT_ABWASSERVORRSICHTUNGEN]:
    spezifikationIcons.abwasservorrsichtungen,
  [TAETIGKEIT_BAD_ARMATUREN]: spezifikationIcons.badArmaturen,
  [TAETIGKEIT_HEIZKOERPER_STREICHEN]: spezifikationIcons.heizkoerperStreichen,
  [TAETIGKEIT_BAD_FLIESEN]: spezifikationIcons.badFliesen,
  [TAETIGKEIT_FUGEN]: spezifikationIcons.fugen,
  [TAETIGKEIT_BODENEINLAEUFE]: spezifikationIcons.bodeneinlaeufe,
  [TAETIGKEIT_GASLEISTUNGEN]: spezifikationIcons.gasleistungen,
  [TAETIGKEIT_HAUSWASSERSTATION]: spezifikationIcons.hauswasserstation,
  [TAETIGKEIT_HEIZUNGSANLAGE]: spezifikationIcons.heizungsanlage,
  [TAETIGKEIT_FUSSBODENHEIZUNG]: spezifikationIcons.fussbodenheizung,
  [TAETIGKEIT_KLIMAANLAGE]: spezifikationIcons.klimaanlage,
  [TAETIGKEIT_LUEFTUNG]: spezifikationIcons.lueftung,
  [TAETIGKEIT_WASCHMASCHINENANSCHLUSS]:
    spezifikationIcons.waschmaschinenanschluss,
  [TAETIGKEIT_WASSERLEITUNGEN]: spezifikationIcons.wasserleitungen,
  [TAETIGKEIT_WASSERPUMPE]: spezifikationIcons.wasserpumpe,
  [TAETIGKEIT_SONSTIGE_HEIZUNG_SANITAER_KLIMA]:
    spezifikationIcons.sonstigeHeizungSanitaerKlima,
  [TAETIGKEIT_BOILER_WARMWASSER]: spezifikationIcons.boilerWarmwasser,
  [TAETIGKEIT_ANTENNE_SCHUESSEL]: spezifikationIcons.antenneSchuessel,
  [TAETIGKEIT_STROMLEITUNGEN]: spezifikationIcons.stromleitungen,
  [TAETIGKEIT_BELEUCHTUNG]: spezifikationIcons.beleuchtung,
  [TAETIGKEIT_KLINGEL_SPRECHANLAGE]: spezifikationIcons.klingelSprechanlage,
  [TAETIGKEIT_ANSCHLUSS_ELEKTROHERD]: spezifikationIcons.anschlussElektroherd,
  [TAETIGKEIT_SMART_HOME_EINBINDEN]: spezifikationIcons.smartHomeEinbinden,
  [TAETIGKEIT_ALARMANLAGEN]: spezifikationIcons.alarmanlagen,
  [TAETIGKEIT_SONSTIGE_ELEKTRIKARBEITEN]:
    spezifikationIcons.sonstigeElektrikarbeiten,
  [TAETIGKEIT_BLITZSCHUTZANLAGE]: spezifikationIcons.blitzschutzanlage,
  [TAETIGKEIT_TUEREN_EINBAUEN]: spezifikationIcons.tuerenEinbauen,
  [TAETIGKEIT_FENSTER_EINBAUEN]: spezifikationIcons.fensterEinbauen,
  [TAETIGKEIT_FENSTER_STREICHEN]: spezifikationIcons.fensterStreichen,
  [TAETIGKEIT_ROLLAEDEN_ANBRINGEN]: spezifikationIcons.rollaedenAnbringen,
  [TAETIGKEIT_ESTRICH_BEARBEITEN]: spezifikationIcons.estrichBearbeiten,
  [TAETIGKEIT_SCHIFFSBODEN_LEGEN]: spezifikationIcons.schiffsbodenLegen,
  [TAETIGKEIT_TREPPE_GELAENDER_AUS_HOLZ_EINBAUEN]:
    spezifikationIcons.treppeGelaenderAusHolzEinbauen,
  [TAETIGKEIT_OBERBOEDEN_AUF_TREPPEN]:
    spezifikationIcons.treppeGelaenderOberboeden,
  [TAETIGKEIT_TREPPENAUFGANG_FLIESEN]: spezifikationIcons.treppeFliesen,
  [TAETIGKEIT_TREPPE_FLIESEN]: spezifikationIcons.treppeFliesen,
  [TAETIGKEIT_WAENDE_DECKEN_HOLZVERKLEIDUNGEN]:
    spezifikationIcons.waendeDeckenHolzverkleidungen,
  [TAETIGKEIT_SONSTIGE_DECKENARBEITEN]:
    spezifikationIcons.sonstigeDeckenarbeiten,
  [OBJEKTART_BALKON_TERRASSENARBEITEN]:
    spezifikationIcons.balkonTerrassenarbeiten,
  [TAETIGKEIT_EINGANGSPODEST_FLIESEN]: spezifikationIcons.eingangspodestFliesen,
  [TAETIGKEIT_KLEINREPARATUR]: spezifikationIcons.kleinreparaturen,
  [TAETIGKEIT_RASENMAEHEN]: spezifikationIcons.rasenmaehen,
  [TAETIGKEIT_REINIGUNG_HOFFLAECHE]: spezifikationIcons.reinigungHofflaechen,
  [TAETIGKEIT_BESEITIGUNG_GARTENABFAELLE]:
    spezifikationIcons.beseitigungGartenabfaelle,
  [TAETIGKEIT_SCHNITTARBEITEN_HECKE_BUESCHE]:
    spezifikationIcons.schnittarbeiten,
  [TAETIGKEIT_AUFBAU_GARTENHAUS]: spezifikationIcons.aufbauGartenhaeuser,
  [TAETIGKEIT_WINTERDIENST]: spezifikationIcons.winterdienst,
};
