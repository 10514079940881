import innen from './innen.svg';
import aussen from './aussen.svg';

import treppenGelaenderarbeiten from './treppen-gelaenderarbeiten.svg';
import wandDeckenarbeiten from './wand-deckenarbeiten.svg';
import fensterTuerarbeiten from './fenster-tuerarbeiten.svg';
import bodenarbeiten from './bodenarbeiten.svg';
import elektrikAnschlussarbeiten from './elektrik-anschlussarbeiten.svg';
import heizungSanitaerKlimaarbeiten from './heizung-sanitaer-klimaarbeiten.svg';
import sonstigeArbeiten from './sonstige-arbeiten.svg';

// Treppen- & Geländerarbeiten
import treppeGelaenderBehandeln from './treppe-gelaender-behandeln.svg';
import treppeGelaenderAusHolzEinbauen from './treppe-gelaender-aus-holz-einbauen.svg';
import treppeGelaenderOberboeden from './treppe-gelaender-oberboeden.svg';
import treppeFliesen from './treppe-fliesen.svg';

// Wand- & Deckenarbeiten
import waendeRaeumeFliesen from './waende-raume-fliesen.svg';
import waendeDeckenTapezieren from './waende-decken-tapezieren.svg';
import waendeDeckenStreichen from './waende-decken-streichen.svg';
import dekorputzWaendeDecken from './dekorputz-waende-decken.svg';
import innendaemmungInstallieren from './innendaemmung-installieren.svg';
import waendeDeckenHolzverkleidungen from './waende-decken-holzverkleidungen.svg';
import sonstigeFliesenarbeiten from './sonstige-fliesenarbeiten.svg';
import sonstigeDeckenarbeiten from './sonstige-deckenarbeiten.svg';

// Fenster- & Türarbeiten
import fensterBehandeln from './fenster-behandeln.svg';
import tuerenBehandeln from './tueren-behandeln.svg';
import tuerenEinbauen from './tueren-einbauen.svg';
import fensterEinbauen from './fenster-einbauen.svg';
import fensterStreichen from './fenster-streichen.svg';
import rollaedenAnbringen from './rollaeden-anbringen.svg';

// Bodenarbeiten
import fliesenNatursteinLegen from './fliesen-naturstein-legen.svg';
import teppichLinoleumPvcLegen from './teppich-linoleum-pvc-legen.svg';
import laminatVinylLegen from './laminat-vinyl-legen.svg';
import holzbodenbelaegeBearbeiten from './holzbodenbelaege-bearbeiten.svg';
import holzarbeiten from './holzarbeiten.svg';
import oberboedenAufTreppen from './oberboedenAufTreppen.svg';
import estrichBearbeiten from './estrich-bearbeiten.svg';
import schiffsbodenLegen from './schiffsboden-legen.svg';
import eingangspodestFliesen from './eingangspodest-fliesen.svg';

// Elektrik- & Anschlussarbeiten
import boilerWarmwasser from './boiler-warmwasser.svg';
import antenneSchuessel from './antenne-schuessel.svg';
import stromleitungen from './stromleitungen.svg';
import beleuchtung from './beleuchtung.svg';
import klingelSprechanlage from './klingel-sprechanlage.svg';
import anschlussElektroherd from './anschluss-elektroherd.svg';
import smartHomeEinbinden from './smart-home-einbinden.svg';
import photovoltaik from './photovoltaik.svg';
import alarmanlagen from './alarmanlagen.svg';
import sonstigeElektrikarbeiten from './sonstige-elektrikarbeiten.svg';
import blitzschutzanlage from './blitzschutzanlage.svg';

// Heizung, Sanitär & Klima
import abwasservorrsichtungen from './abwasservorrsichtungen.svg';
import badArmaturen from './bad-armaturen.svg';
import badFliesen from './bad-fliesen.svg';
import fugen from './fugen.svg';
import bodeneinlaeufe from './bodeneinlaeufe.svg';
import gasleistungen from './gasleistungen.svg';
import hauswasserstation from './hauswasserstation.svg';
import heizungsanlage from './heizungsanlage.svg';
import heizkoerperStreichen from './heizkoerper-streichen.svg';
import fussbodenheizung from './fussbodenheizung.svg';
import klimaanlage from './klimaanlage.svg';
import lueftung from './lueftung.svg';
import waschmaschinenanschluss from './waschmaschinenanschluss.svg';
import wasserleitungen from './wasserleitungen.svg';
import wasserpumpe from './wasserpumpe.svg';
import sonstigeHeizungSanitaerKlima from './sonstige-heizung-sanitaer-klima.svg';

// Holzarbeiten
import holzmoebel from './holzmoebel.svg';
import kuechenaufAbbau from './kuechenauf-abbau.svg';
import sonstigeHolzarbeiten from './sonstige-holzarbeiten.svg';

// Dacharbeiten
import dacharbeiten from './dacharbeiten.svg';
import fassadenarbeiten from './fassadenarbeiten.svg';
import dachrinne from './dachrinne.svg';
import dach from './dach.svg';
import dachfenster from './dachfenster.svg';
import sanitaerluefter from './sanitaerluefter.svg';
import vordaecher from './vordaecher.svg';
import garagendaecher from './garagendaecher.svg';
import schornstein from './schornstein.svg';
import photovoltaikDach from './photovoltaik-dach.svg';
import sonstigeDacharbeiten from './sonstige-dacharbeiten.svg';

// Balkon- und Terrassenarbeiten
import balkonTerrassenarbeiten from './balkon-terrassenarbeiten.svg';
import balkoneAbdichten from './balkone-abdichten.svg';
import balkonverkleidungenHolz from './balkonverkleidungen-holz.svg';
import balkonFliesen from './balkon-fliesen.svg';

// Fassadenarbeiten
import fassadeStreichen from './fassade-streichen.svg';
import fassadeVerputzen from './fassade-verputzen.svg';
import waermeverbundsystemInstallieren from './waermeverbundsystem-installieren.svg';
import fassadeFliesen from './fassade-fliesen.svg';
import fassadeVerkleiden from './fassade-verkleiden.svg';

import dachcheck from './dachcheck.svg';

import satteldach from './dachcheck/dachart-satteldach.svg';
import flachdach from './dachcheck/dachart-flachdach.svg';
import pultdach from './dachcheck/dachart-pultdach.svg';
import mansardendach from './dachcheck/dachart-mansardendach.svg';
import walmdach from './dachcheck/dachart-walmdach.svg';

import aufbauGartenhaeuser from './aufbau-von-gartenhaeusern.svg';
import beseitigungGartenabfaelle from './beseitigung-von-gartenabfaellen.svg';
import rasenmaehen from './rasenmaehen.svg';
import reinigungHofflaechen from './reinigung-von-hofflaechen.svg';
import schnittarbeiten from './schnittarbeiten.svg';
import winterdienst from './winterdienst.svg';
import kleinmontage from './kleinmontage.svg';
import kleinreparaturen from './kleinreparaturen.svg';

export default {
  innen,
  aussen,
  treppenGelaenderarbeiten,
  wandDeckenarbeiten,
  fensterTuerarbeiten,
  bodenarbeiten,
  elektrikAnschlussarbeiten,
  heizungSanitaerKlimaarbeiten,
  sonstigeArbeiten,
  waendeRaeumeFliesen,
  waendeDeckenTapezieren,
  waendeDeckenStreichen,
  dekorputzWaendeDecken,
  innendaemmungInstallieren,
  sonstigeFliesenarbeiten,
  treppeGelaenderBehandeln,
  fensterBehandeln,
  tuerenBehandeln,
  fliesenNatursteinLegen,
  teppichLinoleumPvcLegen,
  laminatVinylLegen,
  holzbodenbelaegeBearbeiten,
  holzarbeiten,
  dacharbeiten,
  fassadenarbeiten,
  fassadeStreichen,
  fassadeVerputzen,
  dachcheck,
  satteldach,
  flachdach,
  pultdach,
  mansardendach,
  walmdach,
  waermeverbundsystemInstallieren,
  fassadeFliesen,
  fassadeVerkleiden,
  balkoneAbdichten,
  balkonverkleidungenHolz,
  balkonFliesen,
  dachrinne,
  dach,
  dachfenster,
  sanitaerluefter,
  oberboedenAufTreppen,
  vordaecher,
  garagendaecher,
  schornstein,
  photovoltaik,
  sonstigeDacharbeiten,
  holzmoebel,
  kuechenaufAbbau,
  sonstigeHolzarbeiten,
  abwasservorrsichtungen,
  badArmaturen,
  heizkoerperStreichen,
  badFliesen,
  fugen,
  bodeneinlaeufe,
  gasleistungen,
  hauswasserstation,
  heizungsanlage,
  fussbodenheizung,
  klimaanlage,
  lueftung,
  waschmaschinenanschluss,
  wasserleitungen,
  wasserpumpe,
  sonstigeHeizungSanitaerKlima,
  boilerWarmwasser,
  antenneSchuessel,
  stromleitungen,
  beleuchtung,
  klingelSprechanlage,
  anschlussElektroherd,
  smartHomeEinbinden,
  alarmanlagen,
  sonstigeElektrikarbeiten,
  blitzschutzanlage,
  tuerenEinbauen,
  fensterEinbauen,
  fensterStreichen,
  rollaedenAnbringen,
  estrichBearbeiten,
  schiffsbodenLegen,
  treppeGelaenderAusHolzEinbauen,
  treppeGelaenderOberboeden,
  treppeFliesen,
  waendeDeckenHolzverkleidungen,
  balkonTerrassenarbeiten,
  photovoltaikDach,
  sonstigeDeckenarbeiten,
  eingangspodestFliesen,
  aufbauGartenhaeuser,
  beseitigungGartenabfaelle,
  rasenmaehen,
  reinigungHofflaechen,
  schnittarbeiten,
  winterdienst,
  kleinreparaturen,
  kleinmontage,
  gartenHofpflegeWinterdienst: winterdienst,
  entruempelungUmzugshilfe: sonstigeArbeiten,
};
