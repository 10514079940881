import React from 'react';
import classNames from 'classnames';
import { toTimeString } from '../../dateTime';
import { useAnfrageUndEventContext } from '../../contexts/AnfrageUndEventContext';
import { useAuftraege } from '../../DomainContext';
import { Auftragsstatus } from '../Auftragsstatus/Auftragsstatus';

import './Chatrooms.scss';

const Chatroom = ({ anfrage, active, onClick }) => {
  const auftraege = useAuftraege();

  return (
    <div
      className={classNames('chatroom', {
        'chatroom--active': active,
      })}
      onClick={() => onClick(anfrage)}
      data-cy-handwerker={anfrage.handwerker.firmenname}
      data-cy-status={anfrage.statusAlsText()}
    >
      <div className="chatroom__header">
        Fachpartner&nbsp;
        {anfrage.handwerker ? anfrage.handwerker.firmenname : 'HANDWERKER NAME'}
        <span className="chatroom__zeit">
          {toTimeString(anfrage.updatedAt)}
        </span>
      </div>
      <div className="chatroom__ort">{anfrage.handwerker.ort}</div>
      <div className="chatroom__anfrageStatus">
        <Auftragsstatus
          fortschritt={auftraege.auftragsstatus([anfrage])}
          gtmLabel="chatCustomer"
        />
      </div>
    </div>
  );
};

const Chatrooms = ({ anfrage, onClickChatroom }) => {
  const byFirmenname = (anfrage1, anfrage2) => {
    return anfrage1.handwerker.firmenname.localeCompare(
      anfrage2.handwerker.firmenname
    );
  };

  const { anfragen } = useAnfrageUndEventContext();

  const anfragenZuAuftrag = anfragen.filter(
    (a) => a.anfrageAuftragId === anfrage?.auftrag.id
  );

  return (
    <section className="chatrooms">
      {anfragenZuAuftrag && anfragenZuAuftrag.length !== 0 ? (
        anfragenZuAuftrag
          .sort(byFirmenname)
          .map((a, key) => (
            <Chatroom
              key={key}
              anfrage={a}
              active={anfrage.id === a.id}
              onClick={onClickChatroom}
            />
          ))
      ) : (
        <p>Keine Anfragen gefunden.</p>
      )}
    </section>
  );
};

export default Chatrooms;
