import React from 'react';
import classNames from 'classnames';

import Button from '../Button/Button';

import { KATEGORIE_INDEX_HANDWERKERAUSWAHL } from '../../domain/schritte/konstanten';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const MAX_KATEGORIE_INDEX = KATEGORIE_INDEX_HANDWERKERAUSWAHL;

export const SpezifikationsassistentHeader = ({
  kategorieIndex,
  springeZuKategorieIndex,
  onClose,
}) => {
  return (
    <div className="spezifikationsassistentHeader">
      <div className="spezifikationsassistentHeader__titel">
        <h1 className="spezifikationsassistentHeader__titelueberschrift">
          Neuen Auftrag erstellen
        </h1>
        <Button
          icon={<CloseIcon />}
          className="spezifikationsassistentHeader__close"
          color="grey"
          outlined
          onClick={onClose}
        />
      </div>
      <div className="spezifikationsassistentHeader__kategorien">
        <Kategorie
          name="Tätigkeiten"
          beschreibung="Was soll gemacht werden?"
          kategorieIndex={0}
          aktuellerKategorieIndex={kategorieIndex}
          springeZuKategorieIndex={springeZuKategorieIndex}
        />
        <Kategorie
          name="Auftragsdetails"
          beschreibung="Wie ist der Zustand &amp; Umfang?"
          kategorieIndex={1}
          aktuellerKategorieIndex={kategorieIndex}
          springeZuKategorieIndex={springeZuKategorieIndex}
        />
        <Kategorie
          name="Zeitraum"
          beschreibung="Wann soll es gemacht werden?"
          kategorieIndex={2}
          aktuellerKategorieIndex={kategorieIndex}
          springeZuKategorieIndex={springeZuKategorieIndex}
        />
        <Kategorie
          name="Standort"
          beschreibung="Wo soll es gemacht werden?"
          kategorieIndex={3}
          aktuellerKategorieIndex={kategorieIndex}
          springeZuKategorieIndex={springeZuKategorieIndex}
        />
        <Kategorie
          name="Kontakt"
          beschreibung="Wie bin ich zu erreichen?"
          kategorieIndex={4}
          aktuellerKategorieIndex={kategorieIndex}
          springeZuKategorieIndex={springeZuKategorieIndex}
        />
        <Kategorie
          name="Handwerkerauswahl"
          beschreibung="Wer könnte es machen?"
          kategorieIndex={5}
          aktuellerKategorieIndex={kategorieIndex}
          springeZuKategorieIndex={springeZuKategorieIndex}
        />
        <div
          className={classNames(
            'spezifikationsassistentHeader__mobileProgress',
            {
              'spezifikationsassistentHeader__mobileProgress--kategorie0':
                kategorieIndex === 0,
              'spezifikationsassistentHeader__mobileProgress--kategorie1':
                kategorieIndex === 1,
              'spezifikationsassistentHeader__mobileProgress--kategorie2':
                kategorieIndex === 2,
              'spezifikationsassistentHeader__mobileProgress--kategorie3':
                kategorieIndex === 3,
              'spezifikationsassistentHeader__mobileProgress--kategorie4':
                kategorieIndex === 4,
              'spezifikationsassistentHeader__mobileProgress--kategorie5':
                kategorieIndex === 5,
            }
          )}
        />
      </div>
    </div>
  );
};

function Kategorie({
  name,
  beschreibung,
  aktuellerKategorieIndex,
  kategorieIndex,
  springeZuKategorieIndex,
}) {
  const isComplete = aktuellerKategorieIndex > kategorieIndex;
  const isCurrent = aktuellerKategorieIndex === kategorieIndex;
  const isNextComplete = aktuellerKategorieIndex > kategorieIndex + 1;
  const isNextCurrent =
    aktuellerKategorieIndex === kategorieIndex + 1 ||
    MAX_KATEGORIE_INDEX === aktuellerKategorieIndex;
  const handleKategorieClick = () => {
    if (aktuellerKategorieIndex > kategorieIndex) {
      springeZuKategorieIndex(kategorieIndex);
    }
  };
  return (
    <>
      <div
        className={classNames('spezifikationsassistentHeader__kategorie', {
          'spezifikationsassistentHeader__kategorie--current': isCurrent,
          'spezifikationsassistentHeader__kategorie--complete': isComplete,
        })}
        onClick={handleKategorieClick}
      >
        <div className="spezifikationsassistentHeader__kategoriename">
          {name}
        </div>
        <div className="spezifikationsassistentHeader__kategoriebeschreibung">
          {beschreibung}
        </div>
      </div>
      <div
        className={classNames('spezifikationsassistentHeader__spacer', {
          'spezifikationsassistentHeader__spacer--current': isCurrent,
          'spezifikationsassistentHeader__spacer--complete': isComplete,
          'spezifikationsassistentHeader__spacer--last':
            MAX_KATEGORIE_INDEX === kategorieIndex,
        })}
      >
        <div
          className={classNames(
            'spezifikationsassistentHeader__spacerLeftBorder',
            {
              'spezifikationsassistentHeader__spacerLeftBorder--current': isCurrent,
              'spezifikationsassistentHeader__spacerLeftBorder--complete': isComplete,
            }
          )}
        />
        <div
          className={classNames(
            'spezifikationsassistentHeader__spacerRightBorder',
            {
              'spezifikationsassistentHeader__spacerRightBorder--current': isNextCurrent,
              'spezifikationsassistentHeader__spacerRightBorder--complete': isNextComplete,
            }
          )}
        />
      </div>
    </>
  );
}
