import React from 'react';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { useAnhangUrl } from '../../hooks';
import { gtmEvent } from '../../googleAnalytics';
import { useBenutzerContext } from '../../contexts/BenutzerContext';
import { zuUmsetzungdetails } from '../../domain/auftraege';
import Ausfuehrungszeitraum from '../Ausfuerungszeitraum/Ausfuehrungszeitraum';
import ConciergeService, {
  CONCIERGE_SERVICE_CONTEXT,
} from '../ConciergeService/ConciergeService';

import pdfIcon from '../../assets/icons/pdf_file.svg';

import './AnfragedetailsModal.scss';

export default function AnfragedetailsModal({
  hide,
  anfrage,
  auftrag,
  onAnnahme,
  onAblehnung,
}) {
  const { istHandwerker } = useBenutzerContext();

  const nimmAnfrageAn = () => {
    onAnnahme();

    gtmEvent({
      category: 'request',
      action: 'accepted',
    });
  };
  const lehneAnfrageAb = (event) => {
    hide(event);
    onAblehnung(event);
  };

  const actions =
    istHandwerker && anfrage?.kannAngenommenWerden() ? (
      <>
        <Button onClick={lehneAnfrageAb} data-cy-id="anfrageAblehnenButton">
          Ablehnen
        </Button>
        <Button
          color="green"
          onClick={nimmAnfrageAn}
          data-cy-id="anfrageAnnehmenButton"
        >
          Annehmen
        </Button>
      </>
    ) : (
      <>
        {istHandwerker && (
          <Button
            className="anfragedetails__druckButton hidden@mobile"
            onClick={() => {
              gtmEvent({
                category: 'contract',
                action: 'printClicked',
              });
              const win = window.open(
                `/auftragsdruck/${anfrage?.id}`,
                '_blank'
              );
              win.focus();
            }}
          >
            Drucken
          </Button>
        )}
        <Button onClick={hide} data-cy-id="auftragsdetailsSchliessenButton">
          Schließen
        </Button>
      </>
    );
  const spezifikation = auftrag.spezifikation;

  const umsetzungsdetails = zuUmsetzungdetails(spezifikation);

  return (
    <Modal
      hide={hide}
      title={`Auftragsdetails “${auftrag.beschreibung}”`}
      subtitle={subtitle(spezifikation)}
      actions={actions}
      gaTrackingName="Auftragsdetails"
    >
      <div className="anfragedetails">
        {!istHandwerker && (
          <div className="anfragedetails__conciergeService">
            <ConciergeService
              auftrag={auftrag}
              context={CONCIERGE_SERVICE_CONTEXT.KOMMUNIKATIONSKANAL}
            />
          </div>
        )}
        <div className="anfragedetails__zeitraum">
          <div className="anfragedetails__header">Ausführungszeitraum</div>
          <div className="anfragedetails__content">
            <Ausfuehrungszeitraum spezifikation={spezifikation} />
          </div>
        </div>
        <div className="anfragedetails__auftraggeber">
          <div className="anfragedetails__header">Auftraggeber</div>
          <div className="anfragedetails__content">
            {auftrag.kunde.vorname} {auftrag.kunde.nachname}
            {spezifikation['Kontaktwunsch'] && (
              <>
                <br />
                <span>Kontaktwunsch: {spezifikation['Kontaktwunsch']}</span>
              </>
            )}
            {spezifikation['Telefonnummer'] && (
              <>
                <br />
                <span>Telefonnummer: {spezifikation['Telefonnummer']}</span>
              </>
            )}
          </div>
        </div>
        <div className="anfragedetails__standort">
          <div className="anfragedetails__header">Standort</div>
          <div className="anfragedetails__content">
            {spezifikation['Straße und Hausnummer']}
            <br />
            {spezifikation['PLZ']} {spezifikation['Ort']}
            <br />
            {spezifikation['Adresszusatz'] && (
              <>
                {spezifikation['Adresszusatz']}
                <br />
              </>
            )}
            <br />
            {spezifikation['Parkplatzsituation vor Ort']}
          </div>
        </div>
        {spezifikation.teiltaetigkeit && (
          <>
            <div className="anfragedetails__taetigkeiten">
              <div className="anfragedetails__header">
                Gewünschte Tätigkeiten
              </div>
              <div className="anfragedetails__content">
                {spezifikation.teiltaetigkeit.map((t) => (
                  <div key={t} className="anfragedetails__taetigkeit">
                    {t}
                  </div>
                ))}
              </div>
            </div>
            <div className="anfragedetails__umsetzungsdetails">
              <div className="anfragedetails__header">
                Umfang &amp; Umsetzungsdetails
              </div>
              <div className="anfragedetails__content">
                {Object.keys(umsetzungsdetails)
                  .sort()
                  .map((umsetzungsdetail) => (
                    <React.Fragment key={umsetzungsdetail}>
                      <div className="anfragedetails__attribut">
                        {umsetzungsdetail}:
                      </div>
                      <div className="anfragedetails__wert">
                        {Array.isArray(umsetzungsdetails[umsetzungsdetail])
                          ? umsetzungsdetails[umsetzungsdetail].join(', ')
                          : umsetzungsdetails[umsetzungsdetail]}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </>
        )}
        {spezifikation['Sonstige Informationen'] ? (
          <div className="anfragedetails__sonstiges">
            <div className="anfragedetails__header">Sonstige Informationen</div>
            <div className="anfragedetails__content">
              {spezifikation['Sonstige Informationen']}
            </div>
          </div>
        ) : (
          spezifikation['Auftragsbeschreibung'] && (
            <div className="anfragedetails__sonstiges">
              <div className="anfragedetails__header">Auftragsbeschreibung</div>
              <div className="anfragedetails__content">
                {spezifikation['Auftragsbeschreibung']}
              </div>
            </div>
          )
        )}
        {auftrag.anhaenge.length > 0 && (
          <div className="anfragedetails__dateien">
            <div className="anfragedetails__header">Fotos &amp; Grundrisse</div>
            <div className="anfragedetails__content">
              {auftrag.anhaenge.map((anhang) => (
                <FotoOderGrundriss key={anhang.datei.key} anhang={anhang} />
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

function FotoOderGrundriss({ anhang }) {
  const url = useAnhangUrl({ anhang });
  if (anhang.typ === 'FOTO') {
    return (
      <div className="anfragedetails__fotoContainer">
        <div className="anfragedetails__foto">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img className="anfragedetails__fotoImage" src={url} alt="" />
          </a>
        </div>
        <div className="anfragedetails__dateiname">{anhang.datei.fileName}</div>
      </div>
    );
  } else if (anhang.typ === 'PDF') {
    return (
      <div className="anfragedetails__pdfContainer">
        <div className="anfragedetails__pdf">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img className="anfragedetails__pdfImage" src={pdfIcon} alt="" />
          </a>
        </div>
        <div className="anfragedetails__dateiname">{anhang.datei.fileName}</div>
      </div>
    );
  }
  return null;
}

export const subtitle = (spezifikation) => {
  const handwerkOderTaetigkeit = spezifikation['Benötigtes Handwerk']
    ? spezifikation['Benötigtes Handwerk']
    : spezifikation.taetigkeit
    ? spezifikation.taetigkeit
    : null;

  return [
    spezifikation.bereich,
    spezifikation.objektart,
    handwerkOderTaetigkeit,
  ]
    .filter((it) => it)
    .join(' - ');
};
