export const URL_REGEX_PATTERN =
  "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";

export const EMAIL_REGEX_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function istProfilVollstaendig(benutzer) {
  return (
    istImpressumAlsTextOderLinkVorhanden(benutzer) &&
    istDatenschutzAlsPdfOderLinkVorhanden(benutzer) &&
    sindEinsatzgebieteVorhanden(benutzer) &&
    sindFirmendatenGefuellt(benutzer) &&
    sindNurValideLinksVorhanden(benutzer)
  );
}

export function sindFirmendatenGefuellt(aktualisierterBenutzer) {
  return (
    aktualisierterBenutzer.firmenname &&
    aktualisierterBenutzer.strasse &&
    aktualisierterBenutzer.plz &&
    aktualisierterBenutzer.ort &&
    aktualisierterBenutzer.email &&
    aktualisierterBenutzer.telefon
  );
}

export function sindEinsatzgebieteVorhanden(benutzer) {
  return benutzer.regionen.length > 0;
}

export function sindLeistungenVorhanden(benutzer) {
  return (
    Array.isArray(benutzer.gewaehlteLeistungen) &&
    benutzer.gewaehlteLeistungen.length > 0
  );
}

export function sindNurValideLinksVorhanden(
  benutzer,
  pruefeAuchOptionaleFelder = true
) {
  const impressumIstKeinLink = benutzer.impressumTyp !== 'Impressum als Link';
  const datenschutzbestimmungenIstKeinLink =
    benutzer.datenschutzbestimmungenTyp !== 'Datenschutzbestimmungen als Link';
  const agbIstKeinLink = benutzer.agbTyp !== 'AGB als Link';
  const webseitenLinkNichtVorhanden = !benutzer.webseite;

  const impressumLinkValide = istLinkValide(benutzer, 'impressumLink');

  const datenschutzbestimmungenLinkValide = istLinkValide(
    benutzer,
    'datenschutzbestimmungenLink'
  );

  const agbLinkValide =
    !pruefeAuchOptionaleFelder || istLinkValide(benutzer, 'agbLink');

  const webseitenLinkValide =
    !pruefeAuchOptionaleFelder || istLinkValide(benutzer, 'webseite');

  return (
    (impressumIstKeinLink || impressumLinkValide) &&
    (datenschutzbestimmungenIstKeinLink || datenschutzbestimmungenLinkValide) &&
    (agbIstKeinLink || agbLinkValide) &&
    (webseitenLinkNichtVorhanden || webseitenLinkValide)
  );
}

function istDatenschutzAlsLinkVorhanden(benutzer) {
  return (
    benutzer.datenschutzbestimmungenTyp ===
      'Datenschutzbestimmungen als Link' &&
    Boolean(benutzer.datenschutzbestimmungenLink)
  );
}

export function istDatenschutzAlsPdfOderLinkVorhanden(benutzer) {
  const hatDatenschutzAlsPdf =
    benutzer.datenschutzbestimmungenTyp === 'Datenschutzbestimmungen als PDF' &&
    Boolean(benutzer.datenschutzbestimmungen);

  return hatDatenschutzAlsPdf || istDatenschutzAlsLinkVorhanden(benutzer);
}

export function istDatenschutzAlsPdfOderLinkUngespeichertVorhanden(
  benutzer,
  datenschutzbestimmungenFileContainers
) {
  const hatUngespeicherteDatenschutzPdf =
    Array.isArray(datenschutzbestimmungenFileContainers) &&
    datenschutzbestimmungenFileContainers.filter((fc) => !fc.deleted).length >
      0;

  const hatDatenschutzAlsPdf =
    benutzer.datenschutzbestimmungenTyp === 'Datenschutzbestimmungen als PDF' &&
    hatUngespeicherteDatenschutzPdf;

  const hatDatenschutzAlsPdfOderLink =
    hatDatenschutzAlsPdf || istDatenschutzAlsLinkVorhanden(benutzer);
  return hatDatenschutzAlsPdfOderLink;
}

export function istImpressumAlsTextOderLinkVorhanden(benutzer) {
  const hatImpressumAlsText =
    benutzer.impressumTyp === 'Impressum als Text' &&
    Boolean(benutzer.impressum);
  const hatImpressumAlsLink =
    benutzer.impressumTyp === 'Impressum als Link' &&
    Boolean(benutzer.impressumLink);
  const hatImpressumAlsTextOderLink =
    hatImpressumAlsText || hatImpressumAlsLink;
  return hatImpressumAlsTextOderLink;
}

function istLinkValide(benutzer, feld) {
  return benutzer[feld] && new RegExp(URL_REGEX_PATTERN).test(benutzer[feld]);
}

export function istEmailValide(email) {
  return EMAIL_REGEX_PATTERN.test(String(email).toLowerCase());
}
