import React from 'react';
import { useHistory } from 'react-router-dom';
import { withBreakpoints } from 'react-with-breakpoints';

import classNames from 'classnames';

import { Cell, HeaderCell, Row, Table } from '../../../components/Table/Table';
import Status from '../../../components/Status/Status';
import { groupBy, mapMapEntries } from '../../../collections';
import { ANFRAGE_AUFTRAG_FOREIGN_KEY } from '../../../persistence/auftragRepository';
import { isYoungerThanDate, toDateString, toTime } from '../../../dateTime';
import Button from '../../../components/Button/Button';
import AnfragedetailsModal from '../../../components/AnfragedetailsModal/AnfragedetailsModal';
import { useKannAuftragGeloeschtWerden, useModalState } from '../../../hooks';
import AuftragLoeschenDialog from '../../../components/AuftragLoeschenDialog/AuftragLoeschenDialog';
import AuftragNichtLoeschbarDialog from '../../../components/AuftragNichtLoeschbarDialog/AuftragNichtLoeschbarDialog';
import { ICON_MAP } from '../../../domain/schritte/konstanten';

import { useAuftraege } from '../../../DomainContext';
import Benachrichtigung from '../../../components/Benachrichtigung/Benachrichtigung';

import { byUpdatedAtAsc, byUpdatedAtDesc } from '../../../sort';
import Umfrage from '../../../components/Bewertungen/Portalbewertung/Umfrage';
import AuftragWeiterleitenModal from '../../../components/AuftragWeiterleitenModal/AuftragWeiterleitenModal';
import Link from '../../../components/Link/Link';
import { useKundenauftraege } from '../../../hooks/useKundenauftraege';
import { useAnfrageUndEventContext } from '../../../contexts/AnfrageUndEventContext';
import NeuerAuftragSchaltflaeche from '../../../components/NeuerAuftragSchaltflaeche/NeuerAuftragSchaltflaeche';
import ConciergeService, {
  CONCIERGE_SERVICE_CONTEXT,
} from '../../../components/ConciergeService/ConciergeService';
import { CONCIERGE_SERVICE_GRUND } from '../../../domain/auftraege';
import { Auftragsstatus } from '../../../components/Auftragsstatus/Auftragsstatus';

import helpCenterIcon from '../../../assets/icons/help_center.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

import './MeineAuftraege.scss';

export default function MeineAuftraege() {
  const history = useHistory();
  const anfrageGesendetBenachrichtigungVisible =
    history.location.search &&
    history.location.search.includes('?anfragenGesendet');

  const auftragAnConciergeGesendetBenachrichtigungVisible =
    history.location.search &&
    history.location.search.includes('?auftragAnConciergeGesendet');

  const auftragGeloeschtBenachrichtigungVisible =
    history.location.search &&
    history.location.search.includes('?auftragGeloescht');

  const passwortZurueckgesetztBenachrichtigungVisible =
    history.location.search &&
    history.location.search.includes('?passwortZurueckgesetzt');

  const { anfragen, events } = useAnfrageUndEventContext();
  const auftragAnfragenMap = groupBy(
    anfragen,
    (anfrage) => anfrage[ANFRAGE_AUFTRAG_FOREIGN_KEY]
  );
  const { kundenauftraege } = useKundenauftraege(
    anfragen,
    auftragAnConciergeGesendetBenachrichtigungVisible
  );
  const anfrageIdAnzahlUngesehenerEventsMap = anfrageIdZuAnzahlUngesehenerEvents(
    anfragen,
    events
  );

  return (
    <div className="meineAuftraege">
      {auftragGeloeschtBenachrichtigungVisible && (
        <Benachrichtigung titel="Auftrag gelöscht">
          Der Auftrag wurde erfolgreich gelöscht.
        </Benachrichtigung>
      )}
      {passwortZurueckgesetztBenachrichtigungVisible && (
        <Benachrichtigung titel="Passwort erfolgreich geändert">
          Sie haben Ihr Passwort erfolgreich geändert. Bitte nutzen Sie ab
          sofort das neue Passwort, um sich anzumelden.
        </Benachrichtigung>
      )}
      {(anfrageGesendetBenachrichtigungVisible ||
        auftragAnConciergeGesendetBenachrichtigungVisible) && (
        <>
          <Benachrichtigung titel="Ihre Auftragsanfrage wurde erfolgreich versendet">
            Die
            {auftragAnConciergeGesendetBenachrichtigungVisible
              ? ' Provinzial wird '
              : ' ausgewählten Betriebe werden '}
            sich innerhalb der nächsten drei Werktage bei Ihnen melden.
          </Benachrichtigung>
          <Umfrage />
        </>
      )}
      <NeuerAuftragMobile />
      <h1 className="meineAuftraege__ueberschrift1">Meine Aufträge</h1>

      {!kundenauftraege || kundenauftraege.length === 0 ? (
        <div className="meineAuftraege__auftraege">
          <NeuerAuftragDesktop />
        </div>
      ) : (
        <>
          {anfragen && anfragen.length > 0 ? (
            <OffeneAuftraege
              offeneAuftraege={filterOffeneAuftraege(
                kundenauftraege,
                auftragAnfragenMap
              )}
              auftragAnfragenMap={auftragAnfragenMap}
              anfrageIdAnzahlUngesehenerEventsMap={
                anfrageIdAnzahlUngesehenerEventsMap
              }
            />
          ) : (
            <div className="meineAuftraege__auftraege">
              <NeuerAuftragDesktop />
            </div>
          )}
          <VergangeneAuftraege
            vergangeneAuftraege={kundenauftraege.filter(
              ohneAnfragenOderAbgebrochenOderBeendet(auftragAnfragenMap)
            )}
            auftragAnfragenMap={auftragAnfragenMap}
          />
        </>
      )}
    </div>
  );
}

function OffeneAuftraege({
  offeneAuftraege,
  auftragAnfragenMap,
  anfrageIdAnzahlUngesehenerEventsMap,
}) {
  return (
    <div className="meineAuftraege__auftraege">
      {offeneAuftraege.map((auftrag, key) => (
        <OffenerAuftrag
          key={key}
          auftrag={auftrag}
          anfragen={auftragAnfragenMap.get(auftrag.id)}
          anfrageIdAnzahlUngesehenerEventsMap={
            anfrageIdAnzahlUngesehenerEventsMap
          }
        />
      ))}
      <NeuerAuftragDesktop />
    </div>
  );
}

const OffenerAuftrag = withBreakpoints(function ({
  auftrag,
  anfragen,
  anfrageIdAnzahlUngesehenerEventsMap,
  currentBreakpoint,
}) {
  const history = useHistory();

  const isDesktop = currentBreakpoint === 'xlarge';
  const zuletztAktualisierteAnfrage = zuletztAktualisiert(anfragen);
  const [
    isAnfragedetailsModalVisible,
    showAnfragedetailsModal,
    hideAnfragedetailsModal,
  ] = useModalState();
  const [
    isAuftragLoeschenDialogVisible,
    showAuftragLoeschenDialog,
    hideAuftragLoeschenDialog,
  ] = useModalState();
  const [
    isAuftragNichtLoeschbarDialogVisible,
    showAuftragNichtLoeschbarDialog,
    hideAuftragNichtLoeschbarDialog,
  ] = useModalState();
  const auftragKannGeloeschtWerden = useKannAuftragGeloeschtWerden(
    zuletztAktualisierteAnfrage,
    anfragen
  );
  const showAuftragLoeschenOderNichtLoeschbarDialog = auftragKannGeloeschtWerden
    ? showAuftragLoeschenDialog
    : showAuftragNichtLoeschbarDialog;

  const auftraege = useAuftraege();

  if (anfragen.length === 0) {
    return null;
  }

  const { beschreibung, spezifikation } = auftrag;

  const linkZuKommunikationskanal = isDesktop
    ? `/kommunikationskanal/${zuletztAktualisierteAnfrage.id}`
    : `/anfragen/${zuletztAktualisierteAnfrage.id}`;

  const oeffneKommunikationskanal = (event) => {
    event.preventDefault();
    history.push(linkZuKommunikationskanal);
  };

  return (
    <div
      className="meineAuftraege__auftrag"
      data-cy-auftragsbeschreibung={beschreibung}
    >
      <div
        className="meineAuftraege__auftragsstatus"
        data-cy-id="auftragsStatus"
      >
        <Auftragsstatus
          fortschritt={auftraege.auftragsstatus(anfragen)}
          gtmLabel="overviewCustomer"
        />
      </div>
      <div
        className="meineAuftraege__auftragsinformationen"
        style={{
          backgroundImage: `url("${ICON_MAP[spezifikation.taetigkeit]}")`,
        }}
      >
        <div className="meineAuftraege__auftragskopfzeile">
          <h2 className="meineAuftraege__auftragsbeschreibung">
            <a
              href={
                isDesktop
                  ? `/kommunikationskanal/${zuletztAktualisierteAnfrage.id}`
                  : `/anfragen/${zuletztAktualisierteAnfrage.id}`
              }
              onClick={oeffneKommunikationskanal}
            >
              {beschreibung}
            </a>
          </h2>
          <Button
            icon={<DeleteIcon />}
            color="grey"
            outlined
            onClick={showAuftragLoeschenOderNichtLoeschbarDialog}
          />
          {auftragKannGeloeschtWerden
            ? isAuftragLoeschenDialogVisible && (
                <AuftragLoeschenDialog
                  hide={hideAuftragLoeschenDialog}
                  anfrage={zuletztAktualisierteAnfrage}
                />
              )
            : isAuftragNichtLoeschbarDialogVisible && (
                <AuftragNichtLoeschbarDialog
                  hide={hideAuftragNichtLoeschbarDialog}
                  anfrage={zuletztAktualisierteAnfrage}
                />
              )}
        </div>
        <div className="meineAuftraege__auftragsdetailsAnsehen">
          <Link size="small" color="grey" onClick={showAnfragedetailsModal}>
            Auftragsdetails ansehen
          </Link>

          {isAnfragedetailsModalVisible && (
            <AnfragedetailsModal
              hide={hideAnfragedetailsModal}
              anfrage={zuletztAktualisierteAnfrage}
              auftrag={zuletztAktualisierteAnfrage.auftrag}
            />
          )}
        </div>
      </div>
      <div className="meineAuftraege__angefragteHandwerker">
        <h3 className="meineAuftraege__ueberschrift3">
          Angefragte Fachpartner:
        </h3>
        <div className="meineAuftraege__conciergeService margin:top:.5rem">
          <ConciergeService
            auftrag={auftrag}
            context={CONCIERGE_SERVICE_CONTEXT.MEINE_AUFTRAEGE}
          />
        </div>
        {anfragen.sort(byUpdatedAtDesc).map((anfrage, key) => {
          const linkZuKommunikationskanal = `/kommunikationskanal/${anfrage.id}`;
          const oeffneKommunikationskanal = () =>
            history.push(linkZuKommunikationskanal);

          const anfrageWurdeBeendet = anfrage.wurdeBeendet();

          const anzahlUngesehenerEvents =
            anfrageIdAnzahlUngesehenerEventsMap.get(anfrage.id) || 0;

          const anfrageWurdeBeendetUndOhneUngeleseneNachrichtenUndOhneUngeseheneEvents =
            anfrageWurdeBeendet && anzahlUngesehenerEvents === 0;

          return (
            <div
              key={key}
              className={classNames('meineAuftraege__angefragterHandwerker', {
                'meineAuftraege__angefragterHandwerker--anfrageWurdeBeendetUndOhneUngeleseneNachrichtenUndOhneUngeseheneEvents': anfrageWurdeBeendetUndOhneUngeleseneNachrichtenUndOhneUngeseheneEvents,
              })}
              onClick={oeffneKommunikationskanal}
            >
              <div className="meineAuftraege__anfrageinformationen">
                <a
                  onClick={(event) => event.preventDefault()}
                  href={linkZuKommunikationskanal}
                  className={classNames('meineAuftraege__firmenname', {
                    'meineAuftraege__firmenname--anfrageWurdeBeendetUndOhneUngeleseneNachrichtenUndOhneUngeseheneEvents': anfrageWurdeBeendetUndOhneUngeleseneNachrichtenUndOhneUngeseheneEvents,
                  })}
                >
                  {anfrage.handwerker.firmenname}
                </a>
                <div>{anfrage.statusAlsText()}</div>
              </div>

              <div>
                {anzahlUngesehenerEvents > 0 && (
                  <span className="meineAuftraege__ungelesen">
                    {anzahlUngesehenerEvents}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

const VergangenerAuftragMitAnfragen = withBreakpoints(function ({
  anfragen,
  auftrag,
  currentBreakpoint,
}) {
  const history = useHistory();

  const isDesktop = currentBreakpoint === 'xlarge';
  const zuletztAktualisierteAnfrage = zuletztAktualisiert(anfragen);
  const { beschreibung, updatedAt, createdAt, imStatusSeit } = auftrag;
  const istAbgeschlossen = anfragen.some((anfrage) =>
    anfrage.wurdeAbgeschlossen()
  );
  const linkZuKommunikationskanal = isDesktop
    ? `/kommunikationskanal/${zuletztAktualisierteAnfrage.id}`
    : `/anfragen/${zuletztAktualisierteAnfrage.id}`;
  const oeffneKommunikationskanal = () =>
    history.push(linkZuKommunikationskanal);

  const [
    isConciergeServiceModalVisible,
    showConciergeServiceModal,
    hideConciergeServiceModal,
  ] = useModalState();

  return (
    <>
      <Row
        className="meineAuftraege__vergangenerAuftrag"
        onClick={oeffneKommunikationskanal}
        data-cy-auftragsbeschreibung={beschreibung}
      >
        <Cell>
          <Link href={linkZuKommunikationskanal}>{beschreibung}</Link>
        </Cell>
        <Cell className="meineAuftraege__vergangenerAuftragZeit">
          {toDateString(updatedAt)}
        </Cell>
        <Cell className="meineAuftraege__vergangenerAuftragZeit">
          {toDateString(createdAt)}
        </Cell>
        <Cell className="meineAuftraege__vergangenerAuftragImStatusSeit">
          {istAbgeschlossen
            ? `Auftrag abgeschlossen am ${toDateString(imStatusSeit)}`
            : auftrag.anConciergeServiceWeitergeleitetAm
            ? `Weitergeleitet am ${toDateString(
                auftrag.anConciergeServiceWeitergeleitetAm
              )}`
            : `Weiterleitung verfügbar`}
        </Cell>
        <Cell className="meineAuftraege__status">
          {istAbgeschlossen ? (
            <Status
              color="lightGreen"
              size="largeNoHorizontalMargin"
              text={'Auftrag abgeschlossen'}
            />
          ) : auftrag.anConciergeServiceWeitergeleitetAm ? (
            <Status
              color="grey"
              size="largeNoHorizontalMargin"
              text={`Weitergeleitet am ${toDateString(
                auftrag.anConciergeServiceWeitergeleitetAm
              )}`}
              data-cy-id="weitergeleitetAm"
            />
          ) : (
            <>
              <Button
                color="green"
                onClick={showConciergeServiceModal}
                data-cy-id="auftragWeiterleiten"
              >
                Weiterleitung verfügbar
              </Button>
            </>
          )}
        </Cell>
      </Row>
      {!zuletztAktualisierteAnfrage.auftrag
        .anConciergeServiceWeitergeleitetAm &&
        isConciergeServiceModalVisible && (
          <AuftragWeiterleitenModal
            hide={hideConciergeServiceModal}
            zeigeDetails={true}
            auftrag={zuletztAktualisierteAnfrage.auftrag}
            anfrageIds={anfragen.map((anfrage) => anfrage.id)}
            gtmLabel="existingContract"
            grund={CONCIERGE_SERVICE_GRUND.ALLE_ANFRAGEN_ZU_AUFTRAG_ABGEBROCHEN}
          />
        )}
    </>
  );
});

function VergangenerAuftragOhneAnfragen({ auftrag }) {
  const { beschreibung, updatedAt, createdAt } = auftrag;
  const [
    isConciergeServiceModalVisible,
    showConciergeServiceModal,
    hideConciergeServiceModal,
  ] = useModalState();

  const [
    isAnfragedetailsModalVisible,
    showAnfragedetailsModal,
    hideAnfragedetailsModal,
  ] = useModalState();

  return (
    <>
      <Row
        className="meineAuftraege__vergangenerAuftrag"
        data-cy-auftragsbeschreibung={beschreibung}
        onClick={showAnfragedetailsModal}
      >
        <Cell>
          <Link onClick={showAnfragedetailsModal}>{beschreibung}</Link>
        </Cell>
        <Cell className="meineAuftraege__vergangenerAuftragZeit">
          {toDateString(updatedAt)}
        </Cell>
        <Cell className="meineAuftraege__vergangenerAuftragZeit">
          {toDateString(createdAt)}
        </Cell>
        <Cell className="meineAuftraege__vergangenerAuftragImStatusSeit">
          {auftrag.anConciergeServiceWeitergeleitetAm ? (
            `Weitergeleitet am ${toDateString(
              auftrag.anConciergeServiceWeitergeleitetAm
            )}`
          ) : (
            <Status
              color="lightGreen"
              size="largeNoHorizontalMargin"
              text="Weiterleitung verfügbar"
              icon={helpCenterIcon}
            />
          )}
        </Cell>
        <Cell className="meineAuftraege__status">
          {auftrag.anConciergeServiceWeitergeleitetAm ? (
            <Status
              color="grey"
              size="largeNoHorizontalMargin"
              text={`Weitergeleitet am ${toDateString(
                auftrag.anConciergeServiceWeitergeleitetAm
              )}`}
              data-cy-id="weitergeleitetAm"
            />
          ) : (
            <>
              <Button
                color="green"
                onClick={showConciergeServiceModal}
                data-cy-id="auftragWeiterleiten"
              >
                Weiterleitung verfügbar
              </Button>
            </>
          )}
        </Cell>
      </Row>
      {!auftrag.anConciergeServiceWeitergeleitetAm &&
        isConciergeServiceModalVisible && (
          <AuftragWeiterleitenModal
            zeigeDetails={true}
            hide={hideConciergeServiceModal}
            auftrag={auftrag}
            gtmLabel="existingContract"
            grund={CONCIERGE_SERVICE_GRUND.ALLE_ANFRAGEN_ZU_AUFTRAG_ABGEBROCHEN}
          />
        )}
      {isAnfragedetailsModalVisible && (
        <AnfragedetailsModal hide={hideAnfragedetailsModal} auftrag={auftrag} />
      )}
    </>
  );
}

function VergangeneAuftraege({ vergangeneAuftraege, auftragAnfragenMap }) {
  return vergangeneAuftraege && vergangeneAuftraege.length > 0 ? (
    <div>
      <h2 className="meineAuftraege__ueberschrift2">Vergangene Aufträge</h2>
      <div>
        <Table>
          <Row>
            <HeaderCell>Auftrag</HeaderCell>
            <HeaderCell>Letzte Aktualisierung</HeaderCell>
            <HeaderCell>Erstellt am</HeaderCell>
            <HeaderCell>Status</HeaderCell>
          </Row>
          {vergangeneAuftraege
            .sort(byAuftragUpdatedAt(auftragAnfragenMap))
            .map((vergangenerAuftrag, key) =>
              auftragAnfragenMap.has(vergangenerAuftrag.id) ? (
                <VergangenerAuftragMitAnfragen
                  key={key}
                  anfragen={auftragAnfragenMap.get(vergangenerAuftrag.id)}
                  auftrag={vergangenerAuftrag}
                />
              ) : (
                <VergangenerAuftragOhneAnfragen
                  key={key}
                  auftrag={vergangenerAuftrag}
                />
              )
            )}
        </Table>
      </div>
    </div>
  ) : null;
}

function byAuftragUpdatedAt(auftragAnfragenMap) {
  return (vergangenerAuftrag1, vergangenerAuftrag2) =>
    updatedAtZuAuftrag(auftragAnfragenMap, vergangenerAuftrag1) -
    updatedAtZuAuftrag(auftragAnfragenMap, vergangenerAuftrag2);
}

function updatedAtZuAuftrag(auftragAnfragenMap, vergangenerAuftrag) {
  const anfragen = auftragAnfragenMap.get(vergangenerAuftrag.id);
  if (anfragen && anfragen.length > 0) {
    return toTime(anfragen.sort(byUpdatedAtDesc)[0].updatedAt);
  }
  return toTime(vergangenerAuftrag.updatedAt);
}

function NeuerAuftragDesktop() {
  return (
    <NeuerAuftragSchaltflaeche
      className="meineAuftraege__neuerAuftrag"
      data-cy-id="neuerAuftragButton"
      Component="button"
    >
      <PlusIcon className="meineAuftraege__neuerAuftragIcon" />
      Neuer Auftrag
    </NeuerAuftragSchaltflaeche>
  );
}

function NeuerAuftragMobile() {
  return (
    <NeuerAuftragSchaltflaeche
      icon={<PlusIcon />}
      className="hidden@desktop"
      color="yellow"
    >
      Neuer Auftrag
    </NeuerAuftragSchaltflaeche>
  );
}

function anfrageIdZuAnzahlUngesehenerEvents(anfragen, events) {
  const anfrageEventMap = groupBy(events, (event) => event.anfrageId);
  return mapMapEntries(anfrageEventMap, ([anfrageId, eventsZuAnfrage]) => [
    anfrageId,
    anzahlUngesehenerEventsZuAnfrage(anfragen, eventsZuAnfrage),
  ]);
}

function anzahlUngesehenerEventsZuAnfrage(anfragen, eventsZuAnfrage) {
  return eventsZuAnfrage.filter(ungeseheneEvents(anfragen)).length;
}

function ungeseheneEvents(anfragen) {
  return (event) => {
    const anfrage = anfrageZuEvent(anfragen)(event);
    return anfrage && anfrage.zuletztGesehenVonKunde
      ? isYoungerThanDate(event.createdAt, anfrage.zuletztGesehenVonKunde)
      : true;
  };
}

function offeneOderGesendeteAnfragen(anfrage) {
  return anfrage.istOffen() || anfrage.wurdeGesendet();
}

function filterOffeneAuftraege(kundenauftraege, auftragAnfragenMap) {
  return filtereAuftraege(auftragAnfragenMap, offeneUndGesendeteAuftraege)
    .sort(byAuftragCreatedAt(auftragAnfragenMap))
    .map((auftragId) => kundenauftraege.find((a) => a.id === auftragId));
}

function filtereAuftraege(auftragAnfragenMap, filter) {
  return [...auftragAnfragenMap.entries()]
    .filter(filter)
    .map(([auftragId]) => auftragId);
}

function offeneUndGesendeteAuftraege([, anfragenZuAuftrag]) {
  return anfragenZuAuftrag.some(offeneOderGesendeteAnfragen);
}

function byAuftragCreatedAt(auftragAnfragenMap) {
  return (auftragId1, auftragId2) =>
    isYoungerThanDate(
      auftragAnfragenMap.get(auftragId1)[0].auftrag.createdAt,
      auftragAnfragenMap.get(auftragId2)[0].auftrag.createdAt
    )
      ? -1
      : 1;
}

function ohneAnfragenOderAbgebrochenOderBeendet(auftragAnfragenMap) {
  return (auftrag) => {
    const anfragenZuAuftrag = auftragAnfragenMap.get(auftrag.id);
    const ohneAnfragen =
      Array.isArray(anfragenZuAuftrag) && anfragenZuAuftrag.length === 0;
    const wurdeAbgebrochen =
      !anfragenZuAuftrag ||
      anfragenZuAuftrag.every((anfrage) => anfrage.wurdeAbgebrochen());
    const wurdeBeendet =
      !anfragenZuAuftrag ||
      anfragenZuAuftrag.every((anfrage) => anfrage.wurdeBeendet());

    return ohneAnfragen || wurdeAbgebrochen || wurdeBeendet;
  };
}

function zuletztAktualisiert(anfragenZuAuftrag) {
  return anfragenZuAuftrag.sort(byUpdatedAtAsc)[0];
}

function anfrageZuEvent(anfragen) {
  return (event) => anfragen.find((a) => a.id === event.anfrageId);
}
