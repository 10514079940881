import { useEffect, useState } from 'react';
import {
  useKundenverzeichnis,
  useHandwerkerverzeichnis,
} from '../../DomainContext';

export function useIstKundeLoeschbar(kunde, setLoading) {
  const kundenverzeichnis = useKundenverzeichnis();
  const [isKundeLoeschbar, setKundeLoeschbar] = useState(false);

  useEffect(() => {
    let didCancel = false;
    async function checkKontoLoeschbar() {
      if (!didCancel) {
        setKundeLoeschbar(await kundenverzeichnis.istKundeLoeschbar(kunde.id));
        setLoading(false);
      }
    }

    checkKontoLoeschbar();
    return () => {
      didCancel = true;
    };
  }, [kunde, kundenverzeichnis, setLoading]);
  return isKundeLoeschbar;
}

export function useIstHandwerkerLoeschbar(handwerker, setLoading) {
  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const [isHandwerkerLoeschbar, setHandwerkerLoeschbar] = useState(false);

  useEffect(() => {
    let didCancel = false;
    async function checkKontoLoeschbar() {
      if (!didCancel) {
        setHandwerkerLoeschbar(
          await handwerkerverzeichnis.istHandwerkerLoeschbar(handwerker.id)
        );
        setLoading(false);
      }
    }

    checkKontoLoeschbar();
    return () => {
      didCancel = true;
    };
  }, [handwerker, handwerkerverzeichnis, setLoading]);
  return isHandwerkerLoeschbar;
}
