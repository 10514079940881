import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import EventDispatcher from '../domain/eventDispatcher';

export const EVENT_TYPE_KUNDE_GELOESCHT = 'KUNDE_GELOESCHT';
export const EVENT_TYPE_ANFRAGE_GELOESCHT_WEIL_KUNDE_GELOESCHT =
  'ANFRAGE_GELOESCHT_WEIL_KUNDE_GELOESCHT';
export const EVENT_TYPE_KUNDE_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT =
  'KUNDE_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT';
export const EVENT_TYPE_HANDWERKER_GELOESCHT = 'HANDWERKER_GELOESCHT';
export const EVENT_TYPE_ANFRAGE_GELOESCHT_WEIL_HANDWERKER_GELOESCHT =
  'ANFRAGE_GELOESCHT_WEIL_HANDWERKER_GELOESCHT';
export const EVENT_TYPE_HANDWERKER_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT =
  'HANDWERKER_FUER_WEITER_BESTEHENDE_ANFRAGE_GELOESCHT';
export const EVENT_TYPE_HANDWERKER_ANGEMELDET = 'HANDWERKER_ANGEMELDET';
export const EVENT_TYPE_HANDWERKER_PROFIL_AKTUALISIERT =
  'HANDWERKER_PROFIL_AKTUALISIERT';
export const EVENT_TYPE_AUFTRAG_GELOESCHT = 'AUFTRAG_GELOESCHT';
export const EVENT_TYPE_ANFRAGE_GESENDET = 'ANFRAGE_GESENDET';
export const EVENT_TYPE_AUFTRAG_AN_CONCIERGE_GESENDET =
  'AUFTRAG_AN_CONCIERGE_GESENDET';
export const EVENT_TYPE_ANFRAGE_ABGELEHNT = 'ANFRAGE_ABGELEHNT';
export const EVENT_TYPE_ANFRAGE_ANGENOMMEN = 'ANFRAGE_ANGENOMMEN';
export const EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN = 'ANFRAGE_ZURUECKGEZOGEN';
export const EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN_DURCH_KUNDENBERATER =
  'ANFRAGE_ZURUECKGEZOGEN_DURCH_KUNDENBERATER';
export const EVENT_TYPE_NACHRICHT_GESENDET = 'NACHRICHT_GESENDET';
export const EVENT_TYPE_ANGEBOT_GESENDET = 'ANGEBOT_GESENDET';
export const EVENT_TYPE_ANGEBOT_ANGENOMMEN = 'ANGEBOT_ANGENOMMEN';
export const EVENT_TYPE_ANGEBOT_ABGELEHNT = 'ANGEBOT_ABGELEHNT';
export const EVENT_TYPE_AUFTRAGSABNAHME_GESTARTET = 'AUFTRAGSABNAHME_GESTARTET';
export const EVENT_TYPE_AUFTRAGSABNAHME_ABGELEHNT = 'AUFTRAGSABNAHME_ABGELEHNT';
export const EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT =
  'AUFTRAGSABNAHME_BESTAETIGT';
export const EVENT_TYPE_ZAHLUNGSEINGANG_BESTAETIGT =
  'ZAHLUNGSEINGANG_BESTAETIGT';
export const EVENT_TYPE_ANFRAGE_ABGESCHLOSSEN_DURCH_KUNDENBERATER =
  'ANFRAGE_ABGESCHLOSSEN_DURCH_KUNDENBERATER';
export const EVENT_TYPE_AUFTRAG_WIDERRUFEN = 'AUFTRAG_WIDERRUFEN';
export const EVENT_TYPE_RECHNUNG_GESENDET = 'RECHNUNG_GESENDET';
export const EVENT_TYPE_BEWERTUNG_ANGEFORDERT = 'BEWERTUNG_ANGEFORDERT';
export const EVENT_TYPE_PROBLEM_GEMELDET = 'PROBLEM_GEMELDET';

export default class EventDispatcherImpl extends EventDispatcher {
  constructor() {
    super();
    this.subscribers = new Map();
  }

  subscribe(eventType, subscriber) {
    const eventSubscribers = this.subscribers.get(eventType) || [];
    eventSubscribers.push(subscriber);
    this.subscribers.set(eventType, eventSubscribers);
  }

  unsubscribe(eventType, subscriber) {
    const eventSubscribers = this.subscribers.get(eventType) || [];
    this.subscribers.set(
      eventType,
      eventSubscribers.filter(
        (eventSubscriber) => eventSubscriber !== subscriber
      )
    );
  }

  async fireEvent(event) {
    return this.fireEvents([event]);
  }

  async fireEvents(events) {
    try {
      await API.graphql(
        graphqlOperation(mutations.fireEvents, {
          events: events.map((event) => JSON.stringify(event)),
        })
      );
    } catch (data) {
      logIfUnverified(data);
    }
    events.forEach((event) => {
      const eventSubscribers = this.subscribers.get(event.type) || [];
      eventSubscribers.forEach((eventSubscriber) => eventSubscriber(event));
    });
  }
}

function logIfUnverified(data) {
  if (
    data &&
    data.errors &&
    Array.isArray(data.errors) &&
    data.errors.length > 0 &&
    data.errors[0].message &&
    data.errors[0].message.includes('Email address is not verified.')
  ) {
    console.log(data.errors[0].message);
  } else {
    throw data;
  }
}
