import React, { useState, useRef } from 'react';
import { useAuftraege } from '../../DomainContext';
import Button from '../Button/Button';
import classNames from 'classnames';
import { gtmEvent } from '../../googleAnalytics';
import { withBreakpoints } from 'react-with-breakpoints';
import { useDialogState } from '../../hooks';
import MaximaleDateigroesseUeberschrittenDialog from '../MaximaleDateigroesseUeberschrittenDialog/MaximaleDateigroesseUeberschrittenDialog';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron_right.svg';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { ReactComponent as PdfFileIcon } from '../../assets/icons/pdf_file.svg';
import './ChatInputArea.scss';

const MAX_FILE_SIZE = 1024 * 1024 * 9;

const ChatInputArea = withBreakpoints(function ChatInputArea({
  anfrage,
  autor,
  currentBreakpoint
}) {
  const [isSendingNachricht, setSendingNachricht] = useState(false);
  const [isSendeNachrichtDisabled, setSendeNachrichtDisabled] = useState(true);
  const [areDateiUploadButtonsVisible, setDateiUploadButtonsVisible] = useState(
    false
  );
  const nachrichtTextAreaRef = useRef(null);
  const auftraege = useAuftraege();

  const resizeTextArea = () => {
    nachrichtTextAreaRef.current.style.height = 'auto';
    nachrichtTextAreaRef.current.style.height = `calc(${nachrichtTextAreaRef.current.scrollHeight}px - 2em + 3px)`;
  };

  const handleNachrichtTextAreaChange = () => {
    const text = nachrichtTextAreaRef.current.value.trim();
    setSendeNachrichtDisabled(text.length === 0);
    resizeTextArea();
  };
  const sendeNachricht = async e => {
    e.preventDefault();
    e.stopPropagation();
    const text = nachrichtTextAreaRef.current.value.trim();
    if (text.length > 0) {
      setSendeNachrichtDisabled(true);
      setSendingNachricht(true);
      await auftraege.sendeNachricht(autor, anfrage, text);
      nachrichtTextAreaRef.current.value = '';
      nachrichtTextAreaRef.current.focus();
      resizeTextArea();
      setSendingNachricht(false);

      gtmEvent({
        category: 'request',
        action: 'messageSent',
        label: autor === 'HANDWERKER' ? 'craftsman' : 'customer'
      });
    }
  };

  const isChatDisabled = anfrage.wurdeGesendet();
  const isChatHidden = !anfrage.kannKommuniziertWerden();

  const [
    isMaximaleDateigroesseUeberschrittenDialogShown,
    showMaximaleDateigroesseUeberschrittenDialog,
    hideMaximaleDateigroesseUeberschrittenDialog
  ] = useDialogState();

  const fotoHochladenRef = useRef(null);
  const pdfHochladenRef = useRef(null);

  const [isPdfHochladenLoading, setPdfHochladenLoading] = useState(false);
  const [isFotoHochladenLoading, setFotoHochladenLoading] = useState(false);

  const verarbeiteFileUpload = async event => {
    const isPdfHochladenRef = event.target === pdfHochladenRef.current;
    if (isPdfHochladenRef) {
      setPdfHochladenLoading(true);
    } else {
      setFotoHochladenLoading(true);
    }

    const file = event.target.files[0];
    event.target.value = null;
    if (file.size <= MAX_FILE_SIZE) {
      await auftraege.sendeNachricht(autor, anfrage, 'Datei', file);
    } else {
      showMaximaleDateigroesseUeberschrittenDialog();
    }

    if (isPdfHochladenRef) {
      setPdfHochladenLoading(false);
    } else {
      setFotoHochladenLoading(false);
    }
  };

  const handleFotoHochladenClick = () => {
    fotoHochladenRef.current.click();

    gtmEvent({
      category: 'request',
      action: 'imgUploaded'
    });
  };

  const handlePdfHochladenClick = () => {
    pdfHochladenRef.current.click();

    gtmEvent({
      category: 'request',
      action: 'pdfUploaded'
    });
  };

  const handleZeigeDateiUploadButtonsClick = () => {
    setDateiUploadButtonsVisible(true);
  };

  const isDesktop = currentBreakpoint === 'xlarge';

  return (
    <>
      <div
        className={classNames('chatInputArea', {
          'chatInputArea--hidden': isChatHidden
        })}
      >
        <div className="chatInputArea__form">
          <Button
            disabled={isChatDisabled}
            icon={<ChevronRightIcon />}
            className={classNames('margin:bottom:.75rem', {
              hidden: areDateiUploadButtonsVisible || isDesktop
            })}
            color="green"
            size="large"
            outlined
            onClick={handleZeigeDateiUploadButtonsClick}
          />
          <Button
            disabled={isChatDisabled}
            icon={<CameraIcon />}
            loading={isFotoHochladenLoading}
            className={classNames(
              'margin:bottom:.75rem',
              'margin:right:.75rem',
              {
                hidden: !areDateiUploadButtonsVisible && !isDesktop
              }
            )}
            color="green"
            size="large"
            outlined
            onClick={handleFotoHochladenClick}
          />

          <input
            onChange={verarbeiteFileUpload}
            accept="image/x-png,image/gif,image/jpeg"
            type="file"
            style={{ display: 'none' }}
            ref={fotoHochladenRef}
          />
          <Button
            disabled={isChatDisabled}
            icon={<PdfFileIcon />}
            className={classNames('margin:bottom:.75rem', {
              hidden: !areDateiUploadButtonsVisible && !isDesktop
            })}
            color="green"
            size="large"
            outlined
            loading={isPdfHochladenLoading}
            onClick={handlePdfHochladenClick}
          />

          <input
            onChange={verarbeiteFileUpload}
            accept="application/pdf"
            type="file"
            style={{ display: 'none' }}
            ref={pdfHochladenRef}
          />
          <textarea
            disabled={isChatDisabled}
            ref={nachrichtTextAreaRef}
            placeholder={
              autor === 'HANDWERKER'
                ? `Nachricht an ${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`
                : `Nachricht an ${anfrage.handwerker.firmenname}`
            }
            className="chatInputArea__textArea"
            onChange={handleNachrichtTextAreaChange}
            onCut={resizeTextArea}
            onPaste={resizeTextArea}
            onDrop={resizeTextArea}
            onKeyDown={resizeTextArea}
            onFocus={() => setDateiUploadButtonsVisible(false)}
            rows={1}
            cols={5}
          />
          <button
            className={classNames('chatInputArea__sendeNachrichtButton', {
              'chatInputArea__sendeNachrichtButton--spinner': isSendingNachricht,
              'chatInputArea__sendeNachrichtButton--expanded': !isSendeNachrichtDisabled,
              'chatInputArea__sendeNachrichtButton--chatDisabled': isChatDisabled
            })}
            onClick={sendeNachricht}
            disabled={isSendeNachrichtDisabled || isChatDisabled}
            data-cy-id="sendeNachrichtButton"
          >
            <div
              className={classNames('chatInputArea__sendeNachrichtText', {
                'chatInputArea__sendeNachrichtText--hidden': isSendeNachrichtDisabled
              })}
            >
              Senden
            </div>
          </button>
        </div>
      </div>
      {isMaximaleDateigroesseUeberschrittenDialogShown && (
        <MaximaleDateigroesseUeberschrittenDialog
          hide={hideMaximaleDateigroesseUeberschrittenDialog}
        />
      )}
    </>
  );
});

export default ChatInputArea;
