import React from 'react';
import classNames from 'classnames';
import './TextArea.scss';

export function TextArea({
  label,
  labelClassName,
  attribut,
  placeholder,
  hilfetext,
  validierungstext,
  maxLength,
  ...props
}) {
  return (
    <>
      {label && (
        <label
          className={classNames('textArea__label', labelClassName)}
          htmlFor={attribut}
          data-cy-label={label}
        >
          {maxLength ? `${label} (max. ${maxLength} Zeichen)` : label}
        </label>
      )}
      <textarea
        id={attribut}
        name={attribut}
        placeholder={placeholder}
        className={classNames('textArea', {
          'textArea--invalid': validierungstext
        })}
        data-cy-id={attribut}
        {...props}
      />
      {hilfetext && <div className="textArea__hilfetext">{hilfetext}</div>}
      {validierungstext && (
        <div
          data-cy-validierungstext={validierungstext}
          className="textArea__validierungstext"
        >
          {validierungstext}
        </div>
      )}
    </>
  );
}
