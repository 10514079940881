import { Storage } from 'aws-amplify';
import awsmobile from '../aws-exports';
import { v4 as uuid } from 'uuid';

export const toS3Object = (key, fileName, mimeType) => ({
  bucket: awsmobile.aws_user_files_s3_bucket,
  region: awsmobile.aws_user_files_s3_bucket_region,
  key,
  mimeType,
  fileName
});

export async function uploadFile(file) {
  const { name: fileName, type: mimeType } = file;
  const generatedFileName = `${uuid()}-${fileName}`;
  // Muss manuell in den Storage gepackt werden, da Amplify dies noch nicht automatisch unterstützt,
  // siehe: https://github.com/aws-amplify/amplify-js/issues/2706#issuecomment-464384653
  // Die maximale Dateigröße über AppSync beträgt 1 MB.
  const { key } = await Storage.put(generatedFileName, file, {
    contentDisposition: 'inline',
    contentType: mimeType
  });
  return { key, fileName, mimeType };
}
