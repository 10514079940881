import './Umfrage.scss';

import React, { useState } from 'react';

import BewertungErfolgreich from '../BewertungErfolgreich';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import ReactDOM from 'react-dom';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { useModalState } from '../../../hooks';

function Umfragehinweis({ showUmfrageModal, hideUmfragehinweisModal }) {
  return ReactDOM.createPortal(
    <div className="umfragehinweis">
      <div className="umfragehinweis__header">
        <button
          className="umfragehinweis__closeButton"
          onClick={hideUmfragehinweisModal}
        />
        <div className="umfragehinweis__title">
          Ihre Meinung ist uns wichtig!
        </div>
        <div className="umfragehinweis__spacer" />
      </div>
      <div className="umfragehinweis__content">
        <div className="umfragehinweis__contentText">
          Um die „MeinZuhauseUndIch“-Plattform für Sie weiter zu verbessern,
          brauchen wir Ihre Hilfe.
        </div>
        <Button
          color="green"
          size="small"
          onClick={() => {
            hideUmfragehinweisModal();
            showUmfrageModal();
          }}
        >
          Jetzt bewerten
        </Button>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}

export default function Umfrage() {
  const [
    isUmfragehinweisModalVisible,
    ,
    hideUmfragehinweisModal,
  ] = useModalState(true);
  const [
    isUmfrageModalVisible,
    showUmfrageModal,
    hideUmfrageModal,
  ] = useModalState();

  return (
    <>
      {isUmfragehinweisModalVisible && (
        <Umfragehinweis
          showUmfrageModal={showUmfrageModal}
          hideUmfragehinweisModal={hideUmfragehinweisModal}
        />
      )}
      {isUmfrageModalVisible && (
        <UmfrageModal hideUmfrageModal={hideUmfrageModal} />
      )}
    </>
  );
}

export function UmfrageModal({ hideUmfrageModal }) {
  const [isDankeVisible, setDankeVisible] = useState(false);
  return (
    <Modal
      title="Ihre Meinung ist uns wichtig!"
      hideActions
      hide={hideUmfrageModal}
    >
      {isDankeVisible ? (
        <BewertungErfolgreich
          hideModal={hideUmfrageModal}
          text="Wir haben Ihre Bewertung erhalten."
        />
      ) : (
        <ReactTypeformEmbed
          class="typeform-widget"
          url="https://meinzuhauseundich898874.typeform.com/to/u0boOu?typeform-welcome=0"
          style={{ position: 'unset' }}
          onSubmit={() => setDankeVisible(true)}
        />
      )}
    </Modal>
  );
}
