import './Footer.scss';

import Link from '../Link/Link';
import ProvinzialLogo from '../ProvinzialLogo/ProvinzialLogo';
import React from 'react';
import { UmfrageModal } from '../Bewertungen/Portalbewertung/Umfrage';
import contactIcon from '../../assets/icons/contact.svg';
import smileyIcon from '../../assets/icons/smiley.svg';
import { useHistory } from 'react-router-dom';
import { useModalState } from '../../hooks';

export default () => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const isRouteSpezifikationsassistent = pathname.includes(
    '/spezifikationsassistent'
  );
  const isKommunikationskanalActive =
    pathname.includes('/kommunikationskanal') ||
    pathname.includes('/nachrichten');

  return (
    <>
      {!isRouteSpezifikationsassistent && !isKommunikationskanalActive && (
        <footer className="footer">
          <div className="footer__hinweise">
            <Hilfehinweis />
            <Umfragehinweis />
            <div className="footer__logo">
              <ProvinzialLogo className="provinzialLogo--footer" />
            </div>
          </div>
          <div className="footer__legalContent">
            <div className="footer__provinzial">
              &copy; {new Date().getFullYear()} Provinzial Rheinland
            </div>
            <ul className="footer__list">
              <li className="footer__listElement">
                <a
                  href="https://meinzuhauseundich.de/impressum"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Impressum
                </a>
              </li>
              <li className="footer__listElement">
                <a
                  href="https://meinzuhauseundich.de/datenschutzerklaerung"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzerklärung
                </a>
              </li>
              <li className="footer__listElement">
                <a
                  href="https://meinzuhauseundich.de/allgemeine-geschaeftsbedingungen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rechtliches
                </a>
              </li>
              <li className="footer__listElement">
                <a title="Cookie-Einstellungen" target="_self" class="link">
                  Cookie-Einstellungen
                </a>
              </li>
            </ul>
          </div>
        </footer>
      )}
    </>
  );
};

function FooterHinweis({ icon, iconAlt, intro, text, children }) {
  return (
    <div className="footer__hinweis">
      <img className="footer__hinweisIcon" src={icon} alt={iconAlt} />
      <div>
        <h3 className="footer__hinweisIntro">{intro}</h3>
        <p className="footer__hinweisText">{text}</p>
        {children}
      </div>
    </div>
  );
}

function Umfragehinweis() {
  const [
    isUmfrageModalVisible,
    showUmfrageModal,
    hideUmfrageModal,
  ] = useModalState();

  const text = (
    <>
      Unser Ziel ist es, <strong>meinzuhauseundich.de</strong> stetig zu
      verbessern.
      <br />
      Ihre Meinung wird uns dabei helfen.
    </>
  );

  return (
    <FooterHinweis
      icon={smileyIcon}
      iconAlt="An Meinungsumfrage teilnehmen"
      intro="Ihre Meinung ist uns wichtig"
      text={text}
    >
      <Link size="small" onClick={showUmfrageModal}>
        An Meinungsumfrage teilnehmen
      </Link>
      {isUmfrageModalVisible && (
        <UmfrageModal hideUmfrageModal={hideUmfrageModal} />
      )}
    </FooterHinweis>
  );
}

function Hilfehinweis() {
  const text = (
    <>
      Sie haben Fragen oder Anregungen zu unserem Service? Sie erreichen uns
      unter{' '}
      <a
        className="link link--small"
        href="mailto:support@provinzial-meinzuhause.de"
      >
        support@provinzial-meinzuhause.de
      </a>{' '}
      oder nutzen Sie unseren{' '}
      <a href="https://meinzuhauseundich.de#rueckruf-service">
        Rückruf-Service
      </a>
    </>
  );

  return (
    <FooterHinweis
      icon={contactIcon}
      intro="So erreichen Sie uns"
      text={text}
    ></FooterHinweis>
  );
}
