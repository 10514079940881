import React, { useState } from 'react';

import Button from '../../../components/Button/Button';
import { useKundenverzeichnis } from '../../../DomainContext';
import { useBenutzerContext } from '../../../contexts/BenutzerContext';

import './WerbeeinwilligungVervollstaendigen.scss';

const werbeeinwilligungStatus = {
  idle: 'idle',
  error: 'error',
  loading: 'loading',
};

export default function WerbeeinwilligungVervollstaendigen() {
  const kundenverzeichnis = useKundenverzeichnis();
  const { benutzer: kunde } = useBenutzerContext();
  const [status, setStatus] = useState(werbeeinwilligungStatus.idle);

  async function updateWerbeeinwilligung(werbeeinwilligungErteilt) {
    try {
      console.log('start loading');
      setStatus(werbeeinwilligungStatus.loading);
      await kundenverzeichnis.aktualisiereKunde({
        id: kunde.id,
        werbeeinwilligung: werbeeinwilligungErteilt,
      });
    } catch (error) {
      console.log('error', error);
      setStatus(werbeeinwilligungStatus.error);
    } finally {
      console.log('end loading');
    }
  }
  console.log({ status });
  return (
    <div
      className="werbeeinwilligungVervollstaendigen"
      data-cy-id="werbeeinwilligung"
    >
      <div className="werbeeinwilligungVervollstaendigen__container">
        <div className="werbeeinwilligungVervollstaendigen__header">
          <h1 className="werbeeinwilligungVervollstaendigen__headerTitle">
            Einwilligung in telefonische Kontaktaufnahme
          </h1>
        </div>
        <div className="werbeeinwilligungVervollstaendigen__content">
          <p>
            Dürfen wir Sie zum Zwecke der{' '}
            <a href="/einwilligung" target="_blank" rel="noopener noreferrer">
              Unterstützung und Optimierung unserer Vermittlungsangebote
            </a>{' '}
            telefonisch kontaktieren?
          </p>
          <p>
            Die Zustimmung können Sie jederzeit in Ihrem Profil verwalten und
            wieder entziehen.
          </p>
          {status === werbeeinwilligungStatus.error && (
            <p className="werbeeinwilligungVervollstaendigen__error">
              Ein Fehler ist aufgetreten, bitte probieren Sie es erneut.
            </p>
          )}
        </div>
        <div className="werbeeinwilligungVervollstaendigen__actions">
          <Button
            size="medium"
            loading={status === werbeeinwilligungStatus.loading}
            disabled={status === werbeeinwilligungStatus.loading}
            onClick={() => updateWerbeeinwilligung(false)}
          >
            Nein, weiter ohne Einwilligung
          </Button>
          <Button
            size="medium"
            color="green"
            loading={status === werbeeinwilligungStatus.loading}
            disabled={status === werbeeinwilligungStatus.loading}
            onClick={() => updateWerbeeinwilligung(true)}
            data-cy-id="werbeeinwilligungZustimmenButton"
          >
            Ja, ich stimme zu
          </Button>
        </div>
      </div>
    </div>
  );
}
