import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useS3ObjectUrl } from '../../hooks';

import { Select } from '../Select/Select';
import { TextInput } from '../TextInput/TextInput';
import { TextArea } from '../TextArea/TextArea';
import { RadioGroup } from '../RadioGroup/RadioGroup';
import { gtmEvent } from '../../googleAnalytics';

import pdfIcon from '../../assets/icons/pdf_file.svg';

export function Profilzeile({ feld, children }) {
  return (
    <div className="profil__zeile">
      <label className="profil__feld">{feld}:</label>
      <div className="profil__wert">{children}</div>
    </div>
  );
}

export function EMailBenachrichtigungenEinstellung({
  aktualisierterBenutzer,
  aktualisiereBenutzer,
}) {
  return (
    <>
      <div className="profil__feldtext">
        <h2 className="profil__feldueberschrift">E-Mail-Benachrichtigungen</h2>
        <div className="profil__feldinhalt">
          Benachrichtigungen sind Meldungen zu den Aktivitäten Ihrer Aufträge
          dieser Plattform, die Sie per E-Mail erhalten.
        </div>
      </div>
      <RadioGroup
        hideLabel
        label="emailbenachrichtigungen"
        options={['Aktiviert', 'Deaktiviert']}
        value={
          aktualisierterBenutzer.emailbenachrichtigungen
            ? 'Aktiviert'
            : 'Deaktiviert'
        }
        vertical
        onChange={(e) => {
          const isEnabled = e.target.value === 'Aktiviert';
          aktualisiereBenutzer({
            ...aktualisierterBenutzer,
            emailbenachrichtigungen: isEnabled,
          });
          gtmEvent({
            category: 'profile',
            action: isEnabled
              ? 'emailNotificationsEnabled'
              : 'emailNotificationsDisabled',
          });
        }}
      />
    </>
  );
}

export function AbwesenheitEinstellung({
  aktualisierterBenutzer,
  aktualisiereBenutzer,
}) {
  const [abwesenheit, setAbwesenheit] = useState(
    Boolean(aktualisierterBenutzer?.abwesenheit)
  );
  return (
    <>
      <div className="profil__abwesenheitEinstellung">
        <div className="profil__feldtext">
          <h2 className="profil__feldueberschrift">Abwesenheit</h2>
          <div className="profil__feldinhalt">
            Stellen Sie hier ein ob und ab wann Sie abwesend sind. Dies
            bedeutet, dass Sie für den Zeitraum keine neuen Anfragen erhalten
            werden.
          </div>
        </div>
        <RadioGroup
          hideLabel
          label="abwesenheit"
          options={['Aktiviert', 'Deaktiviert']}
          value={abwesenheit ? 'Aktiviert' : 'Deaktiviert'}
          vertical
          onChange={(event) => {
            const isEnabled = event.target.value === 'Aktiviert';

            setAbwesenheit(!abwesenheit);
            if (!isEnabled) {
              aktualisiereBenutzer({
                ...aktualisierterBenutzer,
                abwesenheit: null,
              });
            }
          }}
        />
      </div>
      {abwesenheit && (
        <Zeitraum
          von={aktualisierterBenutzer.abwesenheit?.von}
          bis={aktualisierterBenutzer.abwesenheit?.bis}
          onChange={(neueAbwesenheit) => {
            aktualisiereBenutzer({
              ...aktualisierterBenutzer,
              abwesenheit: neueAbwesenheit,
            });
          }}
        />
      )}
    </>
  );
}

export function Zeitraum({ von, bis, onChange }) {
  const [gewaehlterZeitraum, setGewaehlterZeitraum] = useState({
    von: von ? new Date(von) : new Date(),
    bis: bis ? new Date(bis) : new Date(),
  });

  const handleZeitraumChange = (datumVon, datumBis) => {
    if (datumVon && datumBis) {
      onChange({ von: datumVon.toISOString(), bis: datumBis.toISOString() });
    }
  };

  return (
    <div className="profil__zeitraum">
      <div className="profil__zeitraumVon">
        <label>Von:</label>
        <DatePicker
          title="Zeitraum von"
          className="textInput__input"
          selected={gewaehlterZeitraum.von}
          onChange={(value) => {
            setGewaehlterZeitraum((currentGewaehlterZeitraum) => {
              const currentBis = moment(currentGewaehlterZeitraum.bis).isBefore(
                moment(value)
              )
                ? value
                : currentGewaehlterZeitraum.bis;
              handleZeitraumChange(value, currentBis);
              return { von: value, bis: currentBis };
            });
          }}
          dateFormat="dd.MM.yyyy"
          minDate={new Date()}
        />
      </div>
      <div className="profil__zeitraumBis">
        <label>Bis:</label>
        <DatePicker
          title="Zeitraum bis"
          className="textInput__input"
          selected={gewaehlterZeitraum.bis}
          onChange={(value) => {
            setGewaehlterZeitraum((currentGewaehlterZeitraum) => {
              handleZeitraumChange(currentGewaehlterZeitraum.von, value);
              return { von: currentGewaehlterZeitraum.von, bis: value };
            });
          }}
          dateFormat="dd.MM.yyyy"
          minDate={gewaehlterZeitraum.von}
        />
      </div>
    </div>
  );
}

export function RechtlicheAngabe({
  handwerker,
  typattribut,
  linkattribut,
  dokumentattribut,
  textattribut,
  ueberschrift,
  typattributbedingung,
  linkattributbedingung,
  dokumentattributbedingung,
  textattributbedingung,
}) {
  const typattributwert = handwerker[typattribut];
  const linkattributwert = handwerker[linkattribut];
  const textattributwert = handwerker[textattribut];
  const dokumentUrl = useS3ObjectUrl(handwerker[dokumentattribut]);
  const zeigeLink =
    typattributwert === linkattributbedingung && handwerker[linkattribut];
  const zeigeDokument =
    typattributwert === dokumentattributbedingung &&
    handwerker[dokumentattribut];
  const zeigeText =
    typattributwert === textattributbedingung && handwerker[textattribut];
  return (
    typattributwert !== typattributbedingung &&
    (zeigeLink || zeigeDokument || zeigeText) && (
      <>
        <h2 className="profil__sectionUeberschrift">{ueberschrift}</h2>
        {zeigeLink && (
          <a
            href={linkattributwert.replace(/^javascript:/, '')}
            rel="noreferrer noopener"
            target="_blank"
          >
            {linkattributwert}
          </a>
        )}
        {zeigeDokument && (
          <a
            className="profil__rechtlicheAngabe"
            href={dokumentUrl}
            rel="noreferrer noopener"
            target="_blank"
          >
            <img src={pdfIcon} className="profil__pdfIcon" alt="" />
            {handwerker[dokumentattribut].fileName}
          </a>
        )}
        {zeigeText && (
          <p>
            {textattributwert &&
              textattributwert.split('\n').map((zeile, key) => (
                <React.Fragment key={key}>
                  {zeile}
                  <br />
                </React.Fragment>
              ))}
          </p>
        )}
      </>
    )
  );
}

export function SelectWrapper({
  aktualisierterBenutzer,
  setAktualisierterBenutzer,
  validierungstext,
  validateOnBlur,
  attribut,
  onBlur,
  ...props
}) {
  const [blurred, setBlurred] = useState(false);
  return (
    <Select
      {...props}
      validierungstext={
        validateOnBlur ? (blurred ? validierungstext : null) : validierungstext
      }
      onBlur={(event) => {
        if (onBlur) {
          onBlur(event);
        }
        setBlurred(true);
      }}
      value={aktualisierterBenutzer[attribut] || ''}
      onChange={(event) => {
        setAktualisierterBenutzer({
          ...aktualisierterBenutzer,
          [attribut]: event.target.value,
        });
      }}
      data-cy-id={attribut}
    />
  );
}

export function TextInputWrapper({
  aktualisierterBenutzer,
  setAktualisierterBenutzer,
  validierungstext,
  validateOnBlur,
  onBlur,
  ...props
}) {
  const [blurred, setBlurred] = useState(false);
  return (
    <TextInput
      {...props}
      validierungstext={
        validateOnBlur ? (blurred ? validierungstext : null) : validierungstext
      }
      value={aktualisierterBenutzer[props.attribut] || ''}
      onBlur={(event) => {
        if (onBlur) {
          onBlur(event);
        }
        setBlurred(true);
      }}
      onChange={(event) => {
        setAktualisierterBenutzer(
          {
            ...aktualisierterBenutzer,
            [props.attribut]:
              event.target.value.length === 0 ? null : event.target.value,
          },
          event
        );
      }}
    />
  );
}

export function TextAreaWrapper({
  aktualisierterBenutzer,
  setAktualisierterBenutzer,
  setProfilGeaendert,
  validierungstext,
  validateOnBlur,
  onBlur,
  ...props
}) {
  const [blurred, setBlurred] = useState(false);
  return (
    <TextArea
      validierungstext={
        validateOnBlur ? (blurred ? validierungstext : null) : validierungstext
      }
      onBlur={(event) => {
        if (onBlur) {
          onBlur(event);
        }
        setBlurred(true);
      }}
      value={aktualisierterBenutzer[props.attribut] || ''}
      onChange={(event) => {
        setAktualisierterBenutzer({
          ...aktualisierterBenutzer,
          [props.attribut]:
            event.target.value.length === 0 ? null : event.target.value,
        });
        setProfilGeaendert && setProfilGeaendert(true);
      }}
      {...props}
    />
  );
}
