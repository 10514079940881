import React from 'react';
import { Radio } from './Input';
import { TextInput } from '../TextInput/TextInput';

export function Kontaktauswahl({ eingabe, spezifikation, waehleWert }) {
  return (
    <div className="spezifikationsassistent__kontaktauswahl">
      <Radio
        spezifikation={spezifikation}
        attribut={eingabe.attribut}
        wert="telefonisch"
        label="telefonisch (empfohlen)"
        waehleWert={waehleWert}
      />
      <div className="margin:top:.5rem margin:left:1rem">
        <TextInput
          disabled={spezifikation[eingabe.attribut] !== 'telefonisch'}
          attribut="Telefonnummer"
          label="Telefonnummer"
          hilfetext="Wird nur mit selbst ausgewählten Handwerkern geteilt. Bei Rückfragen kann der Fachpartner mit Ihnen direkt in Kontakt treten. Dadurch kann Ihre Anfrage schneller bearbeitet werden."
          value={spezifikation['Telefonnummer'] || ''}
          onChange={(event) => waehleWert('Telefonnummer', event.target.value)}
        />
      </div>
      <Radio
        className="margin:top:1rem"
        spezifikation={spezifikation}
        attribut={eingabe.attribut}
        wert="über meinzuhauseundich.de"
        waehleWert={() => {
          waehleWert('Telefonnummer', '');
          waehleWert(eingabe.attribut, 'über meinzuhauseundich.de');
        }}
      />
    </div>
  );
}
