import React from 'react';
import checkedIcon from '../../assets/icons/checked.svg';
import './Timeline.scss';

export default function Timeline({ children }) {
  return (
    <div className="timeline">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          index,
        });
      })}
      <TimelineSuccessItem />
    </div>
  );
}
export function TimelineItem({ headline, index, children }) {
  return (
    <div className="timeline__item">
      <div className="timeline__bubble">
        <div className="timeline__bubbleContent">
          <h1 className="timeline__headline">{headline}</h1>
          <p className="timeline__text">{children}</p>
        </div>
      </div>
      <div className="timeline__step">
        <div className="timeline__stepIndex">{index + 1}</div>
      </div>
      <div className="timeline__empty" />
    </div>
  );
}
function TimelineSuccessItem() {
  return (
    <div className="timeline__item timeline__item--success">
      <div className="timeline__bubble" />
      <div className="timeline__step">
        <div className="timeline__iconContainer">
          <img className="timeline__icon" src={checkedIcon} alt="" />
        </div>
      </div>
      <div className="timeline__empty" />
      <div className="timeline__successText">Fertig!</div>
    </div>
  );
}
