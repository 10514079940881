import { gtmSchrittLabel } from '../../screens/Kunde/Spezifikationsassistent/gtmSchrittLabel';
import React, { useEffect, useState } from 'react';
import { gtmEvent } from '../../googleAnalytics';
import classNames from 'classnames';
import Button from '../Button/Button';
import { useModalState } from '../../hooks';
import Link from '../Link/Link';
import { ReactComponent as ThinArrowIcon } from '../../assets/icons/arrow_thin_up.svg';
import { AuftragsstatusModal } from '../Auftragsstatus/AuftragsstatusModal';

export function Schritt({
  schritt,
  spezifikation,
  vorherigerKategorieIndex,
  naechsterKategorieIndex,
  schrittIndex,
  ausgewaehlteHandwerker,
  gefundeneHandwerker,
  handleAnfrageSendenClicked,
  children,
  onSchrittChange,
}) {
  const label = gtmSchrittLabel(
    schritt.kategorieIndex,
    schrittIndex,
    spezifikation
  );
  useEffect(() => {
    gtmEvent({
      category: 'assistant',
      action: 'stepOpened',
      label,
    });
  }, [label]);

  const augmentIstVollstaendig = (schritt) => ({
    ...schritt,
    istVollstaendig:
      schritt.istVollstaendig ||
      ((spezifikation) =>
        !schritt.eingaben.some((e) => {
          const hatBedingung = Boolean(e.bedingung);
          const sollAngezeigtWerden =
            !hatBedingung || e.bedingung(schrittIndex, spezifikation);
          const istVollstaendig =
            !sollAngezeigtWerden || e.istVollstaendig(spezifikation);

          return !istVollstaendig;
        })),
  });

  const zurueck = () => {
    onSchrittChange(schrittIndex - 1, vorherigerKategorieIndex);
  };

  const weiter = () => {
    onSchrittChange(schrittIndex + 1, naechsterKategorieIndex);
  };

  const [ladetext, setLadetext] = useState(null);

  const hasActions =
    schrittIndex > 0 && schritt.typ !== 'ladeindikator' && ladetext === null;
  const canHaveWeiterButton =
    schritt.typ === 'mehrfachauswahl' ||
    schritt.typ === 'einfachauswahl' ||
    schritt.typ === 'gemischteAuswahl' ||
    schritt.typ === 'standortauswahl' ||
    schritt.typ === 'ausfuehrungszeitraum' ||
    schritt.typ === 'kontaktauswahl';
  const canHaveAnfrageSendenButton = schritt.typ === 'handwerkerauswahl';

  const augmentedSchritt = augmentIstVollstaendig(schritt);
  const isWeiterButtonDisabled =
    canHaveWeiterButton && !augmentedSchritt.istVollstaendig(spezifikation);
  const isAnfrageSendenButtonDisabled =
    canHaveAnfrageSendenButton &&
    ausgewaehlteHandwerker.length === 0 &&
    gefundeneHandwerker.length > 0;

  return (
    <div className="spezifikationsassistent__schritt">
      <h1 className="spezifikationsassistent__frage">
        {ladetext === null && schritt.frage}
      </h1>
      <div
        className={classNames(
          'spezifikationsassistent__auswahl',
          `spezifikationsassistent__auswahl--${schritt.typ}`
        )}
      >
        {children(setLadetext, ladetext)}
      </div>
      {hasActions && (
        <>
          <div className="spezifikationsassistent__actions">
            <Button
              className="spezifikationsassistent__zurueckButton"
              size="small"
              onClick={zurueck}
            >
              Zurück
            </Button>
            {canHaveWeiterButton && (
              <Button
                className="spezifikationsassistent__weiterButton"
                color="green"
                disabled={isWeiterButtonDisabled}
                onClick={weiter}
                autoFocus
              >
                Weiter
              </Button>
            )}
            {canHaveAnfrageSendenButton && (
              <Button
                className="spezifikationsassistent__weiterButton"
                color="green"
                disabled={isAnfrageSendenButtonDisabled}
                data-cy-id="sendeAnfrageButton"
                onClick={() => {
                  setLadetext(
                    'Anfragen werden an Fachpartner gesendet. Bitte warten...'
                  );
                  handleAnfrageSendenClicked();
                }}
                autoFocus
              >
                {gefundeneHandwerker.length > 0 ? (
                  <>Anfrage an Fachpartner senden</>
                ) : (
                  <>Anfrage an Provinzial senden</>
                )}
              </Button>
            )}
          </div>
          {canHaveAnfrageSendenButton && <UnverbindlichkeitsInfo />}
        </>
      )}
    </div>
  );
}

function UnverbindlichkeitsInfo() {
  const [isVisible, showModal, hideModal] = useModalState();
  return (
    <>
      <div className="spezifikationsassistent__unverbindlichkeitsInfo">
        <span>
          Keine Sorge, das Senden der Anfrage ist
          <br />
          komplett unverbindlich.{' '}
          <Link
            color="lightGreen"
            onClick={() => {
              gtmEvent({
                category: 'contract',
                action: 'howToModalOpened',
                label: 'craftsmenSelection',
              });
              showModal();
            }}
          >
            Weitere Infos
          </Link>
        </span>
        <ThinArrowIcon />
      </div>
      {isVisible && (
        <AuftragsstatusModal
          hideModal={hideModal}
          gtmLabel="craftsmenSelection"
        />
      )}
    </>
  );
}
