import SCHRITTE_TAETIGKEITEN from './taetigkeiten';
import SCHRITTE_AUFTRAGSDETAILS from './auftragsdetails';
import SCHRITTE_ZEITRAUM from './zeitraum';
import SCHRITTE_STANDORT from './standort';
import SCHRITTE_KONTAKT from './kontakt';
import SCHRITTE_HANDWERKERAUSWAHL from './handwerkerauswahl';

export default [
  ...SCHRITTE_TAETIGKEITEN,
  ...SCHRITTE_AUFTRAGSDETAILS,
  ...SCHRITTE_ZEITRAUM,
  ...SCHRITTE_STANDORT,
  ...SCHRITTE_KONTAKT,
  ...SCHRITTE_HANDWERKERAUSWAHL,
];
