import './Startseite.scss';

import { Accordion, Entry } from '../../components/Accordion/Accordion';

import Leistungsuebersicht from '../../components/Spezifikationsassistent/Leistungsuebersicht';
import Link from '../../components/Link/Link';
import NeuerAuftragSchaltflaeche from '../../components/NeuerAuftragSchaltflaeche/NeuerAuftragSchaltflaeche';
import React from 'react';
import StartseiteBuehne from './StartseiteBuehne';
import aussenIcon from '../../assets/icons/startseite/aussen.svg';
import erfahrungIcon from '../../assets/icons/vorteile/10-jahre-erfahrung.svg';
import gewaehrleistungIcon from '../../assets/icons/vorteile/5-jahre-gewaehrleistung.svg';
import hoechsteQualitaetIcon from '../../assets/icons/vorteile/hoechste-qualitaet.svg';
import innenIcon from '../../assets/icons/startseite/innen.svg';
import regionalitaetIcon from '../../assets/icons/vorteile/regionalitaet.png';
import schritt1 from '../../assets/icons/schritte/step-1.svg';
import schritt2 from '../../assets/icons/schritte/step-2.svg';
import schritt3 from '../../assets/icons/schritte/step-3.svg';
import schritt4 from '../../assets/icons/schritte/step-4.svg';
import { useHistory } from 'react-router-dom';
import volleKontrolleIcon from '../../assets/icons/vorteile/volle-kontrolle.svg';
import zeitersparnisIcon from '../../assets/icons/vorteile/zeitersparnis.png';

export default function Startseite() {
  const history = useHistory();
  return (
    <div className="startseite">
      <StartseiteBuehne />
      <div className="startseite__inhaltWrapper">
        <div className="startseite__inhalt">
          <div className="startseite__bereich">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  Für welchen Bereich benötigen Sie einen Fachpartner?
                </h1>
                <div className="startseite__spezifkationSchnelleinstiegkacheln">
                  <div className="startseite__spezifikationSchnelleinstieg">
                    <h2>Innenbereich</h2>
                    <img src={innenIcon} alt="Haus innen" />
                    <p className="startseite__beschreibung">
                      Egal ob Sanitär-, Malerarbeiten oder Entrümpelungen,
                      beschreiben Sie hier Ihr Vorhaben für den Innenbereich.
                    </p>
                    <p>
                      <NeuerAuftragSchaltflaeche
                        color="yellow"
                        einstiegspunkt="INNEN"
                        gtmEventData={{
                          category: 'landingpage',
                          action: 'newRequestClicked',
                          label: 'innen',
                        }}
                      >
                        Fachpartner finden
                      </NeuerAuftragSchaltflaeche>
                    </p>
                  </div>
                  <div className="startseite__spezifikationSchnelleinstieg">
                    <h2>Außenbereich</h2>
                    <img src={aussenIcon} alt="Haus aussen" />
                    <p className="startseite__beschreibung">
                      Egal ob Holz-, Dach- oder Gartenarbeiten, beschreiben Sie
                      hier Ihr Vorhaben für den Außenbereich.
                    </p>
                    <NeuerAuftragSchaltflaeche
                      color="yellow"
                      einstiegspunkt="AUSSEN"
                      gtmEventData={{
                        category: 'landingpage',
                        action: 'newRequestClicked',
                        label: 'aussen',
                      }}
                    >
                      Fachpartner finden
                    </NeuerAuftragSchaltflaeche>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="startseite__bereich">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h6 className="startseite__ueberschrift startseite__ueberschrift--small">
                  Beschreiben Sie in wenigen Schritten Ihr Vorhaben und wir
                  empfehlen Ihnen passende Fachpartner aus unserem zuverlässigen
                  Netzwerk. Kostenlos und unverbindlich.
                </h6>
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  Ihre Vorteile
                </h1>
                <div className="startseite__vorteilliste">
                  <Vorteil icon={regionalitaetIcon} ueberschrift="Regionalität">
                    Wir vermitteln Ihnen regionale Fachpartner aus verschiedenen
                    Gewerken.
                  </Vorteil>
                  <Vorteil
                    icon={erfahrungIcon}
                    ueberschrift="Über 10 Jahre Erfahrung"
                  >
                    In der Zusammenarbeit mit unseren vertrauenswürdigen
                    Fachpartnern.
                  </Vorteil>
                  <Vorteil
                    icon={hoechsteQualitaetIcon}
                    ueberschrift="Höchste Qualitätsstandards"
                  >
                    Denn mit unseren Handwerkern arbeiten wir bereits
                    erfolgreich in der Behebung von Versicherungsschäden
                    zusammen. Die Qualität unserer Fachpartner sichern wir mit
                    regelmäßigen Qualitätskontrollen.
                  </Vorteil>
                </div>
                <div className="startseite__vorteilliste">
                  <Vorteil
                    icon={gewaehrleistungIcon}
                    ueberschrift="5 Jahre Gewährleistung"
                  >
                    Durch die Provinzial für alle über meinzuhauseundich.de
                    zustande gekommenen Arbeiten.
                  </Vorteil>
                  <Vorteil
                    icon={zeitersparnisIcon}
                    ueberschrift="Zeitersparnis"
                  >
                    Sie sparen eine Menge Zeit, da wir die aufwendige
                    Fachpartnersuche für Sie übernehmen.
                  </Vorteil>
                  <Vorteil
                    icon={volleKontrolleIcon}
                    ueberschrift="Jederzeit im Blick"
                  >
                    Sie entscheiden an welche Fachpartner Ihre Anfragen gesendet
                    werden und können alle Schritte online nachvollziehen.
                  </Vorteil>
                </div>
              </div>
            </div>
          </div>
          <div className="startseite__bereich">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  Finden Sie professionelle Fachpartner. Schnell und Kostenlos.
                </h1>
                <p className="startseite__schrittUnterschrift">
                  Wir bieten Ihnen die richtige Unterstützung für Ihr Projekt,
                  egal ob eine große Sanierung ansteht oder nur eine neue Lampe
                  montiert werden soll.
                </p>
              </div>
            </div>
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--large">
                  So finden Sie Ihren <strong>Fachpartner</strong>
                </h1>
                <div className="startseite__schrittliste">
                  <Schritt icon={schritt1}>
                    Vorhaben schnell und einfach beschreiben und an gewünschte
                    Fachpartner senden.
                  </Schritt>
                  <Schritt icon={schritt2}>
                    Auftragsdetails komfortabel im Chat oder direkt mit
                    interessierten Fachpartnern besprechen.
                  </Schritt>
                  <Schritt icon={schritt3}>
                    Angebote erhalten, vergleichen und das für Sie beste
                    beauftragen.
                  </Schritt>
                  <Schritt icon={schritt4}>
                    Nach erfolgreicher Ausführung nehmen Sie den Auftrag ab und
                    bezahlen die Rechnung.
                  </Schritt>
                </div>
                <p className="startseite__schrittUnterschrift">
                  Das alles geschieht ganz einfach online hier über
                  meinzuhauseundich.de.
                </p>
                <p className="startseite__cta">
                  <NeuerAuftragSchaltflaeche
                    color="yellow"
                    size="large"
                    gtmEventData={{
                      category: 'landingpage',
                      action: 'newRequestClicked',
                      label: 'bottom',
                    }}
                    data-cy-id="callToActionButton"
                  >
                    Fachpartner finden
                  </NeuerAuftragSchaltflaeche>
                </p>
              </div>
            </div>
          </div>
          <div className="startseite__bereich startseite__faq">
            <div className="startseite__teaser">
              <div className="startseite__teaserContent">
                <h1 className="startseite__ueberschrift startseite__ueberschrift--small margin:bottom:1.5rem">
                  Sie haben Fragen zu <strong>MeinZuhauseUndIch</strong>?
                  <br />
                  Hier finden Sie Antworten auf die wichtigsten Fragen.
                </h1>
                <Accordion gtmCategory="landingpage" gtmAction="faqClicked">
                  <Entry title="Wie finde ich den richtigen Fachpartner für meinen Auftrag?">
                    Um schnell und einfach den richtigen Fachpartner für Ihren
                    Auftrag zu finden, müssen Sie sich einmal auf
                    meinzuhauseundich.de registrieren. Als nächstes stellen Sie
                    auf meinzuhauseundich.de Ihren Auftrag ein. Daraufhin werden
                    Ihnen die passenden Fachpartner aus Ihrer Region angezeigt.
                    Entscheiden Sie sich anschließend für den passenden
                    Fachpartner für Ihr Vorhaben.
                  </Entry>
                  <Entry title="Welche Leistungen und Services stehen mir zur Verfügung?">
                    <p className="margin:bottom:1rem">
                      meinzuhauseundich.de vermittelt Ihnen folgende Fachpartner
                      und Servicepartner:
                    </p>
                    <Leistungsuebersicht />
                  </Entry>
                  <Entry title="Sind die Fachpartner auf meinzuhauseundich.de qualitätsgeprüft?">
                    Wir vermitteln nur qualitätsgeprüfte Fachpartner an Sie, mit
                    denen die Provinzial bereits erfolgreich in der Behebung von
                    Versicherungsschäden zusammenarbeitet. In das
                    Fachpartnernetz der Provinzial werden nur Fachpartner
                    aufgenommen, die erfolgreich eine Qualitätsprüfung im Rahmen
                    eines persönlichen Gesprächs beim Fachpartner vor Ort
                    bestanden haben. Anschließend wird laufend überprüft, ob in
                    der Behebung von Versicherungsschäden die Arbeiten beim
                    Kunden und die Prozesse zu unserer Zufriedenheit ausgeführt
                    werden. Die laufenden Qualitätskontrollen werden durch ein
                    ein Jahresgespräch beim Fachpartner vor Ort abgerundet.
                  </Entry>
                  <Entry title="Wie erhalte ich ein Angebot eines Fachpartners?">
                    Die von Ihnen ausgewählten Fachpartner melden sich innerhalb
                    von 3 Werktagen bei Ihnen mit einem Angebot oder mit
                    Rückfragen zu Ihrer Anfrage. Gegebenenfalls werden sich die
                    von Ihnen ausgewählten Fachpartner auch telefonisch bei
                    Ihnen melden, um Rückfragen zu klären, oder Ihnen einen
                    Vor-Ort-Termin anbieten, um ein individuelles und
                    passgenaues Angebot zu erstellen.
                  </Entry>
                  <Entry title="Kann ein Fachpartner meine Anfrage ablehnen?">
                    Ja, es kann vorkommen, dass ein von Ihnen ausgewählter
                    Fachpartner Ihre Anfrage ablehnt. Das kann beispielsweise
                    der Fall sein, weil dieser in dem von Ihnen gewünschten
                    Umsetzungszeitraum keine freien Kapazitäten hat. Über den
                    Grund der Ablehnung werden Sie von uns natürlich informiert.
                  </Entry>
                  <Entry title="Wie beauftrage ich einen Fachpartner?">
                    Wenn Ihnen ein Angebot zusagt, können Sie den Fachpartner
                    direkt über meinzuhauseundich.de beauftragen. Der Vertrag
                    kommt dann direkt zwischen Ihnen und dem Fachpartner
                    zustande. Sie erhalten dann umgehend von uns eine
                    Auftragsbestätigung für Ihre Unterlagen.
                  </Entry>
                  <Entry title="Ist der Service MeinZuhauseUndIch kostenlos?">
                    Ja, die Registrierung auf meinzuhauseundich.de und die Suche
                    nach dem richtigen Fachpartner ist für Sie vollkommen
                    kostenlos. Ein von Ihnen beauftragter Fachpartner lässt
                    Ihnen nach der Ausführung des Auftrags über
                    meinzuhauseundich.de Ihre Rechnung zukommen, so dass Sie
                    diese direkt bezahlen können.
                  </Entry>
                  <Entry title="Welche Gewährleistungen erhalte ich bei der Beauftragung von Fachpartnern über meinzuhauseundich.de?">
                    <p>
                      Ihre Sicherheit ist uns wichtig! Damit Sie bei allen
                      Vorhaben, die Sie über meinzuhauseundich.de beauftragen,
                      auf Nummer sicher gehen können, erhalten Sie kostenfrei
                      eine 5-jährige Gewährleistung durch die Provinzial
                      Dienstleistungsgesellschaft mbH auf die von Ihnen
                      beauftragte Fachpartnerleistung. Detaillierte
                      Informationen zur Gewährleistung finden Sie auch in
                      unseren{' '}
                      <a
                        href="https://meinzuhauseundich.de/allgemeine-geschaeftsbedingungen"
                        title="Allgemeine Geschäftsbedingungen"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AGB
                      </a>
                      .
                    </p>
                    <p>
                      Die Gewährleistung des Fachpartners selbst hängt von den
                      Arbeiten ab, die durchgeführt werden. Bei Arbeiten zur
                      Errichtung eines Bauwerks oder Arbeiten, die auf die
                      Bausubstanz einwirken (z.B. Einbau einer neuen Heizung),
                      beträgt die Gewährleistungsfrist fünf Jahre. Bei kleineren
                      Arbeiten, wie Reparaturen oder Instandhaltungsmaßnahmen,
                      endet die Gewährleistung schon nach zwei Jahren.
                    </p>
                  </Entry>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Vorteil({ icon, ueberschrift, children }) {
  return (
    <div className="startseite__vorteil">
      <img
        src={icon}
        className="startseite__icon startseite__icon--large"
        alt=""
      />
      <h3 className="startseite__ueberschrift startseite__ueberschrift--tiny">
        {ueberschrift}
      </h3>
      <p className="startseite__beschreibung">{children}</p>
    </div>
  );
}

function Schritt({ icon, children }) {
  return (
    <div className="startseite__schritt">
      <img
        src={icon}
        className="startseite__icon startseite__icon--small"
        alt=""
      />
      <p className="startseite__beschreibung">{children}</p>
    </div>
  );
}
