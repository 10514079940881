import './Link.scss';

import React from 'react';
import { Link as Routerlink } from 'react-router-dom';
import classNames from 'classnames';

export default function Link({
  children,
  icon,
  className,
  href,
  color = 'transparent',
  size = 'medium',
  weight = 'normal',
  onClick,
  ...props
}) {
  const hasIcon = Boolean(icon);

  if (onClick) {
    return (
      <button
        className={classNames(
          'link',
          `link--${color}`,
          `link--${size}`,
          `link--${weight}`,
          className,
          { 'link--hasIcon': hasIcon }
        )}
        onClick={onClick}
        {...props}
      >
        {icon && <span className="link__icon">{icon}</span>}
        {children}
      </button>
    );
  }
  return (
    <Routerlink
      className={classNames(
        'link',
        `link--${color}`,
        `link--${size}`,
        `link--${weight}`,
        className,
        { 'link--hasIcon': hasIcon }
      )}
      to={href}
      {...props}
    >
      {icon && <span className="link__icon">{icon}</span>}
      {children}
    </Routerlink>
  );
}
