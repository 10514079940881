import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Div100vh from 'react-div-100vh';
import { useBodyScrollPrevention, useModalTracker } from '../../hooks';
import ReactTooltip from 'react-tooltip';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Button from '../Button/Button';

import { ReactComponent as CloseButton } from '../../assets/icons/close.svg';

import './Modal.scss';

const Modal = ({
  className,
  title,
  subtitle,
  hide,
  children,
  actions,
  hideActions,
  closeable,
  gaTrackingName,
  contentRef,
}) => {
  useBodyScrollPrevention();
  useModalTracker(gaTrackingName);
  closeable = typeof closeable === 'undefined';

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return ReactDOM.createPortal(
    <div className="modal__overlay" data-cy-id="modal">
      <Div100vh
        as="dialog"
        style={{ maxHeight: '100rvh' }}
        className={classNames(
          'modal',
          {
            'modal--modal': !className,
          },
          className
        )}
        open={true}
      >
        {loading && (
          <div className="modal__innerOverlay">
            <LoadingIndicator />
          </div>
        )}
        <div className="modal__header">
          {closeable && (
            <Button
              className="modal__closeButton"
              outlined
              icon={<CloseButton />}
              onClick={hide}
            />
          )}
          {title && (
            <>
              <div className="modal__title" title={title}>
                {title}
              </div>
              {subtitle && <span>{subtitle}</span>}
            </>
          )}
          <div className="modal__spacer" />
        </div>
        <div
          className={classNames('modal__content', {
            'modal__content--hideActions': hideActions,
          })}
          ref={contentRef}
        >
          {children}
        </div>
        {!hideActions && (
          <div className="modal__actions">
            {typeof actions === 'function' ? actions(setLoading) : actions}
          </div>
        )}
      </Div100vh>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;
