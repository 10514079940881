import React, { useEffect } from 'react';

import { Accordion } from '../Accordion/Accordion';
import Button from '../Button/Button';
import { Entry } from '../Accordion/Accordion';
import Modal from '../Modal/Modal';
import { gtmEvent } from '../../googleAnalytics';
import { useBenutzerContext } from '../../contexts/BenutzerContext';

export function AuftragsstatusModal({ hideModal, gtmLabel }) {
  const { istHandwerker } = useBenutzerContext();
  const actions = <Button onClick={hideModal}>Schließen</Button>;

  useEffect(() => {
    gtmEvent({
      category: 'contract',
      action: 'howToModalOpened',
      label: gtmLabel,
    });
  }, [gtmLabel]);

  return (
    <Modal
      title="So funktioniert „MeinZuhauseUndIch“"
      hide={hideModal}
      actions={actions}
    >
      {istHandwerker ? (
        <>
          <p className="margin:bottom:1rem">
            Von der Einstellung der Anfrage durch den Kunden bis zum
            abgeschlossenen Auftrag durchläuft die Anfrage mehrere Status.
            <br />
            Hier finden Sie Erläuterungen zu den einzelnen Schritten:
          </p>
          <Accordion>
            <Entry title="1 Anfrage erhalten">
              <p>Für Sie liegt eine neue Kundenanfrage vor.</p>
              <p>
                Bitte teilen Sie dem Kunden zeitnah mit, ob dieser Auftrag für
                Ihre Firma von Interesse ist.
              </p>
              <p>
                Bitte denken Sie daran: Das Serviceversprechen auf der
                "MeinZuhauseUndIch"-Plattform ist, dass Sie dem Kunden
                innerhalb von 3 Werktagen nach seiner Anfrage eine Antwort
                zukommen lassen.
              </p>
              <p>
                <strong>Eine Annahme ist noch nicht verbindlich.</strong> Diese
                gibt Ihnen und dem Kunden aber die Möglichkeit Fragen /
                Auftragsdetails über den Chat zu klären.
              </p>
            </Entry>

            <Entry title="2 Anfrageklärung">
              <p>
                Bitte nehmen Sie Kontakt zum Kunden auf und vereinbaren Sie
                falls erforderlich einen Vor-Ort-Termin. Wenn Sie dem Kunden ein
                Angebot unterbreiten möchten, so laden Sie dieses bitte über die
                Funktion „Angebot abgeben“ im Chat mit dem Kunden hoch. Kommen
                Sie nicht mit dem Kunden zusammen? Dann können Sie über die
                Funktion „Anfrage ablehnen“ die Anfrage schließen
              </p>
              <p>
                <strong>
                  Bitte nutzen Sie den Angebotsprozess über MeinZuhauseUhdIch,
                  nur so erhält der Kunde zusätzlich fünf Jahre Gewährleistung
                  von der Provinzial über die ausgeführten Leistungen!
                </strong>
              </p>
            </Entry>
            <Entry title="3 Angebotsklärung">
              <p>
                Sie haben bereits ein Angebot erstellt und hochgeladen. Es liegt
                nun beim Kunden, das Angebot anzunehmen. Dies erfolgt digital
                auf MeinZuhauseUndIch. Es kommt auch ohne Unterschrift eine
                rechtsverbindliche Beauftragung zustande, wenn das Angebot vom
                Kunden angenommen wird. Sie erhalten anschließend eine
                Bestätigung über die Annahme durch den Kunden per E-Mail.
              </p>
              <p>
                Sie möchten das Angebot noch einmal verändern? Laden Sie einfach
                ein neues PDF über die Funktion „Angebot abgeben“ im Chat mit
                dem Kunden hoch.
              </p>
            </Entry>
            <Entry title="4 Auftragsausführung">
              <p>
                Bitte stoßen Sie nach Auftragsausführung die Abnahme durch den
                Kunden im Chat über die Funktion „Auftragsabnahme starten“ an.
              </p>
              <p>
                Sollte ein Kunde von seinem 14tägigen Widerrufsrecht Gebrauch
                gemacht haben, können Sie die Anfrage mit der Funktion
                „Auftragswiderruf bestätigen“ beenden. Wurde schon mit der
                Auftragsausführung gestartet, können Sie bis dahin angefallene
                Kosten über die Funktion „Rechnung hochladen“ im Kunden-Chat dem
                Kunden in Rechnung stellen.
              </p>
            </Entry>
            <Entry title="5 Auftragsabnahme">
              Sie haben bestätigt, dass Sie den Auftrag vor Ort ausgeführt
              haben. Nun soll auch der Kunde bestätigen, dass die Ausführungen
              gemäß Ihrer Absprachen erledigt wurden. Diese Dokumentation gibt
              auch Ihnen Verbindlichkeit.
            </Entry>
            <Entry title="6 Abrechnung">
              Bitte stellen Sie die Rechnung als PDF über die Funktion „Rechnung
              hochladen“ im Kunden-Chat ein. Der Kunde erhält umgehend eine
              E-Mail zum Erhalt der Rechnung. Bitte bestätigen Sie über die
              Funktion „Zahlungseingang bestätigen“ anschließend, dass die
              Zahlung vom Kunden beglichen wurde. Dann ist der Auftrag auch für
              Sie komplett abgeschlossen.
            </Entry>
            <Entry title="7 Auftrag abgeschlossen">
              Herzlichen Glückwünsch, Sie haben erfolgreich einen Auftrag mit
              dem Kunden über MeinZuhauseUndIch abgeschlossen! Sie finden den
              Auftrag für Ihre Dokumentation unter „Abgeschlossene Aufträge“.
            </Entry>
            <Entry title="Anfrage abgelehnt">
              Die Anfrage wurde durch Sie abgelehnt. In diesem Fall ist die
              Anfrage für Sie abgeschlossen.
            </Entry>
            <Entry title="Anfrage zurückgezogen">
              Die Anfrage wurde durch den Kunden zurückgezogen. In diesem Fall
              ist die Anfrage für Sie abgeschlossen.
            </Entry>
            <Entry title="Auftrag widerrufen">
              Der Kunde hat von seinem 14tägigen Widerrufsrechts Gebrauch
              gemacht und Sie haben dies bestätigt über die Funktion
              „Auftragswiderruf bestätigen“ . Wurde schon mit der
              Auftragsausführung gestartet, können Sie dem Kunden bis dahin
              angefallene Kosten über die Funktion „Rechnung hochladen“ im
              Kunden-Chat in Rechnung stellen.
            </Entry>
          </Accordion>
          <p>
            Haben Sie noch Fragen? Antworten auf die häufigsten Fragen finden
            Sie{' '}
            <a
              href="/Handwerker_FAQ.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              hier
            </a>
            .
          </p>
          <p>
            {' '}
            Oder rufen Sie uns gerne unter{' '}
            <a className="link" href="tel:02119785858">
              0211 978 58 58
            </a>{' '}
            an oder schreiben Sie uns eine <br /> E-Mail an{' '}
            <a href="mailto:meinzuhause@provinzial-dl.com">
              meinzuhause@provinzial-dl.com
            </a>
            .
          </p>
        </>
      ) : (
        <>
          <p className="margin:bottom:1rem">
            Von der Einstellung Ihrer Anfrage bis zum abgeschlossenen Auftrag
            durchläuft die Anfrage mehrere Status.
            <br />
            Hier finden Sie Erläuterungen zu den einzelnen Schritten:
          </p>
          <Accordion>
            <Entry title="1 Anfrage gesendet">
              Sie haben Ihr Projekt beschrieben und als unverbindliche Anfrage
              an bis zu drei Fachpartner gesendet. Die Fachpartner sichten Ihre
              Anfrage innerhalb von 3 Werktagen.
            </Entry>
            <Entry title="2 Anfrageklärung">
              <p>
                Ein Fachpartner ist an Ihrem Projekt interessiert.{' '}
                <strong>
                  Eine Annahme der Anfrage ist noch nicht verbindlich.
                </strong>{' '}
                Der Fachpartner und Sie können nun aber die Fragen /
                Auftragsdetails über den Chat klären.
              </p>
              <p>
                Falls erforderlich nimmt der Fachpartner Kontakt zu Ihnen auf
                und vereinbart falls erforderlich einen Vor-Ort-Termin mit
                Ihnen. Wenn der Fachpartner Ihnen ein Angebot unterbreiten
                möchte, so erhalten Sie darüber eine E-Mail und können dieses
                anschließend online einsehen.
              </p>
              <p>
                <strong>
                  Bitte nutzen Sie mit dem Fachpartner den Angebotsprozess über
                  MeinZuhauseUndIch, nur so erhalten Sie zusätzlich fünf Jahre
                  Gewährleistung von der Provinzial über die ausgeführten
                  Leistungen!
                </strong>
              </p>
              <p>
                Kommen Sie nicht mit dem Fachpartner zusammen? Dann können Sie
                über die Funktion „Anfrage zurückziehen“ den Fachpartner darüber
                informieren.
              </p>
            </Entry>
            <Entry title="3 Angebotsklärung">
              <p>
                Sie haben bereits ein Angebot vom Fachpartner erhalten. Gerne
                können Sie über den Chat auch noch Nachfragen stellen. Bitte
                entscheiden Sie sich zur Annahme oder Ablehnung des Angebots.
                Dies erfolgt digital auf „MeinZuhauseUndIch“. Es kommt auch ohne
                Unterschrift eine rechtsverbindliche Beauftragung zustande, wenn
                das Angebot von Ihnen angenommen wird.
              </p>
              <p>
                <strong>
                  Bitte nutzen Sie mit dem Fachpartner den Angebotsprozess über
                  „MeinZuhauseUndIch“, nur so erhalten Sie zusätzlich fünf Jahre
                  Gewährleistung von der Provinzial über die ausgeführten
                  Leistungen!
                </strong>
              </p>
              <p>
                Sollten Sie Ihre Anfrage noch an weitere Fachpartner geschickt
                haben, werden diese nach Angebotsannahme automatisch informiert,
                dass der Auftrag bereits vergeben wurde.
              </p>
            </Entry>
            <Entry title="4 Auftragsausführung">
              Nachdem Sie das Angebot des Handwerkers angenommen haben erfolgt
              die Ausführung des Auftrags vor Ort.
            </Entry>
            <Entry title="5 Auftragsabnahme">
              Der Fachpartner hat bestätigt, dass er den Auftrag vor Ort
              ausgeführt hat. Bitte bestätigen auch Sie, dass die Ausführungen
              gemäß Ihrer Absprachen erledigt wurden. Diese Dokumentation gibt
              auch Ihnen Verbindlichkeit.
            </Entry>
            <Entry title="6 Abrechnung">
              <p>
                Der Fachpartner hat die Rechnung als PDF eingestellt. Sie
                erhalten umgehend eine E-Mail zum Erhalt der Rechnung. Bitte
                begleichen Sie die Rechnung wie mit dem Fachpartner vereinbart.
              </p>
              <p>
                Außerdem haben Sie ab jetzt die Möglichkeit den Fachpartner und
                seine Leistung im vorliegenden Auftrag zu bewerten.
              </p>
            </Entry>
            <Entry title="7 Auftrag abgeschlossen">
              Herzlichen Glückwunsch, Sie haben erfolgreich einen Auftrag mit
              dem Fachpartner über „MeinZuhauseUndIch“ abgeschlossen! Der
              Fachpartner hat den Eingang Ihrer Zahlung bestätigt. Sie finden
              den Auftrag für Ihre Dokumentation unter „Meine Aufträge“.
            </Entry>
            <Entry title="Weiterleitung verfügbar">
              Die ausgewählten Fachpartner haben Ihre Anfrage abgelehnt oder Sie
              haben diese selbst zurückgezogen. Wenn Sie weiterhin einen
              passenden Fachpartner suchen, können Sie über die Funktion
              „Auftrag weiterleiten“ Ihre Anfrage an unser Serviceteam
              übergeben. Wir melden uns innerhalb von 3 Werktagen mit einem
              konkreten Handwerkerkontakt per E-Mail bei Ihnen.
            </Entry>
            <Entry title="Weitergeleitet am">
              Hier sehen Sie, wann Sie Ihre Anfrage an unser Serviceteam
              übergeben haben. Wir melden uns innerhalb von 3 Werktagen mit
              einem konkreten Handwerkerkontakt per E-Mail bei Ihnen.
            </Entry>
          </Accordion>
          Haben Sie noch Fragen? Rufen Sie uns gerne unter{' '}
          <a className="link" href="tel:02119785858">
            0211 978 58 58
          </a>{' '}
          an oder schreiben Sie uns eine <br /> E-Mail an{' '}
          <a href="mailto:support@provinzial-meinzuhause.de">
            support@provinzial-meinzuhause.de
          </a>
          .
        </>
      )}
    </Modal>
  );
}
