import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as AlertHelpIcon } from '../../assets/icons/alert_help.svg';

import './Tooltip.scss';
import classNames from 'classnames';

export function Tooltip({ text, tooltipId }) {
  const tooltip = useRef(null);
  const [isTooltipOverlayVisible, setTooltipOVerlayVisible] = useState(false);

  return (
    <>
      <AlertHelpIcon
        className="tooltip__image"
        data-tip
        data-event="click"
        data-for={tooltipId}
        data-cy-tooltip={tooltipId}
      />
      <ReactTooltip
        id={tooltipId}
        className="tooltip"
        globalEventOff="click"
        clickable
        isCapture
        effect="solid"
        ref={tooltip}
        place="bottom"
        afterShow={() => {
          setTooltipOVerlayVisible(true);
        }}
        afterHide={() => {
          setTooltipOVerlayVisible(false);
        }}
      >
        <button
          className="tooltip__closeButton"
          onClick={() => {
            tooltip.current.tooltipRef = null;
            ReactTooltip.hide();
          }}
        />
        {text}
      </ReactTooltip>
      <div
        className={classNames({ tooltip__overlay: isTooltipOverlayVisible })}
      />
    </>
  );
}
