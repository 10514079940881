/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "meinzuhause-hostingbucket-production",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d3r7gtjmzooo2w.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-central-1:5b2a8db5-2c19-4054-8732-40b2bf6d8a06",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_XX9gpUU4c",
    "aws_user_pools_web_client_id": "4kr14qubof469sg3uu8vugqo2m",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dateien-production",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://hxbaxug7qrh5jd6zs67rjaxwzy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-zj6cly2ktfhc7fjtno52f3azxi"
};


export default awsmobile;
