/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAuftraege = /* GraphQL */ `
  query ListAuftraege(
    $filter: ModelAuftragFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuftraege(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getAuftrag = /* GraphQL */ `
  query GetAuftrag($id: ID!) {
    getAuftrag(id: $id) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listAnfragen = /* GraphQL */ `
  query ListAnfragen(
    $filter: ModelAnfrageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnfragen(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
      nextToken
    }
  }
`;
export const getAnfrage = /* GraphQL */ `
  query GetAnfrage($id: ID!) {
    getAnfrage(id: $id) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const anfragenByGesendetAn = /* GraphQL */ `
  query AnfragenByGesendetAn(
    $gesendetAn: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnfrageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    anfragenByGesendetAn(
      gesendetAn: $gesendetAn
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
      nextToken
    }
  }
`;
export const anfragenByGesendetVon = /* GraphQL */ `
  query AnfragenByGesendetVon(
    $gesendetVon: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnfrageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    anfragenByGesendetVon(
      gesendetVon: $gesendetVon
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
      nextToken
    }
  }
`;
export const getAngebot = /* GraphQL */ `
  query GetAngebot($id: ID!) {
    getAngebot(id: $id) {
      id
      createdAt
      updatedAt
      netto
      brutto
      annahmedatum
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      status
      anfrageAngeboteId
      gesendetVon
      gesendetAn
    }
  }
`;
export const listAngebote = /* GraphQL */ `
  query ListAngebote(
    $filter: ModelAngebotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAngebote(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        netto
        brutto
        annahmedatum
        anhang {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        status
        anfrageAngeboteId
        gesendetVon
        gesendetAn
      }
      nextToken
    }
  }
`;
export const getRechnung = /* GraphQL */ `
  query GetRechnung($id: ID!) {
    getRechnung(id: $id) {
      id
      createdAt
      netto
      brutto
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageRechnungenId
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const listRechnungen = /* GraphQL */ `
  query ListRechnungen(
    $filter: ModelRechnungFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRechnungen(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        netto
        brutto
        anhang {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anfrageRechnungenId
        gesendetVon
        gesendetAn
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNachrichten = /* GraphQL */ `
  query GetNachrichten($id: ID!) {
    getNachrichten(id: $id) {
      id
      createdAt
      autor
      text
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageNachrichtenId
      gelesen
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const listNachrichten = /* GraphQL */ `
  query ListNachrichten(
    $filter: ModelNachrichtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNachrichten(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        autor
        text
        anhang {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anfrageNachrichtenId
        gelesen
        gesendetVon
        gesendetAn
        updatedAt
      }
      nextToken
    }
  }
`;
export const listKunden = /* GraphQL */ `
  query ListKunden(
    $filter: ModelKundeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKunden(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getKunde = /* GraphQL */ `
  query GetKunde($id: ID!) {
    getKunde(id: $id) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listHandwerker = /* GraphQL */ `
  query ListHandwerker(
    $filter: ModelHandwerkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHandwerker(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
      nextToken
    }
  }
`;
export const getHandwerker = /* GraphQL */ `
  query GetHandwerker($id: ID!) {
    getHandwerker(id: $id) {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const getKontoloeschung = /* GraphQL */ `
  query GetKontoloeschung($id: ID!) {
    getKontoloeschung(id: $id) {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const listKontoloeschungen = /* GraphQL */ `
  query ListKontoloeschungen(
    $filter: ModelKontoloeschungFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKontoloeschungen(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grund
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        anfrageId
        type
        payload
        createdAt
        berechtigterKunde
        berechtigterHandwerker
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByBerechtigterKunde = /* GraphQL */ `
  query EventsByBerechtigterKunde(
    $berechtigterKunde: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByBerechtigterKunde(
      berechtigterKunde: $berechtigterKunde
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        anfrageId
        type
        payload
        createdAt
        berechtigterKunde
        berechtigterHandwerker
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByBerechtigterHandwerker = /* GraphQL */ `
  query EventsByBerechtigterHandwerker(
    $berechtigterHandwerker: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByBerechtigterHandwerker(
      berechtigterHandwerker: $berechtigterHandwerker
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        anfrageId
        type
        payload
        createdAt
        berechtigterKunde
        berechtigterHandwerker
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByAnfrage = /* GraphQL */ `
  query EventsByAnfrage(
    $anfrageId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByAnfrage(
      anfrageId: $anfrageId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        anfrageId
        type
        payload
        createdAt
        berechtigterKunde
        berechtigterHandwerker
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFrist = /* GraphQL */ `
  query GetFrist($id: ID!) {
    getFrist(id: $id) {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const listFristen = /* GraphQL */ `
  query ListFristen(
    $filter: ModelFristFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFristen(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fristAnfrageId
        faelligkeitsdatum
        typ
        initialesFaelligkeitsdatum
        createdAt
        updatedAt
        anfrage {
          id
          status
          zuletztGesehenVonHandwerker
          zuletztGesehenVonKunde
          anfrageAuftragId
          createdAt
          updatedAt
          anfrageHandwerkerId
          gesendetVon
          gesendetAn
          imStatusSeit
          handwerkerBewertetAm
          auftrag {
            id
            beschreibung
            ausfuehrungszeitraum
            strasse
            plz
            ort
            auftragKundeId
            spezifikation
            anhaenge {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anConciergeServiceWeitergeleitetAm
            updatedAt
            createdAt
            owner
            kunde {
              id
              vorname
              nachname
              email
              werbeeinwilligung
              emailbenachrichtigungen
              geloescht
              createdAt
              updatedAt
              owner
            }
          }
          angebote {
            items {
              id
              createdAt
              updatedAt
              netto
              brutto
              annahmedatum
              anhang {
                typ
                datei {
                  bucket
                  key
                  region
                  fileName
                  localUri
                  mimeType
                }
              }
              status
              anfrageAngeboteId
              gesendetVon
              gesendetAn
            }
            nextToken
          }
          rechnungen {
            items {
              id
              createdAt
              netto
              brutto
              anhang {
                typ
                datei {
                  bucket
                  key
                  region
                  fileName
                  localUri
                  mimeType
                }
              }
              anfrageRechnungenId
              gesendetVon
              gesendetAn
              updatedAt
            }
            nextToken
          }
          nachrichten {
            items {
              id
              createdAt
              autor
              text
              anhang {
                typ
                datei {
                  bucket
                  key
                  region
                  fileName
                  localUri
                  mimeType
                }
              }
              anfrageNachrichtenId
              gelesen
              gesendetVon
              gesendetAn
              updatedAt
            }
            nextToken
          }
          handwerker {
            id
            status
            firmenname
            strasse
            plz
            ort
            telefon
            mobil
            telefonischeErreichbarkeit
            webseite
            firmengruendung
            anzahlMitarbeiter
            email
            teilnehmerSeit
            lat
            long
            gewerke
            leistungen
            gewaehlteLeistungen {
              gewerk
              leistungen
            }
            regionen
            firmenlogo {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
            agbTyp
            agb {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
            agbLink
            datenschutzbestimmungenTyp
            datenschutzbestimmungen {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
            datenschutzbestimmungenLink
            impressumTyp
            impressum
            impressumLink
            emailbenachrichtigungen
            zusatzqualifikationenVorhanden
            zusatzqualifikationen
            geloescht
            letztesAnmeldedatum
            createdAt
            updatedAt
            abwesenheit {
              von
              bis
            }
            filialen {
              name
              strasse
              plz
              ort
              lat
              long
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const getBewertung = /* GraphQL */ `
  query GetBewertung($id: ID!) {
    getBewertung(id: $id) {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
export const listBewertungen = /* GraphQL */ `
  query ListBewertungen(
    $filter: ModelBewertungFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBewertungen(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bewertungKundeId
        bewertungAnfrageId
        bewertungHandwerkerId
        kundeAnzeigeName
        auftragsTitel
        kommentar
        kategorien {
          key
          titel
          sterne
        }
        freigegeben
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
