import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '../Button/Button';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import './MobileMenu.scss';

export function MobileMenuItem({ onClick, label, className }) {
  return (
    <li
      onClick={onClick}
      className={classNames('mobileMenu__option', className)}
    >
      {label}
    </li>
  );
}

export default function MobileMenu({ children, hide, isVisible }) {
  return (
    <div
      className={classNames('mobileMenu__overlay', {
        'mobileMenu__overlay--visible': isVisible,
      })}
    >
      <OutsideClickHandler onOutsideClick={hide}>
        <div className="mobileMenu" onMouseLeave={hide}>
          <div className="mobileMenu__header">
            <span className="mobileMenu__headerTitle">Aktion wählen</span>
            <Button icon={<CloseIcon />} onClick={hide} />
          </div>
          <ul className="mobileMenu__optionlist">{children}</ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
