import React, { useState } from 'react';
import { TextInput } from '../TextInput/TextInput';
import { Radio } from './Input';

export function Standortauswahl({ spezifikation, waehleWert }) {
  const [plzValide, setPLZValide] = useState(true);

  const createWaehleWert = (attribut) => (event) =>
    waehleWert(attribut, event.target.value);

  const createWaehleWertPLZ = (event) => {
    waehleWert('PLZ', event.target.value);
    setPLZValide(true);
  };

  const validierePLZ = (event) => {
    setPLZValide(event.target.validity.valid);
  };

  return (
    <div className="spezifikationsassistent__standort">
      <div className="spezifikationsassistent__adresse">
        <div className="spezifikationsassistent__strasse">
          <TextInput
            label="Straße und Hausnummer"
            value={spezifikation['Straße und Hausnummer'] || ''}
            onChange={createWaehleWert('Straße und Hausnummer')}
            attribut="StrasseUndHausnummer"
          />
        </div>
        <div className="spezifikationsassistent__ort">
          <div className="spezifikationsassistent__plz">
            <TextInput
              label="PLZ"
              value={spezifikation['PLZ'] || ''}
              onChange={createWaehleWertPLZ}
              onBlur={validierePLZ}
              type="text"
              pattern="^[0-9]{5}$"
              validierungstext={!plzValide && 'Ungültige PLZ'}
              attribut="PLZ"
            />
          </div>
          <div className="spezifikationsassistent__stadt">
            <TextInput
              label="Ort"
              value={spezifikation['Ort'] || ''}
              onChange={createWaehleWert('Ort')}
              attribut="Ort"
            />
          </div>
        </div>
        <div className="spezifikationsassistent__adresszusatz">
          <TextInput
            label="Adresszusatz"
            value={spezifikation['Adresszusatz'] || ''}
            onChange={createWaehleWert('Adresszusatz')}
            attribut="Adresszusatz"
          />
        </div>
      </div>
      <h2 className="spezifikationsassistent__unterueberschrift">
        Parkplatzsituation vor Ort
      </h2>
      <div className="spezifikationsassistent__parkplatzsituation">
        <Radio
          className="spezifikationsassistent__parkplatzsituationauswahl"
          spezifikation={spezifikation}
          attribut="Parkplatzsituation vor Ort"
          wert="Privater Parkplatz vorhanden"
          waehleWert={waehleWert}
        />
        <Radio
          className="spezifikationsassistent__parkplatzsituationauswahl"
          spezifikation={spezifikation}
          attribut="Parkplatzsituation vor Ort"
          wert="Öffentlicher Parkplatz vorhanden"
          waehleWert={waehleWert}
        />
        <Radio
          className="spezifikationsassistent__parkplatzsituationauswahl"
          spezifikation={spezifikation}
          attribut="Parkplatzsituation vor Ort"
          wert="Parken nur sehr eingeschränkt möglich"
          waehleWert={waehleWert}
        />
        <Radio
          className="spezifikationsassistent__parkplatzsituationauswahl"
          spezifikation={spezifikation}
          attribut="Parkplatzsituation vor Ort"
          wert="Sonstiges"
          waehleWert={waehleWert}
        />
      </div>
      {spezifikation['Parkplatzsituation vor Ort'] &&
        spezifikation['Parkplatzsituation vor Ort'] === 'Sonstiges' && (
          <textarea
            value={
              spezifikation['Parkplatzsituation vor OrtText']
                ? spezifikation['Parkplatzsituation vor OrtText']
                : ''
            }
            placeholder="Geben Sie dazu bitte Details an."
            onChange={(event) =>
              waehleWert('Parkplatzsituation vor OrtText', event.target.value)
            }
            className="spezifikationsassistent__sonstigesText spezifikationsassistent__sonstigesText--standort"
          />
        )}
    </div>
  );
}
