import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { recursiveQuery } from '../persistence/query';

export const KATEGORIEN = [
  {
    titel: 'Arbeitsqualität',
    key: 'arbeitsqualitaet',
  },
  {
    titel: 'Zuverlässigkeit',
    key: 'zuverlaessigkeit',
  },
  {
    titel: 'Kommunikation',
    key: 'kommunikation',
  },
];

export async function gebeBewertungAb({
  anfrage,
  kommentar,
  sterneBewertungen,
}) {
  const bewertung = {
    bewertungKundeId: anfrage.auftrag.kunde.id,
    bewertungAnfrageId: anfrage.id,
    bewertungHandwerkerId: anfrage.handwerker.id,
    auftragsTitel: anfrage.auftrag.beschreibung,
    kundeAnzeigeName: `${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname[0]}.`,
    kommentar,
    kategorien: KATEGORIEN.map((k) => ({
      ...k,
      sterne: sterneBewertungen[k.key],
    })),
  };

  const errors = validiereBewertung(bewertung);

  if (errors.length > 0) {
    return errors;
  }

  try {
    let response = await erstelleBewertung(bewertung);
    if (response && response.errors) {
      return response.errors;
    }
    return [];
  } catch (error) {
    return [
      'Leider ist etwas schief gelaufen, bitte probieren Sie es später erneut.',
    ];
  }
}

export function validiereBewertung(bewertung) {
  const errors = [];

  if (!sindSterneInAllenKategorienGesetzt(bewertung.kategorien)) {
    errors.push('Bitte bewerten Sie alle Kategorien.');
  }

  if (!bewertung.kommentar) {
    errors.push('Bitte geben Sie einen Kommentar ab.');
  }

  return errors;
}

export function sindSterneInAllenKategorienGesetzt(kategorien) {
  return (
    Array.isArray(kategorien) &&
    kategorien.length === KATEGORIEN.length &&
    kategorien.every(
      (sterneBewertung) =>
        1 <= sterneBewertung.sterne && sterneBewertung.sterne <= 5
    )
  );
}

export async function ladeBewertungenZuHandwerkerliste(handwerkerliste) {
  const {
    listBewertungen: { items: bewertungen },
  } = await recursiveQuery({
    query: queries.listBewertungen,
    variables: {
      filter: {
        freigegeben: {
          eq: true,
        },
        or: handwerkerliste.map((handwerker) => ({
          bewertungHandwerkerId: {
            eq: handwerker.id,
          },
        })),
      },
    },
  });

  return bewertungen;
}

export async function ladeBewertungenZuHandwerker(hwkId) {
  const {
    listBewertungen: { items: bewertungen },
  } = await recursiveQuery({
    query: queries.listBewertungen,
    variables: {
      filter: {
        bewertungHandwerkerId: {
          eq: hwkId,
        },
        freigegeben: {
          eq: true,
        },
      },
    },
  });

  return bewertungen;
}

async function erstelleBewertung(bewertung) {
  return API.graphql(
    graphqlOperation(mutations.executeCommand, {
      command: 'gebeBewertungAb',
      arguments: JSON.stringify({
        bewertung,
      }),
    })
  );
}

export function berechneDurchschnitt(bewertungen) {
  if (bewertungen.length === 0) {
    return 0;
  }

  const summeBewertungen = bewertungen.reduce((aktuelleSumme, bewertung) => {
    const summe = bewertung.kategorien.reduce(
      (summeKategorien, kategorie) => summeKategorien + kategorie.sterne,
      0
    );
    return aktuelleSumme + summe / bewertung.kategorien.length;
  }, 0);
  return summeBewertungen / bewertungen.length;
}

export function berechneDurchschnittFuerKategorie(bewertungen, kategorieKey) {
  if (bewertungen.length === 0) {
    return 0;
  }

  const summeKategorie = bewertungen.reduce((aktuelleSumme, bewertung) => {
    const summe = bewertung.kategorien.reduce(
      (summeKategorien, kategorie) =>
        kategorie.key === kategorieKey
          ? summeKategorien + kategorie.sterne
          : summeKategorien,
      0
    );
    return aktuelleSumme + summe;
  }, 0);
  return summeKategorie / bewertungen.length;
}
