import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import { Table, Row, HeaderCell } from '../../../components/Table/Table';
import Anfrage from '../../../components/Anfrage/Anfrage';
import KeineAnfragenGefundenPlatzhalter from '../../../components/KeineAnfragenGefundenPlatzhalter/KeineAnfragenGefundenPlatzhalter';
import './OffeneAuftraege.scss';
import { AnfragenActionWrapper } from '../../../components/Anfrage/Anfrage';
import { useOffeneAnfragen } from '../../../hooks/useAnfragen';

export default () => {
  const anfragen = useOffeneAnfragen();

  return (
    <>
      <Navbar />
      <div className="offeneAuftraege">
        {anfragen && anfragen.length === 0 ? (
          <KeineAnfragenGefundenPlatzhalter
            title="Sie haben aktuell keine offenen Aufträge"
            subtext="In dieser Übersicht werden alle Ihre offenen Aufträge angezeigt. Sobald Sie eine Anfrage annehmen, wird diese hier dargestellt."
          />
        ) : (
          <Table>
            <Row>
              <HeaderCell>Auftrag</HeaderCell>
              <HeaderCell>Letzte Aktualisierung</HeaderCell>
              <HeaderCell>Auftraggeber</HeaderCell>
              <HeaderCell>Ort</HeaderCell>
              <HeaderCell>Status</HeaderCell>
            </Row>
            <AnfragenActionWrapper autor="HANDWERKER">
              {(handleAnfrageClick) =>
                anfragen.map((anfrage) => (
                  <Anfrage
                    autor="HANDWERKER"
                    key={anfrage.id}
                    anfrage={anfrage}
                    spalten={[
                      'auftrag',
                      'letzteAktualisierung',
                      'auftraggeber',
                      'ort',
                      'status',
                    ]}
                    onAnfrageClick={handleAnfrageClick}
                  />
                ))
              }
            </AnfragenActionWrapper>
          </Table>
        )}
      </div>
    </>
  );
};
