import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import './SindSieProvinzialKundeStoerer.scss';
import { gtmEvent } from '../../googleAnalytics';
import { useHistory } from 'react-router-dom';

export function SindSieProvinzialKundeStoerer() {
  const history = useHistory();
  const handleClick = (istProvinzialKunde) => {
    gtmEvent({
      category: 'landingpage',
      action: 'kundenstatus',
      label: 'ist_provinzial_kunde',
      value: istProvinzialKunde ? '1' : '0',
    });
    history.push('/');
  };
  const actions = (
    <>
      <Button
        color="yellow"
        size="extraLarge"
        onClick={() => handleClick(false)}
        icon={<Back />}
        iconPosition="right"
      >
        Nein
      </Button>
      <Button
        color="yellow"
        size="extraLarge"
        onClick={() => handleClick(true)}
        icon={<Back />}
        iconPosition="right"
      >
        Ja
      </Button>
    </>
  );

  return (
    <Modal
      className="modal--dialog stoerer modal--wide modal--center"
      actions={actions}
      closeable={false}
      gaTrackingName="Sind Sie Provinzialkunde?"
    >
      <h1>
        Sind Sie Kunde der
        <br />
        Provinzial Rheinland Versicherung?
      </h1>
    </Modal>
  );
}
