import React from 'react';
import logo from '../../assets/images/Mein_Zuhause_fb3ed55e6b.svg';
import logo2 from '../../assets/images/Provinzial_ebad559604.svg';
import classNames from 'classnames';
import './ProvinzialLogo.scss';

export default function ({ className }) {
  return (
    <div className={classNames('provinzialLogo', className)}>
      <img
        src={logo}
        className="provinzialLogo__image"
        border={0}
        alt="Provinzial Logo"
      />
      <img
        src={logo2}
        className="provinzialLogo__image"
        border={0}
        alt="Provinzial Logo"
      />
    </div>
  );
}
