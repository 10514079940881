import { zuGewerk } from './gewerk';
import {
  EVENT_TYPE_HANDWERKER_ANGEMELDET,
  EVENT_TYPE_HANDWERKER_PROFIL_AKTUALISIERT,
  EVENT_TYPE_HANDWERKER_GELOESCHT,
} from '../persistence/eventDispatcherImpl';
import { currentDateTimeAsISOString } from '../dateTime';
import { mitBerechtigung, fuerHandwerker } from './eventDispatcher';
import {
  transformiereGewaehlteLeistungenInGewerkeUndLeistungen,
  langleistungZuKurzleistung,
  leistungenFuerGewerk,
} from './leistung';
import {
  distanzInKilometer,
  ermittleGeokoordinaten,
  hatLatLong,
} from '../geocode';

export const UNBESTAETIGT = 'UNBESTAETIGT';
export const PROFIL_UNVOLLSTAENDIG = 'PROFIL_UNVOLLSTAENDIG';
export const PROFIL_VOLLSTAENDIG = 'PROFIL_VOLLSTAENDIG';

export default class Handwerkerverzeichnis {
  constructor(auftraege, handwerkerRepository, eventDispatcher) {
    this.auftraege = auftraege;
    this.handwerkerRepository = handwerkerRepository;
    this.eventDispatcher = eventDispatcher;
  }

  async aktualisiereHandwerker(input) {
    const aktualisierterBereinigterHandwerker = {
      ...this.bereinigeLeereOptionaleFelder(
        this.konsolidiereHandwerkerdaten(
          input.urspruenglicherHandwerker,
          input.aktualisierterHandwerker
        )
      ),
    };

    if (aktualisierterBereinigterHandwerker.gewaehlteLeistungen) {
      const {
        gewerke,
        leistungen,
      } = transformiereGewaehlteLeistungenInGewerkeUndLeistungen(
        aktualisierterBereinigterHandwerker.gewaehlteLeistungen
      );
      aktualisierterBereinigterHandwerker.gewerke = gewerke;
      aktualisierterBereinigterHandwerker.leistungen = leistungen;
    }

    if (
      aktualisierterBereinigterHandwerker.strasse ||
      aktualisierterBereinigterHandwerker.plz ||
      aktualisierterBereinigterHandwerker.ort
    ) {
      const position = await ermittleGeokoordinaten({
        strasse: input.aktualisierterHandwerker.strasse,
        plz: input.aktualisierterHandwerker.plz,
        ort: input.aktualisierterHandwerker.ort,
      });

      aktualisierterBereinigterHandwerker.lat = position ? position.lat : null;
      aktualisierterBereinigterHandwerker.long = position
        ? position.long
        : null;
    }

    if (Array.isArray(aktualisierterBereinigterHandwerker.filialen)) {
      for (const filiale of aktualisierterBereinigterHandwerker.filialen) {
        const position = await ermittleGeokoordinaten({
          strasse: filiale.strasse,
          plz: filiale.plz,
          ort: filiale.ort,
        });
        filiale.lat = position ? position.lat : null;
        filiale.long = position ? position.long : null;
      }
    }

    const aktualisierterHandwerker = await this.handwerkerRepository.aktualisiereHandwerker(
      {
        urspruenglicherHandwerker: input.urspruenglicherHandwerker,
        handwerker: aktualisierterBereinigterHandwerker,
        firmenlogoFileContainers: input.firmenlogoFileContainers,
        agbFileContainers: input.agbFileContainers,
        datenschutzbestimmungenFileContainers:
          input.datenschutzbestimmungenFileContainers,
      }
    );

    this.eventDispatcher.fireEvent(
      mitBerechtigung(
        {
          type: EVENT_TYPE_HANDWERKER_PROFIL_AKTUALISIERT,
          payload: JSON.stringify({
            handwerkerId: input.urspruenglicherHandwerker.id,
          }),
        },
        fuerHandwerker(input.urspruenglicherHandwerker)
      )
    );

    return aktualisierterHandwerker;
  }

  async setzeProfilVollstaendig(handwerkerId) {
    await this.aktualisiereHandwerker({
      urspruenglicherHandwerker: {
        id: handwerkerId,
      },
      aktualisierterHandwerker: {
        id: handwerkerId,
        status: PROFIL_VOLLSTAENDIG,
      },
    });
  }

  async holeHandwerkerZuId(handwerkerId) {
    return this.handwerkerRepository.holeHandwerkerZuId(handwerkerId);
  }

  async istHandwerkerLoeschbar(handwerkerId) {
    return !(await this.auftraege.existierenNichtBeendeteAnfragenMitAngebotZuHandwerkerId(
      handwerkerId
    ));
  }

  async loescheHandwerkerdaten(handwerker, grund) {
    await this.handwerkerRepository.speichereKontoloeschgrund(grund);

    await this.handwerkerRepository.aktualisiereHandwerker({
      urspruenglicherHandwerker: handwerker,
      handwerker: this.ueberschreibeLoeschbareHandwerkerFelder(handwerker),
    });

    await this.meldeHandwerkerGeloescht(handwerker, grund);
  }

  async findeHandwerker(spezifikation) {
    const leistungen = zuLeistungen(spezifikation);
    const region = zuRegion(spezifikation);
    return this.handwerkerRepository.findeHandwerkerlisteZu(leistungen, region);
  }

  async meldeHandwerkerAngemeldet(handwerker) {
    return this.eventDispatcher.fireEvent(
      mitBerechtigung(
        {
          type: EVENT_TYPE_HANDWERKER_ANGEMELDET,
          payload: JSON.stringify({
            handwerkerId: handwerker.id,
          }),
        },
        fuerHandwerker(handwerker)
      )
    );
  }

  async meldeHandwerkerGeloescht(handwerker, grund) {
    return this.eventDispatcher.fireEvents([
      {
        type: EVENT_TYPE_HANDWERKER_GELOESCHT,
        payload: JSON.stringify({
          handwerker,
          grund,
          loeschdatum: currentDateTimeAsISOString(),
        }),
      },
    ]);
  }

  alleStandorte(handwerker) {
    return [
      { name: handwerker.ort, lat: handwerker.lat, long: handwerker.long },
    ].concat(handwerker.filialen ? handwerker.filialen : []);
  }

  naechsterStandort(positionAuftragsadresse, handwerker) {
    const standorteMitGeokoordinaten = this.alleStandorte(handwerker).filter(
      hatLatLong
    );

    if (
      !hatLatLong(positionAuftragsadresse) ||
      standorteMitGeokoordinaten.length === 0
    ) {
      return { name: handwerker.ort };
    }

    return standorteMitGeokoordinaten.reduce(
      (naechsterStandort, standort) => {
        const entfernung = distanzInKilometer(
          {
            lat: standort.lat,
            long: standort.long,
          },
          positionAuftragsadresse
        );
        if (entfernung < naechsterStandort.entfernung) {
          return {
            entfernung,
            name: standort.name,
          };
        }
        return naechsterStandort;
      },
      { entfernung: Number.MAX_SAFE_INTEGER, name: '' }
    );
  }

  ueberschreibeLoeschbareHandwerkerFelder(handwerker) {
    return {
      ...Object.keys(handwerker).reduce((felder, feld) => {
        return { ...felder, [feld]: null };
      }, {}),
      id: handwerker.id,
      firmenname: handwerker.firmenname,
      gewerke: [],
      regionen: [],
      gewaehlteLeistungen: [],
      geloescht: true,
      createdAt: '1970-01-01T00:00:00.000Z',
      updatedAt: '1970-01-01T00:00:00.000Z',
    };
  }

  konsolidiereHandwerkerdaten(
    urspruenglicherHandwerker,
    geaenderterHandwerker
  ) {
    const keysUrspruenglicherHandwerker = Object.keys(
      urspruenglicherHandwerker
    );
    const keysGeaenderterHandwerker = Object.keys(geaenderterHandwerker);
    const konsolidierterHandwerker = { id: urspruenglicherHandwerker.id };

    keysUrspruenglicherHandwerker.forEach((attribut) => {
      if (
        geaenderterHandwerker[attribut] !== undefined &&
        geaenderterHandwerker[attribut] !== urspruenglicherHandwerker[attribut]
      ) {
        konsolidierterHandwerker[attribut] = geaenderterHandwerker[attribut];
      }
    });

    keysGeaenderterHandwerker.forEach((attribut) => {
      if (urspruenglicherHandwerker[attribut] === undefined) {
        konsolidierterHandwerker[attribut] = geaenderterHandwerker[attribut];
      }
    });

    return konsolidierterHandwerker;
  }

  bereinigeLeereOptionaleFelder(benutzer) {
    const optionaleFelder = [
      'mobil',
      'telefonischeErreichbarkeit',
      'webseite',
      'firmengruendung',
      'anzahlMitarbeiter',
      'firmenlogo',
    ];

    return optionaleFelder.reduce((benutzer, optionalesFeld) => {
      if (benutzer[optionalesFeld] === '') {
        return { ...benutzer, [optionalesFeld]: null };
      }
      return benutzer;
    }, benutzer);
  }
}

function zuRegion(spezifikation) {
  const plz = spezifikation['PLZ'] || '';
  return plz.substring(0, 3);
}

function zuLeistungen(spezifikation) {
  const leistung = langleistungZuKurzleistung(spezifikation['taetigkeit']);

  if (leistung) {
    return [leistung];
  }

  const gewerk = zuGewerk(spezifikation);

  if (gewerk) {
    return leistungenFuerGewerk(gewerk);
  }

  throw new Error(
    `Es konnten keine Leistungen zur Spezifikation ermittelt werden: ${JSON.stringify(
      spezifikation
    )}`
  );
}
