import React from 'react';
import classNames from 'classnames';
import './Table.scss';

const Table = (props) => (
  <div className={classNames('table', props.className)}>{props.children}</div>
);

const Row = ({ className, ...props }) => (
  <div className={classNames('table__row', className)} {...props}>
    {props.children}
  </div>
);

const Cell = ({ className, ...props }) => (
  <div className={classNames('table__cell', className)} {...props}>
    {props.children}
  </div>
);

const HeaderCell = (props) => (
  <div className={classNames('table__headerCell', props.className)}>
    {props.children}
  </div>
);

export { Table, Row, HeaderCell, Cell };
