import {
  ANFRAGESTATUS_ABGESCHLOSSEN,
  ANFRAGESTATUS_ABRECHNUNG,
  ANFRAGESTATUS_ANFRAGE_ABGELEHNT,
  ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN,
  ANFRAGESTATUS_ANFRAGEKLAERUNG,
  ANFRAGESTATUS_ANGEBOTSKLAERUNG,
  ANFRAGESTATUS_AUFTRAGSABNAHME,
  ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG,
  ANFRAGESTATUS_GESENDET,
  ANFRAGESTATUS_WIDERRUFEN,
} from './anfrage';
import {
  ANGEBOTSTATUS_ABGELEHNT,
  ANGEBOTSTATUS_OFFEN,
  ANGEBOTSTATUS_WIDERRUFEN,
} from './angebot';
import {
  EVENT_TYPE_ANFRAGE_ABGELEHNT,
  EVENT_TYPE_ANFRAGE_ANGENOMMEN,
  EVENT_TYPE_ANFRAGE_GESENDET,
  EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN,
  EVENT_TYPE_ANGEBOT_ABGELEHNT,
  EVENT_TYPE_ANGEBOT_ANGENOMMEN,
  EVENT_TYPE_ANGEBOT_GESENDET,
  EVENT_TYPE_AUFTRAG_AN_CONCIERGE_GESENDET,
  EVENT_TYPE_AUFTRAG_WIDERRUFEN,
  EVENT_TYPE_AUFTRAGSABNAHME_ABGELEHNT,
  EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT,
  EVENT_TYPE_AUFTRAGSABNAHME_GESTARTET,
  EVENT_TYPE_NACHRICHT_GESENDET,
  EVENT_TYPE_PROBLEM_GEMELDET,
  EVENT_TYPE_RECHNUNG_GESENDET,
  EVENT_TYPE_BEWERTUNG_ANGEFORDERT,
  EVENT_TYPE_ZAHLUNGSEINGANG_BESTAETIGT,
} from '../persistence/eventDispatcherImpl';
import { fuerHandwerker, fuerKunde, mitBerechtigung } from './eventDispatcher';
import { currentDateTimeAsISOString } from '../dateTime';
import { recursiveQuery } from '../persistence/query';
import * as queries from '../graphql/queries';
import { unmarshallAnfragen } from '../persistence/marshall';

const MWST_SATZ = 1.19;

export const CONCIERGE_SERVICE_GRUND = {
  ALLE_ANFRAGEN_ZU_AUFTRAG_ABGEBROCHEN: 'ALLE_ANFRAGEN_ZU_AUFTRAG_ABGEBROCHEN',
  HANDWERKER_REAGIEREN_NICHT: 'HANDWERKER_REAGIEREN_NICHT',
  KEINE_HANDWERKER_GEFUNDEN: 'KEINE_HANDWERKER_GEFUNDEN',
};

export default class Auftraege {
  auftragRepository;

  constructor(auftragRepository, eventDispatcher) {
    this.auftragRepository = auftragRepository;
    this.eventDispatcher = eventDispatcher;
  }

  async holeAuftraege() {
    return this.auftragRepository.holeAuftraege();
  }

  async holeAnfragen() {
    return this.auftragRepository.holeAnfragen();
  }

  async holeAnfragenByHwkId(hwkId) {
    const { anfragenByGesendetAn } = await recursiveQuery({
      query: queries.anfragenByGesendetAn,
      variables: {
        gesendetAn: hwkId,
        sortDirection: 'DESC',
      },
    });

    return unmarshallAnfragen(anfragenByGesendetAn);
  }

  async holeAnfragenByKundenId(benutzerId) {
    const { anfragenByGesendetVon } = await recursiveQuery({
      query: queries.anfragenByGesendetVon,
      variables: {
        gesendetVon: benutzerId,
        sortDirection: 'DESC',
      },
    });

    return unmarshallAnfragen(anfragenByGesendetVon);
  }

  async sendeAuftragOhneGefundeneHandwerkerAnConcierge(auftrag, kunde) {
    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAuftragAnConciergeGesendetEvent({
          auftrag: { ...auftrag, kunde },
          grund: CONCIERGE_SERVICE_GRUND.KEINE_HANDWERKER_GEFUNDEN,
        }),
        fuerKunde(kunde)
      )
    );
  }

  async legeAuftragUndAnfragenAn(auftrag, kunde, handwerkerliste) {
    const auftragUndAnfragen = await this.auftragRepository.erzeugeAuftragUndAnfragen(
      auftrag,
      kunde,
      handwerkerliste
    );

    for (const anfrage of auftragUndAnfragen.anfragen) {
      await this.eventDispatcher.fireEvent(
        mitBerechtigung(
          this._erzeugeAnfrageGesendetEvent(anfrage),
          fuerKunde(kunde),
          fuerHandwerker(anfrage)
        )
      );
    }

    return auftragUndAnfragen;
  }

  async nimmAnfrageAn(anfrage) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_ANFRAGEKLAERUNG,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAnfrageAngenommenEvent(aktualisierteAnfrage),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );

    return aktualisierteAnfrage;
  }

  async lehneAnfrageAb(anfrage, ablehnungsgrund) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_ANFRAGE_ABGELEHNT,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAnfrageAbgelehntEvent(
          aktualisierteAnfrage,
          ablehnungsgrund
        ),
        fuerKunde(anfrage),
        fuerHandwerker(anfrage)
      )
    );
  }

  async zieheAnfrageZurueck(anfrage, ablehnungsgrund) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAnfrageZurueckgezogenEvent(
          aktualisierteAnfrage,
          ablehnungsgrund
        ),
        fuerKunde(anfrage),
        fuerHandwerker(anfrage)
      )
    );
    return aktualisierteAnfrage;
  }

  async sendeNachricht(autor, anfrage, nachrichtentext, datei = undefined) {
    const nachricht = await this.auftragRepository.fuegeNachrichtHinzu(
      anfrage,
      {
        autor,
        text: nachrichtentext,
        gelesen: false,
      },
      datei
    );

    await this._setzeAnfrageaktualisierungsdatum(anfrage.id);
    if (autor !== 'SYSTEM') {
      await this.eventDispatcher.fireEvent(
        mitBerechtigung(
          this._erzeugeNachrichtGesendetEvent(anfrage, nachricht),
          fuerKunde(anfrage),
          fuerHandwerker(anfrage)
        )
      );
    }

    return nachricht;
  }

  async sendeAngebot(anfrage, datei, rechnungsbetrag) {
    const netto = rechnungsbetrag;
    const brutto = Math.round(netto * MWST_SATZ * 100) / 100;

    const gesendetesAngebot = await this.auftragRepository.sendeAngebot(
      anfrage,
      datei,
      netto,
      brutto,
      ANGEBOTSTATUS_OFFEN
    );

    const aktualisierteAnfrage = await this.auftragRepository.holeAnfrageZuId(
      anfrage.id
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        {
          type: EVENT_TYPE_ANGEBOT_GESENDET,
          ...genericEventDaten(aktualisierteAnfrage, {
            angebot: gesendetesAngebot,
          }),
        },
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );

    return gesendetesAngebot;
  }

  async lehneAngebotAb(anfrage, angebot) {
    const aktualisiertesAngebot = await this.auftragRepository.aktualisiereAngebot(
      {
        ...angebot,
        status: ANGEBOTSTATUS_ABGELEHNT,
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAngebotAbgelehntEvent(anfrage, aktualisiertesAngebot),
        fuerKunde(anfrage),
        fuerHandwerker(anfrage)
      )
    );

    return aktualisiertesAngebot;
  }

  async nimmAngebotAn(anfrage, angebot) {
    const aktualisiertesAngebot = await this.auftragRepository.nimmAngebotAn(
      anfrage,
      angebot
    );
    const aktualisierteAnfrage = await this.auftragRepository.holeAnfrageZuId(
      anfrage.id
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAngebotAngenommenEvent(
          aktualisierteAnfrage,
          aktualisiertesAngebot
        ),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );

    return aktualisiertesAngebot;
  }

  async sendeRechnung(anfrage, datei, rechnungsbetrag) {
    const netto = rechnungsbetrag;
    const brutto = Math.round(netto * MWST_SATZ * 100) / 100;
    const gesendeteRechnung = await this.auftragRepository.sendeRechnung(
      anfrage,
      datei,
      netto,
      brutto
    );

    const aktualisierteAnfrage = await this.auftragRepository.holeAnfrageZuId(
      anfrage.id
    );

    await this.eventDispatcher.fireEvents([
      mitBerechtigung(
        {
          type: EVENT_TYPE_RECHNUNG_GESENDET,
          ...genericEventDaten(aktualisierteAnfrage, {
            rechnung: gesendeteRechnung,
          }),
        },
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      ),
      mitBerechtigung(
        {
          type: EVENT_TYPE_BEWERTUNG_ANGEFORDERT,
          ...genericEventDaten(aktualisierteAnfrage),
        },
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      ),
    ]);

    return gesendeteRechnung;
  }

  async widerrufeAuftrag(anfrage, angebot, hatArbeitenDurchgefuehrt) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: hatArbeitenDurchgefuehrt
          ? ANFRAGESTATUS_ABRECHNUNG
          : ANFRAGESTATUS_WIDERRUFEN,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    const aktualisiertesAngebot = await this.auftragRepository.aktualisiereAngebot(
      {
        ...angebot,
        status: ANGEBOTSTATUS_WIDERRUFEN,
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAuftragWiderrufenEvent(
          aktualisierteAnfrage,
          aktualisiertesAngebot
        ),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );
  }

  async markiereNachrichtAlsGelesen(nachricht, autor) {
    if (nachricht.gelesen || nachricht.autor === autor) {
      return;
    }

    if (!nachricht.gelesen) {
      await this.auftragRepository.aktualisiereNachricht({
        id: nachricht.id,
        gelesen: true,
      });
    }
  }

  async markiereAnfrageAlsGesehen(anfrage, rolle) {
    await this.auftragRepository.aktualisiereAnfrage({
      id: anfrage.id,
      [rolle === 'HANDWERKER'
        ? 'zuletztGesehenVonHandwerker'
        : 'zuletztGesehenVonKunde']: currentDateTimeAsISOString(),
    });
  }

  async holeEventsZuAnfrageNach(anfrage, isoDatum) {
    return this.auftragRepository.holeEventsZuAnfrageNach(anfrage, isoDatum);
  }

  async loescheAuftrag(auftrag) {
    await this.auftragRepository.loescheAuftrag(auftrag);
  }

  async starteAuftragsabnahme(anfrage) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_AUFTRAGSABNAHME,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAuftragsabnahmeGestartetEvent(aktualisierteAnfrage),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );
  }

  async lehneAuftragsabnahmeAb(anfrage) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAuftragsabnahmeAbgelehntEvent(aktualisierteAnfrage),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );
  }

  async bestaetigeAuftragsabnahme(anfrage) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_ABRECHNUNG,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAuftragsabnahmeBestaetigtEvent(aktualisierteAnfrage),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );
  }

  async meldeProblem(anfrage) {
    const events = await this.auftragRepository.holeEventsZuAnfrageId(
      anfrage.id
    );
    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeProblemGemeldetEvent(anfrage, events),
        fuerKunde(anfrage),
        fuerHandwerker(anfrage)
      )
    );
  }

  async bestaetigeZahlungseingang(anfrage) {
    const aktualisierteAnfrage = await this.auftragRepository.aktualisiereAnfrage(
      {
        id: anfrage.id,
        status: ANFRAGESTATUS_ABGESCHLOSSEN,
        imStatusSeit: currentDateTimeAsISOString(),
      }
    );

    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeZahlungseingangBestaetigtEvent(aktualisierteAnfrage),
        fuerKunde(aktualisierteAnfrage),
        fuerHandwerker(aktualisierteAnfrage)
      )
    );

    return aktualisierteAnfrage;
  }

  async existierenAnfragenInAusfuehrungZuKundeId(kundeId) {
    const auftraege = await this.auftragRepository.holeAuftraegeZuKundeId(
      kundeId
    );

    for (const auftrag of auftraege) {
      const anfragen = await this.auftragRepository.holeAnfragenZuAuftragId(
        auftrag.id
      );

      for (const anfrage of anfragen) {
        if (anfrage.wirdAusgefuehrt()) {
          return false;
        }
      }
    }

    return true;
  }

  async existierenNichtBeendeteAnfragenMitAngebotZuHandwerkerId(handwerkerId) {
    const anfragen = await this.auftragRepository.holeAnfragenZuHandwerkerId(
      handwerkerId
    );

    for (const anfrage of anfragen) {
      const hasAngebot = anfrage.angebote.length > 0;
      if (hasAngebot && !anfrage.wurdeBeendet()) {
        return true;
      }
    }

    return false;
  }

  async kannAuftragGeloeschtWerden(auftrag, anfragen) {
    return anfragen
      .filter((a) => a.anfrageAuftragId === auftrag.id)
      .every((anfrage) => anfrage.kannGeloeschtWerden());
  }

  fortgeschrittensterAnfragestatus(anfragen) {
    const hatStatus = (status) => (a) => a.status === status;

    const fortgeschrittensterAnfragenstatus = (status) =>
      anfragen.some(hatStatus(status));

    return [
      ANFRAGESTATUS_ABGESCHLOSSEN,
      ANFRAGESTATUS_ABRECHNUNG,
      ANFRAGESTATUS_AUFTRAGSABNAHME,
      ANFRAGESTATUS_AUFTRAGSAUSFUEHRUNG,
      ANFRAGESTATUS_ANGEBOTSKLAERUNG,
      ANFRAGESTATUS_ANFRAGEKLAERUNG,
      ANFRAGESTATUS_GESENDET,
      ANFRAGESTATUS_WIDERRUFEN,
      ANFRAGESTATUS_ANFRAGE_ZURUECKGEZOGEN,
      ANFRAGESTATUS_ANFRAGE_ABGELEHNT,
    ].find(fortgeschrittensterAnfragenstatus);
  }

  auftragsstatus(anfragen) {
    const anfrageStatus = this.fortgeschrittensterAnfragestatus(anfragen);
    const weitesteAnfrage = anfragen.find(
      (anfrage) => anfrage.status === anfrageStatus
    );
    return weitesteAnfrage.fortschritt();
  }

  async leiteAnConciergeServiceWeiter({
    auftrag,
    grund,
    anfrageIds = undefined,
  }) {
    await this._sendeAuftragAnConcierge({ auftrag, grund, anfrageIds });

    await this.auftragRepository.aktualisiereAuftrag({
      ...auftrag,
      anConciergeServiceWeitergeleitetAm: new Date().toISOString(),
    });
  }

  async _sendeAuftragAnConcierge({ auftrag, grund, anfrageIds = undefined }) {
    await this.eventDispatcher.fireEvent(
      mitBerechtigung(
        this._erzeugeAuftragAnConciergeGesendetEvent({
          auftrag,
          grund,
          anfrageIds,
        }),
        fuerKunde(auftrag.kunde)
      )
    );
  }

  async _setzeAnfrageaktualisierungsdatum(anfrageId) {
    return this.auftragRepository.aktualisiereAnfrage({
      id: anfrageId,
    });
  }

  _erzeugeAnfrageGesendetEvent(anfrage) {
    return {
      type: EVENT_TYPE_ANFRAGE_GESENDET,
      ...genericEventDaten(anfrage),
    };
  }

  _erzeugeAuftragAnConciergeGesendetEvent({
    auftrag,
    grund,
    anfrageIds = undefined,
  }) {
    return {
      type: EVENT_TYPE_AUFTRAG_AN_CONCIERGE_GESENDET,
      payload: JSON.stringify({
        auftrag,
        grund,
        ...(anfrageIds ? { anfrageIds } : {}),
      }),
    };
  }

  _erzeugeAnfrageAngenommenEvent(anfrage) {
    return {
      type: EVENT_TYPE_ANFRAGE_ANGENOMMEN,
      ...genericEventDaten(anfrage),
    };
  }

  _erzeugeAnfrageAbgelehntEvent(anfrage, ablehnungsgrund) {
    return {
      type: EVENT_TYPE_ANFRAGE_ABGELEHNT,
      ...genericEventDaten(anfrage, { ablehnungsgrund }),
    };
  }

  _erzeugeAnfrageZurueckgezogenEvent(anfrage, ablehnungsgrund) {
    return {
      type: EVENT_TYPE_ANFRAGE_ZURUECKGEZOGEN,
      ...genericEventDaten(anfrage, { ablehnungsgrund }),
    };
  }

  _erzeugeNachrichtGesendetEvent(anfrage, nachricht) {
    return {
      type: EVENT_TYPE_NACHRICHT_GESENDET,
      ...genericEventDaten(anfrage, { nachricht }),
    };
  }

  _erzeugeAngebotAbgelehntEvent(anfrage, angebot) {
    return {
      type: EVENT_TYPE_ANGEBOT_ABGELEHNT,
      ...genericEventDaten(anfrage, { angebot }),
    };
  }

  _erzeugeAuftragWiderrufenEvent(anfrage, angebot) {
    return {
      type: EVENT_TYPE_AUFTRAG_WIDERRUFEN,
      ...genericEventDaten(anfrage, { angebot }),
    };
  }

  _erzeugeAngebotAngenommenEvent(anfrage, angebot) {
    return {
      type: EVENT_TYPE_ANGEBOT_ANGENOMMEN,
      ...genericEventDaten(anfrage, { angebot }),
    };
  }

  _erzeugeAuftragsabnahmeGestartetEvent(anfrage) {
    return {
      type: EVENT_TYPE_AUFTRAGSABNAHME_GESTARTET,
      ...genericEventDaten(anfrage),
    };
  }

  _erzeugeAuftragsabnahmeAbgelehntEvent(anfrage) {
    return {
      type: EVENT_TYPE_AUFTRAGSABNAHME_ABGELEHNT,
      ...genericEventDaten(anfrage),
    };
  }

  _erzeugeAuftragsabnahmeBestaetigtEvent(anfrage) {
    return {
      type: EVENT_TYPE_AUFTRAGSABNAHME_BESTAETIGT,
      ...genericEventDaten(anfrage),
    };
  }

  _erzeugeProblemGemeldetEvent(anfrage, events) {
    const umsetzungsdetails = zuUmsetzungdetails(anfrage.auftrag.spezifikation);
    return {
      type: EVENT_TYPE_PROBLEM_GEMELDET,
      ...genericEventDaten(anfrage, { umsetzungsdetails, events }),
    };
  }

  _erzeugeZahlungseingangBestaetigtEvent(anfrage) {
    return {
      type: EVENT_TYPE_ZAHLUNGSEINGANG_BESTAETIGT,
      ...genericEventDaten(anfrage),
    };
  }
}

function genericEventDaten(anfrage, payload = {}) {
  return {
    payload: JSON.stringify({
      anfrage,
      ...payload,
    }),
    anfrageId: anfrage.id,
    baseUrl: window.location.origin,
  };
}

export function zuUmsetzungdetails(spezifikation) {
  const umsetzungsdetails = { ...spezifikation };
  delete umsetzungsdetails['bereich'];
  delete umsetzungsdetails['objektart'];
  delete umsetzungsdetails['taetigkeit'];
  delete umsetzungsdetails['teiltaetigkeit'];
  delete umsetzungsdetails['Auftragsbeschreibung'];
  delete umsetzungsdetails['Sonstige Informationen'];
  delete umsetzungsdetails['ausfuehrungszeitraum'];
  delete umsetzungsdetails['speziellerAusfuehrungszeitraum'];
  delete umsetzungsdetails['Straße und Hausnummer'];
  delete umsetzungsdetails['PLZ'];
  delete umsetzungsdetails['Ort'];
  delete umsetzungsdetails['Adresszusatz'];
  delete umsetzungsdetails['Telefonnummer'];
  delete umsetzungsdetails['Kontaktwunsch'];
  delete umsetzungsdetails['Parkplatzsituation vor Ort'];
  delete umsetzungsdetails['Fotos, Grundriss'];
  return umsetzungsdetails;
}
