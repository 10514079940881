import React from 'react';
import './Select.scss';
import { v4 as uuid } from 'uuid';

export function Select({ label, options, validierungstext, ...props }) {
  const id = uuid();
  return (
    <>
      <label className="select__label" htmlFor={id} data-cy-label={label}>
        {label}
      </label>
      <select className="select__select" id={id} {...props}>
        {options.map((option, optionIndex) => (
          <option key={`option-${optionIndex}`} value={option}>
            {option}
          </option>
        ))}
      </select>
      {validierungstext && (
        <div
          data-cy-validierungstext={validierungstext}
          className="select__validierungstext"
        >
          {validierungstext}
        </div>
      )}
    </>
  );
}
