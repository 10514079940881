import React from 'react';
import { useModalState } from '../../hooks';
import AnfragedetailsModal from '../AnfragedetailsModal/AnfragedetailsModal';
import Link from '../Link/Link';
import './Auftragsdetails.scss';
import Ausfuehrungszeitraum from '../Ausfuerungszeitraum/Ausfuehrungszeitraum';

export default function Auftragsdetails({ anfrage }) {
  const [
    isAnfragedetailsModalShown,
    showAnfragedetailsModal,
    hideAnfragedetailsModal,
  ] = useModalState();

  const spezifikation = anfrage.auftrag.spezifikation;

  return (
    <section className="auftragsdetails">
      <h1 className="auftragsdetails__headline">Auftragsdetails</h1>
      <div className="auftragsdetails__status">
        <h2 className="auftragsdetails__header">Status</h2>
        <div className="auftragsdetails__content" data-cy-id="anfragestatus">
          {anfrage.statusAlsText()}
        </div>
      </div>
      <div className="auftragsdetails__aufuehrungszeitraum">
        <h2 className="auftragsdetails__header">
          Gewünschter Ausführungszeitraum
        </h2>
        <div className="auftragsdetails__content">
          <Ausfuehrungszeitraum spezifikation={spezifikation} />
        </div>
      </div>
      <div className="auftragsdetails__auftraggeber">
        <h2 className="auftragsdetails__header">Auftraggeber</h2>
        <div className="auftragsdetails__content">
          {anfrage.auftrag.kunde.vorname} {anfrage.auftrag.kunde.nachname}
          {spezifikation['Telefonnummer'] && (
            <>
              <br />
              Tel: {spezifikation['Telefonnummer']}
            </>
          )}
        </div>
      </div>
      <div className="auftragsdetails__auftragsstandort">
        <h2 className="auftragsdetails__header">Auftragsstandort</h2>
        <div className="auftragsdetails__content">
          {spezifikation['Straße und Hausnummer']}
          <br />
          {spezifikation['PLZ']} {spezifikation['Ort']}
          <br />
          {spezifikation['Adresszusatz'] && (
            <>
              {spezifikation['Adresszusatz']}
              <br />
            </>
          )}
          Parksituation vor Ort:
          <br />
          {spezifikation['Parkplatzsituation vor Ort']}
        </div>
      </div>
      {spezifikation.teiltaetigkeit && (
        <div className="auftragsdetails__taetigkeiten">
          <h2 className="auftragsdetails__header">Gewünschte Tätigkeiten</h2>
          <ul className="auftragsdetails__content auftragsdetails__taetigkeitenlist">
            {spezifikation.teiltaetigkeit.map((t) => (
              <li key={t}>
                <span className="auftragsdetails__taetigkeit">{t}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Link
        onClick={showAnfragedetailsModal}
        size="small"
        data-cy-id="auftragsdetails-oeffnen"
      >
        Alle Details anzeigen
      </Link>
      {isAnfragedetailsModalShown && (
        <AnfragedetailsModal
          hide={hideAnfragedetailsModal}
          anfrage={anfrage}
          auftrag={anfrage.auftrag}
        />
      )}
    </section>
  );
}
