import './Gewaehrleistungsteaser.scss';

import React from 'react';
import badgeIcon from '../../assets/icons/badge.svg';
import classNames from 'classnames';

export default ({ className }) => (
  <div className={classNames('gewaehrleistungsteaser', className)}>
    <img className="gewaehrleistungsteaser__icon" src={badgeIcon} alt="" />
    <div className="gewaehrleistungsteaser__text">
      <div className="gewaehrleistungsteaser__ueberschrift">
        Gewährleistung durch die Provinzial
      </div>
      <div className="gewaehrleistungsteaser__hinweis">
        Ihre Sicherheit ist uns wichtig! Damit Sie auch bei diesem Auftrag auf
        Nummer sicher gehen können, erhalten Sie kostenfrei eine 5-jährige
        Gewährleistung durch die Provinzial Dienstleistungsgesellschaft mbH auf
        die von Ihnen beauftragte Handwerkerleistung. Detaillierte Informationen
        zur Gewährleistung finden Sie in Artikel 7 unserer&nbsp;
        <a
          href="/rechtliches"
          target="_blank"
          rel="noopener noreferrer"
          className="gewaehrleistungsteaser__link"
        >
          AGB
        </a>
        .
      </div>
    </div>
  </div>
);
