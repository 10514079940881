import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

import HandwerkerRepository from '../domain/handwerkerRepository';
import { uploadFile, toS3Object } from './uploadFile';
import { unmarshallHandwerkerliste } from './marshall';
import { recursiveQuery } from './query';

export default class HandwerkerRepositoryImpl extends HandwerkerRepository {
  async holeHandwerkerZuId(handwerkerId) {
    const {
      data: { getHandwerker },
    } = await API.graphql(
      graphqlOperation(queries.getHandwerker, {
        id: handwerkerId,
      })
    );
    return getHandwerker;
  }

  async findeHandwerkerlisteZu(leistungen = [], region) {
    const { listHandwerker } = await recursiveQuery({
      query: queries.listHandwerker,
      variables: {
        filter: {
          or: leistungen.map((leistung) => ({
            leistungen: { contains: leistung },
          })),
          regionen: {
            contains: region,
          },
          and: [
            {
              or: [
                {
                  datenschutzbestimmungenTyp: {
                    eq: 'Datenschutzbestimmungen als Link',
                  },
                },
                {
                  datenschutzbestimmungenTyp: {
                    eq: 'Datenschutzbestimmungen als PDF',
                  },
                },
              ],
            },
            {
              or: [
                {
                  impressumTyp: {
                    eq: 'Impressum als Text',
                  },
                },
                {
                  impressumTyp: {
                    eq: 'Impressum als Link',
                  },
                },
              ],
            },
          ],
        },
      },
    });

    return unmarshallHandwerkerliste(listHandwerker);
  }

  async speichereKontoloeschgrund(grund) {
    await API.graphql(
      graphqlOperation(mutations.createKontoloeschung, { input: { grund } })
    );
  }

  async loescheHandwerker(handwerker) {
    return API.graphql(
      graphqlOperation(mutations.deleteHandwerker, {
        input: { id: handwerker.id },
      })
    );
  }

  async aktualisiereHandwerker(input) {
    if (input.firmenlogoFileContainers) {
      const s3Objects = await uploadOrDeleteFileContainers(
        input.firmenlogoFileContainers
      );
      input.handwerker.firmenlogo =
        s3Objects.length === 1 ? s3Objects[0] : null;
    }
    if (input.agbFileContainers) {
      const s3Objects = await uploadOrDeleteFileContainers(
        input.agbFileContainers
      );
      input.handwerker.agb = s3Objects.length === 1 ? s3Objects[0] : null;
    }
    if (input.datenschutzbestimmungenFileContainers) {
      const s3Objects = await uploadOrDeleteFileContainers(
        input.datenschutzbestimmungenFileContainers
      );
      input.handwerker.datenschutzbestimmungen =
        s3Objects.length === 1 ? s3Objects[0] : null;
    }

    const {
      data: { updateHandwerker },
    } = await API.graphql(
      graphqlOperation(mutations.updateHandwerker, {
        input: input.handwerker,
      })
    );

    if (input.handwerker.email) {
      await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
        email: input.handwerker.email,
      });
    }

    return updateHandwerker;
  }
}

async function uploadOrDeleteFileContainers(fileContainers) {
  const deletedS3Objects = fileContainers
    .filter((fc) => fc.deleted && fc.s3Object)
    .map((fc) => fc.s3Object);
  const newFiles = fileContainers
    .filter((fc) => !fc.deleted && fc.file)
    .map((fc) => fc.file);
  const s3Objects = fileContainers
    .filter((fc) => !fc.deleted && fc.s3Object)
    .map((fc) => fc.s3Object);

  for (const deletedS3Object of deletedS3Objects) {
    await Storage.remove(deletedS3Object.key);
  }

  for (const newFile of newFiles) {
    const { key, fileName, mimeType } = await uploadFile(newFile);
    s3Objects.push(toS3Object(key, fileName, mimeType));
  }
  return s3Objects;
}
