import React, { useRef, useState } from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import { usePreview } from '../../hooks';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  validateFiles,
  getValidMimeTypes,
  getFilesFromEvent,
} from './validateFiles';

import {
  ACCEPTED_TYPES_PDF_ONLY,
  ACCEPTED_TYPES_IMAGES_ONLY,
} from './constants';

import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';

import './Dropzone.scss';

export default function Dropzone({
  fileContainers,
  className,
  isMultiple,
  acceptedTypes,
  dataCyId,
  onChange,
  validierungstext,
  maxFileSizeInMb,
}) {
  const dropzoneRef = useRef(null);
  const fileRef = useRef(null);
  const [isInvalidFileSelected, setInvalidFileSelected] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  const handleFileSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const validation = validateFiles(
      event,
      isMultiple,
      acceptedTypes,
      fileContainers,
      maxFileSizeInMb
    );
    if (validation.isValid) {
      setInvalidFileSelected(false);
      const droppedFiles = getFilesFromEvent(event);
      const droppedFileContainers = filesToFileContainers(droppedFiles);

      const newFileContainers = [
        ...fileContainers,
        ...droppedFileContainers.filter(notIn(fileContainers)),
      ];

      clearFileSelection(event);
      onChange(newFileContainers);
    } else {
      setInvalidFileSelected(true);
      setValidationMessage(validation.validationMessage);
      clearFileSelection(event);
      onChange(fileContainers);
    }
  };

  const handleDropzoneClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      !event.target.classList.contains('dropzone__deleteDropzoneContent') &&
      !(!isMultiple && fileContainers.length === 1)
    ) {
      fileRef.current.click();
    }
  };

  const createHandleDeleteFile = (fileContainer) => () => {
    const noDeletedNotUploadedFiles = (fc) =>
      fc.file || fc.s3Object ? !fc.deleted : true;
    onChange(
      fileContainers
        .map(markAsDeletedWhenEqualTo(fileContainer))
        .filter(noDeletedNotUploadedFiles)
    );

    setInvalidFileSelected(false);
  };

  const handleDrag = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const notDeletedFileContainers = fileContainers.filter(
    (fileContainer) => !fileContainer.deleted
  );
  return (
    <>
      <div
        className={classNames('dropzone__validation', className)}
        style={{ display: isInvalidFileSelected ? 'block' : 'none' }}
      >
        {validationMessage}
      </div>
      <div
        ref={dropzoneRef}
        className={classNames(
          'dropzone',
          {
            'dropzone--singlePdf':
              !isMultiple && acceptedTypes === ACCEPTED_TYPES_PDF_ONLY,
            'dropzone--singleImage':
              !isMultiple && acceptedTypes === ACCEPTED_TYPES_IMAGES_ONLY,
            'dropzone--invalide': validierungstext,
          },
          className
        )}
        onDrop={handleFileSelected}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDragEnter={handleDrag}
        onClick={handleDropzoneClick}
      >
        <input
          style={{ display: 'none' }}
          type="file"
          accept={getValidMimeTypes(acceptedTypes).join(',')}
          multiple={isMultiple}
          ref={fileRef}
          onChange={handleFileSelected}
          onClick={(event) => event.stopPropagation()}
          data-cy-id={dataCyId}
        />

        {notDeletedFileContainers.length > 0 && (
          <div className="dropzone__content">
            {notDeletedFileContainers.map((fileContainer, key) => (
              <DropzonePreview
                key={key}
                fileContainer={fileContainer}
                onDelete={createHandleDeleteFile(fileContainer)}
              />
            ))}
          </div>
        )}
        {(notDeletedFileContainers.length === 0 || isMultiple) && (
          <>
            <div className="dropzone__contentText dropzone__contentText--desktop">
              {`${acceptedTypes} ablegen oder`}&nbsp;
              <span className="dropzone__browse">durchsuchen</span>
            </div>
            <div className="dropzone__contentText dropzone__contentText--mobile">
              <span className="dropzone__browse">Durchsuchen</span>
            </div>
          </>
        )}
      </div>
      {validierungstext && (
        <div className="dropzone__validierungstext">{validierungstext}</div>
      )}
    </>
  );
}

export function DropzonePreview({ fileContainer, onDelete }) {
  const { previewUrl, fileName, isLoading } = usePreview(fileContainer);

  return (
    <div className="dropzone__preview">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <img className="dropzone__previewImage" src={previewUrl} alt="" />
      )}
      <div className="dropzone__fileName">{fileName}</div>
      <div className="dropzone__deleteFile">
        <Button
          icon={<DeleteIcon />}
          className="dropzone__deleteDropzoneContent"
          color="grey"
          outlined
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        />
      </div>
    </div>
  );
}

export function s3ObjectsToFileContainers(s3Objects) {
  return s3Objects.map((s3Object) => ({
    s3Object,
  }));
}

export function fileContainersToFiles(fileContainers) {
  return fileContainers
    .filter((fileContainer) => !fileContainer.deleted)
    .filter((fileContainer) => fileContainer.file)
    .map((fileContainer) => fileContainer.file);
}

export function filesToFileContainers(files) {
  return files.map((file) => ({
    file,
  }));
}

function clearFileSelection(event) {
  if (event.dataTransfer) {
    event.dataTransfer.clearData();
  } else if (event.target.files) {
    event.target.value = null;
  }
}

function notIn(existingFileContainers) {
  return (droppedFileContainer) =>
    !existingFileContainers.some(
      (fileContainer) =>
        fileContainer.file &&
        fileContainer.file.name === droppedFileContainer.file.name
    );
}

function markAsDeletedWhenEqualTo(fileContainer) {
  return (fc) => {
    const areEqualS3Objects =
      fc.s3Object &&
      fileContainer.s3Object &&
      fc.s3Object.key === fileContainer.s3Object.key;
    const areEqualFiles =
      fc.file && fileContainer.file && fc.file.name === fileContainer.file.name;

    if (areEqualS3Objects || areEqualFiles) {
      return {
        ...fc,
        deleted: true,
      };
    }
    return fc;
  };
}
