import React from 'react';
import { groupBy } from '../../collections';
import {
  Dateiuploadeingabe,
  Eingabengruppe,
  Freitexteingabe,
  Listenauswahleingabe,
  Mehrfachauswahleingabe,
  Texteingabe,
  Zeitraum,
} from './Eingabe';
import 'react-datepicker/dist/react-datepicker.css';
import { CheckBox, Radio, SofortauswahlButton, TextauswahlBox } from './Input';
import { Kontaktauswahl } from './Kontaktauswahl';

export function GemischteAuswahl({
  schritt,
  schrittIndex,
  spezifikation,
  waehleWert,
  waehleMehrfachauswahlwert,
}) {
  const gruppierteEingaben = groupBy(
    schritt.eingaben,
    (eingabe) => eingabe.gruppenIndex
  );
  return Array.from(gruppierteEingaben.keys()).map((gruppenIndex) => {
    const sollAngezeigtWerden = (eingabe) =>
      !eingabe.bedingung || eingabe.bedingung(schrittIndex, spezifikation);
    const anzuzeigendeEingabenDerGruppe = gruppierteEingaben
      .get(gruppenIndex)
      .filter(sollAngezeigtWerden);
    return (
      anzuzeigendeEingabenDerGruppe.length > 0 && (
        <Eingabengruppe
          key={`eingabengruppe-${gruppenIndex}`}
          gruppenIndex={gruppenIndex}
          gruppierteEingaben={gruppierteEingaben}
        >
          {anzuzeigendeEingabenDerGruppe.map((eingabe, eingabeIndex) => (
            <React.Fragment key={`eingabe-${eingabeIndex}`}>
              {eingabe.typ === 'text' && (
                <Texteingabe
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'mehrfachauswahl' && (
                <Mehrfachauswahleingabe
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleMehrfachauswahlwert={waehleMehrfachauswahlwert}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'listenauswahl' && (
                <Listenauswahleingabe
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'einfachauswahl' && (
                <Einfachauswahl
                  schritt={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'freitext' && (
                <Freitexteingabe
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'dateiupload' && (
                <Dateiuploadeingabe
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'zeitraum' && (
                <Zeitraum
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'kontaktauswahl' && (
                <Kontaktauswahl
                  eingabe={eingabe}
                  spezifikation={spezifikation}
                  waehleWert={waehleWert}
                />
              )}
              {eingabe.typ === 'leerraum' && <div />}
            </React.Fragment>
          ))}
        </Eingabengruppe>
      )
    );
  });
}

export function Sofortauswahl({
  schritt,
  spezifikation,
  waehleSofortauswahlwert,
}) {
  return schritt.auswahlmoeglichkeiten.map(
    (auswahlmoeglichkeit, auswahlIndex) => (
      <SofortauswahlButton
        key={`auswahlmoeglichkeit-${auswahlIndex}`}
        spezifikation={spezifikation}
        attribut={schritt.attribut}
        waehleSofortauswahlwert={waehleSofortauswahlwert}
        {...auswahlmoeglichkeit}
      />
    )
  );
}

export function Textauswahl({
  schritt,
  spezifikation,
  waehleSofortauswahlwert,
}) {
  return schritt.auswahlmoeglichkeiten.map(
    (auswahlmoeglichkeit, auswahlIndex) => (
      <TextauswahlBox
        key={`auswahlmoeglichkeit-${auswahlIndex}`}
        spezifikation={spezifikation}
        attribut={schritt.attribut}
        waehleSofortauswahlwert={waehleSofortauswahlwert}
        {...auswahlmoeglichkeit}
      />
    )
  );
}

export function Einfachauswahl({ schritt, spezifikation, waehleWert }) {
  return schritt.auswahlmoeglichkeiten.map(
    (auswahlmoeglichkeit, auswahlIndex) => (
      <Radio
        key={`auswahlmoeglichkeit-${auswahlIndex}`}
        spezifikation={spezifikation}
        attribut={schritt.attribut}
        waehleWert={waehleWert}
        {...auswahlmoeglichkeit}
      />
    )
  );
}

export function Mehrfachauswahl({
  schritt,
  spezifikation,
  waehleMehrfachauswahlwert,
}) {
  return schritt.auswahlmoeglichkeiten.map(
    (auswahlmoeglichkeit, auswahlIndex) => (
      <CheckBox
        key={`auswahlmoeglichkeit-${auswahlIndex}`}
        spezifikation={spezifikation}
        attribut={schritt.attribut}
        waehleMehrfachauswahlwert={waehleMehrfachauswahlwert}
        {...auswahlmoeglichkeit}
      />
    )
  );
}
