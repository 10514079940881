import React, { useState, useEffect } from 'react';
import { useModalState } from '../../hooks';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

import alertErrorBigIcon from '../../assets/icons/alert_error_big.svg';

import './IEHinweisStoerer.scss';

export default function IEHinweisStoerer() {
  const [isStoererModalShown, hideStoererModal] = useModalState(true);
  const [wurdeGeschlossen, setWurdeGeschlossen] = useState(false);
  const [isCloseButtonActive, setCloseButtonActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);

  // IE Hack
  const isIE = false || !!document.documentMode;

  const mussAngezeigtWerden = isIE && !wurdeGeschlossen;

  useEffect(() => {
    let didCancel = false;
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        if (!didCancel) {
          setTimeLeft(timeLeft - 1);
        }
      }, 1000);
      return () => {
        didCancel = true;
        clearTimeout(timer);
      };
    }
  }, [timeLeft]);

  useEffect(() => {
    let didCancel = false;
    const timer = setTimeout(() => {
      if (!didCancel) {
        setCloseButtonActive(true);
      }
    }, 5000);
    return () => {
      didCancel = true;
      clearTimeout(timer);
    };
  }, []);

  const actions = (
    <>
      <Button
        color="green"
        disabled={!isCloseButtonActive}
        onClick={() => {
          setWurdeGeschlossen(true);
          hideStoererModal();
        }}
      >
        Fortfahren {timeLeft !== 0 && `(${timeLeft})`}
      </Button>
    </>
  );

  return (
    <>
      {mussAngezeigtWerden && isStoererModalShown && (
        <Modal
          className="modal--dialog"
          title="Ihr Browser wird nicht unterstützt"
          closeable={true}
          actions={actions}
          gaTrackingName="IE nicht unterstützt"
        >
          <div className="ieHinweisStoerer__content">
            <img
              className="ieHinweisStoerer__alertIcon"
              src={alertErrorBigIcon}
              alt=""
            />
            <div className="ieHinweisStoerer__infotext">
              Sie verwenden mit dem Internet Explorer einen veralteten Browser.
              Dieser wird von unserer Seite leider nicht mehr unterstützt. Dies
              kann dazu führen, dass Erscheinungsbild und Funktionsweise dieser
              Seite beinträchtigt sind. Bitte wechseln Sie daher auf einen
              aktuellen Browser (z.B. Microsoft Edge, Firefox oder Chrome), um
              den vollen Funktionsumfang nutzen zu können.
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
