/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAnfrageByKunde = /* GraphQL */ `
  subscription OnCreateAnfrageByKunde($gesendetVon: String) {
    onCreateAnfrageByKunde(gesendetVon: $gesendetVon) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const onCreateAnfrageByHandwerker = /* GraphQL */ `
  subscription OnCreateAnfrageByHandwerker($gesendetAn: String) {
    onCreateAnfrageByHandwerker(gesendetAn: $gesendetAn) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const onDeleteAnfrageByKunde = /* GraphQL */ `
  subscription OnDeleteAnfrageByKunde($gesendetVon: String) {
    onDeleteAnfrageByKunde(gesendetVon: $gesendetVon) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const onDeleteAnfrageByHandwerker = /* GraphQL */ `
  subscription OnDeleteAnfrageByHandwerker($gesendetAn: String) {
    onDeleteAnfrageByHandwerker(gesendetAn: $gesendetAn) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const onUpdateAnfrageByKunde = /* GraphQL */ `
  subscription OnUpdateAnfrageByKunde($gesendetVon: String) {
    onUpdateAnfrageByKunde(gesendetVon: $gesendetVon) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const onUpdateAnfrageByHandwerker = /* GraphQL */ `
  subscription OnUpdateAnfrageByHandwerker($gesendetAn: String) {
    onUpdateAnfrageByHandwerker(gesendetAn: $gesendetAn) {
      id
      status
      zuletztGesehenVonHandwerker
      zuletztGesehenVonKunde
      anfrageAuftragId
      createdAt
      updatedAt
      anfrageHandwerkerId
      gesendetVon
      gesendetAn
      imStatusSeit
      handwerkerBewertetAm
      auftrag {
        id
        beschreibung
        ausfuehrungszeitraum
        strasse
        plz
        ort
        auftragKundeId
        spezifikation
        anhaenge {
          typ
          datei {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
        }
        anConciergeServiceWeitergeleitetAm
        updatedAt
        createdAt
        owner
        kunde {
          id
          vorname
          nachname
          email
          werbeeinwilligung
          emailbenachrichtigungen
          geloescht
          createdAt
          updatedAt
          owner
        }
      }
      angebote {
        items {
          id
          createdAt
          updatedAt
          netto
          brutto
          annahmedatum
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          status
          anfrageAngeboteId
          gesendetVon
          gesendetAn
        }
        nextToken
      }
      rechnungen {
        items {
          id
          createdAt
          netto
          brutto
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageRechnungenId
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      nachrichten {
        items {
          id
          createdAt
          autor
          text
          anhang {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anfrageNachrichtenId
          gelesen
          gesendetVon
          gesendetAn
          updatedAt
        }
        nextToken
      }
      handwerker {
        id
        status
        firmenname
        strasse
        plz
        ort
        telefon
        mobil
        telefonischeErreichbarkeit
        webseite
        firmengruendung
        anzahlMitarbeiter
        email
        teilnehmerSeit
        lat
        long
        gewerke
        leistungen
        gewaehlteLeistungen {
          gewerk
          leistungen
        }
        regionen
        firmenlogo {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbTyp
        agb {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        agbLink
        datenschutzbestimmungenTyp
        datenschutzbestimmungen {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
        datenschutzbestimmungenLink
        impressumTyp
        impressum
        impressumLink
        emailbenachrichtigungen
        zusatzqualifikationenVorhanden
        zusatzqualifikationen
        geloescht
        letztesAnmeldedatum
        createdAt
        updatedAt
        abwesenheit {
          von
          bis
        }
        filialen {
          name
          strasse
          plz
          ort
          lat
          long
        }
      }
    }
  }
`;
export const onCreateNachrichtByAnfrage = /* GraphQL */ `
  subscription OnCreateNachrichtByAnfrage($anfrageNachrichtenId: String) {
    onCreateNachrichtByAnfrage(anfrageNachrichtenId: $anfrageNachrichtenId) {
      id
      createdAt
      autor
      text
      anhang {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anfrageNachrichtenId
      gelesen
      gesendetVon
      gesendetAn
      updatedAt
    }
  }
`;
export const onCreateEventByKunde = /* GraphQL */ `
  subscription OnCreateEventByKunde($berechtigterKunde: String) {
    onCreateEventByKunde(berechtigterKunde: $berechtigterKunde) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const onCreateEventByHandwerker = /* GraphQL */ `
  subscription OnCreateEventByHandwerker($berechtigterHandwerker: String) {
    onCreateEventByHandwerker(berechtigterHandwerker: $berechtigterHandwerker) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const onUpdateHandwerkerById = /* GraphQL */ `
  subscription OnUpdateHandwerkerById($id: ID) {
    onUpdateHandwerkerById(id: $id) {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const onCreateAuftrag = /* GraphQL */ `
  subscription OnCreateAuftrag($owner: String) {
    onCreateAuftrag(owner: $owner) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateAuftrag = /* GraphQL */ `
  subscription OnUpdateAuftrag($owner: String) {
    onUpdateAuftrag(owner: $owner) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onDeleteAuftrag = /* GraphQL */ `
  subscription OnDeleteAuftrag($owner: String) {
    onDeleteAuftrag(owner: $owner) {
      id
      beschreibung
      ausfuehrungszeitraum
      strasse
      plz
      ort
      auftragKundeId
      spezifikation
      anhaenge {
        typ
        datei {
          bucket
          key
          region
          fileName
          localUri
          mimeType
        }
      }
      anConciergeServiceWeitergeleitetAm
      updatedAt
      createdAt
      owner
      kunde {
        id
        vorname
        nachname
        email
        werbeeinwilligung
        emailbenachrichtigungen
        geloescht
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onCreateKunde = /* GraphQL */ `
  subscription OnCreateKunde($owner: String) {
    onCreateKunde(owner: $owner) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateKunde = /* GraphQL */ `
  subscription OnUpdateKunde($owner: String) {
    onUpdateKunde(owner: $owner) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteKunde = /* GraphQL */ `
  subscription OnDeleteKunde($owner: String) {
    onDeleteKunde(owner: $owner) {
      id
      vorname
      nachname
      email
      werbeeinwilligung
      emailbenachrichtigungen
      geloescht
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateHandwerker = /* GraphQL */ `
  subscription OnCreateHandwerker {
    onCreateHandwerker {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const onUpdateHandwerker = /* GraphQL */ `
  subscription OnUpdateHandwerker {
    onUpdateHandwerker {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const onDeleteHandwerker = /* GraphQL */ `
  subscription OnDeleteHandwerker {
    onDeleteHandwerker {
      id
      status
      firmenname
      strasse
      plz
      ort
      telefon
      mobil
      telefonischeErreichbarkeit
      webseite
      firmengruendung
      anzahlMitarbeiter
      email
      teilnehmerSeit
      lat
      long
      gewerke
      leistungen
      gewaehlteLeistungen {
        gewerk
        leistungen
      }
      regionen
      firmenlogo {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbTyp
      agb {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      agbLink
      datenschutzbestimmungenTyp
      datenschutzbestimmungen {
        bucket
        key
        region
        fileName
        localUri
        mimeType
      }
      datenschutzbestimmungenLink
      impressumTyp
      impressum
      impressumLink
      emailbenachrichtigungen
      zusatzqualifikationenVorhanden
      zusatzqualifikationen
      geloescht
      letztesAnmeldedatum
      createdAt
      updatedAt
      abwesenheit {
        von
        bis
      }
      filialen {
        name
        strasse
        plz
        ort
        lat
        long
      }
    }
  }
`;
export const onCreateKontoloeschung = /* GraphQL */ `
  subscription OnCreateKontoloeschung {
    onCreateKontoloeschung {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateKontoloeschung = /* GraphQL */ `
  subscription OnUpdateKontoloeschung {
    onUpdateKontoloeschung {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteKontoloeschung = /* GraphQL */ `
  subscription OnDeleteKontoloeschung {
    onDeleteKontoloeschung {
      id
      grund
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent(
    $berechtigterKunde: String
    $berechtigterHandwerker: String
  ) {
    onCreateEvent(
      berechtigterKunde: $berechtigterKunde
      berechtigterHandwerker: $berechtigterHandwerker
    ) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent(
    $berechtigterKunde: String
    $berechtigterHandwerker: String
  ) {
    onUpdateEvent(
      berechtigterKunde: $berechtigterKunde
      berechtigterHandwerker: $berechtigterHandwerker
    ) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent(
    $berechtigterKunde: String
    $berechtigterHandwerker: String
  ) {
    onDeleteEvent(
      berechtigterKunde: $berechtigterKunde
      berechtigterHandwerker: $berechtigterHandwerker
    ) {
      id
      anfrageId
      type
      payload
      createdAt
      berechtigterKunde
      berechtigterHandwerker
      updatedAt
    }
  }
`;
export const onCreateFrist = /* GraphQL */ `
  subscription OnCreateFrist {
    onCreateFrist {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const onUpdateFrist = /* GraphQL */ `
  subscription OnUpdateFrist {
    onUpdateFrist {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const onDeleteFrist = /* GraphQL */ `
  subscription OnDeleteFrist {
    onDeleteFrist {
      id
      fristAnfrageId
      faelligkeitsdatum
      typ
      initialesFaelligkeitsdatum
      createdAt
      updatedAt
      anfrage {
        id
        status
        zuletztGesehenVonHandwerker
        zuletztGesehenVonKunde
        anfrageAuftragId
        createdAt
        updatedAt
        anfrageHandwerkerId
        gesendetVon
        gesendetAn
        imStatusSeit
        handwerkerBewertetAm
        auftrag {
          id
          beschreibung
          ausfuehrungszeitraum
          strasse
          plz
          ort
          auftragKundeId
          spezifikation
          anhaenge {
            typ
            datei {
              bucket
              key
              region
              fileName
              localUri
              mimeType
            }
          }
          anConciergeServiceWeitergeleitetAm
          updatedAt
          createdAt
          owner
          kunde {
            id
            vorname
            nachname
            email
            werbeeinwilligung
            emailbenachrichtigungen
            geloescht
            createdAt
            updatedAt
            owner
          }
        }
        angebote {
          items {
            id
            createdAt
            updatedAt
            netto
            brutto
            annahmedatum
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            status
            anfrageAngeboteId
            gesendetVon
            gesendetAn
          }
          nextToken
        }
        rechnungen {
          items {
            id
            createdAt
            netto
            brutto
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageRechnungenId
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        nachrichten {
          items {
            id
            createdAt
            autor
            text
            anhang {
              typ
              datei {
                bucket
                key
                region
                fileName
                localUri
                mimeType
              }
            }
            anfrageNachrichtenId
            gelesen
            gesendetVon
            gesendetAn
            updatedAt
          }
          nextToken
        }
        handwerker {
          id
          status
          firmenname
          strasse
          plz
          ort
          telefon
          mobil
          telefonischeErreichbarkeit
          webseite
          firmengruendung
          anzahlMitarbeiter
          email
          teilnehmerSeit
          lat
          long
          gewerke
          leistungen
          gewaehlteLeistungen {
            gewerk
            leistungen
          }
          regionen
          firmenlogo {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbTyp
          agb {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          agbLink
          datenschutzbestimmungenTyp
          datenschutzbestimmungen {
            bucket
            key
            region
            fileName
            localUri
            mimeType
          }
          datenschutzbestimmungenLink
          impressumTyp
          impressum
          impressumLink
          emailbenachrichtigungen
          zusatzqualifikationenVorhanden
          zusatzqualifikationen
          geloescht
          letztesAnmeldedatum
          createdAt
          updatedAt
          abwesenheit {
            von
            bis
          }
          filialen {
            name
            strasse
            plz
            ort
            lat
            long
          }
        }
      }
    }
  }
`;
export const onCreateBewertung = /* GraphQL */ `
  subscription OnCreateBewertung {
    onCreateBewertung {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBewertung = /* GraphQL */ `
  subscription OnUpdateBewertung {
    onUpdateBewertung {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBewertung = /* GraphQL */ `
  subscription OnDeleteBewertung {
    onDeleteBewertung {
      id
      bewertungKundeId
      bewertungAnfrageId
      bewertungHandwerkerId
      kundeAnzeigeName
      auftragsTitel
      kommentar
      kategorien {
        key
        titel
        sterne
      }
      freigegeben
      createdAt
      updatedAt
    }
  }
`;
