import { useEffect, useState } from 'react';
import { useAuftraege } from '../DomainContext';
import { API, graphqlOperation } from 'aws-amplify';
import {
  onCreateAnfrageByHandwerker,
  onCreateAnfrageByKunde,
  onDeleteAnfrageByHandwerker,
  onDeleteAnfrageByKunde,
  onUpdateAnfrageByHandwerker,
  onUpdateAnfrageByKunde,
} from '../graphql/subscriptions';
import { unmarshallAnfrage } from '../persistence/marshall';
import { updateOrPush } from '../collections';
import { useAnfrageUndEventContext } from '../contexts/AnfrageUndEventContext';

export function useOffeneAnfragen() {
  const { anfragen } = useAnfrageUndEventContext();
  return anfragen.filter((anfrage) => anfrage.istOffen());
}

export function useGesendeteAnfragen() {
  const { anfragen } = useAnfrageUndEventContext();
  return anfragen.filter((anfrage) => anfrage.wurdeGesendet());
}

export function useBeendeteAnfragen() {
  const { anfragen } = useAnfrageUndEventContext();
  return anfragen.filter((anfrage) => anfrage.wurdeBeendet());
}

export function useAnfragen(benutzerId, istHandwerker) {
  const [anfragen, setAnfragen] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const auftraege = useAuftraege();

  useEffect(() => {
    let didCancel = false;
    const subscriptions = [];

    async function loadAnfragen() {
      try {
        if (!didCancel) {
          setLoading(true);
        }
        const holeAnfragenBy = istHandwerker
          ? await auftraege.holeAnfragenByHwkId(benutzerId)
          : await auftraege.holeAnfragenByKundenId(benutzerId);

        if (!didCancel) {
          setAnfragen(holeAnfragenBy);
        }
      } catch (errors) {
        console.log(errors);
      } finally {
        if (!didCancel) {
          setLoading(false);
        }
      }
    }

    if (benutzerId) {
      loadAnfragen();
      subscriptions.push(
        subscribeOnCreateAnfrage(benutzerId, istHandwerker, (anfrage) => {
          if (!didCancel) {
            setAnfragen((currentAnfragen) => [...currentAnfragen, anfrage]);
          }
        }),
        subscribeOnUpdateAnfrage(benutzerId, istHandwerker, (anfrage) => {
          if (!didCancel) {
            setAnfragen((currentAnfragen) => [
              ...updateOrPush(currentAnfragen, anfrage),
            ]);
          }
        }),
        subscribeOnDeleteAnfrage(benutzerId, istHandwerker, (anfrageId) => {
          if (!didCancel) {
            setAnfragen((currentAnfragen) =>
              currentAnfragen.filter((anfrage) => anfrage.id !== anfrageId)
            );
          }
        })
      );
    }

    return () => {
      didCancel = true;
      subscriptions.forEach(unsubscribe);
    };
  }, [auftraege, benutzerId, istHandwerker]);

  return { anfragen, isLoading };
}

const subscribeOnCreateAnfrage = (benutzerId, istHandwerker, callback) => {
  return subscribe(
    operationOnCreateAnfrage(istHandwerker),
    anfragenFilter(benutzerId, istHandwerker),
    (next) => {
      callback(
        unmarshallAnfrage(
          next.onCreateAnfrageByHandwerker || next.onCreateAnfrageByKunde
        )
      );
    }
  );
};

const subscribeOnUpdateAnfrage = (benutzerId, istHandwerker, callback) => {
  return subscribe(
    operationOnUpdateAnfrage(istHandwerker),
    anfragenFilter(benutzerId, istHandwerker),
    (next) => {
      callback(
        unmarshallAnfrage(
          next.onUpdateAnfrageByHandwerker || next.onUpdateAnfrageByKunde
        )
      );
    }
  );
};

const subscribeOnDeleteAnfrage = (benutzerId, istHandwerker, callback) => {
  return subscribe(
    operationOnDeleteAnfrage(istHandwerker),
    anfragenFilter(benutzerId, istHandwerker),
    (next) => {
      const geloeschteAnfrage =
        next.onDeleteAnfrageByHandwerker || next.onDeleteAnfrageByKunde;
      callback(geloeschteAnfrage.id);
    }
  );
};

function anfragenFilter(benutzerId, istHandwerker) {
  return istHandwerker
    ? { gesendetAn: benutzerId }
    : { gesendetVon: benutzerId };
}

function operationOnCreateAnfrage(istHandwerker) {
  return istHandwerker ? onCreateAnfrageByHandwerker : onCreateAnfrageByKunde;
}

function operationOnUpdateAnfrage(istHandwerker) {
  return istHandwerker ? onUpdateAnfrageByHandwerker : onUpdateAnfrageByKunde;
}

function operationOnDeleteAnfrage(istHandwerker) {
  return istHandwerker ? onDeleteAnfrageByHandwerker : onDeleteAnfrageByKunde;
}

function subscribe(operation, filter, nextMessageHandler) {
  return API.graphql(graphqlOperation(operation, filter)).subscribe({
    next: (onSubscriptionMessage) =>
      nextMessageHandler(onSubscriptionMessage.value.data),
  });
}

function unsubscribe(subscription) {
  if (subscription) {
    subscription.unsubscribe();
  }
}
