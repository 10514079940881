import { useEffect, useState } from 'react';
import { useAuftraege } from '../DomainContext';

export function useKundenauftraege(anfragen, anConciergeServiceWeitergeleitet) {
  const [kundenauftraege, setKundenauftraege] = useState([]);

  const auftraege = useAuftraege();
  useEffect(() => {
    let didCancel = false;

    async function loadAuftraege() {
      try {
        const kundenauftraege = await auftraege.holeAuftraege();
        if (!didCancel) {
          setKundenauftraege(kundenauftraege);
        }
      } catch (errors) {
        console.log(errors);
      }
    }

    loadAuftraege();

    return () => {
      didCancel = true;
    };
  }, [auftraege, anfragen, anConciergeServiceWeitergeleitet]);

  return { kundenauftraege };
}
