import React, { useEffect, useReducer } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

import './Anmelden.scss';
import { Message } from './Message';
import { useBenutzerContext } from '../../contexts/BenutzerContext';

const status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const or = (...fns) => (...args) => fns.find((fn) => fn.apply(null, args));
const isCode = (code) => (error) => error?.code === code;

const ERROR_CONFIGS = [
  {
    matches: or(
      isCode('CodeMismatchException'),
      isCode('ExpiredCodeException')
    ),
    message: () => (
      <>
        Ihr Link ist ungültig oder der Bestätigungscode ist bereits verwendet
        worden.
        <br />
        Bitte wenden Sie sich an unsere Serviceberater unter der E-Mail-Adresse{' '}
        <a className="link" href="mailto:meinzuhause@provinzial-dl.com">
          meinzuhause@provinzial-dl.com
        </a>{' '}
        oder per Telefon:{' '}
        <a className="link" href="tel:02119785858">
          0211 978 58 58
        </a>
        .
      </>
    ),
  },
  {
    matches: isCode('LimitExceededException'),
    message: () => (
      <>
        Sie haben die aktuelle Aktion zu häufig ausgeführt.
        <br />
        Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.
      </>
    ),
  },
  {
    matches: (_) => true,
    message: () => 'Es ist ein unbekannter Fehler aufgetreten.',
  },
];

const emailBestaetigenReducer = (state, action) => {
  switch (action.type) {
    case 'ERROR_RECEIVED':
      return {
        ...state,
        status: status.ERROR,
        error: action.error,
      };
    case 'EMAIL_CONFIRMED':
      return {
        ...state,
        status: status.SUCCESS,
        error: null,
      };
    default:
      throw new Error(`unknown action ${action.type}`);
  }
};

export default function EmailBestaetigen({ location }) {
  const { cognitoUser } = useBenutzerContext();
  const bestaetigungscode = new URLSearchParams(location.search).get(
    'bestaetigungscode'
  );

  const [state, dispatch] = useReducer(emailBestaetigenReducer, {
    status: status.LOADING,
    error: null,
  });

  useEffect(() => {
    let didCancel = false;

    async function bestaetigeEmail() {
      try {
        await Auth.verifyCurrentUserAttributeSubmit('email', bestaetigungscode);
        if (!didCancel) {
          dispatch({ type: 'EMAIL_CONFIRMED' });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({
            type: 'ERROR_RECEIVED',
            error: error,
          });
        }
      }
    }

    if (cognitoUser) {
      if (cognitoUser.attributes.email_verified) {
        dispatch({ type: 'EMAIL_CONFIRMED' });
      } else {
        bestaetigeEmail();
      }
    }

    return () => {
      didCancel = true;
    };
  }, [bestaetigungscode, cognitoUser]);

  if (state.status === status.LOADING) {
    return <LoadingIndicator />;
  }

  if (state.status === status.ERROR) {
    return (
      <div className="anmelden">
        <Message type="error">{errorMessage(state.error)}</Message>
      </div>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/anfragen',
        search: '?email-bestaetigt=',
      }}
    />
  );
}

function errorMessage(error) {
  const errorConfig = ERROR_CONFIGS.find((e) => e.matches(error));
  return errorConfig.message();
}
