import Link from '../../components/Link/Link';
import React from 'react';
import warningIcon from '../../assets/icons/alert_warning.svg';

const userNotConfirmedWarning = ({ handleResendSignUp }) => (
  <>
    <strong>Registrierung nicht abgeschlossen</strong> <br /> Bitte klicken Sie
    den Aktivierungslink in der E-Mail, die wir Ihnen gesendet haben, um Ihre
    Registrierung abzuschließen. <br />
    <Link onClick={handleResendSignUp} data-cy-id="bestaetigungAnfordernButton">
      Neuen Aktivierungslink anfordern
    </Link>
  </>
);

const codeInvalidError = ({ handleResendVerificationLink }) => (
  <>
    Klicken Sie{' '}
    <Link onClick={handleResendVerificationLink} type="button">
      hier
    </Link>{' '}
    um einen neuen Link per E-Mail zu erhalten.
  </>
);

const forgotPasswordCodeInvalidError = ({ handleResendVerificationLink }) => (
  <>
    <strong>Ihr Passwort konnte nicht geändert werden</strong>, da der Link
    nicht mehr gültig ist. Klicken Sie{' '}
    <Link onClick={handleResendVerificationLink} type="button">
      hier
    </Link>{' '}
    um einen neuen Link per E-Mail zu erhalten.
  </>
);

const usernameOrPasswordIncorrectError = ({ username, istHandwerker }) => (
  <>
    Ein Benutzerkonto mit dieser{' '}
    {istHandwerker ? 'HWK-Nummer' : 'E-Mail-Adresse'} existiert nicht oder das
    Passwort ist falsch. Kontrollieren Sie bitte Groß- und Kleinschreibung und
    versuchen Sie es erneut.{' '}
    <a
      className="anmelden__link"
      href={
        istHandwerker
          ? `/handwerker/passwortVergessen?username=${encodeURIComponent(
              username
            )}`
          : `/passwortVergessen?username=${encodeURIComponent(username)}`
      }
    >
      Haben Sie Ihr Passwort vergessen?
    </a>
  </>
);

function userIsDisabledError({ istHandwerker }) {
  return istHandwerker ? (
    <>
      Anmelden nicht möglich, bitte wenden Sie sich an einen unserer
      Mitarbeiter.
      <br />
      Sie erreichen uns unter{' '}
      <a href="mailto:support@provinzial-meinzuhause.de">
        support@provinzial-meinzuhause.de
      </a>{' '}
      oder per Telefon: <a href="tel:02119785858">0211 978 58 58</a>
      (Mo-Fr von 8-17 Uhr).
    </>
  ) : (
    'Anmelden nicht möglich, bitte wenden Sie sich an den Support.'
  );
}

const userAlreadyExistsError = ({ username, istHandwerker }) => (
  <>
    Sie sind bereits registriert.{' '}
    <a
      className="anmelden__link"
      href={
        istHandwerker
          ? `/handwerker/anmelden?username=${encodeURIComponent(username)}`
          : `/anmelden?username=${encodeURIComponent(username)}`
      }
    >
      Zur Anmeldung
    </a>
  </>
);

export function Message({ type, headline, children }) {
  return headline ? (
    <div className="anmelden__container anmelden__container--borderBottom anmelden__container--largePadding anmelden__container--alignCenter">
      <img src={warningIcon} alt="" className="anmelden__icon" />
      <h1
        className="anmelden__headline1 margin:bottom:.75rem"
        data-cy-id="versandbestaetigungHeadline"
      >
        {headline}
      </h1>
      <p className="anmelden__paragraph">{children}</p>
    </div>
  ) : (
    <div
      data-cy-id="message"
      className={`anmelden__message anmelden__message--${type}`}
    >
      {children}
    </div>
  );
}

export function ErrorMessage({ error, ...props }) {
  const isCode = (code) => error?.code === code;
  const isMessage = (message) => error?.message.includes(message);

  const isPasswordError =
    isCode('InvalidParameterException') || isCode('InvalidPasswordException');
  const isPasswordInvalid = error?.message.toLowerCase().includes('password');
  const isPasswordEmpty = isMessage('Password cannot be empty');

  const possibleErrors = [
    {
      condition: isCode('UserNotConfirmedException'),
      message: userNotConfirmedWarning,
      type: 'warning',
    },
    {
      condition:
        isCode('ExpiredCodeException') ||
        (isCode('CodeMismatchException') &&
          !isMessage('Invalid verification code provided, please try again.')),
      message: codeInvalidError,
      headline: 'Der Link ist nicht mehr gültig',
      type: 'warning',
    },
    {
      condition:
        isCode('CodeMismatchException') &&
        isMessage('Invalid verification code provided, please try again.'),
      message: forgotPasswordCodeInvalidError,
      type: 'error',
    },
    {
      condition:
        isCode('NotAuthorizedException') &&
        isMessage('Incorrect username or password.'),
      message: usernameOrPasswordIncorrectError,
      type: 'error',
    },
    {
      condition:
        isCode('NotAuthorizedException') &&
        isMessage('Password attempts exceeded'),
      message: () =>
        'Sie haben die maximale Anzahl von Anmeldeversuche überschritten. Bitte probieren Sie es später noch einmal.',
      type: 'error',
    },
    {
      condition:
        isCode('InvalidParameterException') &&
        isMessage('Invalid email address format.'),
      message: () => 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      type: 'error',
    },
    {
      condition: isPasswordError && isPasswordInvalid,
      message: () => 'Bitte geben Sie ein gültiges Passwort ein.',
      type: 'error',
    },
    {
      condition: (isPasswordError && !isPasswordInvalid) || isPasswordEmpty,
      message: () => 'Bitte geben Sie Ihr Passwort ein.',
      type: 'error',
    },
    {
      condition:
        isCode('NotAuthorizedException') && isMessage('User is disabled.'),
      message: userIsDisabledError,
      type: 'error',
    },
    {
      condition: isMessage('Username cannot be empty'),
      message: ({ istHandwerker }) =>
        `Bitte geben Sie Ihre ${
          istHandwerker ? 'HWK-Nummer' : 'E-Mail-Adresse'
        } an.`,
      type: 'error',
    },
    {
      condition: isCode('NetworkError'),
      message: () =>
        'Anscheinend haben Sie keine Internetverbindung. Bitte prüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
      type: 'error',
    },
    {
      condition: isCode('LimitExceededException'),
      message: () =>
        'Sie haben die aktuelle Aktion zu häufig ausgeführt. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
      type: 'error',
    },
    {
      condition: isCode('UsernameExistsException'),
      message: userAlreadyExistsError,
      type: 'error',
    },
    {
      condition: isMessage("Failed to execute 'atob' on 'Window'"),
      message: () => 'Ihr Registrierungslink ist leider nicht gültig.',
      type: 'error',
    },
  ];

  const possibleError = possibleErrors.find(({ condition }) => condition);
  const message = possibleError
    ? possibleError.message(props)
    : 'Ein unbekannter Fehler ist aufgetreten.';
  const type = possibleError ? possibleError.type : 'error';

  return (
    error && (
      <Message type={type} headline={possibleError?.headline}>
        {message}
      </Message>
    )
  );
}

export function SuccessMessage({ message }) {
  return <Message type="success">{message}</Message>;
}
