import { toTime } from './dateTime';

export function byUpdatedAtDesc(entityA, entityB) {
  return Math.sign(toTime(entityB.updatedAt) - toTime(entityA.updatedAt));
}

export function byUpdatedAtAsc(entityA, entityB) {
  return Math.sign(toTime(entityA.updatedAt) - toTime(entityB.updatedAt));
}

export function byCreatedAtAsc(entityA, entityB) {
  return Math.sign(toTime(entityA.createdAt) - toTime(entityB.createdAt));
}
