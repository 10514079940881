import React from 'react';
import { useAuftraege } from '../../DomainContext';
import DateiMitBetragHochladenModal from '../DateiMitBetragHochladenModal/DateiMitBetragHochladenModal';
import { gtmEvent } from '../../googleAnalytics';

export default ({ hide, anfrage }) => {
  const auftraege = useAuftraege();
  const sendeRechnung = async (datei, betrag, betragAsNumber) => {
    await auftraege.sendeRechnung(anfrage, datei, betragAsNumber);

    gtmEvent({
      category: 'contract',
      action: 'invoiceUploaded',
      value: betragAsNumber
    });
  };

  return (
    <DateiMitBetragHochladenModal
      hide={hide}
      ladeDateiHoch={sendeRechnung}
      title="Rechnung hochladen"
      dateiHochladenLabel="Rechnung hochladen"
      betragLabel="Rechnungsbetrag in Euro (netto)"
    >
      <p>
        Bitte legen Sie hier Ihre Rechnung im PDF-Format ab und geben Sie den
        Rechnungsbetrag (netto) aus Ihrer Rechnung seperat mit an.
      </p>
    </DateiMitBetragHochladenModal>
  );
};
