import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { withBreakpoints } from 'react-with-breakpoints';

import { useModalState } from '../../hooks';
import { Row, Cell } from '../Table/Table';
import Status from '../Status/Status';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { toDateTimeString } from '../../dateTime';
import { useAuftraege } from '../../DomainContext';
import AnfragedetailsModal from '../AnfragedetailsModal/AnfragedetailsModal';
import { gtmEvent } from '../../googleAnalytics';
import Ausfuehrungszeitraum from '../Ausfuerungszeitraum/Ausfuehrungszeitraum';
import { Auftragsstatus } from '../Auftragsstatus/Auftragsstatus';

import './Anfrage.scss';

const AnfrageAblehnenDialog = ({ hide, onAblehnung }) => {
  const [ablehnungsgruende, setAblehnungsgruende] = useState(new Set());
  const [sonstigerAblehnungsgrund, setSonstigerAblehnungsgrund] = useState('');

  const createLehneAnfrageAb = (setLoading) => async () => {
    setLoading(true);
    try {
      await onAblehnung(sonstigerAblehnungsgrund, ablehnungsgruende);
      hide();
    } catch (_) {
      setLoading(false);
    }
  };

  const isAnfrageAblehnenDisabled =
    ablehnungsgruende.size === 0 ||
    (ablehnungsgruende.has('Sonstiges') && sonstigerAblehnungsgrund === '');

  const updateAblehnungsgruende = (event) => {
    const text = event.target.value;
    if (text !== 'Sonstiges') {
      setSonstigerAblehnungsgrund('');
    }
    setAblehnungsgruende(new Set([text]));
  };

  const actions = (setLoading) => (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        color="red"
        data-cy-id="anfrageAblehnenButton"
        disabled={isAnfrageAblehnenDisabled}
        onClick={createLehneAnfrageAb(setLoading)}
      >
        Anfrage ablehnen
      </Button>
    </>
  );

  return (
    <Modal
      className="modal--dialog"
      title="Anfrage ablehnen"
      hide={hide}
      actions={actions}
      gaTrackingName="Anfrage ablehnen"
    >
      <div className="anfrageAblehnen">
        <p className="anfrageAblehnen__grundAngabe">
          Bitte geben Sie einen Grund an:
        </p>
        <ul className="anfrageAblehnen__grundAuswahl">
          <li className="anfrageAblehnen__grund">
            <label
              htmlFor="ausfuehrungstermin"
              className="anfrageAblehnen__grundLabel"
            >
              <input
                className="anfrageAblehnen__checkbox"
                id="ausfuehrungstermin"
                type="radio"
                name="group"
                value="Keine Zeit, mein Auftragsbuch ist voll."
                onChange={updateAblehnungsgruende}
              />
              Keine Zeit, mein Auftragsbuch ist voll
            </label>
          </li>
          <li className="anfrageAblehnen__grund">
            <label
              htmlFor="auftragkosten"
              className="anfrageAblehnen__grundLabel"
            >
              <input
                className="anfrageAblehnen__checkbox"
                id="auftragkosten"
                type="radio"
                name="group"
                value="Zu weit entfernt."
                onChange={updateAblehnungsgruende}
              />
              Zu weit entfernt
            </label>
          </li>
          <li className="anfrageAblehnen__grund">
            <label
              htmlFor="ausfuehrungsdetails"
              className="anfrageAblehnen__grundLabel"
            >
              <input
                className="anfrageAblehnen__checkbox"
                id="ausfuehrungsdetails"
                type="radio"
                name="group"
                value="Auftrag passt nicht zu meinen Leistungen."
                onChange={updateAblehnungsgruende}
              />
              Auftrag passt nicht zu meinen Leistungen
            </label>
          </li>
          <li className="anfrageAblehnen__grund">
            <label htmlFor="sonstiges" className="anfrageAblehnen__grundLabel">
              <input
                className="anfrageAblehnen__checkbox"
                id="sonstiges"
                type="radio"
                name="group"
                value="Sonstiges"
                onChange={updateAblehnungsgruende}
              />
              Sonstiges
            </label>
            {ablehnungsgruende.has('Sonstiges') && (
              <textarea
                value={sonstigerAblehnungsgrund}
                onChange={(event) =>
                  setSonstigerAblehnungsgrund(event.target.value)
                }
                className="anfrageAblehnen__sonstigesText"
              />
            )}
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default function Anfrage({ anfrage, spalten, autor, onAnfrageClick }) {
  const auftraege = useAuftraege();

  const anzahlUngelesenerNachrichten = anfrage.nachrichten.filter(
    (nachricht) => !nachricht.gelesen && nachricht.autor !== autor
  ).length;

  const [
    anzahlUngesehenerEventsUndNachrichten,
    setAnzahlUngesehenerEventsUndNachrichten,
  ] = useState(0);

  useEffect(() => {
    let didCancel = false;
    async function holeEventsZuAnfrageNachZuletztGesehen() {
      if (!didCancel) {
        const events = await auftraege.holeEventsZuAnfrageNach(
          anfrage,
          autor === 'HANDWERKER'
            ? anfrage.zuletztGesehenVonHandwerker
            : anfrage.zuletztGesehenVonKunde
        );

        setAnzahlUngesehenerEventsUndNachrichten(
          events.length + anzahlUngelesenerNachrichten
        );
      }
    }

    holeEventsZuAnfrageNachZuletztGesehen();

    return () => {
      didCancel = true;
    };
  }, [anfrage, auftraege, autor, anzahlUngelesenerNachrichten]);

  const columnMap = {
    handwerker: (key) => (
      <Cell onClick={() => onAnfrageClick(anfrage)} key={key}>
        <a
          href={`/kommunikationskanal/${anfrage.id}`}
          className="anfrage__handwerker"
          onClick={(event) => event.preventDefault()}
        >
          {anzahlUngesehenerEventsUndNachrichten > 0 ? (
            <>
              <span className="anfrage__anzahlUngeleseneNachrichten">
                {anzahlUngesehenerEventsUndNachrichten}
              </span>
              <p className="anfrage--ungelesen">
                {anfrage.handwerker
                  ? anfrage.handwerker.firmenname
                  : 'HANDWERKER NAME'}
              </p>
            </>
          ) : anfrage.handwerker ? (
            anfrage.handwerker.firmenname
          ) : (
            'HANDWERKER NAME'
          )}
        </a>
      </Cell>
    ),
    anfrage: (key) => (
      <Cell onClick={() => onAnfrageClick(anfrage)} key={key}>
        <button className="anfrage__beschreibung">
          {!anfrage.zuletztGesehenVonHandwerker ? (
            <>
              <Status className="anfrage__status" text="NEU" color="yellow" />
              <p className="anfrage--ungelesen">
                {anfrage.auftrag.beschreibung}
              </p>
            </>
          ) : (
            anfrage.auftrag.beschreibung
          )}
        </button>
      </Cell>
    ),
    auftrag: (key) => (
      <Cell onClick={() => onAnfrageClick(anfrage)} key={key}>
        <a
          href={`/kommunikationskanal/${anfrage.id}`}
          onClick={(event) => event.preventDefault()}
          className="anfrage__beschreibung"
        >
          {anzahlUngesehenerEventsUndNachrichten > 0 ? (
            <>
              <span className="anfrage__anzahlUngeleseneNachrichten">
                {anzahlUngesehenerEventsUndNachrichten}
              </span>
              <p className="anfrage--ungelesen">
                {anfrage.auftrag.beschreibung}
              </p>
            </>
          ) : (
            anfrage.auftrag.beschreibung
          )}
        </a>
      </Cell>
    ),
    erhalten: (key) => (
      <Cell
        onClick={() => onAnfrageClick(anfrage)}
        key={key}
        className="anfrage__erhalten"
      >
        {toDateTimeString(anfrage.createdAt)}
      </Cell>
    ),
    letzteAktualisierung: (key) => (
      <Cell
        onClick={() => onAnfrageClick(anfrage)}
        key={key}
        className="anfrage__letzteAktualisierung"
      >
        {toDateTimeString(anfrage.updatedAt)}
      </Cell>
    ),
    erstelltAm: (key) => (
      <Cell
        onClick={() => onAnfrageClick(anfrage)}
        key={key}
        className="anfrage__erstelltAm"
      >
        {toDateTimeString(anfrage.createdAt)}
      </Cell>
    ),
    ausfuehrungszeitraum: (key) => (
      <Cell
        onClick={() => onAnfrageClick(anfrage)}
        key={key}
        className="anfrage__ausfuehrungszeitraum"
      >
        <Ausfuehrungszeitraum spezifikation={anfrage.auftrag.spezifikation} />
      </Cell>
    ),
    auftraggeber: (key) => (
      <Cell
        onClick={() => onAnfrageClick(anfrage)}
        key={key}
        className="anfrage__auftraggeber"
      >
        {`${anfrage.auftrag.kunde.vorname} ${anfrage.auftrag.kunde.nachname}`}
      </Cell>
    ),
    ort: (key) => (
      <Cell
        onClick={() => onAnfrageClick(anfrage)}
        key={key}
        className="anfrage__anschrift"
      >
        <div className="anfrage__strasse">{anfrage.auftrag.strasse},&nbsp;</div>
        <div className="anfrage__ort">{anfrage.auftrag.ort}</div>
      </Cell>
    ),
    status: (key) => (
      <Cell key={key} className="anfrage__status">
        <Auftragsstatus
          fortschritt={auftraege.auftragsstatus([anfrage])}
          gtmLabel="overviewCraftsman"
        />
      </Cell>
    ),
  };

  return (
    <Row
      key={anfrage.id}
      className={`${classNames('anfrage', {
        'anfrage--ungelesen': anzahlUngesehenerEventsUndNachrichten > 0,
      })}`}
    >
      {spalten.map((spalte, key) => columnMap[spalte](key))}
    </Row>
  );
}

export const AnfragenActionWrapper = withBreakpoints(
  function AnfragenActionWrapper({ autor, children, currentBreakpoint }) {
    const history = useHistory();
    const [
      isDetailsModalShown,
      showDetailsModal,
      hideDetailsModal,
    ] = useModalState();

    const [
      isAblehnenDialogShown,
      showAblehnenDialog,
      hideAblehnenDialog,
    ] = useModalState();
    const auftraege = useAuftraege();

    const [anfrage, waehleAnfrage] = useState(null);

    const nimmAnfrageAn = async () => {
      await auftraege.nimmAnfrageAn(anfrage);
      history.push(`/kommunikationskanal/${anfrage.id}`);
    };

    const lehneAnfrageAb = async (
      sonstigerAblehnungsgrund,
      ablehnungsgruende
    ) => {
      const ablehnungsgrund = Array.from(ablehnungsgruende).join(', ');

      await auftraege.lehneAnfrageAb(
        anfrage,
        ablehnungsgrund.concat(' ', sonstigerAblehnungsgrund).trim()
      );

      gtmEvent({
        category: 'request',
        action: 'denied',
        label: ablehnungsgrund.concat(
          sonstigerAblehnungsgrund ? ' Sonstiger Ablehnungsgrund' : ''
        ),
      });
    };

    const handleAnfrageClick = (clickedAnfrage) => {
      if (!isAblehnenDialogShown && !isDetailsModalShown) {
        if (autor === 'HANDWERKER') {
          if (clickedAnfrage.wurdeGesendet()) {
            waehleAnfrage(clickedAnfrage);
            showDetailsModal();
          } else {
            history.push(`/kommunikationskanal/${clickedAnfrage.id}`);
          }
        } else {
          if (currentBreakpoint === 'xlarge') {
            history.push(`/kommunikationskanal/${clickedAnfrage.id}`);
          } else {
            history.push(`/anfragen/${clickedAnfrage.id}`);
          }
        }
      }
    };

    return (
      <>
        {isAblehnenDialogShown && (
          <AnfrageAblehnenDialog
            hide={hideAblehnenDialog}
            onAblehnung={lehneAnfrageAb}
          />
        )}
        {isDetailsModalShown && (
          <AnfragedetailsModal
            hide={hideDetailsModal}
            onAnnahme={nimmAnfrageAn}
            onAblehnung={showAblehnenDialog}
            anfrage={anfrage}
            auftrag={anfrage.auftrag}
          />
        )}
        {children(handleAnfrageClick)}
      </>
    );
  }
);
