import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from './graphql/mutations';
import { unmarshallObject } from './persistence/marshall';

const EARTH_RADIUS_KM = 6371;

export async function ermittleGeokoordinaten({ strasse, plz, ort }) {
  const {
    data: { executeCommand },
  } = await API.graphql(
    graphqlOperation(mutations.executeCommand, {
      command: 'ermittleGeokoordinaten',
      arguments: JSON.stringify({ strasse, plz, ort }),
    })
  );
  return unmarshallObject(executeCommand);
}

export function hatLatLong(obj) {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.lat === 'number' &&
    typeof obj.long === 'number'
  );
}

export function distanzInKilometer(pos1, pos2) {
  let { lat: lat1, long: long1 } = pos1;
  let { lat: lat2, long: long2 } = pos2;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLong = degreesToRadians(long2 - long1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLong / 2) * Math.sin(dLong / 2) * Math.cos(lat1) * Math.cos(lat2);
  return EARTH_RADIUS_KM * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}
