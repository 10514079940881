import React from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { useBewertungen } from '../../../hooks/useBewertungen';
import Bewertungsanzeige from './Bewertungsanzeige';

export default function BewertungenModal({ hide, handwerker }) {
  const { bewertungen, isLoading } = useBewertungen(handwerker?.id);

  const actions = <Button onClick={hide}>Schließen</Button>;

  return (
    <Modal title="Meine Bewertungen" actions={actions} hide={hide}>
      {isLoading && <LoadingIndicator />}
      <Bewertungsanzeige bewertungen={bewertungen} handwerker={handwerker} />
    </Modal>
  );
}
