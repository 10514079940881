import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import KeineAnfragenGefundenPlatzhalter from '../../../components/KeineAnfragenGefundenPlatzhalter/KeineAnfragenGefundenPlatzhalter';
import { HeaderCell, Row, Table } from '../../../components/Table/Table';
import Anfrage from '../../../components/Anfrage/Anfrage';
import { AnfragenActionWrapper } from '../../../components/Anfrage/Anfrage';
import './AbgeschlosseneAuftraege.scss';
import { useBeendeteAnfragen } from '../../../hooks/useAnfragen';

export default () => {
  const anfragen = useBeendeteAnfragen();

  return (
    <>
      <Navbar />
      <div className="abgeschlosseneAuftraege">
        <AnfragenActionWrapper autor="HANDWERKER">
          {(handleAnfrageClick) =>
            anfragen && anfragen.length === 0 ? (
              <KeineAnfragenGefundenPlatzhalter
                title="Sie haben aktuell noch keine abgeschlossenen Aufträge"
                subtext="In dieser Übersicht werden alle Ihre abgeschlossenen Aufträge angezeigt. So können Sie auch nach Ablauf Ihrer Aufträge, Details dazu einsehen."
              />
            ) : (
              <Table>
                <Row>
                  <HeaderCell>Auftrag</HeaderCell>
                  <HeaderCell>Letzte Aktualisierung</HeaderCell>
                  <HeaderCell>Auftraggeber</HeaderCell>
                  <HeaderCell>Ort</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                </Row>
                {anfragen.map((anfrage) => (
                  <Anfrage
                    key={anfrage.id}
                    autor="HANDWERKER"
                    anfrage={anfrage}
                    spalten={[
                      'auftrag',
                      'letzteAktualisierung',
                      'auftraggeber',
                      'ort',
                      'status',
                    ]}
                    onAnfrageClick={handleAnfrageClick}
                  />
                ))}
              </Table>
            )
          }
        </AnfragenActionWrapper>
      </div>
    </>
  );
};
