import React from 'react';
import { toDateString } from '../../../dateTime';
import Rating from './Rating';
import Bewertungszusammenfassung from './Bewertungszusammenfassung';
import {
  berechneDurchschnitt,
  berechneDurchschnittFuerKategorie,
} from '../../../domain/bewertung';

import './Bewertungsanzeige.scss';
import { KATEGORIEN } from '../../../domain/bewertung';
import { numberFormat } from '../../../i18n';

export default function Bewertungsanzeige({ bewertungen, handwerker }) {
  const gesammtdurchschnitt = berechneDurchschnitt(bewertungen);
  return (
    <>
      <h1 className="bewertungAnzeige__ueberschrift">Gesamtbewertung</h1>
      <div className="bewertungAnzeige__durchschnittAnzeige">
        <div className="bewertungAnzeige__durchschnittAnzeigeSterne">
          <div className="margin:bottom:.5rem">
            <Rating value={gesammtdurchschnitt} size="large" />
          </div>
          <span>
            <strong>{numberFormat(gesammtdurchschnitt)}</strong> von{' '}
            {numberFormat(5)}
          </span>
        </div>
        <div>
          {KATEGORIEN.map(({ titel, key }) => {
            const wert = berechneDurchschnittFuerKategorie(bewertungen, key);
            return (
              <div className="durchschnitt__kategorie" key={key}>
                <span className="durchschnitt__kategorieTitel">{titel}:</span>
                <Rating value={wert} size="small" color="lightgreen" />
                <small className="durchschnitt__kategorieWert">
                  {numberFormat(wert)}
                </small>
              </div>
            );
          })}
        </div>
      </div>
      <h1 className="bewertungAnzeige__ueberschrift">
        Alle Bewertungen im Detail ({bewertungen.length})
      </h1>
      {bewertungen.map((bewertung) => (
        <div key={bewertung.id}>
          <div className="bewertungAnzeige__kundenanzeige">
            von {bewertung.kundeAnzeigeName || 'gelöschter Benutzer'} am{' '}
            {toDateString(bewertung.createdAt)}
          </div>
          <Einzelbewertung handwerker={handwerker} bewertung={bewertung} />
        </div>
      ))}
    </>
  );
}

function Einzelbewertung({ handwerker, bewertung }) {
  return (
    <div className="einzelbewertung">
      <div className="einzelbewertung__sterne">
        <Bewertungszusammenfassung
          hwkId={handwerker.id}
          bewertungen={[bewertung]}
          className="margin:bottom:1rem"
        />
        {bewertung.kategorien.map((kategorie) => (
          <div className="einzelbewertung__kategorie" key={kategorie.key}>
            <span className="einzelbewertung__kategorieTitel">
              {kategorie.titel}:
            </span>
            <Rating
              name={kategorie.key}
              value={kategorie.sterne}
              size="small"
              color="lightgreen"
            />
          </div>
        ))}
      </div>
      <div>
        <div className="einzelbewertung__kommentarTitel">
          {bewertung.auftragsTitel}
        </div>
        <div>
          <pre>{bewertung.kommentar}</pre>
        </div>
      </div>
    </div>
  );
}
