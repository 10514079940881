import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
export function Wizard({ children, initialStep = 0, onStepChange, onFinish }) {
  const [step, setStep] = useState(initialStep);
  const numberOfSteps = React.Children.count(children);
  const hasPreviousStep = step > 0;
  const hasNextStep = step < numberOfSteps - 1;
  const [validSteps, setValidSteps] = useState([]);
  const isCurrentStepValid = validSteps[step];
  const [isLoading, setLoading] = useState(false);
  const isNextStepAvailable = hasNextStep && isCurrentStepValid && !isLoading;
  const goToPreviousStep = () => {
    setStep(step - 1);
    window.scrollTo({ top: 0 });
  };
  const goToNextStep = () => {
    if (hasNextStep && isCurrentStepValid) {
      setLoading(true);
      onStepChange().then(() => {
        setStep(step + 1);
        setLoading(false);
        window.scrollTo({ top: 0 });
      });
    }
  };
  const setStepValid = (isValid) => {
    const nextValidSteps = [...validSteps];
    nextValidSteps[step] = isValid;
    setValidSteps(nextValidSteps);
  };
  return (
    <div className="wizard">
      <div className="wizard__header">
        <h1 className="wizard__headerTitle">Profil vervollständigen</h1>
        <div className="wizard__headerSteps">
          <span className="wizard__currentStep">{step + 1}</span>/
          {numberOfSteps}
        </div>
      </div>
      <div className="wizard__content">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          React.Children.map(
            children,
            (child, stepIndex) =>
              stepIndex === step && React.cloneElement(child, { setStepValid })
          )
        )}
      </div>
      <div className="wizard__actions">
        {hasPreviousStep ? (
          <Button onClick={goToPreviousStep}>Zurück</Button>
        ) : (
          <div />
        )}
        {hasNextStep ? (
          <Button
            color="green"
            onClick={goToNextStep}
            disabled={!isNextStepAvailable}
            data-cy-id="nextStep"
          >
            Weiter
          </Button>
        ) : (
          <Button color="green" onClick={onFinish} data-cy-id="finish">
            Abschließen
          </Button>
        )}
      </div>
    </div>
  );
}
