import './KontoLoeschenDialog.scss';

import React, { useState } from 'react';
import {
  useHandwerkerverzeichnis,
  useKundenverzeichnis,
} from '../../DomainContext';
import {
  useIstHandwerkerLoeschbar,
  useIstKundeLoeschbar,
} from '../ProfilModal/hooks';

import Amplify from 'aws-amplify';
import Button from '../Button/Button';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Modal from '../Modal/Modal';
import { gtmEvent } from '../../googleAnalytics';
import { useBenutzerContext } from '../../contexts/BenutzerContext';
import { useHistory } from 'react-router-dom';

async function deleteUser(cognitoUser) {
  new Promise((resolve, reject) => {
    cognitoUser.deleteUser((error, data) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  });
}

export default ({ hide }) => {
  const history = useHistory();
  const [kontoloeschenGrund, setKontoloeschenGrund] = useState(null);
  const [isKontoLoeschenDisabled, setKontoLoeschenDisabled] = useState(
    kontoloeschenGrund === null
  );
  const [
    isSonstigerKontoLoeschenGrundChecked,
    setSonstigerKontoLoeschenGrundChecked,
  ] = useState(false);
  const [
    sonstigerKontoLoeschenGrund,
    setSonstigerKontoLoeschenGrund,
  ] = useState('');

  const [loading, setLoading] = useState(true);

  const updateKontoloeschenGrund = (event, text) => {
    if (event.target.checked) {
      setSonstigerKontoLoeschenGrund('');
      setKontoloeschenGrund(text);
      setKontoLoeschenDisabled(false);
    }
  };

  const handleSonstigerKontoLoeschenGrundChange = (event) => {
    const text = event.target.value;
    setSonstigerKontoLoeschenGrund(text);
    setKontoloeschenGrund(sonstigerKontoLoeschenGrund);
    setKontoLoeschenDisabled(!text || text.length === 0);
  };

  const handwerkerverzeichnis = useHandwerkerverzeichnis();
  const kundenverzeichnis = useKundenverzeichnis();
  const { cognitoUser, benutzer, istHandwerker } = useBenutzerContext();
  const kontoLoeschen = async () => {
    setKontoLoeschenDisabled(true);
    const grund = isSonstigerKontoLoeschenGrundChecked
      ? sonstigerKontoLoeschenGrund
      : kontoloeschenGrund;
    if (istHandwerker) {
      await handwerkerverzeichnis.loescheHandwerkerdaten(benutzer, grund);
    } else {
      await kundenverzeichnis.loescheKundendaten(benutzer, grund);
    }
    await deleteUser(cognitoUser);
    gtmEvent({
      category: 'profile',
      action: 'accountDeleted',
      label: grund,
    });
    await Amplify.Auth.signOut();
    history.push('/');
  };

  const kontoLoeschbar = istHandwerker
    ? useIstHandwerkerLoeschbar(benutzer, setLoading)
    : useIstKundeLoeschbar(benutzer, setLoading);

  const intro = kontoLoeschbar
    ? 'Möchten Sie Ihr Konto dauerhaft löschen?'
    : 'Ihr Konto kann nicht gelöscht werden';
  const actions = kontoLoeschbar ? (
    <>
      <Button onClick={hide}>Abbrechen</Button>
      <Button
        color="red"
        onClick={kontoLoeschen}
        disabled={isKontoLoeschenDisabled}
        data-cy-id="kontoLoeschenModalButton"
      >
        Konto löschen
      </Button>
    </>
  ) : (
    <Button data-cy-id="konto-nicht-loeschbar-dialog-cancel" onClick={hide}>
      Schließen
    </Button>
  );

  return (
    <Modal
      className="modal--dialog"
      title={loading ? '' : intro}
      hide={hide}
      actions={loading ? undefined : actions}
      gaTrackingName="Konto löschen"
    >
      {loading ? (
        <LoadingIndicator />
      ) : kontoLoeschbar ? (
        <div className="kontoLoeschen" data-cy-id="konto-loeschen-dialog">
          <p>
            Wir bedauern sehr, dass Sie Ihr Konto bei&nbsp;
            <span className="kontoLoeschen__highlightSpan">
              MeinZuhauseUndIch
            </span>
            &nbsp; dauerhaft löschen möchten.
          </p>
          <p className="kontoLoeschen__konsequenzen">
            Ihr Konto und die damit verknüpften Daten, Anfragen und Aufträge
            können nach bestätigter Löschung nicht wiederhergestellt werden.
          </p>
          <p className="kontoLoeschen__grundAngabe">
            Bitte geben Sie einen Grund an, weshalb Sie Ihr Konto löschen
            möchten.
          </p>
          <ul className="kontoLoeschen__grundAuswahl">
            <li className="kontoLoeschen__grund">
              <label
                htmlFor="datenschutz"
                className="kontoLoeschen__grundLabel"
              >
                <input
                  className="kontoLoeschen__radio"
                  id="datenschutz"
                  type="radio"
                  name="group"
                  onChange={(event) => {
                    setSonstigerKontoLoeschenGrundChecked(false);
                    updateKontoloeschenGrund(
                      event,
                      'Ich habe Bedenken bezüglich des Datenschutzes'
                    );
                  }}
                />
                Ich habe Bedenken bezüglich des Datenschutzes
              </label>
            </li>
            <li className="kontoLoeschen__grund">
              <label htmlFor="service" className="kontoLoeschen__grundLabel">
                <input
                  className="kontoLoeschen__radio"
                  id="service"
                  type="radio"
                  name="group"
                  onChange={(event) => {
                    setSonstigerKontoLoeschenGrundChecked(false);
                    updateKontoloeschenGrund(
                      event,
                      'Ich nutze den Service von „MeinZuhauseUndIch“ nicht (mehr)'
                    );
                  }}
                />
                Ich nutze den Service von „MeinZuhauseUndIch“ nicht (mehr)
              </label>
            </li>
            <li className="kontoLoeschen__grund">
              <label htmlFor="sonstiges" className="kontoLoeschen__grundLabel">
                <input
                  className="kontoLoeschen__radio"
                  id="sonstiges"
                  type="radio"
                  name="group"
                  onChange={(event) => {
                    setSonstigerKontoLoeschenGrundChecked(event.target.checked);
                    setKontoLoeschenDisabled(true);
                  }}
                />
                Sonstiges
              </label>
              {isSonstigerKontoLoeschenGrundChecked && (
                <textarea
                  className="kontoLoeschen__sonstigesText"
                  value={sonstigerKontoLoeschenGrund}
                  onChange={handleSonstigerKontoLoeschenGrundChange}
                />
              )}
            </li>
          </ul>
        </div>
      ) : (
        <p
          className="kontoNichtLoeschbar__info"
          data-cy-id="konto-nicht-loeschbar"
        >
          Sie haben momentan mindestens einen aktiven Auftrag in Ausführung. Sie
          können Ihr Konto erst löschen, wenn alle Aufträge abgeschlossen sind.
        </p>
      )}
    </Modal>
  );
};
