import './Header.scss';

import React, { useState } from 'react';
import { useLogOut, useModalState } from '../../hooks';

import { AuftragsstatusModal } from '../Auftragsstatus/AuftragsstatusModal';
import BewertungenModal from '../Bewertungen/Handwerkerbewertung/BewertungenModal';
import Button from '../Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import DatenschutzVervollstaendigenStoerer from '../DatenschutzVervollstaendigenStoerer/DatenschutzVervollstaendigenStoerer';
import OutsideClickHandler from 'react-outside-click-handler';
import ProfilModal from '../ProfilModal/ProfilModal';
import classNames from 'classnames';
import { gtmEvent } from '../../googleAnalytics';
import handwerkerIcon from '../../assets/icons/handwerker.svg';
import { istProfilVollstaendig } from '../ProfilModal/validierung';
import kundeIcon from '../../assets/icons/kunde.svg';
import { useBenutzerContext } from '../../contexts/BenutzerContext';
import { useHistory } from 'react-router-dom';
import { withBreakpoints } from 'react-with-breakpoints';

const HeaderNavigationItem = ({ onClick, isActive, children, ...props }) => {
  return (
    <button
      type="button"
      className={classNames('headerNavigation__item', {
        'headerNavigation__item--active': isActive,
      })}
      onClick={onClick}
      data-cy-id="headerNavigationItem"
      {...props}
    >
      <label
        className={classNames('headerNavigation__itemLabel', {
          'headerNavigation__itemLabel--active': isActive,
        })}
      >
        {children}
      </label>
    </button>
  );
};

const Navigation = function Navigation() {
  const history = useHistory();
  const { benutzer, istHandwerker } = useBenutzerContext();

  const [isModalVisible, showModal, hideModal] = useModalState();

  const oeffneStartseite = () => {
    gtmEvent({
      category: 'navigation',
      action: 'landingpageClicked',
    });
    history.push('/');
  };
  const oeffneAnfragen = () => {
    gtmEvent({
      category: 'navigation',
      action: 'requestsClicked',
    });
    history.push('/anfragen');
  };
  const oeffneMeineAuftraege = () => {
    gtmEvent({
      category: 'navigation',
      action: 'openContractsClicked',
    });
    history.push('/meineAuftraege');
  };

  const pathname = history.location.pathname;
  const istStartseiteActive = () => pathname === '/' && !isModalVisible;
  const istMeineAuftraegeAktiv = () =>
    (pathname.includes('/anfragen') ||
      pathname.includes('/offeneAuftraege') ||
      pathname.includes('/meineAuftraege') ||
      pathname.includes('/abgeschlosseneAuftraege')) &&
    !isModalVisible;

  const istProfilVervollstaendigen =
    istHandwerker && benutzer && !istProfilVollstaendig(benutzer);

  const istWerbeeinwilligungVervollstaendigen =
    !istHandwerker && benutzer && benutzer.werbeeinwilligung === null;

  if (istHandwerker && !istProfilVervollstaendigen) {
    return (
      <>
        <div className="headerNavigation">
          <HeaderNavigationItem
            isActive={istStartseiteActive()}
            onClick={oeffneStartseite}
          >
            Startseite
          </HeaderNavigationItem>
          <HeaderNavigationItem isActive={isModalVisible} onClick={showModal}>
            So funktioniert's
          </HeaderNavigationItem>
          {/*<HeaderNavigationItem*/}
          {/*  isActive={false}*/}
          {/*  onClick={() => {*/}
          {/*    window.open('https://meinzuhauseundich.de', '_blank');*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Hauscockpit*/}
          {/*</HeaderNavigationItem>*/}
          <HeaderNavigationItem
            isActive={istMeineAuftraegeAktiv()}
            onClick={oeffneAnfragen}
          >
            Meine Aufträge
          </HeaderNavigationItem>
        </div>
        {isModalVisible && (
          <AuftragsstatusModal hideModal={hideModal} gtmLabel="headerMenu" />
        )}
      </>
    );
  } else if (!istHandwerker && !istWerbeeinwilligungVervollstaendigen) {
    return (
      <div className="headerNavigation">
        <HeaderNavigationItem
          isActive={istStartseiteActive()}
          onClick={oeffneStartseite}
        >
          Startseite
        </HeaderNavigationItem>
        {/*<HeaderNavigationItem*/}
        {/*  isActive={false}*/}
        {/*  onClick={() => {*/}
        {/*    window.open('https://meinzuhauseundich.de', '_blank');*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Hauscockpit*/}
        {/*</HeaderNavigationItem>*/}
        <HeaderNavigationItem
          isActive={istMeineAuftraegeAktiv()}
          onClick={oeffneMeineAuftraege}
        >
          Meine Aufträge
        </HeaderNavigationItem>
      </div>
    );
  }

  return null;
};

const HeaderMenu = ({ logout, isDesktop, isVisible, hide }) => {
  const history = useHistory();
  const { benutzer, istHandwerker } = useBenutzerContext();
  const [
    isProfilModalShown,
    showProfilModal,
    hideProfilModal,
  ] = useModalState();

  const [
    isBewertungenModalShown,
    showBewertungenModal,
    hideBewertungenModal,
  ] = useModalState();

  const oeffneStartseite = () => {
    gtmEvent({
      category: 'navigation',
      action: 'headerMenuLandingpageClicked',
    });
    history.push('/');
    hide();
  };

  const oeffneAuftragsliste = () => {
    gtmEvent({
      category: 'navigation',
      action: istHandwerker
        ? 'headerMenuRequestsClicked'
        : 'headerMenuOpenContractsClicked',
    });

    history.push(istHandwerker ? '/anfragen' : '/meineAuftraege');
    hide();
  };

  const oeffneProfil = () => {
    showProfilModal();
    setScrollToDatenschutz(false);
    hide();
  };

  const oeffneBewertungen = () => {
    showBewertungenModal();
    hide();
  };

  const [scrollToDatenschutz, setScrollToDatenschutz] = useState(false);
  const oeffneProfilZumDatenschutz = () => {
    showProfilModal();
    setScrollToDatenschutz(true);
  };

  const handleMouseLeave = (event) => {
    if (isDesktop) {
      const keepMenuOpen =
        event.relatedTarget?.className &&
        event.relatedTarget.className.includes('avatar');
      if (!keepMenuOpen) {
        hide();
      }
    }
  };

  const istProfilVervollstaendigen =
    istHandwerker && !istProfilVollstaendig(benutzer);

  const istWerbeeinwilligungVervollstaendigen =
    !istHandwerker && benutzer.werbeeinwilligung === null;

  return (
    <div
      className={classNames('userMenu__overlay', {
        'userMenu__overlay--visible': isVisible,
      })}
    >
      <DatenschutzVervollstaendigenStoerer
        showProfilModal={oeffneProfilZumDatenschutz}
      />
      <OutsideClickHandler onOutsideClick={hide}>
        <div className="userMenu" onMouseLeave={handleMouseLeave}>
          <div className="userMenu__header">
            <span className="userMenu__headerTitle">Aktion wählen</span>
            <Button icon={<CloseIcon />} onClick={hide} />
          </div>
          <ul className="userMenu__optionlist">
            <li
              onClick={oeffneStartseite}
              className="userMenu__option userMenu__option--startseite hidden@desktop"
            >
              Startseite
            </li>
            {/*<li*/}
            {/*  className="userMenu__option userMenu__option--filiale hidden@desktop"*/}
            {/*  onClick={() => {*/}
            {/*    window.open('https://meinzuhauseundich.de', '_blank');*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Hauscockpit*/}
            {/*</li>*/}

            {!istProfilVervollstaendigen &&
              !istWerbeeinwilligungVervollstaendigen && (
                <>
                  <li
                    onClick={oeffneAuftragsliste}
                    className="userMenu__option userMenu__option--checklist hidden@desktop"
                  >
                    Meine Aufträge
                  </li>
                  <li
                    onClick={oeffneProfil}
                    className="userMenu__option  userMenu__option--user"
                  >
                    Mein Profil
                  </li>
                  {istHandwerker && (
                    <li
                      onClick={oeffneBewertungen}
                      className="userMenu__option  userMenu__option--star"
                    >
                      Meine Bewertungen
                    </li>
                  )}
                </>
              )}
            {isProfilModalShown && (
              <ProfilModal
                hide={hideProfilModal}
                kunde={!istHandwerker ? benutzer : null}
                handwerker={istHandwerker ? benutzer : null}
                scrollToDatenschutz={scrollToDatenschutz}
              />
            )}
            {isBewertungenModalShown && (
              <BewertungenModal
                hide={hideBewertungenModal}
                handwerker={benutzer}
              />
            )}
            <li
              onClick={logout}
              className="userMenu__option userMenu__option--logout"
            >
              Ausloggen
            </li>
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export const Avatar = withBreakpoints(function Avatar({
  logout,
  currentBreakpoint,
}) {
  const { benutzer, istHandwerker } = useBenutzerContext();
  const isDesktop = currentBreakpoint === 'xlarge';
  const [isMenuVisible, showMenu, hideMenu] = useModalState();
  const handleMouseEnter =
    isDesktop && !isMenuVisible ? () => showMenu() : () => {};

  const handleMouseLeave = (event) => {
    if (isDesktop) {
      const keepMenuOpen =
        event.relatedTarget?.className &&
        event.relatedTarget.className.includes('userMenu');
      if (!keepMenuOpen) {
        hideMenu();
      }
    }
  };
  return benutzer ? (
    <>
      <nav
        className="avatar"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={showMenu}
      >
        <div role="menu" className="avatar__menu">
          <span className="avatar__name">
            {istHandwerker
              ? benutzer.firmenname
              : `${benutzer.vorname} ${benutzer.nachname}`}
          </span>
          <img
            className="avatar__image"
            src={istHandwerker ? handwerkerIcon : kundeIcon}
            alt="Logout"
          />
        </div>
      </nav>
      <HeaderMenu
        hide={hideMenu}
        isVisible={isMenuVisible}
        isDesktop={isDesktop}
        logout={logout}
      />
    </>
  ) : null;
});

export default withBreakpoints(function Header({ currentBreakpoint }) {
  const history = useHistory();
  const { benutzer, istHandwerker } = useBenutzerContext();
  const logout = useLogOut(history, istHandwerker);

  const pathname = history.location.pathname;
  const isRouteKommunikationskanal = pathname.includes('/kommunikationskanal');
  const isRouteNachrichten = pathname.includes('/nachrichten');
  const isRouteAnfragen = pathname.includes('/anfragen');
  const isRouteSpezifikationsassistent = pathname.includes(
    '/spezifikationsassistent'
  );
  const isDesktop = currentBreakpoint === 'xlarge';
  const isMobileKommunikationskanalKunde =
    isRouteKommunikationskanal || isRouteNachrichten || isRouteAnfragen;
  const isHandwerkerHeaderVisible = !isRouteKommunikationskanal || isDesktop;
  const isKundeHeaderVisible =
    !isRouteSpezifikationsassistent &&
    (!isMobileKommunikationskanalKunde || isDesktop);
  const isHeaderVisible = istHandwerker
    ? isHandwerkerHeaderVisible
    : isKundeHeaderVisible;

  return (
    isHeaderVisible && (
      <header className="header">
        {benutzer && (
          <>
            <Navigation />
            <Avatar logout={logout} />
          </>
        )}
        {!benutzer && (
          <Button
            color="lightGreen"
            size="small"
            data-cy-id="anmeldenButton"
            onClick={() => {
              history.push('/anmelden');
            }}
          >
            Anmelden
          </Button>
        )}
      </header>
    )
  );
});
