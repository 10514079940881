import React from 'react';

import Modal from '../Modal/Modal';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import contactIcon from '../../assets/icons/contact.svg';
import { useModalState } from '../../hooks';
import Button from '../Button/Button';
import './KontaktHinweis.scss';

export function KontaktHinweis({ icon, children, ...props }) {
  const [isHelpDialogShown, showHelpDialog, hideHelpDialog] = useModalState();

  return (
    <>
      <button
        className="kontaktHinweisButton"
        {...props}
        onClick={showHelpDialog}
        data-cy-id="kontaktHinweisButton"
      >
        {icon && <span className="kontaktHinweisButton__icon">{icon}</span>}
        <span className="kontaktHinweisButton__text">{children}</span>
      </button>
      {isHelpDialogShown && <KontaktHinweisDialog hide={hideHelpDialog} />}
    </>
  );
}

function KontaktHinweisDialog({ hide }) {
  return (
    <Modal
      className="modal--dialog "
      hide={hide}
      gaTrackingName="Kontakt Hinweis Dialog"
      hideActions
    >
      <div className="kontaktHinweisDialog" data-cy-id="kontaktHinweisDialog">
        <img
          src={contactIcon}
          className="kontaktHinweisDialog__icon"
          alt="Rufen Sie uns an"
        />
        <h2>Benötigen Sie Hilfe?</h2>
        <p>
          Gerne helfen wir Ihnen weiter.
          <br />
          Sie erreichen uns von <strong>Mo-Fr von 8-17 Uhr</strong> unter
          Telefon:
        </p>
        <div className="hidden@desktop kontaktHinweisDialog__phone">
          <Button
            icon={<PhoneIcon />}
            color="yellow"
            onClick={() => {
              window.location.href = 'tel:02119785858';
            }}
          >
            0211 978 58 58
          </Button>
        </div>
        <div className="hidden@mobile kontaktHinweisDialog__phone">
          <img src={phoneIcon} alt="Telefon" />
          <a href="tel:02119785858">0211 978 58 58</a>
        </div>
        <p>
          Oder schreiben Sie uns:
          <br />
          <a href="mailto:meinzuhause@provinzial-dl.com">
            meinzuhause@provinzial-dl.com
          </a>
        </p>
      </div>
    </Modal>
  );
}
